import React from 'react';
import { DividerBackgroundContainer, DividerContainer, DividerStrokeContainer } from './styles';
import { WaveSize, WavyDividerProps } from './types';
import { WavyEdge } from './WavyEdge';

export const WavyDivider: React.FC<WavyDividerProps> = (props) => {
  const { className, backgroundColor, patternId = '', strokeColor, waveSize = WaveSize.SHORT, ...otherProps } = props;
  const wavyEdgeProps = {
    ...otherProps,
    waveSize,
  };

  return (
    <DividerContainer id='DividerContainer' className={className}>
      {backgroundColor && (
        <DividerBackgroundContainer id='DividerBackgroundContainer' color={backgroundColor}>
          <WavyEdge {...wavyEdgeProps} patternId={`${patternId}-fill`} />
        </DividerBackgroundContainer>
      )}
      <DividerStrokeContainer color={strokeColor}>
        <WavyEdge {...wavyEdgeProps} isStroke patternId={`${patternId}-stroke`} />
      </DividerStrokeContainer>
    </DividerContainer>
  );
};
