import React from 'react';

export const defaultItestAttribute = 'data-cy';

/**
 * Clones `el` and adds a property used to identify a DOM element during integration tests
 * @param id the value of the attribute
 * @param el the react element which will be cloned
 */
export function addItestID(
  id: string | undefined | null,
  el: React.ReactElement,
  attrName: string = defaultItestAttribute
): React.ReactElement {
  if (!id) return el;
  return React.cloneElement(el, { ...el.props, [attrName]: id }, el.props.children);
}
