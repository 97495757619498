import { times } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Dot = styled.div<{ active: boolean; complete: boolean }>`
  width: ${(props) => (props.active ? 9 : 7)}px;
  height: ${(props) => (props.active ? 9 : 7)}px;
  border-radius: 50%;
  background: #da3a4a;
  opacity: ${(props) => (props.complete ? 1 : 0.5)};
  margin-right: 11px;
`;

const DotIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DotIndicator: React.FC<{ activeIdx: number; total: number; onClick: (idx: number) => void }> = ({
  activeIdx,
  total,
  onClick,
}) => (
  <DotIndicatorContainer>
    {times(total, (idx) => (
      <Dot active={idx === activeIdx} complete={idx <= activeIdx} key={idx} onClick={() => onClick(idx)} />
    ))}
  </DotIndicatorContainer>
);
