import { useEffect } from 'react';
import { SELECTED_ITEM_SELECTOR } from '../consts';

export const getSelectedItem = (items: React.ReactElement[] = []) =>
  items.find((item) => {
    const { props } = item || {};
    return props.isSelected;
  });

export const useScrollToSelectedItem = (items: React.ReactNode[]) => {
  useEffect(() => {
    // Fins selected item and scroll into view.
    if (items) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const selectedItem = getSelectedItem(items as React.ReactElement[]);
      if (selectedItem) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const selectedElement = document.querySelector(SELECTED_ITEM_SELECTOR) as HTMLElement;
        if (selectedElement?.parentElement) {
          const { parentElement } = selectedElement;
          parentElement.scrollTop = selectedElement.offsetTop;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
