import { useCallback, useState } from 'react';

export const useRefState = <T>() => {
  const [ref, setRef] = useState<T | null>(null);
  const refCallback = useCallback((newRef: T | null) => {
    if (newRef) {
      setRef(newRef);
    }
  }, []);
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return [ref, refCallback] as [typeof ref, typeof refCallback];
};
