import { sortBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ConsumerGroupOrderFieldsFragment } from 'src/gqlReactTypings.generated.d';
import {
  DishFirstModalBody,
  DishFirstModalFooter,
  DishFirstModalSecondaryButton,
} from 'src/pages/consumer/dish-first/DishFirstModal';
import { ButtonSizes, PrimaryButton } from 'src/shared/design-system/Buttons';
import { useRefState } from 'src/shared/hooks/useRefState';
import { FoodItemRatingUpdate, OrderRatingsByOrderId } from './constants';
import { Header, HeaderSection } from './styles';

import { ShefCommentSection } from './ShefCommentSection';
import { ShefRatingSection } from './ShefRatingSection';

interface ConsumerGroupOrderRatingModalShefStepProps {
  groupOrder: ConsumerGroupOrderFieldsFragment;
  ignoreFoodTags: string[];
  negativeFoodTags: string[];
  onBack: () => void;
  onPartialSave: () => void;
  onSubmit: () => void;
  onFoodItemRatingUpdate: (update: FoodItemRatingUpdate) => void;
  orderRatingsByOrderId: OrderRatingsByOrderId;
  positiveFoodTags: string[];
  onShefRatingCommentUpdate: (orderId: number, comment: string) => void;
  onShefRatingPrivateCommentUpdate: (orderId: number, privateComment: string) => void;
  isSubmitDisabled: boolean;
}

/**
 * The rating step in the group order rating modal.
 * In this step, a user will rate each line item in each order within the group order.
 */
export const ConsumerGroupOrderRatingModalShefStep = ({
  groupOrder,
  ignoreFoodTags,
  negativeFoodTags,
  onBack,
  onPartialSave,
  onSubmit,
  onFoodItemRatingUpdate,
  orderRatingsByOrderId,
  positiveFoodTags,
  onShefRatingCommentUpdate,
  onShefRatingPrivateCommentUpdate,
  isSubmitDisabled,
}: ConsumerGroupOrderRatingModalShefStepProps): JSX.Element => {
  const sortedOrders = sortBy(groupOrder.orders, 'id');
  const [currentOrderIndex, setCurrentOrderIndex] = useState<number>(0);

  const currentOrder = sortedOrders[currentOrderIndex];
  const currentOrderRating = orderRatingsByOrderId[currentOrder.id];
  const isFinalOrder = currentOrderIndex === sortedOrders.length - 1;

  const [ratingSectionRef, setRatingSectionRef] = useRefState<HTMLDivElement>();
  const [headerSectionRef, setHeaderSectionRef] = useRefState<HTMLDivElement>();

  useEffect(
    () => headerSectionRef?.children[currentOrderIndex].scrollIntoView({ behavior: 'smooth' }),
    [currentOrderIndex, headerSectionRef?.children]
  );

  const handleSelectOrder = useCallback(
    (i: number) => {
      if (!isSubmitDisabled) {
        onPartialSave();
      }
      setCurrentOrderIndex(i);
      ratingSectionRef?.scrollTo({ top: 0, behavior: 'auto' });
    },
    [isSubmitDisabled, onPartialSave, ratingSectionRef]
  );
  const handleContinue = useCallback(() => {
    const updatedOrderIndex = currentOrderIndex + 1;

    if (!isSubmitDisabled) {
      onPartialSave();
    }

    setCurrentOrderIndex(updatedOrderIndex);
    ratingSectionRef?.scrollTo({ top: 0, behavior: 'auto' });
  }, [currentOrderIndex, isSubmitDisabled, ratingSectionRef, onPartialSave]);
  const handleBack = useCallback(() => {
    const updatedOrderIndex = currentOrderIndex - 1;
    if (updatedOrderIndex < 0) {
      onBack();
    } else {
      setCurrentOrderIndex(updatedOrderIndex);
    }
  }, [currentOrderIndex, onBack]);
  const onCommentUpdate = useCallback(
    (comment: string) => onShefRatingCommentUpdate(currentOrder.id, comment),
    [currentOrder.id, onShefRatingCommentUpdate]
  );
  const onPrivateCommentUpdate = useCallback(
    (privateComment: string) => onShefRatingPrivateCommentUpdate(currentOrder.id, privateComment),
    [currentOrder.id, onShefRatingPrivateCommentUpdate]
  );

  return (
    <>
      {sortedOrders.length > 1 && (
        <div className='w-full'>
          <div className='overflow-x-auto whitespace-nowrap text-center' ref={setHeaderSectionRef}>
            {sortedOrders.map((o, i) => (
              <div className='inline-block px-4 text-center' id={`${i}`}>
                <div className='flex'>
                  <hr className={`-ml-4 mb-0 mt-4 h-px grow border-0 bg-current${i === 0 ? ' invisible' : ''}`}></hr>
                  <input
                    type='radio'
                    className='size-6 accent-current before:border-current'
                    id={`${o.id}`}
                    name='current_order'
                    value={o.id}
                    checked={o.id === currentOrder.id}
                    onClick={() => handleSelectOrder(i)}
                  />
                  <hr
                    className={`-mr-4 mb-0 mt-4 h-px grow border-0 bg-current${
                      i === sortedOrders.length - 1 ? ' invisible' : ''
                    }`}></hr>
                </div>
                <label htmlFor={`${o.id}`} className='block w-max'>
                  Shef {o.publicShef.publicName}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
      <DishFirstModalBody ref={setRatingSectionRef}>
        <HeaderSection>
          <Header>Tell us about your experience</Header>
        </HeaderSection>
        <ShefRatingSection
          className='border-0 border-b-2 border-solid'
          order={currentOrder}
          ignoreFoodTags={ignoreFoodTags}
          negativeFoodTags={negativeFoodTags}
          onFoodItemRatingUpdate={onFoodItemRatingUpdate}
          orderRatingsByOrderId={orderRatingsByOrderId}
          positiveFoodTags={positiveFoodTags}
        />
        <ShefCommentSection
          comment={currentOrderRating.comment}
          onCommentUpdate={onCommentUpdate}
          privateComment={currentOrderRating.privateComment}
          onPrivateCommentUpdate={onPrivateCommentUpdate}
        />
      </DishFirstModalBody>

      <DishFirstModalFooter>
        <DishFirstModalSecondaryButton onClick={handleBack}>Back</DishFirstModalSecondaryButton>
        {!isFinalOrder ? (
          <PrimaryButton size={ButtonSizes.L} onClick={handleContinue}>
            Continue
          </PrimaryButton>
        ) : (
          <PrimaryButton size={ButtonSizes.L} onClick={onSubmit} disabled={isSubmitDisabled}>
            Submit
          </PrimaryButton>
        )}
      </DishFirstModalFooter>
    </>
  );
};
