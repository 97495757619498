import React from 'react';
import { MultiCartQuantityPicker } from 'src/pages/consumer/order/components/MultiCart/MultiCartQuantityPicker';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Border, Colors, FontSize, FontWeight, Mixins, Spacing } from 'web-common/src/shared/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spacing.SINGLE};

  background: ${Colors.WHITE};
  box-shadow: 0px -6px 14px rgba(0, 0, 0, 0.07);

  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  padding: ${Spacing.SINGLE};
  margin-top: -${Border.Radius.L};

  ${Mixins.Media.maxWidthSmall(css`
    padding: ${Spacing.HALF} ${Spacing.SINGLE} ${Spacing.HALF} ${Spacing.SINGLE};
  `)}
`;

const QuantityPicker = styled(MultiCartQuantityPicker)`
  flex: 1;
  height: ${Spacing.TRIPLE};
  padding-left: ${Spacing.SINGLE};
  padding-right: ${Spacing.SINGLE};

  font-weight: ${FontWeight.BOLD};
  font-size: ${FontSize.M};
  width: unset;
`;

const ActionButton = styled.button<{ disabled?: boolean }>`
  flex: 1;

  color: ${ifProp('disabled', Colors.WHITE, Colors.WHITE)};
  background: ${ifProp('disabled', Colors.GRAY, Colors.RASPBERRY)};
  border: none;
  font-weight: ${FontWeight.SEMI_BOLD};
  font-size: ${FontSize.XS};

  height: ${Spacing.TRIPLE};
  border-radius: ${Border.Radius.M};
`;

type BannerState = { bannerShowing: false } | { bannerShowing: true; bannerIcon: React.Component; bannerText: string };

type ModalV2FooterState = {
  quantityPickerDisabled: boolean;
  actionButtonDisabled: boolean;
  actionButtonText: string;
} & BannerState;

interface IModalV2FooterProps {
  onQuantityChange: (quantity: number) => void;
  quantity: number;
  minQuantity: number | undefined;
  maxQuantity: number | undefined;
  isServingSizeSelected: boolean;
  isEditingCart: boolean;
  hasBeenUpdated: boolean;
  actionButtonClick?: () => void;
  showModalPrompt?: boolean;
  openModal?: () => void;
}

const getModalV2FooterState = (
  input: Pick<
    IModalV2FooterProps,
    'isServingSizeSelected' | 'isEditingCart' | 'hasBeenUpdated' | 'quantity' | 'maxQuantity'
  >
): ModalV2FooterState => {
  const actionButtonText = match(input)
    .with({ isEditingCart: true, quantity: 0 }, () => 'Remove')
    .with({ isEditingCart: true }, () => 'Update')
    .with({ isServingSizeSelected: false }, () => 'Select portion size')
    .otherwise(() => 'Add to cart');

  const { isServingSizeSelected, isEditingCart, hasBeenUpdated, quantity, maxQuantity } = input;
  return {
    actionButtonDisabled:
      !isServingSizeSelected ||
      (isEditingCart && !hasBeenUpdated) ||
      (maxQuantity !== undefined && quantity > maxQuantity),
    actionButtonText,
    quantityPickerDisabled: false,
    bannerShowing: false,
  };
};

export const ModalV2Footer: React.FC<IModalV2FooterProps> = ({
  quantity,
  onQuantityChange,
  minQuantity,
  maxQuantity,
  isServingSizeSelected,
  actionButtonClick,
  isEditingCart,
  hasBeenUpdated,
}) => {
  const footerState = getModalV2FooterState({
    isServingSizeSelected,
    isEditingCart,
    hasBeenUpdated,
    quantity,
    maxQuantity,
  });
  return (
    <Container>
      <span className='flex-1'>
        <QuantityPicker
          quantity={quantity}
          minQuantity={minQuantity}
          fullSize
          maxQuantity={maxQuantity}
          increment={() => onQuantityChange(quantity + 1)}
          decrement={() => onQuantityChange(quantity - 1)}
          disabled={footerState.quantityPickerDisabled}
        />
      </span>
      <ActionButton onClick={actionButtonClick} disabled={footerState.actionButtonDisabled}>
        {footerState.actionButtonText}
      </ActionButton>
    </Container>
  );
};
