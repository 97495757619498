import { Slugs } from 'common/urls/Slugs';
import { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useGetFoodItemLazyQuery } from 'src/gqlReactTypings.generated.d';
import { getSlug } from 'src/shared/utils/RouteUtilities';
import { useDispatch, useSelector } from 'src/store';
import { actions, selectors } from 'src/store/explore';
import { useDishModalQueryParams } from './hooks/useDishModalQueryParams';

export const useDishModalState = () => {
  const fetchOnMountRef = useRef<boolean>(false);
  const dispatch = useDispatch();

  const routeMatch = useRouteMatch();
  const zipCode = getSlug(routeMatch, Slugs.ZIP_CODE);

  const [fetchFoodItem, { data, loading }] = useGetFoodItemLazyQuery();
  const { foodItemSlug, hasUrlParams, shefId } = useDishModalQueryParams();
  const foodItemMeta = useSelector(selectors.selectFoodItemMeta);
  const isDishModalShowing = useSelector(selectors.selectIsDishModalShowing);

  useEffect(() => {
    if (foodItemSlug && shefId && !loading && (!data || data.sluggedFoodItem?.slug !== foodItemSlug)) {
      fetchFoodItem({
        variables: {
          shefId,
          slug: foodItemSlug,
          zipCode,
        },
      }).catch((e) => {
        console.error('Failed to fetch', e);
      });
    }
  });

  useEffect(() => {
    if (shefId && foodItemSlug && !data?.sluggedFoodItem && !fetchOnMountRef?.current && !isDishModalShowing) {
      // eslint-disable-next-line functional/immutable-data -- Run on mount only
      fetchOnMountRef.current = true;
      dispatch(actions.openDishModal({ foodItemSlug, shefId }));
    }
  }, [dispatch, fetchFoodItem, shefId, foodItemSlug, data?.sluggedFoodItem, isDishModalShowing]);

  return {
    foodItem: data?.sluggedFoodItem,
    foodItemMeta,
    isDishModalShowing: isDishModalShowing && hasUrlParams,
    shefId,
  };
};
