import cn from 'classnames';
import React, { useCallback, useState } from 'react';
import { Modal } from '.';
import Button, { ButtonType } from '../Button';
import { BaseModalProps } from './components/BaseModal';
import { CloseIcon } from './components/CloseIcon';

interface StyledModalProps extends React.PropsWithChildren<BaseModalProps> {
  headerText?: React.ReactNode;
  showCloseButton?: boolean;
  primaryButtonText?: string;
  primaryDisabled?: boolean;
  secondaryButtonText?: string;
  onAccept?: () => void;
  removeContainerPadding?: boolean;
  hideOverflowGradient?: boolean;
}

export const StyledModal: React.FC<StyledModalProps> = ({
  children,
  modalType,
  headerText,
  isShowing,
  primaryButtonText,
  primaryDisabled = false,
  secondaryButtonText,
  showCloseButton = true,
  onClose,
  onAccept,
  removeContainerPadding,
  hideOverflowGradient = false,
}) => {
  const [contentIsOverflowing, setContentIsOverflowing] = useState<boolean>(false);

  const handleContentIsOverflowing = useCallback(
    (node) => setContentIsOverflowing(node?.scrollHeight > node?.clientHeight),
    [setContentIsOverflowing]
  );

  return (
    <Modal isShowing={isShowing} onClose={onClose} modalType={modalType}>
      <div>
        {(headerText || showCloseButton) && (
          <div
            className={cn('flex w-full items-center pb-4 pt-6', {
              'justify-center': headerText && !showCloseButton,
              'justify-end': !headerText && showCloseButton,
              'justify-between': headerText && showCloseButton,
              'p-6': !removeContainerPadding,
            })}>
            {headerText && <p className='text-2xl font-bold'>{headerText}</p>}
            {showCloseButton && (
              <button className={cn('border-none bg-none', { 'mr-6': removeContainerPadding })} onClick={onClose}>
                <CloseIcon />
              </button>
            )}
          </div>
        )}

        {/* Gradiant overlay when content is scrollable */}
        <div
          className='relative max-h-[70vh] w-full overflow-y-auto overflow-x-hidden md:max-h-[70vh]'
          ref={handleContentIsOverflowing}>
          <div className={cn({ 'px-6': !removeContainerPadding })}>{children}</div>
          {contentIsOverflowing && (
            <div
              className={cn('sticky bottom-0 left-0 h-6 w-full', {
                'bg-gradient-to-t from-neutral-100 to-transparent': !hideOverflowGradient,
              })}
            />
          )}
        </div>

        <div
          className={cn('relative flex w-full px-6 py-4', {
            'justify-between': secondaryButtonText && primaryButtonText,
            'justify-end': !secondaryButtonText,
          })}>
          {secondaryButtonText && (
            <Button buttonType={ButtonType.TextLink} onClick={onClose}>
              {secondaryButtonText}
            </Button>
          )}
          {primaryButtonText && onAccept && (
            <div>
              <Button buttonType={ButtonType.Primary} onClick={onAccept} disabled={primaryDisabled}>
                {primaryButtonText}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
