import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Pepper = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M13.9853 5.93177L13.8789 5.3621C13.6831 4.30949 13.0527 3.40285 12.1507 2.8749C12.1043 2.84746 11.8667 2.7344 11.6177 2.63013C12.0545 1.79045 11.8701 0.737833 11.105 0.0946277C10.9398 -0.04477 10.6896 -0.0272081 10.5459 0.133044C10.4022 0.293297 10.4203 0.535871 10.5855 0.675268C11.105 1.11102 11.2023 1.84423 10.8481 2.39195C9.37002 2.11974 7.84328 2.68172 6.89034 3.87044L6.63796 4.18546C6.5825 4.25461 6.5395 4.32924 6.50781 4.40608C6.44556 4.55974 6.5361 4.73427 6.69794 4.78476C6.90392 4.84952 7.12801 4.90549 7.31249 4.92415L8.34352 4.97464C8.86639 4.99989 9.32362 5.32369 9.50696 5.80005L9.51715 5.82859L9.75029 6.61558C9.77858 6.71218 9.85894 6.7462 9.89176 6.75718C9.92458 6.76706 10.0106 6.78352 10.0909 6.72096L10.6512 6.28191C11.1571 5.84067 11.8814 5.7342 12.4993 6.0108L13.1965 6.32252C13.321 6.36972 13.4545 6.41692 13.5858 6.46192C13.7612 6.52229 13.9548 6.41692 13.9853 6.2391C14.0023 6.14032 14.0023 6.03714 13.9831 5.93396L13.9853 5.93177Z'
      fill='currentColor'
      stroke='none'
    />
    <Path
      d='M12.8784 7.0228L12.17 6.70559C11.8384 6.55631 11.4479 6.61558 11.1785 6.85486L11.1604 6.87023L10.5912 7.31586C10.4089 7.45855 10.186 7.53319 9.95964 7.53319C9.85778 7.53319 9.75593 7.51782 9.6552 7.48709C9.33265 7.38831 9.08366 7.14134 8.99086 6.82523L8.76224 6.0547C8.68642 5.87249 8.50873 5.74956 8.30502 5.73968L7.24569 5.68809C7.05895 5.67053 6.79865 5.61345 6.53608 5.53662C6.36066 6.13482 6.29389 6.80986 6.22598 7.51563C6.12073 8.60447 6.00076 9.83819 5.44054 10.9742C5.05914 11.7481 4.60644 12.321 4.09715 12.6777C3.88438 12.827 3.6286 12.9401 3.31624 13.0257C2.5772 13.2265 2.10526 13.681 2.02151 14.2726C1.9321 14.9026 2.14827 15.3065 2.34519 15.5348C2.58626 15.8136 2.93144 15.9805 3.29134 15.9936C3.39773 15.998 3.50411 15.9991 3.6105 15.9991C5.75178 15.9991 7.9587 15.2176 9.71066 13.828C11.1469 12.6898 12.9113 10.6329 13.4013 7.21159C13.2553 7.1622 13.0889 7.10183 12.8999 7.03048L12.8784 7.0217V7.0228Z'
      fill='currentColor'
      stroke='none'
    />
  </SVG>
);
