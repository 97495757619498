import { ClientEvents, ClientTrackableEvents } from '../events/ClientEvents';
import { ServerEvents, TrackableServerEvents } from '../events/ServerEvents';
import { SharedEvents, TrackableSharedEvents } from '../events/SharedEvents';

export type AllEvents = ClientEvents | ServerEvents | SharedEvents;

export interface IIdentifiers {
  actorId?: string; // ID of the user who triggered the event
  userId?: string;
  deviceId?: string;
  sessionId?: string;
  regionId?: number;
  source?: string;
  userAgent?: string;
  ipAddress?: string;
  sessionStartTime?: number;
}

export type EventProperties<EVENT_NAME extends AllEvents> = EVENT_NAME extends ServerEvents
  ? TrackableServerEvents[EVENT_NAME]
  : EVENT_NAME extends ClientEvents
  ? ClientTrackableEvents[EVENT_NAME]
  : EVENT_NAME extends SharedEvents
  ? TrackableSharedEvents[EVENT_NAME]
  : never;

export interface ILogBulk<EVENT_NAME extends AllEvents> {
  eventName: EVENT_NAME;
  properties: EventProperties<EVENT_NAME>[];
  identifiers: IIdentifiers;
  allowNoIdentifier?: boolean; // Use if you dont want to check for an identifier when logging events (rare)
  occurredAt?: Date; // only specified when coming from the client and events could be delayed
}
export type ILog<EVENT_NAME extends AllEvents> = {
  eventName: EVENT_NAME;
  properties: EVENT_NAME extends ServerEvents
    ? TrackableServerEvents[EVENT_NAME]
    : EVENT_NAME extends ClientEvents
    ? ClientTrackableEvents[EVENT_NAME]
    : EVENT_NAME extends SharedEvents
    ? TrackableSharedEvents[EVENT_NAME]
    : never;
  identifiers: IIdentifiers;
  allowNoIdentifier?: boolean; // Use if you dont want to check for an identifier when logging events (rare)
  occurredAt?: Date; // only specified when coming from the client and events could be delayed
};
export interface IAnalyticsEventManager<T extends AllEvents = AllEvents> {
  logBulk: (props: ILogBulk<T>) => Promise<number | undefined>; // Returns number of successful writes
  log: (props: ILog<T>) => Promise<boolean>;
}

type CustomUserIdentifiers = {
  identifiers?: {
    userId?: string; // Custom userId used to overwrite default userId.
  };
};

export type IIdentifiedLogBulk<T extends AllEvents> = Omit<ILogBulk<T>, 'identifiers'> & CustomUserIdentifiers;
export type IIdentifiedLog<T extends AllEvents> = Omit<ILog<T>, 'identifiers'> & CustomUserIdentifiers;

export interface IIdentifiedAnalyticsEventManager<T extends AllEvents> {
  logBulk: (props: IIdentifiedLogBulk<T>) => Promise<number | undefined>; // Returns number of successful writes
  log: (props: IIdentifiedLog<T>) => Promise<boolean>;
}

export enum UserIdentifierType {
  USER_ID = 'USER_ID',
  DEVICE_ID = 'DEVICE_ID', // This is the browser tracker id
}
