import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './michelle-blackshire.jpg';

export const MichelleBlackshire: PersonInfo = {
  firstName: 'Michelle',
  lastName: 'Blackshire',
  image,
  pronoun: Pronoun.SHE,
  role: ROLE.PRODUCT_DESIGNER,
};
