import { useEffect } from 'react';
import { useDispatch, useSelector } from 'src/store';
import { setIsMultiCart } from 'src/store/cart/actions';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';

export const useIsDishFirst = () => {
  const [, , zipCodeMetaData] = useCachedZipCode();
  const zipcodeData = zipCodeMetaData.cachedZipCodeData?.zipCode;

  const isRegionDataReady = !!zipcodeData;
  const isDishFirst = !(zipcodeData?.isHubless ?? false); // Default to isDishFirst = true

  // keep cart in sync with latest isDishFirst status
  const dispatch = useDispatch();
  const isMultiCart = useSelector((state) => state.cart.isMultiCart);
  useEffect(() => {
    if (isRegionDataReady && isDishFirst !== isMultiCart) dispatch(setIsMultiCart(isDishFirst));
  }, [isRegionDataReady, isDishFirst, isMultiCart, dispatch]);

  return { isRegionDataReady, isDishFirst };
};
