import * as Sentry from '@sentry/react';
import { requestAppReview as requestAppReviewMessage } from '@shef/native-bridge';
import { StorageKeys } from 'common/storage/constants';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocalStorage } from 'src/shared/storage/Storage';
import { useScopedReducer } from 'src/shef-global-state/shefGlobalState';
import { isNativeMobileApp } from '../isNativeMobileApp';
import { useNativeAppContext } from './useNativeAppContext';

interface RequestAppReviewHook {
  canRequestAppReview: boolean;
  requestAppReview: () => void;
}

interface RequestAppReviewOptions {
  requestAppReviewImmediately?: boolean;
  type: string;
}

export const useRequestAppReview = ({
  requestAppReviewImmediately = false,
  type,
}: RequestAppReviewOptions): RequestAppReviewHook => {
  const { state: configs } = useScopedReducer('configs');
  const [, , nativeActionCreators] = useNativeAppContext();
  const [lastRequestedAppReviewAt, setLastRequestedAppReviewAt] = useLocalStorage<string | null>(
    StorageKeys.LAST_REQUESTED_APP_REVIEW_AT,
    null
  );

  /**
   * We can only request for an app review if it's the native mobile app and we haven't already requested
   * for one before or it's been over a week since the last time we requested for an app review.
   *
   * Every instance of a webview in the app has its own set of cookies, but we have this check anyways
   * to ensure that we aren't spamming the app with messages through the webbus.
   */
  const canRequestAppReview = useMemo(() => {
    if (!isNativeMobileApp() || !configs.requestAppReviewsEnabled) {
      return false;
    }

    if (!lastRequestedAppReviewAt) {
      return true;
    }

    const lastRequestedAppReviewAtDate = DateTime.fromISO(lastRequestedAppReviewAt);
    const oneWeekAgoDate = DateTime.local().minus({ weeks: 1 });
    return lastRequestedAppReviewAtDate < oneWeekAgoDate;
  }, [configs.requestAppReviewsEnabled, lastRequestedAppReviewAt]);

  const requestAppReview = useCallback(() => {
    if (!isNativeMobileApp()) {
      try {
        Sentry.captureMessage('Warning: requesting for native app review not from native mobile app', 'warning');
      } catch (error) {
        console.error(error);
      }
    }

    if (canRequestAppReview) {
      setLastRequestedAppReviewAt(DateTime.local().toISO());
      nativeActionCreators.dispatchSendRequest(requestAppReviewMessage, { type });
    }
  }, [canRequestAppReview, nativeActionCreators, setLastRequestedAppReviewAt, type]);

  useEffect(() => {
    if (canRequestAppReview && requestAppReviewImmediately) {
      requestAppReview();
    }
  }, [canRequestAppReview, requestAppReview, requestAppReviewImmediately]);

  return { canRequestAppReview, requestAppReview };
};
