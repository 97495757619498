import { css } from 'styled-components';

export enum FontFamily {
  SANS = `'Ja', 'Helvetica', 'Arial', 'sans-serif'`,

  /** Generally should be used when text is 18px and smaller (some exceptions for mobile). */
  BODY_SANS = `'Ja', 'Helvetica', 'Arial', 'sans-serif'`,
}

export enum FontSize {
  XXXS = '10px',
  XXS = '12px',
  XS = '14px',
  S = '16px',
  M = '18px',
  L = '20px',
  XL = '24px',
  XXL = '30px',
  XXXL = '40px',
  SEMI_HUGE = '50px',
  HUGE = '60px',
  MASSIVE = '80px',
  // DEPRECATED
  L2 = '20px',
  XL2 = '28px',
}

/** DEPRECATED: Old Line Heights */
export enum LineHeight {
  XXXS = '14px',
  XXS = '18px',
  XS = '20px',
  S = '24px',
  M = '25px',
  L = '28px',
  XL = '30px',
  XXL = '34px',
  XXXL = '40px',
  HUGE = '50px',
  MASSIVE = '80px',
  EXTRA_MASSIVE = '100px',
}

export enum FontLineHeight {
  SMALLEST = '11px', // Font 10px Caps
  FIVE_XS = '12px', // Font 12px Caps
  FOUR_XS = '15px', // Font 14px Caps
  THREE_XS = '16px', // Font 10px
  XXS = '17px', // Font 16px Caps
  XS = '20px', // Font 12px
  S = '21px', // Font 18px Caps
  M = '24px', // Font 14px
  L = '26px', // Font 16px
  XL = '28px', // Font 18px
  XXL = '30px', // Font 20px
  THREE_XL = '32px', // Font 24px
  FOUR_XL = '40px', // Font 30px
  FIVE_XL = '48px', // Font 40px
  SIX_XL = '65px', // Font 60px
  LARGEST = '85px', // Font 80px
}

// See https://www.w3.org/TR/css-fonts-3/#font-weight-numeric-values
export enum FontWeight {
  THIN = '100',
  EXTRA_LIGHT = '200',
  LIGHT = '300',
  NORMAL = '400',
  MEDIUM = '500',
  SEMI_BOLD = '600',
  BOLD = '700',
  EXTRA_BOLD = '800',
  HEAVY = '900',
}

export const FontVariant = {
  /* Size: 12px / Line Height: 20px */
  BodyDesktopS: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.XXS};
    line-height: ${FontLineHeight.XS};
  `,
  /* Size: 14px / Line Height: 24px */
  BodyDesktopM: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.XS};
    line-height: ${FontLineHeight.M};
  `,
  /* Size: 18px / Line Height: 28px */
  BodyDesktopL: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.M};
    line-height: ${FontLineHeight.XL};
  `,

  /* Size: 14px / Line Height: 24px */
  BodyMobileS: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.XS};
    line-height: ${FontLineHeight.M};
  `,
  /* Size: 16px / Line Height: 26px */
  BodyMobileM: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.S};
    line-height: ${FontLineHeight.L};
  `,

  /* Size: 30px / Line Height: 40px */
  HeaderS: css`
    font-family: ${FontFamily.SANS};
    font-size: ${FontSize.XXL};
    line-height: ${FontLineHeight.FOUR_XL};
  `,
  /* Size: 40px / Line Height: 48px */
  HeaderM: css`
    font-family: ${FontFamily.SANS};
    font-size: ${FontSize.XXXL};
    line-height: ${FontLineHeight.FIVE_XL};
  `,
  /* Size: 60px / Line Height: 65px */
  HeaderL: css`
    font-family: ${FontFamily.SANS};
    font-size: ${FontSize.HUGE};
    line-height: ${FontLineHeight.SIX_XL};
  `,
  /* Size: 80px / Line Height: 85px */
  HeaderXL: css`
    font-family: ${FontFamily.SANS};
    font-size: ${FontSize.MASSIVE};
    line-height: ${FontLineHeight.LARGEST};
  `,

  /* Size: 10px / Line Height: 11px / Caps */
  Label: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.XXXS};
    line-height: ${FontLineHeight.SMALLEST};
    text-transform: uppercase;
  `,

  /* Size: 10px / Line Height: 16px */
  Legal: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.XXXS};
    line-height: ${FontLineHeight.THREE_XS};
  `,

  /* Size: 12px / Line Height: 12px / Caps */
  SubHeaderXXS: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.XXS};
    line-height: ${FontLineHeight.FIVE_XS};
    text-transform: uppercase;
  `,
  /* Size: 14px / Line Height: 15px / Caps */
  SubHeaderXS: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.XS};
    line-height: ${FontLineHeight.FOUR_XS};
    text-transform: uppercase;
  `,
  /* Size: 16px / Line Height: 17px / Caps */
  SubHeaderS: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.S};
    line-height: ${FontLineHeight.XXS};
    text-transform: uppercase;
  `,
  /* Size: 18px / Line Height: 21px / Caps */
  SubHeaderM: css`
    font-family: ${FontFamily.BODY_SANS};
    font-size: ${FontSize.M};
    line-height: ${FontLineHeight.S};
    text-transform: uppercase;
  `,
  /* Size: 20px / Line Height: 30px */
  SubHeaderL: css`
    font-family: ${FontFamily.SANS};
    font-size: ${FontSize.L};
    line-height: ${FontLineHeight.XXL};
  `,
  /* Size: 24px / Line Height: 32px */
  SubHeaderXL: css`
    font-family: ${FontFamily.SANS};
    font-size: ${FontSize.XL};
    line-height: ${FontLineHeight.THREE_XL};
  `,
};

/**
 * Applies appropriate font family based on font size.
 * @param size: Font size. Use Font.Size enum only
 * @param weight: Optional font weight. Use Font.Weight enum only
 */
export const styleBySize = (size: FontSize, weight?: FontWeight) => css`
  font-size: ${size};
  font-family: ${FontFamily.SANS};
  ${weight ? `font-weight: ${weight};` : ``}
`;

export const Font = {
  bySize: styleBySize,
  Family: FontFamily,
  LineHeight,
  Size: FontSize,
  Weight: FontWeight,
  Variant: FontVariant,
};
