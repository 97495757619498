import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './nitish-kackar.jpg';

export const NitishKackar: PersonInfo = {
  firstName: 'Nitish',
  lastName: 'Kackar',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.PRODUCT_MANAGER,
  otherRoles: [ROLE.DATA_SCIENTIST],
};
