import { DEFAULT_PAGE_SIZE } from 'src/store/search';

export const BASE_SEARCH_VARIABLES = {
  isPartialQuery: true,
  filters: {
    availabilityDates: [],
    cuisineFilters: [],
    dietaryFilters: [],
    dishTypeFilters: [],
    proteinFilters: [],
    spiceLevelFilters: [],
    priceLevelFilters: [],
    isEcoFriendlyPackaging: false,
    minDishRating: 80,
  },
  searchState: {
    paginationState: {
      isEnd: false,
      nbHits: 100,
      queryIdx: 0,
    },
    seenRecordIds: [[], [], [], []],
  },
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
};
