import { noOp } from 'common/Constants';
import React, { useCallback } from 'react';
import { ToggleYesNoProps } from '../types';
import { DEFAULT_NO_LABEL, DEFAULT_YES_LABEL, NO_VALUE, YES_VALUE } from './consts';

export const useYesNo = (props: ToggleYesNoProps) => {
  const {
    dataTest,
    noLabel = DEFAULT_NO_LABEL,
    onChange = noOp,
    value,
    yesLabel = DEFAULT_YES_LABEL,
    ...otherProps
  } = props;
  const hasValue = value !== undefined;
  const selectedValue = value === true ? YES_VALUE : NO_VALUE;

  const handleChange = useCallback(
    (changedValue: string, e: React.MouseEvent<HTMLButtonElement>) => {
      onChange(changedValue === YES_VALUE, e);
    },
    [onChange]
  );

  const options = [
    { label: yesLabel, value: YES_VALUE, dataTest: value ? dataTest : '' },
    { label: noLabel, value: NO_VALUE, dataTest: !value ? dataTest : '' },
  ];

  return {
    barProps: {
      dataTest,
      onChange: handleChange,
      options,
      value: hasValue ? selectedValue : undefined,
      ...otherProps,
    },
  };
};
