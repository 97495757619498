import { getDomainForShefUrl } from 'common/RouteUtils';
import { QueryParams } from 'common/urls/QueryParams';
import { Slugs } from 'common/urls/Slugs';
import { get, some } from 'lodash';
import QueryString from 'query-string';
import { matchPath, RouteChildrenProps } from 'react-router';
import { FULL_QUERY_SLUG, Routes } from '../Routes';
import { omitQueryParamsFromSearch, pickQueryParamsFromSearch } from './QueryParamsUtilities';

export const getSlug = (match: any, slug: string): string => get(match, `params.${slug}`);

export const getParam = (location: any, param: string): string | null =>
  new URLSearchParams(location.search).get(param);

export const setParam = (location: any, param: string, value: string): void =>
  new URLSearchParams(location.search).set(param, value);

export const getIdSlug = (match: any): string => get(match, `params.${Slugs.ID}`);

export function getFullRelativeUrl(location: Partial<RouteChildrenProps['location']>): string {
  const { pathname = '', search = '' } = location;
  return pathname + search;
}

/*
 * Returns a relative string url with a redirect back to the current page
 */
export function getLoginRedirectViaLocation(location: RouteChildrenProps['location']): string {
  // If we're on the register page, just replace the '/register' in the path with '/login' since it's already encoded
  // location.search adds in any query params if applicable
  const isRegisterPage = locationInRoutes(location, [Routes.CONSUMER_REGISTER]);
  if (isRegisterPage) return location.pathname.replace('/register', '/login') + location.search;

  const fullRelativeUrl = getFullRelativeUrl(location);
  const returnUri = encodeURIComponent(fullRelativeUrl);

  return Routes.CONSUMER_LOGIN.replace(`:${Slugs.REDIRECT}?`, returnUri);
}

export function getLoginRedirect(match: RouteChildrenProps['match']): string {
  const uri = encodeURIComponent(match ? match.url.replace('/register', '') : '/');
  return Routes.CONSUMER_LOGIN.replace(`:${Slugs.REDIRECT}?`, uri);
}

const REGISTER_FORM_PREFILL_QUERY_PARAMS = [QueryParams.FIRST_NAME, QueryParams.LAST_NAME, QueryParams.EMAIL];

export const getRegisterRedirectViaLocation = (location: RouteChildrenProps['location']): string => {
  // If we're on the login page, just replace the '/login' in the path with '/register' since it's already encoded
  // location.search adds in any query params if applicable
  const isLoginPage = locationInRoutes(location, [Routes.CONSUMER_LOGIN]);
  if (isLoginPage) return location.pathname.replace('/login', '/register') + location.search;

  const encodedRedirectUrl = encodeURIComponent(
    `${location.pathname}?${omitQueryParamsFromSearch(location.search, REGISTER_FORM_PREFILL_QUERY_PARAMS)}`
  );
  const redirectQueryParams = pickQueryParamsFromSearch(location.search, REGISTER_FORM_PREFILL_QUERY_PARAMS);
  const redirectUrl = Routes.CONSUMER_REGISTER.replace(`:${Slugs.REDIRECT}`, encodedRedirectUrl);

  return `${redirectUrl}${redirectQueryParams}`;
};

export const getRegisterRedirect = (match: any, search = ''): string => {
  const uri = encodeURIComponent(match.url.replace('/login', '') + search);
  return Routes.CONSUMER_REGISTER.replace(`:${Slugs.REDIRECT}?`, uri);
};

export const getLogoutRedirect = (match: any): string =>
  Routes.LOGOUT.replace(`:${Slugs.REDIRECT}?`, encodeURIComponent(match.url));

export const getLogoutRedirectViaLocation = (location: RouteChildrenProps['location']): string => {
  const returnUri = encodeURIComponent(getFullRelativeUrl(location).replace('/login', '').replace('/register', ''));
  return Routes.LOGOUT.replace(`:${Slugs.REDIRECT}?`, returnUri);
};

// if the uriComponent is decoded, it should be encoded
const shouldEncodeURIComponent = (uriComponent: string | number): boolean => {
  try {
    const decoded = decodeURIComponent(`${uriComponent}`) === `${uriComponent}`;
    return decoded;
  } catch (e: unknown) {
    // uriComponent might be decoded already and have a % sign in it, which will throw an error on decode
    return true;
  }
};

export type EXTRA_SLUGS = { slug: string; value: string | number }[];

export type QUERY_PARAMS = Partial<Record<QueryParams, any>> | undefined;
export const getSluggedPath = (
  path: string,
  extraSlugs: EXTRA_SLUGS = [],
  queryParams: QUERY_PARAMS = undefined
): string => {
  let basePath = path;
  for (const extraSlug of extraSlugs) {
    const urlEncodedSlug = shouldEncodeURIComponent(extraSlug.value)
      ? encodeURIComponent(extraSlug.value)
      : `${extraSlug.value}`;
    basePath = basePath.replace(`:${extraSlug.slug}?`, urlEncodedSlug);
    basePath = basePath.replace(`:${extraSlug.slug}`, urlEncodedSlug);
  }

  basePath = basePath.replace(FULL_QUERY_SLUG, '');
  // Get rid of existing query params
  const paramsIndex = basePath.indexOf('?');
  if (paramsIndex !== -1) basePath = basePath.substring(0, paramsIndex);

  // Add new query params
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const query = QueryString.stringify(queryParams as { [key: string]: any });
  if (query) {
    basePath += `?${query}`;
  }

  return basePath.replace(`:${Slugs.REDIRECT}?`, '').replace('//', '/');
};

export const getIdSluggedPath = (path: string, id: string | number, queryParams: QUERY_PARAMS = undefined): string =>
  getSluggedPath(path, [{ slug: Slugs.ID, value: id }], queryParams);

export const getAnchorPath = (path: string, anchor: string) => `${path}#${anchor}`;

// Allow converting a parameter with { useQueryParam } from 'use-query-params to an enum and back
export class ParamEnumType<T extends { toString(): string }> {
  constructor(private defaultVal: T) {}

  encode = (value: T | null | undefined) => {
    if (!value) {
      return this.defaultVal.toString();
    }

    return value.toString();
  };

  decode = (value: string | (string | null)[] | null | undefined) => {
    if (!value) {
      return this.defaultVal;
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value as unknown as T;
  };
}

export const locationInRoutes = (location: RouteChildrenProps['location'], routes: string[]): boolean =>
  some(routes.map((route) => !!matchPath(location.pathname, { path: route, exact: true })));

export const getDomain = () => getDomainForShefUrl(process.env.REACT_APP_FRONT_END_URL);
