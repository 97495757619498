import { createSelector, Dictionary } from '@reduxjs/toolkit';
import { getPriceWithCustomizations } from 'common/CustomizationUtils';
import { isDefined } from 'common/TypeUtilities';
import { isoDateToUrlDate } from 'common/UrlDate';
import { compact, countBy, fromPairs, isNil, keyBy, map, mapValues, pick, sumBy, values } from 'lodash';
import { AddOnItemType, FoodType } from 'src/gqlReactTypings.generated.d';
import { MAIN_FOOD_TYPES, SIDE_FOOD_TYPES } from 'src/shared/Constants';
import {
  addOnItemAdapter,
  availableAddOnItemsAdapter,
  foodItemAdapter,
  isLoadedShef,
  lineItemAdapter,
} from 'src/store/cart/entities';
import { selectCartDeliveryDatesByShefIds } from 'src/store/cart/selectors/deliveryDate';
import { selectCartShefsById } from 'src/store/cart/selectors/shefs';
import { CartFoodItem, CartLineItem, CartState, WholeCartLineItem } from 'src/store/cart/types';
import { getAdjustedFoodItemAvailability, isFoodItemAvailable } from 'src/store/cart/utils/capacity';

const foodItemSelectors = foodItemAdapter.getSelectors();
const lineItemSelectors = lineItemAdapter.getSelectors();
const addOnItemSelectors = addOnItemAdapter.getSelectors();
const availableAddOnItemsSelectors = availableAddOnItemsAdapter.getSelectors();

// base selectors
export const selectLineItems = (state: CartState) => lineItemSelectors.selectAll(state.lineItems);
// NOTE: v2 redux toolkit will allow you to customize the entity's id type to avoid needing to typecast like so
export const selectLineItemIds = (state: CartState) => state.lineItems.ids as CartLineItem['id'][];
export const selectLineItem = (state: CartState, lineItemId: number) =>
  lineItemSelectors.selectById(state.lineItems, lineItemId);

export const selectCartFoodItemsById = (state: CartState) => state.foodItems.entities;
export const selectCartFoodItem = (state: CartState, foodItemId: number) =>
  foodItemSelectors.selectById(state.foodItems, foodItemId);
export const selectCartFoodItemCount = (state: CartState) => state.foodItems.ids.length;

export const selectAddOnItems = (state: CartState) => addOnItemSelectors.selectAll(state.addOnItems);
export const selectAvailableAddOnItems = (state: CartState) =>
  availableAddOnItemsSelectors.selectAll(state.availableAddOnItems);

// Calculates the price of a single quantity of a line item with its customizations applied
export const calcLineItemUnitPriceWithCustomizations = (
  lineItem?: Pick<CartLineItem, 'customizationIds'>,
  foodItem?: CartFoodItem
) => {
  if (isNil(foodItem) || isNil(lineItem)) {
    return 0;
  }

  const { customizationIds } = lineItem;

  if (isNil(customizationIds) || customizationIds.length === 0) {
    return foodItem.price;
  }
  const { spiceLevelCustomizations, servingSizeCustomizations } = foodItem;
  const keyedCustomizations = keyBy([...(spiceLevelCustomizations ?? []), ...(servingSizeCustomizations ?? [])], 'id');
  const lineItemCustomizations = compact(
    map(customizationIds, (customizationId) => keyedCustomizations[customizationId])
  );

  return getPriceWithCustomizations(foodItem.price, lineItemCustomizations);
};

export const selectFullLineItems = createSelector(selectLineItems, selectCartFoodItemsById, (lineItems, foodItems) =>
  lineItems
    .map((lineItem) => {
      const foodItem = foodItems[lineItem.foodItemId];
      return foodItem
        ? {
            ...lineItem,
            foodItem,
            unitPriceWithCustomizations: calcLineItemUnitPriceWithCustomizations(lineItem, foodItem),
          }
        : undefined;
    })
    .filter(isDefined)
);

export const selectFullLineItemsById = createSelector(selectFullLineItems, (lineItems) =>
  lineItems.reduce((lineItemsById, lineItem) => ({ ...lineItemsById, [lineItem.id]: lineItem }), {})
);

export const selectShefLineItems = createSelector(
  selectFullLineItems,
  (_: any, shefId: string) => shefId,
  (lineItems, shefId) => lineItems.filter((item) => item.shefId === shefId)
);

export const selectActiveShefLineItems = createSelector(
  [selectFullLineItems, (state: CartState) => state.activeCartShefId],
  (lineItems, shefId) => lineItems.filter((item) => item.shefId === shefId)
);

export const selectShefLineItemsById = createSelector(
  selectShefLineItems,
  (shefLineItems): Dictionary<WholeCartLineItem | undefined> => keyBy(shefLineItems, 'id')
);

export const selectAvailableAddOnItemsByType = createSelector(
  selectAvailableAddOnItems,
  (_: CartState, addOnType: AddOnItemType) => addOnType,
  (availableAddOnItems, addOnType) => availableAddOnItems.filter((item) => item.addonType === addOnType)
);

const emptyArray = [];
const selectRawOrderDataLineItems = (state: CartState): WholeCartLineItem[] =>
  // have to use ts-ignore because of weird type situation caused by immer
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  state.rawOrderData.lineItems || emptyArray;

export const selectLineItemQuantitiesInEditingOrder = createSelector(
  selectRawOrderDataLineItems,
  (orderLineItems): Dictionary<number> =>
    fromPairs(orderLineItems.map(({ foodItem, quantity, foodItemId }) => [foodItem?.id ?? foodItemId, quantity]))
);

export const selectLineItemQuantities = createSelector(selectLineItems, (lineItems) =>
  fromPairs(lineItems.map((li) => [li.foodItemId, li.quantity]))
);

export const selectLineItemAvailabilitiesById = createSelector(
  selectFullLineItems,
  selectCartDeliveryDatesByShefIds,
  selectLineItemQuantitiesInEditingOrder,
  (lineItems, deliveryDates, lineItemQuantitiesInEditingOrder) =>
    lineItems.reduce<Record<number, { numAvailable: number | null; numRemaining: number | null; quantity: number }>>(
      (acc, { id, foodItemId, quantity, shefId, foodItem: { availability } }) => {
        const deliveryDate = deliveryDates[shefId];
        const numAvailable =
          getAdjustedFoodItemAvailability({
            foodItemsAvailabilities: availability,
            deliveryDate,
            quantityInEditingOrder: lineItemQuantitiesInEditingOrder[foodItemId] ?? 0,
          }) ?? Infinity;
        const numRemaining = numAvailable - quantity;

        return {
          ...acc,
          [id]: { numAvailable, numRemaining, quantity },
        };
      },
      {}
    )
);

// returns list of line items that are unavailable relative to their currently selected delivery date
export const selectUnavailableLineItemIds = createSelector(
  selectCartShefsById,
  selectFullLineItems,
  selectCartDeliveryDatesByShefIds,
  selectLineItemQuantitiesInEditingOrder,
  (shefs, lineItems, deliveryDates, lineItemQuantitiesInEditingOrder): Set<number> =>
    new Set(
      lineItems
        .filter(({ id: foodItemId, shefId, foodItem: { availability } }) => {
          const shef = shefs[shefId];
          const deliveryDate = deliveryDates[shef?.id || ''] || availability.map((a) => a.availabilityDate).sort()?.[0];
          if (!deliveryDate) return true;
          return !isFoodItemAvailable({
            foodItemsAvailabilities: availability,
            deliveryDate,
            quantityInEditingOrder: lineItemQuantitiesInEditingOrder[foodItemId] ?? 0,
          });
        })
        .map(({ id }) => id)
    )
);

// takes all line items and removes any items that are not available and reduces
// the quantity to the max value available for that delivery date
export const selectAvailableLineItems = createSelector(
  selectFullLineItems,
  selectUnavailableLineItemIds,
  selectCartDeliveryDatesByShefIds,
  (lineItems, unavailableLineItemIds, deliveryDates) =>
    lineItems
      .filter((lineItem) => !unavailableLineItemIds.has(lineItem.foodItemId))
      .map((lineItem) => {
        const deliveryDate = deliveryDates[lineItem.shefId] || '';
        const capacity =
          lineItem.foodItem.availability.find((a) => a.availabilityDate.startsWith(deliveryDate))?.numAvailable ?? null;
        if (capacity === null) return lineItem;
        const quantity = Math.min(capacity, lineItem.quantity);
        return { ...lineItem, quantity };
      })
);

export const selectShefAvailableLineItems = createSelector(
  selectAvailableLineItems,
  (_: CartState, shefId: string) => shefId,
  (availableLineItems, shefId) => availableLineItems.filter((lineItem) => lineItem.shefId === shefId)
);

export const selectShefsAvailableLineItems = createSelector(
  selectAvailableLineItems,
  (_: CartState, shefIds: string[]) => shefIds,
  (availableLineItems, shefIds) => {
    const shefIdSet = new Set(shefIds);
    return availableLineItems.filter((lineItem) => shefIdSet.has(lineItem.shefId));
  }
);

export const selectActiveShefAvailableLineItems = createSelector(
  selectAvailableLineItems,
  (state: CartState) => state.activeCartShefId,
  (availableLineItems, shefId) => (shefId ? availableLineItems.filter((lineItem) => lineItem.shefId === shefId) : [])
);

export const selectShefAndFoodItemUnavailabilityCounts = createSelector(
  selectFullLineItems,
  selectCartShefsById,
  (_state: CartState, shefIds: string[]) => shefIds,
  selectLineItemQuantitiesInEditingOrder,
  (cart: CartState) => cart.possibleDeliveryDates,
  (
    lineItems,
    cartShefs,
    shefIds,
    lineItemQuantitiesInEditingOrder,
    possibleDeliveryDates
  ): Record<string, { foodItems: number; shefs: number } | undefined> => {
    // shef count calculations
    const shefs = values(pick(cartShefs, shefIds)).filter(isDefined).filter(isLoadedShef);
    const numShefs = shefIds.length;
    const shefIdsSet = new Set(shefIds);
    const shefsLineItems = lineItems.filter((item) => shefIdsSet.has(item.shefId));

    // backfill date maps with zero as countBy's won't adds for no available shefs/food items
    const defaultZeroAvailability = fromPairs(possibleDeliveryDates.map((date) => [date, 0]));

    const numShefAvailableByShefsDeliveryDate = countBy(
      shefs
        .map((shef) => shef.availability)
        .flat()
        .filter((availability) => !availability.isSoldOut)
        .map((availability) => availability.availabilityDate)
    );
    const numShefAvailableByDeliveryDate = { ...defaultZeroAvailability, ...numShefAvailableByShefsDeliveryDate };

    const numShefUnavailableByDeliveryDate = mapValues(
      numShefAvailableByDeliveryDate,
      (numAvailable) => numShefs - numAvailable
    );

    // food item count calculations
    const foodItemIds = map(shefsLineItems, 'foodItemId');
    const numFoodItems = foodItemIds.length;
    const numFoodItemsAvailableByShefDeliveryDate = countBy(
      shefsLineItems.flatMap((li) =>
        li.foodItem.availability
          .filter((availability) =>
            isFoodItemAvailable({
              foodItemsAvailabilities: [availability],
              deliveryDate: availability.availabilityDate,
              quantityInEditingOrder: lineItemQuantitiesInEditingOrder[li.foodItemId] ?? 0,
            })
          )
          .map((availability) => isoDateToUrlDate(availability.availabilityDate))
      )
    );
    const numFoodItemsAvailableByDeliveryDate = {
      ...defaultZeroAvailability,
      ...numFoodItemsAvailableByShefDeliveryDate,
    };
    const numFoodItemsUnavailableByDeliveryDate = mapValues(
      numFoodItemsAvailableByDeliveryDate,
      (numAvailable) => numFoodItems - numAvailable
    );

    return fromPairs(
      possibleDeliveryDates.map((date) => [
        date,
        {
          foodItems: numFoodItemsUnavailableByDeliveryDate[date] ?? numFoodItems,
          shefs: numShefUnavailableByDeliveryDate[date] ?? numShefs,
        },
      ])
    );
  }
);

export const selectActiveLineItemInputs = createSelector(selectActiveShefAvailableLineItems, (lineItems) =>
  lineItems.map((item) => ({ foodItemId: item.foodItemId, quantity: item.quantity }))
);

export const selectAddOnItemInputs = createSelector(selectAddOnItems, (addOnItem) =>
  addOnItem.map(({ id, quantity }) => ({ id, quantity }))
);

export const selectActiveCartLineItemCount = createSelector(selectActiveLineItemInputs, (activeShefLineItems) =>
  sumBy(activeShefLineItems, 'quantity')
);

export const selectShefAvailableLineItemQuantity = createSelector(selectShefAvailableLineItems, (lineItems) =>
  sumBy(lineItems, 'quantity')
);

export const selectActiveCartItemCount = createSelector(
  selectActiveLineItemInputs,
  selectAddOnItems,
  (activeShefLineItems, activeShefAddOnItems) =>
    sumBy(activeShefLineItems, 'quantity') + sumBy(activeShefAddOnItems, 'quantity')
);

export const selectAvailableCartItemCount = createSelector(
  selectAvailableLineItems,
  selectAddOnItems,
  (activeShefLineItems, activeShefAddOnItems) =>
    sumBy(activeShefLineItems, 'quantity') + sumBy(activeShefAddOnItems, 'quantity')
);

export const selectShefCartIsEmpty = createSelector(selectShefLineItems, (lineItems) => lineItems.length === 0);

export const selectMultiCartIsEmpty = createSelector(selectLineItemIds, (ids) => ids.length === 0);

const getFoodItemIdsForFoodTypes = ({
  foodTypes,
  lineItems,
}: {
  foodTypes: (FoodType | null | undefined)[];
  lineItems: readonly WholeCartLineItem[];
}) => lineItems.filter((item) => foodTypes.includes(item.foodItem.foodType)).map((item) => item.foodItemId);

export const selectAvailableCartItemIdsByType = createSelector(selectAvailableLineItems, (availableLineItems) => ({
  mainIds: getFoodItemIdsForFoodTypes({ foodTypes: MAIN_FOOD_TYPES, lineItems: availableLineItems }),
  sideIds: getFoodItemIdsForFoodTypes({ foodTypes: SIDE_FOOD_TYPES, lineItems: availableLineItems }),
}));
