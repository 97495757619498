import { useState } from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Colors, FontSize, FontWeight, Spacing, Transition } from 'web-common/src/shared/styles';

const Tab = styled.button<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  background: none;
  border: none;
  margin: 0;
  padding: 0;

  font-size: ${FontSize.S};
  font-weight: ${FontWeight.MEDIUM};

  transition: color ${Transition.Time.BASE};

  :after {
    content: ' ';
    background: ${Colors.GRAY_DARK_30};
    border-top-left-radius: ${Spacing.QUARTER};
    border-top-right-radius: ${Spacing.QUARTER};
    margin-top: ${Spacing.QUARTER};
    height: ${Spacing.QUARTER};
    width: 100%;
    z-index: 1;
    opacity: 0;
    transition: opacity ${Transition.Time.BASE};
  }

  :hover {
    :after {
      opacity: 0.5;
    }
  }

  ${ifProp(
    'selected',
    css`
      color: ${Colors.GRAY_DARK_30};
      :after {
        opacity: 1;
      }
      :hover {
        :after {
          opacity: 1;
        }
      }
    `,
    css`
      color: ${Colors.GRAY_DARK_10};
    `
  )}
`;

const Divider = styled.hr`
  position: relative;
  top: -1px;
  margin: ${Spacing.NONE} ${Spacing.NONE} ${Spacing.SINGLE} ${Spacing.NONE};
  height: 0.5px;
  background: ${Colors.GRAY_LITE_20};
`;

interface IDetailsTabsProps<T extends string> {
  tabs: Record<T, React.ReactNode>;
  className?: string;
}

export const DetailsTabs = <T extends string>({ tabs, className }: IDetailsTabsProps<T>) => {
  const tabNames = Object.keys(tabs);
  const [selectedTab, setSelectedTab] = useState(tabNames[0]);

  const tabComponents = tabNames.map((tabName) => (
    <Tab selected={tabName === selectedTab} key={tabName} onClick={() => setSelectedTab(tabName)}>
      {tabName}
    </Tab>
  ));

  return (
    <div className={className}>
      <div className='flex flex-row items-start justify-start gap-4'>{tabComponents}</div>
      <Divider />
      <div className='text-base font-medium text-neutral-700'>{tabs[selectedTab]}</div>
    </div>
  );
};
