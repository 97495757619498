import { ArrayType } from 'common/TypeUtilities';
import React, { useCallback } from 'react';
import { UpcomingOrderFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { DishFirstModalRoundButton } from 'src/pages/consumer/dish-first/DishFirstModal';
import { DynamicImage } from 'src/shared/design-system/DynamicImage';
import { Alert, ThumbsDownV2, ThumbsUpV2 } from 'src/shared/design-system/Icon';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import { minScreen } from 'src/shared/utils/StyledComponentUtils';
import styled, { css } from 'styled-components';
import { BorderRadius, Colors, FontWeight, LineHeight, ScreenWidth, Spacing } from 'web-common/src/shared/styles';
import { RatingPercent } from '../ratings/constants';

const DISH_IMAGE_SIZE = 56;
const ROUND_BUTTON_SIZE = 40;
const ROUND_BUTTON_ICON_SIZE = 16;

const StyledGroupOrderFoodItemRating = styled.div<{ disabled?: boolean }>`
  padding: ${Spacing.SINGLE};

  ${ifProp(
    'disabled',
    css`
      opacity: 0.3;
      pointer-events: none;
    `
  )}

  ${minScreen(ScreenWidth.SMALL)} {
    padding: ${Spacing.SINGLE_HALF};
  }
`;

export const GroupOrderFoodItemRatings = styled.div`
  ${StyledGroupOrderFoodItemRating} {
    border-bottom: 1px solid ${Colors.GRAY_LITE_20};
  }

  ${StyledGroupOrderFoodItemRating}:last-child {
    border-bottom: none;
  }
`;

const FoodItemImage = styled(DynamicImage)`
  border-radius: ${BorderRadius.M};
  flex-shrink: 0;
`;

const OrderRatingFoodItemDetailsAndRatingSection = styled.div`
  display: flex;
  gap: ${Spacing.HALF};
  align-items: center;
`;

const FoodItemName = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-grow: 1;
  font-weight: ${FontWeight.SEMI_BOLD};
  line-height: ${LineHeight.XXS};
  margin-right: ${Spacing.HALF};
`;

interface GroupOrderFoodItemRatingProps {
  disabled?: boolean;
  foodItem: ArrayType<UpcomingOrderFieldsFragment['lineItems']>['foodItem'];
  onRatingClick?: (ratingPercent: RatingPercent) => void;
  selectedRatingPercent?: RatingPercent;
}

export const GroupOrderFoodItemRating: React.FC<React.PropsWithChildren<GroupOrderFoodItemRatingProps>> = ({
  children,
  disabled,
  foodItem,
  onRatingClick,
  selectedRatingPercent,
}) => {
  const { name: foodItemName, imageUrl: foodItemImageUrl } = foodItem;

  const getRatingButtonProps = useCallback(
    (ratingPercent: RatingPercent) => ({
      onClick: () => onRatingClick?.(ratingPercent),
      selected: selectedRatingPercent === ratingPercent,
      size: ROUND_BUTTON_SIZE,
    }),
    [onRatingClick, selectedRatingPercent]
  );

  return (
    <StyledGroupOrderFoodItemRating disabled={disabled}>
      <OrderRatingFoodItemDetailsAndRatingSection>
        <FoodItemImage
          src={foodItemImageUrl ?? ''}
          sourceWidth={DISH_IMAGE_SIZE}
          sourceHeight={DISH_IMAGE_SIZE}
          alt={foodItemName ?? 'dish'}
          width={DISH_IMAGE_SIZE}
          height={DISH_IMAGE_SIZE}
        />
        <FoodItemName>{foodItemName}</FoodItemName>

        {onRatingClick ? (
          <>
            <DishFirstModalRoundButton {...getRatingButtonProps(100)}>
              <ThumbsUpV2 height={ROUND_BUTTON_ICON_SIZE} outline />
            </DishFirstModalRoundButton>
            <DishFirstModalRoundButton {...getRatingButtonProps(0)}>
              <ThumbsDownV2 height={ROUND_BUTTON_ICON_SIZE} outline />
            </DishFirstModalRoundButton>
            <DishFirstModalRoundButton {...getRatingButtonProps(null)}>
              <Alert height={ROUND_BUTTON_ICON_SIZE} />
            </DishFirstModalRoundButton>
          </>
        ) : null}
      </OrderRatingFoodItemDetailsAndRatingSection>
      {children}
    </StyledGroupOrderFoodItemRating>
  );
};
