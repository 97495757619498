import { MealPlanFormat } from 'src/gqlReactTypings.generated.d';
import { Routes } from 'src/shared/Routes';

// React Hook Form consts
export const mode = 'all';
export const reValidateMode = 'onChange';

export const STEP_1 = 'household';
export const MEAL_PLANS_PREFERENCES_FIRST_STEP = `${Routes.CONSUMER_MEAL_PLANS_PREFERENCES}?step=${STEP_1}`;
export const MEAL_PLANS_PREFERENCES_FIRST_STEP_MAINS_PLUS_SIDES = `${Routes.CONSUMER_MEAL_PLANS_PREFERENCES}?step=${STEP_1}&format=${MealPlanFormat.MainsPlusSides}`;
export const COMPLETE_MEAL_PLANS_PREFERENCES_FIRST_STEP = `${Routes.CONSUMER_MEAL_PLANS_PREFERENCES}?step=${STEP_1}&format=${MealPlanFormat.CompleteMeal}`;
