import { ActiveExperiments } from 'common/experiments/ExperimentDefinitions';
import { IVariant } from 'common/experiments/IExperimentManager';
import { StorageKeys } from 'common/storage/constants';
import { QueryParams } from 'common/urls/QueryParams';
import { fromPairs, isString, isUndefined } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'src/shared/storage/Storage';
import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';

type VariantOverrides = Record<string, IVariant>;
type ExperimentPair = [string, IVariant];

// reads from local storage overrides settings and reads from url query params to detect any additional overrides
export const useVariantOverrides = (): [VariantOverrides, (overrides: VariantOverrides) => void] => {
  const [variantOverrides, setVariantOverrides] = useLocalStorage(StorageKeys.VARIANT_OVERRIDES, {});

  const [{ [QueryParams.EXPERIMENT]: urlVariantOverrides }, setQueryParams] = useQueryParams({
    [QueryParams.EXPERIMENT]: withDefault(ArrayParam, new Array<string | null>()),
  });

  // parses experiments in the url, format is `?exp=EXP_NAME_1-control&exp=EXP_NAME_2-treatment`
  const parsedUrlVariantOverrides: VariantOverrides = useMemo(
    () =>
      fromPairs(
        urlVariantOverrides
          .filter(isString)
          .map((overridePair) => {
            const [expName, variantName] = overridePair.split('-');
            const maybeVariant = ActiveExperiments.find((exp) => exp.name === expName)?.variants.find(
              (variant) => variant.name === variantName
            );
            return [expName, maybeVariant];
          })
          .filter((pair): pair is ExperimentPair => !isUndefined(pair[1]))
      ),
    [urlVariantOverrides]
  );

  useEffect(() => {
    if (Object.keys(parsedUrlVariantOverrides).length > 0) {
      setVariantOverrides({ ...variantOverrides, ...parsedUrlVariantOverrides });
      setQueryParams({ [QueryParams.EXPERIMENT]: [] }, 'replaceIn');
    }
  }, [parsedUrlVariantOverrides, variantOverrides]);

  return [variantOverrides, setVariantOverrides];
};
