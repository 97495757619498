import { RootState } from 'src/store';

// selects either the cart or editableOrderCart root state
// many components work for either state and pass in the
// isEditingOrder flag optionally
export const selectCart = (state: RootState, isOrder?: boolean) => state[isOrder ? 'editableOrderCart' : 'cart'];

export * from './bulkDiscount';
export * from './bundles';
export * from './capacity';
export * from './deliveryDate';
export * from './lineItems';
export * from './orders';
export * from './shefs';
export * from './subtotals';
