function roundToNearest(num: number, bucketSize: number): number {
  return Math.floor(Math.ceil(num / bucketSize) * bucketSize);
}

export function roundAndScale([oldWidth, oldHeight]: readonly [number, number], bucketSize: number): [number, number] {
  let newWidth: number;
  let newHeight: number;

  if (oldWidth > oldHeight) {
    newWidth = roundToNearest(oldWidth, bucketSize);
    newHeight = Math.ceil(oldHeight * (newWidth / oldWidth));
  } else {
    newHeight = roundToNearest(oldHeight, bucketSize);
    newWidth = Math.ceil(oldWidth * (newHeight / oldHeight));
  }

  return [newWidth, newHeight];
}
