import { arrayToggle } from 'common/ArrayUtilities';
import { ArrayType } from 'common/TypeUtilities';
import { useCallback, useMemo } from 'react';
import { UpcomingOrderFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { DishFirstModalButton, DishFirstModalButtons } from 'src/pages/consumer/dish-first/DishFirstModal';
import styled from 'styled-components';
import { match } from 'ts-pattern';
import { Spacing } from 'web-common/src/shared/styles';
import { GroupOrderFoodItemRating } from '../../GroupOrderModal/GroupOrderFoodItemRating';
import { FoodItemRating, RatingPercent } from '../constants';
import { FoodItemRatingUpdate } from './constants';

const FoodTagButtons = styled(DishFirstModalButtons)`
  margin-top: ${Spacing.SINGLE};
`;

interface OrderRatingSectionFoodItemProps {
  foodItem: ArrayType<UpcomingOrderFieldsFragment['lineItems']>['foodItem'];
  foodItemRating: FoodItemRating | undefined;
  hasBeenRated: boolean;
  ignoreFoodTags: string[];
  negativeFoodTags: string[];
  onFoodItemRatingUpdate: (update: FoodItemRatingUpdate) => void;
  orderId: number;
  positiveFoodTags: string[];
}

/**
 * Component for rating an individual line item within an order.
 */
export const OrderRatingSectionFoodItem = ({
  foodItem,
  foodItemRating,
  hasBeenRated,
  ignoreFoodTags,
  negativeFoodTags,
  onFoodItemRatingUpdate,
  orderId,
  positiveFoodTags,
}: OrderRatingSectionFoodItemProps): JSX.Element => {
  const { id: foodItemId } = foodItem;

  const updateFoodItemRating = useCallback(
    (foodItemRatingUpdate: Pick<FoodItemRating, 'tags' | 'ratingPercent'> | null) => {
      const newFoodItemRating = foodItemRatingUpdate
        ? {
            ...foodItemRatingUpdate,
            foodItemId,
          }
        : null;

      onFoodItemRatingUpdate({
        orderId,
        foodItemId,
        foodItemRating: newFoodItemRating,
      });
    },
    [foodItemId, onFoodItemRatingUpdate, orderId]
  );

  /**
   * Handler for updating the food item rating.
   *
   * If the clicked rating is the same as the existing rating, remove the rating altogether.
   * Otherwise, set the rating as the clicked rating and reset the tags.
   */
  const handleRatingClick = useCallback(
    (ratingPercent: RatingPercent) => {
      match(foodItemRating?.ratingPercent)
        .with(ratingPercent, () => updateFoodItemRating(null))
        .otherwise(() =>
          updateFoodItemRating({
            ratingPercent,
            tags: [],
          })
        );
    },
    [foodItemRating?.ratingPercent, updateFoodItemRating]
  );

  /**
   * Handler for updating the food item tags.
   */
  const handleTagClick = useCallback(
    (tag: string) => {
      // User shouldn't be able to click on a tag without a rating selected,
      // so we don't actually expect this to happen.
      if (!foodItemRating) {
        throw new Error('You cannot add a dish tag before rating it');
      }

      const { ratingPercent, tags } = foodItemRating;

      updateFoodItemRating({
        ratingPercent,
        tags: arrayToggle(tags, tag),
      });
    },
    [foodItemRating, updateFoodItemRating]
  );

  const foodTagButtons = useMemo(() => {
    if (!foodItemRating) {
      return null;
    }

    const { ratingPercent, tags } = foodItemRating;
    const foodTags = match(ratingPercent)
      .with(0, () => negativeFoodTags)
      .with(100, () => positiveFoodTags)
      .with(null, () => ignoreFoodTags)
      .exhaustive()
      .filter((foodTag) => (hasBeenRated ? tags.includes(foodTag) : true));

    if (foodTags.length === 0) return null;

    return (
      <FoodTagButtons>
        {foodTags.map((tag) => (
          <DishFirstModalButton key={tag} onClick={() => handleTagClick(tag)} selected={tags.includes(tag)}>
            {tag}
          </DishFirstModalButton>
        ))}
      </FoodTagButtons>
    );
  }, [hasBeenRated, foodItemRating, handleTagClick, ignoreFoodTags, negativeFoodTags, positiveFoodTags]);

  return (
    <GroupOrderFoodItemRating
      foodItem={foodItem}
      onRatingClick={handleRatingClick}
      selectedRatingPercent={foodItemRating?.ratingPercent}>
      {foodTagButtons}
    </GroupOrderFoodItemRating>
  );
};
