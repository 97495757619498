import { FaExclamationCircleOutline } from 'src/shared/design-system/FontAwesome';

export const AllergyWarning: React.FC = () => (
  <div className='mt-4 flex flex-row items-start rounded bg-neutral-50 p-2 text-neutral-700'>
    <FaExclamationCircleOutline />
    <span className='ml-2 text-xs font-semibold'>
      Please note that dishes may contain — or may be processed in a facility that contains — allergens such as milk,
      peanuts, tree nuts, wheat, dairy, eggs, fish, shellfish, soy, or sesame.
    </span>
  </div>
);
