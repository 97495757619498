import { css } from 'styled-components';

/**
 * Hides the Scrollbar
 */
export const hide = () => css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }
`;
