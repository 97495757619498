export type ResultRef = {
  __ref: string;
};

export interface CuisineResult {
  category: ResultRef;
}

export interface DishResult extends ResultRef {}

export type DishResults = [DishResult[], DishResult[], DishResult[], DishResult[]];

export interface ShefResult extends ResultRef {}

export const getRef = (ref: ResultRef) => ref.__ref;

export const mergeCuisineResults = (existing: CuisineResult[], incoming: CuisineResult[]) => {
  const existingResultsRef = new Set(existing.map((cuisineResult) => getRef(cuisineResult.category)));
  const newResultsToAdd = incoming.filter((cuisineResult) => !existingResultsRef.has(getRef(cuisineResult.category)));
  return [...existing, ...newResultsToAdd];
};

export const mergeShefResults = (existing: ShefResult[], incoming: ShefResult[]) => {
  const existingResultsRef = new Set(existing.map(getRef));
  const newResultsToAdd = incoming.filter((shefResult) => !existingResultsRef.has(getRef(shefResult)));
  return [...existing, ...newResultsToAdd];
};

export const mergeDishResults = (existing: DishResults, incoming: DishResults) => {
  const [firstExisting = [], secondExisting = [], thirdExisting = [], fourthExisting = []] = existing;
  const [first = [], second = [], third = [], fourth = []] = incoming;

  const existingFirstResultsRef = new Set(firstExisting.map(getRef));
  const existingSecondResultsRef = new Set(secondExisting.map(getRef));
  const existingThirdResultsRef = new Set(thirdExisting.map(getRef));
  const existingFourthResultsRef = new Set(fourthExisting.map(getRef));

  const firstResultsToAdd = first.filter((dishResult) => !existingFirstResultsRef.has(getRef(dishResult)));
  const secondResultsToAdd = second.filter((dishResult) => !existingSecondResultsRef.has(getRef(dishResult)));
  const thirdResultsToAdd = third.filter((dishResult) => !existingThirdResultsRef.has(getRef(dishResult)));
  const fourthResultsToAdd = fourth.filter((dishResult) => !existingFourthResultsRef.has(getRef(dishResult)));

  return [
    [...firstExisting, ...firstResultsToAdd],
    [...secondExisting, ...secondResultsToAdd],
    [...thirdExisting, ...thirdResultsToAdd],
    [...fourthExisting, ...fourthResultsToAdd],
  ];
};
