import { useSearchDataProviderProps } from './hooks/useSearchDataProviderProps';
import { SearchDataContext } from './SearchDataContext';

export const SearchDataProvider = ({ children }) => {
  const {
    activeZipCode,
    data,
    dataCombinedSearch,
    error,
    loading,
    isMoreResultsLoading,
    isSearchHidden,
    hasMoreResults,
    handleQueryChange,
    handleShowMoreShefs,
    handleShowMoreDishes,
    handleClearSearch,
    refreshSearch,
  } = useSearchDataProviderProps();

  return (
    <SearchDataContext.Provider
      value={{
        activeZipCode,
        data,
        dataCombinedSearch,
        error,
        loading,
        isMoreResultsLoading,
        isSearchHidden,
        hasMoreResults,
        handleQueryChange,
        handleShowMoreShefs,
        handleShowMoreDishes,
        handleClearSearch,
        refreshSearch,
      }}>
      {children}
    </SearchDataContext.Provider>
  );
};
