import { noop } from 'lodash';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'src/shared/hooks/useLocation';
import { useSelector } from 'src/store';
import { cartSlice } from 'src/store/cart';
import { CartActionLocation } from 'src/store/cart/types';

interface CartPositionState {
  open: () => void;
  close: () => void;
  toggle: () => void;
  isOpen: boolean;

  // Boolean indicating whether or not the cart is mounted on the page
  isCartMounted: boolean;

  // Function to be called in a useEffect within the cart component.
  // This provides us with the ability to reference `isCartMounted` across the rest of the app
  // to understand if the page that we're on can show the cart component.
  onCartMount: () => void;
}

const defaultState: CartPositionState = {
  open: noop,
  close: noop,
  toggle: noop,
  isOpen: false,
  isCartMounted: false,
  onCartMount: noop,
};

const DishFistCartPositionContext = createContext<CartPositionState>(defaultState);

const DENY_CART_AUTO_OPEN_LOCATIONS = new Set<CartActionLocation | undefined>(['dish-first-modal-more-items']);

const useAutoOpenCart = (openCart: () => void) => {
  // auto opens the cart when items have increased OR delivery date and cart
  // content have been changed
  const lastLineItemAction = useSelector(({ cart }) => cart.lastLineItemAction);
  const deliveryDate = useSelector(({ cart }) => cart.deliveryDate);

  // keep track of what values were before to compare to current versions
  const [prevLastLineItemAction, setPrevLastLineItemAction] = useState(lastLineItemAction);
  const [prevDeliveryDate, setPrevDeliveryDate] = useState(deliveryDate);

  useEffect(() => {
    const isNewDeliveryDate = prevDeliveryDate !== deliveryDate;
    const isNewLastLineItemAction = lastLineItemAction !== prevLastLineItemAction;

    const defaultNumAdded =
      lastLineItemAction && cartSlice.actions.incLineItemQuantity.match(lastLineItemAction) ? 1 : 0;
    const numAddedInLastAction = lastLineItemAction?.payload?.quantity ?? defaultNumAdded;
    const hasAddedInLocationThatShouldAutoOpen =
      numAddedInLastAction > 0 && !DENY_CART_AUTO_OPEN_LOCATIONS.has(lastLineItemAction?.payload?.location);

    const shouldAutoOpen = isNewLastLineItemAction && (hasAddedInLocationThatShouldAutoOpen || isNewDeliveryDate);

    if (shouldAutoOpen) openCart();
    if (isNewDeliveryDate) setPrevDeliveryDate(deliveryDate);
    if (isNewLastLineItemAction) setPrevLastLineItemAction(lastLineItemAction);
  }, [openCart, deliveryDate, prevDeliveryDate, lastLineItemAction, prevLastLineItemAction]);
};

const DishFirstCartPositionProvider = ({ children }: { children: React.ReactNode }) => {
  // Page State
  const location = useLocation();
  const [isCartMounted, setIsCartMounted] = useState(false);

  // Open State
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (location.state?.autoCloseCart ?? true) {
      setIsOpen(false);
    }
  }, [location]);

  useAutoOpenCart(() => setIsOpen(true));

  return (
    <DishFistCartPositionContext.Provider
      value={{
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        toggle: () => setIsOpen(!isOpen),
        isCartMounted,
        onCartMount: () => {
          setIsCartMounted(true);
          return () => setIsCartMounted(false);
        },
      }}>
      {children}
    </DishFistCartPositionContext.Provider>
  );
};

const useDishFistCartPositionContext = () => {
  const context = useContext(DishFistCartPositionContext);
  if (context === undefined) {
    throw new Error('useDishFistCartPositionContext must be used within a DishFistCartPositionContext');
  }
  return context;
};

export { DishFirstCartPositionProvider, useDishFistCartPositionContext };
