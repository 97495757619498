/** Modified from https://github.com/marvelapp/react-ab-test/blob/master/src/debugger.jsx * */

import { UserIdentifierType } from 'common/analytics-event-manager/IAnalyticsEventManager';
import { Time } from 'common/Constants';
import { valueToEnum } from 'common/EnumUtils';
import { ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { CONTROL_VARIANT } from 'common/experiments/ExperimentManager';
import { IExperiment, IVariant } from 'common/experiments/IExperimentManager';
import React, { useContext, useEffect, useState } from 'react';
import { CloseV2 } from 'src/shared/design-system/Icon';
import { Toggle } from 'src/shared/design-system/Toggle';
import { VerticallyCenteredText } from 'src/shared/design-system/VerticallyCenteredText';
import { BUILD_TIMESTAMP } from 'src/shared/hooks/useApiSyncHook';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { isDevEnvironment } from 'src/shared/utils/EnvironmentUtilities';
import styled, { css } from 'styled-components';
import { Border, Colors, Font, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';
import { ExperimentContext } from './ExperimentContext';

export const DebuggerContainer = styled.div`
  bottom: 0;
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  padding: 0;
  position: fixed;
  transform: translateX(-50%);
  z-index: 25000;
`;

export const Tab = styled(DebuggerContainer)`
  background: rgba(240, 240, 240, 0.7);
  border: 1px solid ${Colors.GRAY};
  border-bottom: 0;
  cursor: pointer;
  padding: 1px ${Spacing.THREE_QUARTERS};
  transition: padding ${Transition.Time.BASE}, border-radius ${Transition.Time.BASE};
  backdrop-filter: blur(${Spacing.QUARTER});
  ${Font.bySize(Font.Size.XXS)}
  ${Mixins.Border.setTopSide(Border.Radius.M)}

  &:hover {
    padding: ${Spacing.HALF} ${Spacing.DOUBLE};
    ${Mixins.Border.setTopSide(Border.Radius.L)}
  }
`;

export const Container = styled(DebuggerContainer)`
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(${Spacing.QUARTER});
  box-shadow: 0 0 16px ${Colors.GRAY_DARK_30};
  ${Mixins.Border.setTopSide(Border.Radius.L)}

  ${Mixins.Media.maxWidthSmall(css`
    max-width: 400px;
    width: calc(100vw - ${Spacing.SINGLE});
    background: rgba(255, 255, 255, 0.94);
  `)}
`;

export const CloseButton = styled.button`
  background: ${Colors.RADISH};
  border: 2px solid ${Colors.WHITE};
  box-sizing: border-box;
  color: ${Colors.WHITE};
  cursor: pointer;
  outline: none;
  position: absolute;
  padding: 0;
  margin: 0;
  top: -${Spacing.THREE_QUARTERS};
  right: -${Spacing.THREE_QUARTERS};
  box-shadow: 0 0 4px ${Colors.GRAY_DARK_20};
  z-index: 100;
  ${Mixins.Shape.circle(Spacing.DOUBLE)}
  ${Mixins.Flex.center()}
`;

export const Experiments = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 104px);
`;

export const ExperimentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${Spacing.HALF} ${Spacing.THREE_QUARTERS};
  gap: ${Spacing.SINGLE};
  transition: background ${Transition.Time.BASE};

  &:not(:first-child) {
    border-top: 1px solid ${Colors.GRAY_LITE_10};
  }

  & button {
    font-size: ${Font.Size.XS};
  }
  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }
  &:first-child {
    ${Mixins.Border.setTopSide(Border.Radius.L)}
  }

  ${Mixins.Media.maxWidthSmall(css`
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${Spacing.QUARTER};
    padding: ${Spacing.THREE_QUARTERS};
    & button {
      font-size: ${Font.Size.XS};
    }
    &:hover {
      background: transparent;
    }
  `)}
`;

export const ExperimentName = styled.div<{ isControl?: boolean }>`
  word-break: break-all;
  transition: color ${Transition.Time.BASE};
  ${Font.bySize(Font.Size.XS, Font.Weight.SEMI_BOLD)}
  ${Mixins.Text.overflow(2)}
  ${({ isControl }) => css`
    color: ${isControl ? Colors.GRAY_DARK_10 : Colors.GRAY_DARK_30};
  `}
`;

export const ToggleContainer = styled.div`
  min-width: 180px;
  ${Mixins.Media.maxWidthSmall(css`
    margin-left: auto;
  `)}
`;

export const BuildNote = styled.div`
  border-top: 1px solid ${Colors.GRAY_LITE_10};
  padding: ${Spacing.THREE_QUARTERS};
  ${Font.bySize(Font.Size.XXS)}
  ${Mixins.Flex.center()}
`;

export const Debugger: React.FC = () => {
  const { getVariant, getAllExperiments, setVariantOverride } = useContext(ExperimentContext);
  const [visible, setVisible] = useState<boolean>(false);
  const [experimentState, setExperimentState] = useState<
    {
      experiment: IExperiment;
      activeVariant: IVariant | null;
    }[]
  >([]);
  const toggleVisibility = () => {
    setVisible(!visible);
  };

  const [currentUser] = useCurrentUser();

  const allExperiments = getAllExperiments();
  useEffect(() => {
    async function getActiveVariants() {
      return Promise.all(
        allExperiments
          .filter((experiment) => experiment.userIdentifierType === UserIdentifierType.DEVICE_ID || currentUser)
          .map(async (experiment) => {
            const activeVariant = await getVariant(valueToEnum(ExperimentNames, experiment.name), false);
            return {
              experiment,
              activeVariant,
            };
          })
      );
    }

    let mounted = true;
    getActiveVariants()
      .then((experimentState) => mounted && setExperimentState(experimentState))
      .catch(console.error);

    return () => {
      mounted = false;
    };
  }, [allExperiments, currentUser, getVariant]);

  if (visible) {
    return (
      <Container>
        <CloseButton onClick={toggleVisibility}>
          <CloseV2 />
        </CloseButton>
        <Experiments>
          {experimentState.map(({ experiment, activeVariant }) => {
            if (experiment.variants.length === 0) return null;
            return (
              <ExperimentContainer key={experiment.name}>
                <ExperimentName isControl={activeVariant?.name === CONTROL_VARIANT}>{experiment.name}</ExperimentName>
                <ToggleContainer>
                  <Toggle.Bar
                    height={28}
                    padding={4}
                    options={experiment.variants.map(({ name }) => ({ label: name, value: name }))}
                    onChange={(variantName) =>
                      setVariantOverride(valueToEnum(ExperimentNames, experiment.name), variantName)
                    }
                    value={activeVariant?.name ?? CONTROL_VARIANT}
                  />
                </ToggleContainer>
              </ExperimentContainer>
            );
          })}
        </Experiments>
        <BuildNote>This panel is hidden on production builds.</BuildNote>
        {!isDevEnvironment() && BUILD_TIMESTAMP && (
          <BuildNote>{new Date(+BUILD_TIMESTAMP * Time.ONE_SECOND_IN_MS).toString()}</BuildNote>
        )}
      </Container>
    );
  }
  if (experimentState.length > 0) {
    return (
      <Tab onClick={toggleVisibility}>
        <VerticallyCenteredText>
          {experimentState.length} Active Experiment
          {experimentState.length > 1 ? 's' : ''}
        </VerticallyCenteredText>
      </Tab>
    );
  }
  return null;
};
