import { DockedScreenSize, NavbarState, NavLocation } from './types';

export const initialNavbarState: NavbarState = {
  [NavLocation.ANIMATIONS]: true,
  [NavLocation.BACK_BUTTON]: false,
  [NavLocation.CUISINE_FILTERS]: false,
  [NavLocation.CUISINE_FILTER_BUTTON]: false,
  [NavLocation.DATE_PICKER]: false,
  [NavLocation.DATE_PICKER_BUTTON]: false,
  [NavLocation.DIETARY_FILTERS]: false,
  [NavLocation.PROTEIN_FILTERS]: false,
  [NavLocation.INLINE_FILTER_BUTTONS]: false,
  [NavLocation.FILTER_BAR]: false,
  [NavLocation.MENU_BAR]: false,
  [NavLocation.MORE_FILTERS]: false,
  [NavLocation.NAVIGATION]: false,
  [NavLocation.PROMO]: false,
  [NavLocation.PROMO_EXPANDED]: false,
  [NavLocation.SEARCH]: false,
  [NavLocation.SUB_CUISINE_FILTERS]: false,
  [DockedScreenSize.DESKTOP]: [],
  [DockedScreenSize.TABLET]: [],
  [DockedScreenSize.MOBILE]: [],
  isContentShowing: false,
  isScrollingUp: false,
};

export const useInitialDishFirstExploreState = (): NavbarState => ({ ...initialNavbarState });
