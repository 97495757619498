import { useEffect, useRef } from 'react';
import { addScrollableTarget, disablePageScroll, enablePageScroll, removeScrollableTarget } from 'scroll-lock';

interface UseScrollLockParams {
  enabled?: boolean;
  scrollableTargetRef?: React.RefObject<HTMLElement>;
}

/**
 * Block page scroll on mount.
 *
 * @param enabled - Whether scroll should be blocked on mount (defaults to true).
 * @param scrollableTargetRef - Element to enable scrolling for within the page.
 *                              Scrolling will be enabled for the target and all nested children.
 */
export const useScrollLock = ({ scrollableTargetRef, enabled = true }: UseScrollLockParams) => {
  const locked = useRef(false);

  useEffect(() => {
    const scrollableTarget = scrollableTargetRef?.current ?? undefined;
    const nestedScrollableTargets = scrollableTarget?.querySelectorAll('*') ?? [];

    if (enabled && !locked.current) {
      addScrollableTarget(nestedScrollableTargets);
      disablePageScroll(scrollableTarget);
      locked.current = true;
    }

    return () => {
      if (locked.current) {
        enablePageScroll(scrollableTarget);
        removeScrollableTarget(nestedScrollableTargets);
        locked.current = false;
      }
    };
  }, [enabled, scrollableTargetRef]);
};
