import { noOp } from 'common/Constants';
import { isUndefined } from 'lodash';
import { useState } from 'react';
import { ToggleBarProps } from '../types';

export const useBar = <T, K extends keyof T>(props: ToggleBarProps<T, K>) => {
  const { onChange = noOp, height, options, padding, value, isNullValid, ...otherProps } = props;

  const selectedIndex = Math.max(
    options.findIndex((option) => option.value === value),
    0
  );
  const hasValue = isNullValid ? !isUndefined(value) : !!value;
  const numberOfOptions = options.length;
  const [clickedIndex, setClickedIndex] = useState(selectedIndex);

  return {
    buttonHighlightProps: {
      clickedIndex,
      hasValue,
      height,
      numberOfOptions,
      padding,
      selectedIndex,
    },
    clickedIndex,
    hasValue,
    height,
    numberOfOptions,
    options,
    padding,
    onChange,
    setClickedIndex,
    selectedIndex,
    value,
    ...otherProps,
  };
};
