// Definitions: https://docs.google.com/spreadsheets/d/19ZwqUioSwwZVEg00C0dRdfygsXUswAzs96LYxYz5gEI
export enum UtmQueryParams {
  SOURCE = 'utm_source',
  MEDIUM = 'utm_medium',
  CAMPAIGN = 'utm_campaign',
  TERM = 'utm_term',
  CONTENT = 'utm_content',
  TARGET = 'utm_target',
  CUISINE = 'utm_cuisine',
}

// Any time a source is our own shef.com
export const SHEF_SOURCE = 'shef';
