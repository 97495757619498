/**
 * Enum represents all of the services that makes requests to our graphql server.
 * We use this to differentiate where requests are coming from for analytics purposes.
 */
export enum GqlRequestSource {
  SHEF_API = 'shef-api',
  CONSUMER_APP = 'consumer-app',
  RETOOL = 'retool',
  STORYBOOK = 'storybook',
  SHEF_WEB = 'shef-web',
  SHEF_NEXT = 'shef-next',
}
