import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Colors } from 'web-common/src/shared/styles';

export const TextLink = styled(Link)`
  color: ${Colors.RADISH};
  text-decoration: none;

  :link,
  :visited,
  :hover,
  :active {
    color: ${Colors.RADISH};
    text-decoration: none;
  }
`;
