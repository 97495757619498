export const IMAGE_SIZE = {
  height: 4,
  width: '100%',
  preserveAspectRatio: 'none',
} as const;

export const WAVE_SIZE = {
  height: 6,
  width: 30,
  viewBox: '0 0 120 18',
};
