import React, { useCallback, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { AppButton } from 'src/shared/design-system/AppButtonDeprecated';
import { DotIndicator } from 'src/shared/design-system/DotIndicator';
import styled from 'styled-components';
import { Colors, Font } from 'web-common/src/shared/styles';
import { StepByStep } from './StepByStep';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface IProps {
  onComplete: () => void;
  initialStep?: number;
  buttonText?: string;
  buttonTextLastStep?: string;
}

const Button = styled(AppButton)`
  padding: 15.5px 40px;
  margin-top: 0 !important;
  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  background-color: ${Colors.RADISH};
`;

export const StepByStepWithDotIndicator: React.FC<React.PropsWithChildren<IProps>> = ({
  children,
  onComplete,
  buttonText = 'Next',
  buttonTextLastStep = 'Get Started',
  initialStep = 0,
}) => {
  const [step, setStep] = useState(initialStep);
  const numSteps = React.Children.count(children);

  const incrementStep = useCallback(() => setStep((step) => Math.min(step + 1, numSteps - 1)), [numSteps]);
  const decrementStep = useCallback(() => setStep((step) => Math.max(step - 1, 0)), []);
  const isOnLastStep = step === numSteps - 1;

  const swipeHandlers = useSwipeable({
    onSwipedRight: decrementStep,
    onSwipedLeft: incrementStep,
  });

  const handleButtonClick = useCallback(() => {
    if (isOnLastStep) {
      onComplete();
    } else {
      incrementStep();
    }
  }, [incrementStep, isOnLastStep, onComplete]);

  return (
    <div {...swipeHandlers}>
      <StepByStep activeStep={step}>{children}</StepByStep>
      <Footer>
        <DotIndicator activeIdx={step} total={numSteps} onClick={setStep} />
        <Button title={isOnLastStep ? buttonTextLastStep : buttonText} onClick={handleButtonClick} />
      </Footer>
    </div>
  );
};
