import React from 'react';
import { CheckmarkV2 } from 'src/shared/design-system/Icon';
import styled from 'styled-components';
import { ifNotProp } from 'web-common/src/shared/styled-utils/ifNotProp';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';

const StyledLabel = styled.label<{ checked?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  background: ${({ checked, disabled }) => (!disabled && checked ? Colors.MINT_50 : Colors.WHITE)};
  border: 1px solid;
  border-color: ${({ checked, disabled }) => (!disabled && checked ? Colors.MINT_500 : Colors.GRAY_LITE_20)};
  border-radius: ${Spacing.HALF};
  font-size: ${Font.Size.S};
  font-weight: ${Font.Weight.MEDIUM};
  color: ${Colors.GRAY_DARK_30};

  min-height: ${Spacing.QUADRUPLE};
  padding-right: ${Spacing.SINGLE};

  cursor: ${ifNotProp('disabled', 'pointer')};
`;

type FieldsetInputProps = React.InputHTMLAttributes<HTMLInputElement> & { type: 'radio' | 'checkbox' };

const StyledCheckbox = styled.span<{ type: string; checked?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  height: ${Spacing.SINGLE_HALF};
  width: ${Spacing.SINGLE_HALF};
  margin-left: ${Spacing.SINGLE};
  box-sizing: border-box;

  border: ${({ checked, disabled }) => (disabled || checked ? 'none' : `1px solid ${Colors.GRAY_DARK_10}`)};
  border-radius: ${({ type }) => (type === 'checkbox' ? Spacing.QUARTER : Spacing.THREE_QUARTERS)};
  background: ${({ checked, disabled }) => (!disabled && checked ? Colors.MINT_600 : Colors.WHITE)};
`;

const StyledCheckmark = styled(CheckmarkV2)<{ checked?: boolean; disabled?: boolean }>`
  display: ${({ checked, disabled }) => (disabled || !checked ? 'none' : undefined)};
  width: ${Spacing.THREE_QUARTERS};

  color: ${Colors.WHITE};
`;

const ChildrenContainer = styled.div<{ disabled?: boolean }>`
  flex-grow: 1;
  align-self: stretch;
  opacity: ${ifProp('disabled', '0.5')};
`;

export const FieldsetInput: React.FC<React.PropsWithChildren<FieldsetInputProps>> = ({
  children,
  checked,
  type,
  disabled,
  ...otherProps
}) => (
  <StyledLabel checked={checked} disabled={disabled}>
    <ChildrenContainer disabled={disabled}>{children}</ChildrenContainer>
    <input {...otherProps} checked={checked} type={type} disabled={disabled} className='hidden' />
    <StyledCheckbox checked={checked} type={type} disabled={disabled}>
      <StyledCheckmark checked={checked} />
    </StyledCheckbox>
  </StyledLabel>
);
