import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { Colors, Spacing } from 'web-common/src/shared/styles';

export const Container = styled.div<{ fluid?: boolean }>`
  position: relative;
  ${ifProp(
    'fluid',
    css`
      display: flex;
    `
  )}
`;

export const HiddenInput = styled.textarea`
  top: 0;
  left: 0;
  position: absolute;
  pointer-events: none !important;
  opacity: 0 !important;
  max-height: ${Spacing.SINGLE} !important;
`;

export const TextCountContainer = styled.div<{ isMaxReached: boolean }>`
  color: ${ifProp('isMaxReached', Colors.RADISH, Colors.GRAY_DARK_10)};
`;
