import { css, FlattenInterpolation, ThemeProps } from 'styled-components';
import { ScreenWidth } from '../screenWidth';

export type StyleType = FlattenInterpolation<ThemeProps<unknown>> | string;

/**
 * Adds styles given a min-width media screen size breakpoint.
 * @param size: The min screen size.
 * @param styles: The styles for this screen size.
 */
export const minWidth = (size: string, styles: StyleType) => css`
  @media (min-width: ${size}) {
    ${styles}
  }
`;

/**
 * Adds styles given a max-width media screen size breakpoint.
 * @param size: The max screen size.
 * @param styles: The styles for this screen size.
 */
export const maxWidth = (size: string, styles: StyleType) => css`
  @media (max-width: ${size}) {
    ${styles}
  }
`;

/** Styles for the extra small min-width breakpoint. (576px) */
export const minWidthExtraSmall = (styles: StyleType) => minWidth(ScreenWidth.EXTRA_SMALL, styles);
/** Styles for the small min-width breakpoint. (768px) */
export const minWidthSmall = (styles: StyleType) => minWidth(ScreenWidth.SMALL, styles);
/** Styles for the medium min-width breakpoint. (992px) */
export const minWidthMedium = (styles: StyleType) => minWidth(ScreenWidth.MEDIUM, styles);
/** Styles for the large min-width breakpoint. (1200px) */
export const minWidthLarge = (styles: StyleType) => minWidth(ScreenWidth.LARGE, styles);
/** Styles for the extra large min-width breakpoint. (2400px) */
export const minWidthExtraLarge = (styles: StyleType) => minWidth(ScreenWidth.EXTRA_LARGE, styles);

/** Styles for the extra small max-width breakpoint. (576px) */
export const maxWidthExtraSmall = (styles: StyleType) => maxWidth(ScreenWidth.EXTRA_SMALL, styles);
/** Styles for the small max-width breakpoint. (768px) */
export const maxWidthSmall = (styles: StyleType) => maxWidth(ScreenWidth.SMALL, styles);
/** Styles for the medium max-width breakpoint. (992px) */
export const maxWidthMedium = (styles: StyleType) => maxWidth(ScreenWidth.MEDIUM, styles);
/** Styles for the large max-width breakpoint. (1200px) */
export const maxWidthLarge = (styles: StyleType) => maxWidth(ScreenWidth.LARGE, styles);
/** Styles for the extra large max-width breakpoint. (2400px) */
export const maxWidthExtraLarge = (styles: StyleType) => maxWidth(ScreenWidth.EXTRA_LARGE, styles);
