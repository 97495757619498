import { isDefined } from 'common/TypeUtilities';
import { currentUrlDate } from 'common/UrlDate';
import * as t from 'io-ts';
import { map, omit, orderBy, sortedUniq, uniqBy } from 'lodash';
import {
  CartAddOnItemV2,
  CartFoodItemV2,
  CartFoodItemV3,
  CartStateV1,
  CartStateV10,
  CartStateV11,
  CartStateV12,
  CartStateV13,
  CartStateV14,
  CartStateV15,
  CartStateV16,
  CartStateV17,
  CartStateV18,
  CartStateV19,
  CartStateV2,
  CartStateV20,
  CartStateV3,
  CartStateV4,
  CartStateV5,
  CartStateV6,
  CartStateV7,
  CartStateV8,
  CartStateV9,
} from 'src/store/cart/types';
import { IFirstStateVersion, INextStateVersion, IStateVersions } from 'src/store/storage';
import { getEntityList, toEntity } from 'src/store/utils';

const v1Cart: IFirstStateVersion = {
  version: 1 as const,
  codec: t.unknown,
};

const v2Cart: INextStateVersion<CartStateV1, CartStateV2> = {
  version: 2,
  codec: t.unknown,
  migration: (cartV1) => {
    // Delivery Date Migration

    const currentDate = currentUrlDate();

    const shefs = Object.values(cartV1.shefs.entities).filter(isDefined);
    const shefDeliveryDates = sortedUniq(map(shefs, 'deliveryDate').sort());

    const shefFutureDeliveryDate = shefDeliveryDates.find((date) => date > currentDate);
    const mostRecentDeliveryDate = shefDeliveryDates[shefDeliveryDates.length - 1] || undefined;

    // try to find the next future available delivery date from any data we already have
    // 1. default to using values already in the cart (shefs)
    // 2. if there's already values in the cart, we'll want to kick off the expired cart flow
    // 3. if no items, let's default to the current date
    // in the case of 2 & 3, the stale cart flow will prompt the user to update if they have
    // items in the cart, or automatically be updated to the delivery date of the first food
    // item that gets added to the cart
    const deliveryDate = shefFutureDeliveryDate || mostRecentDeliveryDate || currentDate;

    // Add On Item Migration

    const v1AddOnItems = Object.values(cartV1.addOnItems.entities).filter(isDefined);
    // sort in desc order by
    const sortV1AddOnItems = orderBy(v1AddOnItems, 'quantity', 'desc');
    const v2AddOnItems = uniqBy(sortV1AddOnItems, 'addOnItemId')
      .map((v1AddOnItems) => {
        const item = omit(v1AddOnItems, ['shefId', 'addOnItemId']);

        // if we can't parse id for whatever reason, just remove from the state
        const [idString] = item.id.split('-');
        const id = parseInt(idString, 10);
        if (Number.isNaN(id)) return undefined;

        const v2Item: CartAddOnItemV2 = { ...item, id };
        return v2Item;
      })
      .filter(isDefined);

    return { ...cartV1, deliveryDate, addOnItems: toEntity(v2AddOnItems) };
  },
};

const v3Cart: INextStateVersion<CartStateV2, CartStateV3> = {
  version: 3,
  codec: t.unknown,
  migration: (cartV2) => ({
    ...cartV2,
    zipCode: '',
    possibleDeliveryDates: [],
    requireUserAcknowledgeDeliveryDateUpdate: false,
    queuedActions: [],
    rawLoadedDishFirstFoodItems: {},
  }),
};

const v4Cart: INextStateVersion<CartStateV3, CartStateV4> = {
  version: 4,
  codec: t.unknown,
  migration: (cartV3) => ({
    ...omit(cartV3, 'isStandardDeliveryZipCode'),
    isMultiCartFeatureEnabled: false,
  }),
};

const v5Cart: INextStateVersion<CartStateV4, CartStateV5> = {
  version: 5,
  codec: t.unknown,
  migration: (cartV4) => omit(cartV4, 'rawLoadedDishFirstFoodItems'),
};

const v6Cart: INextStateVersion<CartStateV5, CartStateV6> = {
  version: 6,
  codec: t.unknown,
  migration: (cartV5) => ({
    ...cartV5,
    minimumFreeShippingAmount: 2500,
  }),
};

const v7Cart: INextStateVersion<CartStateV6, CartStateV7> = {
  version: 7,
  codec: t.unknown,
  migration: (cartV6) => ({
    ...cartV6,
    zipCodeTimeZone: '',
  }),
};

const v8Cart: INextStateVersion<CartStateV7, CartStateV8> = {
  version: 8,
  codec: t.unknown,
  migration: (cartV7) => omit(cartV7, ['zipCodeTimeZone', 'minimumFreeShippingAmount']),
};

const v9Cart: INextStateVersion<CartStateV8, CartStateV9> = {
  version: 9,
  codec: t.unknown,
  migration: (cartV8) => ({ ...cartV8, lastLineItemAction: undefined }),
};

const v10Cart: INextStateVersion<CartStateV9, CartStateV10> = {
  version: 10,
  codec: t.unknown,
  migration: (cartV9) => ({ ...cartV9, skipTomorrowDefaultDate: false }),
};

// adding created at / updated at times -- server should have it's
// own values, so these will get overriden shortly after this
const v11Cart: INextStateVersion<CartStateV10, CartStateV11> = {
  version: 11,
  codec: t.unknown,
  migration: (cartV10): CartStateV11 => {
    const now = new Date().toISOString();
    const newLineItems = getEntityList(cartV10.lineItems).map((li) => ({
      ...li,
      createdAt: now,
      updatedAt: now,
    }));

    return {
      ...cartV10,
      lineItems: toEntity(newLineItems),
    };
  },
};

const v12Cart: INextStateVersion<CartStateV11, CartStateV12> = {
  version: 12,
  codec: t.unknown,
  migration: (cartV11): CartStateV12 => ({
    ...cartV11,
    includeSupplyVarietyShefs: false,
  }),
};

const v13Cart: INextStateVersion<CartStateV12, CartStateV13> = {
  version: 13,
  codec: t.unknown,
  migration: (cartV12): CartStateV13 => omit(cartV12, 'includeSupplyVarietyShefs'),
};

const v14Cart: INextStateVersion<CartStateV13, CartStateV14> = {
  version: 14,
  codec: t.unknown,
  migration: (cartV13): CartStateV14 => {
    const newLineItems = getEntityList(cartV13.lineItems).map((li) => ({
      ...li,
      customizationIds: [],
    }));

    return {
      ...cartV13,
      lineItems: toEntity(newLineItems),
    };
  },
};

const v15Cart: INextStateVersion<CartStateV14, CartStateV15> = {
  version: 15,
  codec: t.unknown,
  migration: (cartV14) => ({ ...cartV14, bulkDiscountTiers: [] }),
};

const v16Cart: INextStateVersion<CartStateV15, CartStateV16> = {
  version: 16,
  codec: t.unknown,
  migration: (cartV15) => ({ ...cartV15, discountApplied: 0, promoApplied: 0 }),
};

const v17Cart: INextStateVersion<CartStateV16, CartStateV17> = {
  version: 17,
  codec: t.unknown,
  migration: (cartV16) => {
    // eslint-disable-next-line prefer-const
    let { foodItems, foodItemsAvailabilities, lineItems, ...cart } = cartV16;

    const v1FoodItems = getEntityList(foodItems);

    const missingAvailabilityIds: Set<number> = new Set();

    const v2FoodItems = v1FoodItems
      .map<CartFoodItemV2 | undefined>((v1FoodItem) => {
        const availabilityData = foodItemsAvailabilities.entities[v1FoodItem.id];

        // shouldn't happen, but just in case record that we have so missing data and remove the related item
        if (!availabilityData) {
          missingAvailabilityIds.add(v1FoodItem.id);
          return undefined;
        }

        return {
          ...v1FoodItem,
          availability: Object.entries(availabilityData.availabilities).map(([availabilityDate, numAvailable]) => ({
            availabilityDate,
            numAvailable,
          })),
        };
      })
      .filter(isDefined);

    // force as early of gc collection ass possible for large state that's been accruing on the app
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (foodItemsAvailabilities as any) = null;

    // shouldn't happen, but remove the related line item for the missing food item
    const validLineItems = getEntityList(lineItems).filter(({ id }) => !missingAvailabilityIds.has(id));

    return {
      ...cart,
      lineItems: toEntity(validLineItems),
      foodItems: toEntity(v2FoodItems),
    };
  },
};

const v18Cart: INextStateVersion<CartStateV17, CartStateV18> = {
  version: 18,
  codec: t.unknown,
  migration: (cartV17) => ({
    ...cartV17,
    foodItems: toEntity(
      // zero value will get corrected once we load values from the server again, which happens on page load
      getEntityList(cartV17.foodItems).map<CartFoodItemV3>((fi) => ({
        ...fi,
        discountForTakeRateShiftExperiment: 0,
      }))
    ),
    promoCode: undefined,
  }),
};

const v19Cart: INextStateVersion<CartStateV18, CartStateV19> = {
  version: 19,
  codec: t.unknown,
  migration: (cartV18) => ({
    ...cartV18,
    regionId: undefined,
  }),
};

const v20Cart: INextStateVersion<CartStateV19, CartStateV20> = {
  version: 19,
  codec: t.unknown,
  migration: (cartV19) => ({
    ...cartV19,
    discountForTakeRateShiftExperiment: undefined,
  }),
};

export const versions: IStateVersions = [
  v1Cart,
  v2Cart,
  v3Cart,
  v4Cart,
  v5Cart,
  v6Cart,
  v7Cart,
  v8Cart,
  v9Cart,
  v10Cart,
  v11Cart,
  v12Cart,
  v13Cart,
  v14Cart,
  v15Cart,
  v16Cart,
  v17Cart,
  v18Cart,
  v19Cart,
  v20Cart,
];
