import { ClientEvents } from 'common/events/ClientEvents';
import React, { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'src/shared/design-system/Modal';
import { useTracker } from 'src/shared/hooks/useTracker';
import { StepByStepWithDotIndicator } from 'src/step-by-step/StepByStepWithDotIndicator';
import styled from 'styled-components';
import { Colors, Font } from 'web-common/src/shared/styles';
import { ChooseADeliveryDayStep, ChooseYourDishesStep, ExploreLocalShefsStep } from './EducationStep';

const StyledModal = styled(Modal)`
  width: 400px;

  & > ${ModalHeader} {
    font-size: 22px;
    line-height: 28px;
    color: ${Colors.RADISH};
  }
`;

const CloseButton = styled.div`
  cursor: pointer;
  height: 24px;
  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${Colors.GRAY};
`;

interface INewUserEducationModalProps {
  isShowing: boolean;
  onHide: () => void;
}

export const NewUserEducationModal: React.FC<INewUserEducationModalProps> = ({ isShowing, onHide }) => {
  const tracker = useTracker();

  useEffect(() => {
    if (isShowing) {
      tracker.track(ClientEvents.NEW_USER_EDUCATION_VIEW, {});
    }
  }, [isShowing, tracker]);

  return (
    <StyledModal isShowing={isShowing} onClose={onHide}>
      <ModalHeader>
        How Shef Works
        <CloseButton onClick={onHide}>Close</CloseButton>
      </ModalHeader>
      <ModalBody>
        <StepByStepWithDotIndicator onComplete={onHide}>
          <ExploreLocalShefsStep />
          <ChooseADeliveryDayStep />
          <ChooseYourDishesStep />
        </StepByStepWithDotIndicator>
      </ModalBody>
    </StyledModal>
  );
};
