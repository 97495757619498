import { ActiveExperiments, ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { IExperiment, IExperimentManager, IVariant } from 'common/experiments/IExperimentManager';
import { StorageKeys } from 'common/storage/constants';
import React from 'react';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { IExperimentContext } from './IExperimentContext';

export class LocalExperimentContext implements IExperimentContext {
  private storage = getBrowserStorage();

  private readonly startedExperiments = new Set<string>();

  constructor(private readonly expManager: IExperimentManager) {}

  getAllExperiments(): ReadonlyArray<Readonly<IExperiment>> {
    return ActiveExperiments;
  }

  getExperiment(name: ExperimentNames): Readonly<IExperiment> | null {
    return this.expManager.getExperiment(name);
  }

  logExposure(experimentName: ExperimentNames): void {
    this.expManager.getVariant(experimentName, true);
  }

  getVariant(experimentName: ExperimentNames, logExposure = true): IVariant | null {
    const variant: IVariant | null = this.expManager.getVariant(experimentName, logExposure);
    if (!this.startedExperiments.has(experimentName)) {
      this.startedExperiments.add(experimentName);
    }
    return variant;
  }

  // Used by debugger
  setVariantOverride(experimentName: ExperimentNames, experimentVariant: string) {
    const variant = this.expManager.getExperiment(experimentName)?.variants.find((v) => v.name === experimentVariant);
    if (!variant) {
      throw new Error(`Variant ${experimentVariant} does not exist!`);
    }

    this.expManager.setVariantOverride(experimentName, experimentVariant);
    this.storage.setItem(StorageKeys.VARIANT_OVERRIDES, JSON.stringify(this.expManager.variantOverrides));
  }
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const ExperimentContext: React.Context<IExperimentContext> = React.createContext({} as IExperimentContext);
