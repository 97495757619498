import { useEffect } from 'react';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { Store } from 'src/store';
import { setSkipTomorrowDefaultDate, userLogin, userLogout } from '../actions';

interface UseSyncUserStateWithCartProps {
  store: Store;
}

export function useSyncUserStateWithCart({ store }: UseSyncUserStateWithCartProps) {
  const [currentUser, currentUserLoading] = useCurrentUser();
  const isLoggedIn = Boolean(currentUser?.id);
  const isConsideredLoggedIn = store.getState().cart.userLoggedIn;

  const skipTomorrowDefaultDate = (currentUser?.orderCount ?? 0) > 0;
  const isSkipTomorrowDefaultDate = store.getState().cart.skipTomorrowDefaultDate;

  useEffect(() => {
    if (isLoggedIn !== isConsideredLoggedIn && !currentUserLoading) {
      store.dispatch(isLoggedIn ? userLogin() : userLogout());
    }
  }, [isLoggedIn, isConsideredLoggedIn, currentUserLoading]);

  useEffect(() => {
    if (skipTomorrowDefaultDate !== isSkipTomorrowDefaultDate && !currentUserLoading) {
      store.dispatch(setSkipTomorrowDefaultDate({ skipTomorrowDefaultDate }));
    }
  }, [skipTomorrowDefaultDate, isSkipTomorrowDefaultDate, currentUserLoading]);
}
