import { useMemo } from 'react';
import { useGetSearchResultsLazyQuery } from 'src/gqlReactTypings.generated.d';
import { BASE_SEARCH_VARIABLES } from '../../consts';
import { UseGetSearchResultsQueryProps } from '../types';

export const useGetSearchResultsQuery = (props: UseGetSearchResultsQueryProps) => {
  const { deliveryDate, query, zipCode } = props;

  const defaultGetSearchInput = useMemo(
    () => ({
      query,
      deliveryDate,
      zipCode: zipCode ?? '',
      ...BASE_SEARCH_VARIABLES,
    }),
    [query, deliveryDate, zipCode]
  );

  const [getSearchResults, { data, loading, error, fetchMore, variables }] = useGetSearchResultsLazyQuery({
    fetchPolicy: 'network-only',
    variables: { ...defaultGetSearchInput },
  });

  return {
    data,
    loading: loading || (variables ? query !== variables?.query : false),
    error,
    defaultGetSearchInput,
    getSearchResults,
    fetchMore,
  };
};
