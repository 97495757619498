import { RootState } from 'src/store';
import { SearchState } from './types';

export const selectSearch = (state: RootState): SearchState => state.search;

export const selectDishPageSize = (state: RootState) => selectSearch(state).dishPageSize;

export const selectIsPartialQuery = (state: RootState) => selectSearch(state).isPartialQuery;

export const selectIsSearchHidden = (state: RootState) => selectSearch(state).isSearchHidden;

export const selectIsSearchInputFocused = (state: RootState) => selectSearch(state).isSearchInputFocused;

export const selectSearchQuery = (state: RootState) => selectSearch(state).searchQuery;

export const selectShefPageSize = (state: RootState) => selectSearch(state).shefPageSize;

export const selectActiveZipCode = (state: RootState) => selectSearch(state).activeZipCode;

export const selectModalDeliveryDates = (state: RootState) => selectSearch(state).modalDeliveryDates;
