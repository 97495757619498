import { ISession } from './ISession';

export function isSession(item: unknown): item is ISession {
  return (
    item !== undefined &&
    item !== null &&
    Object.prototype.hasOwnProperty.call(item, 'id') &&
    Object.prototype.hasOwnProperty.call(item, 'startedAt') &&
    Object.prototype.hasOwnProperty.call(item, 'updatedAt') &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    typeof (item as any).id === 'string' &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    typeof (item as any).startedAt === 'number' &&
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    typeof (item as any).updatedAt === 'number'
  );
}
