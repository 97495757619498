import { FontFamily, FontWeight } from 'web-common/src/shared/styles';

export enum ButtonColors {
  DEFAULT = 'default',
  RED = 'red',
  GRAY = 'gray',
  LIGHT_GRAY = 'light_gray',
  DARK_GRAY = 'dark_gray',
  BLUE = 'blue',
  YELLOW = 'yellow',
  WHITE = 'white',
}

export enum ButtonSizes {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum ButtonSelectStyles {
  BACKGROUND = 'BACKGROUND',
  BORDER = 'BORDER',
}

export interface ButtonProps {
  /** Color of the button. */
  color?: ButtonColors | string;
  /** Color of the button. */
  fontWeight?: FontWeight;
  /** If button is disabled. */
  disabled?: boolean;
  /** If the button is in error state. */
  error?: boolean;
  /** Auto sizes button width inside a flex container. */
  fluid?: boolean;
  /** If button is selected. */
  isSelected?: boolean;
  /** Size of the button. */
  size?: ButtonSizes;
  /** Font family of the button */
  fontFamily?: FontFamily;
  /** Selected style variant */
  selectedStyle?: ButtonSelectStyles;
}
