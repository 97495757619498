import QueryString from 'query-string';

// Documentation here: https://support.singular.net/hc/en-us/articles/360042283811-Website-to-App-Attribution-Forwarding-for-Mobile-Web#Prerequisites
// Note we use click through tracking ofr everything, but if we need view through, we can get them to generate a new link for us
export const SINGULAR_TRACKING_LINK = 'https://shef.sng.link/A1e2b/gd5z?_dl=shef%3A%2F%2Fhome&_smtype=3';

/**
 * Singular gives us a cross-platform tracking link to open the app stores, and falls back to shef.com/app
 */
export const getAppStoreUrl = (params: { [key: string]: string } = {}): string =>
  QueryString.stringifyUrl({
    url: SINGULAR_TRACKING_LINK,
    query: params,
  });
