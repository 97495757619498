import { StorageKeys } from 'common/storage/constants';
import { toPairs } from 'lodash';
import { IStorage } from 'src/shared/storage/Storage';
import { RootState } from 'src/store';
import { ISavePersistedState, IStoredState, IStoredStateOptions } from 'src/store/storage/types';

type StoredStatePairs = Array<[keyof IStoredState, IStoredStateOptions]>;

export const saveState =
  (storedState: IStoredState): ISavePersistedState =>
  ({ storage, state }): void => {
    (toPairs(storedState) as StoredStatePairs).forEach(([key, options]) => {
      saveKey(storage, state[key], options.key, options.currentVersion, options.expiresAt);
    });
  };

export const saveKey = (
  storage: IStorage,
  state: RootState[keyof RootState],
  storageKey: StorageKeys,
  version: number,
  expiresAt?: number
) => {
  if (!state) console.warn('No state provided to save');
  const value = JSON.stringify({
    version,
    expiresAt,
    state,
  });
  storage.setItem(storageKey, value);
};
