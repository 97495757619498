import { useEffect } from 'react';
import { useHistory } from 'src/shared/hooks/useHistory';
import { useGlobalReducer } from 'src/shef-global-state/shefGlobalState';
import { sendInfoToGoogleTagManager } from '../../tracking/trackers/third-party/googleTagManagerUtils';

export const useSendUserInfoToGoogleTagManager = () => {
  const { state } = useGlobalReducer();
  const { currentUser, loaded: currentUserLoaded } = state;
  const history = useHistory();

  useEffect(() => {
    if (!currentUserLoaded) {
      return;
    }
    const tagManagerArgs = {
      userId: currentUser?.id,
      userEmail: currentUser?.email,
      userLoggedIn: !!currentUser,
      pagePath: history.location.pathname,
      pageQueryArgs: history.location.search,
    };
    sendInfoToGoogleTagManager(tagManagerArgs);
  }, [currentUser, currentUserLoaded, history.location.search, history.location.pathname]);
};
