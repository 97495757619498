import styled from 'styled-components';
import { Font } from 'web-common/src/shared/styles';
import { breakpointWidth } from '../constants';

export const Header0 = styled.h1`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: bold;

  font-size: 80px;
  line-height: 80px;

  @media only screen and (max-width: ${breakpointWidth}px) {
    font-size: 40px;
    line-height: 45px;
  }
`;

export const Header1 = styled.h2`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: bold;

  font-size: 40px;
  line-height: 50px;

  @media only screen and (max-width: ${breakpointWidth}px) {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const Header2 = styled.h3`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: bold;

  font-size: 24px;
  line-height: 30px;

  @media only screen and (max-width: ${breakpointWidth}px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export const Header3 = styled.h4`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: bold;

  font-size: 20px;
  line-height: 25px;
`;

export const Header4 = styled.h5`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: bold;

  font-size: 16px;
  line-height: 20px;
`;
