import { Font, Spacing } from 'web-common/src/shared/styles';
import { DropdownSize } from './consts';

export const THEME = {
  [DropdownSize.S]: {
    FontSize: Font.Size.XS,
    FontSizeHeader: Font.Size.XXS,
    IconSize: Spacing.SINGLE,
    Padding: Spacing.HALF,
  },
  [DropdownSize.M]: {
    FontSize: Font.Size.S,
    FontSizeHeader: Font.Size.XS,
    IconSize: Spacing.SINGLE_QUARTER,
    Padding: Spacing.THREE_QUARTERS,
  },
  [DropdownSize.L]: {
    FontSize: Font.Size.M,
    FontSizeHeader: Font.Size.S,
    IconSize: Spacing.SINGLE_HALF,
    Padding: Spacing.SINGLE,
  },
};
