import React from 'react';
import styled, { css } from 'styled-components';
import { Path } from 'web-common/src/shared/design-system/Utilities/SVG';
import { Transition } from 'web-common/src/shared/styles';
import { BaseIcon16 } from '../BaseIcon';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  isCrossed?: boolean;
}

export interface BaseIconProps extends SVGProps {
  crossPath?: string;
  path: string;
}

export const CrossPath = styled(Path)<{ show?: boolean }>`
  transform-origin: center center;
  transition: transform ${Transition.Time.BASE}, opacity ${Transition.Time.BASE};
  ${({ show = false }) => css`
    opacity: ${show ? 1 : 0};
    transform: scale(${show ? 1 : 0.8});
  `}
`;

export const BaseDietaryIcon: React.FC<BaseIconProps> = (props) => {
  const { crossPath, isCrossed = false, path, ...otherProps } = props;
  return (
    <BaseIcon16 {...otherProps}>
      <Path d={path} />
      {crossPath && <CrossPath show={isCrossed} d={crossPath} />}
    </BaseIcon16>
  );
};
