import React from 'react';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import { ZIndexes } from 'src/shared/ZIndexes';
import styled, { css } from 'styled-components';
import { Colors, Font } from 'web-common/src/shared/styles';

interface BaseModalContentProps {
  className?: string;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  children: React.ReactNode;
}

const StyledBaseModalContent = styled.div<Pick<BaseModalContentProps, 'isFullHeight' | 'isFullWidth'>>`
  background-color: ${Colors.WHITE};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: ${Font.Family.SANS};
  z-index: ${ZIndexes.ModalContent};
  overflow-y: scroll;
  position: absolute;

  ::-webkit-scrollbar {
    display: none;
  }

  ${ifProp(
    'isFullWidth',
    css`
      width: 100% !important;
      max-width: 100% !important;
    `
  )}

  ${ifProp(
    'isFullHeight',
    css`
      height: 100% !important;
      max-height: 100% !important;
    `
  )}
`;

export const BaseModalContent = React.forwardRef<HTMLDivElement, BaseModalContentProps>((props, ref) => (
  <StyledBaseModalContent {...props} ref={ref} />
));
