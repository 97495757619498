import styled from 'styled-components';
import { Colors, Font, Mixins, Spacing } from 'web-common/src/shared/styles';

export const Container = styled.div`
  ${Mixins.Flex.setColumn()}
  gap: ${Spacing.THREE_QUARTERS};
  margin-top: ${Spacing.QUARTER};
`;

export const Cuisines = styled.p`
  color: ${Colors.GRAY_DARK_30};
  margin-top: ${Spacing.NONE};
  margin-bottom: -${Spacing.HALF};
  ${Font.bySize(Font.Size.XS, Font.Weight.MEDIUM)}
  font-family: ${Font.Family.SANS};
`;

export const Name = styled.h1`
  color: ${Colors.GRAY_DARK_30};
  margin: 0;
  ${Font.bySize(Font.Size.XXL, Font.Weight.BOLD)}
`;

export const ByShef = styled.p`
  ${Font.bySize(Font.Size.XS, Font.Weight.SEMI_BOLD)}
  font-family: ${Font.Family.SANS};
  margin: ${Spacing.QUARTER} 0 0;
  color: ${Colors.GRAY_DARK_20};

  & a {
    text-decoration: underline;
  }
`;
