import { noOp } from 'common/Constants';
import React, { FocusEvent, useCallback, useRef, useState } from 'react';
import { useOnMount } from 'src/shared/hooks/useOnMount';
import { Container, HiddenInput, TextCountContainer } from './styles';

export interface BaseTextAreaProps {
  /** Auto resize height of textarea. */
  autoHeight?: boolean;
  /** Additional style class. */
  className?: string;
  /** If TextARea is disabled. */
  disabled?: boolean;
  /** Adds flex property for text area to take container width. */
  fluid?: boolean;
  /** Light theme (White background) */
  light?: boolean;
  /** Max length of text. */
  maxLength?: number;
  /** Blue event callback. */
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  /** Callback when value of textarea changes. */
  onChange: (value: string) => void;
  /**
   * Callback if enter key is pressed. Passes back keyboard event and additional callback
   * to resize textarea if value changes after enter key is pressed and autoHeight is true.
   */
  onEnterKey?: (e: React.KeyboardEvent<HTMLTextAreaElement>, resizeCallback: () => void) => void;
  /** Placeholder text. */
  placeholder?: string;
  /** Default number of rows. */
  rows?: number;
  /** Additional styles. */
  style?: React.CSSProperties;
  /** Value of the textarea. */
  value?: string;
}

export const BaseTextArea: React.FC<BaseTextAreaProps> = (props) => {
  const {
    autoHeight,
    className,
    fluid,
    maxLength,
    onChange,
    onEnterKey,
    style = {},
    value = '',
    ...otherProps
  } = props;
  const [height, setHeight] = useState(0);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const hasMaxLength = !!maxLength;

  const setTextAreaHeight = () => {
    setTimeout(() => {
      setHeight((textAreaRef.current?.scrollHeight ?? 0) + 2);
    }, 10);
  };

  const getFormattedContent = useCallback(
    (content: string) => {
      if (!!maxLength && content.length > maxLength) {
        return content.slice(0, maxLength);
      }
      return content;
    },
    [maxLength]
  );

  const setFormattedContent = useCallback(
    (text: string) => {
      const formattedContent = getFormattedContent(text);
      if (formattedContent !== value) {
        onChange(formattedContent);
      }
    },
    [getFormattedContent, onChange, value]
  );

  useOnMount(() => {
    if (hasMaxLength) {
      setFormattedContent(value);
    }
  });

  return (
    <Container data-role={'textarea-container'} fluid={fluid}>
      {autoHeight && (
        <HiddenInput className={className} ref={textAreaRef} style={style} onChange={noOp} value={value} />
      )}
      <textarea
        className={className}
        data-role='textarea'
        onChange={(e) => {
          setFormattedContent(e.target.value);
          if (autoHeight) {
            setTextAreaHeight();
          }
        }}
        onKeyPress={(e) => {
          if (onEnterKey && e.key === 'Enter') {
            onEnterKey(e, () => {
              if (autoHeight) {
                setTextAreaHeight();
              }
            });
          }
        }}
        style={{ ...style, height: autoHeight ? height : '' }}
        value={value}
        {...otherProps}
      />
      {!!hasMaxLength && (
        <TextCountContainer isMaxReached={value?.length === maxLength}>
          {value?.length} / {maxLength}
        </TextCountContainer>
      )}
    </Container>
  );
};
