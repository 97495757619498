import React, { useEffect, useMemo } from 'react';
import { RouteChildrenProps } from 'react-router';
import { LoadingWithHeight } from 'src/shared/design-system/Loading';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { getToken } from 'src/shared/storage/Storage';
import { isLazyComponent } from 'src/util/retry';

/* Blocks rendering if a users token is present and global state is loading.
 * When it blocks rendering it checks to see if the component passed in has
 * a preload function. Preload functions are promises that trigger the chunk
 * for a given function to load before attempting rendering. */
export const withLoading = (Base: React.ComponentType<RouteChildrenProps>) => (props: RouteChildrenProps) => {
  const token = getToken();
  const [, loading] = useCurrentUser();

  useEffect(() => {
    if (isLazyComponent(Base)) {
      Base.preload &&
        Base.preload().catch((err) => {
          console.error(`Failed to load chunk for route ${props?.location?.pathname}`, err);
        });
    }
  }, [props?.location?.pathname]);

  const Component = useMemo(() => {
    if (token && loading) {
      return LoadingWithHeight;
    }
    return Base;
  }, [loading, token]);

  return <Component {...props} />;
};
