import { formatGqlErrors } from 'common/GqlUtilities';
import { isTooLateToOrderForDeliveryDate } from 'common/OrderDateRangeUtilities';
import { isDefined } from 'common/TypeUtilities';
import { urlDateFormat, urlDateToDateTime } from 'common/UrlDate';
import { map, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { fetchCartShefs } from 'src/store/cart/utils/cartQueries';
import { getEntityList } from 'src/store/utils';
import { CreateCartEffect } from './listeners';

export const loadShefData: CreateCartEffect =
  ({ gqlClient, cartSlice }) =>
  async (_action, listenerApi) => {
    const { setShefs } = cartSlice.actions;
    const { cart } = listenerApi.getState();
    const lineItems = getEntityList(cart.lineItems);
    // filter out "" shef id if activeCartShefId is empty string in default state
    const shefIds = uniq([...map(lineItems, 'shefId'), cart.activeCartShefId].filter(Boolean));

    // resync any new items or any shefs who are over an hour stale
    const shefIdsNeedingFetched = shefIds.filter((shefId) => {
      const shef = cart.shefs.entities[shefId];
      if (!shef) return true;

      const { loading, syncedAt } = shef;
      const stale = syncedAt && DateTime.fromISO(syncedAt) < DateTime.local().minus({ hours: 1 });
      return loading || stale;
    });

    if (shefIdsNeedingFetched.length === 0) return;

    try {
      const { errors, data } = await fetchCartShefs({
        gqlClient,
        shefIds: shefIdsNeedingFetched,
      });
      // FUTURE TODO: need to handle errors better - likely needs to retry the requests
      // currently it will permanently leave the order blocks for multi cart in a loading state
      if (errors?.length) return console.error(formatGqlErrors(errors));
      const shefs = data?.shefs
        .map((shef) => {
          const existingShefData = cart.shefs.entities[shef.id];

          const getValidDeliveryDate = (urlDate: string | undefined): string | undefined => {
            if (!urlDate) return undefined;
            const isTooLate = isTooLateToOrderForDeliveryDate(
              urlDateToDateTime(urlDate, shef.timeZone),
              shef.shefProfile.cutoffMsBeforeDeliveryDate
            );
            return isTooLate ? undefined : urlDate;
          };

          const validExistingDeliveryDate = getValidDeliveryDate(existingShefData?.deliveryDate);
          const nextValidDeliveryDate = shef.availability?.find(({ availabilityDate, isSoldOut }) => {
            const validDeliveryDate = getValidDeliveryDate(availabilityDate);
            return !isSoldOut && validDeliveryDate;
          })?.availabilityDate;
          const invalidFallback = DateTime.local().toFormat(urlDateFormat);

          // set to existing value if order deadline hasn't already passed or next availabilityDate
          // that isn't sold out and also hasn't passed the order deadline, otherwise fallback to
          // current date as it doesn't matter since shef can't be ordered from
          const deliveryDate = validExistingDeliveryDate || nextValidDeliveryDate || invalidFallback;

          return {
            ...existingShefData,
            ...shef,
            deliveryDate,
            name: shef.firstName || '',
            imageUrl: shef.imageUrl || '',
            loading: false as const,
            syncedAt: new Date().toISOString(),
          };
        })
        .filter(isDefined);
      if (shefs?.length) listenerApi.dispatch(setShefs(shefs));
    } catch (err) {
      console.error(err);
    }
  };
