import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './julian-hernandez.jpg';

export const JulianHernandez: PersonInfo = {
  firstName: 'Julian',
  lastName: 'Hernandez',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
