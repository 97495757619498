import { ClientEvents } from 'common/events/ClientEvents';
import { NavClickElementName } from 'common/events/ClientTypes';
import React, { useCallback } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useTracker } from 'src/shared/hooks/useTracker';
import styled from 'styled-components';

export interface TrackedNavLinkProps extends LinkProps {
  /** The name of the link. Used for tracking purposes. */
  name: NavClickElementName;
}

const StyledTrackedNavLink = styled(Link)`
  -webkit-tap-highlight-color: transparent;
`;

/**
 * Wrapper around Link used to track navigation link clicks.
 */
export const TrackedNavLink: React.FC<TrackedNavLinkProps> = ({ name, onClick, ...props }) => {
  const tracker = useTracker();
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      tracker.track(ClientEvents.NAV_CLICK, { elementName: name });
      onClick?.(e);
    },
    [name, onClick, tracker]
  );

  return <StyledTrackedNavLink {...props} onClick={handleClick} />;
};
