import { Bar } from './Bar';
import { Button, Buttons } from './Buttons';
import { Toggle as ToggleComponent } from './Toggle';
import { YesNo } from './YesNo';

// eslint-disable-next-line functional/immutable-data -- Assign sub toggle components
const Toggle = Object.assign(ToggleComponent, { Bar, Buttons, YesNo });

export { Bar, Button, Buttons, Toggle, YesNo };
export default Toggle;
