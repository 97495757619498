import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Transition } from 'web-common/src/shared/styles';

const transitionTime = 220;

export const Container = styled.div<{ show: boolean }>`
  opacity: ${ifProp('show', 1, 0)};
  transition: height ${Transition.Time.BASE}, opacity ${Transition.Time.BASE};
`;

export type ExpandingContainerStyle = {
  height: string | number;
  overflow: string;
};

export type ExpandingContainerProps = {
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  renderContentWithStyle?: (style: ExpandingContainerStyle) => React.ReactNode;
  show?: boolean;
};

export const useShowHideDiv = (show?: boolean, loading?: boolean) => {
  const ref = useRef<HTMLDivElement>(null);
  const mountHeightRef = useRef(0);
  const hiddenRef = useRef(0);
  const [height, setHeight] = useState(0);
  const [isHidden, setIsHidden] = useState(!show);

  const getContainerHeight = useCallback(() => {
    if (ref.current?.getBoundingClientRect) {
      const containerHeight = ref.current.getBoundingClientRect().height;
      setHeight(containerHeight);
      return containerHeight;
    }
    return 0;
  }, []);

  useEffect(() => {
    // eslint-disable-next-line functional/immutable-data -- Delay calc height.
    mountHeightRef.current = window.setInterval(() => {
      if (getContainerHeight()) {
        clearInterval(mountHeightRef.current);
      }
    }, transitionTime);
  });

  useEffect(() => {
    getContainerHeight();
  }, [getContainerHeight, show, loading]);

  useEffect(() => {
    window.addEventListener('resize', getContainerHeight);
    return () => {
      window.removeEventListener('resize', getContainerHeight);
    };
  }, [getContainerHeight]);

  useEffect(() => {
    if (show && isHidden) {
      // eslint-disable-next-line functional/immutable-data -- Setting timeout ref.
      hiddenRef.current = window.setTimeout(() => {
        setIsHidden(false);
      }, transitionTime);
    } else if (!show && !isHidden) {
      setIsHidden(true);
    }
  }, [isHidden, show]);

  useEffect(() => () => {
    clearTimeout(hiddenRef.current);
    clearTimeout(mountHeightRef.current);
  });

  return { ref, style: { height: show ? height : '0px', overflow: isHidden ? 'hidden' : 'visible' } };
};

export const ExpandingContainer: React.FC<ExpandingContainerProps> = (props) => {
  const { children, className, loading, renderContentWithStyle, show = false } = props;
  const { ref, style } = useShowHideDiv(show, loading);

  return (
    <Container show={show} style={style} className={className}>
      <div ref={ref}>{children}</div>
      {!!renderContentWithStyle && renderContentWithStyle(style)}
    </Container>
  );
};
