import { cartShefAdapter } from '../entities';
import { CartState } from '../types';

const cartShefSelectors = cartShefAdapter.getSelectors();

export const selectCartShefs = (state: CartState) => cartShefSelectors.selectAll(state.shefs);
export const selectCartShefIds = (state: CartState) => state.shefs.ids;
export const selectCartShefsById = (state: CartState) => state.shefs.entities;
export const selectCartShefById = (state: CartState, shefId: string) =>
  cartShefSelectors.selectById(state.shefs, shefId);
export const selectActiveShef = (state: CartState) => selectCartShefById(state, state.activeCartShefId);
