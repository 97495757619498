import { Mixins, Spacing } from 'web-common/src/shared/styles';

export const NAV_HEIGHT = Spacing.TRIPLE;
export const NAV_HEIGHT_VALUE = Mixins.Spacing.getSpacingValue(NAV_HEIGHT);
export const NAV_HEIGHT_SCROLL_VALUE = NAV_HEIGHT_VALUE * 3 + 32;
export const NAV_HEIGHT_SHEF_MENU_SCROLL_VALUE = NAV_HEIGHT_SCROLL_VALUE + 260;

export const PROMO_HEIGHT = Spacing.TRIPLE_HALF;
export const PROMO_HEIGHT_VALUE = Mixins.Spacing.getSpacingValue(PROMO_HEIGHT);
export const PROMO_HEIGHT_MOBILE = Spacing.QUINTUPLE;
export const PROMO_HEIGHT_MOBILE_VALUE = Mixins.Spacing.getSpacingValue(PROMO_HEIGHT_MOBILE);

export const NAV_HEIGHT_LARGE = Spacing.TRIPLE_HALF;
export const NAV_HEIGHT_LARGE_VALUE = Mixins.Spacing.getSpacingValue(NAV_HEIGHT_LARGE);

export const MODAL_BOTTOM_HEIGHT = '88px';
export const MODAL_BOTTOM_HEIGHT_VALUE = 88;
export const MODAL_MAIN_CONTENT_HEIGHT = `calc(100vh - ${NAV_HEIGHT})`;
export const MODAL_MAIN_CONTENT_HEIGHT_WITH_CTA = `calc(100vh - ${NAV_HEIGHT} - ${MODAL_BOTTOM_HEIGHT})`;

export const LOGO_HEIGHT = '24px';
export const LOGO_WIDTH = '50px';

export const HEADER = {
  CUISINE_FILTERS: 'Cuisine',
  DATE_PICKER: 'Delivery Date',
  DIETARY_FILTERS: 'Dietary',
  MORE_FILTERS: 'More filters',
  PROTEIN_FILTERS: 'Protein',
  SUB_CUISINE_FILTERS: 'Subcuisine',
};

export const defaultClientRect = { top: null };
