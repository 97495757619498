import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

/**
 * Deprecated version of the down chevron. Use ChevronDownV2 instead.
 * This chevron is no longer a part of the design system.
 */
export const ChevronDown = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
    <Path d='M5,7c0.38,0,0.77,0.15,1.06,0.44L12,13.38l5.94-5.94c0.59-0.59,1.54-0.59,2.12,0c0.59,0.59,0.59,1.54,0,2.12l-7,7c-0.59,0.59-1.54,0.59-2.12,0l-7-7c-0.59-0.59-0.59-1.54,0-2.12C4.23,7.15,4.62,7,5,7z' />
  </SVG>
);

export const ChevronDownV2 = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <Path d='M5 9L12 16L19 9' stroke='currentColor' strokeWidth='2' fill='none' />
  </SVG>
);

export const ChevronDownV3 = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M15.7169 5.11754L8.63071 12.228C8.40811 12.4223 8.1855 12.5 7.9629 12.5C7.7774 12.5 7.55479 12.4223 7.36929 12.2669L0.283076 5.15639C-0.0879304 4.80669 -0.0879304 4.18501 0.245975 3.83532C0.57988 3.44677 1.13639 3.44677 1.5074 3.79646L7.9629 10.2853L14.4926 3.75761C14.8265 3.40791 15.4201 3.40791 15.754 3.79646C16.0879 4.14616 16.0879 4.76784 15.7169 5.11754Z'
      fill='#EC2044'
    />
  </SVG>
);
