import * as Sentry from '@sentry/react';
import { IReqRes } from '@shef/native-bridge';
import { useCallback, useEffect, useReducer } from 'react';
import { DispatchSendRequest, NativeAppAction, NativeAppHookResult, NativeAppState } from 'src/mobile-app/hooks/types';
import { isNativeMobileApp } from '../isNativeMobileApp';
import { useNativeAppMessenger } from '../NativeAppMessengerContext';
import { useNativeAppNavigation } from './useNativeAppNavigation';
import { useNativeAppRequestRouter } from './useNativeAppRequestRouter';

const initialState: NativeAppState = {
  managedRoutes: window.App?.routes ?? [],
  sendRequestError: null,
  paymentMethods: null,
  paymentResult: null,
};

// Hook to execute when inside of the native app
const useReal = (): NativeAppHookResult => {
  useEffect(() => console.log('Setting up Native App'), []);

  const nativeAppMessenger = useNativeAppMessenger();

  if (!nativeAppMessenger) {
    throw new Error('nativeAppMessenger is missing');
  }

  const [state, dispatch] = useReducer((state: NativeAppState, action: NativeAppAction) => {
    switch (action.name) {
      case 'SEND_REQUEST':
        return { ...state, sendRequestError: null };
      case 'SEND_REQUEST_ERROR':
        return { ...state, sendRequestError: action.error };
      case 'SET_PAYMENT_METHODS':
        return { ...state, paymentMethods: action.paymentMethods };
      case 'SET_PAYMENT_RESULT':
        return { ...state, paymentResult: action.paymentResult };
      default:
        return state;
    }
  }, initialState);

  // Used to ensure type safety when dispatching a SEND_REQUEST action
  const dispatchSendRequest: DispatchSendRequest = useCallback(
    <I = unknown, O = unknown>(reqRes: IReqRes<I, O>, args: I) => {
      dispatch({ name: 'SEND_REQUEST', reqRes, args });
      nativeAppMessenger.sendRequest(reqRes, args).catch((error) => {
        Sentry.captureException(error, { extra: { message: 'Failed to send request', reqRes, args } });
        dispatch({ name: 'SEND_REQUEST_ERROR', error });
      });
    },
    [nativeAppMessenger]
  );

  useNativeAppNavigation(dispatchSendRequest, state.managedRoutes);
  const [router] = useNativeAppRequestRouter(dispatch);

  useEffect(() => {
    nativeAppMessenger.registerRequestRouter(router);
    nativeAppMessenger.start();

    return () => {
      nativeAppMessenger.stop();
    };
  }, [nativeAppMessenger, router]);

  const actionCreators = { dispatchSendRequest };

  return [state, dispatch, actionCreators];
};

// Hook to execute when not in the native app
const useFake = (): NativeAppHookResult => {
  useEffect(() => console.log('Not setting up Native App'), []);
  return [
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    new Proxy(
      {},
      {
        get: () => {
          throw new Error('Tried to access native app state while not running the native app');
        },
      }
    ) as NativeAppState,
    () => {
      throw new Error('Tried to dispatch native app action while not running the native app');
    },
    {
      dispatchSendRequest: () => {
        throw new Error('Tried to dispatch native app request while not running the native app');
      },
    },
  ];
};

export const useNativeApp = isNativeMobileApp() ? useReal : useFake;
