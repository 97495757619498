import { find, findLast, isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowSoftLeft, ArrowSoftRight } from 'src/shared/design-system/Icon';
import styled from 'styled-components';
import { Colors, Spacing, ZIndex } from 'web-common/src/shared/styles';

const Container = styled.div<{ scrollPositionLeft: number; scrollPositionRight: number; marginX: number }>`
  position: relative;
  margin-left: ${({ marginX }) => `-${marginX}px`};
  margin-right: ${({ marginX }) => `-${marginX}px`};

  display: flex;
  flex-direction: row;

  :before {
    content: ' ';
    background: ${({ marginX }) =>
      `linear-gradient(90deg, ${Colors.WHITE} ${Math.round(
        ((marginX + 16) / (marginX + 48)) * 100
      )}%, rgba(255, 255, 255, 0) 100%)`};

    position: absolute;
    width: ${({ scrollPositionLeft, marginX }) => `${Math.min(scrollPositionLeft + marginX, marginX + 48)}px`};
    height: 100%;
    left: ${Spacing.NONE};
    top: ${Spacing.NONE};
    z-index: ${ZIndex.HorizontalSnapBadgeContainerFadeBarOverlay};

    @media (pointer: coarse) {
      width: ${({ scrollPositionLeft, marginX }) => `${Math.min(scrollPositionLeft + marginX, 32)}px`};
      background: linear-gradient(90deg, ${Colors.WHITE} 0%, rgba(255, 255, 255, 0) 100%);
    }
  }

  :after {
    content: ' ';
    background: ${({ marginX }) =>
      `linear-gradient(270deg, ${Colors.WHITE} ${Math.round(
        ((marginX + 16) / (marginX + 48)) * 100
      )}%, rgba(255, 255, 255, 0) 100%)`};

    position: absolute;
    width: ${({ scrollPositionRight, marginX }) => `${Math.min(scrollPositionRight + marginX, marginX + 48)}px`};
    height: 100%;
    right: ${Spacing.NONE};
    top: ${Spacing.NONE};
    z-index: ${ZIndex.HorizontalSnapBadgeContainerFadeBarOverlay};

    @media (pointer: coarse) {
      width: ${({ scrollPositionRight, marginX }) => `${Math.min(scrollPositionRight + marginX, 32)}px`};
      background: linear-gradient(270deg, ${Colors.WHITE} 0%, rgba(255, 255, 255, 0) 100%);
    }
  }
`;

const ScrollButton = styled.button`
  position: absolute;
  align-items: center;
  justify-content: center;
  align-self: center;

  box-sizing: border-box;
  width: ${Spacing.DOUBLE};
  height: ${Spacing.DOUBLE};
  border-radius: ${Spacing.SINGLE};
  padding: 0;

  border: 1px solid ${Colors.GRAY_LITE_20};
  background: ${Colors.WHITE};
  cursor: pointer;

  z-index: ${ZIndex.HorizontalSnapBadgeContainerButton};
`;

const LeftScrollButton = styled(ScrollButton)<{ scrollPositionLeft: number; marginX: number }>`
  left: ${({ marginX }) => `${marginX}px`};
  display: ${({ scrollPositionLeft, marginX }) => (scrollPositionLeft < 16 + marginX ? 'none' : 'flex')};

  @media (pointer: coarse) {
    display: none;
  }
`;

const LeftScrollIcon = styled(ArrowSoftLeft)`
  color: ${Colors.RADISH};
  width: ${Spacing.THREE_QUARTERS};
`;

const RightScrollButton = styled(ScrollButton)<{ scrollPositionRight: number; marginX: number }>`
  right: ${({ marginX }) => `${marginX}px`};
  display: ${({ scrollPositionRight, marginX }) => (scrollPositionRight < 16 + marginX ? 'none' : 'flex')};

  @media (pointer: coarse) {
    display: none;
  }
`;

const RightScrollIcon = styled(ArrowSoftRight)`
  color: ${Colors.RADISH};
  width: ${Spacing.THREE_QUARTERS};
`;

const ScrollContainer = styled.div<{ marginX: number; gap: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ gap }) => `${gap}px`};

  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  overflow-y: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  > * {
    scroll-snap-align: start;
    scroll-margin-left: ${({ marginX }) => `${marginX}px`};
  }
  > :first-child {
    margin-left: ${({ marginX }) => `${marginX}px`};
  }
  > :last-child {
    margin-right: ${({ marginX }) => `${marginX}px`};
  }
`;

type ScrollStatus = {
  scrollPositionLeft: number;
  scrollPositionRight: number;
};

interface IHorizontalSnapBadgeContainer {
  marginX?: number;
  gap?: number;
  className?: string;
}

/**
 * A component for showing a bunch of badges in a horizontal row.
 *
 * Shows buttons for scrolling on desktop but not on mobile.
 *
 * @param marginX prop for how much extra padding to put on the end. See https://www.figma.com/file/haWzvhZw9LN9AI8dQAHMrZ/Dish-details?type=design&node-id=848%3A82041&t=K0gELrnSeTHabl2N-1
 */
export const HorizontalSnapBadgeContainer: React.FC<React.PropsWithChildren<IHorizontalSnapBadgeContainer>> = ({
  children,
  marginX = 16,
  gap = 8,
  className,
}) => {
  const [scrollStatus, setScrollStatus] = useState<ScrollStatus>({
    scrollPositionLeft: 0,
    scrollPositionRight: 0,
  });
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef) {
      return;
    }
    const ref = scrollRef.current;
    if (isNil(ref)) {
      return;
    }

    const update = () => {
      if (ref.children.length === 0) {
        return;
      }

      const scrollContainerRect = ref.getBoundingClientRect();
      const leftmostElement = ref.children[0];
      const scrollPositionLeft = scrollContainerRect.left - leftmostElement.getBoundingClientRect().left + marginX;
      const rightmostElement = ref.children[ref.children.length - 1];
      const scrollPositionRight = rightmostElement.getBoundingClientRect().right - scrollContainerRect.right + marginX;

      setScrollStatus({
        scrollPositionLeft,
        scrollPositionRight,
      });
    };

    update();
    ref.addEventListener('scroll', update, { passive: true });

    return () => ref.removeEventListener('scroll', update);
  }, [scrollRef, marginX]);

  const scrollLeft = useCallback(() => {
    if (!scrollRef) {
      return;
    }
    const ref = scrollRef.current;
    if (isNil(ref) || ref.children.length === 0) {
      return;
    }

    const scrollContainerRect = ref.getBoundingClientRect();
    const elementToScrollTo =
      find(ref.children, (child) => {
        const childRect = child.getBoundingClientRect();
        return childRect.left + (scrollContainerRect.width - marginX) > scrollContainerRect.left;
      }) ?? ref.children[0];

    ref.scrollTo({
      left: elementToScrollTo.getBoundingClientRect().left + ref.scrollLeft - scrollContainerRect.left - marginX,
      behavior: 'smooth',
    });
  }, [scrollRef, marginX]);

  const scrollRight = useCallback(() => {
    if (!scrollRef) {
      return;
    }
    const ref = scrollRef.current;
    if (isNil(ref) || ref.children.length === 0) {
      return;
    }

    const scrollContainerRect = ref.getBoundingClientRect();
    const elementToScrollTo =
      findLast(ref.children, (child) => {
        const childRect = child.getBoundingClientRect();
        return childRect.left < scrollContainerRect.right - marginX;
      }) ?? ref.children[ref.children.length - 1];

    ref.scrollTo({
      left: elementToScrollTo.getBoundingClientRect().left + ref.scrollLeft - scrollContainerRect.left,
      behavior: 'smooth',
    });
  }, [scrollRef, marginX]);

  return (
    <Container
      scrollPositionLeft={scrollStatus.scrollPositionLeft}
      scrollPositionRight={scrollStatus.scrollPositionRight}
      marginX={marginX}
      className={className}>
      <LeftScrollButton scrollPositionLeft={scrollStatus.scrollPositionLeft} onClick={scrollLeft} marginX={marginX}>
        <LeftScrollIcon />
      </LeftScrollButton>
      <RightScrollButton scrollPositionRight={scrollStatus.scrollPositionRight} onClick={scrollRight} marginX={marginX}>
        <RightScrollIcon />
      </RightScrollButton>
      <ScrollContainer marginX={marginX} gap={gap} ref={scrollRef}>
        {children}
      </ScrollContainer>
    </Container>
  );
};
