import { ClientTrackableEvents } from 'common/events/ClientEvents';
import { DateTime } from 'luxon';
import { CurrentUserFieldsFragment } from 'src/gqlReactTypings.generated.d';

export enum OptOut {
  YES,
  NO,
  UNKNOWN,
}

export type UserInfo = Pick<
  CurrentUserFieldsFragment,
  | 'id'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'lastSearchedZipCode'
  | 'lastSearchedRegionId'
  | 'shefStatus'
  | 'referralCode'
  | 'lastOrderPhoneNumber'
  | 'orderCount'
> & { createdAt: DateTime; deviceId?: string; lastLogin?: Date | null };

/**
 * Keep this interface small. We will implement future 'tracking' using it,
 * and stitch the trackers together using a 'Composite' tracker.
 */
export interface ITracker extends IAnonymousTracker {
  hasBeenIdentified: () => boolean;
  identify(userInfo: UserInfo): void;
  updateIdentity<T extends keyof UserInfo>(userInfo: Pick<UserInfo, T>): void;
  onLogout(): void;
  browserTrackerId: string;
}

export interface IAnonymousTracker {
  init(): void;
  track<K extends keyof ClientTrackableEvents>(event: K, data: ClientTrackableEvents[K]): void;
}
