import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

interface StarProps extends React.SVGProps<SVGSVGElement> {
  index?: string | number;
  outline?: boolean;
  percentWidth?: number;
}

export const Star: React.FC<StarProps> = ({ index = 1, outline, percentWidth, ...props }) => {
  if (outline) {
    return <StarOutline {...props} />;
  }

  return percentWidth == null ? (
    <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
      <Path d='M12,0.8L8.3,8.2L0,9.4l6,5.7l-1.4,8.1l7.4-3.8l7.4,3.8L18,15.1l6-5.7l-8.3-1.2L12,0.8z' />
    </SVG>
  ) : (
    <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
      <g>
        <defs>
          <rect id={`SVGID_${index}`} width='24' height='24' style={{ width: `${percentWidth}%` }} />
        </defs>
        <clipPath id={`SVGID_0000000000${index}`}>
          <use xlinkHref={`#SVGID_${index}`} style={{ overflow: 'visible' }} />
        </clipPath>
        <Path
          id='background-star'
          d='M12,0.8L8.3,8.2L0,9.4l6,5.7l-1.4,8.1l7.4-3.8l7.4,3.8L18,15.1l6-5.7l-8.3-1.2L12,0.8z'
          style={{ fill: 'transparent ' }}
        />
        <Path
          style={{ clipPath: `url(#SVGID_0000000000${index})` }}
          d='M12,0.8L8.3,8.2L0,9.4l6,5.7l-1.4,8.1l7.4-3.8l7.4,3.8L18,15.1l6-5.7l-8.3-1.2L12,0.8z'
        />
      </g>
    </SVG>
  );
};

export const StarOutline: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      d='M12.5167 16.4139L12 16.102L11.4833 16.4139L7.33226 18.9192L8.43385 14.1972L8.57098 13.6094L8.11477 13.2142L4.44617 10.0361L9.27454 9.62642L9.8754 9.57544L10.1107 9.02023L12 4.5626L13.8893 9.02023L14.1246 9.57544L14.7255 9.62642L19.5538 10.0361L15.8852 13.2142L15.429 13.6094L15.5661 14.1972L16.6677 18.9192L12.5167 16.4139Z'
      stroke='currentColor'
      strokeWidth='2'
      fill='none'
    />
  </SVG>
);
