import { CheckmarkV2 } from 'src/shared/design-system/Icon';
import styled, { css } from 'styled-components';
import { Colors, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';
import { DEFAULT_CHECKED_COLOR, DEFAULT_SIZE, TOGGLE_SWITCH_MARGIN } from './consts';
import { getCheckedColor, getSizeWithMargin, getToggleTransform } from './utils';

export const Container = styled.div`
  box-sizing: border-box;
`;

export const ToggleContainer = styled.div<{ checked?: boolean; checkedColor?: string; size?: number }>`
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: background ${Transition.Time.BASE}, border ${Transition.Time.BASE};
  ${Mixins.Flex.set('flex-start', 'center')}

  ${({ checked, checkedColor = DEFAULT_CHECKED_COLOR, size = DEFAULT_SIZE }) => css`
    height: ${size}px;
    width: calc(${getSizeWithMargin(size)} * 1.8);
    border-radius: ${getSizeWithMargin(size)};
    background: ${getCheckedColor(checked, checkedColor)};
    border: ${TOGGLE_SWITCH_MARGIN}px solid ${getCheckedColor(checked, checkedColor)};
  `}
`;

export const ToggleSwitch = styled.div<{ checked?: boolean; checkedColor?: string; size?: number }>`
  box-sizing: border-box;
  background: ${Colors.WHITE};
  transition: transform ${Transition.Time.BASE};

  ${({ checked = false, checkedColor = DEFAULT_CHECKED_COLOR, size = DEFAULT_SIZE }) =>
    css`
      color: ${getCheckedColor(checked, checkedColor)};
      ${Mixins.Shape.circleForcedMinDimensions(getSizeWithMargin(size))};
      transform: translateX(${checked ? getToggleTransform(size) : '0px'});
      & svg {
        max-height: ${Spacing.THREE_QUARTERS};
        max-width: ${Spacing.THREE_QUARTERS};
        opacity: ${checked ? 1 : 0};
        transition: opacity ${Transition.Time.BASE};
      }
    `}
  ${Mixins.Flex.center()}
`;

export const ToggleIcon = CheckmarkV2;

export const ToggleInput = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;
