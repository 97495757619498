import React from 'react';
import { Bar } from '../Bar';
import { ToggleYesNoProps } from '../types';
import { useYesNo } from './hooks';

export const YesNo: React.FC<ToggleYesNoProps> = (props) => {
  const { barProps } = useYesNo(props);

  return <Bar {...barProps} />;
};
