import { EntityState } from '@reduxjs/toolkit';
import { isDefined } from 'common/TypeUtilities';
import { Dictionary, keyBy, map, uniq } from 'lodash';

export function toEntity<T extends { id: string | number }>(values: T[]) {
  const entities: Dictionary<T | undefined> = keyBy(values, 'id');
  return {
    ids: uniq(map(values, 'id')),
    entities,
  };
}

export function getEntityList<T>(entityState: EntityState<T>): T[] {
  return Object.values(entityState.entities).filter(isDefined);
}
