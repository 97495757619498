import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Compostable = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <title>Compostable dish</title>
    <desc>An icon representing a compostable container</desc>
    <Path
      d='M2 2.5H14V12.7857C14 13.7324 13.3284 14.5 12.5 14.5H3.5C2.67154 14.5 2 13.7324 2 12.7857V2.5ZM3.5 4.21429V12.7857H12.5V4.21429H3.5Z'
      fill='currentColor'
    />
    <Path d='M0 4.5H16V3C16 2.17158 15.2837 1.5 14.4 1.5H1.6C0.716352 1.5 0 2.17158 0 3V4.5Z' fill='currentColor' />
  </SVG>
);
