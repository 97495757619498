import { useCallback, useRef, useState } from 'react';
import { useCanScrollBase } from 'src/shared/design-system/shared/hooks';

const HEADER_OFFSET = 72;

export const useModalParallax = (modalRef: React.RefObject<HTMLDivElement>) => {
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = useCallback(() => {
    setScrollTop(modalRef?.current?.scrollTop ?? 0);
  }, [modalRef]);

  useCanScrollBase(handleScroll);

  return {
    handleScroll,
    scrollTop,
  };
};

export const getScrollRatio = (start: number, end: number, scrollTop: number) => {
  const range = end - start;
  const offset = Math.max(0, Math.min(scrollTop - start, range));
  return offset / range;
};

export const useModalFoodImageParallax = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const { handleScroll, scrollTop } = useModalParallax(wrapperRef);

  const imageHeight = imageRef.current?.offsetHeight ?? 320;
  const isScrollMax = scrollTop > imageHeight - HEADER_OFFSET - 6;

  const imageOpacityRatio = 1 - getScrollRatio(imageHeight * 0.5, imageHeight - HEADER_OFFSET, scrollTop);
  const imageTransformRatio = getScrollRatio(0, imageHeight - HEADER_OFFSET, scrollTop);

  const headerPaddingRatio = getScrollRatio(imageHeight - HEADER_OFFSET, imageHeight, scrollTop);
  const headerPadding = 16 - Math.min(16 * headerPaddingRatio, 16);
  const headerPaddingSides = 16 - Math.min(16 * headerPaddingRatio, 12);

  const headerImageScale = Math.min(6, Math.max(1 / (scrollTop / imageHeight), 1));
  const headerImageTranslate = isScrollMax ? 0 : Math.max(headerImageScale * 4 - 3, 0) - 1;

  const wavyEdgeTransformRatio = getScrollRatio(imageHeight * 0.4, imageHeight - (HEADER_OFFSET + 4), scrollTop);

  return {
    foodImageContainerProps: {
      ref: imageRef,
    },
    foodImageProps: {
      style: {
        opacity: imageOpacityRatio,
        transform: `translate3d(0, ${imageTransformRatio * -50}px, 0) scale(${1 + imageTransformRatio / 4})`,
      },
    },
    headerProps: {
      isDocked: isScrollMax,
      style: {
        padding: `${headerPadding}px ${headerPaddingSides}px`,
      },
    },
    headerImageProps: {
      style: {
        opacity: 1 - imageOpacityRatio,
        transform: `scale(${1 + imageOpacityRatio}) translate3d(0, ${headerImageTranslate}px, 0)`,
      },
    },
    wavyEdgeProps: {
      style: {
        transform: `scaleX(${1 + wavyEdgeTransformRatio ** 6 * scrollTop})`,
      },
    },
    wrapperProps: {
      onScroll: handleScroll,
      onWheel: handleScroll,
      ref: wrapperRef,
    },
  };
};
