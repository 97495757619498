import {
  IReqRes,
  IRequestRouter,
  RequestHub,
  ResponseHub,
  TDecodedReqMessage,
  TDecodedResMessage,
} from '@shef/native-bridge';
import { enforceExhaustive } from 'common/EnumUtils';
import { backoffRetry } from 'common/PromiseUtils';
import { compare } from 'compare-versions';
import { filter, tap } from 'rxjs/operators';
import { WebDataBus } from './WebDataBus';

type PendingRequestFn = () => void;

export enum NativeAppFeature {
  NATIVE_GROUP_PAYMENTS,
}

/**
 * Wrapper around the core components used to send messages between the native app and shef-web.
 */
export class NativeAppMessenger {
  private dataBus: WebDataBus;

  private requestHub: RequestHub;

  private responseHub: ResponseHub | null = null;

  private pendingRequestFns: PendingRequestFn[] = [];

  private started = false;

  constructor(private readonly App: ConsumerApp) {
    this.dataBus = new WebDataBus(this.App, (err) => console.error('data bus err', err));
    this.requestHub = new RequestHub(
      'asd',
      this.dataBus.messageStream.pipe(filter((msg): msg is TDecodedResMessage => msg.type === 'res')),
      this.dataBus.sendMessage
    );
  }

  public registerRequestRouter(router: IRequestRouter): void {
    if (this.responseHub) {
      throw new Error('A router has already been set!');
    }

    this.responseHub = new ResponseHub(
      router,
      this.dataBus.messageStream.pipe(
        filter((msg): msg is TDecodedReqMessage => msg.type === 'req'),
        tap((msg) => console.debug(`${msg.name} ${msg.id}`))
      ),
      this.dataBus.sendMessage
    );
  }

  public start(): void {
    if (!this.responseHub) {
      throw new Error('responseHub not set!');
    }

    this.responseHub.start();
    this.dataBus.start();

    this.started = true;
    this.sendPendingRequests();
  }

  public stop(): void {
    if (!this.responseHub) {
      throw new Error('responseHub not set!');
    }

    this.started = false;
    this.responseHub.stop();
    this.dataBus.stop();
  }

  public sendRequest<I, O>(reqRes: IReqRes<I, O>, args: I): Promise<O> {
    if (this.started) {
      return this.requestHub.sendRequest(reqRes, args);
    }

    return new Promise((resolve) => {
      this.pendingRequestFns.push(() => {
        resolve(this.requestHub.sendRequest(reqRes, args));
      });
    });
  }

  public sendRequestWithRetry<I, O>(
    reqRes: IReqRes<I, O>,
    args: I,
    { tries = 3, timeout = 1000 }: { tries?: number; timeout?: number } = {}
  ): Promise<O> {
    return backoffRetry(() => this.sendRequest(reqRes, args), tries, timeout);
  }

  public isFeatureEnabled(feature: NativeAppFeature): boolean {
    const { version } = this.App;

    switch (feature) {
      case NativeAppFeature.NATIVE_GROUP_PAYMENTS:
        return compare(version, '3.1.145', '>=');
      default:
        throw enforceExhaustive(feature);
    }
  }

  private sendPendingRequests(): void {
    if (!this.started) {
      throw new Error("Can't send pending requests! NativeAppMessenger hasn't been started.");
    }

    const { pendingRequestFns } = this;
    this.pendingRequestFns = [];

    pendingRequestFns.forEach((fn) => fn());
  }
}
