import { StorageKeys } from 'common/storage/constants';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { HelpButtonState } from './types';

export const initialHelpButtonState: HelpButtonState = {
  isShowing: true,
  hasSeenNewUserEducationModal: (() => {
    try {
      const item = getBrowserStorage().getItem(StorageKeys.NEW_USER_EDUCATION_MODAL);
      return item ? JSON.parse(item) : false;
    } catch {
      return false;
    }
  })(),
};
