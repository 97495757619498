import { QueryParams } from 'common/urls/QueryParams';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useZipCode } from 'src/shared/hooks/useZipCode';
import { SEARCH_DEBOUNCE_TIME } from 'src/store/search';
import { useCombinedSearchResults } from './useCombinedSearch';
import { useGetSearchResults } from './useGetSearch';
import { useSearchQueryReducer } from './useSearchQuery/useSearchQueryReducer';

export const useSearchDataProviderProps = () => {
  const updateSearchRef = useRef(0);

  const { changeSearchQuery, searchQuery } = useSearchQueryReducer();

  const {
    data,
    error,
    handleSearch,
    handleShowMoreShefs,
    handleShowMoreDishes,
    hasMoreResults,
    isMoreResultsLoading,
    loading,
    updateQueryParams,
    zipCode: activeZipCode,
  } = useGetSearchResults({ query: searchQuery });
  const combineSearchResults = useCombinedSearchResults({ query: searchQuery });
  const { availableToCustomers, loading: zipCodeDataLoading } = useZipCode(activeZipCode);

  const debouncedSearchRef = useRef(debounce(handleSearch, SEARCH_DEBOUNCE_TIME));
  const handleDebouncedSearch = debouncedSearchRef.current;

  const handleClearSearch = useCallback(() => {
    changeSearchQuery('');
    updateQueryParams({ [QueryParams.CONSUMER_SEARCH]: '' }, 'replaceIn');
  }, [updateQueryParams, handleSearch]);

  const handleQueryChange = useCallback(
    (newQuery: string, isPartialQuery: boolean) => {
      changeSearchQuery(newQuery);
      updateQueryParams({ [QueryParams.CONSUMER_SEARCH]: newQuery }, 'replaceIn');
      return handleDebouncedSearch(newQuery, isPartialQuery);
    },
    [updateQueryParams, handleDebouncedSearch]
  );

  const refreshSearch = useCallback(() => {
    // eslint-disable-next-line functional/immutable-data -- Timeout
    updateSearchRef.current = window.setTimeout(() => {
      // eslint-disable-next-line functional/immutable-data -- Update search
      debouncedSearchRef.current = debounce(handleSearch, SEARCH_DEBOUNCE_TIME);
      handleSearch(searchQuery, false);
    }, 100);
  }, [handleSearch, searchQuery]);

  const handleDebouncedSearchCombined = useMemo(
    () => debounce(combineSearchResults.handleSearch, SEARCH_DEBOUNCE_TIME),
    []
  );
  const dataCombinedSearch = {
    ...combineSearchResults,
    activeZipCode,
    handleClearSearch: useCallback(() => {
      changeSearchQuery('');
      updateQueryParams({ [QueryParams.CONSUMER_SEARCH]: '' }, 'replaceIn');
      return combineSearchResults.handleSearch('');
    }, [updateQueryParams, combineSearchResults]),
    handleQueryChange: useCallback(
      (newQuery: string) => {
        changeSearchQuery(newQuery);
        updateQueryParams({ [QueryParams.CONSUMER_SEARCH]: newQuery }, 'replaceIn');
        return handleDebouncedSearchCombined(newQuery);
      },
      [updateQueryParams, handleDebouncedSearchCombined]
    ),
    searchQuery,
  };

  useEffect(
    () => () => {
      clearTimeout(updateSearchRef.current);
    },
    []
  );

  return {
    activeZipCode,
    data,
    dataCombinedSearch,
    error,
    loading,
    isMoreResultsLoading,
    hasMoreResults,
    handleQueryChange,
    handleShowMoreShefs,
    handleShowMoreDishes,
    handleClearSearch,
    isSearchHidden: !activeZipCode || (zipCodeDataLoading ? false : !availableToCustomers),
    refreshSearch,
  };
};
