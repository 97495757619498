import { StorageKeys } from 'common/storage/constants';
import { useCallback } from 'react';
import { useIsContentShowing, useSearchState } from 'src/pages/consumer/components/DishFirstHeader/store';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { useDispatch, useSelector } from 'src/store';
import { actions, selectors } from 'src/store/help/button';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';

interface HelpButtonState {
  helpButtonIsVisible: boolean;
  hasSeenNewUserEducationModal: boolean;
}

export const initialHelpButtonState: HelpButtonState = {
  helpButtonIsVisible: true,
  hasSeenNewUserEducationModal: (() => {
    try {
      const item = getBrowserStorage().getItem(StorageKeys.NEW_USER_EDUCATION_MODAL);
      return item ? JSON.parse(item) : false;
    } catch {
      return false;
    }
  })(),
};

export const useHelpButtonState = (): HelpButtonState & {
  show: () => void;
  hide: () => void;
  setHasSeenNewUserEducationModal: (value: boolean) => void;
} => {
  const dispatch = useDispatch();
  const { isSmallScreen } = useScreenContext();
  const hasSeenNewUserEducationModal = useSelector(selectors.selectHasSeenNewUserEducationModal);
  const isHelpButtonShowing = useSelector(selectors.selectIsHelpButtonShowing);
  const isSearchShowing = useSearchState();
  const isContentShowing = useIsContentShowing();

  const show = useCallback(() => {
    dispatch(actions.showHelpButton());
  }, [dispatch]);

  const hide = useCallback(() => {
    dispatch(actions.hideHelpButton());
  }, [dispatch]);

  const setHasSeenNewUserEducationModal = useCallback(
    (hasSeenModal: boolean) => {
      dispatch(actions.setHasSeenNewUserEducationModal({ hasSeenModal }));
    },
    [dispatch]
  );
  const showNavContents = isSmallScreen && isContentShowing;
  const showSearch = isSmallScreen && isSearchShowing;
  const helpButtonIsVisible = isHelpButtonShowing && !showSearch && !showNavContents;

  return {
    helpButtonIsVisible,
    hasSeenNewUserEducationModal,
    show,
    hide,
    setHasSeenNewUserEducationModal,
  };
};
