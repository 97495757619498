import { ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { IVariant } from 'common/experiments/IExperimentManager';
import { useCallback, useContext, useMemo } from 'react';
import { ExperimentContext } from './ExperimentContext';
import { useHasValidIdentifier } from './useHasValidIdentifier';

function fetchVariant(
  canGetVariant: boolean,
  getVariant: (experimentName: ExperimentNames, logExposure: boolean) => IVariant | null,
  name: ExperimentNames,
  logExposureImmediately: boolean
) {
  if (!canGetVariant) return null;
  return getVariant(name, logExposureImmediately);
}

export function useVariant(name: ExperimentNames, logExposureImmediately = true) {
  const { getVariant, logExposure: expCtxLogExposure } = useContext(ExperimentContext);
  const canGetVariant = useHasValidIdentifier(name);
  const variant = useMemo(
    () => fetchVariant(canGetVariant, getVariant, name, logExposureImmediately),
    [canGetVariant, getVariant, logExposureImmediately, name]
  );
  const logExposure = useCallback(() => expCtxLogExposure(name), [expCtxLogExposure, name]);

  return useMemo(() => ({ variant, logExposure } as const), [variant, logExposure]);
}
