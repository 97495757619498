import { StorageKeys } from 'common/storage/constants';
import { useEffect, useRef, useState } from 'react';
import { RouteChildrenProps } from 'react-router';
import { isDevEnvironment } from 'src/shared/utils/EnvironmentUtilities';
import { useLocalStorage } from '../storage/Storage';

// Let's throttle reloading by "reloadIntervalMinutes" in the case of delayed Web deploy...
const reloadIntervalMinutes = 5;
const reloadIntervalMs = reloadIntervalMinutes * 60 * 1000;

const timestampFallback = isDevEnvironment() ? 'development' : undefined;
export const BUILD_TIMESTAMP = process.env.REACT_APP_BUILD_TIMESTAMP ?? timestampFallback;

export const useApiSync = (history: RouteChildrenProps['history']) => {
  const [needToUpdate, setNeedToUpdate] = useState<boolean>(false);
  const currentLatestVersion = useRef<string>();

  const [lastReloadTimestamp, setLastReloadTimestamp] = useLocalStorage<string | null>(
    StorageKeys.LAST_RELOAD_TIMESTAMP,
    null
  );

  // when url changes, take current saved version,
  // and then compare it to the initial one from build
  useEffect(() => {
    if (!currentLatestVersion.current || !BUILD_TIMESTAMP || currentLatestVersion.current === BUILD_TIMESTAMP) {
      return;
    }

    const lastReloadDate = lastReloadTimestamp && new Date(+lastReloadTimestamp);
    if (lastReloadDate) {
      const diffMs = Date.now() - lastReloadDate.getTime();

      if (diffMs <= reloadIntervalMs) {
        return;
      }
    }

    setNeedToUpdate(true);
    setLastReloadTimestamp(Date.now().toString());
  }, [history.location.pathname, lastReloadTimestamp, setLastReloadTimestamp]);

  // after running that comparison fetch a new version to compare against next navigation
  useEffect(() => {
    fetch('/version')
      .then((response) => response.json())
      .then((body) => {
        if (body && body.version && typeof body.version === 'string') {
          currentLatestVersion.current = body.version;
        } else {
          console.error('Malformed version body', body);
        }
      })
      .catch(console.error);
  }, [history.location.pathname]);

  return [needToUpdate];
};
