import { chain, ValueKeyIteratee } from 'lodash';

/**
 * Returns a new array with the item added to the array if it doesn't already exist
 * or removed from the array if it does exists.
 */
export const arrayToggle = <Item>(items: Item[], item: Item): Item[] => {
  if (items.includes(item)) {
    return items.filter((previousItem) => previousItem !== item);
  }

  return [...items, item];
};

export const findAllIndexes = <T>(array: T[], predicate?: ValueKeyIteratee<T>): number[] => {
  return chain(array).pickBy(predicate).keys().map(Number).value();
};
