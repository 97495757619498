import styled from 'styled-components';
import { Spacing } from 'web-common/src/shared/styles';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.div`
  margin-left: ${Spacing.HALF};
`;
