import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const ZoomIn = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
    <Path
      d='M21.56,19.44l-3.23-3.23C19.38,14.74,20,12.94,20,11c0-4.97-4.03-9-9-9s-9,4.03-9,9c0,4.97,4.03,9,9,9
          c1.94,0,3.74-0.62,5.21-1.67l3.23,3.23C19.73,21.85,20.12,22,20.5,22s0.77-0.15,1.06-0.44C22.15,20.97,22.15,20.03,21.56,19.44z
          M5,11c0-3.31,2.69-6,6-6s6,2.69,6,6s-2.69,6-6,6S5,14.31,5,11z M15.5,11c0,0.83-0.67,1.5-1.5,1.5h-1.5V14c0,0.83-0.67,1.5-1.5,1.5
          S9.5,14.83,9.5,14v-1.5H8c-0.83,0-1.5-0.67-1.5-1.5S7.17,9.5,8,9.5h1.5V8c0-0.83,0.67-1.5,1.5-1.5s1.5,0.67,1.5,1.5v1.5H14
          C14.83,9.5,15.5,10.17,15.5,11z'
    />
  </SVG>
);
