import { oneLine } from 'common-tags';
import { match, P } from 'ts-pattern';
import { ReferralShareLocation, ShareType } from './events/ClientEvents';
import { formatCurrency, numberToEnglish } from './FormatUtilities';

type MinimalReferralCode = { getValue: number; giveValue: number; maxUsesPerUser?: number | undefined | null };
type GetReferralCodeTextParams = {
  referralCode: MinimalReferralCode;
  location?: ReferralShareLocation;
};
type OrderType = {
  uuid: string;
  date: string;
};

type OptionalReferralCodeTextParams<P> =
  | (P & { shefFirstName: string; foodItemName: string; order?: OrderType })
  | (P & { shefFirstName: string; foodItemName?: undefined; order?: OrderType })
  | (P & { shefFirstName?: undefined; foodItemName: string; order?: OrderType })
  | (P & { shefFirstName?: undefined; foodItemName?: undefined; order?: OrderType });

/**
 * Formats the give value of a referral code into a human-readable string
 */
const formatGiveValue = (referralCode: Pick<MinimalReferralCode, 'giveValue'>) =>
  formatCurrency(referralCode.giveValue, true);

/**
 * Formats the total value of a referral code into a human-readable string
 */
export const formatTotalValue = (referralCode: Pick<MinimalReferralCode, 'giveValue' | 'maxUsesPerUser'>): string =>
  formatCurrency(referralCode.giveValue * (referralCode.maxUsesPerUser ?? 1), true);

/**
 * Formats the get value of a referral code into a human-readable string
 */
const formatGetValue = (referralCode: Pick<MinimalReferralCode, 'getValue'>) =>
  formatCurrency(referralCode.getValue, true);

/**
 * Formats the max orders of a referral code into a human-readable string
 */
export const formatNumOrders = (
  referralCode: Pick<MinimalReferralCode, 'maxUsesPerUser'>,
  includeShef = false
): string => {
  const maxUsesPerUser = referralCode.maxUsesPerUser ?? 1;
  return maxUsesPerUser === 1
    ? `${includeShef ? 'Shef ' : ''}order`
    : `${numberToEnglish(maxUsesPerUser)} ${includeShef ? 'Shef ' : ''}orders`;
};

// Banner requires slightly different language
export const formatNumOrdersBanner = (remainingUses: number): string =>
  remainingUses > 1 ? `${numberToEnglish(remainingUses)} orders` : 'order';

export const formatNumOrdersReferralBanner = (remainingUses: number): string =>
  match({ remainingUses })
    .with({ remainingUses: 0 }, () => '')
    .with({ remainingUses: 1 }, () => 'your next order')
    .with({ remainingUses: 2 }, () => 'each of your first two orders')
    .otherwise(() => `each of your first ${remainingUses} orders`);
/**
 * Formats the value per order of a referral code into a human-readable string
 */
const formatValuePerOrder = (referralCode: Pick<MinimalReferralCode, 'giveValue' | 'maxUsesPerUser'>): string => {
  const maxUsesPerUser = referralCode.maxUsesPerUser ?? 1;
  const ordersText = maxUsesPerUser === 1 ? 'order' : 'orders';
  return `${formatCurrency(referralCode.giveValue, true)} off first ${maxUsesPerUser} ${ordersText}`;
};

/**
 * Generates the referral amount for a given referral code
 */
export const getReferralCodeGetAmount = ({ referralCode }: GetReferralCodeTextParams): string =>
  `Get ${formatGetValue(referralCode)}`;

/**
 * Generates the referral page title for a given referral code
 */
export const getReferralCodeTitle = ({ referralCode }: GetReferralCodeTextParams): string =>
  `Give ${formatTotalValue(referralCode)}, Get ${formatGetValue(referralCode)}`;

/**
 * Generates the referral page description for a given referral code
 */
export const getReferralCodeDescription = (
  params: OptionalReferralCodeTextParams<GetReferralCodeTextParams>
): string => {
  const { referralCode, shefFirstName, foodItemName } = params;

  const getValueText = formatGetValue(referralCode);
  const giveValueText = formatGiveValue(referralCode);
  const numOrdersText = formatNumOrders(referralCode);

  const maxUsesPerUser = referralCode.maxUsesPerUser ?? 1;
  const totalGiveValueText = maxUsesPerUser > 1 ? ` (${formatTotalValue(referralCode)} total)` : '';

  return match({ shefFirstName, foodItemName })
    .with(
      { shefFirstName: P.string, foodItemName: P.string },
      () => oneLine`
        Loving Shef ${shefFirstName}'s ${foodItemName}? Share it with a friend & earn ${getValueText}
        in credits when they sign up and place an order using your referral link.
        Your friend gets ${giveValueText} off their first ${numOrdersText}${totalGiveValueText}.`
    )
    .with(
      { shefFirstName: P.string },
      () => oneLine`
        Enjoying Shef ${shefFirstName}'s cooking? Share their menu with a friend & earn ${getValueText}
        in credits when they sign up and place an order using your referral link.
        Your friend gets ${giveValueText} off their first ${numOrdersText}${totalGiveValueText}.`
    )
    .otherwise(
      () => oneLine`
        Earn ${getValueText} credit per friend that signs up and orders using your link.
        They also get ${giveValueText} off their first ${numOrdersText}.`
    );
};

/**
 * Generates the SMS title for a shared referral code
 */
const getSMSShareMessage = (params: OptionalReferralCodeTextParams<GetReferralCodeTextParams>) => {
  const { referralCode, shefFirstName, foodItemName, order, location } = params;

  const giveValueText = formatGiveValue(referralCode);
  const numOrdersText = formatNumOrders(referralCode);

  return match({ shefFirstName, foodItemName, orderUuid: order?.uuid, orderDate: order?.date, location })
    .with({ location: ReferralShareLocation.WAITLIST }, () => ({
      title: oneLine`
         Hey! I just signed up to get early access to Shef. It's a way to find homemade meals from
         cooks in our neighborhood. It's way cheaper and healthier than eating out. If you use my 
         referral code we'll both get early access to the platform. They were on CBS and Forbes recently
         and I'm excited to try it soon!`,
    }))
    .with({ orderUuid: P.string, orderDate: P.string }, () => ({
      title: oneLine`
          Hey! Have you heard of Shef? I've been getting a variety of homemade meals
          delivered from local cooks through them, I think you'd love it!
          Check out what I ordered on ${order?.date}: `,
    }))
    .with({ shefFirstName: P.string, foodItemName: P.string }, () => ({
      title: oneLine`
        Try Shef ${shefFirstName}'s ${foodItemName}! It's one of my favorite dishes; you'd love it!
        Use my link for ${giveValueText} off your first ${numOrdersText}: `,
    }))
    .with({ shefFirstName: P.string }, () => ({
      title: oneLine`
        Check out Shef ${shefFirstName}'s menu! I thoroughly enjoy my homemade meals from them; you'd love it!
        Use my link for ${giveValueText} off your first ${numOrdersText}: `,
    }))
    .otherwise(() => ({
      title: oneLine`
        Hey! Have you heard of Shef? I've been getting a variety of homemade meals
        delivered from local cooks through them, I think you'd love it!
        Use my link for ${giveValueText} off your first ${numOrdersText}: `,
    }));
};

/**
 * Generates the social title and message for a shared referral code
 */
const getSocialShareMessage = (
  params: OptionalReferralCodeTextParams<GetReferralCodeTextParams & { shareType: ShareType }>
) => {
  const { referralCode, shefFirstName, foodItemName, order, location, shareType } = params;

  const maxUsesPerUser = referralCode.maxUsesPerUser ?? 1;
  const totalValueText = formatTotalValue(referralCode);
  const valuePerOrderText = maxUsesPerUser > 1 ? `(${formatValuePerOrder(referralCode)}) ` : '';
  const titleField = shareType === ShareType.FACEBOOK ? 'quote' : 'title';

  return match({ shefFirstName, foodItemName, orderUuid: order?.uuid, orderDate: order?.date, location })
    .with({ location: ReferralShareLocation.WAITLIST }, () => ({
      [titleField]: oneLine`
         Hey! I just signed up to get early access to Shef. It's a way to find homemade meals from
         cooks in our neighborhood. It's way cheaper and healthier than eating out. If you use my 
         referral code we'll both get early access to the platform. They were on CBS and Forbes recently
         and I'm excited to try it soon!`,
    }))
    .with({ orderUuid: P.string, orderDate: P.string }, () => ({
      [titleField]: oneLine`
          I've been getting such a variety of amazing meals delivered from local cooks with @Shef.
          It's the perfect balance of fresh, homemade food & supporting local.
          Check out what I ordered on ${order?.date}: `,
    }))
    .with({ shefFirstName: P.string, foodItemName: P.string }, () => ({
      [titleField]: oneLine`
        I have been loving getting homemade meals delivered from local cooks with @Shef.
        One of my favorites is Shef ${shefFirstName}'s ${foodItemName}.
        Try it and get ${totalValueText} off ${valuePerOrderText}using my link: `,
      message: `Want fresh, authentic, home-style food? Try Shef ${shefFirstName}'s ${foodItemName}!`,
    }))
    .with({ shefFirstName: P.string }, () => ({
      [titleField]: oneLine`
        I have been loving getting homemade meals delivered from local cooks with @Shef.
        Try one of my favorites, Shef ${shefFirstName}, and get ${totalValueText} off
        ${valuePerOrderText}using my link: `,
      message: `Want fresh, authentic, home-style food? Check out Shef ${shefFirstName}'s menu!`,
    }))
    .otherwise(() => ({
      [titleField]: oneLine`
        I've been getting such a variety of amazing meals delivered from local cooks with @Shef.
        It's the perfect balance of fresh, homemade food & supporting local.
        Get ${totalValueText} off to try it ${valuePerOrderText}using my link: `,
    }));
};

type TEmailShareType = {
  subject: string;
  body: string;
};
/**
 * Generates the email subject and body for a shared referral code
 */
export const getEmailShareMessage = (
  params: OptionalReferralCodeTextParams<GetReferralCodeTextParams & { referrerFirstName: string }>
): TEmailShareType => {
  const { referralCode, referrerFirstName, shefFirstName, foodItemName, order, location } = params;

  const maxUsesPerUser = referralCode.maxUsesPerUser ?? 1;
  const totalValueText = formatTotalValue(referralCode);

  return match({ shefFirstName, foodItemName, orderUuid: order?.uuid, orderDate: order?.date, location })
    .with({ location: ReferralShareLocation.WAITLIST }, () => ({
      subject: `${referrerFirstName} wants to give you early access to Shef!`,
      body: oneLine`
         Hey! I just signed up to get early access to Shef. It's a way to find homemade meals from
         cooks in our neighborhood. It's way cheaper and healthier than eating out. If you use my 
         referral code we'll both get early access to the platform. They were on CBS and Forbes recently
         and I'm excited to try it soon!`,
    }))
    .with({ orderUuid: P.string, orderDate: P.string }, () => ({
      subject: `${referrerFirstName} is sharing what they ordered from Shef!`,
      body: oneLine`
          Check out my order on ${order?.date}.
          I thoroughly enjoy my homemade meals from them and I think you'd love it!
          The dishes are so authentic & delicious - plus, they're all delivered right to your door.
          View my order here: `,
    }))
    .with({ shefFirstName: P.string, foodItemName: P.string }, () => {
      const giveValueText = formatCurrency(referralCode.giveValue, true);
      const numOrdersText = formatNumOrders(referralCode);

      return {
        subject: `${referrerFirstName} gifted you ${totalValueText} to try Shef ${shefFirstName}'s ${foodItemName}!`,
        body: oneLine`
          Try Shef ${shefFirstName}'s ${foodItemName}! It's one of my favorites and I think you'd love it!
          Dishes on Shef are so authentic & delicious - plus, they're all delivered right to your door.
          Get ${giveValueText} off your first ${numOrdersText} when you order using my link: `,
      };
    })
    .with({ shefFirstName: P.string }, () => {
      const giveValueText = formatCurrency(referralCode.giveValue, true);
      const numOrdersText = formatNumOrders(referralCode);

      return {
        subject: `${referrerFirstName} gifted you ${totalValueText} to try Shef ${shefFirstName}!`,
        body: oneLine`
          Check out Shef ${shefFirstName}'s menu!
          I thoroughly enjoy my homemade meals from them and I think you'd love it!
          The dishes are so authentic & delicious - plus, they're all delivered right to your door.
          Get ${giveValueText} off your first ${numOrdersText} when you order using my link: `,
      };
    })
    .otherwise(() => {
      const valuePerOrderText = maxUsesPerUser > 1 ? `(${formatValuePerOrder(referralCode)}) ` : '';

      return {
        subject: `${referrerFirstName} gifted you ${totalValueText} to try Shef!`,
        body: oneLine`
          I love discovering & supporting the local cooks in my area and I think you will too!
          Shef is an amazing community that has allowed me to enjoy such a variety of authentic,
          homemade meals - all delivered to my door. Here's ${totalValueText} off to try it
          ${valuePerOrderText}when you order using my link: `,
      };
    });
};

type TDefaultShareType = {
  title: string;
};

type TFacebookShareType = {
  ['quote']?: string;
  ['title']?: string;
};

/**
 * Generates the corresponding message data for a given referral code and share type
 */
export const getShareMessage = ({
  referralCode,
  referrerFirstName,
  shareType,
  shefFirstName,
  foodItemName,
  order,
  location,
}: OptionalReferralCodeTextParams<
  GetReferralCodeTextParams & {
    shareType: ShareType;
    referrerFirstName: string;
    location: ReferralShareLocation;
  }
>): TEmailShareType | TDefaultShareType | TFacebookShareType =>
  match(shareType)
    .with(ShareType.EMAIL, () =>
      getEmailShareMessage({ referralCode, referrerFirstName, shefFirstName, foodItemName, order, location })
    )
    .with(ShareType.TEXT, () => getSMSShareMessage({ referralCode, shefFirstName, foodItemName, order, location }))
    .otherwise(() => getSocialShareMessage({ referralCode, shefFirstName, foodItemName, order, location, shareType }));
