import { Time } from 'common/Constants';
import { COOKIE_SESSION_KEY } from 'common/cookies/Cookies';
import {
  deserializeSessionCookie,
  getSessionCookieProperties,
  serializeSessionCookie,
} from 'common/cookies/CookieUtils';
import { ISession, ISessionManager, isSession, SessionHandler } from 'common/sessions';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { getDomain } from '../shared/utils/RouteUtilities';

export class CookieBackedSessionManager implements ISessionManager {
  private session: ISession | null = null;

  private newSessionHandlers: SessionHandler[] = [];

  public readonly sessionTimeoutMs: number;

  private readonly getTime: () => number;

  constructor({
    getTime = Date.now,
    sessionTimeoutMs = Time.ONE_MINUTE_IN_MS * 360,
  }: {
    readonly getTime?: () => number;
    readonly sessionTimeoutMs?: number;
  }) {
    this.sessionTimeoutMs = sessionTimeoutMs;
    this.getTime = getTime;
  }

  public getOrCreateSession(): Readonly<ISession> {
    this.session = this.session ?? this.getSessionFromStorage();

    if (!this.session) {
      return this.replaceWithNewSession();
    }

    const isSessionTooOld = this.isSessionTooOld(this.session);
    return isSessionTooOld ? this.replaceWithNewSession() : this.session;
  }

  public addNewSessionListener(handler: SessionHandler) {
    this.newSessionHandlers = this.newSessionHandlers.concat(handler);
  }

  public removeNewSessionListener(handler: SessionHandler) {
    this.newSessionHandlers = this.newSessionHandlers.filter((existingHandler) => existingHandler !== handler);
  }

  public touch(): void {
    const session = this.getOrCreateSession();
    this.replaceSession({ ...session, updatedAt: this.getTime() });
  }

  public clear(): void {
    this.session = null;
    Cookies.remove(COOKIE_SESSION_KEY);
  }

  private replaceWithNewSession(): ISession {
    const session = this.createSession();
    this.replaceSession(session);
    return session;
  }

  private getSessionFromStorage(): ISession | null {
    const maybeSession = Cookies.get(COOKIE_SESSION_KEY);
    if (!maybeSession) {
      return null;
    }

    try {
      const session = deserializeSessionCookie(maybeSession);
      return isSession(session) ? session : null;
    } catch (err) {
      console.warn('Dropping stored session because of parse error', err);
      return null;
    }
  }

  private isSessionTooOld(session: ISession): boolean {
    return this.getTime() - session.updatedAt > this.sessionTimeoutMs;
  }

  private createSession(): ISession {
    const now = this.getTime();
    return {
      id: uuid(),
      startedAt: now,
      updatedAt: now,
    };
  }

  private replaceSession(session: ISession): void {
    const isNewSession = session.id !== this.session?.id;
    this.session = session;
    const domain = getDomain();
    const properties = getSessionCookieProperties(domain);
    Cookies.set(COOKIE_SESSION_KEY, serializeSessionCookie(session), properties);

    if (isNewSession) {
      this.newSessionHandlers.forEach((handler) => {
        try {
          handler(session);
        } catch (err) {
          console.error(err);
        }
      });
    }
  }
}
