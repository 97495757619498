import styled from 'styled-components';
import { Colors, FontFamily, FontSize, FontWeight, LineHeight } from 'web-common/src/shared/styles';

interface AnchorProps {
  fontFamily?: FontFamily;
  size?: FontSize;
  color?: Colors;
  lineHeight?: LineHeight;
  fontWeight?: FontWeight;
}

export const Anchor = styled.a<AnchorProps>`
  color: ${({ color = Colors.RADISH }) => color};
  font-family: ${({ fontFamily = FontFamily.SANS }) => fontFamily};
  font-size: ${({ size = 'inherit' }) => size};
  line-height: ${({ lineHeight = 'inherit' }) => lineHeight};
`;
