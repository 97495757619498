import { useGlobalReducer } from 'src/shef-global-state/shefGlobalState';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';

export const useDeliveryFee = () => {
  const { state } = useGlobalReducer();
  const [zipCode, , zipCodeMetaData] = useCachedZipCode();
  const { deliveryFee, minimumDiscountedShippingAmount, discountedDeliveryFee } = zipCodeMetaData;

  return {
    deliveryFee: state.deliveryFee ?? deliveryFee,
    minimumAmountForDiscountedShipping: state.minimumAmountForDiscountedShipping ?? minimumDiscountedShippingAmount,
    zipCode,
    discountedDeliveryFee: state.discountedDeliveryFee ?? discountedDeliveryFee,
  };
};
