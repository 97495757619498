import { backoffRetry } from 'common/PromiseUtils';
import { isEmpty } from 'lodash';
import { toast, ToastOptions } from 'react-toastify';
import { ToastContent, ToastState } from 'src/shared/design-system/Toast';

interface Options extends ToastOptions {
  icon?: JSX.Element;
}

backoffRetry(() => import(/* webpackPrefetch: true */ 'react-toastify/dist/ReactToastify.min.css'), 3, 250).catch(
  console.error
);

toast.configure({
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: true,
});

export const Notifications = {
  info(data: string | JSX.Element, options: ToastOptions = {}) {
    if (!isEmpty(data)) {
      toast(<ToastContent toastState={ToastState.INFO}>{data}</ToastContent>, { type: 'info', ...options });
    }
  },
  error(data: string | JSX.Element, options: ToastOptions = {}) {
    if (!isEmpty(data)) {
      console.error(data);
      toast(<ToastContent toastState={ToastState.ERROR}>{data}</ToastContent>, { type: 'error', ...options });
    }
  },
  warning(data: string | JSX.Element, options: ToastOptions = {}) {
    if (!isEmpty(data)) {
      console.warn(data);
      toast(<ToastContent toastState={ToastState.WARNING}>{data}</ToastContent>, { type: 'warning', ...options });
    }
  },
  success(data: string | JSX.Element, options: Options = {}) {
    const { icon, ...rest } = options;
    if (!isEmpty(data)) {
      toast(
        <ToastContent toastState={ToastState.SUCCESS} icon={icon}>
          {data}
        </ToastContent>,
        { type: 'success', ...rest }
      );
    }
  },
};

export default Notifications;
