import { CartState } from '../types';

export const selectBulkDiscountTiers = (state: CartState) => state.bulkDiscountTiers;

export const selectDiscountApplied = (state: CartState) => state.discountApplied;
export const selectPromoApplied = (state: CartState) => state.promoApplied;
export const selectPromoCode = (state: CartState) => state.promoCode;

export const selectTotalDiscountApplied = (state: CartState) =>
  (state.discountApplied ?? 0) + (state.promoApplied ?? 0);
