import { QueryParams } from 'common/urls/QueryParams';
import { DishFirstSegmentFilter } from 'src/gqlReactTypings.generated.d';
import { QueryParamsUpdates } from '../../explore/useExploreUrl';

export type DishFirstQueryParamsUpdates = QueryParamsUpdates<DishFirstSegmentFilter>;
export type FilterUpdates = Required<{
  dietaryFilters: DishFirstQueryParamsUpdates[QueryParams.DIETARY_FILTER];
  dishTypeFilters: DishFirstQueryParamsUpdates[QueryParams.DISH_TYPE_FILTER];
  isEcoFriendlyPackaging: DishFirstQueryParamsUpdates[QueryParams.ECO_FILTER];
  minRatingFilter: DishFirstQueryParamsUpdates[QueryParams.MIN_RATING_FILTER];
  priceLevelFilters: DishFirstQueryParamsUpdates[QueryParams.PRICE_LEVEL_FILTER];
  proteinFilters: DishFirstQueryParamsUpdates[QueryParams.PROTEIN_FILTER];
  segmentFilters: DishFirstQueryParamsUpdates[QueryParams.SEGMENT_FILTER];
  spiceLevelFilters: DishFirstQueryParamsUpdates[QueryParams.SPICE_LEVEL_FILTER];
  cuisineFilters: DishFirstQueryParamsUpdates[QueryParams.CUISINE_FILTER];
  browseFilter: DishFirstQueryParamsUpdates[QueryParams.BROWSE_FILTER];
}>;

export enum PageHeroType {
  FilterBanner = 'FILTER_BANNER',
  Main = 'MAIN',
  Loading = 'LOADING',
  RecommendedShefs = 'RECOMMENDED_SHEFS',
  FilterBannerRecommendedShefs = 'FILTER_BANNER_RECOMMENDED_SHEFS',
  CuisineCategoryPage = 'CUISINE_CATEGORY_PAGE',
  VerifiedDietaryPage = 'VERIFIED_DIETARY_PAGE',
}

export const DEFAULT_IS_ECO_FRIENDLY_PACKAGING = false;

export const MEAL_TYPE_SELECTOR = 'meal-collection';
export const MEAL_TYPE_CONTAINER_ID = 'browse-by-meal-type-container';
export const MEAL_TYPE_SECTION_OFFSET_TOP = 20;
