import React from 'react';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';

const verticalStyle = css`
  flex-direction: column;
  > li {
    margin-bottom: ${Spacing.QUARTER};
    margin-right: ${Spacing.NONE};
  }
`;

const horizontalStyle = css`
  > li {
    margin: ${Spacing.NONE};
    display: flex;
    align-items: center;
  }

  > li:after {
    content: '•';
    color: ${Colors.GRAY_DARK_10};
    line-height: ${Font.LineHeight.XXXS};
    margin: 0 ${Spacing.HALF};
  }

  > li:onlychild:after {
    content: none;
  }

  > li:last-child:after {
    content: none;
  }
`;

const BadgeContainerList = styled.ul<{ vertical?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${Spacing.NONE};
  list-style: none;
  margin: ${Spacing.NONE};

  ${ifProp('vertical', verticalStyle, horizontalStyle)}
`;

interface IBadgeContainerProps {
  className?: string;
  children?: React.ReactNode;
  vertical?: boolean;
}

export const BadgeContainer: React.FC<IBadgeContainerProps> = ({ className, children, vertical }) => (
  <BadgeContainerList vertical={vertical} className={className}>
    {React.Children.map(children, (child, index) => child && <li key={index}>{child}</li>)}
  </BadgeContainerList>
);
