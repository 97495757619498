import React from 'react';
import { DEFAULT_SIZE, DEFAULT_STROKE_WIDTH } from './consts';
import { Bar, BarFill, Container } from './styles';
import { RadialProps, StartDirection } from './types';

export const getCircleProps = (percent: number, diameter = DEFAULT_SIZE, strokeWidth = DEFAULT_STROKE_WIDTH) => {
  const radius = diameter / 2;
  const circleRadius = radius - strokeWidth / 2;
  const strokeDasharray = `${2 * Math.PI * circleRadius * (percent / 100)}, 10000`;
  const sharedCircleProps = { cx: radius, cy: radius, r: circleRadius, strokeWidth };
  return { barProps: sharedCircleProps, barFillProps: { ...sharedCircleProps, style: { strokeDasharray } }, radius };
};

export const Radial: React.FC<RadialProps> = (props) => {
  const {
    backgroundColor,
    children,
    className,
    fillColor,
    percent = 0,
    size = DEFAULT_SIZE,
    startDirection = StartDirection.RIGHT,
    strokeWidth = DEFAULT_STROKE_WIDTH,
  } = props;
  const { barProps, barFillProps, radius } = getCircleProps(percent, size, strokeWidth);

  return (
    <Container className={className} height={size} width={size}>
      <Bar {...barProps} strokeColor={backgroundColor} />
      <BarFill key={`fill-${radius}`} {...barFillProps} startDirection={startDirection} strokeColor={fillColor} />
      {children}
    </Container>
  );
};
