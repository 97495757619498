import { css } from 'styled-components';

/**
 * Styles a container to be a square.
 */
export const square = (size: string) => css`
  height: ${size};
  width: ${size};
`;

/**
 * Styles a container to be a square with min width and min height.
 */
export const squareForcedMinDimensions = (size: string) => css`
  ${square(size)}
  min-height: ${size};
  min-width: ${size};
`;

/**
 * Styles a container to be a circle.
 */
export const circle = (size: string) => css`
  ${square(size)}
  border-radius: ${size};
`;

/**
 * Styles a container to be a circle with min width and min height.
 */
export const circleForcedMinDimensions = (size: string) => css`
  ${squareForcedMinDimensions(size)}
  border-radius: ${size};
`;
