import { useHistory } from 'src/shared/hooks/useHistory';
import { getDishCardUrlParams, getPathName } from 'src/shared/utils/DishCardUtils';
import { useDispatch } from 'src/store';
import { actions } from 'src/store/explore';
import { useDishModalQueryParams } from './hooks/useDishModalQueryParams';
import { OpenDishModalProps } from './types';

export const useDishModalActions = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { clearUrl } = useDishModalQueryParams();

  return {
    closeDishModal: () => {
      clearUrl();
      dispatch(actions.closeDishModal());
    },
    openDishModal: ({ idx, segmentMetrics, foodItemSlug, shefId }: OpenDishModalProps) => {
      const urlParams = getDishCardUrlParams({
        initialParams: history.location.search,
        foodItemSlug,
        shefId,
        segmentMetrics,
        idx,
      });
      history.push(getPathName(history.location.pathname, urlParams), { scrollToTop: false });
      dispatch(actions.openDishModal({ idx, segmentMetrics, foodItemSlug, shefId }));
    },
  };
};
