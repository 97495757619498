import { isAnyOf } from '@reduxjs/toolkit';
import { GqlClient } from 'src/shared/GqlClient';
import { CartSlice } from 'src/store/cart';
import { CheckoutSlice } from 'src/store/checkout';
import { AppListenerEffect, startAppListening } from 'src/store/listenerMiddleware';
import { ITracker } from 'src/tracking/types';
import { clearCartWithServer } from './clearCartWithServer';
import { loadCartFromServer } from './loadCartFromServer';
import { loadShefData } from './loadShefData';
import { persistCartToServer } from './persistCartToServer';
import { syncLineItemQuantityWithServer } from './syncLineItemQuantityWithServer';
import { trackCartEvent } from './trackCartEvent';
import { trackCheckoutEvent } from './trackCheckoutEvent';

interface CreateCartListenersParams {
  gqlClient: GqlClient;
  cartSlice: CartSlice;
  checkoutSlice: CheckoutSlice;
  tracker?: ITracker;
}

export type CreateCartEffect = (params: CreateCartListenersParams) => AppListenerEffect;

// FUTURE TODO: refactor this code to observe an internal queue of writes that should happen
// this is ported from our previous useCart solution which suffers from potential to have race conditions
export function createCartListeners({ cartSlice, checkoutSlice, gqlClient, tracker }: CreateCartListenersParams) {
  // NOTE: explicitly use cartSlice to refer to actions rather than importing directly
  // as some actions are wrapped to handle both cart and editable order updates while
  // we only want to do the various syncing tasks below for the cart state
  const cart = cartSlice.actions;
  const checkout = checkoutSlice.actions;
  const effectParams = { cartSlice, checkoutSlice, gqlClient, tracker };

  // load cart data on at initial page load or when zip code changes
  startAppListening({
    matcher: isAnyOf(cart.init, cart.setZipCode, cart.forceRefetch),
    effect: loadCartFromServer(effectParams),
  });

  // sync line item quantity updates with the server
  startAppListening({
    matcher: isAnyOf(cart.incLineItemQuantity, cart.decLineItemQuantity, cart.setLineItemQuantity, cart.removeLineItem),
    effect: syncLineItemQuantityWithServer(effectParams),
  });

  // clear carts
  startAppListening({
    matcher: isAnyOf(cart.clearCarts, cart.clearCartsByShefIds),
    effect: clearCartWithServer(effectParams),
  });

  // persist cart to server on log in
  startAppListening({
    actionCreator: cart.userLogin,
    effect: persistCartToServer(effectParams),
  });

  // always load shef data as needed
  startAppListening({
    matcher: isAnyOf(cart.setServerCart, cart.incLineItemQuantity, cart.setLineItemQuantity, cart.setActiveCart),
    effect: loadShefData(effectParams),
  });

  // send cart related analytics events
  startAppListening({
    // all events that can add to cart or change delivery date
    matcher: isAnyOf(
      cart.incLineItemQuantity,
      cart.setLineItemQuantity,
      cart.decLineItemQuantity,
      cart.setDeliveryDate,
      cart.setPossibleDeliveryDates,
      cart.setServerCart
    ),
    // do not make function async or listenerApi.getOriginalState()
    // will provide unstable results
    effect: trackCartEvent(effectParams),
  });

  // send checkout related analytics events
  startAppListening({
    actionCreator: checkout.startCheckout,
    // do not make function async or listenerApi.getOriginalState()
    // will provide unstable results
    effect: trackCheckoutEvent(effectParams),
  });
}
