import { css } from 'styled-components';
import { getSpacingStyle, SpacingType } from './spacing';

export const set = (topOrAll: SpacingType, right?: SpacingType, bottom?: SpacingType, left?: SpacingType) =>
  css`
    margin: ${getSpacingStyle(topOrAll, right, bottom, left)};
  `;

export const setTop = (top: SpacingType) =>
  css`
    margin-top: ${top};
  `;

export const setBottom = (bottom: SpacingType) =>
  css`
    margin-bottom: ${bottom};
  `;

export const setLeft = (left: SpacingType) =>
  css`
    margin-left: ${left};
  `;

export const setRight = (right: SpacingType) =>
  css`
    margin-right: ${right};
  `;
