import { formatDateTimeUrlDate, urlDateToDateTime } from 'common/UrlDate';
import { DateTime } from 'luxon';

export const HOURS_BEFORE_MIDNIGHT_SKIP_TOMORROW_DEFAULT_DELIVERY_DATE = 6;

// returns our 6pm cutoff time for choosing next day as the default delivery date
export const getNextDayDefaultCuttoff = (now: DateTime = DateTime.local()) =>
  now.endOf('day').minus({ hours: HOURS_BEFORE_MIDNIGHT_SKIP_TOMORROW_DEFAULT_DELIVERY_DATE });

// pick our best guess at a default delivery date for the cart.
// tomorrow or the day after may not be valid delivery dates depending on
// the market the user is in, but they could be so we default to the best one
// and then resolve any issues later after requesting delivery dates from the
// server
export const getDefaultCartDate = (now: DateTime = DateTime.local()) => {
  const daysToAdd = now.toMillis() > getNextDayDefaultCuttoff(now).toMillis() ? 2 : 1;
  const defaultDateTime = now.plus({ days: daysToAdd }).startOf('day');
  return formatDateTimeUrlDate(defaultDateTime);
};

// FUTURE TODO: will need to revist function when adding same day to dish-first
// `now` param is for testing purposes
export function findDefaultDeliveryDate(
  possibleDeliveryDates: string[],
  currentDeliveryDate: string,
  cartHasItems: boolean,
  now: DateTime = DateTime.local(),
  skipTomorrowDefaultDate = false
): string | undefined {
  const today = formatDateTimeUrlDate(now);
  const tomorrow = now.startOf('day').plus({ days: 1 });
  const futureDeliveryDates = possibleDeliveryDates.filter((date) => date > today);
  const [firstDeliveryDate = undefined, secondDeliveryDate = undefined] = futureDeliveryDates;

  // Return nothing if there's no delivery date in the future.
  if (!firstDeliveryDate) return undefined;

  const firstDeliveryDateTime = urlDateToDateTime(firstDeliveryDate, now.zoneName);

  const firstDateIsTomorrow = tomorrow.toMillis() === firstDeliveryDateTime.toMillis();
  const beforeTomorrowCutoffTime = now < getNextDayDefaultCuttoff(now);
  const cartDateIsTomorrowAndHasItems =
    firstDateIsTomorrow && currentDeliveryDate === firstDeliveryDate && cartHasItems;

  const useFirstDeliveryDay =
    (cartDateIsTomorrowAndHasItems && beforeTomorrowCutoffTime) ||
    (firstDateIsTomorrow && beforeTomorrowCutoffTime && !skipTomorrowDefaultDate) ||
    !firstDateIsTomorrow;

  return useFirstDeliveryDay ? firstDeliveryDate : secondDeliveryDate;
}
