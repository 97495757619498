import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Font, Spacing } from 'web-common/src/shared/styles';

const ErrorDiv = styled.div`
  font-family: ${Font.Family};
  margin: ${Spacing.SINGLE};
`;

export const ErrorFallback = (): React.ReactElement => (
  <ErrorDiv>
    Sorry, something went wrong on our end. Try refreshing or going back to the <Link to={'/'}>home page</Link>.
  </ErrorDiv>
);
