import { Delimiter } from 'src/shared/design-system/Utilities/TextReplace';

export const DEFAULT_FREE_DELIVERY_THRESHOLD = 4000;
export const PROGRESS_BAR_MOUNT_BUFFER = 0.0001;

export const DISCOUNTED_DELIVERY_TEXT = 'Delivery!';
export const ADD_AMOUNT_MESSAGE = `Add ${Delimiter} more from any shef for ${Delimiter}`;

export const BREAKDOWN_DISCOUNTED_DELIVERY_TEXT = `${Delimiter} Delivery`;
export const BREAKDOWN_DISCOUNT_TEXT = `Spend ${Delimiter}, get ${Delimiter}${Delimiter}`;
export const BREAKDOWN_DISCOUNT_YOUR_ORDER_TEXT = ' your order';
export const BREAKDOWN_PERCENT_OFF_TEXT = '% Off';
export const BREAKDOWN_SAVINGS_ICON = `💰`;
export const BREAKDOWN_SAVINGS_TEXT = `${Delimiter} You’re saving ${Delimiter} on this order!`;

export const DISCOUNTED_DELIVERY_MESSAGE = `You qualify for ${Delimiter} delivery!`;

export const TOTAL_SAVINGS_TEXT = `Saving ${Delimiter}`;

export const REWARDS_DISCOUNTED_DELIVERY_TEXT = 'Delivery';
export const REWARDS_MAX_REACHED_TEXT = '🎉  Max rewards unlocked!';
export const REWARDS_PERCENT_OFF_TEXT = 'OFF';
export const REWARDS_DISCOUNT_TEXT = `Add ${Delimiter} for ${Delimiter}`;

export const SHARED_CONFETTI_PROPS = {
  angle: 280,
  startVelocity: -7,
  spread: 110,
  decay: 0.95,
  numberOfParticles: 60,
};
