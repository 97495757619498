import { SpiceLevel } from 'common/SpiceLevels';

export enum DietaryTags {
  VEGETARIAN = 'Vegetarian',
  VEGAN = 'Vegan',
  GLUTEN_FREE = 'Gluten Free',
  DAIRY_FREE = 'Dairy Free',
  LOW_FODMAP = 'Low FODMAP',
  PALEO = 'Paleo',
  WHOLE30 = 'Whole 30',
  HALAL = 'Halal',
  ORGANIC = 'Organic',
  COMPOSTABLE = 'Compostable',
  KOSHER = 'Kosher',
}

export enum SpiceLevelOptions {
  NOT_SPICY = 'Not Spicy',
  MILD = 'Mild',
  MEDIUM = 'Medium',
  HOT = 'Hot',
}

export const SpiceLevelMap: Record<SpiceLevelOptions, SpiceLevel> = {
  [SpiceLevelOptions.NOT_SPICY]: SpiceLevel.NOT_SPICY,
  [SpiceLevelOptions.MILD]: SpiceLevel.MILD,
  [SpiceLevelOptions.MEDIUM]: SpiceLevel.MEDIUM,
  [SpiceLevelOptions.HOT]: SpiceLevel.HOT,
};
