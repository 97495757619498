import { sumBy } from 'lodash';

export const findDeletedItemsById = <T>(originalArray: T[], newArray: T[], someKey: keyof T): T[] => {
  const deletedFoodItems = originalArray.filter((originalItem) => {
    return !newArray.find((newItem) => originalItem[someKey] === newItem[someKey]);
  });

  return deletedFoodItems;
};

export const zeroOutQty = <T extends { quantity: number }>(items: T[]): T[] => {
  return items.map((i) => ({ ...i, quantity: 0 }));
};

export const calculateItemsTotal = <T extends { quantity: number; price: number }>(items: T[]): number => {
  return sumBy(items, (i) => i.quantity * i.price);
};
