export const GOOGLE_TAG_MANAGER_DATA_LAYER_NAME = 'tagManagerDataLayerName';

export enum GoogleTagManagerEventNames {
  CART_ADD_ITEM = 'cart-add-item',
  EXISTING_USER_PURCHASE = 'existing-user-purchase',
  NEW_USER_PURCHASE = 'new-user-purchase',
  PAGE_VIEW = 'page-view',
  PURCHASE_CONVERSION = 'purchase-conversion',
  REGISTER_SUCCESS = 'register-success',
  SHEF_APPLICATION_RECEIVED = 'shef-application-received',
  MEAL_PLAN_PURCHASE = 'meal-plan-purchase',
}

// The property names in this interface MUST EXACTLY MATCH THE
// VARIABLES IN GOOGLE TAG MANAGER! If you make changes to this,
// you must make the corresponding changes in the Google Tag Manager
// web console as well. Probably best not to change existing names at all though.
export interface ITagManagerInfo {
  userId?: string;
  userEmail?: string;
  userLoggedIn: boolean;
  pagePath: string;
  // The query string (e.g. "?sp=foo&blah=bar")
  pageQueryArgs: string;
  // The name of the custom event in Google Tag Manager:
  event?: string;
  conversionSubtotal?: number;
  orderId?: string;
  newUserPurchase?: number;
}

export const sendInfoToGoogleTagManager = (tagManagerData: ITagManagerInfo) => {
  try {
    window.dataLayer.push({ ...tagManagerData });
  } catch (err) {
    console.error(err);
  }
};
