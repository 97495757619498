import React from 'react';
import { ShareButton, ShareIcon } from './styles';
import { ButtonType } from './types';

export const Share: React.FC<ButtonType> = (props) => {
  const { onClick, to } = props;
  return (
    <ShareButton onClick={onClick} to={to}>
      <ShareIcon />
    </ShareButton>
  );
};
