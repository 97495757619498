import whiteStar from 'src/assets/svg/stars/star-white-fill.svg';
import { ZIndexes } from 'src/shared/ZIndexes';
import styled from 'styled-components';
import { BorderRadius, BoxShadow, Colors, Spacing } from 'web-common/src/shared/styles';

export const StyledAppUpsellBanner = styled.div`
  background-color: ${Colors.RADISH};
  border-radius: ${BorderRadius.M};
  bottom: ${Spacing.SINGLE_HALF};
  box-shadow: ${BoxShadow.APP_UPSELL_BANNER};
  color: ${Colors.WHITE};
  display: flex;
  justify-content: space-between;
  left: ${Spacing.THREE_QUARTERS};
  position: fixed;
  right: ${Spacing.THREE_QUARTERS};
  z-index: ${ZIndexes.ConsumerExploreAppUpsellBanner};
`;

export const CloseButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  padding: ${Spacing.SINGLE} ${Spacing.SINGLE} ${Spacing.SINGLE} ${Spacing.DOUBLE};
`;

export const Star = styled.img.attrs(() => ({
  alt: 'star',
  width: '18px',
  height: '18px',
  src: whiteStar,
}))`
  margin-right: ${Spacing.QUARTER};
`;

export const StarsContainer = styled.div`
  display: flex;
`;

export const BannerContent = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${Spacing.SINGLE} 0 ${Spacing.SINGLE} ${Spacing.SINGLE};
`;

export const LogoContainer = styled.div`
  border-radius: ${BorderRadius.M};
  border: 1px solid ${Colors.WHITE};
  display: flex;
  margin-right: ${Spacing.HALF};
  padding: 0 ${Spacing.QUARTER};
`;

export const CloseIcon = styled.img`
  height: 16px;
  width: 16px;
`;
