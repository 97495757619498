import { ModalBody } from 'src/shared/design-system/Modal';
import { ifNotProp } from 'src/shared/styled-utils/ifNotProp';
import { minScreen } from 'src/shared/utils/StyledComponentUtils';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { BorderRadius, Colors, Font, ScreenWidth, Spacing } from 'web-common/src/shared/styles';

const getSharedButtonStyles = ({ selected = false }: { selected?: boolean }) =>
  match({ selected })
    .with(
      { selected: true },
      () => css`
        background: ${Colors.BELL_PEPPER_LITE_30};
        border: 1px solid ${Colors.RADISH};
      `
    )
    .with(
      { selected: false },
      () => css`
        background: ${Colors.WHITE};
        border: 1px solid ${Colors.GRAY_LITE_10};
      `
    )
    .exhaustive();

export const DishFirstModalBody = styled(ModalBody)`
  padding: 0 0 ${Spacing.SINGLE_QUARTER};
  overflow-y: auto;
`;

export const DishFirstModalFooter = styled.div<{ noShadow?: boolean }>`
  align-items: center;
  ${ifNotProp(
    'noShadow',
    css`
      box-shadow: 0px -4px 18px rgba(0, 0, 0, 0.15);
    `
  )}

  display: flex;
  justify-content: space-between;
  padding: ${Spacing.SINGLE};

  ${minScreen(ScreenWidth.SMALL)} {
    padding: ${Spacing.SINGLE} ${Spacing.SINGLE_HALF};
  }
`;

export const DishFirstModalSecondaryButton = styled.div`
  color: ${Colors.GRAY_DARK_10};
  cursor: pointer;
  font-size: ${Font.Size.S};
  font-weight: ${Font.Weight.MEDIUM};
  line-height: ${Font.LineHeight.XS};
  display: flex;
  align-items: center;
  justify-items: center;

  // Increase tap target of button while lining it up to the left side
  padding: ${Spacing.SINGLE};
  margin-left: -${Spacing.SINGLE};
`;

export const DishFirstModalRoundButton = styled.div<{ selected?: boolean; size?: number }>`
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  line-height: ${Font.LineHeight.XXS};
  flex-shrink: 0;

  ${({ size = 72 }) => css`
    height: ${size}px;
    width: ${size}px;
  `}

  ${getSharedButtonStyles}
`;

export const DishFirstModalRoundButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing.HALF};
`;

export const DishFirstModalButton = styled.div<{ selected?: boolean }>`
  background: ${({ selected }) => (selected ? Colors.BELL_PEPPER_LITE_30 : Colors.WHITE)};
  border-radius: ${BorderRadius.M};
  border: 1px solid ${({ selected }) => (selected ? Colors.RADISH : Colors.GRAY_LITE_20)};
  color: ${Colors.GRAY_DARK_20};
  cursor: pointer;
  padding: ${Spacing.SINGLE};
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${getSharedButtonStyles}
`;

export const DishFirstModalButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing.HALF};
`;
