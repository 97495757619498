import { useCallback, useState } from 'react';

export type InputCallbackAndValue<T, I> = [
  T,
  (event: React.FormEvent<I>) => void,
  React.Dispatch<React.SetStateAction<T>>
];

/**
 * Combines `useCallback` and `useState` to give a tuple of [value, onChange] for use on any input element
 * @param initialValue The initial value of the input
 * @param getValueFromEvent transform a DOM event into an input value
 */
export function useInputCallback<T, I = HTMLInputElement>(
  initialValue: T,
  getValueFromEvent: (event: React.FormEvent<I>) => T
): InputCallbackAndValue<T, I> {
  const [val, setVal] = useState(initialValue);
  const handle = useCallback((event: React.FormEvent<I>) => setVal(getValueFromEvent(event)), [getValueFromEvent]);
  return [val, handle, setVal];
}

/**
 * Combines `useCallback` and `useState` to give a tuple of [value: string, onChange] for use on text input elements
 * @param initialValue The initial value of the input
 */
export function useTextInputCallback(initialValue: string): InputCallbackAndValue<string, HTMLInputElement> {
  return useInputCallback(initialValue, (e) => e.currentTarget.value);
}

/**
 * Combines `useCallback` and `useState` to give a tuple of [value, onChange] for use on a checkbox input elements
 * @param initialValue The initial value of the input
 */
export function useCheckboxInputCallback(initialValue: boolean): InputCallbackAndValue<boolean, HTMLInputElement> {
  return useInputCallback(initialValue, (e) => e.currentTarget.checked);
}
