export interface LightboxProps {
  className?: string;
  /** If lightbox is shown by default. */
  defaultShow?: boolean;
  /** Pass in href to full res image to show full screen link. */
  fullResolutionHref?: string;
  /** Displays this content instead of `children` when lightbox is shown. */
  lightboxContent?: React.ReactNode;
  /** Callback called when the lightbox content is shown */
  onShow?: () => void;
}

export interface LightboxWithControlsProps extends LightboxProps {
  header?: string | null;
  /** Max zoom level. */
  maxZoomLevel?: number;
  /** Amount of zoom per step. */
  zoomStep?: number;
}

export const animationDuration = 200;

export const defaultZoomStep = 0.5;
export const defaultMaxZoomLevel = 5;
