import { IAnalyticsEventManager, ILog, ILogBulk } from 'common/analytics-event-manager/IAnalyticsEventManager';
import { ClientEvents } from 'common/events/ClientEvents';
import { SharedEvents } from 'common/events/SharedEvents';
import { ITracker } from 'src/tracking/types';

const loggedEvents = new Set<string>();

export class ExperimentLogger implements IAnalyticsEventManager<ClientEvents | SharedEvents> {
  constructor(private readonly tracker: ITracker) {}

  public logBulk(_props: ILogBulk<ClientEvents | SharedEvents>): Promise<number | undefined> {
    return Promise.reject(new Error('Not yet implemented'));
  }

  public async log({ eventName, properties }: ILog<ClientEvents | SharedEvents>): Promise<boolean> {
    if (eventName !== SharedEvents.EXPERIMENT_EXPOSURE) {
      throw new Error('Must only log exposure event!');
    }

    // Add some simple deduping to prevent event overload
    const key = eventName + JSON.stringify(properties);
    if (loggedEvents.has(key)) {
      return Promise.resolve(false);
    }

    loggedEvents.add(key);
    // TODO: convert events to union type
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    this.tracker.track(eventName, properties as any);
    return Promise.resolve(true);
  }
}
