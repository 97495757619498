import { getCircularReplacer } from './JSONUtils';

/**
 * When you try/catch an error and then want to rethrow, you can use this class
 * to preserve the stack trace of both errors. Especially useful with async code,
 * or when a try/catch could error in multiple ways.
 *
 *    async function doTheThing() {
 *      try {
 *        await do();
 *        await the();
 *        await thing();
 *      } catch (err) {
 *        throw new CompositeError('failed to do the thing', err);
 *      }
 *    }
 */
export class CompositeError extends Error {
  constructor(message: string, baseError: Error, readonly data?: unknown) {
    super(message);
    this.stack = `${this.stack}\nCaused by:\n${baseError.stack}`;
    if ((baseError as any).data) {
      this.stack = `${this.stack}\nContext:\n${JSON.stringify((baseError as any).data, getCircularReplacer())}`;
    }
  }
}
