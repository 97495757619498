import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './jeff-gao.jpg';

export const JeffGao: PersonInfo = {
  firstName: 'Jeff',
  lastName: 'Gao',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
