import { device } from 'common/BrowserUtilities';
import { ClientEvents } from 'common/events/ClientEvents';
import { useCallback } from 'react';
import { singularSdk } from 'singular-sdk';
import { isNativeMobileApp } from '../../mobile-app/isNativeMobileApp';
import { SINGULAR_TRACKING_LINK } from '../utils/MobileAppUtils';
import { useCurrentUser } from './useCurrentUserHook';
import { useTracker } from './useTracker';

interface AppUpsellHook {
  openAppListing: () => void;
  showAppUpsell: boolean;
}

const openAppStore = () => {
  // Builds web to app link with the following base link with deeplinking.
  const url = singularSdk.buildWebToAppLink(SINGULAR_TRACKING_LINK);
  singularSdk.openApp(url);
};

export const useAppUpsell = (): AppUpsellHook => {
  const [currentUser] = useCurrentUser();
  const tracker = useTracker();

  const deviceType = device(window.navigator.userAgent);
  const isAndroidOrIos = deviceType === 'Android' || deviceType === 'iPhone';
  const userHasOrders = currentUser !== null && currentUser.orderCount > 0;
  const showAppUpsell = isAndroidOrIos && userHasOrders && !isNativeMobileApp();

  const openAppListing = useCallback(() => {
    tracker.track(ClientEvents.NAVIGATE_TO_APP_LISTING, {});
    openAppStore();
  }, [tracker]);

  return { showAppUpsell, openAppListing };
};
