import { isNil } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { useOnMount } from 'src/shared/hooks/useOnMount';
import { useUnmount } from 'src/shared/hooks/useUnmount';
import { Transition } from 'web-common/src/shared/styles';
import { Background, Bar, BarGlow, Container } from './styles';
import { ProgressBarProps } from './types';

export const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const {
    backgroundColor,
    barColor,
    className,
    height,
    percent,
    renderBarGlowContent,
    showGlow = false,
    startingPercent,
    totalPercent = 100,
    transitionTime = Transition.TimeValue.BASE,
  } = props;
  const percentComplete = Math.max(Math.min((percent / totalPercent) * 100, 100), 0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [hasAnimatedOnce, setHasAnimatedOnce] = useState(false);
  const [isMounted, setIsMounted] = useState(!isNil(startingPercent));
  const isMountedRef = useRef(false);

  const animatedBar = useSpring({
    from: { percent: 0, width: `0%` },
    to: { percent: percentComplete, width: `${percentComplete}%` },
    config: { duration: transitionTime },
    onStart: useCallback(() => {
      if (showGlow && isMounted && isMountedRef.current) {
        setIsAnimating(true);
      }
    }, [isMounted, showGlow]),
    onRest: useCallback(() => {
      if (showGlow && isMounted && isMountedRef.current) {
        setIsAnimating(false);
      }
      if (!hasAnimatedOnce) {
        setHasAnimatedOnce(true);
      }
    }, [hasAnimatedOnce, isMounted, showGlow]),
    immediate: !isMounted,
  });

  useOnMount(() => {
    // eslint-disable-next-line functional/immutable-data -- Mounting
    isMountedRef.current = true;
    setIsMounted(true);
  });

  useUnmount(() => {
    // eslint-disable-next-line functional/immutable-data -- Unmounting
    isMountedRef.current = false;
    setIsMounted(false);
  });

  return (
    <Container className={className}>
      <Background color={backgroundColor} height={height}>
        <Bar color={barColor} height={height} style={animatedBar}>
          <BarGlow show={showGlow && isAnimating}>{renderBarGlowContent?.(hasAnimatedOnce)}</BarGlow>
        </Bar>
      </Background>
    </Container>
  );
};
