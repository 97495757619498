import { isAnyOf } from '@reduxjs/toolkit';
import { noOp } from 'common/Constants';
import { ClientEvents, ClientTrackableEvents } from 'common/events/ClientEvents';
import { difference } from 'lodash';
import { getFoodItemToTrack } from 'src/store/cart/utils/tracking';
import { match, P } from 'ts-pattern';
import type { CreateCartEffect } from './listeners';

type DeliveryDateChangeReason = ClientTrackableEvents[ClientEvents.DISH_FIRST_CART_CHANGE_DELIVERY_DATE]['reason'];

export const trackCartEvent: CreateCartEffect =
  ({ tracker, cartSlice }) =>
  (action, listenerApi) => {
    if (!tracker) return;

    const {
      decLineItemQuantity,
      incLineItemQuantity,
      setLineItemQuantity,
      setDeliveryDate,
      setPossibleDeliveryDates,
      setServerCart,
    } = cartSlice.actions;

    const { cart: currCart, checkout: currCheckout } = listenerApi.getState();
    const { cart: prevCart, checkout: prevCheckout } = listenerApi.getOriginalState();

    const { isMultiCart } = currCart;
    const newShefIdsInCart = difference(currCheckout.checkoutShefIds, prevCheckout.checkoutShefIds);
    const startedNewOrder = isMultiCart && newShefIdsInCart.length > 0;

    // track dish-first delivery date change event
    const hasNewDeliveryDate = prevCart.deliveryDate !== currCart.deliveryDate;
    const deliveryDateChangeParams = { action, hasNewDeliveryDate, isMultiCart };
    const deliveryDateChangeReason = match<typeof deliveryDateChangeParams, DeliveryDateChangeReason | ''>(
      deliveryDateChangeParams
    )
      .with({ isMultiCart: false }, { hasNewDeliveryDate: false }, () => '')
      .with(
        { action: P.when(isAnyOf(incLineItemQuantity, decLineItemQuantity, setLineItemQuantity)) },
        () => 'started-new-order'
      )
      .with({ action: P.when(isAnyOf(setPossibleDeliveryDates, setServerCart)) }, () => 'stale')
      .with({ action: P.when(setDeliveryDate.match) }, () => 'manual-change')
      .otherwise(() => '');
    if (deliveryDateChangeReason) {
      tracker.track(ClientEvents.DISH_FIRST_CART_CHANGE_DELIVERY_DATE, { reason: deliveryDateChangeReason });
    }

    // single possibility analytics events
    match({ action, isMultiCart, startedNewOrder })
      .with({ action: P.when(incLineItemQuantity.match) }, () => {
        const { foodItem, location, segmentMetrics, idx } = action.payload;
        tracker.track(ClientEvents.CART_ADD_ITEM, getFoodItemToTrack(foodItem, location, segmentMetrics, idx));
      })
      .with({ action: P.when(decLineItemQuantity.match) }, () => {
        tracker.track(
          ClientEvents.CART_REMOVE_ITEM,
          getFoodItemToTrack(action.payload.foodItem, action.payload.location)
        );
      })
      .with({ action: P.union(P.when(setLineItemQuantity.match)) }, () => {
        const currQuantity = action.payload.quantity;
        const prevQuantity = prevCart.lineItems.entities[action.payload.foodItem.id]?.quantity || 0;
        if (currQuantity === prevQuantity) return;

        const event = currQuantity > prevQuantity ? ClientEvents.CART_ADD_ITEM : ClientEvents.CART_REMOVE_ITEM;
        tracker.track(event, getFoodItemToTrack(action.payload.foodItem, action.payload.location));
      })
      .with({ startedNewOrder: true }, () => {
        const shefId = newShefIdsInCart?.[0] ?? '';
        const numberOfOrders = currCheckout.checkoutShefIds.length;
        tracker.track(ClientEvents.MULTI_CART_STARTED_NEW_ORDER, { numberOfOrders, shefId });
      })
      .otherwise(noOp);
  };
