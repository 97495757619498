import { Time } from '../Constants';
import { GqlRequestSource } from '../GqlRequestSource';
import { ISession } from '../sessions/ISession';
import { isSession } from '../sessions/isSession';
import { COOKIE_DEVICE_ID, COOKIE_SESSION_KEY, COOKIE_SOURCE } from './Cookies';

export interface ICookieProps {
  secure: boolean;
  expires: Date;
  httpOnly: boolean;
  overwrite: boolean;
  domain: string;
}
// These two types are the basic interfaces we need from Express Request and Response without adding express into common
export type CookieRequest = { cookies?: Record<string, any>; headers?: Record<string, any> };
export type CookieResponse = { cookie: (key: string, value: string, props: ICookieProps) => void };

export function getMaxEpiration() {
  return new Date(Date.now() + Time.ONE_YEAR_IN_MS * 10);
}

function getValueWithHeadersFallback(req: CookieRequest, key: string) {
  // We check both headers and cookies. Our mobile web clients will use cookies, our mobile apps will use headers for their first
  // request, then we set the value into a cookie. This is because there are currently two bugs in react-native-webview
  // that dont allow us to send cookies after the first request:
  // 1) Can't properly send cookies on url change: https://github.com/react-native-webview/react-native-webview/issues/4
  // 2) Can't send cookies if existing cookies: https://github.com/react-native-webview/react-native-webview/pull/2711
  return req.headers?.[key] || req.cookies?.[key];
}

export function getDeviceId(req: CookieRequest) {
  return getValueWithHeadersFallback(req, COOKIE_DEVICE_ID);
}

export function getSource(req: CookieRequest): GqlRequestSource | null {
  return getValueWithHeadersFallback(req, COOKIE_SOURCE);
}

export const deserializeSessionCookie = (session: string): ISession | null => {
  // Quick hack: the mobile clients arent always sending b64 encoded, so we check to see if it's b64 encoded
  // https://stackoverflow.com/questions/7860392/determine-if-string-is-in-base64-using-javascript
  const decoded = session.endsWith('=') ? Buffer.from(session, 'base64').toString() : session;
  const parsedSession = JSON.parse(decoded);
  return isSession(parsedSession) ? parsedSession : null;
};

export const serializeSessionCookie = (session: ISession): string => {
  return Buffer.from(JSON.stringify(session)).toString('base64');
};

export function getSession(req: CookieRequest): ISession | null {
  // Old old clients use x-sid header to indicate session id. This was actually passed to GQLClients in shef-web and
  const startedAt = req.headers?.['x-sst'] ? parseInt(String(req.headers['x-sst']), 10) : Date.now();
  const oldHeaderBasedSession: ISession | null = req.headers?.['x-sid']
    ? {
        id: req.headers['x-sid'],
        startedAt,
        updatedAt: startedAt,
      }
    : null;
  const cookie = getValueWithHeadersFallback(req, COOKIE_SESSION_KEY);
  return cookie ? deserializeSessionCookie(cookie) : oldHeaderBasedSession;
}

export const SESSION_LENGTH_HOURS = 6;
/**
 * Returns properties for set-cookie header response.
 * Note that our cookies are currently shared across devboxes. Unfortunately in order to prevent this,
 * we'd have to remap our url patterns to be api.dev1.shef.com instead of dev1.api.shef.com. The current devX.api
 * means that we cant have a domain property that isolates to a dev/prod environment.
 */
export function getSessionCookieProperties(domain: string): ICookieProps {
  const expiration = new Date(Date.now() + SESSION_LENGTH_HOURS * Time.ONE_HOUR_IN_MS);
  return { secure: false, expires: expiration, httpOnly: false, overwrite: true, domain };
}

/**
 * Returns properties for set-cookie header response.
 * Note that our cookies are currently shared across devboxes. Unfortunately in order to prevent this,
 * we'd have to remap our url patterns to be api.dev1.shef.com instead of dev1.api.shef.com. The current devX.api
 * means that we cant have a domain property that isolates to a dev/prod environment.
 */
export function getDeviceIdCookieProperties(domain: string): ICookieProps {
  const expiration = getMaxEpiration();
  return { secure: false, expires: expiration, httpOnly: false, overwrite: true, domain };
}

export function getSourceCookieProperties(domain: string): ICookieProps {
  const expiration = getMaxEpiration();
  return { secure: false, expires: expiration, httpOnly: false, overwrite: true, domain };
}

export function getProxyCookieProperties(domain: string): ICookieProps {
  const expiration = getMaxEpiration();
  return { secure: false, expires: expiration, httpOnly: true, overwrite: true, domain };
}

/**
 * Note that GQLClients dont do a good job of allowing us to add in cookies, so we just send these as headers with our requests
 */
export const getGQLHeaders = (
  browserTrackerId: string,
  source: GqlRequestSource,
  session?: ISession
): Record<string, string> => {
  const headers: Record<string, string> = {
    [COOKIE_SOURCE]: source,
    [COOKIE_DEVICE_ID]: browserTrackerId,
  };
  if (session) {
    // eslint-disable-next-line functional/immutable-data
    headers[COOKIE_SESSION_KEY] = serializeSessionCookie(session);
  }

  return headers;
};
