export const findOrCreateElement = (id) => {
  const element = document.querySelector(`#${id}`);

  if (element) {
    return element;
  }

  const newElement = document.createElement('div');
  newElement.setAttribute('id', id);
  document.body.appendChild(newElement);

  return newElement;
};
