import { Either, isLeft, left, right } from 'fp-ts/Either';
import { Is, Type, undefined as undefinedCodec } from 'io-ts';
import { ICodec } from './ICodec';

export const JSONCodecError = {
  PARSE_FAILURE: 'JSON parse failure!',
  IS_CHECK_FAILURE: '`is` check failed??',
  FIELD_FAILURE: (key: string, actual: unknown, expected: string): string =>
    `Validation failed after parse for key "${key}". Expected "${expected}", actual "${actual}"`,
};

/**
 * Provides an easy way to turn io-ts TypeC objects into a class which can
 * encode and decode them, providing human readable errors when there
 * are validation issues.
 *
 * Usage:
 *
 * ```
 * import * as t from 'io-ts';
 * const MyCodec = new JSONCodec(
 *  'MyCodec',
 *  t.type({
 *    hello: t.string,
 *    world: t.string,
 *  })
 * );
 *
 * // Serialize an object
 * const encoded = MyCodec.encode({hello: 'wow', world: 'ok!'});
 *
 * // Deserialize an object
 * const decoded = MyCodec.decode(encoded);
 *
 * // Get a compile-time type for the object
 * type MyCodecType = typeof MyCodec._T;
 * const x: MyCodecType = {hello: '', world: ''};
 * ```
 */
export class JSONCodec<T> implements ICodec<T> {
  constructor(public readonly name: string, private readonly base: Type<T>) {}

  public decode(i: string): Either<Error, T> {
    // Special case for t.undefined codec
    if ((this.base as any) === undefinedCodec) {
      return i === '' ? right(undefined as any as T) : left(new Error(JSONCodecError.PARSE_FAILURE));
    }

    let parsed: unknown;
    try {
      parsed = JSON.parse(i);
    } catch (err) {
      return left(new Error(JSONCodecError.PARSE_FAILURE));
    }

    const baseDecode = this.base.decode(parsed);
    if (isLeft(baseDecode)) {
      const firstError = baseDecode.left[0];
      const lastContext = firstError.context[firstError.context.length - 1];
      return left(new Error(JSONCodecError.FIELD_FAILURE(lastContext.key, lastContext.actual, lastContext.type.name)));
    }

    const is: Is<T> = (a): a is T => this.base.is(a);
    if (is(baseDecode.right)) return right(baseDecode.right);

    return left(new Error(JSONCodecError.IS_CHECK_FAILURE));
  }

  public decodeUnsafe(i: string): T {
    const decoded = this.decode(i);
    if (isLeft(decoded)) {
      throw decoded.left;
    }
    return decoded.right;
  }

  public encode(t: T): Either<Error, string> {
    try {
      return right(this.encodeUnsafe(t));
    } catch (err: unknown) {
      return left(err instanceof Error ? err : new Error('Failed to encode'));
    }
  }

  /**
   * same as {@link encode}, but can possibly throw.
   */
  public encodeUnsafe(t: T): string {
    return JSON.stringify(t);
  }

  public readonly _T = this.base._O;
}
