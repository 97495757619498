import { css } from 'styled-components';
import { Border } from '../border';
import { Colors } from '../colors';
import { Font } from '../font';
import { Spacing } from '../spacing';
import { TransitionTime } from '../transition';

/**
 * Styles a select input. Provides hover and focus states.
 */
export const SelectStyles = () => css`
  color: ${Colors.GRAY_DARK_30};
  border: 1px solid ${Colors.GRAY_DARK_10};
  border-radius: ${Border.Radius.S};
  font-family: ${Font.Family.SANS};
  outline: none;
  padding: ${Spacing.HALF};
  transition: border-color ${TransitionTime.BASE};

  &:hover:not(:disabled) {
    border-color: ${Colors.AQUA_LITE_10};
  }
  &:focus {
    border-color: ${Colors.AQUA};
  }
`;

/**
 * Styles a text input. Provides hover and focus states.
 */
export const TextStyles = () => css`
  color: ${Colors.GRAY_DARK_30};
  background: ${Colors.GRAY_LITE_30};
  border: 1px solid ${Colors.GRAY_LITE_30};
  border-radius: 0;
  font-size: ${Font.Size.S};
  font-family: ${Font.Family.SANS} !important;
  outline: none;
  padding: ${Spacing.THREE_QUARTERS} ${Spacing.SINGLE};
  transition: background ${TransitionTime.BASE}, border ${TransitionTime.BASE};

  &:hover:not(:disabled) {
    background: ${Colors.GRAY_LITE_30};
    border-color: ${Colors.GRAY};
  }
  &:focus:not(:disabled) {
    background: ${Colors.AQUA_LITE_30};
    border-color: ${Colors.AQUA_LITE_20};
  }
  &:disabled {
    color: ${Colors.GRAY_DARK_10};
  }
`;

export const TextLightStyles = () => css`
  ${TextStyles()}
  background: ${Colors.WHITE};
  border: 1px solid ${Colors.GRAY};

  &:hover:not(:disabled) {
    border-color: ${Colors.GRAY_DARK_10};
  }
  &:focus:not(:disabled) {
    border-color: ${Colors.AQUA_LITE_20};
  }
`;

/**
 * Styles a text input. Provides hover and focus states.
 */
export const TextAreaStyles = () => css`
  ${TextStyles()}
`;

export const TextAreaLightStyles = () => css`
  ${TextLightStyles()}
`;
