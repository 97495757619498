import { DockedScreenSize, NavLocation } from './types';
import {
  generateDockedReducer,
  generateHideAllReducer,
  generateHideReducer,
  generateIsScrollingUpReducer,
  generateResetStateReducer,
  generateShowReducer,
  generateToggleReducer,
  generateUndockedReducer,
} from './utils';

const { DESKTOP, TABLET, MOBILE } = DockedScreenSize;

// ANIMATIONS //
export const showAnimations = generateShowReducer(NavLocation.ANIMATIONS);
export const hideAnimations = generateHideReducer(NavLocation.ANIMATIONS);

// BACK BUTTON //
export const showBackButton = generateShowReducer(NavLocation.BACK_BUTTON);
export const hideBackButton = generateHideReducer(NavLocation.BACK_BUTTON);

// CUISINE FILTERS //
export const showCuisineFilters = generateShowReducer(NavLocation.CUISINE_FILTERS);
export const hideCuisineFilters = generateHideReducer(NavLocation.CUISINE_FILTERS);
export const toggleCuisineFilters = generateToggleReducer(NavLocation.CUISINE_FILTERS);

// CUISINE FILTER BUTTON //
export const showCuisineFilterButton = generateShowReducer(NavLocation.CUISINE_FILTER_BUTTON);
export const hideCuisineFilterButton = generateHideReducer(NavLocation.CUISINE_FILTER_BUTTON);
export const dockCuisineFilterButtonDesktop = generateDockedReducer(NavLocation.CUISINE_FILTER_BUTTON, DESKTOP);
export const dockCuisineFilterButtonTablet = generateDockedReducer(NavLocation.CUISINE_FILTER_BUTTON, TABLET);
export const dockCuisineFilterButtonMobile = generateDockedReducer(NavLocation.CUISINE_FILTER_BUTTON, MOBILE);
export const undockCuisineFilterButtonDesktop = generateUndockedReducer(NavLocation.CUISINE_FILTER_BUTTON, DESKTOP);
export const undockCuisineFilterButtonTablet = generateUndockedReducer(NavLocation.CUISINE_FILTER_BUTTON, TABLET);
export const undockCuisineFilterButtonMobile = generateUndockedReducer(NavLocation.CUISINE_FILTER_BUTTON, MOBILE);

// DATE PICKER //
export const showDatePicker = generateShowReducer(NavLocation.DATE_PICKER);
export const hideDatePicker = generateHideReducer(NavLocation.DATE_PICKER);
export const toggleDatePicker = generateToggleReducer(NavLocation.DATE_PICKER);

// DATE PICKER BUTTON //
export const showDatePickerButton = generateShowReducer(NavLocation.DATE_PICKER_BUTTON);
export const hideDatePickerButton = generateHideReducer(NavLocation.DATE_PICKER_BUTTON);
export const dockDatePickerButtonDesktop = generateDockedReducer(NavLocation.DATE_PICKER_BUTTON, DESKTOP);
export const dockDatePickerButtonTablet = generateDockedReducer(NavLocation.DATE_PICKER_BUTTON, TABLET);
export const dockDatePickerButtonMobile = generateDockedReducer(NavLocation.DATE_PICKER_BUTTON, MOBILE);
export const undockDatePickerButtonDesktop = generateUndockedReducer(NavLocation.DATE_PICKER_BUTTON, DESKTOP);
export const undockDatePickerButtonTablet = generateUndockedReducer(NavLocation.DATE_PICKER_BUTTON, TABLET);
export const undockDatePickerButtonMobile = generateUndockedReducer(NavLocation.DATE_PICKER_BUTTON, MOBILE);

// DIETARY FILTERS //
export const showDietaryFilters = generateShowReducer(NavLocation.DIETARY_FILTERS);
export const hideDietaryFilters = generateHideReducer(NavLocation.DIETARY_FILTERS);
export const toggleDietaryFilters = generateToggleReducer(NavLocation.DIETARY_FILTERS);

// FILTER BAR //
export const showFilterBar = generateShowReducer(NavLocation.FILTER_BAR);
export const hideFilterBar = generateHideReducer(NavLocation.FILTER_BAR);
export const toggleFilterBar = generateToggleReducer(NavLocation.FILTER_BAR);

// INLINE FILTER BUTTONS //
export const showInlineFilterButtons = generateShowReducer(NavLocation.INLINE_FILTER_BUTTONS);
export const hideInlineFilterButtons = generateHideReducer(NavLocation.INLINE_FILTER_BUTTONS);
export const dockInlineFilterButtonsDesktop = generateDockedReducer(NavLocation.INLINE_FILTER_BUTTONS, DESKTOP);
export const dockInlineFilterButtonsTablet = generateDockedReducer(NavLocation.INLINE_FILTER_BUTTONS, TABLET);
export const dockInlineFilterButtonsMobile = generateDockedReducer(NavLocation.INLINE_FILTER_BUTTONS, MOBILE);
export const undockInlineFilterButtonsDesktop = generateUndockedReducer(NavLocation.INLINE_FILTER_BUTTONS, DESKTOP);
export const undockInlineFilterButtonsTablet = generateUndockedReducer(NavLocation.INLINE_FILTER_BUTTONS, TABLET);
export const undockInlineFilterButtonsMobile = generateUndockedReducer(NavLocation.INLINE_FILTER_BUTTONS, MOBILE);

// MENU BAR //
export const showMenuBar = generateShowReducer(NavLocation.MENU_BAR);
export const hideMenuBar = generateHideReducer(NavLocation.MENU_BAR);
export const toggleMenuBar = generateToggleReducer(NavLocation.MENU_BAR);

// MORE FILTERS //
export const showMoreFilters = generateShowReducer(NavLocation.MORE_FILTERS);
export const hideMoreFilters = generateHideReducer(NavLocation.MORE_FILTERS);
export const toggleMoreFilters = generateToggleReducer(NavLocation.MORE_FILTERS);

// NAVIGATION //
export const showNavigation = generateShowReducer(NavLocation.NAVIGATION);
export const hideNavigation = generateHideReducer(NavLocation.NAVIGATION);
export const toggleNavigation = generateToggleReducer(NavLocation.NAVIGATION);

// PROTEIN FILTERS //
export const showProteinFilters = generateShowReducer(NavLocation.PROTEIN_FILTERS);
export const hideProteinFilters = generateHideReducer(NavLocation.PROTEIN_FILTERS);
export const toggleProteinFilters = generateToggleReducer(NavLocation.PROTEIN_FILTERS);

// PROMO //
export const showPromo = generateShowReducer(NavLocation.PROMO);
export const hidePromo = generateHideReducer(NavLocation.PROMO);
export const togglePromo = generateToggleReducer(NavLocation.PROMO);
export const expandPromo = generateShowReducer(NavLocation.PROMO_EXPANDED);
export const collapsePromo = generateHideReducer(NavLocation.PROMO_EXPANDED);

// SEARCH //
export const showSearch = generateShowReducer(NavLocation.SEARCH);
export const hideSearch = generateHideReducer(NavLocation.SEARCH);
export const toggleSearch = generateToggleReducer(NavLocation.SEARCH);

// SUB CUISINE FILTERS //
export const showSubCuisineFilters = generateShowReducer(NavLocation.SUB_CUISINE_FILTERS);
export const hideSubCuisineFilters = generateHideReducer(NavLocation.SUB_CUISINE_FILTERS);
export const toggleSubCuisineFilters = generateToggleReducer(NavLocation.SUB_CUISINE_FILTERS);

// SCROLL DIRECTION //
export const setIsScrollingUp = generateIsScrollingUpReducer();

export const hideAll = generateHideAllReducer();
export const resetState = generateResetStateReducer();
