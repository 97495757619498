import { noOp } from 'common/Constants';
import React, { useCallback } from 'react';
import { Container, ToggleContainer, ToggleIcon, ToggleInput, ToggleSwitch } from './styles';
import { ToggleProps } from './types';

export const Toggle: React.FC<ToggleProps> = (props) => {
  const { checked, checkedColor, className, dataTest, onBlur, onChange = noOp, showCheckedIcon, size } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.checked, e);
    },
    [onChange]
  );

  return (
    <Container className={className}>
      <ToggleContainer checkedColor={checkedColor} checked={checked} size={size}>
        <ToggleSwitch checked={checked} checkedColor={checkedColor} size={size}>
          {showCheckedIcon && <ToggleIcon />}
        </ToggleSwitch>
        <ToggleInput checked={checked} onBlur={onBlur} onChange={handleChange} data-cy={dataTest} />
      </ToggleContainer>
    </Container>
  );
};
