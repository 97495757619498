import { ColorTheme } from 'common/Constants';

const isDarkColorThemePreferred = () => window.matchMedia?.('(prefers-color-scheme: dark)').matches;
const isLightColorThemePreferred = () => window.matchMedia?.('(prefers-color-scheme: light)').matches;

/**
 * Returns the preferred color theme of the user's browser.
 */
export const getPreferredColorTheme = (): ColorTheme | null => {
  if (isDarkColorThemePreferred()) {
    return ColorTheme.DARK;
  }
  if (isLightColorThemePreferred()) {
    return ColorTheme.LIGHT;
  }

  return null;
};
