import { noOp } from 'common/Constants';
import { createContext, useContext } from 'react';
import { NativeAppHookResult } from 'src/mobile-app/hooks/types';

export const NativeAppContext = createContext<NativeAppHookResult>([
  {
    managedRoutes: [],
    sendRequestError: null,
    paymentMethods: null,
    paymentResult: null,
  },
  noOp,
  {
    dispatchSendRequest: noOp,
  },
]);

export const useNativeAppContext = () => useContext(NativeAppContext);
