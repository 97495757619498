import React from 'react';
import { addItestID } from 'src/itest-utils/addItestID';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import { switchProp } from 'src/shared/styled-utils/switchProp';
import styled, { css } from 'styled-components';
import { Colors } from 'web-common/src/shared/styles';

export interface IProps {
  title: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (e: React.MouseEvent | any) => any;
  disabled?: boolean;

  secondary?: boolean;
  white?: boolean;
  styleClass?: string;
  containerStyle?: React.CSSProperties;
  containerClass?: string;
  className?: string;

  small?: boolean;
  large?: boolean;

  fullWidth?: boolean;

  noMargin?: boolean;
  marginMin?: boolean;

  /**
   * A string which identifies the actual input element. This string does not necessarily have to be unique --
   * You might want to select all of the checkboxes associated with a parent element, for instance.
   */
  itestID?: string;

  name?: string; // passes through to button attribute name
}

const IconSpan = styled.span`
  margin: 0 7px;
`;

const primary = css`
  background-color: ${Colors.RADISH};
  color: white;
  border-color: ${Colors.RADISH};
  border-radius: 30px;
  font-weight: 500;
  transition: opacity 0.22s;

  &.active {
    box-shadow: none;
  }

  &:hover {
    opacity: 0.7;
    background-color: ${Colors.RADISH};
  }
`;

const secondary = css`
  background-color: ${Colors.JACKFRUIT};
  color: ${Colors.BLACK};
  border-radius: 0;

  &:hover {
    opacity: 0.7;
    background-color: ${Colors.JACKFRUIT};
  }

  &:focus,
  &:active {
    background-color: ${Colors.JACKFRUIT};
  }
`;

const white = css`
  background-color: transparent;
  border: 1px solid ${Colors.GRAY_DARK_10};
  color: ${Colors.BLACK};
  font-weight: 500;
`;

interface IButtonProps {
  which?: 'primary' | 'secondary' | 'white';
  fullWidth?: boolean;
  size?: 'small' | 'large';
  noMargin?: boolean;
  marginMin?: boolean;
}
const Button = styled.button<IButtonProps>`
  position: relative;

  display: inline-block;
  padding: 6px 12px;
  ${ifProp(
    'fullWidth',
    css`
      width: 100%;
    `
  )}
  margin-bottom: ${ifProp('marginMin', '2px', '0')};
  ${ifProp(
    'noMargin',
    css`
      margin: 0;
    `
  )}

  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;

  cursor: pointer;
  user-select: none;

  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;

  ${switchProp('size', {
    small: css`
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
      border-radius: 3px;
    `,
    large: css`
      padding: 10px 16px;
      font-size: 18px;
      line-height: 1.33;
      border-radius: 6px;
    `,
  })}

  ${switchProp(
    'which',
    {
      primary,
      secondary,
      white,
    },
    primary
  )}
`;

/**
 * @deprecated Use button components from `shared/design-system/Buttons`.
 */
export class AppButton extends React.Component<React.PropsWithChildren<IProps>> {
  private getWhich(): 'primary' | 'secondary' | 'white' {
    const { white, secondary } = this.props;
    if (secondary) {
      return 'secondary';
    }
    if (white) {
      return 'white';
    }
    return 'primary';
  }

  private getSize(): 'small' | 'large' | undefined {
    const { small, large } = this.props;
    if (small) {
      return 'small';
    }
    if (large) {
      return 'large';
    }
    return undefined;
  }

  public render() {
    const {
      title,
      fullWidth,
      disabled,
      onClick,
      noMargin,
      containerStyle,
      containerClass,
      marginMin,
      icon,
      className,
      name,
    } = this.props;

    return addItestID(
      this.props.itestID,
      <Button
        which={this.getWhich()}
        size={this.getSize()}
        name={name}
        fullWidth={fullWidth}
        marginMin={marginMin}
        noMargin={noMargin}
        style={containerStyle}
        className={`${containerClass} ${className}`}
        onClick={!disabled ? onClick : undefined}>
        {icon ? <IconSpan>{icon}</IconSpan> : null}
        {title}
      </Button>
    );
  }
}
