import React from 'react';
import { CloseButton, CloseIcon } from './styles';
import { ButtonType } from './types';

export const Close: React.FC<ButtonType> = (props) => {
  const { onClick } = props;
  return (
    <CloseButton onClick={onClick}>
      <CloseIcon />
    </CloseButton>
  );
};
