import { ClientEvents } from 'common/events/ClientEvents';
import { useCallback } from 'react';
import { useTracker } from 'src/shared/hooks/useTracker';
import { DEFAULT_PAGE_SIZE_PARTIAL } from 'src/store/search';
import { UseGetSearchResultsProps } from '../types';
import { usePageSizeReducer } from '../usePageSize';
import { useSearchFilters } from '../useSearchFilters';
import { useCombinedSearchResultsQuery } from './useCombinedSearchResultsQuery';

export const useCombinedSearchResults = (props: UseGetSearchResultsProps) => {
  const { query } = props;
  const tracker = useTracker();

  const { updateQueryParams, zipCode } = useSearchFilters();
  const { dishPageSize, shefPageSize, changeDishPageSize, changeShefPageSize } = usePageSizeReducer();

  const isMoreResultsLoading = false;
  const hasMoreResults = false;

  const { data, loading, error, getSearch, fetchMore, defaultSearchInput } = useCombinedSearchResultsQuery({
    query,
    zipCode,
    shefPageSize,
    dishPageSize,
  });

  const handleSearch = useCallback(
    (newQuery: string) => {
      if (newQuery.length > 0) {
        tracker.track(ClientEvents.FOOD_SEARCH, {
          value: newQuery,
          numResults: 0, // TODO
        });
      }

      return getSearch({
        variables: {
          searchInput: {
            query: newQuery,
            dishPageSize,
            shefPageSize,
            zipCode: zipCode ?? '',
          },
        },
      });
    },
    [dishPageSize, shefPageSize, tracker, getSearch, zipCode]
  );

  const handleShowMoreShefs = useCallback(() => {
    changeShefPageSize(shefPageSize + DEFAULT_PAGE_SIZE_PARTIAL);
    return getSearch({
      variables: { searchInput: { ...defaultSearchInput, shefPageSize: shefPageSize + DEFAULT_PAGE_SIZE_PARTIAL } },
    });
  }, [changeShefPageSize, shefPageSize, getSearch, defaultSearchInput]);

  const handleShowMoreDishes = useCallback(() => {
    changeDishPageSize(dishPageSize + DEFAULT_PAGE_SIZE_PARTIAL);
    return getSearch({
      variables: { searchInput: { ...defaultSearchInput, dishPageSize: dishPageSize + DEFAULT_PAGE_SIZE_PARTIAL } },
    });
  }, [changeDishPageSize, dishPageSize, getSearch, defaultSearchInput]);

  return {
    data,
    dishPageSize,
    error,
    fetchMore,
    handleSearch,
    handleShowMoreShefs,
    handleShowMoreDishes,
    hasMoreResults,
    isMoreResultsLoading,
    loading,
    shefPageSize,
    updateQueryParams,
    zipCode,
  };
};
