import { ClientEvents } from 'common/events/ClientEvents';
import { SharedEvents } from 'common/events/SharedEvents';
import { isEmpty } from 'lodash';
import { UserInfo } from 'src/tracking/types';

// <!-- Event snippet for Purchase conversion page
// In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
// <script>
// function gtag_report_conversion(url) {
//   var callback = function () {
//     if (typeof(url) != 'undefined') {
//       window.location = url;
//     }
//   };
//   gtag('event', 'conversion', {
//       'send_to': 'AW-650536379/j7ZMCPSYl_MBELvLmbYC',
//       'value': 1.0,
//       'currency': 'USD',
//       'transaction_id': '',
//       'event_callback': callback
//   });
//   return false;
// }
// </script>

export const AdWordEvents: Partial<Record<ClientEvents, string>> = {
  [ClientEvents.PURCHASE]: 'AW-650536379/j7ZMCPSYl_MBELvLmbYC',
  [ClientEvents.CART_ADD_ITEM]: 'AW-650536379/O5NOCPmvhvMBELvLmbYC',
  [ClientEvents.SHEF_MENU_VISIT]: 'AW-650536379/W01vCKWL3_MBELvLmbYC',
  [ClientEvents.DISH_MODAL_VISIT]: 'AW-650536379/ujWSCKzSzPMBELvLmbYC',
  [ClientEvents.REGISTER_SUCCESS]: 'AW-650536379/t00FCMKil_MBELvLmbYC',
  [ClientEvents.NEW_USER_PURCHASE]: 'AW-650536379/E3D0COH8kagDELvLmbYC',
  [ClientEvents.EXISTING_USER_PURCHASE]: 'AW-650536379/vMUiCJP9g9oDELvLmbYC',
  [ClientEvents.MEAL_PLANNING_MVP_PURCHASE]: 'AW-650536379/L2xjCIS3o4oZELvLmbYC',
  [ClientEvents.MEAL_PLANNING_MVP_PURCHASE_ATTEMPT]: 'AW-650536379/R1FsCM3K_OIYELvLmbYC',
  [ClientEvents.MEAL_PLANNING_MVP_CHECKOUT_STARTED]: 'AW-650536379/z_0qCICjp-MYELvLmbYC',
  [ClientEvents.MEAL_PLANNING_MVP_DELIVERY_ADDRESS_SELECT]: 'AW-650536379/dJsGCIOjp-MYELvLmbYC',
};

interface GtagUserData {
  email: string;
  address?: {
    postal_code?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    country?: string | null;
  };
}

export const gtagSetUserData = (
  userData: Pick<UserInfo, 'email' | 'firstName' | 'lastName' | 'lastSearchedZipCode'>
) => {
  const address = {
    postal_code: userData.lastSearchedZipCode,
    first_name: userData.firstName,
    last_name: userData.lastName,
    country: userData.lastSearchedZipCode ? 'US' : undefined,
  };

  // Adwords does not want incomplete data to be sent, so we must send either the whole address object or just email
  const hasAllValues = Object.values(address).every((val) => !isEmpty(val));

  const gTagUserData: GtagUserData = { email: userData.email };

  if (hasAllValues) {
    gTagUserData.address = address;
  }

  try {
    gtag('set', 'user_data', gTagUserData);
  } catch (e) {
    console.error(e);
  }
};

export const trackAdwordsEvent = (event: ClientEvents | SharedEvents, data?: Record<string, number | string>) => {
  const eventKey = AdWordEvents[event];
  if (eventKey) {
    try {
      gtag('event', 'conversion', {
        ...data,
        send_to: eventKey,
        event_callback: (...args: any[]) => console.log('[gtag event callback]', ...args),
      });
    } catch (e) {
      console.error(e);
    }
  }
};
