import { ApolloContextValue, getApolloContext } from '@apollo/client';
import { useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export function useGqlClient(): Exclude<ApolloContextValue['client'], undefined> {
  const ctx = useContext(getApolloContext());
  if (ctx.client === undefined) {
    throw new Error('Missing apollo client?!');
  }
  return ctx.client;
}
