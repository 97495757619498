import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useConsumerGroupOrderRatingQuery } from 'src/gqlReactTypings.generated.d';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useLocation } from 'src/shared/hooks/useLocation';
import { Notifications } from 'src/shared/Notifications';
import { getLoginRedirectViaLocation } from 'src/shared/utils/RouteUtilities';
import { ConsumerGroupOrderRatingModal } from './ConsumerGroupOrderRatingModal';

interface ConsumerRatingModalContainerProps {
  groupOrderId: string;
  hide: () => void;
}

export const ConsumerGroupOrderRatingModalContainer = ({
  groupOrderId,
  hide,
}: ConsumerRatingModalContainerProps): JSX.Element | null => {
  const location = useLocation();
  const [currentUser, isCurrentUserLoading] = useCurrentUser();

  const {
    data,
    error: queryError,
    loading: isQueryLoading,
  } = useConsumerGroupOrderRatingQuery({
    variables: { groupOrderIds: [groupOrderId] },
  });
  const groupOrder = data?.groupOrdersByIds.find(({ id }) => id === groupOrderId);
  const { positiveFoodTags = [], negativeFoodTags = [] } = data?.tagsForConsumer ?? {};
  const ignoreFoodTags = data?.tagsForConsumer?.ignoreFoodTags ?? [];
  const isLoading = isQueryLoading || isCurrentUserLoading;
  const hasFailedToLoadGroupOrder = Boolean(!isLoading && (queryError || !groupOrder));

  useEffect(() => {
    if (hasFailedToLoadGroupOrder) {
      Notifications.error(
        "Something went wrong and we couldn't load the order to be reviewed. Please try again later."
      );
      hide();
    }
  }, [hasFailedToLoadGroupOrder, hide]);

  if (!isCurrentUserLoading && !currentUser) {
    return <Redirect to={getLoginRedirectViaLocation(location)} />;
  }

  if (hasFailedToLoadGroupOrder || !groupOrder || !groupOrder.canReviewOrder || !currentUser) {
    return null;
  }

  return (
    <ConsumerGroupOrderRatingModal
      currentUser={currentUser}
      groupOrder={groupOrder}
      hide={hide}
      ignoreFoodTags={ignoreFoodTags}
      negativeFoodTags={negativeFoodTags}
      positiveFoodTags={positiveFoodTags}
    />
  );
};
