import { css } from 'styled-components';

/**
 * Adds flex style.
 */
export const set = (justifyContent?: string, alignItems?: string, direction?: string) => css`
  ${alignItems && `align-items: ${alignItems};`}
  display: flex;
  ${direction && `flex-direction: ${direction};`}
  ${justifyContent && `justify-content: ${justifyContent};`}
`;

/**
 * Adds flex column style.
 */
export const setColumn = (justifyContent?: string, alignItems?: string) => css`
  ${set(justifyContent, alignItems, 'column')}
`;

/**
 * Adds flex style, aligns and justifies to center.
 */
export const center = (direction?: string) => css`
  ${set('center', 'center', direction)}
`;

/**
 * Adds flex column style, aligns and justifies to center.
 */
export const centerColumn = () => css`
  ${set('center', 'center', 'column')}
`;
