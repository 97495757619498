import { event as nativeBridgeEvent } from '@shef/native-bridge';
import { ClientEvents, ClientTrackableEvents, PurchaseData } from 'common/events/ClientEvents';
import { NativeAppMessenger } from 'src/mobile-app/NativeAppMessenger';
import { ITracker, UserInfo } from 'src/tracking/types';

const USD_CURRENCY = 'USD';

export class AppAdTracker implements ITracker {
  public constructor(
    public readonly browserTrackerId: string,
    private readonly nativeAppMessenger: NativeAppMessenger
  ) {}

  // eslint-disable-next-line class-methods-use-this
  public init() {
    // no-op
  }

  // eslint-disable-next-line class-methods-use-this
  public hasBeenIdentified(): boolean {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  public updateIdentity<I extends keyof UserInfo>(_updates: Pick<UserInfo, I>): void {
    // no-op
  }

  // eslint-disable-next-line class-methods-use-this
  public identify(_userInfo: UserInfo) {
    // no-op
  }

  public track<K extends keyof ClientTrackableEvents>(event: K, eventData: ClientTrackableEvents[K]): void {
    try {
      switch (event) {
        case ClientEvents.PURCHASE: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const purchaseData = eventData as ClientTrackableEvents[ClientEvents.PURCHASE];
          const subtotalInMajorUnits = purchaseData.subtotal / 100.0;

          this.nativeAppMessenger
            .sendRequest(nativeBridgeEvent, {
              type: 'purchase',
              amount: subtotalInMajorUnits,
              currency: USD_CURRENCY,
            })
            .catch((error) => {
              console.error(error);
            });

          break;
        }
        case ClientEvents.EXISTING_USER_PURCHASE: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const purchaseData = eventData as PurchaseData;
          const subtotalInMajorUnits = purchaseData.subtotal / 100.0;

          this.nativeAppMessenger
            .sendRequest(nativeBridgeEvent, {
              type: 'customPurchase',
              amount: subtotalInMajorUnits,
              currency: USD_CURRENCY,
              eventName: ClientEvents.EXISTING_USER_PURCHASE,
              params: {
                amount: subtotalInMajorUnits,
                currency: USD_CURRENCY,
              },
            })
            .catch((error) => {
              console.error(error);
            });

          break;
        }
        case ClientEvents.NEW_USER_PURCHASE: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const purchaseData = eventData as PurchaseData;
          const subtotalInMajorUnits = purchaseData.subtotal / 100.0;

          this.nativeAppMessenger
            .sendRequest(nativeBridgeEvent, {
              type: 'customPurchase',
              amount: subtotalInMajorUnits,
              currency: USD_CURRENCY,
              eventName: ClientEvents.NEW_USER_PURCHASE,
              params: {
                amount: subtotalInMajorUnits,
                currency: USD_CURRENCY,
              },
            })
            .catch((error) => {
              console.error(error);
            });

          break;
        }
        case ClientEvents.CART_ADD_ITEM: {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          const cartAddItemData = eventData as ClientTrackableEvents[ClientEvents.CART_ADD_ITEM];

          this.nativeAppMessenger
            .sendRequest(nativeBridgeEvent, {
              type: 'general',
              eventName: ClientEvents.CART_ADD_ITEM,
              params: {
                currency: USD_CURRENCY,
                fb_currency: USD_CURRENCY,
                value: cartAddItemData.price,
              },
            })
            .catch((error) => {
              console.error(error);
            });

          break;
        }
        case ClientEvents.SHEF_APPLICATION_RECEIVED: {
          this.nativeAppMessenger
            .sendRequest(nativeBridgeEvent, {
              type: 'general',
              eventName: ClientEvents.SHEF_APPLICATION_RECEIVED,
              params: {},
            })
            .catch((error) => {
              console.error(error);
            });

          break;
        }
        default: {
          break;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  public onLogout(): void {
    // no-op
  }
}
