import React from 'react';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';
import { VerticallyCenteredText } from '../VerticallyCenteredText';

interface IBadgeDivProps {
  textColor: string;
  backgroundColor: string;
  outline?: boolean;
  className?: string;
}

const BadgeDiv = styled.div<IBadgeDivProps>`
  display: inline-block;
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  border: ${ifProp('outline', `2px solid ${Colors.WHITE}`)};
  border-radius: ${ifProp('outline', '10px', Spacing.HALF)};
  box-sizing: border-box;
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.XXS};
  line-height: ${Font.LineHeight.XXXS};
  margin: ${Spacing.NONE};
  padding: 1px 6px;
`;

interface IBadgeProps extends IBadgeDivProps {
  children: React.ReactNode | React.ReactNode[];
}

export const Badge: React.FC<IBadgeProps> = (props) => {
  const { children, ...restOfProps } = props;
  return (
    <BadgeDiv {...restOfProps}>
      <VerticallyCenteredText>{children}</VerticallyCenteredText>
    </BadgeDiv>
  );
};
