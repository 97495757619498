import { isNil, isNumber, omit } from 'lodash';

const PIIKeys = ['firstName', 'lastName', 'password'];

/*
 * 1. Remove PII
 * 2. Facebook's API requires value to be a number for things like purchase
     value and assigning value to conversion events. eId is our internal event
     id that we pull out and set separately in fbTrack
* */
export function cleanFbData(data?: Record<string, any>) {
  if (isNil(data)) {
    return {};
  }

  const [value, shefValue] = isNumber(data.value) ? [data.value, undefined] : [undefined, data.value];

  return {
    ...omit(data, 'eId', 'value', ...PIIKeys), // Remove PII
    value,
    shef_value: shefValue,
  };
}
