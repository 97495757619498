import { getBulkDiscountTier, getTotalSavingsFromBulkDiscount } from 'common/BulkDiscountUtils';
import { useEffect } from 'react';
import { useCartTotals } from 'src/cart/hooks/useCartTotals/useCartTotals';
import { useIsBulkDiscountExperiment } from 'src/shared/hooks/experiments/useIsBulkDiscountExperiment';
import { useDeliveryFee } from 'src/shared/hooks/useDeliveryFee';
import { useDispatch, useSelector } from 'src/store';
import { setDiscountApplied } from 'src/store/cart/actions';
import { selectBulkDiscountTiers, selectCart } from 'src/store/cart/selectors';
import { CartBulkDiscountTier } from 'src/store/cart/types';

type UseUpdateDiscountAppliedClientSideProps = {
  bulkDiscountTiers: CartBulkDiscountTier[];
  hasPromoCode: boolean;
  subtotal: number;
};

export const useUpdateDiscountAppliedClientSide = (props: UseUpdateDiscountAppliedClientSideProps) => {
  const { bulkDiscountTiers, hasPromoCode, subtotal } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (bulkDiscountTiers) {
      const discountApplied = getTotalSavingsFromBulkDiscount(
        getBulkDiscountTier(bulkDiscountTiers, subtotal),
        subtotal
      );
      dispatch(setDiscountApplied({ discountApplied }));
    }
  }, [bulkDiscountTiers, dispatch, hasPromoCode, subtotal]);
};

export const useBulkDiscount = () => {
  const bulkDiscountTiers = useSelector((state) => selectBulkDiscountTiers(selectCart(state)));
  const { deliveryFee, minimumAmountForDiscountedShipping, zipCode, discountedDeliveryFee } = useDeliveryFee();
  const { isBulkDiscountExperiment } = useIsBulkDiscountExperiment();
  const {
    activeCartItemCount,
    checkoutShefIds,
    isMultiCart,
    hasItemsInCart,
    hasPromoCode,
    loading,
    perShefOrders,
    promoApplied,
    subTotalAfterPromo,
  } = useCartTotals();
  useUpdateDiscountAppliedClientSide({ bulkDiscountTiers, hasPromoCode, subtotal: subTotalAfterPromo });

  return {
    activeCartItemCount,
    bulkDiscountTiers,
    checkoutShefIds,
    deliveryFee,
    hasBulkDiscounts: bulkDiscountTiers ? !!bulkDiscountTiers?.length : false,
    hasItemsInCart,
    hasZipCode: !!zipCode,
    isBulkDiscountExperiment,
    isMultiCart,
    minimumAmountForDiscountedShipping,
    isLoadingSubtotal: loading,
    perShefOrders,
    promoApplied,
    subtotal: subTotalAfterPromo,
    zipCode,
    discountedDeliveryFee,
  };
};
