import { useCallback } from 'react';
import { useNativeAppMessenger } from 'src/mobile-app/NativeAppMessengerContext';
import { useScopedReducer } from 'src/shef-global-state/shefGlobalState';
import { savePrefsToStorage, sendPrefToApp } from './userPreferencesState';
import { IUserPreferences } from './userPreferencesTypes';

type ReturnType = [
  Readonly<IUserPreferences>,
  <K extends keyof IUserPreferences>(key: K, value: IUserPreferences[K]) => void
];

export const useUserPreferences = (): ReturnType => {
  const { state: preferences, reduce } = useScopedReducer('preferences');
  const nativeAppMessenger = useNativeAppMessenger();

  const setPreference = useCallback(
    <K extends keyof IUserPreferences>(key: K, value: IUserPreferences[K]) => {
      reduce((prev: IUserPreferences) => {
        if (prev[key] === value) {
          return prev;
        }

        const next = {
          ...prev,
          [key]: value,
        };

        savePrefsToStorage(next);

        if (nativeAppMessenger) {
          sendPrefToApp(nativeAppMessenger, key, value);
        }

        return next;
      });
    },
    [nativeAppMessenger, reduce]
  );

  return [preferences, setPreference];
};
