import { css } from 'styled-components';

/**
 * Overflow text with ellipsis. Pass in the max number of lines to display.
 */
export const overflow = (numberOfLines = 1) => css`
  display: -webkit-box;
  -webkit-line-clamp: ${numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
