import {
  ChangeActiveZipCodeAction,
  ChangeAllPageSizeAction,
  ChangeDeliveryDatesAction,
  ChangeIsPartialQueryAction,
  ChangePageSizeAction,
  ChangeSearchQueryAction,
  SearchState,
} from './types';

export const changeActiveZipCode = (state: SearchState, { payload }: ChangeActiveZipCodeAction) =>
  state.activeZipCode !== payload.activeZipCode ? { ...state, activeZipCode: payload.activeZipCode ?? null } : state;

export const changeSearchQuery = (state: SearchState, { payload }: ChangeSearchQueryAction) =>
  state.searchQuery !== payload.searchQuery || state.isPartialQuery !== payload.isPartialQuery
    ? { ...state, isPartialQuery: payload.isPartialQuery || false, searchQuery: payload.searchQuery }
    : state;

export const changeIsPartialQuery = (state: SearchState, { payload }: ChangeIsPartialQueryAction) =>
  state.isPartialQuery !== payload.isPartialQuery
    ? { ...state, isPartialQuery: payload.isPartialQuery || false }
    : state;

export const changeDishPageSize = (state: SearchState, { payload }: ChangePageSizeAction) =>
  state.dishPageSize !== payload.pageSize ? { ...state, dishPageSize: payload.pageSize } : state;

export const changeShefPageSize = (state: SearchState, { payload }: ChangePageSizeAction) =>
  state.shefPageSize !== payload.pageSize ? { ...state, shefPageSize: payload.pageSize } : state;

export const changePageSize = (state: SearchState, { payload }: ChangeAllPageSizeAction) =>
  state.dishPageSize !== payload.dishPageSize || state.shefPageSize !== payload.shefPageSize
    ? { ...state, dishPageSize: payload.dishPageSize, shefPageSize: payload.shefPageSize }
    : state;

export const clearSearchQuery = (state: SearchState) =>
  state.searchQuery !== '' ? { ...state, searchQuery: '' } : state;

export const blurSearchInput = (state: SearchState) =>
  state.isSearchInputFocused ? { ...state, isSearchInputFocused: false } : state;

export const focusSearchInput = (state: SearchState) =>
  state.isSearchInputFocused ? state : { ...state, isSearchInputFocused: true };

export const changeModalDeliveryDates = (state: SearchState, { payload }: ChangeDeliveryDatesAction) => ({
  ...state,
  modalDeliveryDate: payload.modalDeliveryDates,
});
