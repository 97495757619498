import React, { useRef } from 'react';
import { useCanScrollVertical } from 'src/shared/design-system/shared/hooks/useCanScroll';
import { BorderRadius } from 'web-common/src/shared/styles';
import { DropdownSize } from '../consts';
import { useScrollToSelectedItem } from './hooks';
import { Container, OverflowShadowBottom, OverflowShadowTop, Wrapper } from './styles';

interface IMenuProps {
  alwaysOpenUpwards?: boolean;
  items: React.ReactNode[];
  borderRadius?: BorderRadius;
  rounded?: boolean;
  size: DropdownSize;
  style: React.CSSProperties;
}

export const getSelectedItem = (items: React.ReactElement[] = []) =>
  items.find((item) => {
    const { props } = item || {};
    return props.isSelected;
  });

export const Menu = React.forwardRef<HTMLDivElement, IMenuProps>((props, ref) => {
  const { alwaysOpenUpwards, items, size, style, ...otherProps } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { canScrollUp, canScrollDown, handleScroll } = useCanScrollVertical(wrapperRef);

  useScrollToSelectedItem(items);

  return (
    <Container
      alwaysOpenUpwards={alwaysOpenUpwards}
      size={size}
      ref={ref}
      style={style}
      {...otherProps}
      data-role='dropdown-menu'>
      <OverflowShadowTop canScroll={canScrollDown} />
      <OverflowShadowBottom canScroll={canScrollUp} />
      <Wrapper onScroll={handleScroll} ref={wrapperRef} data-role='dropdown-menu-container'>
        {items}
      </Wrapper>
    </Container>
  );
});
