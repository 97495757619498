import { ClientEvents } from 'common/events/ClientEvents';
import { StorageKeys } from 'common/storage/constants';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useAppUpsell } from 'src/shared/hooks/useAppUpsell';
import { useTracker } from 'src/shared/hooks/useTracker';
import { useLocalStorage } from 'src/shared/storage/Storage';

interface DismissedState {
  numDismissed: number; // Number of times the app upsell banner has been dismissed
  lastDismissedAt: string; // The last time the app upsell banner was dismissed
}

const NUM_MAX_DISMISSAL = 3;

export const useAppUpsellBannerState = () => {
  const tracker = useTracker();
  const { showAppUpsell, openAppListing } = useAppUpsell();
  const [dismissedState, setDismissedState] = useLocalStorage<DismissedState | null>(
    StorageKeys.APP_UPSELL_BANNER_DISMISSED_STATE,
    null
  );

  const canShowAppUpsellBanner = useMemo(() => {
    if (!showAppUpsell) {
      return false;
    }

    if (dismissedState == null) {
      return true;
    }

    const { numDismissed, lastDismissedAt } = dismissedState;
    const lastDismissedAtDate = DateTime.fromISO(lastDismissedAt);
    const oneWeekAgoDate = DateTime.local().minus({ weeks: 1 });

    return lastDismissedAtDate < oneWeekAgoDate && numDismissed < NUM_MAX_DISMISSAL;
  }, [dismissedState, showAppUpsell]);

  const onDismiss = useCallback(() => {
    const numDismissed = dismissedState ? dismissedState.numDismissed : 0;
    setDismissedState({
      numDismissed: numDismissed + 1,
      lastDismissedAt: DateTime.local().toISO(),
    });
    tracker.track(ClientEvents.APP_UPSELL_BANNER_DISMISSED, {});
  }, [dismissedState, setDismissedState, tracker]);

  return { canShowAppUpsellBanner, onDismiss, openAppListing };
};
