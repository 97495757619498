import React from 'react';
import { Container, FollowIcon, FollowingIcon, FollowingIconShadow } from './styles';
import { FollowToggleIconProps } from './types';

export const FollowToggleIcon: React.FC<FollowToggleIconProps> = (props) => {
  const { className, isAnimated, isFollowing, followingColor } = props;
  return (
    <Container className={className} isAnimated={isAnimated} followingColor={followingColor}>
      {isFollowing ? (
        <>
          <FollowingIcon />
          <FollowingIconShadow />
        </>
      ) : (
        <FollowIcon />
      )}
    </Container>
  );
};
