import { OrganizationFieldsFragment, PromoCodeFieldsFragment } from 'src/gqlReactTypings.generated.d';

export const CURRENT_VERSION = 1;

export interface ISerializablePromotionalState<T extends number = typeof CURRENT_VERSION> {
  version: T;
  state: IPromotionals;
}

export interface IPromotionals {
  availablePromos: PromoCodeFieldsFragment[];
  code: PromoCodeFieldsFragment | null;
  organization: OrganizationFieldsFragment | null;
  error: string | null;
}

export interface IPromotionalState {
  promotionals: IPromotionals;
}
