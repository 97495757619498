import { ifNotProp } from 'src/shared/styled-utils/ifNotProp';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { BorderRadius, Font } from 'web-common/src/shared/styles';
import { ButtonProps, ButtonSizes } from './types';

export const Button = styled.button.attrs(({ isSelected }: { isSelected?: boolean }) => ({
  'data-selected': isSelected,
}))<ButtonProps>`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${BorderRadius.BUTTON};
  border: none;

  font-family: ${({ fontFamily = Font.Family.SANS }) => fontFamily} !important;
  font-style: normal;
  line-height: 16px;

  ${({ fontWeight }) => css`
    font-weight: ${fontWeight ?? 'normal'};
  `}

  ${ifNotProp(
    'disabled',
    css`
      cursor: pointer;
    `
  )}

  ${ifProp(
    'fluid',
    css`
      flex: 1 1 auto;
    `
  )}

  ${({ fluid, size }) =>
    match(size)
      .with(
        ButtonSizes.XS,
        () => css`
          font-size: ${Font.Size.XXS};
          padding: 0 ${fluid ? '0' : '10px'};
          height: 30px;
        `
      )
      .with(
        ButtonSizes.S,
        () => css`
          font-size: ${Font.Size.XS};
          padding: 0 ${fluid ? '0' : '16px'};
          height: 32px;
        `
      )
      .with(
        ButtonSizes.M,
        () => css`
          font-size: ${Font.Size.S};
          padding: 0 ${fluid ? '0' : '30px'};
          height: 40px;
        `
      )
      .otherwise(
        () => css`
          font-size: ${Font.Size.S};
          padding: 0 ${fluid ? '0' : '60px'};
          height: 50px;
        `
      )}
`;
