import React from 'react';
import ReactDOM from 'react-dom';
import {
  DockedScreenSize,
  NavbarState,
  NavLocation,
  ResetStateAction,
  SetIsScrollingUpState,
  TOGGLE_LOCATIONS,
} from './types';

export const getToggleLocations = (locationToFilterOut?: NavLocation) =>
  locationToFilterOut
    ? TOGGLE_LOCATIONS.filter((navLocation) => navLocation !== locationToFilterOut)
    : TOGGLE_LOCATIONS;

export const setNavigationContent = (content: React.ReactNode, containerId: string) => {
  const navigationDom = document.getElementById(containerId);
  if (navigationDom) {
    return ReactDOM.createPortal(
      content,
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- Assign content to nav abr.
      navigationDom as HTMLElement
    );
  }
  return null;
};

export const generateIsScrollingUpReducer =
  () =>
  (state: NavbarState, { payload }: SetIsScrollingUpState) =>
    state.isScrollingUp !== payload.isScrollingUp ? { ...state, isScrollingUp: payload.isScrollingUp } : state;

export const generateShowReducer = (location: NavLocation) => (state: NavbarState) =>
  state[location] ? state : { ...state, [location]: true };

export const generateHideReducer = (location: NavLocation) => (state: NavbarState) =>
  !state[location] ? state : { ...state, [location]: false };

export const generateHideAllReducer = () => (state: NavbarState) => ({
  ...state,
  ...getToggleLocations().reduce((acc, cur) => ({ ...acc, [cur]: false }), []),
});

export const generateToggleReducer = (location: NavLocation) => (state: NavbarState) => {
  if (state[location]) {
    return { ...state, [location]: false };
  }
  return {
    ...state,
    ...getToggleLocations(location).reduce((acc, cur) => ({ ...acc, [cur]: false }), []),
    [location]: true,
  };
};

export const generateDockedReducer = (location: NavLocation, screenSize: DockedScreenSize) => (state: NavbarState) =>
  state[screenSize].includes(location) ? state : { ...state, [screenSize]: [...state[screenSize], location] };

export const generateUndockedReducer = (location: NavLocation, screenSize: DockedScreenSize) => (state: NavbarState) =>
  !state[screenSize].includes(location)
    ? state
    : { ...state, [screenSize]: state[screenSize].filter((dockedLocation) => dockedLocation !== location) };

export const generateResetStateReducer =
  () =>
  (state: NavbarState, { payload }: ResetStateAction) => {
    const additionalActions = payload.hideBackButton
      ? { [NavLocation.BACK_BUTTON]: false }
      : { [NavLocation.INLINE_FILTER_BUTTONS]: true, isScrollingUp: false };
    return { ...state, [NavLocation.ANIMATIONS]: true, ...additionalActions };
  };

export const isDocked = (locations: NavLocation[], locationToCheck: NavLocation) => locations.includes(locationToCheck);
