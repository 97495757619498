import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const CheckmarkV2 = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG height={16} width={16} viewBox={'0 0 16 16'} {...props}>
    <Path
      d='M15.6522 2.36505C16.1159 2.81435 16.1159 3.60062 15.6522 4.04992L6.51951 13.6349C6.09142 14.1217 5.34225 14.1217 4.91416 13.6349L0.347826 8.84243C-0.115942 8.39314 -0.115942 7.60686 0.347826 7.15757C0.77592 6.67083 1.52508 6.67083 1.95318 7.15757L5.699 11.0889L14.0468 2.36505C14.4749 1.87832 15.2241 1.87832 15.6522 2.36505Z'
      fill='currentColor'
    />
  </SVG>
);
