import { SpiceLevel } from 'common/SpiceLevels';

/**
 * @deprecated Please use web-common version
 */
export enum SpiceLevelOptions {
  NOT_SPICY = 'Not Spicy',
  MILD = 'Mild',
  MEDIUM = 'Medium',
  HOT = 'Hot',
}

/**
 * @deprecated Please use web-common version
 */
export const SpiceLevelMap: Record<SpiceLevelOptions, SpiceLevel> = {
  [SpiceLevelOptions.NOT_SPICY]: SpiceLevel.NOT_SPICY,
  [SpiceLevelOptions.MILD]: SpiceLevel.MILD,
  [SpiceLevelOptions.MEDIUM]: SpiceLevel.MEDIUM,
  [SpiceLevelOptions.HOT]: SpiceLevel.HOT,
};
