import { ApolloProvider } from '@apollo/client';
import { GqlRequestSource } from 'common/GqlRequestSource';
import { createRoot } from 'react-dom/client';
import { withRouter } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import 'setimmediate';
import { checkStorageSupport, StorageProvider } from 'src/shared/storage/Storage';
import { initViewHeightListener } from 'src/shared/utils/view-height-fix';
import { TrackerContextProvider } from 'src/tracking/tracker-context';
import 'src/util/shims';
import { QueryParamProvider } from 'use-query-params';
import App from './App';
import { ExperimentContextProvider } from './experimentation/ExperimentContextProvider';
import './index.css';
import { initNativeApp } from './mobile-app/initNativeApp';
import { isNativeMobileApp } from './mobile-app/isNativeMobileApp';
import { NativeAppMessengerContext } from './mobile-app/NativeAppMessengerContext';
import { unregister } from './registerServiceWorker';
import { CookieBackedSessionManager } from './sessions/CookieBackedSessionManager';
import { SessionIdContextProvider } from './sessions/SessionIdContextProvider';
import { IS_BOT_KEY_NAME } from './shared/Constants';
import { createGqlClient } from './shared/GqlClient';
import { GlobalStateProvider } from './shef-global-state/shefGlobalState';
import { ReduxProvider } from './store';
import { createTracker, initBrowserTrackerId, tryReconciliateBrowserTrackerIds } from './tracking/tracking';

// This must be called before everything else to ensure that shef-web is properly configured for the native app.
const nativeAppMessenger = initNativeApp();

initViewHeightListener();
checkStorageSupport();
const { deviceId, serverDeviceId, localDeviceId } = initBrowserTrackerId();
const isBot = window[IS_BOT_KEY_NAME] === `${true}`;
const source = isNativeMobileApp() ? GqlRequestSource.CONSUMER_APP : GqlRequestSource.SHEF_WEB;
const gqlClient = createGqlClient({ source, browserTrackerId: deviceId });
const sessionManager = isNativeMobileApp() ? undefined : new CookieBackedSessionManager({});
const tracker = createTracker({ browserTrackerId: deviceId, isBot, gqlClient, nativeAppMessenger, sessionManager });

tryReconciliateBrowserTrackerIds(serverDeviceId, localDeviceId, tracker);

const render = (Component: any) => {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <StorageProvider>
          <ApolloProvider client={gqlClient}>
            <GlobalStateProvider>
              <SessionIdContextProvider sessionManager={sessionManager}>
                <TrackerContextProvider tracker={tracker}>
                  <ExperimentContextProvider tracker={tracker}>
                    <NativeAppMessengerContext.Provider value={nativeAppMessenger}>
                      <ReduxProvider>
                        <Component />
                      </ReduxProvider>
                    </NativeAppMessengerContext.Provider>
                  </ExperimentContextProvider>
                </TrackerContextProvider>
              </SessionIdContextProvider>
            </GlobalStateProvider>
          </ApolloProvider>
        </StorageProvider>
      </QueryParamProvider>
    </BrowserRouter>
  );
};
render(withRouter(App));

// prod server won't build since import is only available to esm module, but we build a cjs one
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if ((import.meta as any).hot) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  (import.meta as any).hot.accept();
}

// Unregister the CRA service worker - we're going to rely on normal browser caching for now.
unregister();
