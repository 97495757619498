import { Mixins, Spacing } from 'web-common/src/shared/styles';
import { StartDirection } from './types';

export const BASE_SIZE = 100;
export const DEFAULT_SIZE = Mixins.Spacing.getSpacingValue(Spacing.QUADRUPLE);
export const DEFAULT_STROKE_WIDTH = Mixins.Spacing.getSpacingValue(Spacing.THREE_QUARTERS);

export const START_DIRECTION_DEGREE = {
  [StartDirection.TOP]: 270,
  [StartDirection.RIGHT]: 0,
  [StartDirection.BOTTOM]: 90,
  [StartDirection.LEFT]: 180,
};
