export enum QueryParams {
  SHEF = 'shef',
  DATE = 'date', // delivery date
  DISH = 'dish', // dish slug
  DIETARY_FILTER = 'diet',
  DISH_TYPE_FILTER = 'dt',
  SPICE_LEVEL_FILTER = 'spice',
  CUISINE_FILTER = 'cuisine',
  SEGMENT_FILTER = 'c',
  SEGMENT_FILTER_SCROLL = 'cs',
  BROWSE_FILTER = 'bf',
  TAG_FILTER = 'tf',
  ECO_FILTER = 'eco',
  SEARCH = 'search',
  CONSUMER_SEARCH = 'query',
  PROMO_CODE = 'p',
  RANK = 'rank',
  OPEN_MESSAGE_TO_SHEF = 'ms',
  MIN_RATING_FILTER = 'mr',
  PRICE_LEVEL_FILTER = 'price',
  PROTEIN_FILTER = 'protein',
  STEP = 'step',
  ENTRY_POINT = 'entryPoint',

  SHEF_POST_ID = 'spid',
  SHEF_POST_LOCATION = 'spl',
  SHEF_POST_SHEF_ID = 'spsid',

  EXPLORE_SEGMENT_ID = 'esid',
  EXPLORE_SEGMENT_POS = 'esp',
  EXPLORE_SEGMENT_RENDERED_ITEMS = 'esri',
  EXPLORE_SEGMENT_TOTAL_ITEMS = 'esti',

  REDIRECT = 'r',
  PAGE = 'page',
  TYPE = 'type',
  REFERRAL = 'referral',
  RATE_PREV_ORDER_ID = 'prev_oid',
  RATE_PREV_GROUP_ORDER_ID = 'prev_goid',
  ZIP = 'zip',

  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  EMAIL = 'email',

  UTM_SOURCE = 'utm_source',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_MEDIUM = 'utm_medium',
  UTM_CONTENT = 'utm_content',
  UTM_KEYWORD = 'utm_keyword',

  FOOD_ITEM_RATING_LINE_ITEM_ID = 'fir_lid',
  FOOD_ITEM_RATING_RATING_PERCENT = 'fir_rp',

  COMMUNITY = 'community',
  FORMAT = 'format',

  EXPERIMENT = 'exp',

  LANDING_PAGE_VARIANT = 'lp_var',

  TAB = 'tab',
}
