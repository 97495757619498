import { find, reduce } from 'lodash';
import {
  ConsumerGroupOrderFieldsFragment,
  GroupOrderRatingInput,
  UpcomingOrderFieldsFragment,
} from 'src/gqlReactTypings.generated.d';
import { MAIN_FOOD_TYPES } from 'src/shared/Constants';
import { match } from 'ts-pattern';
import { FoodItemRating } from '../constants';
import { isValidRatingPercentValue } from '../RatingsUtilities';
import { InitialFoodItemRating } from '../useInitialFoodItemRating';
import { OrderRatingsByOrderId } from './constants';

/**
 * Helper method for constructing the initial food item ratings for the given order.
 * If the `initialFoodItemRating` is valid for the order, we add it to the list of initial food item ratings.
 */
const getInitialFoodItemRatingsForOrder = (
  order: UpcomingOrderFieldsFragment,
  initialFoodItemRating: InitialFoodItemRating | null
): FoodItemRating[] => {
  if (!initialFoodItemRating) {
    return [];
  }

  const lineItem = find(order.lineItems, ({ id }) => id === initialFoodItemRating.lineItemId);
  if (!lineItem || lineItem.rating) {
    return [];
  }

  return [
    {
      foodItemId: lineItem.foodItem.id,
      ratingPercent: initialFoodItemRating.ratingPercent,
      tags: [],
    },
  ];
};

/**
 * Given a group order, create a map of order ratings by orderId using
 * any existing order rating comments to seed the order ratings.
 */
export const createOrderRatingsMap = ({
  groupOrder,
  initialFoodItemRating,
}: {
  groupOrder: ConsumerGroupOrderFieldsFragment;
  initialFoodItemRating: InitialFoodItemRating | null;
}): OrderRatingsByOrderId => {
  const { orders } = groupOrder;

  return reduce(
    orders,
    (acc: OrderRatingsByOrderId, order) => {
      const { id: orderId, foodRating, shefId } = order;
      const foodItemRatings = getInitialFoodItemRatingsForOrder(order, initialFoodItemRating);

      return {
        ...acc,
        [orderId]: {
          comment: foodRating?.comment ?? '',
          privateComment: foodRating?.privateComment ?? '',
          foodItemRatings,
          orderId,
          shefId,
        },
      };
    },
    {}
  );
};

/**
 * Helper method for sorting the line items by food type and rating count.
 *
 * The order of the line items should be:
 * 1. Main food items
 * 2. Side food items
 *
 * In the scenario that they're the same food type, sort the line items by least rated.
 */
export const getLineItemsSortedByFoodTypeAndRatingCount = (lineItems: UpcomingOrderFieldsFragment['lineItems']) =>
  [...lineItems].sort((a, b) => {
    const foodItemA = a.foodItem;
    const foodItemB = b.foodItem;
    const isAMainFoodType = MAIN_FOOD_TYPES.includes(foodItemA.foodType);
    const isBMainFoodType = MAIN_FOOD_TYPES.includes(foodItemB.foodType);

    return match({ isAMainFoodType, isBMainFoodType })
      .with({ isAMainFoodType: true, isBMainFoodType: false }, () => -1)
      .with({ isAMainFoodType: false, isBMainFoodType: true }, () => 1)
      .otherwise(() => foodItemA.ratingCount - foodItemB.ratingCount);
  });

/**
 * Given an existing food item rating, validate the rating. If it's valid, return the rating as a FoodItemRating.
 */
export const createFoodItemRatingFromExistingRating = ({
  foodItemId,
  ratingPercent,
  tags,
}: {
  foodItemId: number;
  ratingPercent?: number | null | undefined;
  tags?:
    | {
        tag: string;
      }[]
    | null
    | undefined;
}): FoodItemRating | null => {
  if (isValidRatingPercentValue(ratingPercent)) {
    return {
      foodItemId,
      ratingPercent,
      tags: (tags ?? []).map((t) => t.tag),
    };
  }

  return null;
};

/**
 * Helper function for determining if a GroupOrderRatingInput is valid for submitting.
 *
 * A GroupOrderRatingInput is valid as long as there's at least one comment or food item rating.
 */
export const isValidGroupOrderRatingInput = ({ ratings }: GroupOrderRatingInput): boolean =>
  ratings.some(({ comment, privateComment, foodItemRatings }) => {
    const hasComment = !!comment;
    const hasPrivateComment = !!privateComment;
    const hasFoodItemRatings = foodItemRatings && foodItemRatings?.length > 0;
    return hasComment || hasPrivateComment || hasFoodItemRatings;
  });
