import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './katherine-corson.jpg';

export const KatherineCorson: PersonInfo = {
  firstName: 'Katherine',
  lastName: 'Corson',
  image,
  pronoun: Pronoun.SHE,
  role: ROLE.PRODUCT_MANAGER,
};
