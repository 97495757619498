import { match } from 'ts-pattern';
import { Spacing } from 'web-common/src/shared/styles';
import { ItemProps, MenuPosition } from './consts';

type GetMenuPositionProps = {
  flushToDropdown?: boolean;
  inputClientRect: ClientRect;
  menuClientRect: ClientRect;
  alwaysOpenUpwards?: boolean;
};

type GetMenuPositionTransformStyleProps = {
  flushToDropdown?: boolean;
  shouldOpenDownwards?: boolean;
};

export const getItemDisplayValue = (item?: ItemProps) => {
  if (item?.value == null) {
    return '';
  }
  return item?.label || `${item.value}`;
};

/**
 * Function for get element ClientRect from react-autocomplete ref.
 * @param ref: Element ref in autocomplete.
 */
export const getElementClientRect = (ref: React.ReactInstance): ClientRect => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const element = ref as HTMLElement;
  return element.getBoundingClientRect();
};

export const getMenuPositionTransformStyle = (props: GetMenuPositionTransformStyleProps) => {
  const { flushToDropdown, shouldOpenDownwards } = props;
  const downwardsPercentage = flushToDropdown ? '0%' : `calc(0% + ${Spacing.HALF})`;
  const upwardsPercentage = flushToDropdown ? '-100%' : `calc(-100% - ${Spacing.HALF})`;
  return `translateY(${shouldOpenDownwards ? downwardsPercentage : upwardsPercentage})`;
};

/**
 * Get menu position when dropdown is open. Calculates style if dropdown should open upwards or downwards.
 * @param inputClientRect
 * @param menuClientRect
 */
export const getMenuPosition = (props: GetMenuPositionProps): MenuPosition => {
  const { alwaysOpenUpwards, flushToDropdown, inputClientRect, menuClientRect } = props;
  const { left, top, height, width } = inputClientRect;
  const { height: menuHeight } = menuClientRect;
  const windowHeight = window.innerHeight;

  /** The amount of spac above and below menu when open. */
  const spaceAboveMenu = top;
  const spaceBelowMenu = windowHeight - top;

  /** If the bottom edge of the menu is cutoff when open downwards. */
  const bottomOfMenuWhenOpenDown = top + height + menuHeight;
  const isBottomOfMenuCutOffWhenOpenDown = bottomOfMenuWhenOpenDown > windowHeight;

  /** If the top edge of the menu is cutoff when open upwards. */
  const topOfMenuWhenOpenUp = top - height - menuHeight;
  const isTopOfMenuCutOffWhenOpenDown = topOfMenuWhenOpenUp < 0;

  /** Determines if menu can open upwards and/or downwards. */
  const canOpenUpwards = isBottomOfMenuCutOffWhenOpenDown || top > windowHeight;
  const canOpenDownwards = isTopOfMenuCutOffWhenOpenDown || top < 0;

  const shouldOpenDownwards = match({ alwaysOpenUpwards, canOpenUpwards, canOpenDownwards })
    .with({ alwaysOpenUpwards: true }, () => false)
    .with({ canOpenUpwards: true, canOpenDownwards: true }, () => spaceBelowMenu > spaceAboveMenu)
    .with({ canOpenUpwards: true, canOpenDownwards: false }, () => false)
    .with({ canOpenUpwards: false, canOpenDownwards: true }, () => true)
    .otherwise(() => true);

  return {
    left,
    top: shouldOpenDownwards ? top + height : top + 2,
    width,
    transform: getMenuPositionTransformStyle({ flushToDropdown, shouldOpenDownwards }),
  };
};

export const defaultIsItemSelected = (item: ItemProps) => !item.isSectionHeader;
