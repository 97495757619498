/**
 * TODO: this file should not be in common.  We should control all of the cutoff
 * times on the server and provide them to the client when requested. This will
 * prevent the client from rendering things it thinks are possible when the
 * server disagrees (such as after a deploy)
 */
import { times } from 'lodash';
import { DateTime, Duration } from 'luxon';
import { Time, TimeZone } from './Constants';
import { DateString, formatDateTimeUrlDate } from './UrlDate';

/**
 * Since payment intents can live for at most 7 days, and it takes some time
 * to collect payments, we need a buffer window to make sure an order that is
 * placed for the maximum allowed delivery day can have its payment collected.
 */
export const STRIPE_PAYMENT_INTENT_BUFFER_HOURS = 4;

/**
 * We will not support payment intents with longer lifespans than this Duration.
 * Stripe expires intents after they have been uncaptured for 7 days, and its a
 * good idea to give ourselves some buffer if anything goes wrong.
 */
export const MAX_ALLOWED_STRIPE_PAYMENT_INTENT_DURATION = Duration.fromObject({
  days: 7,
  hours: -STRIPE_PAYMENT_INTENT_BUFFER_HOURS,
});

/**
 * The amount of time before the stored delivery date (which is midnight in the
 * shef's timezone) that we lock the order to further line item edits, as well as
 * lock in the delivery date.
 */
export const DELTA_DELIVERY_DATE_CAPTURE_PAYMENT = Duration.fromObject({
  days: 1,
  hours: STRIPE_PAYMENT_INTENT_BUFFER_HOURS,
});

/**
 * The amount of time before the stored delivery date (which is midnight in the
 * shef's timezone) that we lock the order for any edits related to delivery
 * details
 */
const DELTA_DELIVERY_DATE_LOCK_DELIVERY_DETAILS = Duration.fromObject({ hours: 4 });

/**
 * How far into the future people can possibly order
 */
export const MAX_BOOKING_TIME_IN_ADVANCE = Duration.fromObject({ weeks: 3 });

export const DURATION_ONE_DAY = Duration.fromObject({ days: 1 });

export const ALLOWED_CUTOFFS = [3, 6, 12, 24, 36, 48].map((x) => x * Time.ONE_HOUR_IN_MS);

/**
 * Given a time zone and a duration to calculate the max date from the min date (start of tomorrow),
 * return a list of date strings representing delivery dates from the min to max date.
 */
export const getDeliveryWindowUrlDates = (timeZone: string | TimeZone, maxDateDuration: Duration): DateString[] => {
  // We don't have same day delivery yet, so first day is always tomorrow
  const defaultMinDate = DateTime.utc().setZone(timeZone).plus(DURATION_ONE_DAY).startOf('day');
  const defaultMaxDate = defaultMinDate.plus(maxDateDuration);
  const daysDifference = defaultMaxDate.diff(defaultMinDate, ['days', 'hours']).days;

  const deliveryDaysInWindow = times(daysDifference).map((days) => defaultMinDate.plus(Duration.fromObject({ days })));
  return deliveryDaysInWindow.map(formatDateTimeUrlDate);
};

export const getExplorePageDeliveryWindowUrlDates = (timeZone: string | TimeZone): DateString[] =>
  getDeliveryWindowUrlDates(timeZone, MAX_BOOKING_TIME_IN_ADVANCE);

export const getDeliveryDateTimeForDate = (date: Date, timezone: string): DateTime =>
  DateTime.fromJSDate(date, { zone: timezone }).startOf('day');

export function getTimingsForDeliveryDate(deliveryDateTime: DateTime | Date): {
  capturePaymentAt: DateTime;
  lockDeliveryDetailsAt: DateTime;
} {
  const dateTime = DateTime.isDateTime(deliveryDateTime) ? deliveryDateTime : DateTime.fromJSDate(deliveryDateTime);
  return {
    capturePaymentAt: dateTime.minus(DELTA_DELIVERY_DATE_CAPTURE_PAYMENT),
    lockDeliveryDetailsAt: dateTime.minus(DELTA_DELIVERY_DATE_LOCK_DELIVERY_DETAILS),
  };
}

export function getMaxDeliveryDateTimeToCapturePaymentsFor(): DateTime {
  return DateTime.utc().plus(DELTA_DELIVERY_DATE_CAPTURE_PAYMENT);
}

const DURATION_TWO_WEEKS = Duration.fromObject({ weeks: 2 });

export function getDeliveryDateRangeForPaymentCapture(): [DateTime, DateTime] {
  const end = getMaxDeliveryDateTimeToCapturePaymentsFor();
  return [end.minus(DURATION_TWO_WEEKS), end];
}

/**
 * The latest possible time which can place an order for the provided `deliveryDate`
 * @param deliveryDate
 */
export function getDeliveryDateCutoff(deliveryDate: DateTime, cutoffMsBeforeDeliveryDate: number): DateTime {
  return deliveryDate.minus({ milliseconds: cutoffMsBeforeDeliveryDate });
}

export function isTooLateToOrderForDeliveryDate(
  deliveryDate: DateTime,
  cutoffMsBeforeDeliveryDate: number,
  now = DateTime.utc()
): boolean {
  return now > getDeliveryDateCutoff(deliveryDate, cutoffMsBeforeDeliveryDate);
}
/**
 * For now, do not allow moving of the delivery date beyond the booking window.
 */
export function isNewDeliveryDateAfterBookingWindow(deliveryDate: DateTime, now = DateTime.utc()): boolean {
  return deliveryDate.diff(now) > MAX_BOOKING_TIME_IN_ADVANCE;
}
