import { hasLen } from 'common/PatternMatchingUtilities';
import { includes, map, reject, split } from 'lodash';
import { DateTime } from 'luxon';
import { CuisineCategory, CuisineCategoryType } from 'src/gqlReactTypings.generated.d';
import { match, P } from 'ts-pattern';

const NON_DISPLAY_CATEGORIES = [
  CuisineCategoryType.Internal,
  CuisineCategoryType.Holiday,
  CuisineCategoryType.FoodItemSpecialty,
];

interface Props {
  cuisineCategories: Pick<CuisineCategory, 'title' | 'type'>[];
  specialty?: string | null;
  k?: number;
}

export const getTopKCuisines = ({ k, cuisineCategories, specialty }: Props): string => {
  const displayCategories = reject(cuisineCategories, ({ type }) => includes(NON_DISPLAY_CATEGORIES, type));

  return match({ cuisineCategories: displayCategories, specialty })
    .with({ cuisineCategories: hasLen }, ({ cuisineCategories: c }) => map(c, 'title'))
    .with({ specialty: P.not(P.nullish) }, ({ specialty: s }) => split(s, '/'))
    .otherwise(() => [])
    .slice(0, k)
    .join(' · ');
};

export const getCardAvailabilityFooterText = (nextDeliveryDate: DateTime | null): string => {
  if (!nextDeliveryDate) {
    return 'Unavailable';
  }

  const today = DateTime.now();
  const daysFromNextDeliveryDate = nextDeliveryDate.diff(today, ['days']).days;
  // eslint-disable-next-line no-restricted-properties -- Sunday is zero indexed in default JS date
  const dayAsNumber = today.toJSDate().getDay();
  // If a user orders on Saturday we'll use the long-hand form for the upcoming week
  const daysLeftInWeek = dayAsNumber === 6 ? dayAsNumber : 6 - dayAsNumber;

  return daysFromNextDeliveryDate < daysLeftInWeek
    ? nextDeliveryDate.weekdayLong
    : nextDeliveryDate.toLocaleString({
        month: 'numeric',
        day: 'numeric',
      });
};
