import { noOp } from 'common/Constants';
import React from 'react';
import { ToggleButtonMultiplesProps, ToggleButtonsProps } from '../types';
import { Button } from './Button';
import { Container } from './styles';

export const Buttons = <T extends React.ReactNode, K extends keyof T>(props: ToggleButtonsProps<T, K>) => {
  const { className, dataTest, disabled, onChange = noOp, options, overflowWrap, value, ...buttonProps } = props;

  return (
    <Container className={className} overflowWrap={overflowWrap} data-cy={dataTest}>
      {options.map((option, index) => (
        <Button
          data-cy={option.dataTest}
          disabled={option.disabled ?? disabled}
          key={`${index}-${option.value}`}
          {...option}
          {...buttonProps}
          value={option.value === value}
          onChange={(_, e: React.MouseEvent<HTMLButtonElement>) => onChange(option.value, e)}
        />
      ))}
    </Container>
  );
};

export const ButtonsMultiple = <T extends React.ReactNode, K extends keyof T>(
  props: ToggleButtonMultiplesProps<T, K>
) => {
  const { className, dataTest, disabled, onChange = noOp, options, overflowWrap, value = [], ...buttonProps } = props;

  return (
    <Container className={className} overflowWrap={overflowWrap} data-cy={dataTest}>
      {options.map((option, index) => (
        <Button
          disabled={option.disabled ?? disabled}
          key={`${index}-${option.value}`}
          {...option}
          {...buttonProps}
          value={value.includes(option.value)}
          onChange={(isSelected, e: React.MouseEvent<HTMLButtonElement>) => {
            const updatedValues = isSelected ? [...value, option.value] : value.filter((val) => val !== option.value);
            onChange(updatedValues, e);
          }}
        />
      ))}
    </Container>
  );
};
