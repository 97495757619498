import { useState } from 'react';

const MAX_NUM_CHARS = 225;

interface IExpandableTextProps {
  children: string;
  maxChars?: number;
  showMoreText?: string;
}

export const ExpandableTextSimple = ({
  children,
  maxChars = MAX_NUM_CHARS,
  showMoreText = 'more',
}: IExpandableTextProps) => {
  const shouldTruncate = children.length > maxChars;
  const [showAll, setShowAll] = useState(!shouldTruncate);

  const textToShow = showAll ? children : `${children.slice(0, maxChars)}… `;

  return (
    <>
      {textToShow}
      {!showAll && (
        <span onClick={() => setShowAll(true)} className='cursor-pointer underline'>
          {showMoreText}
        </span>
      )}
    </>
  );
};
