import React from 'react';

export enum CanScroll {
  UP = 'canScrollUp',
  DOWN = 'canScrollDown',
  LEFT = 'canScrollLeft',
  RIGHT = 'canScrollRight',
}

export type ScrollHorizontalProps = {
  [CanScroll.LEFT]: boolean;
  [CanScroll.RIGHT]: boolean;
};

export type ScrollVerticalProps = {
  [CanScroll.UP]: boolean;
  [CanScroll.DOWN]: boolean;
};

export interface ScrollProps extends ScrollHorizontalProps, ScrollVerticalProps {}

/**
 * **checkCanScrollVertical**_(element, threshold)_<br>
 * Determines if a given element is scrollable vertically if content is overflown.
 * @param { Object } element: The dom element to check if it's scrollable.
 * @returns { Object } Scrolling definition if element is scrollable up or down.
 */
export const checkCanScrollVertical = (element: React.RefObject<HTMLDivElement>): ScrollVerticalProps => {
  if (element?.current) {
    const { scrollTop, scrollHeight, offsetHeight } = element.current;

    const scrollVerticalDistance = scrollHeight - offsetHeight;

    return {
      [CanScroll.UP]: scrollTop < scrollVerticalDistance,
      [CanScroll.DOWN]: scrollTop > 0,
    };
  }
  return {
    [CanScroll.UP]: false,
    [CanScroll.DOWN]: false,
  };
};

/**
 * **checkCanScrollHorizontal**_(element, threshold)_<br>
 * Determines if a given element is scrollable horizontally if content is overflown.
 * @param { Object } element: The dom element to check if it's scrollable.
 * @returns { Object } Scrolling definition if element is scrollable up or down.
 */
export const checkCanScrollHorizontal = (element: React.RefObject<HTMLDivElement>): ScrollHorizontalProps => {
  if (element?.current) {
    const { scrollLeft, scrollWidth, offsetWidth } = element.current;

    const scrollHorizontalDistance = scrollWidth - offsetWidth;

    return {
      [CanScroll.LEFT]: scrollLeft < scrollHorizontalDistance,
      [CanScroll.RIGHT]: scrollLeft > 0,
    };
  }
  return {
    [CanScroll.LEFT]: false,
    [CanScroll.RIGHT]: false,
  };
};

/**
 * **checkCanScroll**_(element, threshold)_<br>
 * Determines if a given element is scrollable if content is overflown.
 * @param { Object } element: The dom element to check if it's scrollable.
 * @returns { Object } Scrolling definition if element is scrollable up or down.
 */
export const checkCanScroll = (element: React.RefObject<HTMLDivElement>): ScrollProps => {
  if (element?.current) {
    const { scrollTop, scrollLeft, scrollHeight, scrollWidth, offsetHeight, offsetWidth } = element.current;

    const scrollVerticalDistance = scrollHeight - offsetHeight;
    const scrollHorizontalDistance = scrollWidth - offsetWidth;

    return {
      [CanScroll.UP]: scrollTop < scrollVerticalDistance,
      [CanScroll.DOWN]: scrollTop > 0,
      [CanScroll.LEFT]: scrollLeft < scrollHorizontalDistance,
      [CanScroll.RIGHT]: scrollLeft > 0,
    };
  }
  return {
    [CanScroll.UP]: false,
    [CanScroll.DOWN]: false,
    [CanScroll.LEFT]: false,
    [CanScroll.RIGHT]: false,
  };
};
