import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './elmar-carillo.jpg';

export const ElmarCarillo: PersonInfo = {
  firstName: 'Elmar',
  lastName: 'Carillo',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
