import styled, { css } from 'styled-components';
import { Colors, Font, FontWeight, ScreenWidth, Spacing, Transition } from 'web-common/src/shared/styles';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: ${Spacing.NONE} ${Spacing.SINGLE};
`;

export const Wrapper = styled.div`
  padding: 13px 0 13px ${Spacing.HALF};
`;

export const IconContainer = styled.div`
  align-items: center;
  border-radius: 100%;
  display: flex;
  margin-right: ${Spacing.HALF};
`;

export const Button = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 0;
  display: flex;
  padding: ${Spacing.THREE_QUARTERS};
  transition: background ${Transition.Time.BASE}, color ${Transition.Time.BASE};

  & svg {
    height: ${Spacing.SINGLE};
    width: ${Spacing.SINGLE};
  }
`;

export const createToastStyles = (color: Colors) => `
  background: ${color};
  border-color: ${color};

  & ${IconContainer} {
    & svg {
      height: ${Spacing.SINGLE};
      width: ${Spacing.SINGLE};
    }
  }
  & ${Wrapper} {
    color: ${Colors.WHITE};
    font-weight: ${FontWeight.SEMI_BOLD};
  }
`;

export const ToastContainerStyles = css`
  /* max-width corner case for correct iPad mini responsiveness */
  @media (max-width: 767px) {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 480px) {
    gap: ${Spacing.HALF};
  }

  &.Toastify__toast-container {
    display: flex;
  }

  &.Toastify__toast-container--bottom-left,
  &.Toastify__toast-container--bottom-center,
  &.Toastify__toast-container--bottom-right {
    flex-direction: column-reverse;
    transform: translateY(8px);

    @media (max-width: ${ScreenWidth.SMALL}) {
      transform: translateY(-6vh);
    }

    @media (max-width: ${ScreenWidth.EXTRA_SMALL}) {
      transform: translateY(-10vh);
    }

    @media (max-width: ${ScreenWidth.EXTRA_EXTRA_SMALL}) {
      transform: translateY(-12vh);
    }
  }

  &.Toastify__toast-container--top-left,
  &.Toastify__toast-container--top-center,
  &.Toastify__toast-container--top-right {
    flex-direction: column;
  }

  .Toastify__close-button {
    display: none;
  }

  .Toastify__toast {
    border-radius: 4px;
    font-family: ${Font.Family.SANS};
    font-size: ${Font.Size.XS};
    min-height: ${Spacing.DOUBLE};
    padding: 0;

    & .Toastify__toast-body {
      margin: 0;
    }

    &.Toastify__toast--info {
      ${createToastStyles(Colors.AQUA)}
    }

    &.Toastify__toast--success {
      ${createToastStyles(Colors.KOHLRABI_DARK_10)}
    }

    &.Toastify__toast--warning {
      ${createToastStyles(Colors.JACKFRUIT)}
    }

    &.Toastify__toast--error {
      ${createToastStyles(Colors.RADISH)}
    }

    @media (max-width: ${ScreenWidth.SMALL}) {
      width: 343px;
    }
  }
`;
