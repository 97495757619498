import React from 'react';
import styled from 'styled-components';
import { BaseModal, BaseModalProps } from './BaseModal';
import { ModalCloseButton, ModalContent, ModalSize } from './content';

const StyledModal = styled(BaseModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface ModalProps extends Omit<BaseModalProps, 'children'> {
  /**
   * If `true`, the modal will show a close button in the top right corner
   */
  showCloseButton?: boolean;
  /**
   * md | lg | xl
   */
  size?: ModalSize;
  /**
   * If `true`, the modal will take up the width of the screen
   */
  isFullWidth?: boolean;
  /**
   * If `true`, the modal will take up the height of the screen
   */
  isFullHeight?: boolean;
  /**
   * If `true`, the modal will have rounded corners
   */
  isRounded?: boolean;
  /**
   * If `true`, the close button will be on the left
   */
  isCloseOnLeft?: boolean;
  /**
   * a way to label a dom node for cypress
   */
  cy?: string;
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  className,
  cy,
  showCloseButton = false,
  size = 'md',
  isFullWidth = false,
  isFullHeight = false,
  isRounded = true,
  isCloseOnLeft = false,
  ...props
}) => {
  const { onClose } = props;

  return (
    <StyledModal {...props}>
      {(ref) => (
        <ModalContent
          ref={ref}
          data-cy={cy}
          className={className}
          size={size}
          isFullWidth={isFullWidth}
          isFullHeight={isFullHeight}
          isRounded={isRounded}>
          {onClose && showCloseButton && <ModalCloseButton onClick={onClose} isCloseOnLeft={isCloseOnLeft} />}
          {children}
        </ModalContent>
      )}
    </StyledModal>
  );
};
