import { RootState } from 'src/store';
import { ExploreState } from './types';

export const selectExplore = (state: RootState): ExploreState => state.explore;

export const selectDeliveryDate = (state: RootState) => selectExplore(state).deliveryDate;

export const selectFoodItemMeta = (state: RootState) => selectExplore(state).foodItemMeta;

export const selectIsAllCuisinesShowing = (state: RootState) => selectExplore(state).isAllCuisinesShowing;

export const selectIsDishModalShowing = (state: RootState) =>
  !!selectExplore(state).shefId && !!selectExplore(state).foodItemSlug;
