import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './drew-moxon.jpg';

export const DrewMoxon: PersonInfo = {
  firstName: 'Drew',
  lastName: 'Moxon',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.DATA_SCIENTIST,
};
