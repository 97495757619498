import { createSelector, Dictionary } from '@reduxjs/toolkit';
import { sumBy } from 'lodash';
import { CartAddOnItem, CartFoodItem, CartLineItem } from '../types';
import {
  calcLineItemUnitPriceWithCustomizations,
  selectAddOnItems,
  selectAvailableLineItems,
  selectCartFoodItemsById,
  selectShefAvailableLineItems,
  selectShefsAvailableLineItems,
} from './lineItems';

export const calcLineItemsSubtotal = (lineItems: CartLineItem[], foodItems: Dictionary<CartFoodItem>) =>
  lineItems.reduce((subtotal, lineItem) => {
    const foodItemPrice = calcLineItemUnitPriceWithCustomizations(lineItem, foodItems[lineItem.foodItemId]);
    return subtotal + foodItemPrice * lineItem.quantity;
  }, 0);

export const calcLineItemsSubtotalByShefId = (lineItems: CartLineItem[], foodItems: Dictionary<CartFoodItem>) =>
  lineItems.reduce((acc, lineItem) => {
    const currentBalance = acc[lineItem.shefId] ?? 0;
    const foodItemPrice = calcLineItemUnitPriceWithCustomizations(lineItem, foodItems[lineItem.foodItemId]);
    const newBalance = currentBalance + foodItemPrice * lineItem.quantity;
    return { ...acc, [lineItem.shefId]: newBalance };
  }, {});

const calcSubtotal = (lineItems: CartLineItem[], foodItems: Dictionary<CartFoodItem>, addOnItems: CartAddOnItem[]) => {
  const lineItemsTotal = calcLineItemsSubtotal(lineItems, foodItems);
  const addOnsTotal = sumBy(addOnItems, (item) => item.price * item.quantity);
  return lineItemsTotal + addOnsTotal;
};

// selects the subtoal from the perspective of a single cart
// - a single shef + plus the state's add on items
export const selectSingleShefCartSubtotal = createSelector(
  selectShefAvailableLineItems,
  selectCartFoodItemsById,
  selectAddOnItems,
  calcSubtotal
);

export const selectSingleShefCartLineItemsSubtotal = createSelector(
  selectShefAvailableLineItems,
  selectCartFoodItemsById,
  calcLineItemsSubtotal
);

// selects the subtotal from the perspective of multi cart
// - one ore more shefs + plus the state's  add on items
export const selectMultiShefCartSubtotal = createSelector(
  selectShefsAvailableLineItems,
  selectCartFoodItemsById,
  selectAddOnItems,
  calcSubtotal
);

export const selectMultiShefCartLineItemsSubtotal = createSelector(
  selectShefsAvailableLineItems,
  selectCartFoodItemsById,
  calcLineItemsSubtotal
);

// selects the subtotal of the entire cart
// - does not factor in shef availablity so it is not safe to use for checkout
export const selectCartGrandSubtotal = createSelector(
  selectAvailableLineItems,
  selectCartFoodItemsById,
  selectAddOnItems,
  calcSubtotal
);

export const selectLineItemsSubtotalsByShefIds = createSelector(
  selectShefsAvailableLineItems,
  selectCartFoodItemsById,
  calcLineItemsSubtotalByShefId
);
