import {
  AnyAction,
  createListenerMiddleware,
  ListenerEffect,
  TypedAddListener,
  TypedStartListening,
} from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './index';

export const listenerMiddleware = createListenerMiddleware();

export type StartAppListener = TypedStartListening<RootState, AppDispatch>;
export type AppListener = TypedAddListener<RootState, AppDispatch>;
export type AppListenerEffect<Action extends AnyAction = AnyAction> = ListenerEffect<Action, RootState, AppDispatch>;

export const startAppListening = listenerMiddleware.startListening as StartAppListener;
