import stringHash from 'string-hash';

export interface IGatedRollout {
  /**
   * Returns true if they provided `key` is in the rollout, false otherwise.
   * @param key a unique key used as the seed for rollout calculations
   * @param threshold an integer between 0 and 100, inclusive
   */
  inRollout(key: string, threshold: number): boolean;
}

export class GatedRollout implements IGatedRollout {
  public inRollout(key: string, threshold: number): boolean {
    const rolloutHash = stringHash(`rollout${key}rollout`);

    // If the rollout group, a value from [0-99] is >= the rollout percent, do NOT run experiment.
    // Instead, return the control variant and exit early.
    // Example:
    // * [0-50] >= 51 is false, so 51/100 will be in the rollout group. [51-99] or 49/100 will exit early.
    // * [0-98] >= 99 is false, so 99/100 will be in the rollout group. [99-99] or 1/100 will exit early.
    return rolloutHash % 100 < threshold;
  }
}
