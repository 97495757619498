import { shefProfileSlice } from './profile';

export const {
  changeBio,
  changeCoverPhoto,
  changeCuisineCategoryIds,
  changeDropoffHub,
  changeEmail,
  changeFirstName,
  changeHasSubmittedPhotos,
  changeLastName,
  changeOrderCancelation,
  changeOrderCapacity,
  changeOrderCutoffTime,
  changeOrderNotification,
  changePhone,
  changeBusinessName,
  changeWebsiteUrl,
  changeFacebookHandle,
  changeInstagramHandle,
  changeTwitterHandle,
  changeTikTokHandle,
  changeLinkedInHandle,
  changeYoutubeHandle,
  changePhotoOptionA,
  changePhotoOptionB,
  changePhotoOptionC,
  changePhotoOptionAll,
  changePreviewImage,
  clearInputState,
  loadShefProfile,
  resetState,
  saveProfile,
} = shefProfileSlice.actions;
