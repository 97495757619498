import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './ashkan-kashani.jpg';

export const AshkanKashani: PersonInfo = {
  firstName: 'Ashkan',
  lastName: 'Kashani',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
