import {
  ExpirableStorageKeys,
  LocalStorageExpirationsInMs,
  SessionStorageKeys,
  StorageKeys,
} from 'common/storage/constants';
import localStorageFallbackUntyped, { isSupported } from 'local-storage-fallback';
import { isNil } from 'lodash';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { isNativeMobileApp } from 'src/mobile-app/isNativeMobileApp';
import Notifications from '../Notifications';

export interface IStorage extends Storage {}

export interface IStorageContext {
  local: IStorage;
  session: IStorage;
}

const localStorageFallback = localStorageFallbackUntyped as IStorage;

export function checkStorageSupport() {
  if (!isNativeMobileApp() && (!isSupported('localStorage') || !isSupported('sessionStorage'))) {
    Notifications.warning(
      'Web Storage API is not fully available. Update the browser or your privacy settings to gain maximum experience'
    );
  }
}

// get persistent storage with fallback support
export function getBrowserStorage(): IStorage {
  return localStorageFallback;
}

// get session-level storage with fallback support
export function getSessionStorage(): IStorage {
  // if we cannot reach sessionStorage, then use what's available to us
  if (!isSupported('sessionStorage')) {
    return localStorageFallback;
  }

  return window.sessionStorage;
}

export const storages = {
  local: getBrowserStorage(),
  session: getSessionStorage(),
};

type TWriteThroughStorage = Partial<Record<string, any>>;
type ProviderSetterType = (value: (value: TWriteThroughStorage) => TWriteThroughStorage) => void;

interface IStorageValue {
  local: TWriteThroughStorage;
  setLocal: ProviderSetterType;
  session: TWriteThroughStorage;
  setSession: ProviderSetterType;
}
const storageValue: IStorageValue = {
  local: {},
  setLocal: () => {},
  session: {},
  setSession: () => {},
};

export const StorageContext = React.createContext(storageValue);

export const StorageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [local, setLocal] = useState<IStorageValue['local']>({});
  const [session, setSession] = useState<IStorageValue['session']>({});

  const value = useMemo(
    () => ({
      local,
      setLocal,
      session,
      setSession,
    }),
    [local, session]
  );

  return <StorageContext.Provider value={value}>{children}</StorageContext.Provider>;
};

type SetType<T> = (value: T | ((value: T) => T)) => void;

function useProviderStorage<T>(
  backingStorage: IStorage,
  writeThroughStorage: TWriteThroughStorage,
  setter: ProviderSetterType,
  key: string,
  initialValue: T
): [T, SetType<T>] {
  const [memoizedInitialValue] = useState(initialValue);
  const value = useMemo(() => {
    const existingValue = writeThroughStorage[key] as T;
    if (!isNil(existingValue)) {
      return existingValue;
    }
    const storeValue = backingStorage.getItem(key);
    if (storeValue) {
      return JSON.parse(storeValue) as T;
    }
    return memoizedInitialValue;
  }, [backingStorage, memoizedInitialValue, key, writeThroughStorage]);

  type TSetter = T | ((value: T) => T);
  const set = useCallback(
    (newValue: TSetter) => {
      setter((store) => {
        const storedValue = (store[key] as T) ?? memoizedInitialValue;
        // Allow newValue to be a function so we have same API as useState
        const valueToStore = newValue instanceof Function ? newValue(storedValue) : newValue;
        try {
          // Save to local storage
          if (valueToStore !== undefined) {
            backingStorage.setItem(key, JSON.stringify(valueToStore));
          } else {
            backingStorage.removeItem(key);
          }
        } catch (error) {
          // A more advanced implementation would handle the error case
          console.error(error);
        }

        return {
          ...store,
          [key]: valueToStore,
        };
      });
    },
    [backingStorage, memoizedInitialValue, key, setter]
  );

  return [value, set];
}

export function useSessionStorage<T>(key: SessionStorageKeys, initialValue: T) {
  const storage = getSessionStorage();
  const { session, setSession } = useContext(StorageContext);
  return useProviderStorage(storage, session, setSession, key, initialValue);
}

export function useNonstrictSessionStorage<T>(key: SessionStorageKeys, initialValue: T | undefined) {
  const storage = getSessionStorage();
  const { session, setSession } = useContext(StorageContext);
  return useProviderStorage(storage, session, setSession, key, initialValue as T);
}

export function useLocalStorage<T>(key: string, initialValue: T): [T, SetType<T>] {
  const storage = getBrowserStorage();
  const { local, setLocal } = useContext(StorageContext);
  const [value, set] = useProviderStorage(storage, local, setLocal, key, initialValue);

  return [value, set];
}

type ExpirableType<T> = { value: T; expiresAtMs: number };
export function useExpirableLocalStorage<T>(
  key: ExpirableStorageKeys,
  initialValue: T
): [T | undefined, (value: T) => void] {
  const storage = getBrowserStorage();
  const { local, setLocal } = useContext(StorageContext);
  const [value, set] = useProviderStorage<ExpirableType<T>>(storage, local, setLocal, ExpirableStorageKeys[key], {
    value: initialValue,
    expiresAtMs: Date.now() + LocalStorageExpirationsInMs[key],
  });

  const expirableSetter = useCallback(
    (newValue: T) => {
      const expirableValue: ExpirableType<T> = {
        value: newValue,
        expiresAtMs: Date.now() + LocalStorageExpirationsInMs[key],
      };
      set(expirableValue);
    },
    [key, set]
  );

  // Get expirable version
  const finalValue = value && value.expiresAtMs >= Date.now() ? value.value : undefined;
  return [finalValue, expirableSetter];
}

// Used on logout, keeps certain keys
function clearStorage(storage: IStorage) {
  const keysToKeep = [];
  const copiedValues: { [key: string]: string } = {};
  for (const key of keysToKeep) {
    const item = storage.getItem(key);
    if (item) {
      copiedValues[key] = item;
    }
  }
  storage.clear();

  for (const [key, value] of Object.entries(copiedValues)) {
    storage.setItem(key, value);
  }
}

export function useClearAllStorage() {
  const { setSession, setLocal } = useContext(StorageContext);

  const clear = useCallback(() => {
    const local = getBrowserStorage();
    const session = getSessionStorage();

    clearStorage(local);
    clearStorage(session);

    setSession(() => ({}));
    setLocal(() => ({}));
  }, [setLocal, setSession]);
  return clear;
}

export const getToken = (): string | null => getBrowserStorage().getItem(StorageKeys.TOKEN);
export const setToken = (token: string) => getBrowserStorage().setItem(StorageKeys.TOKEN, token);
