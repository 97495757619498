import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useUserPreferences } from 'src/user-preferences/useUserPreferences';
import { MockTracker } from './trackers/mock-tracker';
import { ITracker } from './types';

const defaultTracker: ITracker = new MockTracker();
export const TrackerContext: React.Context<ITracker> = React.createContext(defaultTracker);

interface IProps {
  tracker: ITracker;
}

export const TrackerContextProvider: React.FC<React.PropsWithChildren<IProps>> = ({ children, tracker }) => {
  const [currentUser] = useCurrentUser();
  const [{ zipcode: cachedZipcode }] = useUserPreferences();

  // If we have user data (i.e. our global init state comes back with 'me' data, identify the user in the tracker.
  useEffect(() => {
    if (tracker && currentUser) {
      const { createdAt, lastSearchedZipCode } = currentUser;
      const userInfo = {
        ...currentUser,
        lastSearchedZipCode: cachedZipcode ?? lastSearchedZipCode ?? null,
        createdAt: DateTime.fromJSDate(new Date(createdAt)),
      };

      tracker.hasBeenIdentified() ? tracker.updateIdentity(userInfo) : tracker.identify(userInfo);
    }
  }, [cachedZipcode, currentUser, tracker]);

  return <TrackerContext.Provider value={tracker}>{children}</TrackerContext.Provider>;
};
