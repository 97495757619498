import { createSlice } from '@reduxjs/toolkit';
import { initialShefProfileState } from './initialState';
import {
  changeBio,
  changeBusinessName,
  changeCoverPhoto,
  changeCuisineCategoryIds,
  changeDropoffHub,
  changeEmail,
  changeFacebookHandle,
  changeFirstName,
  changeHasSubmittedPhotos,
  changeInstagramHandle,
  changeLastName,
  changeLinkedInHandle,
  changeOrderCancelation,
  changeOrderCapacity,
  changeOrderCutoffTime,
  changeOrderNotification,
  changePhone,
  changePhotoOptionA,
  changePhotoOptionAll,
  changePhotoOptionB,
  changePhotoOptionC,
  changePreviewImage,
  changeTikTokHandle,
  changeTwitterHandle,
  changeWebsiteUrl,
  changeYoutubeHandle,
  clearInputState,
  loadShefProfile,
  resetState,
  saveProfile,
} from './reducers';
import { ShefProfileState } from './types';

const createShefProfileSlice = (name: string, initialState: ShefProfileState) =>
  createSlice({
    name,
    initialState,
    reducers: {
      changeBio,
      changeCoverPhoto,
      changeCuisineCategoryIds,
      changeDropoffHub,
      changeEmail,
      changeFirstName,
      changeHasSubmittedPhotos,
      changeLastName,
      changeOrderCancelation,
      changeOrderCapacity,
      changeOrderCutoffTime,
      changeOrderNotification,
      changePhone,
      changeBusinessName,
      changeWebsiteUrl,
      changeFacebookHandle,
      changeInstagramHandle,
      changeTwitterHandle,
      changeTikTokHandle,
      changeLinkedInHandle,
      changeYoutubeHandle,
      changePhotoOptionA,
      changePhotoOptionB,
      changePhotoOptionC,
      changePhotoOptionAll,
      changePreviewImage,
      clearInputState,
      loadShefProfile,
      resetState,
      saveProfile,
    },
  });

export const shefProfileSlice = createShefProfileSlice('shefProfile', initialShefProfileState);
