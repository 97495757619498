import { useCallback, useRef, useState } from 'react';
import { defaultMaxZoomLevel, defaultZoomStep, LightboxWithControlsProps } from '../consts';
import { useLightbox } from './useLightbox';

export const getCoordinates = (e: React.TouchEvent | React.MouseEvent) => {
  let x = 0;
  let y = 0;
  if (e.nativeEvent instanceof TouchEvent) {
    x = e.nativeEvent.touches[0].pageX;
    y = e.nativeEvent.touches[0].pageY;
  } else {
    x = e.nativeEvent.pageX;
    y = e.nativeEvent.pageY;
  }
  return { x, y, xVal: x, yVal: y };
};

export const useLightboxControls = (props: LightboxWithControlsProps) => {
  const { handleClickContainer, isHiding, isShown, isShowing, ...otherProps } = useLightbox(props);
  const { className, header, maxZoomLevel = defaultMaxZoomLevel, zoomStep = defaultZoomStep } = props;

  const lastX = useRef(0);
  const lastY = useRef(0);
  const initX = useRef(0);
  const initY = useRef(0);
  const [isMoving, setIsMoving] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const imageRef = useRef<HTMLDivElement>(null);

  const setCurrentAndLastX = useCallback((xVal) => {
    lastX.current = xVal;
    setX(xVal);
  }, []);

  const setCurrentAndLastY = useCallback((yVal) => {
    lastX.current = yVal;
    setY(yVal);
  }, []);

  const startMove = useCallback(
    (e: React.TouchEvent | React.MouseEvent) => {
      e.stopPropagation();
      if (zoomLevel <= 1) return false;
      setIsMoving(true);
      const { xVal, yVal } = getCoordinates(e);
      initX.current = xVal - lastX.current;
      initY.current = yVal - lastY.current;
      return true;
    },
    [zoomLevel]
  );

  const duringMove = useCallback(
    (e: React.TouchEvent | React.MouseEvent) => {
      e.stopPropagation();
      if (!isMoving) return false;
      const { xVal, yVal } = getCoordinates(e);
      setCurrentAndLastX(xVal - initX.current);
      setCurrentAndLastY(yVal - initY.current);
      return true;
    },
    [isMoving, setCurrentAndLastX, setCurrentAndLastY]
  );

  const endMove = useCallback(() => {
    setIsMoving(false);
    if (imageRef?.current) {
      const { clientHeight, clientWidth } = imageRef.current;
      const imageHeight = clientHeight * zoomLevel;
      const imageWidth = clientWidth * zoomLevel;
      const maxXBounds = Math.abs(imageWidth - window.innerWidth) / 2;
      const maxYBounds = Math.abs(imageHeight - window.innerHeight) / 2;
      if (x < maxXBounds * -1) {
        setCurrentAndLastX(maxXBounds * -1);
      } else if (x > maxXBounds) {
        setCurrentAndLastX(maxXBounds);
      }
      if (y < maxYBounds * -1) {
        setCurrentAndLastY(maxYBounds * -1);
      } else if (y > maxYBounds) {
        setCurrentAndLastY(maxYBounds + 48);
      }
    }
  }, [setCurrentAndLastX, setCurrentAndLastY, x, y, zoomLevel]);

  const resetZoom = () => {
    setZoomLevel(1);
    setX(0);
    setY(0);
  };

  const zoomIn = useCallback(() => {
    setZoomLevel(Math.min(zoomLevel + zoomStep, maxZoomLevel));
  }, [maxZoomLevel, zoomLevel, zoomStep]);

  const zoomOut = useCallback(() => {
    const newZoomLevel = zoomLevel - zoomStep;
    if (newZoomLevel === 1) {
      resetZoom();
    } else if (newZoomLevel > 1) {
      setZoomLevel(newZoomLevel);
    }
  }, [zoomLevel, zoomStep]);

  return {
    ...otherProps,
    className,
    isShown,
    backdropProps: {
      isShown,
      isShowing,
      isHiding,
      onClick: () => {
        if (zoomLevel === 1) {
          handleClickContainer();
        }
      },
    },
    closeButtonProps: {
      canClick: true,
      onClick: () => {
        handleClickContainer();
        resetZoom();
      },
    },
    header,
    lightboxContainerProps: {
      isShown,
      isShowing,
      isHiding,
      onMouseDown: startMove,
      onTouchStart: startMove,
      onMouseMove: duringMove,
      onTouchMove: duringMove,
      onMouseUp: endMove,
      onMouseLeave: endMove,
      onTouchEnd: endMove,
    },
    imageContainerProps: {
      ref: imageRef,
      isMoving,
      isZoomed: zoomLevel > 1,
      style: {
        transform: `translate3d(${x}px,${y}px,0px) scale(${zoomLevel})`,
      },
    },
    zoomInButtonProps: {
      canClick: zoomLevel < maxZoomLevel,
      onClick: zoomIn,
    },
    zoomOutButtonProps: {
      canClick: zoomLevel > 1,
      onClick: zoomOut,
    },
  };
};
