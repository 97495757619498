import { JSONCodec } from 'common/codecs/JSONCodec';
import { StorageKeys } from 'common/storage/constants';
import * as t from 'io-ts';
import { IStorage } from 'src/shared/storage/Storage';
import type { RootState } from 'src/store';
import type { NonNegative } from 'type-fest';

export type MigrationFn<PrevState, State> = (prevState: PrevState) => State;

export interface IFirstStateVersion {
  version: 1;
  codec: t.Decoder<unknown, any>; // io-ts codec to decode / encode state to/from storage for this version
}

export interface INextStateVersion<PrevState, State> {
  version: Exclude<NonNegative<number>, 1>;
  migration: MigrationFn<PrevState, State>; // migration to go from previous version to current version (not required for first version)
  codec: t.Decoder<unknown, any>; // io-ts codec to decode / encode state to/from storage for this version
}

export type IStateVersion = IFirstStateVersion | INextStateVersion<any, any>;

export type IStateVersions = [IFirstStateVersion, ...INextStateVersion<any, any>[]];

export interface IStoredStateOptions {
  key: StorageKeys; // key to store data under in storage
  expiresAt?: number; // timestamp when state becomes invalid, otherwise it's valid indefinitely
  currentVersion: number; // latest version of the state
  versions: IStateVersions; // migrations responsible for upgrading state to latest version
}

export type IStoredState = {
  [key in keyof RootState]?: IStoredStateOptions;
};

export type ILoadPersistedState = (params: { storage: IStorage }) => Partial<RootState>;

export type ISavePersistedState = (params: { storage: IStorage; state: Partial<RootState> }) => void;

export const PersistedState = new JSONCodec(
  'PersistedState',
  t.type({
    version: t.number, // version attached to the state, if version is stale it should be migrated to the latest version
    expiresAt: t.union([t.number, t.undefined]), // timestamp when state becomes invalid, otherwise it's valid indefinitely
    state: t.unknown, // state being stored
  })
);

export type IPersistedState = typeof PersistedState._T;
