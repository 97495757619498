import { waitUntil } from 'common/PromiseUtils';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { OAuthLoginType, OAuthMissingFieldsResult } from 'src/gqlReactTypings.generated.d';
import { FaApple } from 'src/shared/design-system/FontAwesome';
import { LoginButtonV2 } from 'src/shared/design-system/Login/LoginButtonV2';
import { usePrevious } from 'src/shared/hooks/usePreviousHook';
import { useScript } from 'src/shared/hooks/useScriptHook';
import { v4 as uuidv4 } from 'uuid';
import { Colors, Spacing } from 'web-common/src/shared/styles';
import { decodeOAuthState, getEncodedOAuthState, isOAuthStateMatching } from './oAuthState';
import { useLoginOrSignUpWithOAuth } from './useLoginOrSignUpWithOAuth';

const SIGN_IN_WITH_APPLE_SCRIPT =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

interface IAppleLoginProps {
  onSuccess?: (isExistingUser: boolean) => void;
  onError: (error: Error) => void;
  onAttempt?: () => void;
  onMissingFields: (type: OAuthLoginType, token: string, missingFieldsResult: OAuthMissingFieldsResult) => void;
  requireZipCode?: boolean;
  signUpEntryPoint?: string | null;
}

export const AppleLogin: React.FC<IAppleLoginProps> = ({
  onSuccess,
  onError,
  onAttempt,
  onMissingFields,
  requireZipCode,
  signUpEntryPoint,
}) => {
  const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID;
  if (isNil(appleClientId)) {
    throw new Error('Missing env variable: REACT_APP_APPLE_CLIENT_ID');
  }

  const redirectUrlBase = process.env.REACT_APP_FRONT_END_URL;
  if (isNil(redirectUrlBase)) {
    throw new Error('Missing env variable: REACT_APP_FRONT_END_URL');
  }

  const [appleLoginScriptLoaded, appleLoginScriptLoadingError] = useScript(SIGN_IN_WITH_APPLE_SCRIPT);
  const prevAppleLoginScriptLoaded = usePrevious(appleLoginScriptLoaded);
  const [loginQueued, setLoginQueued] = useState(false);
  const { login } = useLoginOrSignUpWithOAuth({
    type: OAuthLoginType.Apple,
    onError,
    onSuccess,
    onMissingFields,
    requireZipCode,
    signUpEntryPoint,
  });
  const appleAuthInitialized = useRef(false);
  const uuid = useRef<string>(uuidv4());

  const loginWithApple = useCallback(async () => {
    try {
      if (!appleAuthInitialized.current) {
        await waitUntil(() => !isNil(window.AppleID?.auth?.init), 200, 5);

        const redirectUri = new URL('/login', redirectUrlBase);
        window.AppleID.auth.init({
          clientId: appleClientId,
          scope: 'name email',
          redirectURI: redirectUri.toString(),
          state: getEncodedOAuthState(OAuthLoginType.Apple, uuid.current),
          usePopup: true,
        });
        appleAuthInitialized.current = true;
      }

      const response = await window.AppleID.auth.signIn();

      const state = decodeOAuthState(response.authorization.state);
      if (!isOAuthStateMatching(state, OAuthLoginType.Apple, uuid.current)) {
        return;
      }

      const token = response.authorization.id_token;
      const firstName = response.user?.name?.firstName;
      const lastName = response.user?.name?.lastName;
      login(token, { firstName, lastName, signUpEntryPoint });
    } catch (e) {
      console.error(e);
      onError(e);
    }
  }, [appleClientId, login, onError, redirectUrlBase]);

  useEffect(() => {
    if (appleLoginScriptLoadingError && loginQueued) {
      const loadingError = new Error('Failed to load Sign in with Apple script');
      console.error(loadingError);
      setLoginQueued(false);
      onError(loadingError);
      return;
    }

    if (appleLoginScriptLoaded && !prevAppleLoginScriptLoaded) {
      if (loginQueued) {
        loginWithApple();
        setLoginQueued(false);
      }
    }
  }, [appleLoginScriptLoaded, appleLoginScriptLoadingError, loginQueued]);

  const onClick = () => {
    onAttempt?.();
    if (appleLoginScriptLoadingError) {
      const loadingError = new Error('Failed to load Sign in with Apple script');
      console.error(loadingError);
      onError(loadingError);
      return;
    }

    if (appleLoginScriptLoaded) {
      loginWithApple();
    } else {
      // if the button is clicked before the apple script is done loading, queue the sign in for when the script loads
      setLoginQueued(true);
    }
  };

  return (
    <LoginButtonV2 icon={<FaApple color={Colors.BLACK} style={{ height: Spacing.SINGLE_QUARTER }} />} onClick={onClick}>
      Continue with Apple
    </LoginButtonV2>
  );
};
