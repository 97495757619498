import { oneLine } from 'common-tags';
import { Colors } from 'web-common/src/shared/styles/colors';
import { ScreenWidth, ScreenWidthValues } from 'web-common/src/shared/styles/screenWidth';

// KEEPING export here since it's still referenced in a lot of places.
// Moved to separate file in shared/styles/colors.ts ans screenWidth.ts
export { Colors, ScreenWidth, ScreenWidthValues };

export enum TextAlign {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ObjectPosition {
  CENTER = 'center',
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

export enum IndexPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const Time = {
  ONE_HOUR_IN_MS: 60 * 60 * 1000,
  ONE_MINUTE_IN_MS: 60 * 1000,
};

export const MAX_FEATURED_SHEFS_TO_PICK = 3;
export const FAVORITE_SHEF_COUNT_TO_SHOW = 3;

export const FOOD_ITEM_IMAGE_WIDTH_TO_HEIGHT_ASPECT_RATIO = 1.8;
export const FOOD_ITEM_CAROUSEL_HEIGHT = 185;
export const FOOD_ITEM_CAROUSEL_HEIGHT_MOBILE = 16 * 10;
export const CUISINE_ICON_WIDTH = 80;
export const SHEF_IMAGE_DIMENSION = 72;

export const SCHEMA_VERSION_HEADER_NAME = 'schema';
export const IS_BOT_KEY_NAME = 'isBot';

export const MIN_RATINGS_TO_SHOW_SHEF_THEIR_RATINGS = 3;

// NOTE IF YOU CHANGE THIS: make sure the displayText values in ShefTip component are reasonable.
export const DEFAULT_SHEF_TIP = 200;

export const NUM_DISHES_SERVED = 1_000_000;

export const DELIVERY_DATE_TOOLTIP = oneLine`Our shefs source fresh ingredients and cook your meal in traditional, 
no-shortcut ways, which means it’s not available immediately. All dishes come refrigerated 
and stored in an insulated bag, ready to heat and eat!`;

export const TITLE = 'Shef | Homemade food, delivered to your door.';
