import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const ArrowSoftLeft = (props: React.SVGProps<SVGSVGElement>) => (
  // View box is a little off to keep arrow centered
  <SVG height={16} width={16} viewBox={'0 0 16 14.5'} {...props}>
    {/* eslint-disable-next-line max-len -- SVG path properties */}
    <Path d='M15.5 6.99572C15.5 7.61136 15.031 8.09019 14.4615 8.09019L4.17672 8.09019L7.6943 11.7156C8.12982 12.126 8.12982 12.8443 7.6943 13.2547C7.4933 13.4599 7.22529 13.5625 6.95729 13.5625C6.65578 13.5625 6.38777 13.4599 6.18677 13.2547L0.826633 7.78237C0.391122 7.37195 0.391122 6.65371 0.826633 6.24328L6.18677 0.770969C6.58878 0.326344 7.29229 0.326344 7.6943 0.770969C8.12982 1.18139 8.12982 1.89963 7.6943 2.31006L4.17672 5.90126L14.4615 5.90126C15.031 5.90126 15.5 6.41429 15.5 6.99572Z' />
  </SVG>
);
