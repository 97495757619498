import { DEFAULT_PAGE_SIZE_PARTIAL } from './consts';
import { SearchState } from './types';

export const initialSearchState: SearchState = {
  activeZipCode: null,
  dishPageSize: DEFAULT_PAGE_SIZE_PARTIAL,
  isPartialQuery: true,
  isSearchHidden: true,
  isSearchInputFocused: false,
  shefPageSize: DEFAULT_PAGE_SIZE_PARTIAL,
  searchQuery: '',
  modalDeliveryDates: [],
};

export const useInitialSearchState = (): SearchState => ({ ...initialSearchState });
