export enum TransitionTime {
  HALF = '0.11s',
  BASE = '0.22s',
  DOUBLE = '0.44s',
}

export enum TransitionTimeValue {
  HALF = 110,
  BASE = 220,
  DOUBLE = 440,
}

export const Transition = {
  Time: TransitionTime,
  TimeValue: TransitionTimeValue,
};
