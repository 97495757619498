import { intersection, map, uniq } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useCartTotalsLazyQuery } from 'src/gqlReactTypings.generated.d';
import { usePromotionalsHook } from 'src/promotionals/usePromotionalsHook';
import { useDispatch, useSelector } from 'src/store';
import { setAllDiscountsApplied, setDiscountApplied, setPromoApplied } from 'src/store/cart/actions';
import { selectCart, selectDiscountApplied, selectPromoApplied } from 'src/store/cart/selectors';
import { getEntityList } from 'src/store/utils';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import { useCartTotalsState } from './useCartTotalsState';
import { mapCartAddOnItems } from './utils';

export const useCartTotals = (includeAddOns = false) => {
  const [zipCode] = useCachedZipCode();
  const { code: promoCode } = usePromotionalsHook();
  const dispatch = useDispatch();
  const { lastDiscountApplied, lastPromoApplied, shefIds } = useSelector((state) => {
    const cart = selectCart(state);
    const cartShefIds = uniq(map(getEntityList(cart.lineItems), 'shefId'));

    return {
      lastDiscountApplied: selectDiscountApplied(cart),
      lastPromoApplied: selectPromoApplied(cart),
      shefIds: cartShefIds,
    };
  });

  const lastCartItemCount = useRef(0);
  const [hasPromoCode, setHasPromoCode] = useState(!!promoCode);

  const {
    activeCartItemCount,
    addOnItems,
    addOnItemsSubtotal,
    checkoutShefIds,
    deliveryDate,
    hasItemsInCart,
    isMultiCart,
    lineItemsSubtotal,
    perShefOrders,
  } = useCartTotalsState();

  const [runCartTotals, { data, error, loading }] = useCartTotalsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const updateCartTotal = useCallback(() => {
    if (hasPromoCode && perShefOrders.length > 0) {
      // Only run if promo code exists. Otherwise bulk discount will be calculated server side.
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- Then clause sets store values.
      runCartTotals({
        variables: {
          cartInput: {
            perShefOrders,
            addOnItems: includeAddOns ? mapCartAddOnItems(addOnItems) : [],
            lineItemsSubtotal,
            addOnItemsSubtotal: includeAddOns ? addOnItemsSubtotal : 0,
            shefTip: 0,
            expectedAdditionalDeliveryDateSpend: 0,
            deliveryDate,
            promoCode: promoCode?.code ?? null,
            zipCode,
          },
        },
      }).then(({ data: loadedData }) => {
        if (loadedData?.cartTotals) {
          if (
            loadedData.cartTotals.nonPromoDiscountApplied !== lastDiscountApplied &&
            loadedData.cartTotals.promoApplied !== lastPromoApplied
          ) {
            dispatch(
              setAllDiscountsApplied({
                discountApplied: loadedData.cartTotals.nonPromoDiscountApplied,
                promoApplied: loadedData.cartTotals.promoApplied,
              })
            );
          } else if (loadedData.cartTotals.nonPromoDiscountApplied !== lastDiscountApplied) {
            dispatch(setDiscountApplied({ discountApplied: loadedData.cartTotals.nonPromoDiscountApplied }));
          } else if (loadedData.cartTotals.promoApplied !== lastPromoApplied) {
            dispatch(setPromoApplied({ promoApplied: loadedData.cartTotals.promoApplied }));
          }
        }

        if (promoCode && !intersection(shefIds, promoCode.restrictedToShefIds)[0]) {
          dispatch(setPromoApplied({ promoApplied: 0 }));
        }
      });
    }
  }, [
    addOnItems,
    addOnItemsSubtotal,
    deliveryDate,
    dispatch,
    hasPromoCode,
    includeAddOns,
    lastDiscountApplied,
    lastPromoApplied,
    lineItemsSubtotal,
    perShefOrders,
    promoCode,
    runCartTotals,
    shefIds,
    zipCode,
  ]);

  useEffect(() => {
    if (!hasPromoCode && !!promoCode) {
      setHasPromoCode(true);
    } else if (hasPromoCode && !promoCode) {
      setHasPromoCode(false);
      dispatch(setPromoApplied({ promoApplied: 0 }));
    }
    if (activeCartItemCount !== lastCartItemCount.current) {
      // eslint-disable-next-line functional/immutable-data -- Track previous number of items in cart.
      lastCartItemCount.current = activeCartItemCount;
      updateCartTotal();
    }
  }, [activeCartItemCount, dispatch, hasPromoCode, lastCartItemCount, promoCode, updateCartTotal]);

  const {
    deliveryFee = 0,
    discountApplied = lastDiscountApplied,
    promoApplied = lastPromoApplied,
    subtotal = includeAddOns ? lineItemsSubtotal + addOnItemsSubtotal : lineItemsSubtotal,
  } = data?.cartTotals ?? {};
  const subTotalAfterPromo = Math.max(subtotal - promoApplied, 0);

  return {
    activeCartItemCount,
    addOnItemsSubtotal,
    addOnItems,
    deliveryFee,
    discountApplied,
    isMultiCart,
    checkoutShefIds,
    data,
    deliveryDate,
    error,
    hasItemsInCart,
    hasPromoCode,
    lineItemsSubtotal,
    loading,
    perShefOrders,
    promoCode,
    promoApplied,
    subtotal,
    subTotalAfterPromo,
    updateCartTotal,
  };
};
