import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './oleg-panichev.jpg';

export const OlegPanichev: PersonInfo = {
  firstName: 'Oleg',
  lastName: 'Panichev',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.PRODUCT_DESIGNER,
};
