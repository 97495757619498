import { noOp } from 'common/Constants';
import React, { useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { DEFAULT_MAX_DROPDOWN_HEIGHT, DropdownProps, DropdownSize, KeyPressType } from './consts';
import { useMenuPosition, useTypingFilter } from './hooks';
import { Input } from './Input';
import { Item } from './Item';
import { Menu } from './Menu';
import { Container } from './styles';
import { defaultIsItemSelected, getItemDisplayValue } from './utils';

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    alwaysOpenUpwards = false,
    autocomplete = false,
    borderRadius,
    className,
    disabled,
    isItemSelectable,
    items = [],
    light = true,
    maxMenuHeight = DEFAULT_MAX_DROPDOWN_HEIGHT,
    onChange = noOp,
    placeholder,
    rounded = false,
    showDropdownArrow = true,
    size = DropdownSize.M,
    useMobileDropdown = false,
    value = '',
    ...restProps
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | number>('');

  const { menuPosition, ref } = useMenuPosition({ isOpen, alwaysOpenUpwards, rounded });
  const { getItemFromKeyPress, onKeyPress } = useTypingFilter({ autocomplete, isOpen, items });

  const selectedItem = items.find((item) => item.value === value);
  const valueToShow = props.valueToShow ?? (inputValue || getItemDisplayValue(selectedItem) || '');
  const showMobileDropdown = useMobileDropdown && !autocomplete;

  return (
    <Container className={className} borderRadius={borderRadius} light={light} rounded={rounded} size={size}>
      <Autocomplete
        items={items}
        isItemSelectable={isItemSelectable ?? defaultIsItemSelected}
        getItemValue={getItemDisplayValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onMenuVisibilityChange={(isMenuOpen) => {
          setIsOpen(isMenuOpen);
        }}
        onSelect={(displayedValue, item) => {
          setInputValue(displayedValue);
          onChange(item.value, item);
        }}
        open={isOpen}
        ref={ref}
        renderInput={({ onKeyDown, ref, ...otherProps }) => (
          <Input
            {...otherProps}
            {...restProps}
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            ref={ref as React.ForwardedRef<HTMLInputElement>}
            autocomplete={autocomplete}
            disabled={disabled}
            isOpen={isOpen}
            items={items}
            onChangeValue={onChange}
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            onKeyDown={onKeyDown as KeyPressType}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            setInputValue={setInputValue}
            showDropdownArrow={showDropdownArrow}
            showMobileDropdown={showMobileDropdown}
            size={size}
          />
        )}
        renderItem={(item, isHighlighted) => {
          const isSelected = item.value === selectedItem?.value;
          const selectedItemFromKeypress = getItemFromKeyPress();
          const highlightedValue = getItemDisplayValue(selectedItemFromKeypress);

          return (
            <Item
              key={`${item.value}`}
              isHighlighted={isHighlighted}
              highlightedValue={highlightedValue}
              item={item}
              isSelected={isSelected}
              size={size}
              data-cy='dropdown-item'
            />
          );
        }}
        renderMenu={(items) =>
          disabled ? null : (
            <Menu
              alwaysOpenUpwards={alwaysOpenUpwards}
              items={items}
              borderRadius={borderRadius}
              rounded={rounded}
              size={size}
              style={{ ...menuPosition, maxHeight: maxMenuHeight }}
            />
          )
        }
        shouldItemRender={(item, value) =>
          autocomplete ? getItemDisplayValue(item)?.toLowerCase().indexOf(value?.toLowerCase()) > -1 : true
        }
        value={autocomplete && isOpen && !inputValue ? '' : valueToShow}
        wrapperStyle={{}}
      />
    </Container>
  );
};
