import { noOp } from 'common/Constants';
import React, { useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useScrollLock } from 'src/shared/hooks/useScrollLock';
import { ZIndexes } from 'src/shared/ZIndexes';
import styled, { css, keyframes } from 'styled-components';
import { ModalBackdrop } from './ModalBackdrop';
import { findOrCreateModalRoot, FocusableElement, ModalAnimation } from './utils';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const BaseModalContainer = styled.div<{ animation?: ModalAnimation }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndexes.Modal};

  ${({ animation }) =>
    animation === 'fade'
      ? css`
          -webkit-animation: ${fadeIn} 0.3s both;
          animation: ${fadeIn} 0.3s both;
        `
      : ''}
`;

export interface BaseModalProps extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className'> {
  className?: string;
  /**
   * If `true`, the modal will be showing
   */
  isShowing: boolean;
  /**
   * Callback invoked to close the modal
   */
  onClose?: () => void;
  /**
   * If `true`, the modal will be displayed on top of an overlay
   */
  showBackdrop?: boolean;
  /**
   * If `true`, scrolling will be disabled on the document body when the modal opens
   */
  blockScrollOnMount?: boolean;
  /**
   * If `true`, the modal will close when the backdrop is clicked
   */
  closeOnBackdropClick?: boolean;
  /**
   * A ref to the element to receive focus when the modal opens
   */
  initialFocusRef?: React.RefObject<FocusableElement>;
  /**
   * none | fade
   */
  animation?: ModalAnimation;
  /**
   * Callback to render the content of the modal. Passes in the ref for scroll lock purposes.
   */
  children: (ref: React.RefObject<HTMLDivElement>) => React.ReactNode;
}

export const BaseModal: React.FC<BaseModalProps> = ({
  className,
  children,
  onClose,
  isShowing,
  showBackdrop = true,
  blockScrollOnMount = true,
  closeOnBackdropClick = true,
  animation = 'fade',
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useScrollLock({
    enabled: isShowing && blockScrollOnMount,
    scrollableTargetRef: ref,
  });

  const root = findOrCreateModalRoot();

  const backDropClick = useMemo(() => (closeOnBackdropClick ? onClose : noOp), [closeOnBackdropClick, onClose]);

  return ReactDOM.createPortal(
    isShowing ? (
      <BaseModalContainer animation={animation} className={className}>
        <ModalBackdrop isShowing={showBackdrop} onClick={backDropClick} />
        {children(ref)}
      </BaseModalContainer>
    ) : null,
    root
  );
};
