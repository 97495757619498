import { Clickable } from 'src/shared/design-system/Utilities';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { Colors, Font, Spacing, Transition, ZIndex } from 'web-common/src/shared/styles';

type LightboxProps = {
  isShown?: boolean;
  isShowing?: boolean;
  isHiding?: boolean;
  onClick?: () => void;
};

const sharedPositionStyles = `
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const Backdrop = styled.div<LightboxProps>`
  background: rgba(0, 0, 0, 0.7);
  transition: opacity ${Transition.Time.BASE};
  z-index: ${ZIndex.LightBoxBackdrop};

  ${({ isShown, isShowing, isHiding }) =>
    match({ isShown, isShowing, isHiding })
      .with(
        { isShown: true, isHiding: true },
        () => css`
          ${sharedPositionStyles}
          opacity: 0;
          position: fixed;
        `
      )
      .with(
        { isShown: true },
        () => css`
          ${sharedPositionStyles}
          opacity: 1;
          position: fixed;
          pointer-events: all;
        `
      )
      .with(
        { isShown: false, isShowing: true },
        () => css`
          ${sharedPositionStyles}
          opacity: 1;
          position: fixed;
        `
      )
      .otherwise(
        () => css`
          opacity: 0;
          position: absolute;
          pointer-events: none;
        `
      )};
`;

export const Container = styled.div<LightboxProps>`
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40' width='40' viewBox='0 0 40 40' xml:space='preserve'%3E%3Ccircle cx='20' cy='20' r='20' opacity='0.8'/%3E%3Cpath fill='%23FFFFFF' d='M19.25,17.55c0.47,0.47,0.47,1.23,0,1.7c-0.23,0.23-0.54,0.35-0.85,0.35s-0.62-0.12-0.85-0.35L14.4,16.1V18 c0,0.66-0.54,1.2-1.2,1.2c-0.66,0-1.2-0.54-1.2-1.2v-4.8c0-0.66,0.54-1.2,1.2-1.2H18c0.66,0,1.2,0.54,1.2,1.2 c0,0.66-0.54,1.2-1.2,1.2h-1.9L19.25,17.55z M26.8,20.8c-0.66,0-1.2,0.54-1.2,1.2v1.9l-3.15-3.15c-0.47-0.47-1.23-0.47-1.7,0 s-0.47,1.23,0,1.7l3.15,3.15H22c-0.66,0-1.2,0.54-1.2,1.2c0,0.66,0.54,1.2,1.2,1.2h4.8c0.66,0,1.2-0.54,1.2-1.2V22 C28,21.34,27.46,20.8,26.8,20.8z'/%3E%3C/svg%3E")
      20 20,
    pointer;
  width: 100%;
  opacity: ${ifProp('isShown', 0.8, 1)};
  transition: opacity ${Transition.Time.BASE};
`;

export const CloseContainer = styled(Clickable)<LightboxProps>`
  background: ${Colors.GRAY_DARK_10};
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  top: -${Spacing.SINGLE};
  right: -${Spacing.SINGLE};
  color: ${Colors.WHITE};
  border-radius: 100%;
  border: 2px solid ${Colors.WHITE};
  height: ${Spacing.DOUBLE};
  width: ${Spacing.DOUBLE};
  padding: ${Spacing.QUARTER};
  transition: background ${Transition.Time.BASE};

  & svg {
    height: 100%;
    width: 100%;
    color: ${Colors.WHITE};
  }

  &:hover {
    background: ${Colors.GRAY_DARK_30};
  }

  &:active {
    background: ${Colors.BLACK};
  }
`;

export const FullScreenContainer = styled(CloseContainer)`
  right: ${Spacing.SINGLE_HALF};
`;

export const ImageContainer = styled.div<{ isMoving?: boolean; isZoomed?: boolean }>`
  user-drag: none;
  ${ifProp(
    'isZoomed',
    css`
      cursor: grab;
    `
  )};
  ${ifProp(
    'isMoving',
    ``,
    css`
      transition: transform ${Transition.Time.BASE};
    `
  )}
  & > img {
    max-height: calc(100vh - ${Spacing.QUADRUPLE}) !important;
    max-width: calc(100vw - ${Spacing.QUADRUPLE}) !important;
    user-drag: none;
    user-select: none;
  }
`;

export const LightboxContainer = styled.div<LightboxProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: ${ZIndex.LightBox};
  transition: opacity ${Transition.Time.BASE}, transform ${Transition.Time.BASE};

  ${({ isShowing, isHiding }) =>
    match({ isShowing, isHiding })
      .with(
        { isHiding: true },
        () => css`
          transform: translate(-50%, -50%) scale(0.8);
        `
      )
      .with(
        { isShowing: true },
        () => css`
          transform: translate(-50%, -50%) scale(0.8);
          opacity: 0;
        `
      )
      .otherwise(
        () => css`
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        `
      )};
`;

export const LightboxMenuContainer = styled.div`
  align-items: center;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right; 0;
  height: ${Spacing.TRIPLE};
  width: 100vw;
  max-width: 100vw;
  z-index: ${ZIndex.LightBoxMenu};
`;

export const LightboxMenuHeader = styled.div`
  color: ${Colors.WHITE};
  flex: 1;
  font-size: ${Font.Size.M};
  overflow: hidden;
  padding-left: ${Spacing.SINGLE};
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LightboxMenuButton = styled(Clickable)<{ canClick?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${Spacing.TRIPLE};
  width: ${Spacing.TRIPLE};
  transition: background ${Transition.Time.BASE};

  ${ifProp(
    'canClick',
    css`
      cursor: pointer;
      & svg {
        color: ${Colors.WHITE};
      }
      &: hover {
        background: ${Colors.GRAY_DARK_30};
      }
    `
  )}
`;
