import styled, { css } from 'styled-components';
import { Colors, Font, Mixins, Spacing } from 'web-common/src/shared/styles';

export const MIN_EXPAND_TEXT_WIDTH = 100;

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const ExpandedText = styled.div`
  position: absolute;
  pointer-events: none;
  opacity: 0;
`;

export const SingleLine = styled(ExpandedText)`
  white-space: nowrap;
`;

export const InitialText = styled.div`
  overflow: hidden;
`;

export const LastLineContainer = styled.div`
  display: flex;
`;

export const LastLineWrapper = styled.div<{ expandTextWidth?: number }>`
  overflow: hidden;
  ${({ expandTextWidth = MIN_EXPAND_TEXT_WIDTH }) => css`
    -webkit-mask-image: linear-gradient(
      to right,
      black calc(80% - ${expandTextWidth || MIN_EXPAND_TEXT_WIDTH}px),
      transparent calc(100% - ${expandTextWidth || MIN_EXPAND_TEXT_WIDTH}px)
    );
    mask-image: linear-gradient(
      to right,
      black calc(80% - ${expandTextWidth || MIN_EXPAND_TEXT_WIDTH}px),
      transparent calc(100% - ${expandTextWidth || MIN_EXPAND_TEXT_WIDTH}px)
    );
  `}
`;

export const LastLine = styled.div<{ numberOfLines?: number }>`
  overflow: hidden;
  ${({ numberOfLines = 3 }) => Mixins.Text.overflow(numberOfLines)}
`;

export const ExpandText = styled.span<{ isExpanded?: boolean }>`
  color: ${Colors.AQUA_DARK_10};
  cursor: pointer;
  font-weight: ${Font.Weight.SEMI_BOLD};
  bottom: 0;
  right: 0;
  white-space: nowrap;

  ${({ isExpanded }) => css`
    padding-left: ${isExpanded ? Spacing.QUARTER : Spacing.SINGLE};
    position: ${isExpanded ? 'static' : 'absolute'};
  `};

  &:hover {
    text-decoration: underline;
  }
`;
