import { useEffect } from 'react';
import { ITracker } from 'src/tracking/types';
import { useTracker } from './useTracker';

export const useTrackOnMount: ITracker['track'] = (event, data) => {
  const tracker = useTracker();
  useEffect(() => {
    tracker.track(event, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
