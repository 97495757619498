import { ClientEvents, ClientTrackableEvents } from 'common/events/ClientEvents';
import { isNil, isUndefined, map } from 'lodash';
import { Packaging } from 'src/gqlReactTypings.generated.d';
import { ISegmentMetrics } from 'src/pages/consumer/explore/SegmentAnalytics';
import { CartActionLocation, CartFoodItem } from 'src/store/cart/types';

export function getFoodItemToTrack(
  fragment: CartFoodItem,
  location?: CartActionLocation,
  segmentMetrics?: ISegmentMetrics,
  idx?: number
): ClientTrackableEvents[ClientEvents.CART_ADD_ITEM | ClientEvents.CART_REMOVE_ITEM] {
  return {
    id: fragment.id ?? null,
    price: fragment.price ?? null,
    foodType: fragment.foodType ?? null,
    vegan: fragment.vegan ?? null,
    vegetarian: fragment.vegetarian ?? null,
    lowFodmap: fragment.lowFodmap ?? null,
    paleo: fragment.paleo ?? null,
    whole30: fragment.whole30 ?? null,
    containsNuts: fragment.containsNuts ?? null,
    isPopular: fragment.isPopular ?? null,
    glutenFree: fragment.glutenFree ?? null,
    dairyFree: fragment.dairyFree ?? null,
    compostable: fragment.packaging === Packaging.Compostable,
    servingSizeCustomizationIds: !isNil(fragment.servingSizeCustomizations)
      ? map(fragment.servingSizeCustomizations, 'id')
      : [],
    spiceLevelCustomizationIds: !isNil(fragment.spiceLevelCustomizations)
      ? map(fragment.spiceLevelCustomizations, 'id')
      : [],
    location,
    ...(!isUndefined(idx) &&
      segmentMetrics && {
        esid: segmentMetrics.esid,
        esp: segmentMetrics.pos,
        esti: segmentMetrics.totalItems,
        esri: segmentMetrics.renderedItems,
        rank: idx,
      }),
  };
}
