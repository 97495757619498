import { ApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { COOKIE_DEVICE_ID, COOKIE_SESSION_KEY } from 'common/cookies/Cookies';
import { deserializeSessionCookie, getDeviceIdCookieProperties } from 'common/cookies/CookieUtils';
import { ClientEvents } from 'common/events/ClientEvents';
import { ISessionManager } from 'common/sessions';
import { ISession } from 'common/sessions/ISession';
import Cookies from 'js-cookie';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { v4 as uuid } from 'uuid';
import { NativeAppMessenger } from '../mobile-app/NativeAppMessenger';
import { getDomain } from '../shared/utils/RouteUtilities';
import { MockTracker } from './trackers/mock-tracker';
import { createAdTracker } from './trackers/third-party/ad-tracking';
import { ThirdPartyTracker } from './trackers/third-party/tracker';
import { ITracker } from './types';

// Intentionally not put in STORAGE_KEYS as I dont want anyone to accidentally reference / use this
const oldBrowserTrackerLocalStorageKey = 'browser_tracker';

const trackersEnabled = Number(process.env.REACT_APP_TRACKERS_ENABLED) !== 0;

/**
 * Get the session object from local cookies.
 */
export function getSession(): ISession | null {
  let serverSession: ISession | null = null;
  const sessionCookie = Cookies.get(COOKIE_SESSION_KEY);
  if (!sessionCookie) {
    console.error(`missing session cookie`);
  } else {
    serverSession = deserializeSessionCookie(sessionCookie);
  }

  // TODO: Deprecate once most clients are on 3.1.142+. window.App no longer has session passed in. We instead
  // send it as a header on our first request and then set it into cookies
  const finalSessionInfo: ISession | null = window.App?.session ?? serverSession;
  if (!finalSessionInfo) {
    console.error(`No app session AND no server session, this should be impossible`);
  }
  return finalSessionInfo;
}

export function initBrowserTrackerId(): {
  deviceId: string;
  serverDeviceId: string | undefined;
  localDeviceId: string | undefined;
} {
  const storage = getBrowserStorage();
  const storedBrowserTrackerId = storage.getItem(oldBrowserTrackerLocalStorageKey);
  let browserTrackerIdSetByClient: string | null = storedBrowserTrackerId;

  // Get the server set cookie
  const serverDeviceId = Cookies.get(COOKIE_DEVICE_ID);
  if (!serverDeviceId) {
    console.error(`missing server deviceId cookie`);
  }

  // TODO: Deprecate once most clients are on 3.1.140+. window.App no longer has deviceId passed in. We instead
  // send it as a header on our first request and then set it into cookies
  if (!browserTrackerIdSetByClient && window.App?.deviceId) {
    browserTrackerIdSetByClient = window.App.deviceId;
  }

  let finalBrowserTrackerId = browserTrackerIdSetByClient ?? serverDeviceId;
  if (!finalBrowserTrackerId) {
    console.error(`No local browserTrackerId AND no server deviceId, this should be impossible`);
    finalBrowserTrackerId = uuid();
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return { deviceId: finalBrowserTrackerId!, serverDeviceId, localDeviceId: browserTrackerIdSetByClient ?? undefined };
}

export function tryReconciliateBrowserTrackerIds(
  serverDeviceId: string | undefined,
  localStorageDeviceId: string | undefined,
  tracker: ITracker
) {
  // Should never happen but error checking is already handled for this
  if (!serverDeviceId) {
    return;
  }

  if (localStorageDeviceId && localStorageDeviceId !== serverDeviceId) {
    // Update cookies

    const domain = getDomain();
    const props = getDeviceIdCookieProperties(domain);
    Cookies.set(COOKIE_DEVICE_ID, localStorageDeviceId, { ...props });

    // Fire reconciliation event
    tracker.track(ClientEvents.BROWSER_TRACKER_RECONCILIATION, {
      serverDeviceId,
      clientDeviceId: localStorageDeviceId,
    });
  }

  // clear out old client based browser trackers
  if (localStorageDeviceId) {
    const storage = getBrowserStorage();
    storage.removeItem(oldBrowserTrackerLocalStorageKey);
  }
}

function createTrackerSafe<T>({
  browserTrackerId,
  isBot,
  gqlClient,
  nativeAppMessenger,
  sessionManager,
}: {
  browserTrackerId: string;
  isBot: boolean;
  gqlClient: ApolloClient<T>;
  nativeAppMessenger: NativeAppMessenger | null;
  sessionManager: ISessionManager | undefined;
}): ITracker {
  try {
    if (!isBot && trackersEnabled) {
      const adTracker = createAdTracker({ apolloClient: gqlClient, browserTrackerId, nativeAppMessenger });
      return new ThirdPartyTracker<T>(browserTrackerId, gqlClient, adTracker, sessionManager);
    }
    return new MockTracker();
  } catch (e) {
    try {
      Sentry.captureException(e);
    } catch (f) {
      console.error('All tracking and logging has errored', e, f);
    }
    return new MockTracker();
  }
}

export function createTracker<T>(args: {
  browserTrackerId: string;
  isBot: boolean;
  gqlClient: ApolloClient<T>;
  nativeAppMessenger: NativeAppMessenger | null;
  sessionManager: ISessionManager | undefined;
}): ITracker {
  const tracker = createTrackerSafe(args);
  tracker.init();
  return tracker;
}
