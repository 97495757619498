// organize-imports-ignore
import React from 'react';
import { BaseDietaryIcon, SVGProps } from './BaseDietaryIcon';
import { DAIRY, EGGS, GLUTEN, NUTS, SHELLFISH, SOY } from './iconPaths';

export const AllergenDairy = (props: SVGProps) => <BaseDietaryIcon {...props} {...DAIRY} />;

export const AllergenEggs = (props: SVGProps) => <BaseDietaryIcon {...props} {...EGGS} />;

export const AllergenGluten = (props: SVGProps) => <BaseDietaryIcon {...props} {...GLUTEN} />;

export const AllergenNuts = (props: SVGProps) => <BaseDietaryIcon {...props} {...NUTS} />;

export const AllergenSoy = (props: SVGProps) => <BaseDietaryIcon {...props} {...SOY} />;

export const AllergenShellfish = (props: SVGProps) => <BaseDietaryIcon {...props} {...SHELLFISH} />;
