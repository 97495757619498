import { LoginModalDismissElements } from 'common/events/ClientEvents';
import {
  DEFAULT_NEW_USER_CODE,
  NEW_USER_CODE_FOR_HUBLESS_REGIONS,
  NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY,
  NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY,
} from 'common/PromoCodeUtilities';
import { StorageKeys } from 'common/storage/constants';
import React, { useContext, useReducer } from 'react';
import { useHistory } from 'react-router';
import referralPromoBanner from 'src/assets/svg/referral-promo-banner.svg';
import signUpPromo10 from 'src/assets/svg/sign-up-promo-10.svg';
import signUpPromo15 from 'src/assets/svg/sign-up-promo-15.svg';
import signUpPromo30 from 'src/assets/svg/sign-up-promo-30.svg';
import signUpPromo50 from 'src/assets/svg/sign-up-promo-50.svg';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';

import { CELEBRITY_EVENT_PROMO_BANNER } from 'src/celebrity-event/Content';
import { CelebrityEventContext } from 'src/celebrity-event/context/CelebrityEventProvider';
import { OAuthLoginType, OAuthMissingFieldsResult, PromoCodeType } from 'src/gqlReactTypings.generated.d';
import { ShefLogo } from 'src/pages/consumer/components/header-v2/shared-components/ShefLogo';
import { usePromotionalsHook } from 'src/promotionals/usePromotionalsHook';
import { Header2 } from 'src/shared/design-system/Headers';
import { ArrowSoftLeftV2, Close } from 'src/shared/design-system/Icon';
import { Routes } from 'src/shared/Routes';
import { useLocalStorage } from 'src/shared/storage/Storage';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Colors, Font, Spacing, ZIndex } from 'web-common/src/shared/styles';
import { AuthSecondaryButton } from './AuthSecondaryButton';
import { LogInOrSignUpModalEntryForm } from './LoginOrSignUpModalEntryForm';
import { LoginWithEmail } from './LoginWithEmail';
import { MealPlanPromoBanner } from './MealPlanPromoBanner';
import { OAuthMissingFieldsForm } from './OAuthMissingFieldsForm';
import { SignUpWithEmail } from './SignUpWithEmail';
import { useShowLoginOrSignUpModal } from './useShowLoginOrSignUpModal';

const HeaderContainer = styled.div<{ stickyHeader?: boolean }>`
  position: ${ifProp('stickyHeader', 'sticky')};
  top: 0;
  z-index: ${ZIndex.ModalContent + 1};
  background: ${Colors.WHITE};
`;

const FullScreenHeader = styled(Header2)`
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.XL};
  line-height: ${Font.LineHeight.L};
  margin-top: ${Spacing.SINGLE_HALF};
`;

const ModalHeader = styled.span`
  text-align: center;
  font-family: ${Font.Family.SANS};
  font-weight: ${Font.Weight.BOLD};
  font-size: ${Font.Size.S};
`;

enum FormType {
  ENTRY_POINT,
  REGISTER_WITH_EMAIL,
  LOGIN_WITH_EMAIL,
  OAUTH_MISSING_FIELDS,
}

type OAuthMissingFieldsState = {
  formType: FormType.OAUTH_MISSING_FIELDS;
  oAuthType: OAuthLoginType;
  token: string;
  missingFields: OAuthMissingFieldsResult;
};

type EmailFormState = {
  formType: FormType.ENTRY_POINT | FormType.LOGIN_WITH_EMAIL | FormType.REGISTER_WITH_EMAIL;
  email: string;
  errorMessage?: string;
};

type LoginOrSignUpModalState = OAuthMissingFieldsState | EmailFormState;

interface LoginOrSignUpModalProps {
  showClose?: boolean;
  onCloseButtonClicked?: (LoginModalDismissElements) => void;
  fullScreenView?: boolean;
  stickyHeader?: boolean;
  reloadOnLogin?: boolean;
}

enum ModalActionTypes {
  SET_EMAIL,
  CONTINUE_WITH_EMAIL,
  SWITCH_TO_MISSING_FIELDS_STATE,
  SWITCH_TO_ENTRY,
}

type ModalAction =
  | {
      type: ModalActionTypes.SET_EMAIL;
      email: string;
    }
  | {
      type: ModalActionTypes.CONTINUE_WITH_EMAIL;
      email: string;
      errorMessage?: string;
      isExistingUser: boolean;
    }
  | {
      type: ModalActionTypes.SWITCH_TO_MISSING_FIELDS_STATE;
      oAuthType: OAuthLoginType;
      token: string;
      missingFields: OAuthMissingFieldsResult;
    }
  | {
      type: ModalActionTypes.SWITCH_TO_ENTRY;
    };

const isEmailState = (state: LoginOrSignUpModalState): state is EmailFormState =>
  [FormType.ENTRY_POINT, FormType.LOGIN_WITH_EMAIL, FormType.REGISTER_WITH_EMAIL].includes(state.formType);

const stateReducer = (state: LoginOrSignUpModalState, action: ModalAction): LoginOrSignUpModalState => {
  switch (action.type) {
    case ModalActionTypes.SET_EMAIL: {
      if (isEmailState(state)) {
        return { ...state, email: action.email };
      }
      return state;
    }
    case ModalActionTypes.CONTINUE_WITH_EMAIL: {
      const formType = action.isExistingUser ? FormType.LOGIN_WITH_EMAIL : FormType.REGISTER_WITH_EMAIL;
      return { formType, email: action.email, errorMessage: action.errorMessage };
    }
    case ModalActionTypes.SWITCH_TO_MISSING_FIELDS_STATE: {
      const { oAuthType, token, missingFields } = action;
      return { formType: FormType.OAUTH_MISSING_FIELDS, oAuthType, token, missingFields };
    }
    case ModalActionTypes.SWITCH_TO_ENTRY: {
      const email = isEmailState(state) ? state.email : '';
      return { formType: FormType.ENTRY_POINT, email };
    }
    default:
      return state;
  }
};

export const LoginOrSignUpModalContent: React.FC<LoginOrSignUpModalProps> = ({
  showClose,
  onCloseButtonClicked,
  fullScreenView,
  stickyHeader,
}) => {
  const [state, dispatch] = useReducer(stateReducer, { formType: FormType.ENTRY_POINT, email: '' });

  const [, setStorageKey] = useLocalStorage(StorageKeys.LOGIN_ON_EXPLORE_MODAL, false);
  const {
    signUpPromoCode,
    entryHeader,
    requireZipCode,
    signUpEntryPoint,
    preventReloadOnLogin,
    onSuccessfulLogin,
    signUpCTA,
    continueCTA,
    loginCTA,
    hideBanner,
    hideGuestOption,
  } = useShowLoginOrSignUpModal();
  const { setCode } = usePromotionalsHook();
  const history = useHistory();

  const celebrityEventContext = useContext(CelebrityEventContext);

  const onMealPlanPage = locationInRoutes(history.location, [
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
    Routes.CONSUMER_MEAL_PLANS,
  ]);

  const promoCodeImageClasses = 'bg-mint-100 rounded-lg';
  const promoCodeImage = match({
    signUpPromoCode,
    celebrityEventType: celebrityEventContext.celebrityEventType,
    onMealPlanPage,
  })
    .with(
      { celebrityEventType: P.not(P.nullish) },
      ({ celebrityEventType }) => CELEBRITY_EVENT_PROMO_BANNER[celebrityEventType]
    )
    .with({ signUpPromoCode: { promoCodeType: PromoCodeType.Referral } }, () => (
      <img
        className={promoCodeImageClasses}
        src={referralPromoBanner}
        alt='Sign up to receive your referral reward, Claim your $40 welcome offer'
      />
    ))
    .with({ signUpPromoCode: { code: NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY } }, () => (
      <img className={promoCodeImageClasses} src={signUpPromo30} alt='$30 off your first five orders' />
    ))
    .with({ signUpPromoCode: { code: NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY } }, () => (
      <img className={promoCodeImageClasses} src={signUpPromo50} alt='$50 off your first five orders' />
    ))
    .with(
      {
        onMealPlanPage: true,
      },
      () => <MealPlanPromoBanner />
    )
    .with({ signUpPromoCode: { code: NEW_USER_CODE_FOR_HUBLESS_REGIONS } }, () => (
      <img className={promoCodeImageClasses} src={signUpPromo15} alt='Sign up to receive $15 off your first order' />
    ))
    .with({ signUpPromoCode: { code: DEFAULT_NEW_USER_CODE } }, () => (
      <img className={promoCodeImageClasses} src={signUpPromo10} alt='Sign up to receive $10 off your first order' />
    ))
    .otherwise(() => null);

  const onEmailContinueSuccess = (emailToContinueWith: string, isRegisteredUser: boolean) => {
    dispatch({
      type: ModalActionTypes.CONTINUE_WITH_EMAIL,
      email: emailToContinueWith,
      isExistingUser: isRegisteredUser,
    });
  };

  const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    dispatch({ type: ModalActionTypes.SET_EMAIL, email: event.currentTarget.value });
  };

  const goBackToEntry = () => dispatch({ type: ModalActionTypes.SWITCH_TO_ENTRY });

  const onOAuthMissingFields = (type: OAuthLoginType, token: string, oAuthMissingFields: OAuthMissingFieldsResult) => {
    dispatch({
      type: ModalActionTypes.SWITCH_TO_MISSING_FIELDS_STATE,
      oAuthType: type,
      token,
      missingFields: oAuthMissingFields,
    });
  };

  // Make sure the customers don't see the login modal on explore page experiment again.
  const onSuccessfulLoginOrSignUp = (isExistingUser: boolean) => {
    setStorageKey(true);

    if (!isExistingUser && signUpPromoCode) setCode(signUpPromoCode);

    if (!preventReloadOnLogin) {
      if (!requireZipCode && !celebrityEventContext.celebrityEventType) window.location.reload();
    } else {
      onSuccessfulLogin?.();
    }
  };

  const {
    header,
    formComponent,
    promoBanner,
    closeOrBackButton,
  }: {
    header: string;
    formComponent: React.ReactNode;
    promoBanner: React.ReactNode;
    closeOrBackButton: React.ReactNode;
  } = match(state)
    .with({ formType: FormType.REGISTER_WITH_EMAIL }, (registerState) => ({
      header: 'Finish signing up',
      formComponent: (
        <SignUpWithEmail
          onEmailChange={onEmailChange}
          email={registerState.email}
          onSuccess={onSuccessfulLoginOrSignUp}
          requireZipCode={requireZipCode}
          onUserAlreadyExists={(email, errorMessage) =>
            dispatch({ type: ModalActionTypes.CONTINUE_WITH_EMAIL, email, errorMessage, isExistingUser: true })
          }
          signUpPromoCode={signUpPromoCode?.code}
          signUpEntryPoint={signUpEntryPoint}
          signupCTA={signUpCTA}
        />
      ),
      promoBanner: promoCodeImage,
      closeOrBackButton: (
        <ArrowSoftLeftV2
          className={`color-neutral-800 w-4 ${fullScreenView ? '' : 'absolute'}`}
          cursor='pointer'
          onClick={() => dispatch({ type: ModalActionTypes.SWITCH_TO_ENTRY })}
        />
      ),
    }))
    .with({ formType: FormType.LOGIN_WITH_EMAIL }, (loginState) => ({
      header: 'Log in',
      formComponent: (
        <LoginWithEmail
          onEmailChange={onEmailChange}
          email={loginState.email}
          onSuccess={onSuccessfulLoginOrSignUp}
          goBack={() => dispatch({ type: ModalActionTypes.SWITCH_TO_ENTRY })}
          errorMessage={loginState.errorMessage}
          loginCTA={loginCTA}
        />
      ),
      promoBanner: null,
      closeOrBackButton: (
        <ArrowSoftLeftV2
          className={`color-neutral-800 w-4 ${fullScreenView ? '' : 'absolute'}`}
          cursor='pointer'
          onClick={goBackToEntry}
        />
      ),
    }))
    .with({ formType: FormType.OAUTH_MISSING_FIELDS }, (oAuthMissingFieldsState) => ({
      header: 'Finish signing up',
      formComponent: (
        <OAuthMissingFieldsForm
          type={oAuthMissingFieldsState.oAuthType}
          missingFields={oAuthMissingFieldsState.missingFields}
          token={oAuthMissingFieldsState.token}
          onSuccess={onSuccessfulLoginOrSignUp}
          requireZipCode={requireZipCode}
          signUpEntryPoint={signUpEntryPoint}
        />
      ),
      closeOrBackButton: (
        <ArrowSoftLeftV2
          className={`color-neutral-800 w-4 ${fullScreenView ? '' : 'absolute'}`}
          cursor='pointer'
          onClick={goBackToEntry}
        />
      ),
      promoBanner: promoCodeImage,
    }))
    .with({ formType: FormType.ENTRY_POINT }, (entryState) => ({
      header: entryHeader ?? 'Log in or sign up',
      formComponent: (
        <LogInOrSignUpModalEntryForm
          email={entryState.email}
          onEmailChange={onEmailChange}
          onEmailContinueSuccess={onEmailContinueSuccess}
          onOAuthMissingFields={onOAuthMissingFields}
          onOAuthSuccess={onSuccessfulLoginOrSignUp}
          requireZipCode={requireZipCode}
          signUpEntryPoint={signUpEntryPoint}
          formCloseButton={
            showClose && !hideGuestOption && onCloseButtonClicked ? (
              <AuthSecondaryButton
                onClick={() => onCloseButtonClicked(LoginModalDismissElements.BROWSE_AS_GUEST)}
                label={'Browse as Guest'}
              />
            ) : null
          }
          continueCTA={continueCTA}
        />
      ),
      promoBanner: promoCodeImage,
      closeOrBackButton:
        showClose && onCloseButtonClicked ? (
          <Close
            className={`color-neutral-800 size-3 ${fullScreenView ? '' : 'absolute'}`}
            cursor='pointer'
            onClick={() => onCloseButtonClicked(LoginModalDismissElements.CLOSE_BUTTON)}
            data-cy='login-modal-close-btn'
          />
        ) : null,
    }))
    .otherwise(() => {
      // Should not happen
      throw new Error('Missing login modal form state');
    });

  if (fullScreenView) {
    return (
      <>
        <div className='mx-6 mt-6'>
          {closeOrBackButton ?? <ShefLogo color={Colors.RADISH} />}
          <FullScreenHeader>{header}</FullScreenHeader>
        </div>
        {formComponent}
      </>
    );
  }

  return (
    <>
      <HeaderContainer stickyHeader={stickyHeader}>
        <div className='flex w-full flex-col content-center justify-center p-2'>
          {closeOrBackButton}
          <ModalHeader>{header}</ModalHeader>
        </div>
        <div className='h-px w-full bg-neutral-100' />
      </HeaderContainer>
      <div className='flex w-full flex-col gap-4 p-6'>
        {!hideBanner && promoBanner ? <div className='flex w-full justify-center'>{promoBanner}</div> : null}
        {formComponent}
      </div>
    </>
  );
};
