import { noOp } from 'common/Constants';
import { ClientEvents } from 'common/events/ClientEvents';
import { selectMultiShefCartSubtotal } from 'src/store/cart/selectors';
import { match, P } from 'ts-pattern';
import type { CreateCartEffect } from './listeners';

export const trackCheckoutEvent: CreateCartEffect =
  ({ tracker, checkoutSlice }) =>
  (action, listenerApi) => {
    if (!tracker) return;

    const { startCheckout } = checkoutSlice.actions;

    const { cart, checkout } = listenerApi.getState();
    const numberOfOrders = checkout.checkoutShefIds.length;

    match({ action, isMultiCart: cart.isMultiCart })
      .with({ isMultiCart: false, action: P.when(startCheckout.match) }, () =>
        tracker.track(ClientEvents.SINGLE_CART_STARTED_CHECKOUT, { numberOfOrders })
      )
      .with({ isMultiCart: true, action: P.when(startCheckout.match) }, () => {
        const totalCartSubtotal = selectMultiShefCartSubtotal(cart, checkout.checkoutShefIds);
        tracker.track(ClientEvents.MULTI_CART_STARTED_CHECKOUT, { numberOfOrders, totalCartSubtotal });
      })
      .otherwise(noOp);
  };
