// eslint-disable-next-line no-restricted-imports
import { useHistory as useReactRouterHistory } from 'react-router';

export interface ShefHistoryLocationState {
  isFoodSearchPageInHistory?: boolean;
  isShefPageInHistory?: boolean;

  /**
   * Flag controlling whether or not we scroll to the top of the page on history PUSH or REPLACE.
   * @default true
   */
  scrollToTop?: boolean;
  /**
   * Flag controlling is the side cart should be closed automatically when redirected
   * @default true
   */
  autoCloseCart?: boolean;
  /**
   * When we pushed on to the history stack, did that action originate from web or from the app?
   */
  intentSource?: 'app' | 'web';

  from?: string;
}

/**
 * Hook that wraps react-router's useHistory with ShefHistoryLocationState
 * to improve typing throughout our app when using history location state.
 *
 * Prefer to use this hook over react-router's useHistory;
 *
 */
export const useHistory = () => useReactRouterHistory<ShefHistoryLocationState>();
