// organize-imports-ignore
import React from 'react';
import { BaseDietaryIcon, SVGProps } from './BaseDietaryIcon';
import {
  DAIRY,
  HALAL,
  KETO,
  KOSHER,
  LOW_FODMAP,
  LOW_SODIUM,
  ORGANIC,
  PALEO,
  SUGAR,
  VEGAN,
  VEGETARIAN,
  WHOLE30,
} from './iconPaths';

export const DietaryVegan = (props: SVGProps) => <BaseDietaryIcon {...props} {...VEGAN} />;

export const DietaryVegetarian = (props: SVGProps) => <BaseDietaryIcon {...props} {...VEGETARIAN} />;

export const DietaryPaleo = (props: SVGProps) => <BaseDietaryIcon {...props} {...PALEO} />;

export const DietaryKeto = (props: SVGProps) => <BaseDietaryIcon {...props} {...KETO} />;

export const DietaryWhole30 = (props: SVGProps) => <BaseDietaryIcon {...props} {...WHOLE30} />;

export const DietaryLowFODMAP = (props: SVGProps) => <BaseDietaryIcon {...props} {...LOW_FODMAP} />;

export const DietaryHalal = (props: SVGProps) => <BaseDietaryIcon {...props} {...HALAL} />;

export const DietaryKosher = (props: SVGProps) => <BaseDietaryIcon {...props} {...KOSHER} />;

export const DietaryOrganic = (props: SVGProps) => <BaseDietaryIcon {...props} {...ORGANIC} />;

export const DietaryLowSodium = (props: SVGProps) => (
  <BaseDietaryIcon {...props} {...LOW_SODIUM} isCrossed={!props.isCrossed} />
);

export const DietarySugarFree = (props: SVGProps) => (
  <BaseDietaryIcon {...props} {...SUGAR} isCrossed={!props.isCrossed} />
);

export const DietaryDairyFree = (props: SVGProps) => (
  <BaseDietaryIcon {...props} {...DAIRY} isCrossed={!props.isCrossed} />
);
