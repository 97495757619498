import { ReferralShareLocation } from 'common/events/ClientEvents';
import React from 'react';
import ShareIcon from 'src/assets/svg/share-icon.svg';
import { CopyLight } from 'src/shared/design-system/Icon';
import { useShare } from 'src/shared/hooks/useShare';
import Notifications from 'src/shared/Notifications';
import styled from 'styled-components';
import { Colors } from 'web-common/src/shared/Constants';
import { Font, Spacing } from 'web-common/src/shared/styles';
import { useTrackShare } from './useTrackShare';

const LinkRow = styled.div<{ maxWidth?: string }>`
  display: flex;
  flex-direction: row;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '500px')};
  width: 100%;
`;

const LINK_BOX_HEIGHT = '50px';

const LinkBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: var(--referral-box-height, ${LINK_BOX_HEIGHT});
  background-color: ${Colors.GRAY_LITE_20};
  padding: 0 ${Spacing.SINGLE};
  cursor: pointer;
  flex-grow: 10;
  border-radius: var(--referral-border-radius);
`;

const LinkText = styled.span`
  font-family: ${Font.Family.SANS};
  font-size: var(--referral-font-size);
  line-height: var(--referral-line-height);
  font-weight: var(--referral-font-weight);
  // height: ${LINK_BOX_HEIGHT};
  //line-height: ${LINK_BOX_HEIGHT};
  color: black;
`;

const NativeShareIcon = styled.img.attrs(() => ({ src: ShareIcon }))`
  height: 20px;
  width: 20px;
`;

const CopyIcon = styled(CopyLight)`
  height: 20px;
  width: 20px;
  color: ${Colors.GRAY_DARK_30};
`;

const LinkIcon = () => (navigator.share ? <NativeShareIcon /> : <CopyIcon />);
interface ReferralLinkProps {
  url: string;
  code: string;
  location: ReferralShareLocation;
  className?: string;
  maxWidth?: string;
}

export const ReferralLink: React.FC<ReferralLinkProps> = ({ url, code, location, className, maxWidth }) => {
  const trackShare = useTrackShare({ code, location });

  const { share } = useShare({
    url,
    onShare: (type) => {
      trackShare(type);
      if (type === 'copy') {
        Notifications.success('Copied link!');
      }
    },
    onError: () => {
      Notifications.error('Failed to copy code');
    },
  });

  return (
    <LinkRow onClick={share} className={className} maxWidth={maxWidth}>
      <LinkBox>
        <LinkText>{url}</LinkText>
        <LinkIcon />
      </LinkBox>
    </LinkRow>
  );
};
