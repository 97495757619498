import { share as nativeAppShare } from '@shef/native-bridge';
import { ShareType } from 'common/events/ClientEvents';
import { useCallback, useState } from 'react';
import { useNativeAppContext } from 'src/mobile-app/hooks/useNativeAppContext';
import { isNativeMobileApp } from 'src/mobile-app/isNativeMobileApp';
import { useClipboard } from 'use-clipboard-copy';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';

interface UseShareParams {
  shareMessage?: string;
  url: string;
  onShare: (type: ShareType) => void;
  onError: () => void;
}

export const useShare = ({
  shareMessage = 'Want fresh, authentic, home-style food? Check out Shef!',
  url,
  onShare,
  onError,
}: UseShareParams) => {
  const { isSmallScreen } = useScreenContext();
  const [, , nativeActionCreators] = useNativeAppContext();
  const [copied, setCopied] = useState(false);

  const clipboard = useClipboard({
    copiedTimeout: 1500,
    onSuccess: () => {
      onShare(ShareType.COPY);
      setCopied(true);
    },
    onError,
  });

  const handleShareNative = useCallback(
    (args) => {
      nativeActionCreators.dispatchSendRequest(nativeAppShare, args);
    },
    [nativeActionCreators]
  );

  const share = useCallback(async () => {
    if (isNativeMobileApp()) {
      handleShareNative({ text: shareMessage, url });
      onShare(ShareType.NATIVE);
    } else if (isSmallScreen && navigator.share) {
      await navigator.share({ text: shareMessage, url });
      onShare(ShareType.NATIVE);
    } else {
      clipboard.copy(url);
    }
  }, [isSmallScreen, onShare, onError, shareMessage, handleShareNative, url]);

  return { share, copied };
};
