import { ApolloError } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql';

const DEFAULT_ERROR_MESSAGE = 'There was an error processing your request.';

export const formatGqlErrors = (errors: readonly GraphQLFormattedError[]) => {
  if (!errors.length) {
    return '';
  }
  return errors[0].message;
};

export const formatGqlError = (error: ApolloError): string => {
  if (error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors.length) {
    const eMsg = error.toString().replace('Error: GraphQL error: ', '');
    if (!eMsg) {
      // Can happen with NotAuthenticatedErrors, and maybe others...
      return DEFAULT_ERROR_MESSAGE;
    }
    return eMsg;
  }

  const { networkError } = error;
  const newVar = (networkError as any)?.result?.errors?.[0]?.message;
  if (newVar) return newVar;

  return error.networkError?.message || error.message;
};
