import { first, map } from 'lodash';
import { DateTime } from 'luxon';
import { useRouteMatch } from 'react-router';
import { useDishFirstExploreUrl } from 'src/pages/consumer/explore/useExploreUrl';
import { SpiceLevelMap } from 'src/shared/Enums';

export const useSearchFilters = () => {
  const match = useRouteMatch();
  const filters = useDishFirstExploreUrl({ match });

  const {
    activeZipCode,
    dateFilters,
    cuisineFilters,
    dietaryFilters,
    dishTypeFilters,
    proteinFilters,
    spiceLevelFilters,
    ecoFilter,
    minRatingFilter,
    priceLevelFilters,
    updateQueryParams,
  } = filters;

  const deliveryDate = first(dateFilters) ?? DateTime.local().toFormat('yyyy-MM-dd');
  const spiceLevels = map(spiceLevelFilters, (spiceLevel) => SpiceLevelMap[spiceLevel]);

  return {
    activeZipCode,
    deliveryDate,
    emptySearchFilters: {
      availabilityDates: [],
      cuisineFilters: [],
      dietaryFilters: [],
      dishTypeFilters: [],
      proteinFilters: [],
      spiceLevelFilters: [],
      priceLevelFilters: [],
      isEcoFriendlyPackaging: false,
      minDishRating: 80,
    },
    filters,
    searchFilters: {
      availabilityDates: dateFilters,
      cuisineFilters,
      dietaryFilters,
      dishTypeFilters,
      proteinFilters,
      spiceLevelFilters: spiceLevels,
      priceLevelFilters,
      isEcoFriendlyPackaging: ecoFilter,
      minDishRating: minRatingFilter,
    },
    updateQueryParams,
    zipCode: activeZipCode,
  };
};
