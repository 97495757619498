import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StorageKeys } from 'common/storage/constants';
import Notifications from 'src/shared/Notifications';
import { getBrowserStorage } from 'src/shared/storage/Storage';

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '';

const storage = getBrowserStorage();

const stripePromise = loadStripe(stripeKey);
stripePromise
  .then((stripe) => {
    storage.setItem(StorageKeys.STRIPE_RELOAD_COUNT, '0');
    return stripe;
  })
  .catch((err) => {
    console.error(err);
    const attempts = Number(storage.getItem(StorageKeys.STRIPE_RELOAD_COUNT));
    // If this isn't a number treat it as 0
    if (isNaN(attempts)) {
      storage.setItem(StorageKeys.STRIPE_RELOAD_COUNT, '1');
      window.location.reload();
    } else if (attempts < 3) {
      storage.setItem(StorageKeys.STRIPE_RELOAD_COUNT, String(attempts + 1));
      window.location.reload();
    } else {
      Notifications.error(
        `There was an issue loading the site. Some things might not work properly if you don't refresh!`
      );
    }
  });

export const StripeProvider = ({ children }) => <Elements stripe={stripePromise}>{children}</Elements>;
