import { useMemo } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'src/shared/hooks/useLocation';
import { Routes } from 'src/shared/Routes';
import { ChatRole } from '../ChatContext';

export const useChatRole = (): ChatRole => {
  const location = useLocation();

  const role = useMemo(
    () => (matchPath(location.pathname, { path: Routes.SHEF, exact: false }) ? ChatRole.SHEF : ChatRole.CONSUMER),
    [location]
  );

  return role;
};
