import React, { useCallback, useState } from 'react';
import { Modal } from 'src/shared/design-system/Modal';
import { OrderAheadContent } from './OrderAheadContent';

interface IProps {
  isShowing: boolean;
  onClose: () => any;
  setZip: (zip: string) => void;
}

export const ZipModal: React.FC<IProps> = ({ isShowing, onClose, setZip }) => {
  const [value, setValue] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  const updateValue = useCallback((value: string) => {
    const newValue = value.replace(/\D/, '').substring(0, 5);
    if (newValue.length === 5) {
      setCanSubmit(true);
    }
    setValue(newValue);
  }, []);

  const submit = useCallback(() => {
    if (canSubmit) {
      setZip(value);
      onClose();
    }
  }, [canSubmit, onClose, setZip, value]);

  const onInputMount = useCallback((element: HTMLInputElement | null) => {
    element?.focus();
  }, []);

  return (
    <Modal isShowing={isShowing} onClose={onClose} showCloseButton={true} cy='zip-modal'>
      <OrderAheadContent
        ref={onInputMount}
        submit={submit}
        canSubmit={canSubmit}
        onChange={updateValue}
        value={value}
      />
    </Modal>
  );
};
