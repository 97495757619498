import { ExploreState, OpenDishModalAction, SetDeliveryDateAction, SetFoodItemMetaAction } from './types';

export const closeDishModal = (state: ExploreState) => ({
  ...state,
  isDishModalShowing: false,
  foodItem: undefined,
  foodItemMeta: undefined,
  foodItemSlug: undefined,
  shefId: undefined,
});

export const hideAllCuisines = (state: ExploreState) => ({ ...state, isAllCuisinesShowing: false });

export const openDishModal = (state: ExploreState, { payload }: OpenDishModalAction) => ({
  ...state,
  foodItemMeta: { segmentMetrics: payload.segmentMetrics, idx: payload.idx },
  foodItemSlug: payload.foodItemSlug,
  shefId: payload.shefId,
});

export const setDeliveryDate = (state: ExploreState, { payload }: SetDeliveryDateAction) => ({
  ...state,
  deliveryDate: payload.deliveryDate,
});

export const setFoodItemMeta = (state: ExploreState, { payload }: SetFoodItemMetaAction) => ({
  ...state,
  foodItemMeta: payload,
});

export const showAllCuisines = (state: ExploreState) => ({ ...state, isAllCuisinesShowing: true });
