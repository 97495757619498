import styled from 'styled-components';
import { Mixins, Spacing } from 'web-common/src/shared/styles';

export const Particle = styled.div`
  position: absolute;
  ${Mixins.Shape.square(Spacing.SINGLE)}
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
`;
