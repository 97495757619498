import { Packaging } from 'src/gqlReactTypings.generated.d';
import { DietaryTags } from 'web-common/src/shared/Enums';
import { DisplayedFoodItemTags } from './IconUtils';

export const dietaryTagToFoodItemFilter: Record<DietaryTags, (item: DisplayedFoodItemTags) => boolean> = {
  [DietaryTags.VEGETARIAN]: (item) => (item.vegetarian && !item.vegan) ?? false,
  [DietaryTags.VEGAN]: (item) => item.vegan ?? false,
  [DietaryTags.GLUTEN_FREE]: (item) => item.glutenFree ?? false,
  [DietaryTags.DAIRY_FREE]: (item) => (item.dairyFree && !item.vegan) ?? false,
  [DietaryTags.HALAL]: (item) => item.isHalal ?? false,
  [DietaryTags.PALEO]: (item) => item.paleo ?? false,
  [DietaryTags.LOW_FODMAP]: (item) => item.lowFodmap ?? false,
  [DietaryTags.WHOLE30]: (item) => item.whole30 ?? false,
  [DietaryTags.ORGANIC]: (item) => item.isOrganic ?? false,
  [DietaryTags.COMPOSTABLE]: (item) => item.packaging === Packaging.Compostable,
  [DietaryTags.KOSHER]: (item) => item.kosher ?? false,
};

export const dietaryTagToMenuFilter: Record<DietaryTags, (item: DisplayedFoodItemTags) => boolean> = {
  [DietaryTags.VEGETARIAN]: (item) => (item.vegetarian || item.vegan) ?? false,
  [DietaryTags.VEGAN]: (item) => item.vegan ?? false,
  [DietaryTags.GLUTEN_FREE]: (item) => item.glutenFree ?? false,
  [DietaryTags.DAIRY_FREE]: (item) => (item.dairyFree || item.vegan) ?? false,
  [DietaryTags.PALEO]: (item) => item.paleo ?? false,
  [DietaryTags.LOW_FODMAP]: (item) => item.lowFodmap ?? false,
  [DietaryTags.WHOLE30]: (item) => item.whole30 ?? false,
  [DietaryTags.HALAL]: (item) => item.isHalal ?? false,
  [DietaryTags.ORGANIC]: (item) => item.isOrganic ?? false,
  [DietaryTags.COMPOSTABLE]: (item) => item.packaging === Packaging.Compostable,
  [DietaryTags.KOSHER]: (item) => item.kosher ?? false,
};

export const HIDDEN_DIETARY_TAGS = [DietaryTags.ORGANIC, DietaryTags.COMPOSTABLE];

export const AVAILABLE_DIETARY_OPTIONS = Object.values(DietaryTags).filter(
  (dietaryTag) => !HIDDEN_DIETARY_TAGS.includes(dietaryTag)
);

export const DIETARY_TAG_DISPLAY_ORDER = [
  DietaryTags.COMPOSTABLE,
  DietaryTags.VEGAN,
  DietaryTags.VEGETARIAN,
  DietaryTags.DAIRY_FREE,
  DietaryTags.GLUTEN_FREE,
  DietaryTags.PALEO,
  DietaryTags.WHOLE30,
  DietaryTags.LOW_FODMAP,
  DietaryTags.ORGANIC,
  DietaryTags.HALAL,
  DietaryTags.KOSHER,
];
