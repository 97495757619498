import { noOp } from 'common/Constants';
import { DateString } from 'common/UrlDate';
import { compact, intersection, isEqual, isNil, map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DishModalFoodItemFragment } from 'src/gqlReactTypings.generated.d';
import { useDispatch, useSelector } from 'src/store';
import { incLineItemQuantity } from 'src/store/cart/actions';
import { selectCart, selectLineItem } from 'src/store/cart/selectors';
import { CartActionLocation, CartFoodItem } from 'src/store/cart/types';
import { BaseDishModal, IBaseDishModalFormState } from './BaseDishModal';
import { DEFAULT_CUSTOMIZATION_ID } from './types';
import { useHandleAddToCartDateUpdates } from './utils';

interface IExploreDishModalProps {
  cartActionLocation: CartActionLocation;
  foodItem: CartFoodItem;
  loadedData?: DishModalFoodItemFragment;
  isShowing?: boolean;
  maxQuantity?: number | null;
  showAddButton?: boolean;
  quantityInCart: number;
  shefMenuUrl?: string;
  shareUrl?: string;
  deliveryDate: DateString;
  isEditingOrder?: boolean;
  showModalPrompt?: boolean;
  openModal?: () => void;
  onClose?: () => void;
  onAddToCart?: () => void;
}

export const ExploreDishModal: React.FC<IExploreDishModalProps> = (props) => {
  const {
    cartActionLocation,
    foodItem,
    isShowing = false,
    onClose: handleClose = noOp,
    quantityInCart,
    deliveryDate,
    onAddToCart,
    isEditingOrder = false,
  } = props;

  const cartItem = useSelector((state) => {
    const cart = selectCart(state, isEditingOrder);
    return selectLineItem(cart, foodItem.id);
  });
  const cartItemQuantity = cartItem?.quantity;
  const { handleDeliveryDateUpdates } = useHandleAddToCartDateUpdates(deliveryDate);

  const { spiceLevelCustomizations, servingSizeCustomizations } = foodItem;

  const [formState, setFormState] = useState<IBaseDishModalFormState>({
    servingSizeCustomizationId: undefined,
    spiceLevelCustomizationId: DEFAULT_CUSTOMIZATION_ID,
    quantity: 1,
  });

  const formInitialState: IBaseDishModalFormState = useMemo(() => {
    const customizationIds = cartItem?.customizationIds;
    const servingSizeCustomizationIds = !isNil(servingSizeCustomizations)
      ? map(servingSizeCustomizations, 'id')
      : undefined;
    const spiceLevelCustomizationIds = !isNil(spiceLevelCustomizations)
      ? map(spiceLevelCustomizations, 'id')
      : undefined;

    const existingServingSizeCustomizationId =
      !isNil(customizationIds) && !isNil(servingSizeCustomizations)
        ? intersection(customizationIds, servingSizeCustomizationIds)[0]
        : undefined;

    const existingSpiceLevelCustomizationId =
      !isNil(customizationIds) && !isNil(spiceLevelCustomizationIds)
        ? intersection(customizationIds, spiceLevelCustomizationIds)[0]
        : undefined;

    const isAlreadyInCart = !isNil(cartItemQuantity) && cartItemQuantity > 0;
    return {
      servingSizeCustomizationId: isAlreadyInCart
        ? existingServingSizeCustomizationId ?? DEFAULT_CUSTOMIZATION_ID
        : undefined,
      spiceLevelCustomizationId: existingSpiceLevelCustomizationId ?? DEFAULT_CUSTOMIZATION_ID,
      quantity: isAlreadyInCart ? cartItemQuantity : 1,
    };
  }, [cartItem?.customizationIds, cartItemQuantity, servingSizeCustomizations, spiceLevelCustomizations]);
  const isEditingCart = quantityInCart > 0;
  useEffect(() => {
    if (isShowing) {
      if (isEditingCart) {
        // If we're editing an item, reset to what is already in the cart upon opening the modal
        setFormState({
          servingSizeCustomizationId: formInitialState.servingSizeCustomizationId,
          spiceLevelCustomizationId: formInitialState.spiceLevelCustomizationId,
          quantity: isNil(cartItemQuantity) ? 1 : cartItemQuantity,
        });
      }
    }
    // Intentionally only adding isShowing to dependencies, since this is for resetting the modal
  }, [
    isShowing,
    cartItemQuantity,
    formInitialState.servingSizeCustomizationId,
    formInitialState.spiceLevelCustomizationId,
    isEditingCart,
  ]);

  const dispatch = useDispatch();

  const submit = useCallback(() => {
    handleDeliveryDateUpdates();

    dispatch(
      incLineItemQuantity(isEditingOrder, {
        foodItem: { ...foodItem, id: foodItem.id },
        customizationIds: compact(
          [formState.spiceLevelCustomizationId, formState.servingSizeCustomizationId].filter(
            (customizationId) => customizationId !== DEFAULT_CUSTOMIZATION_ID
          )
        ),
        location: cartActionLocation,
        deliveryDate,
        quantity: formState.quantity,
      })
    );
    onAddToCart?.();
    handleClose();
  }, [
    cartActionLocation,
    deliveryDate,
    dispatch,
    foodItem,
    formState.quantity,
    formState.servingSizeCustomizationId,
    formState.spiceLevelCustomizationId,
    handleClose,
    handleDeliveryDateUpdates,
    isEditingOrder,
    onAddToCart,
  ]);

  const minQuantity = isEditingCart ? 0 : 1;
  return (
    <BaseDishModal
      {...props}
      unitsInCart={quantityInCart}
      canSubmitCart={!isEqual(formState, formInitialState)}
      submit={submit}
      minQuantity={minQuantity}
      isEditingCart={isEditingCart}
      formState={formState}
      setFormState={setFormState}
    />
  );
};
