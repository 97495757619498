import React from 'react';

export const Delimiter = '%s';

/**
 * Helper component to replace `Delimiter`(s) in a string with elements.
 * e.g.
 * ```
 * const text = `Click ${Delimiter} to learn more or ${Delimeter} to sign up.
 * <TextReplace replaceText={[<a key='key-1' href='www.linktosite.com'>Here</a>, <button key='key-1' onClick={() => handleClick()}>fill out this form</button>]} text={text} />;
 *
 * Result:
 * Click <a key='key-1' href='www.linktosite.com'>Here</a> to learn more or <button key='key-1' onClick={() => handleClick()}>fill out this form</button>]} text={text} /> to sign up.
 *
 * Result (Text Version):
 * Click here to learn more or fill out this form to sign up.
 *
 * ```
 */
export type TextReplaceProps = {
  /** Additional style class. */
  className?: string;
  /** The string delimiter. In most cases, don't pass this in and import `Delimiter` to use as your delimeter. */
  delimiter?: string;
  /** HTML element to use. */
  elementType?: string | React.ComponentType<React.PropsWithChildren>;
  /** Function to format each chunk of the text. */
  formatTextChunk?: (textChunk: React.ReactNode, index: number) => React.ReactNode;
  /**
   * Text to replace all `delimiter` values with.
   * Pass in array to replace text in the order of the replacement texts.
   * If passing in an element or [element(s)], you MUST set a unique key for each element.
   */
  replaceText: React.ReactNode | Array<React.ReactNode>;
  /**
   * If passed in, wraps the replaced text with given HTML element type.
   * If passing in array, the index of the element maps to the index of the Delimeter string in the given `text`.
   */
  replaceTextElementType?:
    | string
    | React.ComponentType<React.PropsWithChildren>
    | Array<string | React.ComponentType<React.PropsWithChildren>>;
  /**
   * Formatted text containing at least 1 `replaceText` delimeter.
   * e.g. `I want the next word after this `${TextWithReplacement.replaceText}` to be replaced`;
   */
  text: string;
};

export const TextReplace: React.FC<React.PropsWithChildren<TextReplaceProps>> = (props) => {
  const {
    className,
    delimiter = Delimiter,
    elementType = 'span',
    formatTextChunk = (text) => text,
    replaceText = '',
    replaceTextElementType,
    text,
  } = props;
  let numOfTextReplaced = 0;

  const Container = elementType;
  const replaceMultiple = Array.isArray(replaceText);
  const replaceElementMultiple = Array.isArray(replaceTextElementType);
  const componentProps = { className };

  const textRegex = new RegExp(`(${delimiter})`, 'g');
  const textArray = text.split(textRegex);

  const contents = textArray.map((chunk, index) => {
    if (chunk === delimiter) {
      const replaceTextValue = replaceMultiple ? replaceText?.[numOfTextReplaced] : replaceText;

      if (replaceTextElementType) {
        const Wrapper = replaceElementMultiple ? replaceTextElementType[numOfTextReplaced] : replaceTextElementType;

        numOfTextReplaced += 1;
        return <Wrapper key={`${text}-${index}`}>{replaceTextValue}</Wrapper>;
      }

      numOfTextReplaced += 1;
      return formatTextChunk(replaceTextValue, index);
    }
    return formatTextChunk(chunk, index);
  });

  return <Container {...componentProps}>{contents}</Container>;
};
