import { noOp } from 'common/Constants';
import { assertDateString, DateString } from 'common/UrlDate';
import { first, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import { usePersistentDeliveryDates } from 'src/dish-first/usePersistentDeliveryDates';
import { DishFirstFoodItemFragment } from 'src/gqlReactTypings.generated.d';
import { ExploreDishModalWithZip } from 'src/pages/consumer/order/components/ExploreDishModalWithZip';
import { useSelector } from 'src/store';
import { selectLineItemQuantities } from 'src/store/cart/selectors';
import { useDishModalActions, useDishModalState } from '../../../store';
import { useFoodItemAvailability } from './hooks/useFoodItemAvailability';

export const DishFirstModalComponent: React.FC<{
  foodItem: DishFirstFoodItemFragment;
  deliveryDate?: DateString;
  isShowing: boolean;
  onClose: () => void;
  addToCartPostAction?: () => void;
  isEditingOrder: boolean;
}> = ({ foodItem, deliveryDate, isShowing, onClose, addToCartPostAction = noOp, isEditingOrder }) => {
  const lineItemQuantities = useSelector(({ cart }) => selectLineItemQuantities(cart));
  const lineItemQuantity = lineItemQuantities[foodItem.foodItemId] ?? 0;

  const { maxQuantity } = useFoodItemAvailability({ foodItem, deliveryDate });

  const foodItemWithId = { ...foodItem, id: foodItem.foodItemId };

  if (!foodItem.foodItemId) return null;
  return (
    <ExploreDishModalWithZip
      deliveryDate={deliveryDate}
      cartActionLocation='dish-first-modal'
      foodItem={foodItemWithId}
      quantity={lineItemQuantity}
      remainingQuantity={maxQuantity}
      shefId={foodItem.shef.id}
      isShowing={isShowing}
      onClose={onClose}
      onAddToCart={addToCartPostAction}
      isEditingOrder={isEditingOrder}
    />
  );
};

export const DishFirstModal = React.memo(
  ({
    deliveryDateOverride,
    addToCartPostAction = noOp,
  }: React.PropsWithChildren<{
    deliveryDateOverride?: DateString | null;
    addToCartPostAction?: () => void;
  }>) => {
    const [deliveryDates] = usePersistentDeliveryDates();
    const { closeDishModal } = useDishModalActions();
    const { foodItem, isDishModalShowing: isShowing } = useDishModalState();

    const selectedDate = first(deliveryDates);
    const itemAvailability = foodItem?.availability.find(({ numAvailable }) => (numAvailable ?? 0) > 0);
    const nextDeliveryDate = itemAvailability?.availabilityDate
      ? DateTime.fromISO(itemAvailability.availabilityDate).toISODate()
      : null;
    const formattedNextDeliveryDate = nextDeliveryDate ? assertDateString(nextDeliveryDate) : undefined;

    const isShefAvailable =
      (isEmpty(selectedDate) && itemAvailability) ||
      foodItem?.availability.find(
        (day) => DateTime.fromISO(day.availabilityDate).toISODate() === selectedDate && (day.numAvailable ?? 0) > 0
      );

    const deliveryDateToUse =
      deliveryDateOverride ?? isShefAvailable ? selectedDate ?? formattedNextDeliveryDate : formattedNextDeliveryDate;

    // Don't render modal unless foodItem is loaded in context and create new modal when new food item is used
    return foodItem?.foodItemId ? (
      <DishFirstModalComponent
        key={foodItem.foodItemId}
        foodItem={foodItem}
        deliveryDate={deliveryDateToUse}
        isShowing={isShowing}
        onClose={closeDishModal}
        addToCartPostAction={addToCartPostAction}
        isEditingOrder={false}
      />
    ) : null;
  }
);
