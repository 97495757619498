import { CSSProperties } from 'styled-components';
import { Colors } from 'web-common/src/shared/styles';

export enum WaveSize {
  SHORT = 'short',
  NORMAL = 'normal',
  LONG = 'long',
}

export enum WavePosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export type WavyEdgeProps = {
  className?: string;
  isStroke?: boolean;
  longerWaves?: boolean;
  patternId?: string;
  position?: WavePosition;
  style?: CSSProperties;
  waveHeight?: number;
  waveWidth?: number;
  waveSize?: WaveSize;
};

export type WavyDividerProps = {
  backgroundColor?: Colors;
  strokeColor?: Colors;
  className?: string;
  longerWaves?: boolean;
  patternId?: string;
  position?: WavePosition;
  style?: CSSProperties;
  waveHeight?: number;
  waveWidth?: number;
  waveSize?: WaveSize;
};
