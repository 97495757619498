import { createEntityAdapter } from '@reduxjs/toolkit';
import { CartAddOnItem, CartFoodItem, CartLineItem, CartShef, LoadedCartShef, ServerAddOnItem } from './types';

export const foodItemAdapter = createEntityAdapter<CartFoodItem>();
export const lineItemAdapter = createEntityAdapter<CartLineItem>();
export const addOnItemAdapter = createEntityAdapter<CartAddOnItem>();
export const availableAddOnItemsAdapter = createEntityAdapter<ServerAddOnItem>();
export const cartShefAdapter = createEntityAdapter<CartShef>();

export const getDefaultLineItem = (foodItem: Pick<CartFoodItem, 'userId' | 'id'>): CartLineItem => {
  const { userId: shefId, id } = foodItem;
  const now = new Date().toISOString();
  return { id, shefId, foodItemId: id, quantity: 0, createdAt: now, updatedAt: now };
};

// food items coming from various query results have lots of extra fields
// that are not significant to the cart, but extra fields will get saved
// and will cause components to re-render due to new object
export const formatCartFoodItem = (foodItem: CartFoodItem): CartFoodItem => {
  const result: CartFoodItem | { [key in any]: never } = {
    id: foodItem.id,
    userId: foodItem.userId,
    description: foodItem.description,
    foodType: foodItem.foodType,
    imageUrl: foodItem.imageUrl,
    ingredients: foodItem.ingredients,
    name: foodItem.name,
    portion: foodItem.portion,
    servingSize: foodItem.servingSize,
    price: foodItem.price,
    slug: foodItem.slug,
    spiceLevel: foodItem.spiceLevel,
    vegetarian: foodItem.vegetarian,
    vegan: foodItem.vegan,
    lowFodmap: foodItem.lowFodmap,
    lowSodium: foodItem.lowSodium,
    keto: foodItem.keto,
    kosher: foodItem.kosher,
    paleo: foodItem.paleo,
    whole30: foodItem.whole30,
    isHalal: foodItem.isHalal,
    isOrganic: foodItem.isOrganic,
    containsNuts: foodItem.containsNuts,
    containsEggs: foodItem.containsEggs,
    containsShellfish: foodItem.containsShellfish,
    containsSoy: foodItem.containsSoy,
    isPopular: foodItem.isPopular,
    glutenFree: foodItem.glutenFree,
    dairyFree: foodItem.dairyFree,
    packaging: foodItem.packaging,
    capacity: foodItem.capacity,
    spiceLevelCustomizations: foodItem.spiceLevelCustomizations,
    servingSizeCustomizations: foodItem.servingSizeCustomizations,
    availability: foodItem.availability,
  };
  return result;
};

export const isLoadedShef = (shef: CartShef): shef is LoadedCartShef => !shef.loading;
