import styled from 'styled-components';
import { Colors, Font } from 'web-common/src/shared/styles';

interface AuthSecondaryButtonProps {
  onClick: (e: React.MouseEvent) => void;
  label: string;
}

const SecondaryButton = styled.button`
  padding: unset;
  width: fit-content;

  background: none;
  border: none;
  color: ${Colors.GRAY_DARK_30};
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.S};
  font-weight: ${Font.Weight.MEDIUM};
  text-decoration: underline;
`;

export const AuthSecondaryButton: React.FC<AuthSecondaryButtonProps> = ({ onClick, label }) => (
  <SecondaryButton onClick={onClick}>{label}</SecondaryButton>
);
