import { Checkmark } from 'src/shared/design-system/Icon';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { Colors, Font, Spacing, Transition } from 'web-common/src/shared/styles';
import { DropdownSize } from '../consts';
import { THEME } from '../theme';

export const Wrapper = styled.div`
  flex: 1 1 100%;
  white-space: break-word;
`;

export const Container = styled.div<{
  isHighlighted?: boolean;
  isSectionHeader?: boolean;
  isSelected?: boolean;
  size: DropdownSize;
}>`
  align-items: center;
  background: ${({ isHighlighted, isSelected }) =>
    match({ isHighlighted, isSelected })
      .with({ isSelected: true }, () => Colors.WHITE)
      .with({ isHighlighted: true }, () => Colors.GRAY_LITE_30)
      .otherwise(() => Colors.WHITE)};
  color: ${Colors.GRAY_DARK_30};
  cursor: ${ifProp('isSectionHeader', 'default', 'pointer')};
  display: flex;
  font-family: ${Font.Family.SANS};

  font-weight: 400;
  line-height: ${({ size }) => THEME[size].FontSize};
  position: relative;
  text-align: left;
  transition: background ${Transition.Time.BASE};

  ${({ isSectionHeader, size }) =>
    isSectionHeader
      ? css`
          color: ${Colors.GRAY_DARK_20};
          font-size: ${THEME[size].FontSizeHeader};
          font-weight: ${Font.Weight.BOLD};
          &:not(:first-child) {
            padding-top: ${Spacing.SINGLE};
          }
        `
      : css`
          font-size: ${THEME[size].FontSize};
          &:hover {
            background: ${Colors.GRAY_LITE_30} !important;
          }
          &:active {
            background: ${Colors.GRAY_LITE_20} !important;
          }
        `};

  & > ${Wrapper} {
    padding: ${({ size }) => THEME[size].Padding};
  }
`;

export const IconContainer = styled.div<{ isOpen?: boolean; selected?: boolean; size: DropdownSize }>`
  align-items: center;
  color: ${ifProp('selected', Colors.RADISH, Colors.GRAY_DARK_10)};
  display: flex;
  padding: ${({ size }) => THEME[size].Padding};
  pointer-events: none;

  & svg {
    box-sizing: content-box !important;
    height: ${({ size }) => THEME[size].IconSize};
    width: ${({ size }) => THEME[size].IconSize};
  }
`;

export const SelectedIcon = styled(Checkmark)``;
