import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { ifNotProp } from 'web-common/src/shared/styled-utils/ifNotProp';
import { Colors } from 'web-common/src/shared/styles';
import { WAVE_SIZE } from './consts';
import { WavePosition } from './types';

export const WaveContainer = styled.div<{ $isStroke?: boolean; waveHeight?: number; waveWidth?: number }>`
  display: flex;
  flex-direction: row;
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
  width: 100%;
  ${ifNotProp('$isStroke', `color: ${Colors.WHITE};`)}
`;

export const Container = styled.div<{ waveHeight?: number; wavePosition?: WavePosition }>`
  display: flex;
  flex-direction: row;
  position: absolute;
  height: ${({ waveHeight = WAVE_SIZE.height }) => waveHeight}px;
  width: 100%;
  overflow: hidden;

  ${({ waveHeight = WAVE_SIZE.height, wavePosition = WavePosition.BOTTOM }) =>
    match(wavePosition)
      .with(
        WavePosition.TOP,
        () => css`
          top: -2px;
          left: 0;
          right: 0;
          transform: rotate(180deg);
          & ${WaveContainer} {
            top: -1px;
          }
        `
      )
      .with(
        WavePosition.LEFT,
        () => css`
          top: 0;
          bottom: 0;
          left: -2px;
          transform: rotate(90deg) translateX(-${waveHeight}px);
          transform-origin: left bottom;
          & ${WaveContainer} {
            top: -1px;
          }
        `
      )
      .with(
        WavePosition.RIGHT,
        () => css`
          top: 0;
          bottom: 0;
          right: -2px;
          transform: rotate(270deg) translateX(${waveHeight}px);
          transform-origin: right bottom;
          & ${WaveContainer} {
            top: -1px;
          }
        `
      )
      .otherwise(
        () => css`
          bottom: -2px;
          left: 0;
          right: 0;
          & ${WaveContainer} {
            bottom: 1px;
          }
        `
      )}
`;

export const Path = styled.path<{ $isStroke?: boolean }>`
  ${({ fill, $isStroke }) =>
    $isStroke
      ? css`
          fill: transparent;
          stroke: currentColor;
          stroke-width: 5;
        `
      : css`
          fill: ${fill ?? 'currentColor'};
        `}
`;

export const DividerContainer = styled.div`
  position: relative;
  height: 0px;
  width: 100%;
`;

export const DividerStrokeContainer = styled.div<{ color?: Colors }>`
  position: absolute;
  top: 0px;
  width: 100%;
  color: ${({ color = Colors.GRAY_LITE_20 }) => color};
`;

export const DividerBackgroundContainer = styled.div<{ color?: Colors }>`
  position: absolute;
  top: -1px;
  width: 100%;
  ${({ color }) =>
    color
      ? css`
          color: ${color};

          & ${WaveContainer} {
            color: ${color};
          }
        `
      : ``}
`;
