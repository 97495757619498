import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

interface ThumbsUpV2Props extends React.SVGProps<SVGSVGElement> {
  outline?: boolean;
}

export const ThumbsUpV2 = ({ outline = false, ...props }: ThumbsUpV2Props) => {
  if (outline) {
    return <ThumbsUpV2Outline {...props} />;
  }

  return (
    <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
      <Path
        d='M4 14V7C4 6.4375 3.53125 6 3 6H1C0.4375 6 0 6.46875 0 7V13.9688C0 14.5 0.4375 14.9375 1 14.9375H3C3.53125 15 4 14.5625 4 14ZM16 7.03125C16 6.1875 15.3125 5.53125 14.5 5.53125H9.90625C10.625 4.34375 11 3 11 2.53125C11 1.78125 10.4062 1 9.4375 1C7.46875 1 8.625 3.40625 6.0625 5.4375L5.5625 5.84375C5.1875 6.15625 5 6.5625 5 7C5 7.03125 5 7 5 7V12C5 12.5 5.21875 12.9375 5.59375 13.2188L6.65625 14C7.53125 14.6562 8.5625 15 9.65625 15H11.5C12.3125 15 13 14.3438 13 13.5312C13 13.4062 12.9688 13.2812 12.9375 13.1875C13.5625 13 14 12.4375 14 11.75C14 11.4688 13.9062 11.2188 13.75 11C14.4688 10.8438 15 10.25 15 9.53125C15 9.125 14.8438 8.78125 14.5938 8.5C15.375 8.46875 16 7.8125 16 7.03125Z'
        fill='currentColor'
      />
    </SVG>
  );
};

export const ThumbsUpV2Outline = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M2.75 6H1.25C0.53125 6 0 6.5625 0 7.25V13.75C0 14.4688 0.53125 15 1.25 15H2.75C3.4375 15 4 14.4688 4 13.75V7.25C4 6.5625 3.4375 6 2.75 6ZM3 13.75C3 13.9062 2.875 14 2.75 14H1.25C1.09375 14 1 13.9062 1 13.75V7.25C1 7.125 1.09375 7 1.25 7H2.75C2.875 7 3 7.125 3 7.25V13.75ZM16 6.9375C16 5.875 15.125 5 14.0625 5H10.875C11.2188 4.15625 11.4375 3.34375 11.4375 2.90625C11.4375 1.96875 10.7188 1 9.5 1C8.21875 1 7.9375 1.9375 7.65625 2.71875C6.8125 5.375 5 5.78125 5 6.5C5 6.8125 5.21875 7 5.5 7C5.625 7 5.75 6.96875 5.84375 6.875C7.46875 5.21875 7.9375 5.125 8.625 3.03125C8.90625 2.15625 9 2 9.5 2C10.1562 2 10.4375 2.53125 10.4375 2.90625C10.4375 3.21875 10.1562 4.28125 9.625 5.28125C9.5625 5.34375 9.5625 5.4375 9.5625 5.5C9.5625 5.8125 9.8125 6 10.0625 6H14.0625C14.5625 6 15 6.4375 15 6.9375C15 7.40625 14.5938 7.8125 14.125 7.84375C13.875 7.875 13.6562 8.09375 13.6562 8.34375C13.6562 8.71875 14.0312 8.75 14.0312 9.3125C14.0312 9.75 13.7188 10.125 13.2812 10.2188C13.0938 10.25 12.875 10.4062 12.875 10.6875C12.875 10.9688 13.0938 11.0312 13.0938 11.4375C13.0938 12.4062 12.0938 12.125 12.0938 12.7812C12.0938 12.9062 12.1562 12.9375 12.1562 13.0938C12.1562 13.5938 11.7188 14 11.2188 14H9.46875C6.90625 14 6.09375 12 5.5 12C5.21875 12 5 12.25 5 12.5C4.96875 13.0312 7 15 9.46875 15H11.2188C12.2812 15 13.1562 14.1562 13.1562 13.0938C13.7188 12.75 14.0938 12.125 14.0938 11.4375C14.0938 11.2812 14.0625 11.125 14.0312 11C14.625 10.6562 15.0312 10.0312 15.0312 9.3125C15.0312 9.09375 15 8.84375 14.9062 8.65625C15.5625 8.34375 16 7.6875 16 6.9375Z'
      fill='currentColor'
    />
  </SVG>
);
