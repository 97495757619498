import { compact, uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { OrganizationFieldsFragment, PromoCodeFieldsFragment, PromoCodeType } from 'src/gqlReactTypings.generated.d';
import { useScopedReducer } from 'src/shef-global-state/shefGlobalState';
import { IPromotionals } from './promoCodeTypes';
import { saveStateToStorage } from './promotionalsState';

export const usePromotionalsHook = () => {
  const { state, reduce } = useScopedReducer('promotionals');

  const setOrganization = useCallback(
    (org: OrganizationFieldsFragment | null) => {
      reduce((state) =>
        saveStateToStorage({
          ...state,
          organization: org,
        })
      );
    },
    [reduce]
  );

  const setCode = useCallback(
    (code: PromoCodeFieldsFragment | null) => {
      reduce((state) =>
        saveStateToStorage({
          ...state,
          availablePromos: uniqBy(compact([...(state.availablePromos ?? []), code]), 'id'),
          code,
        })
      );
    },
    [reduce]
  );

  const setError = useCallback(
    (error: IPromotionals['error']) => {
      reduce((state) => ({
        ...state,
        error,
      }));
    },
    [reduce]
  );

  const code = useMemo(
    () => (state.organization ? state.organization.promoCode : state.code ?? null),
    [state.organization, state.code]
  );

  const promoText = useMemo(() => {
    switch (code?.promoCodeType) {
      case PromoCodeType.Referral:
        return 'Referral Promo';
      case PromoCodeType.OrganizationInternal:
        return state.organization?.name ? `${state.organization.name} Promo` : 'Company Promo';
      case PromoCodeType.GiftCard:
        return 'Gift card';
      case undefined:
        return null;
      default:
        return 'Promo';
    }
  }, [code, state.organization]);

  const unsetActiveCode = useCallback(() => {
    reduce((state) =>
      saveStateToStorage({
        ...state,
        code: null,
        error: null,
        organization: null,
      })
    );
  }, [reduce]);

  // Remove a particular promo code
  const removeCode = useCallback(
    (codeToRemove: PromoCodeFieldsFragment) => {
      const newAvailablePromos = state.availablePromos.filter((promoCode) => promoCode.code !== codeToRemove.code);
      const removingActivecode = code?.code === codeToRemove.code;

      reduce((state) =>
        saveStateToStorage({
          availablePromos: newAvailablePromos,
          code: removingActivecode ? newAvailablePromos[0] ?? null : code ?? null,
          error: removingActivecode ? null : state.error,
          organization: removingActivecode ? null : state.organization,
        })
      );
    },
    [code, reduce, state.availablePromos]
  );

  // Removes all saved promos from storage
  const clearState = useCallback(() => {
    reduce(() =>
      saveStateToStorage({
        availablePromos: [],
        code: null,
        error: null,
        organization: null,
      })
    );
  }, [reduce]);

  return {
    // The availablePromos ?? [] in theory should be unnecessary;
    // it seemed to be occasionally nullish though. Maybe
    // from old clients from before availablePromos was
    // introduced.
    availablePromos: state.availablePromos ?? [],
    code,
    promoText,
    organization: state.organization,
    setCode,
    setError,
    error: state.error,
    setOrganization,
    unsetActiveCode,
    removeCode,
    clearState,
  };
};
