import { useCallback } from 'react';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';

const innerHeightToVH = (innerHeight: number) => innerHeight * 0.01;

// --original-vh is set to 1/100 of the page's initial height (upon calling this listener in index.tsx), this will not change
// -vh is set to 1/100 of the current page height, this will change as page height changes (android keyboard pops up, etc.)
export const initViewHeightListener = () => {
  const vh = innerHeightToVH(window.innerHeight);
  document.documentElement.style.setProperty('--original-vh', `${vh}px`);

  const setVh = () => {
    const vh = innerHeightToVH(window.innerHeight);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  setVh();

  window.addEventListener('resize', setVh);
};

export const viewHeightCSSInline = (percent: number) => `calc(var(--vh, 1vh) * ${percent})`;

export const viewHeightCSS = (percent: number, property = 'height', important?: boolean) => `
  ${property}: ${percent}vh${important ? ' !important' : ''};
  ${property}: ${viewHeightCSSInline(percent)}${important ? ' !important' : ''};
`;

export const OriginalViewHeightCSSInline = (percent: number) => `calc(var(--original-vh, 1vh) * ${percent})`;

export const OriginalviewHeightCSS = (percent: number, property = 'height', important?: boolean) => `
  ${property}: ${percent}vh${important ? ' !important' : ''};
  ${property}: ${OriginalViewHeightCSSInline(percent)}${important ? ' !important' : ''};
`;

export const useViewHeight = () => {
  const { windowDimensions } = useScreenContext();
  const getPxNumber = useCallback((vh: number) => innerHeightToVH(windowDimensions.height) * vh, [windowDimensions]);

  const getPx = useCallback((vh: number) => `${getPxNumber(vh)}px`, [getPxNumber]);
  return {
    getPx,
    getPxNumber,
  };
};
