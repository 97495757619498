import { Draft } from '@reduxjs/toolkit';
import { isDefined } from 'common/TypeUtilities';
import { difference, isString, map, mapValues, uniq } from 'lodash';
import {
  addOnItemAdapter,
  cartShefAdapter,
  foodItemAdapter,
  formatCartFoodItem,
  getDefaultLineItem,
  lineItemAdapter,
} from './entities';
import * as CartTypes from './types';
import { CartFoodItem, CartLineItem, CartState, IncAddOnItemQuantityAction, IncLineItemQuantityAction } from './types';
import { findDefaultDeliveryDate } from './utils/date';

type DraftCartState = Draft<CartState>;

export const removeLineItems = (state: DraftCartState, lineItems: CartLineItem[]) => {
  const ids = lineItems.map((lineItem) => lineItem.id);
  const foodItemIds = lineItems.map((lineItem) => lineItem.foodItemId);
  lineItemAdapter.removeMany(state.lineItems, ids);
  foodItemAdapter.removeMany(state.foodItems, foodItemIds);
};

// removes any excess line item quantity from the cart
export const removeExcessiveLineItemQuantity = (state: DraftCartState) => {
  const deliveryDatesByShefId: Record<string, string> = mapValues(state.shefs.entities, 'deliveryDate');
  const lineItems = Object.values(state.lineItems.entities).filter(isDefined);

  lineItems.forEach((lineItem) => {
    const deliveryDate = deliveryDatesByShefId[lineItem.shefId];
    const deliveryDateQuantity =
      state.foodItems.entities[lineItem.foodItemId]?.availability?.find((a) =>
        a.availabilityDate.startsWith(deliveryDate)
      )?.numAvailable ?? Infinity;

    if (deliveryDateQuantity < lineItem.quantity) {
      lineItem.overageQuantity = lineItem.quantity - deliveryDateQuantity;
      lineItem.quantity = deliveryDateQuantity;
    } else if (lineItem.overageQuantity && lineItem.overageQuantity > 0) {
      lineItem.overageQuantity = 0;
    }
  });
};

const updateFoodLineItemItemQuantity = (state: DraftCartState, foodItem: CartFoodItem, amount: number): number => {
  const lineItem = state.lineItems.entities[foodItem.id] ?? getDefaultLineItem(foodItem);

  // if the user is updating the item quantity we can percieve this as an acknowledgement
  // to our messaging that user previously had too many items in their cart
  lineItem.overageQuantity = 0;
  lineItem.updatedAt = new Date().toISOString();

  const newQuantity = lineItem.quantity + amount;
  if (newQuantity > 0) {
    lineItemAdapter.upsertOne(state.lineItems, { ...lineItem, quantity: newQuantity });
    foodItemAdapter.upsertOne(state.foodItems, foodItem);
    return newQuantity;
  }

  removeLineItems(state, [lineItem]);
  return 0;
};

export const updateLineItemCustomizations = (
  state: DraftCartState,
  foodItem: CartFoodItem,
  customizationIds?: string[]
) => {
  if (!customizationIds) return;
  const lineItem = state.lineItems.entities[foodItem.id] ?? getDefaultLineItem(foodItem);
  lineItem.customizationIds = customizationIds;
};

const updateFoodLineItemQuantities = (state: DraftCartState, foodItem: CartFoodItem, amount: number) => {
  updateFoodLineItemItemQuantity(state, foodItem, amount);
};

export const formatCartFoodItems = (foodItems: CartFoodItem[]) =>
  foodItems.map((foodItem) => formatCartFoodItem(foodItem));

export const updateDeliveryDate = (state: DraftCartState, deliveryDate: string) => {
  state.deliveryDate = deliveryDate;
};

export const updateLineItemQuantity = (
  state: DraftCartState,
  { payload }: IncLineItemQuantityAction,
  amount: number
) => {
  const foodItem = formatCartFoodItem(payload.foodItem);

  if (amount === 0) return;

  updateFoodLineItemQuantities(state, foodItem, amount);

  // If recentlyAdded is already true, recentlyAdded always stays true, unless the cart went to 0 items.
  // If the number of items increased, recentlyAdded becomes true.
  // If the cart went to 0 items, recentlyAdded becomes false.
  const addedMoreQuantity = amount > 0;
  const hasItems = state.lineItems.ids.length !== 0;
  state.recentlyAdded = (state.recentlyAdded || addedMoreQuantity) && hasItems;
};

export const updateAddOnItemQuantity = (
  state: DraftCartState,
  { payload }: IncAddOnItemQuantityAction,
  amount: number
) => {
  const { addOnItem } = payload;

  if (amount === 0) return;

  const addOnLineItem = state.addOnItems.entities[addOnItem.id] ?? { ...addOnItem, quantity: 0 };

  const newQuantity = addOnLineItem.quantity + amount;
  if (newQuantity > 0) {
    addOnItemAdapter.upsertOne(state.addOnItems, { ...addOnLineItem, quantity: newQuantity });
  } else {
    addOnItemAdapter.removeOne(state.addOnItems, addOnItem.id);
  }
};

export const clearCartByIds = (state: DraftCartState, shefIds: string[]) => {
  const shefIdsSet = new Set(shefIds);

  const lineItemIds = Object.values(state.lineItems.entities)
    .filter(isDefined)
    .filter((lineItem) => shefIdsSet.has(lineItem.shefId))
    .map((lineItem) => lineItem.id);

  const foodItemIds = lineItemIds
    .map((lineItemId) => state.lineItems.entities[lineItemId]?.foodItemId)
    .filter(isDefined);

  lineItemAdapter.removeMany(state.lineItems, lineItemIds);
  foodItemAdapter.removeMany(state.foodItems, foodItemIds);

  if (state.lineItems.ids.length === 0) {
    addOnItemAdapter.removeAll(state.addOnItems);
  }

  state.ready = true;
};

export const clearUnusedShefs = (state: DraftCartState) => {
  const lineItems = Object.values(state.lineItems.entities).filter(isDefined);
  const lineItemShefIds = uniq(map(lineItems, 'shefId'));
  const shefIdsToKeep = [state.activeCartShefId, ...lineItemShefIds];
  const currentShefIds = state.shefs.ids.filter(isString);
  const shefIdsToRemove = difference(currentShefIds, shefIdsToKeep);
  cartShefAdapter.removeMany(state.shefs, shefIdsToRemove);
};

// make sure that if there's a stale delivery date that it's updated to the next available
export function keepDeliveryDateFresh(state: DraftCartState) {
  const defaultDeliveryDate = findDefaultDeliveryDate(
    state.possibleDeliveryDates,
    state.deliveryDate,
    state.lineItems.ids.length > 0,
    undefined,
    state.skipTomorrowDefaultDate
  );

  if (!defaultDeliveryDate) {
    console.error('No possible delivery date for cart to update to');
  } else if (state.deliveryDate < defaultDeliveryDate) {
    state.deliveryDate = defaultDeliveryDate;

    // only require user to acknowledge delivery date update if there's items in the cart
    const hasItemsInCart = state.lineItems.ids.length > 0;
    if (hasItemsInCart) {
      state.requireUserAcknowledgeDeliveryDateUpdate = true;
    }
  }
}

export const performClearCarts = (state: CartTypes.CartState) => {
  lineItemAdapter.removeAll(state.lineItems);
  foodItemAdapter.removeAll(state.foodItems);
  addOnItemAdapter.removeAll(state.addOnItems);
  cartShefAdapter.removeAll(state.shefs);
};
