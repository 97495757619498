import { useCallback, useMemo, useState } from 'react';
import { useLocalStorage } from 'src/shared/storage/Storage';

type UseModalArgs = {
  defaultShow?: boolean;
  persistentStorageKey?: string;
};

export type UseModalResult = ReturnType<typeof useModal>;

export const useModal = (args: UseModalArgs = {}) => {
  const { defaultShow, persistentStorageKey } = { defaultShow: false, ...args };

  const [hasSeenDialog, setHasSeenDialog] = useLocalStorage(persistentStorageKey ?? '', false);
  const [isShowing, setIsShowing] = useState(defaultShow);

  const show = useCallback(() => {
    setIsShowing(true);
  }, []);

  const hide = useCallback(() => {
    if (persistentStorageKey) {
      setHasSeenDialog(true);
    }
    setIsShowing(false);
  }, [persistentStorageKey, setHasSeenDialog]);

  const toggle = useCallback(() => {
    if (isShowing) {
      hide();
    } else {
      show();
    }
  }, [hide, isShowing, show]);

  // TODO: Figure out a better interface for this method. Right now show() does nothing if the persistentStorageKey has been set
  // and they've already seen the modal...
  const forceShow = useCallback(() => {
    setHasSeenDialog(false);
    show();
  }, [setHasSeenDialog, show]);

  return useMemo(
    () => ({
      hasSeen: hasSeenDialog,
      hide,
      isShowing: isShowing && (!persistentStorageKey || !hasSeenDialog),
      show,
      forceShow,
      toggle,
    }),
    [forceShow, hasSeenDialog, hide, isShowing, persistentStorageKey, show, toggle]
  );
};
