export enum FollowColors {
  WHITE = 'white',
  RED = 'red',
  BLACK = 'black',
}

export type FollowToggleIconProps = {
  className?: string;
  isAnimated?: boolean;
  isFollowing?: boolean;
  followingColor?: FollowColors;
};
