import React from 'react';
import closeWhiteSmallIcon from 'src/assets/svg/design-library/close-white-small.svg';
import logo from 'src/assets/svg/logo/white.svg';
import { Colors, FontSize } from 'web-common/src/shared/styles';
import { Span } from '../Text';
import {
  BannerContent,
  CloseButton,
  CloseIcon,
  LogoContainer,
  Star,
  StarsContainer,
  StyledAppUpsellBanner,
} from './styles';

interface AppUpsellBannerProps {
  onClick: () => void;
  onClose: () => void;
}

/**
 * A banner used for upselling users to the native app.
 * Note: this banner is designed for MOBILE ONLY.
 */
export const AppUpsellBanner: React.FC<AppUpsellBannerProps> = ({ onClick, onClose }) => (
  <StyledAppUpsellBanner>
    <BannerContent onClick={onClick}>
      <LogoContainer>
        <img src={logo} alt='logo' width='34px' />
      </LogoContainer>
      <div>
        <Span size={FontSize.S} color={Colors.WHITE} bold>
          Get the app
        </Span>
        <StarsContainer>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </StarsContainer>
      </div>
    </BannerContent>
    <CloseButton onClick={onClose}>
      <CloseIcon src={closeWhiteSmallIcon} alt='close' />
    </CloseButton>
  </StyledAppUpsellBanner>
);
