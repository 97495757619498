export enum BorderRadius {
  XS = '2px',
  S = '4px',
  M = '8px',
  L = '16px',
  BUTTON = '25px',
}

export const Border = {
  Base: '1px solid',
  BaseDouble: '2px solid',
  Radius: BorderRadius,
};
