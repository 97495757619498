import React from 'react';
import { Rect, SVG } from 'web-common/src/shared/design-system/Utilities';

export const CopyLight = ({ ...svgProps }: React.SVGProps<SVGSVGElement>) => (
  <SVG width='14' height='18' viewBox='0 0 14 18' {...svgProps}>
    <Rect x='0.5' y='3.66666' width='10.6667' height='13' rx='1.5' fill='none' stroke='currentColor' />
    {/* NOTE: Refactor this to accept different background colors if needed */}
    <Rect x='2.83337' y='1.33331' width='10.6667' height='13' rx='1.5' fill='#E4E4E4' stroke='currentColor' />
  </SVG>
);
