import { useZipCodeQuery } from 'src/gqlReactTypings.generated.d';

export const useZipCode = (zipCode?: string | null) => {
  const { data, loading, error } = useZipCodeQuery({
    variables: { zipCode: zipCode ?? '' },
    skip: !zipCode,
  });
  return {
    ...data?.zipCode,
    data,
    loading,
    error,
  };
};
