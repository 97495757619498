import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './alex-von-brandenfels.jpg';

export const AlexVonBrandenfels: PersonInfo = {
  firstName: 'Alex',
  lastName: 'Von Brandenfels',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
