import { Card } from 'src/shared/design-system/Avatar/Card';
import styled from 'styled-components';
import { Spacing } from 'web-common/src/shared/styles';

export const AvatarCard = styled(Card).attrs(() => ({
  showArrow: false,
  showShadow: false,
}))``;

export const Container = styled.div`
  padding: ${Spacing.HALF};
`;
