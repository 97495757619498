import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const ArrowSoftLeftV2 = (props: React.SVGProps<SVGSVGElement>) => (
  // View box is a little off to keep arrow centered
  <SVG height={16} width={16} viewBox={'0 0 16 16'} {...props}>
    <title>Left arrow</title>
    <desc>An arrow pointing left</desc>
    <Path
      d='M16 6.98178C16 7.49185 15.6063 7.89262 15.1409 7.89262H2.97092L7.73154 12.4833C8.08949 12.8112 8.08949 13.3941 7.76734 13.722C7.44519 14.0863 6.90828 14.0863 6.55034 13.7584L0.250559 7.63759C0.0715884 7.45542 0 7.23682 0 6.98178C0 6.76318 0.0715884 6.54458 0.250559 6.36241L6.55034 0.241553C6.90828 -0.0863501 7.44519 -0.0863501 7.76734 0.277987C8.08949 0.60589 8.08949 1.18883 7.73154 1.51673L2.97092 6.10737H15.1409C15.6421 6.10737 16 6.50815 16 6.98178'
      fill='currentColor'
    />
  </SVG>
);
