import { cartSlice, editableOrderCartSlice } from './cart';
import {
  IncAddOnItemQuantityAction,
  IncLineItemQuantityAction,
  SetActiveCartAction,
  SetAddOnItemQuantityAction,
  SetDeliveryDateAction,
  SetLineItemQuantityAction,
  UpdateFoodItemAvailabilitiesForDateAction,
} from './types';

const getCartSlice = (isEditingOrder: boolean) => (isEditingOrder ? editableOrderCartSlice : cartSlice);

export const setDeliveryDate = (isEditingOrder: boolean, payload: SetDeliveryDateAction['payload']) =>
  getCartSlice(isEditingOrder).actions.setDeliveryDate(payload);

export const incLineItemQuantity = (isEditingOrder: boolean, payload: IncLineItemQuantityAction['payload']) =>
  getCartSlice(isEditingOrder).actions.incLineItemQuantity(payload);

export const decLineItemQuantity = (isEditingOrder: boolean, payload: IncLineItemQuantityAction['payload']) =>
  getCartSlice(isEditingOrder).actions.decLineItemQuantity(payload);

export const removeLineItem = (isEditingOrder: boolean, payload: IncLineItemQuantityAction['payload']) =>
  getCartSlice(isEditingOrder).actions.removeLineItem(payload);

export const setLineItemQuantity = (isEditingOrder: boolean, payload: SetLineItemQuantityAction['payload']) =>
  getCartSlice(isEditingOrder).actions.setLineItemQuantity(payload);

export const incAddOnItemQuantity = (isEditingOrder: boolean, payload: IncAddOnItemQuantityAction['payload']) =>
  getCartSlice(isEditingOrder).actions.incAddOnItemQuantity(payload);

export const decAddOnItemQuantity = (isEditingOrder: boolean, payload: IncAddOnItemQuantityAction['payload']) =>
  getCartSlice(isEditingOrder).actions.decAddOnItemQuantity(payload);

export const setAddOnItemQuantity = (isEditingOrder: boolean, payload: SetAddOnItemQuantityAction['payload']) =>
  getCartSlice(isEditingOrder).actions.setAddOnItemQuantity(payload);

export const setShefTip = (isEditingOrder: boolean, payload: number) =>
  getCartSlice(isEditingOrder).actions.setShefTip(payload);

export const setActiveCart = (isEditingOrder: boolean, payload: SetActiveCartAction['payload']) =>
  getCartSlice(isEditingOrder).actions.setActiveCart(payload);

export const updateFoodItemAvailabilitiesForDate = (
  isEditingOrder: boolean,
  payload: UpdateFoodItemAvailabilitiesForDateAction['payload']
) => getCartSlice(isEditingOrder).actions.updateFoodItemAvailabilitiesForDate(payload);

export const forceCartRefetch = () => cartSlice.actions.forceRefetch();

export const {
  clearActiveCarts,
  clearCarts,
  clearCartsByShefIds,
  init,
  setAvailableAddOns,
  setIsMultiCart,
  setAllDiscountsApplied,
  setDiscountApplied,
  setPromoApplied,
  setPromoCode,
  setZipCode,
  setServerCart,
  setCartReady,
  setShefTipPercent,
  setShefs,
  updateShefAvailabilitiesForDate,
  updateFoodItems,
  updateShef,
  userLogin,
  userLogout,
  setPossibleDeliveryDates,
  setSkipTomorrowDefaultDate,
  userAcknowledgeDeliveryDateChange,
  clearQueue,
  queueAction,
} = cartSlice.actions;

export const { resetEditableOrder, setEditableOrder } = editableOrderCartSlice.actions;
