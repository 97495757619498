import React, { useEffect, useRef } from 'react';
import { DropdownSize, ItemProps } from '../consts';
import { getItemDisplayValue } from '../utils';
import { Container, IconContainer, SelectedIcon, Wrapper } from './styles';

interface IItemProps {
  highlightedValue?: string;
  isHighlighted: boolean;
  onMouseEnter?: () => void;
  isSelected?: boolean;
  item: ItemProps;
  size: DropdownSize;
}

export const Item = React.forwardRef<HTMLDivElement, IItemProps>((props, ref) => {
  const { highlightedValue, isHighlighted, onMouseEnter, isSelected, item, size, ...otherProps } = props;
  const { isSectionHeader } = item;
  const label = getItemDisplayValue(item);
  const called = useRef<boolean>(false);
  const hasHighlightedValue = !!highlightedValue;
  const highlighted = label === highlightedValue;

  useEffect(() => {
    if (onMouseEnter && !hasHighlightedValue && isSelected && !called.current) {
      onMouseEnter();
      called.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Highlights selected item.
    if (onMouseEnter && highlighted && !called.current) {
      onMouseEnter();
      called.current = true;
    } else {
      called.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlighted, onMouseEnter]);

  return (
    <Container
      data-selected={isSelected}
      isHighlighted={highlighted || isHighlighted}
      isSelected={isSelected}
      isSectionHeader={isSectionHeader}
      ref={ref}
      size={size}
      {...otherProps}>
      <Wrapper>{item.displayedText ?? getItemDisplayValue(item)}</Wrapper>
      {isSelected && (
        <IconContainer size={size} selected>
          <SelectedIcon />
        </IconContainer>
      )}
    </Container>
  );
});
