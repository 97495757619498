import { createContext } from 'react';
import { User } from 'src/gqlReactTypings.generated.d';
import { StreamChat } from 'stream-chat';

export enum ChatRole {
  SHEF = 'shef',
  CONSUMER = 'consumer',
}

export interface ChatContextType {
  client: StreamChat | null;
  loading: boolean;
  role: ChatRole;
  unread: number;
  tracking: {
    user_id?: string;
    region_id?: number;
    device_id?: string;
    session_id?: string;
  };
  openWidget?: (recipient: Pick<User, 'flakeId' | 'publicName'>) => void;
  closeWidget?: () => void;
}

export const ChatContext = createContext<ChatContextType>({
  client: null,
  loading: false,
  role: ChatRole.CONSUMER,
  unread: 0,
  tracking: {},
});
