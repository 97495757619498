import { animated } from 'react-spring';
import { CheckmarkV2 } from 'src/shared/design-system/Icon';
import styled, { css, keyframes } from 'styled-components';
import { Border, Colors, Font, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';

export const savingsShake = keyframes`
  0% {
    transform: rotateZ(25deg) scale(1.3);
  }
  50% {
    transform: rotateZ(-25deg) scale(1.3);
  }
  100% {
    transform: rotateZ(25deg) scale(1.3);
  }

`;

export const Container = styled.div`
  gap: ${Spacing.SINGLE_HALF};
  max-width: 450px;
  width: 100%;
  ${Mixins.Flex.centerColumn()}
`;

export const Wrapper = styled.div`
  background: ${Colors.GRAY_LITE_30};
  border-radius: ${Border.Radius.L};
  gap: ${Spacing.HALF};
  padding: ${Spacing.SINGLE};
  width: 100%;

  ${Mixins.Flex.centerColumn()};
`;

export const CompletedIcon = styled(CheckmarkV2)<{ show?: boolean }>`
  width: ${Spacing.THREE_QUARTERS};
  ${({ show = false }) => css`
    opacity: ${show ? 1 : 0};
    transform: scale(${show ? 1 : 0});
    transition: opacity ${Transition.Time.BASE}, transform ${Transition.Time.BASE};
  `}
`;

export const DiscountLine = styled.div`
  gap: ${Spacing.HALF};
  ${Mixins.Flex.set()}
`;

export const DiscountText = styled(animated.span)`
  font-weight: ${Font.Weight.BOLD};
`;

export const DeliverySavingsText = styled.span`
  display: none;
  font-weight: ${Font.Weight.BOLD};
  ${DiscountText}:empty + & {
    display: inline;
  }
`;

export const SpendText = styled.span`
  font-weight: ${Font.Weight.BOLD};
`;

export const TotalSavings = styled.div<{ show?: boolean }>`
  color: ${Colors.MINT_600};
  text-align: center;
  transition: max-height ${Transition.Time.BASE}, margin-bottom ${Transition.Time.BASE}, opacity ${Transition.Time.BASE};
  ${Mixins.Flex.center()}
  ${({ show }) => css`
    max-height: ${show ? Spacing.DOUBLE : '0px'};
    margin-bottom: ${show ? Spacing.HALF : '0px'};
    opacity: ${show ? 1 : 0};
  `}
`;

export const SavingsIconContainer = styled.div<{ animate?: boolean }>`
  display: inline-block;
  transition: transform ${Transition.Time.DOUBLE};
  ${({ animate }) => css`
    ${animate
      ? css`
          animation: 0.2s ${savingsShake} infinite;
        `
      : css`
          transform: rotateZ(0deg) scale(1);
          transition: transform ${Transition.Time.DOUBLE};
        `}
  `}
`;
