import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './elora-strom.jpg';

export const EloraStrom: PersonInfo = {
  firstName: 'Elora',
  lastName: 'Strom',
  image,
  pronoun: Pronoun.SHE,
  role: ROLE.SOFTWARE_ENGINEER,
};
