import { DynamicImage } from 'src/shared/design-system/DynamicImage';
import { LightboxWithControls } from 'src/shared/design-system/Lightbox';
import styled, { css } from 'styled-components';
import { Border, Colors, Mixins, Spacing } from 'web-common/src/shared/styles';
import { FOOD_IMAGE_PROPS } from '../consts';

export const Container = styled.div`
  position: sticky;
  top: 0;
  overflow: hidden;
  background: ${Colors.WHITE};
  transform: translateZ(0px);
  ${Mixins.Border.setTopSide(Border.Radius.M)}
  height: 320px;

  ${Mixins.Media.maxWidthSmall(css`
    border-radius: 0;
    height: 0;
    padding-bottom: 66.66%;
    max-height: 320px;
  `)}
`;

export const Image = styled(DynamicImage).attrs(() => ({
  sourceWidth: FOOD_IMAGE_PROPS.sourceWidth,
  sourceHeight: FOOD_IMAGE_PROPS.sourceHeight,
}))`
  width: 100%;
  margin: 0;
`;

export const MiniImage = styled(DynamicImage).attrs(() => ({
  sourceWidth: FOOD_IMAGE_PROPS.sourceWidth,
  sourceHeight: FOOD_IMAGE_PROPS.sourceHeight,
}))`
  height: ${Spacing.DOUBLE_HALF};
  margin: 0;
  border-radius: ${Border.Radius.M};
`;

export const Viewer = styled(LightboxWithControls)`
  display: flex;
`;
