import React from 'react';
import {
  AllergenDairy,
  AllergenGluten,
  Compostable,
  DietaryHalal,
  DietaryKosher,
  DietaryLowFODMAP,
  DietaryOrganic,
  DietaryPaleo,
  DietaryVegan,
  DietaryVegetarian,
  DietaryWhole30,
} from 'src/shared/design-system/Icon';
import { DietaryTags } from 'web-common/src/shared/Enums';

export const dietaryTagToIcon: Record<DietaryTags, React.ReactNode> = {
  [DietaryTags.VEGETARIAN]: <DietaryVegetarian />,
  [DietaryTags.VEGAN]: <DietaryVegan />,
  [DietaryTags.GLUTEN_FREE]: <AllergenGluten isCrossed />,
  [DietaryTags.DAIRY_FREE]: <AllergenDairy isCrossed />,
  [DietaryTags.PALEO]: <DietaryPaleo />,
  [DietaryTags.LOW_FODMAP]: <DietaryLowFODMAP />,
  [DietaryTags.WHOLE30]: <DietaryWhole30 />,
  [DietaryTags.HALAL]: <DietaryHalal />,
  [DietaryTags.ORGANIC]: <DietaryOrganic />,
  [DietaryTags.COMPOSTABLE]: <Compostable />,
  [DietaryTags.KOSHER]: <DietaryKosher />,
};
