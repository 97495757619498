import { ClientEvents } from 'common/events/ClientEvents';
import { isDefined } from 'common/TypeUtilities';
import { map, uniq } from 'lodash';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getCheckoutQueryString } from 'src/checkout/checkoutUtils';
import { FoodType } from 'src/gqlReactTypings.generated.d';
import { useHistory } from 'src/shared/hooks/useHistory';
import { useLocation } from 'src/shared/hooks/useLocation';
import { useModal } from 'src/shared/hooks/useModal';
import { useTracker } from 'src/shared/hooks/useTracker';
import { Routes } from 'src/shared/Routes';
import { useDispatch, useSelector } from 'src/store';
import { selectAvailableCartOrders, selectMultiCartDeliveryDate } from 'src/store/cart/selectors';
import { startCheckout } from 'src/store/checkout';

const defaultState = {
  isShowing: false,
  show: () => {},
  hide: () => {},
  goToCheckout: () => {},
  foodItemIds: [],
  deliveryDate: '',
};

type IPreCheckoutUpsellModalContext = Omit<typeof defaultState, 'hide' | 'foodItemIds'> & {
  hide: (dismiss?: boolean) => void;
  foodItemIds: number[];
};

const PreCheckoutUpsellModalContext = createContext<IPreCheckoutUpsellModalContext>(defaultState);

export const usePreCheckoutUpsellModalContext = () => useContext(PreCheckoutUpsellModalContext);

export const PreCheckoutUpsellModalProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const history = useHistory();
  const tracker = useTracker();

  const { isShowing, show: modalShow, hide: modalHide } = useModal();

  const dispatch = useDispatch();
  const { foodItems, lineItems, checkoutOrders, deliveryDate } = useSelector(({ cart }) => ({
    foodItems: cart.foodItems,
    lineItems: cart.lineItems,
    checkoutOrders: selectAvailableCartOrders(cart),
    deliveryDate: selectMultiCartDeliveryDate(cart),
  }));

  const [initialFoodItemIds, setInitialFoodItemIds] = useState<number[]>([]);
  const cartFoodItems = useMemo(
    () =>
      Object.values(lineItems.ids)
        .map((liId) => foodItems.entities[liId || -1])
        .filter(isDefined),
    [foodItems.entities, lineItems.ids]
  );
  const hasMains = useMemo(() => cartFoodItems.some((fi) => fi.foodType === FoodType.Main), [cartFoodItems]);

  // allow user to dismiss the experience from showing up, unless they change the items in their cart
  const [dismissed, setDismissed] = useState(false);
  useEffect(() => {
    setDismissed(false);
  }, [lineItems.ids]);

  const goToCheckout = useCallback(() => {
    setInitialFoodItemIds([]);
    modalHide();
    const checkoutShefIds = uniq(checkoutOrders.map((order) => order.shef.id));
    const search = getCheckoutQueryString({ location, shefIds: checkoutShefIds, urlDate: deliveryDate });
    dispatch(startCheckout({ shefIds: checkoutShefIds }));
    history.push({ pathname: Routes.CONSUMER_ORDER_FINALIZE, search });
  }, [modalHide, checkoutOrders, location, deliveryDate, history]);

  const show = useCallback(() => {
    if (!hasMains || dismissed) return goToCheckout();
    setInitialFoodItemIds(map(cartFoodItems, 'id'));
    modalShow();
    tracker.track(ClientEvents.PRE_CHECKOUT_UPSELL_MODAL_VIEW, {});
  }, [tracker, modalShow, cartFoodItems, hasMains, dismissed, goToCheckout]);

  const hide = useCallback(
    (dismiss?: boolean) => {
      if (dismiss) setDismissed(dismiss);
      setInitialFoodItemIds([]);
      modalHide();
      tracker.track(ClientEvents.PRE_CHECKOUT_UPSELL_MODAL_CLOSE, {});
    },
    [modalHide, tracker]
  );

  return (
    <PreCheckoutUpsellModalContext.Provider
      value={{
        isShowing,
        show,
        hide,
        foodItemIds: initialFoodItemIds,
        goToCheckout,
        deliveryDate,
      }}>
      {children}
    </PreCheckoutUpsellModalContext.Provider>
  );
};
