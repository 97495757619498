import { PrimaryButton } from 'src/shared/design-system/Buttons';
import { CloseSmall } from 'src/shared/design-system/Icon';
import { Clickable } from 'src/shared/design-system/Utilities';
import styled, { css } from 'styled-components';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Border, Colors, Font, Mixins, Spacing, Transition, ZIndex } from 'web-common/src/shared/styles';
import { MODAL_BOTTOM_HEIGHT, NAV_HEIGHT, NAV_HEIGHT_LARGE } from '../consts';

export const Backdrop = styled.div<{ show?: boolean }>`
  background: ${Colors.GRAY_DARK_30};
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: ${ZIndex.ConsumerNavBarBackdrop};

  ${ifProp(
    'show',
    css`
      opacity: 0.5;
      pointer-events: all;
    `,
    css`
      opacity: 0;
      pointer-events: none;
    `
  )}
`;

export const Container = styled.div<{ show?: boolean }>`
  background: ${Colors.WHITE};
  border-bottom: ${Border.Base} ${Colors.GRAY_LITE_10};
  overflow: hidden;
  z-index: ${ZIndex.ConsumerNavBar};
  transition: margin-top ${Transition.Time.BASE}, opacity ${Transition.Time.BASE},
    max-height ${Transition.Time.BASE} ${Transition.Time.BASE};

  ${ifProp(
    'show',
    css`
      margin-top: 0;
      opacity: 1;
      pointer-events: all;
    `,
    css`
      margin-top: -100%;
      opacity: 0;
      pointer-events: none;
    `
  )}

  ${({ show }) =>
    Mixins.Media.maxWidthSmall(`
    margin-top: 0;
    height: 100%;
    ${show ? `display: block;` : `display: none;`}
  `)}
`;

export const SectionContainer = styled.div<{ largeHeader?: boolean; show?: boolean }>`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${ifProp('largeHeader', NAV_HEIGHT_LARGE, NAV_HEIGHT)});

  ${ifProp(
    'show',
    css`
      max-height: 100%;
      opacity: 1;
      pointer-events: all;
    `,
    css`
      max-height: 0px;
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
    `
  )}
`;

export const Wrapper = styled.div<{ show?: boolean }>`
  flex: 1;
  height: 100%;
`;

export const CloseButton = CloseSmall;

export const CloseButtonContainer = styled(Clickable)`
  position: absolute;
  top: calc(${Spacing.HALF} + ${Spacing.EIGHTH});
  left: ${Spacing.HALF};
`;

export const Header = styled.div<{ largeHeader?: boolean }>`
  background: ${Colors.WHITE};
  border-bottom: ${Border.Base} ${Colors.GRAY_LITE_10};
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
  font-size: ${Font.Size.M};
  font-weight: ${Font.Weight.BOLD};
  height: ${ifProp('largeHeader', NAV_HEIGHT_LARGE, NAV_HEIGHT)};
  width: 100%;
  top: 0;

  & ${CloseButtonContainer} {
    top: calc(${Spacing.HALF} + ${ifProp('largeHeader', Spacing.HALF, Spacing.EIGHTH)});
  }

  ${Mixins.Media.maxWidthSmall(`
    display: flex;
    z-index: ${ZIndex.ConsumerNavBar + 10};
  `)}
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${Colors.WHITE};
  bottom: 0;
  border-top: ${Border.Base} ${Colors.GRAY_LITE_10};
  display: none;
  height: ${MODAL_BOTTOM_HEIGHT};
  justify-content: center;
  padding: ${Spacing.SINGLE};
  position: sticky;

  ${Mixins.Media.maxWidthSmall(`
    display: flex;
  `)}
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }
`;

export const BottomButton = styled(PrimaryButton)`
  border-radius: ${Border.Radius.M};
  padding-left: ${Spacing.DOUBLE};
  padding-right: ${Spacing.DOUBLE};
`;

export const ClearButton = styled(Clickable)`
  background: none;
  border: none;
  outline: none;
  padding: 0;
`;
