import { useDispatch } from 'src/store';
import { actions } from 'src/store/search';

export const usePageSizeActions = () => {
  const dispatch = useDispatch();

  return {
    changePageSize: (dishPageSize: number, shefPageSize: number) => {
      dispatch(actions.changePageSize({ dishPageSize, shefPageSize }));
    },
    changeDishPageSize: (pageSize: number) => {
      dispatch(actions.changeDishPageSize({ pageSize }));
    },
    changeShefPageSize: (pageSize: number) => {
      dispatch(actions.changeShefPageSize({ pageSize }));
    },
  };
};
