import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css, keyframes } from 'styled-components';
import { match } from 'ts-pattern';
import { Border, BorderRadius, Colors, Mixins, Spacing, Transition, ZIndex } from 'web-common/src/shared/styles';
import { DropdownSize } from '../consts';
import { getMenuPositionTransformStyle } from '../utils';

const overflowIndex = 10;

const menuIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.03) ${getMenuPositionTransformStyle({ flushToDropdown: false, shouldOpenDownwards: true })};
  }
  100% {
    opacity: 1;
    overflow-x: visible;
    overflow-y: scroll;
    transform: scale(1) ${getMenuPositionTransformStyle({ flushToDropdown: false, shouldOpenDownwards: true })};
  }
`;

const menuInFlushed = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.03) ${getMenuPositionTransformStyle({ flushToDropdown: true, shouldOpenDownwards: true })};
  }
  100% {
    opacity: 1;
    overflow-x: visible;
    overflow-y: scroll;
    transform: scale(1) ${getMenuPositionTransformStyle({ flushToDropdown: true, shouldOpenDownwards: true })};
  }
`;

const menuInUp = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.03) ${getMenuPositionTransformStyle({ flushToDropdown: false, shouldOpenDownwards: false })};
  }
  100% {
    opacity: 1;
    overflow-x: visible;
    overflow-y: scroll;
    transform: scale(1) ${getMenuPositionTransformStyle({ flushToDropdown: false, shouldOpenDownwards: false })};)}

;
}
`;

const menuInUpFlushed = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.03) ${getMenuPositionTransformStyle({ flushToDropdown: true, shouldOpenDownwards: false })};
  }
  100% {
    opacity: 1;
    overflow-x: visible;
    overflow-y: scroll;
    transform: scale(1) ${getMenuPositionTransformStyle({ flushToDropdown: true, shouldOpenDownwards: false })};
  }
`;

export const Wrapper = styled.div`
  flex: 1 1 100%;
  overflow: auto;
  overflow-x: visible;
`;

export const Container = styled.div<{
  alwaysOpenUpwards?: boolean;
  borderRadius?: BorderRadius;
  rounded?: boolean;
  size: DropdownSize;
}>`
  animation: 0.3s
    ${({ alwaysOpenUpwards, rounded }) =>
      match({ alwaysOpenUpwards: !!alwaysOpenUpwards, rounded: !!rounded })
        .with({ alwaysOpenUpwards: true, rounded: true }, () => menuInUp)
        .with({ alwaysOpenUpwards: true, rounded: false }, () => menuInUpFlushed)
        .with({ alwaysOpenUpwards: false, rounded: true }, () => menuIn)
        .otherwise(() => menuInFlushed)};
  box-sizing: border-box;
  border: 1px solid ${Colors.GRAY} !important;
  border-radius: ${({ borderRadius, rounded }) => {
    if (borderRadius) {
      return borderRadius;
    }
    return rounded ? Border.Radius.M : 0;
  }};
  display: flex;
  position: fixed;
  margin: -1px 0;
  z-index: ${ZIndex.ModalContent};

  ${ifProp(
    'rounded',
    css`
      & ${Wrapper} > div {
        &:first-child {
          ${Mixins.Border.setTopSide(Border.Radius.M)}
        }

        &:last-child {
          ${Mixins.Border.setBottomSide(Border.Radius.M)}
        }
      }
    `
  )};
`;

/** ************************************************************************** */
/* OVERFLOW SHADOWS                                                          */
/** ************************************************************************** */
const shadowDark = 'rgba(0,0,0,0.08)';
const shadowLight = 'transparent';

const OverflowShadow = styled.div<{ canScroll: boolean }>`
  left: 0;
  right: 0;
  height: ${Spacing.SINGLE};
  width: 100%;
  position: absolute;
  pointer-events: none;
  opacity: ${ifProp('canScroll', 1, 0)};
  transition: opacity ${Transition.Time.BASE};
  z-index: ${overflowIndex};
`;

export const OverflowShadowTop = styled(OverflowShadow)`
  top: 0;
  background: linear-gradient(to top, ${shadowLight}, ${shadowDark});
`;

export const OverflowShadowBottom = styled(OverflowShadow)`
  bottom: 0;
  background: linear-gradient(to bottom, ${shadowLight}, ${shadowDark});
`;
