import { omit } from 'lodash';
import React from 'react';
import { Image } from './styles';
import { DynamicImageProps } from './types';
import { generateThumborImgUrl } from './utils';

export const DynamicImage: React.FC<DynamicImageProps> = (props) => {
  const { height, width, sourceHeight, sourceWidth, src } = props;
  if (!src) {
    console.error('Dynamic image is missing path!');
    return null;
  }

  if (!sourceHeight && !sourceWidth) {
    console.error('Dynamic image is missing source size!');
    return null;
  }

  const imageHeightString = typeof height === 'number' ? `${height}px` : height;
  const imageWidthString = typeof width === 'number' ? `${width}px` : width;

  const imageSrc = generateThumborImgUrl({ src, width: sourceWidth, height: sourceHeight });

  return (
    <Image
      className={props.className ?? ''}
      {...omit(props, ['className', 'sourceHeight', 'sourceWidth', 'objectPosition'])}
      width={imageWidthString}
      height={imageHeightString}
      src={imageSrc}
      alt={props.alt}
      data-resized
      threshold={300}
    />
  );
};
