import { ClientTrackableEvents } from 'common/events/ClientEvents';
import { ISessionManager } from 'common/sessions';
import { JSONObj } from 'common/TypeUtilities';
import { CookieBackedSessionManager } from 'src/sessions/CookieBackedSessionManager';
import { ITracker, OptOut, UserInfo } from 'src/tracking/types';

export class MockTracker implements ITracker {
  constructor(private readonly sessionManager: ISessionManager = new CookieBackedSessionManager({})) {}

  public readonly browserTrackerId = 'MOCK_TRACKER_ID';

  init(): void {
    this.sessionManager.getOrCreateSession();
    console.debug('Using mock tracker [init] in react context');
  }

  setOptOutPreference(_preference: OptOut): void {
    console.debug('Using mock tracker [setOptOutPreference] in react context');
  }

  isUserOptedOut(): OptOut {
    console.debug('Using mock tracker [isUserOptedOut] in react context');
    return OptOut.UNKNOWN;
  }

  hasBeenIdentified(): boolean {
    console.debug('Using mock tracker [hasBeenIdentified] in react context');
    return false;
  }

  identify(_userInfo: Partial<UserInfo>): void {
    console.debug('Using mock tracker [identify] in react context');
  }

  updateIdentity(): void {
    console.debug('Using mock tracker [updateIdentity] in react context');
  }

  track<K extends keyof ClientTrackableEvents>(_event: K, _data: ClientTrackableEvents[K]): void {
    console.debug('Using mock tracker [track] in react context', _event, _data);
  }

  trackNavigation(args: { route: string; routeParams: JSONObj; fullPath: string; queryParams: JSONObj }): void {
    console.debug('Using mock tracker [trackNavigation] in react context', args);
  }

  updateAttributes(_attributes: Partial<UserInfo>): void {
    console.debug('Using mock tracker [updateAttributes] in react context', _attributes);
  }

  getZipCode(): string | null {
    console.debug('Using mock tracker [getZipCode] in react context');
    return null;
  }

  getUserId(): string | null {
    console.debug('Using mock tracker [getUserId] in react context');
    return null;
  }

  onLogout(): void {
    console.debug('Using mock tracker [onLogout] in react context');
  }
}
