import { getCustomizationSavingPercent, getPriceWithCustomizations, parseNumServings } from 'common/CustomizationUtils';
import { formatCurrency } from 'common/FormatUtilities';
import { isNil, sortBy, uniqBy } from 'lodash';
import { ChangeEventHandler } from 'react';
import {
  FoodItemCustomizationFieldsFragment,
  FoodItemCustomizationTypeFieldsFragment,
  PriceFactorType,
} from 'src/gqlReactTypings.generated.d';
import { Fieldset, FieldsetTag } from 'src/shared/design-system/Input/Fieldset/Fieldset';
import { FieldsetInput } from 'src/shared/design-system/Input/Fieldset/FieldsetInput';
import { DEFAULT_CUSTOMIZATION_ID } from '../../types';
import { CustomizationOption } from './CustomizationOption';
import { ServingSizeIcon } from './ServingSizeIcon';
import { ServingSizePill } from './ServingSizePill';

type ServingSize = Pick<FoodItemCustomizationFieldsFragment, 'id' | 'portion' | 'priceFactor' | 'priceFactorType'> & {
  customizationType: Pick<FoodItemCustomizationTypeFieldsFragment, 'name'>;
};

interface IServingSizeCustomizationsProps {
  servingSizeOptions?: ServingSize[];
  defaultPortion?: string | null;
  defaultServingSize?: string | null;
  defaultPrice: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string | undefined;
  disabled?: boolean;
}

export const ServingSizeCustomizations: React.FC<IServingSizeCustomizationsProps> = ({
  servingSizeOptions,
  defaultPortion,
  defaultServingSize,
  defaultPrice,
  onChange,
  value,
  disabled,
}) => {
  if (isNil(servingSizeOptions)) {
    return (
      <ServingSizePill
        portion={defaultPortion}
        servingSize={defaultServingSize}
        price={defaultPrice}
        disabled={disabled}
      />
    );
  }

  const parsedDefaultPortionSize = !isNil(defaultServingSize) ? parseNumServings(defaultServingSize) : 1;
  // Include the default
  const allServingSizeOptions = sortBy(
    uniqBy(
      [
        {
          id: DEFAULT_CUSTOMIZATION_ID,
          portion: defaultPortion,
          priceFactor: 0,
          priceFactorType: PriceFactorType.Nominal,
          customizationType: { name: defaultServingSize ?? undefined },
          parsedPortionSize: parsedDefaultPortionSize,
        },
        ...servingSizeOptions.map((servingSizeOption) => ({
          ...servingSizeOption,
          parsedPortionSize: parseNumServings(servingSizeOption.customizationType.name),
        })),
      ],
      'portion'
    ),
    'parsedPortionSize'
  );
  return (
    <div className='flex flex-col py-6'>
      <Fieldset label='Portion size' tag={FieldsetTag.REQUIRED} disabled={disabled}>
        {allServingSizeOptions.map((servingSizeOption) => {
          const percentSaved = Math.round(
            getCustomizationSavingPercent({
              defaultPrice,
              priceFactor: servingSizeOption.priceFactor,
              priceFactorType: servingSizeOption.priceFactorType,
              defaultNumServings: parsedDefaultPortionSize,
              numServings: servingSizeOption.parsedPortionSize,
            })
          );

          const percentSavedText = percentSaved > 0 ? `Save ${percentSaved}%` : undefined;

          return (
            <FieldsetInput
              checked={value === servingSizeOption.id}
              name={servingSizeOption.portion ?? undefined}
              onChange={onChange}
              type='radio'
              value={servingSizeOption.id}
              key={servingSizeOption.id}
              disabled={disabled}>
              <CustomizationOption
                icon={<ServingSizeIcon servingSize={servingSizeOption.customizationType.name} />}
                mainText={formatCurrency(
                  getPriceWithCustomizations(defaultPrice, [
                    {
                      priceFactor: servingSizeOption.priceFactor,
                      priceFactorType: servingSizeOption.priceFactorType,
                    },
                  ])
                )}
                subText={servingSizeOption.portion ?? undefined}
                highlightText={percentSavedText}
              />
            </FieldsetInput>
          );
        })}
      </Fieldset>
    </div>
  );
};
