import * as Border from './border';
import * as Flex from './flex';
import * as Input from './inputs';
import * as Margin from './margin';
import * as Media from './media';
import * as Padding from './padding';
import * as Scrollbar from './scrollbar';
import * as Shape from './shape';
import * as Spacing from './spacing';
import * as Text from './text';

export const Mixins = {
  Border,
  Flex,
  Input,
  Margin,
  Media,
  Padding,
  Scrollbar,
  Shape,
  Spacing,
  Text,
};
