import { navigate } from '@shef/native-bridge';
import { pathToRegexp } from 'path-to-regexp';
import { useEffect, useMemo } from 'react';
import { DispatchSendRequest } from 'src/mobile-app/hooks/types';
import { useHistory } from 'src/shared/hooks/useHistory';

export function useNativeAppNavigation(dispatchSendRequest: DispatchSendRequest, managedRoutes: readonly string[]) {
  const history = useHistory();
  const regexps = useMemo(() => managedRoutes.map((route) => pathToRegexp(route)), [managedRoutes]);

  useEffect(
    () =>
      history.block((location, action) => {
        for (const regexp of regexps) {
          if (regexp.test(location.pathname)) {
            dispatchSendRequest(navigate, { url: location.pathname, action });
            return false;
          }
        }
        // eslint-disable-next-line no-useless-return, consistent-return
        return;
      }),
    [dispatchSendRequest, history, regexps]
  );
}
