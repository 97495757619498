import React from 'react';
import {
  Arrow,
  AvatarImageWrapper,
  Container,
  ContentContainer,
  FollowIcon,
  FollowIconContainer,
  Header,
  HeaderContainer,
  Image,
  SubHeader,
} from './styles';

export interface AvatarCardProps {
  className?: string;
  children?: React.ReactNode;
  header?: React.ReactNode;
  headerAlt?: React.ReactNode;
  imageUrl?: string | null;
  isFollowing?: boolean;
  href?: string;
  onClick?: () => void;
  menuContent?: React.ReactNode;
  subHeader?: React.ReactNode;
  showArrow?: boolean;
  showShadow?: boolean;
}

export const Card: React.FC<AvatarCardProps> = (props) => {
  const {
    children,
    className,
    header,
    headerAlt,
    href,
    onClick,
    imageUrl,
    isFollowing,
    menuContent,
    showArrow = true,
    showShadow = true,
    subHeader,
  } = props;

  return (
    <Container className={className} href={href} onClick={onClick} showShadow={showShadow} nonClickable={!href}>
      {imageUrl && (
        <AvatarImageWrapper>
          {isFollowing && (
            <FollowIconContainer>
              <FollowIcon />
            </FollowIconContainer>
          )}
          <Image imageUrl={imageUrl || ''} />
        </AvatarImageWrapper>
      )}
      <ContentContainer>
        <HeaderContainer>
          {header && <Header>{header}</Header>}
          {headerAlt && <SubHeader>{headerAlt}</SubHeader>}
        </HeaderContainer>
        <SubHeader>{subHeader}</SubHeader>
        {children}
      </ContentContainer>
      {showArrow && href && <Arrow />}
      {menuContent}
    </Container>
  );
};

export { Image };
