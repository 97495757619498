import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './robert-sell.jpg';

export const RobertSell: PersonInfo = {
  firstName: 'Rob',
  lastName: 'Sell',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.ENGINEERING_MANAGER,
};
