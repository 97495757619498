export enum Spacing {
  NONE = '0px',
  EIGHTH = '2px',
  QUARTER = '4px',
  HALF = '8px',
  THREE_QUARTERS = '12px',
  SINGLE = '16px',
  SINGLE_QUARTER = '20px',
  SINGLE_HALF = '24px',
  SINGLE_THREE_QUARTERS = '28px',
  DOUBLE = '32px',
  DOUBLE_QUARTER = '36px',
  DOUBLE_HALF = '40px',
  DOUBLE_THREE_QUARTERS = '44px',
  TRIPLE = '48px',
  TRIPLE_HALF = '56px',
  QUADRUPLE = '64px',
  QUINTUPLE = '80px',
  OCTUPLE = '128px',
}

export const getSpacingMultiple = (multiple: number) => `${16 * multiple}px`;
