import { oneLine } from 'common-tags';
import { noOp } from 'common/Constants';
import lottie, {
  AnimationConfigWithData,
  AnimationDirection,
  AnimationEventCallback,
  AnimationEventName,
  AnimationItem,
} from 'lottie-web/build/player/lottie_light';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

export interface IStyleProps {
  className?: string;
  style?: React.CSSProperties;
  nudge?: [string, string];
}

export interface ILottieAnimationProps extends IStyleProps {
  animationData: AnimationConfigWithData['animationData'];
  play?: boolean;
  loop?: boolean;
  direction?: AnimationDirection;
  speed?: number;
  goToFrame?: number;
  onComplete?: AnimationEventCallback;
  onLoopComplete?: AnimationEventCallback;
  onEnterFrame?: AnimationEventCallback;
}

const LottieContainer = styled.div<{ nudge: [string, string] }>`
  svg {
    transform: translate3d(${({ nudge }) => [...nudge, '0'].join(', ')}) !important;
  }
`;

const useLottieEventListener = (
  animationRef: React.MutableRefObject<AnimationItem | undefined>,
  eventName: AnimationEventName,
  eventListener?: AnimationEventCallback
) => {
  useEffect(() => {
    const animation = animationRef.current;
    if (animation && eventListener) {
      animation.addEventListener(eventName, eventListener);
    }
  }, [animationRef, eventName, eventListener]);
};

export const LottieAnimation: React.FC<ILottieAnimationProps> = ({
  animationData,
  play = true,
  loop = true,
  direction = 1,
  speed = 1,
  goToFrame = null,
  onComplete,
  onLoopComplete,
  onEnterFrame,
  style = {},
  className = '',
  nudge = ['0', '0'],
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Prepare event listener cleanup to occur before the animation item is destroyed
  useEffect(() => () => animationRef.current?.removeEventListener('complete', onComplete), [onComplete]);
  useEffect(() => () => animationRef.current?.removeEventListener('loopComplete', onLoopComplete), [onLoopComplete]);
  useEffect(() => () => animationRef.current?.removeEventListener('enterFrame', onEnterFrame), [onEnterFrame]);

  // Load animation item and destroy on unmount
  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      console.error(oneLine`
        A required container ref does not exist in the lottie animation effect.
        The page loading animation is probably broken until this is fixed.
      `);

      return noOp;
    }

    const animation = lottie.loadAnimation({
      animationData,
      container,
      loop: false,
      autoplay: false,
      renderer: 'svg',
      rendererSettings: { preserveAspectRatio: 'xMinYMin' },
    });

    const onDomLoaded = () => {
      setIsLoaded(true);
    };

    animation.addEventListener('DOMLoaded', onDomLoaded);
    animationRef.current = animation;

    return () => {
      animation.removeEventListener('DOMLoaded', onDomLoaded);
      setIsLoaded(false);
      animation.destroy();
      animationRef.current = undefined;
    };
  }, [animationData]);

  // Add event listeners to loaded animation item
  useLottieEventListener(animationRef, 'complete', onComplete);
  useLottieEventListener(animationRef, 'loopComplete', onLoopComplete);
  useLottieEventListener(animationRef, 'enterFrame', onEnterFrame);

  // Handle loop prop
  useEffect(() => {
    const animation = animationRef.current;
    if (animation && isLoaded) {
      animation.loop = loop;
    }
  }, [isLoaded, loop]);

  // Handle direction prop
  useEffect(() => {
    const animation = animationRef.current;
    if (animation && isLoaded) {
      animation.setDirection(direction);
    }
  }, [isLoaded, direction]);

  // Handle speed prop
  useEffect(() => {
    const animation = animationRef.current;
    if (animation && isLoaded) {
      animation.setSpeed(speed);
    }
  }, [isLoaded, speed]);

  // Handle play state manually so that playback can start in reverse (props.direction = -1)
  // TODO: If we ever implement segments support, this effect should be updated to handle them
  useEffect(() => {
    const animation = animationRef.current;
    if (!animation || !isLoaded) {
      return;
    }

    if (play) {
      const isFrame = true;
      if (animation.playDirection === -1) {
        const endFrame = animation.getDuration(isFrame);
        animation.goToAndPlay(endFrame, isFrame);
      } else {
        animation.play();
      }
    } else {
      animation.pause();
    }
  }, [isLoaded, play]);

  // Go to a specific frame
  useEffect(() => {
    const animation = animationRef.current;
    if (!animation || !isLoaded || goToFrame === null) {
      return;
    }

    const isFrame = true;
    if (play) {
      animation.goToAndPlay(goToFrame, isFrame);
    } else {
      animation.goToAndStop(goToFrame, isFrame);
    }
  }, [isLoaded, goToFrame, play]);

  return <LottieContainer ref={containerRef} style={style} nudge={nudge} className={className} />;
};
