import { noOp } from 'common/Constants';
import React, { useCallback } from 'react';
import { ToggleButtonProps } from '../../types';
import { ButtonContainer, ButtonIconContainer, ButtonText, ButtonWrapper } from './styles';

export const Button: React.FC<ToggleButtonProps> = (props) => {
  const {
    dataTest,
    disabled,
    icon,
    inlineIcon,
    label,
    onChange = noOp,
    prependContent,
    selectedColor,
    selectedColorHighlight,
    size,
    value,
  } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!disabled) {
        onChange(!value, e);
      }
    },
    [disabled, onChange, value]
  );

  return (
    <ButtonContainer
      data-cy={dataTest}
      data-selected={value ?? false}
      disabled={disabled}
      inlineIcon={inlineIcon}
      isSelected={value ?? false}
      onClick={handleClick}
      selectedColor={selectedColor}
      selectedColorHighlight={selectedColorHighlight}
      size={size}>
      {prependContent}
      {(label || icon) && (
        <ButtonWrapper>
          {icon && <ButtonIconContainer>{icon}</ButtonIconContainer>}
          {label && <ButtonText>{label}</ButtonText>}
        </ButtonWrapper>
      )}
    </ButtonContainer>
  );
};
