export enum StartDirection {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
}

export type RadialProps = {
  backgroundColor?: string;
  children?: React.ReactNode;
  className?: string;
  fillColor?: string;
  size?: number;
  startDirection?: StartDirection;
  strokeWidth?: number;
  percent?: number;
};
