import React from 'react';
import closeDarkGreySmallIcon from 'src/assets/svg/design-library/close-dark-grey-small.svg';
import closeWhiteSmallIcon from 'src/assets/svg/design-library/close-white-small.svg';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { BoxShadow, Colors, Font, Mixins, Spacing, ZIndex } from 'web-common/src/shared/styles';

export enum ModalSizeEnum {
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export type ModalSize = 'md' | 'lg' | 'xl' | 'xxl';

const getModalSize = (size: ModalSize): React.CSSProperties['width'] =>
  ({
    md: '365px',
    lg: '450px',
    xl: '600px',
    xxl: '750px',
  }[size]);

export const ModalContent = styled.div<{
  size?: ModalSize;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  isRounded?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: ${Font.Family.SANS};
  background: ${Colors.WHITE};
  box-shadow: ${BoxShadow.MODAL};
  box-sizing: border-box;
  z-index: ${ZIndex.ModalContent};
  padding: ${Spacing.SINGLE_HALF};
  row-gap: 20px;

  border-radius: ${({ isFullWidth, isFullHeight, isRounded }) =>
    isFullWidth || isFullHeight || !isRounded ? '0' : '16px'};

  ${ifProp(
    'isFullWidth',
    css`
      width: 100% !important;
      max-width: 100% !important;
    `,
    ({ size = 'md' }) => css`
      width: ${getModalSize(size)};
      max-width: calc(100% - calc(20px));
    `
  )}
  ${ifProp(
    'isFullHeight',
    css`
      height: 100% !important;
      max-height: 100% !important;
    `
  )}
`;

export const ModalHeader = styled.div`
  display: flex;
  flex: 0 1 0%;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: bold;
`;

export const ModalSubHeader = styled.div`
  display: flex;
  flex: 0 1 0%;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: normal;
`;

export const ModalBody = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
`;

export const ModalFooter = styled.div`
  ${Mixins.Flex.center()}
`;

export const ModalFooterButtonContainer = styled.div<{ stretchButtons?: boolean }>`
  ${Mixins.Flex.center()}
  gap: ${Spacing.SINGLE};

  ${ifProp(
    'stretchButtons',
    css`
      & > * {
        flex: 1;
      }
    `
  )}
`;

const CloseButton = styled.button<{ isCloseOnLeft?: boolean }>`
  background: none;
  border: none;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  ${ifProp(
    'isCloseOnLeft',
    css`
      left: 15px;
    `,
    css`
      right: 15px;
    `
  )}
  padding: 0;

  & > img {
    width: 50%;
    height: 50%;
  }
`;

interface ModalCloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'dark-grey' | 'white';
  isCloseOnLeft?: boolean;
}

const ModalCloseButtonFC: React.FC<ModalCloseButtonProps> = ({ color = 'dark-grey', ...props }) => (
  <CloseButton {...props}>
    <img src={color === 'dark-grey' ? closeDarkGreySmallIcon : closeWhiteSmallIcon} alt='close' />
  </CloseButton>
);

// Rewrap with styled to support styled-components' "component selector" pattern
export const ModalCloseButton = styled(ModalCloseButtonFC)``;
