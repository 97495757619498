import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import { Colors, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';

export const getPointSize = (height?: Spacing | string) => `calc(${height ?? Spacing.QUARTER} * 4)`;

export const Container = styled.div`
  width: 100%;
  ${Mixins.Flex.centerColumn()}
`;

export const PointHighlight = styled.div`
  position: absolute;
  pointer-events: none;
  transition: transform ${Transition.Time.BASE}, opacity ${Transition.Time.DOUBLE};
`;

export const PointContent = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;

  ${Mixins.Flex.center()}
`;

export const Point = styled.div<{
  backgroundColor?: Colors;
  highlightColor?: Colors;
  highlighted?: boolean;
  height?: Spacing | string;
}>`
  position: absolute;
  transform: translate(-50%, -50%);
  transition: background ${Transition.Time.BASE};
  z-index: 0;

  ${Mixins.Flex.center()}

  ${({ backgroundColor, highlightColor, highlighted, height }) => css`
    background: ${highlighted ? highlightColor ?? Colors.BELL_PEPPER : backgroundColor ?? Colors.GRAY_LITE_20};

    &,
    & ${PointHighlight}, & + ${PointContent} {
      border-radius: 100%;
      height: ${getPointSize(height)};
      width: ${getPointSize(height)};
    }

    &,
    & + ${PointContent} {
      top: calc(${height ?? Spacing.QUARTER} / 2);
    }

    & ${PointHighlight} {
      background: ${highlightColor ?? Colors.BELL_PEPPER};
      opacity: ${highlighted ? '0' : '1'};
      transform: scale(${highlighted ? '1.4' : '0'});
    }
  `};
`;

export const Background = styled.div<{ color?: Colors | string; height?: Spacing | string }>`
  position: relative;
  width: 100%;

  ${({ color, height }) => css`
    background: ${color ?? Colors.GRAY_LITE_20};
    height: ${height ?? Spacing.QUARTER};
    border-radius: ${height ?? Spacing.QUARTER};
  `};
`;

export const BarGlow = styled.div<{ show?: boolean }>`
  position: absolute;
  right: 0;
  background: ${Colors.WHITE};
  box-shadow: 0 0 ${Spacing.QUARTER} ${Spacing.QUARTER} ${Colors.AQUA_LITE_30},
    0 0 ${Spacing.SINGLE} ${Spacing.QUARTER} rgba(0, 0, 0, 0.2);
  transform: translateX(50%);
  ${({ show = false }) => css`
    opacity: ${show ? 1 : 0};
    transition: opacity ${show ? Transition.Time.BASE : Transition.Time.DOUBLE} ${show ? '0s' : Transition.Time.DOUBLE};
  `};
`;

export const Bar = styled(animated.div)<{ color?: Colors | string; height?: Spacing | string }>`
  position: relative;
  height: 100%;
  z-index: 1;

  ${({ color, height }) => css`
    background: ${color ?? Colors.BELL_PEPPER};
    height: ${height ?? Spacing.QUARTER};
    border-radius: ${height ?? Spacing.QUARTER};

    & ${BarGlow} {
      ${Mixins.Shape.circle(height ?? Spacing.QUARTER)}
    }
  `};
`;
