import { isEqual } from 'lodash';
import { convertAddressSelectorAddressToAddressInput } from 'src/address/AddressUtilities';
import { GenericAddressFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { ShefProfileBaseState, ShefProfileInputState, ShefProfileState } from './types';

export const checkIfChangedState = (inputState: ShefProfileBaseState, originalState: ShefProfileBaseState) => {
  const inputKeys = Object.keys(inputState);
  return inputKeys.some((key) => !isEqual(inputState[key], originalState[key]));
};

export const getUpdatedInputState = (
  state: ShefProfileState,
  inputState: { [K in keyof ShefProfileInputState]: ShefProfileInputState[K] }
) => ({
  ...state,
  inputState: { ...state.inputState, ...inputState },
});

export const formatShefAddress = (shefAddress?: GenericAddressFieldsFragment | null) =>
  shefAddress ? convertAddressSelectorAddressToAddressInput(shefAddress) : null;
