import { noOp } from 'common/Constants';
import { DateString, dateStringToDateTime, formatDateTimeUrlDate } from 'common/UrlDate';
import { DateTime } from 'luxon';
import React, { useRef } from 'react';
import { Dropdown, DropdownSize } from 'src/shared/design-system/Dropdown';
import styled from 'styled-components';
import { Border, Mixins, Spacing } from 'web-common/src/shared/styles';

export const DROPDOWN_BOTTOM_SCREEN_OFFSET = 300;

export const DeliveryDropdown = styled(Dropdown).attrs(() => ({
  size: DropdownSize.M,
}))`
  & input {
    border-radius: ${Border.Radius.M};
    height: ${Spacing.DOUBLE_HALF};
  }
  & [data-role='dropdown-menu'] {
    border-radius: ${Border.Radius.M};
    overflow: hidden;
  }
  & [data-role='dropdown-menu-container'] {
    & div:first-child {
      ${Mixins.Border.setTopSide(Border.Radius.M)}
    }
    & div:last-child {
      ${Mixins.Border.setBottomSide(Border.Radius.M)}
    }
  }
`;

type DeliveryType = {
  availableDates?: DateString[];
  onChangeDeliveryDate?: (date: string) => void;
  value?: string;
};

export const Delivery: React.FC<DeliveryType> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  //
  // Calculate initial dropdown position in modal. Baed on DROPDOWN_BOTTOM_SCREEN_OFFSET,
  // Determines if the dropdown should open upwards if close to bototm of screen.
  const dropdownPosition = containerRef.current?.getBoundingClientRect().top ?? 0;
  const alwaysOpenUpwards = dropdownPosition > window.innerHeight - DROPDOWN_BOTTOM_SCREEN_OFFSET;

  const { availableDates = [], onChangeDeliveryDate = noOp, value = '' } = props;
  const localTimeZone = DateTime.local().zoneName;
  const items = availableDates.map((dateString) => {
    const dateTime = dateStringToDateTime(dateString, localTimeZone);
    return {
      label: `${dateTime.weekdayShort}, ${dateTime.monthShort} ${dateTime.day}`,
      value: formatDateTimeUrlDate(dateTime),
    };
  });

  return (
    <div ref={containerRef}>
      <DeliveryDropdown
        alwaysOpenUpwards={alwaysOpenUpwards}
        disabled={availableDates.length === 0}
        items={items}
        onChange={onChangeDeliveryDate}
        value={value}
      />
    </div>
  );
};
