import { ClientEvents, LoginModalDismissElements } from 'common/events/ClientEvents';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { ModalAnimation } from 'src/shared/design-system/Modal/utils';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useTracker } from 'src/shared/hooks/useTracker';
import { Modal, ModalType } from 'web-common/src/shared/design-system/components/Modal';
import { LoginOrSignUpModalContent } from './LoginOrSignUpModalContent';
import { useShowLoginOrSignUpModal } from './useShowLoginOrSignUpModal';

interface LoginOrSignUpModalProps {
  hide: () => void;
  isShowing: boolean;
  animation?: ModalAnimation;
}

export const LoginOrSignUpModal: React.FC<LoginOrSignUpModalProps> = ({ isShowing, hide }) => {
  const [currentUser, currentUserIsLoading] = useCurrentUser();
  const tracker = useTracker();
  const { isClosable } = useShowLoginOrSignUpModal();
  const { signUpEntryPoint } = useShowLoginOrSignUpModal();

  useEffect(() => {
    if (isShowing) {
      tracker.track(ClientEvents.LOGIN_MODAL_OPENED, { signUpEntryPoint });
    }
  }, [isShowing, tracker, signUpEntryPoint]);

  const handleClose = (element: LoginModalDismissElements) => {
    hide();
    tracker.track(ClientEvents.LOGIN_MODAL_DISMISSED, { signUpEntryPoint, element });
  };

  return (
    <Modal isShowing={isShowing && !currentUserIsLoading && isNil(currentUser)} modalType={ModalType.PEEK_A_BOO}>
      <div className='max-h-screen overflow-y-auto'>
        <LoginOrSignUpModalContent showClose={isClosable} onCloseButtonClicked={handleClose} stickyHeader />
      </div>
    </Modal>
  );
};
