import { enforceExhaustive } from 'common/EnumUtils';
import React, { useMemo } from 'react';
import { matchPath } from 'react-router';
import { NewUserEducationModalHelpButton } from 'src/new-user-education-modal/NewUserEducationModalHelpButton';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useLocation } from 'src/shared/hooks/useLocation';
import { AdminRoutes, ConsumerSubscriptionRoutes, PhotoEditorRouter, Routes, ShefRoutes } from 'src/shared/Routes';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { UserPreferences } from 'src/user-preferences/userPreferencesTypes';
import { useUserPreferences } from 'src/user-preferences/useUserPreferences';
import { AppUpsellBanner } from '../AppUpsellBanner';
import { useHelpButtonState } from './helpButtonState';
import { useAppUpsellBannerState } from './useAppUpsellBannerState';

// These are the routes we hide the widget on
const HIDDEN_ROUTES = [
  Routes.CONSUMER_ORDER_CART,
  Routes.CONSUMER_ORDER_EDIT_CART,
  Routes.CONSUMER_ORDER_ONE_TIME,
  Routes.CONSUMER_ORDER_FINALIZE,
  Routes.CONSUMER_ORDER_FOOD_ITEM,
  Routes.CONSUMER_LANDING,
  Routes.CONSUMER_REGISTER,
  ...ConsumerSubscriptionRoutes,
  ...Object.values(AdminRoutes),
  ...Object.values(PhotoEditorRouter),
  Routes.SHEF_APPLICATION,
  Routes.SHEF_APPLICATION_STEP,
  Routes.SHEF_CHECKLIST,
  Routes.WAITLIST_HOME,
  Routes.WAITLIST_ACTIONS,
] as const;

const EDUCATION_HIDDEN_ROUTE = [
  ...Object.values(ShefRoutes),
  Routes.CONSUMER_BECOME_A_SHEF,
  Routes.CONSUMER_BECOME_A_SHEF_REGISTER,
];

enum ButtonType {
  HIDDEN,
  NEW_USER_EDUCATION,
  APP_UPSELL,
}

export const HelpButton: React.FC = () => {
  const location = useLocation();
  const [currentUser, isLoading] = useCurrentUser();
  const [preferences] = useUserPreferences();
  const isSubscriptionOnlyUser = preferences[UserPreferences.SUBSCRIPTION_VISIBLE_ONLY];
  const { helpButtonIsVisible, hasSeenNewUserEducationModal } = useHelpButtonState();

  const isExplorePage = locationInRoutes(location, [Routes.CONSUMER_EXPLORE]);
  const { canShowAppUpsellBanner, onDismiss: onAppUpsellDismiss, openAppListing } = useAppUpsellBannerState();
  const canShowAppUpsell = canShowAppUpsellBanner && isExplorePage;

  const isHidden = useMemo(
    () => isSubscriptionOnlyUser || HIDDEN_ROUTES.some((path) => matchPath(location.pathname, { path, exact: true })),
    [isSubscriptionOnlyUser, location.pathname]
  );

  const isEducationButtonHidden = useMemo(
    () => EDUCATION_HIDDEN_ROUTE.some((path) => matchPath(location.pathname, { path, exact: true })),
    [location.pathname]
  );

  const buttonType: ButtonType = useMemo(() => {
    if (isLoading || !helpButtonIsVisible || isHidden) {
      return ButtonType.HIDDEN;
    }

    if (canShowAppUpsell) {
      return ButtonType.APP_UPSELL;
    }

    if (!hasSeenNewUserEducationModal && !currentUser && !isEducationButtonHidden) {
      return ButtonType.NEW_USER_EDUCATION;
    }

    return ButtonType.HIDDEN;
  }, [
    canShowAppUpsell,
    currentUser,
    hasSeenNewUserEducationModal,
    helpButtonIsVisible,
    isHidden,
    isLoading,
    isEducationButtonHidden,
  ]);

  switch (buttonType) {
    case ButtonType.HIDDEN:
      return null;

    case ButtonType.NEW_USER_EDUCATION:
      return <NewUserEducationModalHelpButton />;

    case ButtonType.APP_UPSELL:
      return <AppUpsellBanner onClick={openAppListing} onClose={onAppUpsellDismiss} />;

    default:
      throw enforceExhaustive(buttonType);
  }
};
