import { Modal, ModalBody } from 'src/shared/design-system/Modal';
import { WavyEdge } from 'src/shared/design-system/WavyEdge';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { Border, Colors, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';

export const Main = styled(Modal).attrs(() => ({ size: 'lg' }))`
  position: relative;
  display: block;
  padding: 0;
  border-radius: ${Border.Radius.L};
  overflow: hidden;
  width: 480px;
  background: ${Colors.WHITE};
  z-index: 2000;
  ${Mixins.Media.maxWidthSmall(css`
    border-radius: 0;
  `)}
`;

export const Wrapper = styled.div`
  max-height: calc(100dvh - ${Spacing.QUADRUPLE});
  overflow: auto;

  ${Mixins.Media.maxWidthSmall(css`
    min-height: 100dvh;
    max-height: 100%;
  `)}

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div<{ isDocked?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: ${Spacing.SINGLE};
  background: ${ifProp('isDocked', Colors.WHITE, 'transparent')};
  border-bottom: 1px solid;
  border-bottom-color: ${ifProp('isDocked', Colors.GRAY_LITE_10, 'transparent')};
  top: 0;
  width: 100%;
  z-index: 10;
  min-height: ${Spacing.TRIPLE};
  transition: background ${Transition.Time.BASE} border ${Transition.Time.BASE};

  & img {
    pointer-events: none;
  }

  ${ifProp(
    'isDocked',
    css`
      & button,
      & a {
        box-shadow: none !important;
        background: ${Colors.WHITE} !important;
      }
    `
  )}
`;

export const Body = styled(ModalBody)`
  background: ${Colors.WHITE};
  position: relative;
  padding: ${Spacing.SINGLE} ${Spacing.SINGLE_HALF};
  overflow: visible;

  &:before {
    content: '';
    position: absolute;
    top: -${Spacing.THREE_QUARTERS};
    left: 0;
    right: 0;
    height: ${Spacing.THREE_QUARTERS};
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
    pointer-events: none;
  }
`;

export const TopBodyEdge = styled(WavyEdge).attrs(() => ({
  position: WavyEdge.Position.BOTTOM,
}))`
  top: -${Spacing.QUARTER};
  bottom: unset;
  left: 0;
  overflow: hidden;
  & svg {
    transform: translate3d(0, 0, 0);
  }
`;

export const Divider = styled.div`
  background: ${Colors.GRAY_LITE_20};
  margin: ${Spacing.SINGLE_HALF} 0;
  height: 1px;
  width: 100%;
`;

export const Spacer = styled.div`
  height: ${Spacing.SINGLE};
  width: 100%;
`;

// margin-top and bottom to get section borders to work nicely
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid ${Colors.GRAY_LITE_20};
  border-top: 1px solid ${Colors.GRAY_LITE_20};

  margin-top: -0.5px;
  margin-bottom: -0.5px;
`;

export const GraySectionContainer = styled(SectionContainer)`
  margin-left: -${Spacing.SINGLE_HALF};
  margin-right: -${Spacing.SINGLE_HALF};
  padding-left: ${Spacing.SINGLE_HALF};
  padding-right: ${Spacing.SINGLE_HALF};
  background: ${Colors.GRAY_LITE_30};
  z-index: 1;
`;
