import { setToken } from '../storage/Storage';

/**
 * Initializes the API token for the consumer app.
 */
export const initTokenForApp = () => {
  if (window.App?.token) {
    setToken(window.App.token);
  }
};
