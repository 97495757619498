import * as Sentry from '@sentry/react';
import { UserIdentifierType } from 'common/analytics-event-manager/IAnalyticsEventManager';
import { ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { IGatedRollout } from 'common/gated-rollout/GatedRollout';
import { StorageKeys } from 'common/storage/constants';
import { fromPairs } from 'lodash';
import { useEffect } from 'react';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { LocalExperimentContext } from './ExperimentContext';

interface UseExperimentReportersParams {
  expContext: LocalExperimentContext;
  variantOverrides: any;
  userId: string | undefined;
  deviceId: string;
  gatedRollout: IGatedRollout;
}
export const useExperimentReporters = ({
  expContext,
  variantOverrides,
  userId,
  deviceId,
  gatedRollout,
}: UseExperimentReportersParams) => {
  useEffect(() => {
    const experimentStatusPairs = expContext
      .getAllExperiments()
      // filter out user based experiments is user is not signed in
      .filter(({ userIdentifierType }) => userId || userIdentifierType !== UserIdentifierType.USER_ID)
      .map<[string, string]>(({ name }) => {
        const expName = name as ExperimentNames;
        const variant = expContext.getVariant(expName, false);
        const variantName = variant?.name ?? 'null';
        return [expName, variantName];
      });
    const experimentStatuses = fromPairs(experimentStatusPairs);

    // write cache of values to local storage with is read from script in index.html
    const contextCache = JSON.stringify(experimentStatuses);
    getBrowserStorage().setItem(StorageKeys.DATADOG_EXPERIMENT_CONTEXT, contextCache);

    // datadog - sync as feature flags
    const existingDdContext = window.DD_RUM?.getGlobalContext();
    window.DD_RUM?.setGlobalContext({ ...existingDdContext, exp: experimentStatuses });

    // sentry - sync as tags
    experimentStatusPairs.forEach(([expName, variantName]) => {
      Sentry.setTag(`exp.${expName}`, variantName);
    });
  }, [variantOverrides, expContext, userId, gatedRollout, deviceId]);
};
