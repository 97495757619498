import { createSlice } from '@reduxjs/toolkit';
import { initialHelpButtonState } from './initialState';
import { hideHelpButton, setHasSeenNewUserEducationModal, showHelpButton } from './reducers';
import { HelpButtonState } from './types';

const createHelpButtonSlice = (name: string, initialState: HelpButtonState) =>
  createSlice({
    name,
    initialState,
    reducers: {
      showHelpButton,
      hideHelpButton,
      setHasSeenNewUserEducationModal,
    },
  });

export const helpButtonSlice = createHelpButtonSlice('helpButton', initialHelpButtonState);
