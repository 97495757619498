import { setZipcode } from '@shef/native-bridge';
import { StorageKeys } from 'common/storage/constants';
import { NativeAppMessenger } from 'src/mobile-app/NativeAppMessenger';
import { getBrowserStorage } from 'src/shared/storage/Storage';
import { IUserPreferences, IUserPreferencesState, UserPreferences } from './userPreferencesTypes';

const CURRENT_SERIALIZED_VERSION = 1;

type SerializedPrefs<Version extends number = typeof CURRENT_SERIALIZED_VERSION> = {
  version: Version;
  preferences: IUserPreferences;
};

function isSerializedPrefs<V extends number>(o: any, version: V): o is SerializedPrefs<V> {
  return typeof o === 'object' && o.version === version;
}

export function loadPrefsFromStorage(): IUserPreferences {
  const storage = getBrowserStorage();
  const raw = storage.getItem(StorageKeys.PREFERENCES);

  // Get default value from the previously stored `consumerZipCode`
  const oldStoredZip = storage.getItem(StorageKeys.ZIPCODE);

  const defaults: IUserPreferences = {
    [UserPreferences.ZIPCODE]: oldStoredZip ? JSON.parse(oldStoredZip) : null,
    [UserPreferences.EXPLORE_DELIVERY_DATES]: null,
    [UserPreferences.MOST_RECENTLY_SELECTED_ADDRESS]: null,
    [UserPreferences.UTM]: null,
    [UserPreferences.USER_REFERRED_FOR_SUBSCRIPTION]: null,
    [UserPreferences.SUBSCRIPTION_VISIBLE_ONLY]: null,
  };

  if (raw === null) {
    return defaults;
  }

  try {
    const parsed = JSON.parse(raw);
    return isSerializedPrefs(parsed, CURRENT_SERIALIZED_VERSION)
      ? {
          ...defaults,
          ...parsed.preferences,
        }
      : defaults;
  } catch (err) {
    console.error('Failed to parse stored prefs');
    return defaults;
  }
}

export function savePrefsToStorage(preferences: IUserPreferences) {
  const storage = getBrowserStorage();
  storage.setItem(StorageKeys.PREFERENCES, JSON.stringify({ version: CURRENT_SERIALIZED_VERSION, preferences }));
}

export const initialUserPreferencesState: IUserPreferencesState = {
  preferences: loadPrefsFromStorage(),
};

export function sendPrefToApp<K extends keyof IUserPreferences>(
  nativeAppMessenger: NativeAppMessenger,
  key: K,
  value: IUserPreferences[K]
) {
  switch (key) {
    case UserPreferences.ZIPCODE:
      if (typeof value === 'string') {
        nativeAppMessenger.sendRequest(setZipcode, { zipcode: value }).catch(console.error);
      }
      break;
    default:
      break;
  }
}
