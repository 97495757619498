import { StorageKeys } from 'common/storage/constants';
import { useCallback, useEffect } from 'react';
import { UseCachedZipCodeQuery, useUseCachedZipCodeQuery } from 'src/gqlReactTypings.generated.d';
import { useLocalStorage } from 'src/shared/storage/Storage';
import { UserPreferences } from '../userPreferencesTypes';
import { useUserPreferences } from '../useUserPreferences';

type UseCachedZipCodeHook = [
  cachedZipCode: string | null,
  setCachedZipCode: (zipCode: string | null) => void,
  zipCodeMetaData: {
    cachedZipCodeData: UseCachedZipCodeQuery | undefined;
    cachedZipCodeDataLoading: boolean;
    minimumDiscountedShippingAmount: number | null;
    discountedDeliveryFee: number;
    deliveryFee: number;
  }
];

export const useCachedZipCode = (): UseCachedZipCodeHook => {
  const [{ [UserPreferences.ZIPCODE]: cachedZipCode }, setPreference] = useUserPreferences();
  const [minimumDiscountedShippingAmount, setMinimumShippingAmount] = useLocalStorage<number | null>(
    StorageKeys.RECENT_MINIMUM_ORDER_AMOUNT_FREE_SHIPPING,
    null // Should never be seen though....
  );
  const [discountedDeliveryFee, setDiscountDeliveryFee] = useLocalStorage<number>(
    StorageKeys.RECENT_DISCOUNTED_DELIVERY_FEE,
    0 // Should never be seen though....
  );
  const [deliveryFee, setDeliveryFee] = useLocalStorage<number>(
    StorageKeys.RECENT_DELIVERY_FEE,
    0 // Should never be seen though....
  );
  const { data: cachedZipCodeData, loading: cachedZipCodeDataLoading } = useUseCachedZipCodeQuery({
    variables: {
      zipcode: cachedZipCode ?? '',
    },
    skip: !cachedZipCode,
    fetchPolicy: 'cache-first',
  });

  const setCachedZipCode = useCallback(
    (zipCode: string | null) => {
      if (!zipCode || cachedZipCode === zipCode) {
        return;
      }
      setPreference(UserPreferences.ZIPCODE, zipCode);
    },
    [cachedZipCode, setPreference]
  );

  // This is here to prevent quickly changing text on the page
  useEffect(() => {
    if (cachedZipCodeDataLoading || !cachedZipCodeData?.zipCode) {
      return;
    }

    if (cachedZipCodeData.zipCode.minimumAmountForDiscountedShipping !== minimumDiscountedShippingAmount) {
      setMinimumShippingAmount(cachedZipCodeData.zipCode.minimumAmountForDiscountedShipping ?? null);
    }

    if (cachedZipCodeData.zipCode.discountedDeliveryFee !== discountedDeliveryFee) {
      setDiscountDeliveryFee(cachedZipCodeData.zipCode.discountedDeliveryFee);
    }
    if (cachedZipCodeData.zipCode.deliveryFee && cachedZipCodeData.zipCode.deliveryFee !== deliveryFee) {
      setDeliveryFee(cachedZipCodeData.zipCode.deliveryFee);
    }
  }, [
    cachedZipCodeDataLoading,
    cachedZipCodeData,
    minimumDiscountedShippingAmount,
    setMinimumShippingAmount,
    setPreference,
    discountedDeliveryFee,
    setDiscountDeliveryFee,
    setPreference,
    deliveryFee,
    setDeliveryFee,
  ]);

  const zipCodeMetaData = {
    cachedZipCodeData,
    cachedZipCodeDataLoading,
    minimumDiscountedShippingAmount,
    discountedDeliveryFee,
    deliveryFee,
  };

  return [cachedZipCode, setCachedZipCode, zipCodeMetaData];
};
