import styled from 'styled-components';
import { SVG } from 'web-common/src/shared/design-system/Utilities';
import { Colors, Transition } from 'web-common/src/shared/styles';
import { DEFAULT_STROKE_WIDTH, START_DIRECTION_DEGREE } from './consts';
import { StartDirection } from './types';

export const Container = styled(SVG)`
  stroke-width: ${DEFAULT_STROKE_WIDTH};
`;

export const Bar = styled.circle<{ strokeColor?: string; strokeWidth?: number }>`
  transform-origin: center;
  fill: none;
  stroke: ${({ strokeColor = Colors.GRAY }) => strokeColor};
  stroke-width: ${({ strokeWidth = DEFAULT_STROKE_WIDTH }) => strokeWidth}px;
`;

export const BarFill = styled.circle<{ startDirection?: StartDirection; strokeColor?: string }>`
  fill: none;
  stroke-linecap: butt;
  stroke: ${({ strokeColor = Colors.KOHLRABI }) => strokeColor};
  stroke-width: ${({ strokeWidth = DEFAULT_STROKE_WIDTH }) => strokeWidth}px;
  transform-origin: center;
  transform: rotate(${({ startDirection = StartDirection.RIGHT }) => START_DIRECTION_DEGREE[startDirection]}deg);
  transition: transform ${Transition.Time.BASE};
`;
