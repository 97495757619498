import React from 'react';
import { CheckmarkCircle, Exclamation, InfoCircle } from 'src/shared/design-system/Icon';
import { ToastState } from './consts';
import { Container, IconContainer, Wrapper } from './styles';

type ToastContentProps = {
  toastState?: ToastState;
  icon?: React.ReactNode;
};

export const iconObj = {
  [ToastState.INFO]: InfoCircle,
  [ToastState.SUCCESS]: CheckmarkCircle,
  [ToastState.WARNING]: Exclamation,
  [ToastState.ERROR]: Exclamation,
};

export const ToastContent: React.FC<React.PropsWithChildren<ToastContentProps>> = (props) => {
  const { children, toastState = ToastState.INFO, icon } = props;
  const Icon = iconObj[toastState];
  return (
    <Container>
      <IconContainer>{icon ?? <Icon />}</IconContainer>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};
