export enum ScreenWidthKeys {
  EXTRA_EXTRA_SMALL = 'EXTRA_EXTRA_SMALL',
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL', // Max width for mobile devices
  MEDIUM = 'MEDIUM', // Max width for small laptops and horizontal tablets
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
}

/**
 * Enum used for representing the different screen widths that we support and their breakpoints.
 * Each value represents the MAX WIDTH for the given screen size.
 *
 * WARNING WHEN MODIFYING THESE VALUES:
 *   These MUST always match the values in constants.scss.
 *   These MUST always be ordered from SMALL to LARGE. Do not break this assumption or getNearestScreenWidth WILL FAIL!
 */
export enum ScreenWidth {
  EXTRA_EXTRA_SMALL = '376px',
  EXTRA_SMALL = '576px',
  SMALL = '768px', // Max width for mobile devices
  MEDIUM = '992px', // Max width for small laptops and horizontal tablets
  LARGE = '1200px',
  EXTRA_LARGE = '2400px',
}

export const ScreenWidthValues: Record<ScreenWidth, number> = {
  [ScreenWidth.EXTRA_EXTRA_SMALL]: 376,
  [ScreenWidth.EXTRA_SMALL]: 576,
  [ScreenWidth.SMALL]: 768,
  [ScreenWidth.MEDIUM]: 992,
  [ScreenWidth.LARGE]: 1200,
  [ScreenWidth.EXTRA_LARGE]: 2400,
} as const;

/*
 * All media queries are based on `max-width`
 */
export const MediaQueries: Record<ScreenWidthKeys, string> = {
  EXTRA_EXTRA_SMALL: `@media (max-width: ${ScreenWidth.EXTRA_EXTRA_SMALL})`,
  EXTRA_SMALL: `@media (max-width: ${ScreenWidth.EXTRA_SMALL})`,
  SMALL: `@media (max-width: ${ScreenWidth.SMALL})`,
  MEDIUM: `@media (max-width: ${ScreenWidth.MEDIUM})`,
  LARGE: `@media (max-width: ${ScreenWidth.LARGE})`,
  EXTRA_LARGE: `@media (max-width: ${ScreenWidth.EXTRA_LARGE})`,
} as const;
