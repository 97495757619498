import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled from 'styled-components';

export const ModalBackdrop = styled.div<{ isShowing?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${ifProp('isShowing', 'rgba(0, 0, 0, 0.4)', 'none')};
`;
