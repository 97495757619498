import { QueryParams } from 'common/urls/QueryParams';
import { isUndefined } from 'lodash';
import { ISegmentMetrics } from 'src/pages/consumer/explore/SegmentAnalytics';

export type GetDishCardUrlParamsProps = {
  foodItemSlug: string;
  initialParams?: string | URLSearchParams | string[][] | Record<string, string> | undefined;
  idx?: number;
  shefId: string;
  segmentMetrics?: ISegmentMetrics;
};

export const getDishCardUrlParams = (props: GetDishCardUrlParamsProps) => {
  const { foodItemSlug, initialParams, shefId, segmentMetrics, idx } = props;
  const urlParams = new URLSearchParams(initialParams);
  urlParams.set(QueryParams.DISH, `${foodItemSlug}`);
  urlParams.set(QueryParams.SHEF, `${shefId}`);
  if (!isUndefined(idx) && segmentMetrics) {
    urlParams.set(QueryParams.RANK, `${idx}`);
    urlParams.set(QueryParams.EXPLORE_SEGMENT_ID, `${segmentMetrics.esid}`);
    urlParams.set(QueryParams.EXPLORE_SEGMENT_POS, `${segmentMetrics.pos}`);
    urlParams.set(QueryParams.EXPLORE_SEGMENT_RENDERED_ITEMS, `${segmentMetrics.renderedItems}`);
    urlParams.set(QueryParams.EXPLORE_SEGMENT_TOTAL_ITEMS, `${segmentMetrics.totalItems}`);
  }
  return urlParams;
};

export const getPathName = (pathName: string, urlParams: URLSearchParams) => {
  const queryString = urlParams.toString();
  return queryString ? `${pathName}?${queryString}` : pathName;
};
