import { Star } from 'src/shared/design-system/Icon';
import { prop } from 'src/shared/styled-utils/prop';
import styled from 'styled-components';
import { Colors } from 'web-common/src/shared/styles';

export const STAR_COLOR_FILLED = Colors.JACKFRUIT;
export const STAR_COLOR_EMPTY = Colors.GRAY_LITE_20;
export const DEFAULT_STAR_SIZE = '40px';

interface StarProps {
  color?: string;
  size?: string;
}

export const FilledStar = styled(Star)<StarProps>`
  color: ${prop('color', STAR_COLOR_FILLED)};
  height: ${prop('size', DEFAULT_STAR_SIZE)};
  width: ${prop('size', DEFAULT_STAR_SIZE)};

  & #background-star {
    fill: ${STAR_COLOR_EMPTY} !important;
  }
`;

export const EmptyStar = styled(Star)<StarProps>`
  color: ${STAR_COLOR_EMPTY};
  height: ${prop('size', DEFAULT_STAR_SIZE)};
  width: ${prop('size', DEFAULT_STAR_SIZE)};
`;
