import { Slugs } from 'common/urls/Slugs';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import {
  DishModalFoodItemFragment,
  PerformanceOrderReviewResultFragment,
  useDishModalReviewsQuery,
} from 'src/gqlReactTypings.generated.d';
import { HorizontalSnapBadgeContainer } from 'src/shared/design-system/HorizontalSnapBadgeContainer';
import { VerticallyCenteredText } from 'src/shared/design-system/VerticallyCenteredText';
import { Routes } from 'src/shared/Routes';
import { parseDate } from 'src/shared/utils/DateTimeUtilities';
import { getSlug, getSluggedPath } from 'src/shared/utils/RouteUtilities';
import { Modal } from '../stylesV2';
import { RatingTags } from './RatingTags';

interface IReviewCardsProps {
  reviews: PerformanceOrderReviewResultFragment[];
}

const ReviewCards: React.FC<IReviewCardsProps> = ({ reviews }) => (
  <>
    {reviews.map((review, idx) => {
      const date = parseDate(review.createdAt);
      return (
        <div
          key={idx}
          className='flex h-[124px] w-[200px] shrink-0 flex-col justify-between rounded-lg border-solid border-neutral-100 p-4'>
          <p className='m-0 line-clamp-4 text-sm font-medium italic leading-[1.2] text-neutral-700'>{review.comment}</p>
          <div>
            <VerticallyCenteredText className='text-xs font-semibold text-neutral-500'>
              {review.user.publicName}, {date.toLocaleString()}
            </VerticallyCenteredText>
          </div>
        </div>
      );
    })}
  </>
);

interface IModalReviewsProps {
  foodItemId: number;
  ratingData?: Pick<DishModalFoodItemFragment, 'averageRating' | 'ratingCount' | 'topPositiveTags'>;
}

export const ModalReviews: React.FC<IModalReviewsProps> = ({ foodItemId, ratingData }) => {
  const { data: reviewData } = useDishModalReviewsQuery({
    variables: { foodItemId, offset: 0, limit: 10 },
  });

  const match = useRouteMatch();

  const hasShefId = useMemo(() => {
    const shefIdSlug = getSlug(match, Slugs.ID);
    return !isNil(shefIdSlug);
  }, [match]);
  const reviewUrl = useMemo(() => {
    const querySlug = getSlug(match, Slugs.QUERY);
    const idSlug = getSlug(match, Slugs.ID);
    const slugs = [
      { slug: Slugs.ID, value: idSlug },
      { slug: Slugs.QUERY, value: querySlug },
    ];
    return getSluggedPath(Routes.CONSUMER_ORDER_REVIEWS, slugs);
  }, [match]);

  return (
    <>
      {!isNil(ratingData) && ratingData.ratingCount > 0 && (
        <Modal.SectionContainer className='py-6'>
          <h2 className='mb-4 mt-0 text-lg font-bold text-neutral-800'>Dish review summary</h2>
          <HorizontalSnapBadgeContainer>
            <RatingTags
              averageRating={ratingData.averageRating}
              ratingCount={ratingData.ratingCount}
              tags={ratingData.topPositiveTags}
            />
          </HorizontalSnapBadgeContainer>
        </Modal.SectionContainer>
      )}
      {!isNil(reviewData) && reviewData.foodItemOrderReviews.reviews.length > 0 && (
        <Modal.SectionContainer className='py-6'>
          <h2 className='mb-4 mt-0 text-lg font-bold text-neutral-800'>Reviews from orders with this dish</h2>
          <HorizontalSnapBadgeContainer>
            <ReviewCards reviews={reviewData.foodItemOrderReviews.reviews} />
          </HorizontalSnapBadgeContainer>
          {hasShefId && (
            <Link
              to={reviewUrl}
              className='mt-4 self-start text-sm font-semibold text-neutral-700 underline hover:underline'>
              See all shef reviews
            </Link>
          )}
        </Modal.SectionContainer>
      )}
    </>
  );
};
