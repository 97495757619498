import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const BoxHeart = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG height={14} width={14} viewBox={'0 0 14 14'} {...props}>
    <Path
      d={`M7,6.7L7,6.7c0.9-0.9,2.3-0.9,3.1-0.1c0.8,0.8,0.8,2.2,0,3L7.7,12c-0.4,0.4-1,0.4-1.4,0L3.9,9.6C3,8.8,3,7.4,3.9,6.6
        C4.7,5.8,6.1,5.8,7,6.7L7,6.7z M4.6,8.9L7,11.3l2.4-2.4c0.4-0.5,0.4-1.2,0-1.6c-0.5-0.4-1.2-0.4-1.6,0L7,8.1L6.2,7.3
        C5.8,6.8,5,6.8,4.6,7.3C4.1,7.7,4.1,8.4,4.6,8.9z M13.8,4.1C13.9,4.4,14,4.7,14,4.9V12c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V4.9
        c0-0.3,0-0.6,0.2-0.8l1.3-2.9C1.8,0.5,2.5,0,3.3,0h7.4c0.8,0,1.5,0.5,1.8,1.2L13.8,4.1z M7.5,4h5.2l-1.1-2.4C11.4,1.2,11.1,1,10.7,1
        H7.5V4z M1,5v7c0,0.6,0.4,1,1,1h10c0.5,0,1-0.4,1-1V5H1z M6.5,1H3.3C2.9,1,2.5,1.2,2.4,1.6L1.3,4h5.2V1z`}
    />
  </SVG>
);
