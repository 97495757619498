export const ifNotProp =
  <T, U, V>(key: keyof T | (keyof T)[], falsy: U, truthy?: V) =>
  (props: T) => {
    let pass = false;
    if (Array.isArray(key)) {
      pass = key.filter((k) => props[k]).length === key.length;
    } else {
      pass = Boolean(props[key]);
    }

    const value = pass ? truthy : falsy;
    return value === undefined ? '' : value;
  };
