import { createSelector } from '@reduxjs/toolkit';
import { selectCartShefDeliveryDate } from 'src/store/cart/selectors/deliveryDate';
import { selectFullLineItemsById, selectLineItems } from 'src/store/cart/selectors/lineItems';
import { CartLineItemsById, CartState } from 'src/store/cart/types';

export const getMenuFoodItemQuantity = (
  items: CartLineItemsById,
  foodItemId: number,
  cartDeliveryDate: string | undefined,
  menuDeliveryDate: string | undefined
): number => {
  if (!menuDeliveryDate) return 0;

  if (cartDeliveryDate && cartDeliveryDate !== menuDeliveryDate) return 0;

  const cartLineItem = items[foodItemId];
  if (!cartLineItem) return 0;

  return cartLineItem.quantity;
};

export const selectMenuItemCountById = createSelector(
  selectLineItems,
  selectFullLineItemsById,
  selectCartShefDeliveryDate,
  (_: CartState, __: string, menuDeliveryDate?: string) => menuDeliveryDate,
  (lineItems, cartLineItemsById, cartDeliveryDate, menuDeliveryDate) =>
    lineItems.reduce<Record<string, number | null | undefined>>(
      (acc, { id, foodItemId }) => ({
        ...acc,
        [id]: getMenuFoodItemQuantity(cartLineItemsById, foodItemId, cartDeliveryDate, menuDeliveryDate),
      }),
      {}
    )
);
