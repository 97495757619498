import { createSelector } from '@reduxjs/toolkit';
import { assertDateString, dateStringToDateTime } from 'common/UrlDate';
import { mapValues } from 'lodash';
import { selectCartShefById, selectCartShefsById } from 'src/store/cart/selectors/shefs';
import { CartState } from 'src/store/cart/types';

// There are two delivery dates that are stored in the state at a given time.
// 1. the deliveryDate field at the root cart state level - this is ussed by exclusively by dish-first users
// 2. the deliveryDate field possessed by each shef in the cart - this is ussed by exclusively by shef-first users

// selects the delivery date for the root level cart if the user is in the dish-first experiment
// or the specified shef's delivery date for users in control
export const selectCartShefDeliveryDate = createSelector(
  selectCartShefById,
  (state: CartState) => state.deliveryDate,
  (state: CartState) => state.isMultiCart,
  (shef, deliveryDate, isMultiCart) => (isMultiCart ? deliveryDate : shef?.deliveryDate)
);

// for each shef in the cart we either select the delivery date from the root level cart state
// if the user is in the dish first experiment, otherwise we fall back to the shef's configured delivery date
export const selectCartDeliveryDatesByShefIds = createSelector(
  selectCartShefsById,
  (state: CartState) => state.deliveryDate,
  (state: CartState) => state.isMultiCart,
  (shefsById, deliveryDate, isMultiCart) =>
    mapValues(shefsById, (shef) => (isMultiCart ? deliveryDate : shef?.deliveryDate))
);

export const selectMultiCartDeliveryDate = createSelector(
  (state: CartState) => state.deliveryDate,
  (deliveryDate) => deliveryDate
);

export const selectMultiCartDeliveryDateTime = createSelector(selectMultiCartDeliveryDate, (deliveryDate) =>
  dateStringToDateTime(assertDateString(deliveryDate))
);
