import { isNil } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';
import { FormErrorMessage } from '../Error/FormErrorMessage';
import { Input, Label, LabelSpan } from './styles';

interface LabeledTextInputProps {
  label: string;
  name: string;
  password?: boolean;
  value: string;
  disabled?: boolean;
  autoFocus?: boolean;
  errorMessage?: string;
  width?: string;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  'data-cy'?: string;
}

const Container = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
`;

const ShowHideButton = styled.button`
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.XS};
  font-weight: ${Font.Weight.SEMI_BOLD};
  text-decoration: underline;
  color: ${Colors.GRAY_DARK_30};
  background: none;
  border: none;

  cursor: pointer;

  align-self: center;
  padding: ${Spacing.NONE};
  margin-right: ${Spacing.SINGLE};
  margin-left: ${Spacing.HALF};
`;

const ErrorMessageWithMargin = styled(FormErrorMessage)`
  margin-top: ${Spacing.HALF};
`;

export const LabeledTextInput: React.FC<LabeledTextInputProps> = (props) => {
  const { label, name, password, value, errorMessage, onChange, disabled, autoFocus, width = '100%' } = props;
  const [isPasswordInput, setIsPasswordInput] = useState(password);
  const [focused, setFocused] = useState(false);

  const toggleShowHide = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsPasswordInput((value) => !value);
  };

  const showLabel = value.length !== 0;
  const hasError = !isNil(errorMessage) && errorMessage.length > 0;

  return (
    <Container width={width}>
      <Label showLabel={showLabel} focused={focused} hasError={hasError} htmlFor={name}>
        {showLabel ? <LabelSpan>{label}</LabelSpan> : null}
        <Input
          showLabel={showLabel}
          isPasswordInput={isPasswordInput}
          type={isPasswordInput ? 'password' : 'text'}
          name={name}
          placeholder={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          autoFocus={autoFocus}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          data-cy={props['data-cy']}></Input>
        {password ? (
          <ShowHideButton onClick={toggleShowHide}>{isPasswordInput ? 'Show' : 'Hide'}</ShowHideButton>
        ) : null}
      </Label>
      {hasError ? <ErrorMessageWithMargin>{errorMessage}</ErrorMessageWithMargin> : null}
    </Container>
  );
};
