import { isNil } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { useOnMount } from 'src/shared/hooks/useOnMount';
import { useUnmount } from 'src/shared/hooks/useUnmount';
import { match } from 'ts-pattern';
import { Transition } from 'web-common/src/shared/styles';
import { TieredProgressBarProps, TierType } from '../types';
import { Background, Bar, BarGlow, Container, Point, PointContent, PointHighlight } from './styles';

export const Tiered: React.FC<TieredProgressBarProps> = (props) => {
  const {
    barColor,
    backgroundColor,
    className,
    height,
    tierContent,
    tiers = [25, 50],
    percent = 0,
    showGlow = false,
    startingPercent,
    totalPercent = 100,
  } = props;
  const percentComplete = Math.min((percent / totalPercent) * 100, 100);
  const isMountedRef = useRef(false);
  const [isMounted, setIsMounted] = useState(!isNil(startingPercent));
  const [isAnimating, setIsAnimating] = useState(false);
  const [hasAnimatedOnce, setHasAnimatedOnce] = useState(false);
  const [reachedTiers, setReachTiers] = useState(
    tiers.map((tier: number | TierType) => {
      if (startingPercent == null) {
        const isTierObject = typeof tier === 'object';
        const tierPercent = isTierObject ? tier.percent : tier;
        return percent > tierPercent;
      }
      return false;
    })
  );

  const animatedBar = useSpring({
    from: { percent: 0, width: `0%` },
    to: { percent: percentComplete, width: `${percentComplete}%` },
    config: { duration: Transition.TimeValue.DOUBLE },
    onChange: ({ value }) => {
      const calulatedTiers = tiers.map((tier) => value.percent >= (typeof tier === 'object' ? tier.percent : tier));
      if (calulatedTiers.join('') !== reachedTiers.join('')) {
        setReachTiers(calulatedTiers);
      }
    },
    onStart: useCallback(() => {
      if (showGlow && isMounted && isMountedRef.current) {
        setIsAnimating(true);
      }
    }, [isMounted, showGlow]),
    onRest: useCallback(() => {
      if (showGlow && isMounted && isMountedRef.current) {
        setIsAnimating(false);
      }
      if (!hasAnimatedOnce) {
        setHasAnimatedOnce(true);
      }
    }, [hasAnimatedOnce, isMounted, showGlow]),
    immediate: !isMounted,
  });

  useOnMount(() => {
    // eslint-disable-next-line functional/immutable-data -- Mounting
    isMountedRef.current = true;
    setIsMounted(true);
  });

  useUnmount(() => {
    // eslint-disable-next-line functional/immutable-data -- Unmounting
    isMountedRef.current = false;
    setIsMounted(false);
  });

  return (
    <Container className={className}>
      <Background color={backgroundColor} height={height}>
        <Bar color={barColor} height={height} style={{ width: animatedBar.width }}>
          <BarGlow show={isAnimating} />
        </Bar>
        {tiers.map((tier: number | TierType, index: number) => {
          const isTierObject = typeof tier === 'object';
          const tierPercent = isTierObject ? tier.percent : tier;
          const contentToShow = isTierObject
            ? match({ isTierObject })
                .with({ isTierObject: true }, () =>
                  tier.renderContent
                    ? tier.renderContent(reachedTiers[index], hasAnimatedOnce)
                    : tier.content ?? tierContent
                )
                .otherwise(() => tierContent)
            : tierContent;
          return (
            <React.Fragment key={`${tierPercent}`}>
              <Point
                backgroundColor={backgroundColor}
                highlighted={reachedTiers[index]}
                highlightColor={barColor}
                style={{ left: `${tierPercent}%` }}>
                <PointHighlight />
              </Point>
              {contentToShow && (
                <PointContent data-highlighted={reachedTiers[index]} style={{ left: `${tierPercent}%` }}>
                  {contentToShow}
                </PointContent>
              )}
            </React.Fragment>
          );
        })}
      </Background>
    </Container>
  );
};
