import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './inhye-kim.jpg';

export const InhyeKim: PersonInfo = {
  firstName: 'Inhye',
  lastName: 'Kim',
  image,
  pronoun: Pronoun.SHE,
  role: ROLE.DATA_SCIENTIST,
};
