import { MessageCodec } from '@shef/native-bridge';
import { noOp } from 'common/Constants';
import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { Observable, Subject } from 'rxjs';

type OnMessageFunction = (message: string) => void;

export class WebDataBus {
  private readonly defaultOnNativeMessageImpl: OnMessageFunction;

  private readonly messageStreamSubject: Subject<typeof MessageCodec._T> = new Subject();

  constructor(public readonly App: ConsumerApp, public readonly onError: (err: Error) => void) {
    this.defaultOnNativeMessageImpl = App.onNativeMessage;
  }

  public start(): void {
    this.App.onNativeMessage = (msg) => this.handleNativeMessage(msg);
    const { pendingMessages } = this.App;
    this.App.pendingMessages = [];

    pendingMessages.forEach(this.handleNativeMessage);
  }

  public stop(): void {
    this.App.onNativeMessage = this.defaultOnNativeMessageImpl;
  }

  // Hiding the global ReactNativeWebView from the rest of this codebase
  // eslint-disable-next-line class-methods-use-this
  public sendMessage(msg: typeof MessageCodec._T): void {
    try {
      window?.ReactNativeWebView?.postMessage?.(MessageCodec.encodeUnsafe(msg));
    } catch (e) {
      noOp();
    }
  }

  public get messageStream(): Observable<typeof MessageCodec._T> {
    return this.messageStreamSubject.asObservable();
  }

  private handleNativeMessage(msg: string): void {
    pipe(
      MessageCodec.decode(msg),
      fold(
        (err) => {
          this.onError(err);
        },
        (decoded) => {
          this.messageStreamSubject.next(decoded);
        }
      )
    );
  }
}
