import { waitUntil } from 'common/PromiseUtils';
import { isNil } from 'lodash';
import { useCallback, useEffect } from 'react';
import { RouteChildrenProps } from 'react-router';
import { useShowLoginOrSignUpModal } from 'src/auth/modal/useShowLoginOrSignUpModal';
import { useLoginOrSignUpWithOAuth } from 'src/auth/oAuth/useLoginOrSignUpWithOAuth';
import { OAuthLoginType } from 'src/gqlReactTypings.generated.d';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useScript } from 'src/shared/hooks/useScriptHook';
import { Notifications } from 'src/shared/Notifications';
import { ConsumerLoginRoutes, Routes } from 'src/shared/Routes';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';

export const useGoogleOneTapLogin = (location: RouteChildrenProps['location']) => {
  const [currentUser, currentUserLoading] = useCurrentUser();
  const isOnLoginOrRegisterPage = locationInRoutes(location, [
    Routes.CONSUMER_BECOME_A_SHEF_REGISTER,
    ...ConsumerLoginRoutes,
  ]);

  const { shouldShowGoogleOTL } = useShowLoginOrSignUpModal();

  const shouldShowGoogleLogin =
    !isOnLoginOrRegisterPage && !currentUserLoading && currentUser === null && shouldShowGoogleOTL;

  // Load the script from Google that lets us display the one-tap prompt:
  const [googleLoginScriptFinishedLoading, googleLoginScriptLoadingError] = useScript(
    'https://accounts.google.com/gsi/client',
    undefined,
    !shouldShowGoogleLogin // Skip loading if shouldn't show the login
  );

  const onError = useCallback((error: Error) => Notifications.error(error.message), []);
  const onSuccess = useCallback(() => window.location.reload(), []);

  const { login } = useLoginOrSignUpWithOAuth({ type: OAuthLoginType.GoogleWeb, onError, onSuccess });

  const handleGoogleResponse = useCallback(
    async (response: IGoogleLoginResponse) => {
      if (response.credential) {
        // Response.credential is a JWT token that we send to our backend to validate:
        login(response.credential);
      } else {
        onError(new Error('Something went wrong logging in.'));
      }
    },
    [login, onError]
  );

  const displayOneTapLogin = useCallback(async () => {
    await waitUntil(() => !isNil(window.google?.accounts?.id?.initialize), 200, 5);
    // The argument passed in here is documented at https://developers.google.com/identity/gsi/web/tools/configurator
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
      callback: handleGoogleResponse,
    });
    // Display the prompt now that it's initialized:
    window.google.accounts.id.prompt();
  }, [handleGoogleResponse]);

  // If the Google script has loaded but not been initialized, initialize it and display the prompt:
  useEffect(() => {
    if (
      shouldShowGoogleLogin &&
      googleLoginScriptFinishedLoading &&
      !googleLoginScriptLoadingError &&
      !(window as any).Playwright
    ) {
      displayOneTapLogin().catch(console.error);
    }
  }, [googleLoginScriptFinishedLoading, googleLoginScriptLoadingError, shouldShowGoogleLogin]);
};

interface IGoogleLoginResponse {
  credential?: string;
}
