import React from 'react';
import ReactDOM from 'react-dom';
import { Close, ZoomIn, ZoomOut } from 'src/shared/design-system/Icon';
import { LightboxWithControlsProps } from './consts';
import { useLightboxControls } from './hooks';
import {
  Backdrop,
  Container,
  ImageContainer,
  LightboxContainer,
  LightboxMenuButton,
  LightboxMenuContainer,
  LightboxMenuHeader,
} from './styles';

/**
 * Lightbox component for zooming into an image when clicked with zoom controls.
 * Pass in an `img` element as children or alternatively, you can pass different content to show with the `lightboxContent` prop.
 */
export const LightboxWithControls: React.FC<React.PropsWithChildren<LightboxWithControlsProps>> = (props) => {
  const {
    backdropProps,
    className,
    closeButtonProps,
    containerProps,
    header,
    imageContainerProps,
    isVisible,
    lightboxContainerProps,
    lightboxContent,
    zoomInButtonProps,
    zoomOutButtonProps,
  } = useLightboxControls(props);

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop {...backdropProps}>
          {isVisible && (
            <>
              <LightboxMenuContainer
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                {header && <LightboxMenuHeader>{header}</LightboxMenuHeader>}
                <LightboxMenuButton {...zoomInButtonProps}>
                  <ZoomIn />
                </LightboxMenuButton>
                <LightboxMenuButton {...zoomOutButtonProps}>
                  <ZoomOut />
                </LightboxMenuButton>

                <LightboxMenuButton {...closeButtonProps}>
                  <Close />
                </LightboxMenuButton>
              </LightboxMenuContainer>
              <LightboxContainer {...lightboxContainerProps}>
                <ImageContainer {...imageContainerProps}>{lightboxContent || props.children}</ImageContainer>
              </LightboxContainer>
            </>
          )}
        </Backdrop>,
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        document.getElementById('root') as HTMLElement
      )}
      <Container className={className} {...containerProps}>
        {props.children}
      </Container>
    </>
  );
};
