import { StorageKeys } from 'common/storage/constants';
import { isNil } from 'lodash';
import { useCallback, useEffect } from 'react';
import { PromoCodeFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useHistory } from 'src/shared/hooks/useHistory';
import { useModal } from 'src/shared/hooks/useModal';
import { useLocalStorage } from 'src/shared/storage/Storage';
import { useDispatch, useSelector } from 'src/store';
import { hideLoginModal, showLoginModal } from 'src/store/login';
import { useHideLoginOrSignUpModal } from './useHideLoginOrSignUpModal';

type ShowProps = {
  signUpPromoCode?: PromoCodeFieldsFragment | null;
  onHideModalKey?: string;
  isClosable?: boolean;
  requireZipCode?: boolean;
  entryHeader?: string;
  signUpEntryPoint?: string | null;
  preventReloadOnLogin?: boolean;
  onSuccessfulLogin?: () => void;
  signUpCTA?: string | null;
  continueCTA?: string | null;
  loginCTA?: string | null;
  hideBanner?: boolean;
  hideGuestOption?: boolean;
};

type UseShowLoginOrSignUpResult = Pick<ReturnType<typeof useModal>, 'hide' | 'isShowing'> & {
  shouldShowGoogleOTL: boolean;
  show: (props?: ShowProps) => void;
  signUpPromoCode: PromoCodeFieldsFragment | null;
  isClosable: boolean;
  requireZipCode?: boolean;
  signUpEntryPoint?: string | null;
  entryHeader: string | null;
  preventReloadOnLogin?: boolean | null;
  onSuccessfulLogin?: (() => void) | null;
  signUpCTA: string | null;
  continueCTA: string | null;
  loginCTA: string | null;
  hideBanner: boolean | null;
  hideGuestOption: boolean | null;
};

export const useShowLoginOrSignUpModal = (): UseShowLoginOrSignUpResult => {
  const [currentUser, currentUserIsLoading] = useCurrentUser();
  const [, setStorageKey] = useLocalStorage(StorageKeys.LOGIN_ON_EXPLORE_MODAL, false);
  const { onHideModal } = useHideLoginOrSignUpModal();
  const isLoginModalShowing = useSelector((state) => state.login.isLoginModalShowing);
  const signUpPromoCode = useSelector((state) => state.login.options?.signUpPromoCode ?? null);
  const isClosable = useSelector((state) => state.login.options?.isClosable ?? true);
  const requireZipCode = useSelector((state) => state.login.options?.requireZipCode ?? false);
  const entryHeader = useSelector((state) => state.login.options?.entryHeader ?? null);
  const signUpEntryPoint = useSelector((state) => state.login.options?.signUpEntryPoint ?? null);
  const preventReloadOnLogin = useSelector((state) => state.login.options?.preventReloadOnLogin ?? null);
  const onSuccessfulLogin = useSelector((state) => state.login.options?.onSuccessfulLogin ?? null);
  const signUpCTA = useSelector((state) => state.login.options?.signUpCTA ?? null);
  const continueCTA = useSelector((state) => state.login.options?.continueCTA ?? null);
  const loginCTA = useSelector((state) => state.login.options?.loginCTA ?? null);
  const hideBanner = useSelector((state) => state.login.options?.hideBanner ?? null);
  const hideGuestOption = useSelector((state) => state.login.options?.hideGuestOption ?? null);

  const dispatch = useDispatch();

  const hide = useCallback(() => {
    onHideModal();
    dispatch(hideLoginModal());
    setStorageKey(true);
  }, [dispatch, onHideModal]);

  const show = useCallback(
    ({
      signUpPromoCode = null,
      onHideModalKey,
      isClosable,
      requireZipCode,
      entryHeader,
      signUpEntryPoint,
      preventReloadOnLogin,
      onSuccessfulLogin,
      signUpCTA,
      continueCTA,
      loginCTA,
      hideBanner,
      hideGuestOption,
    }: ShowProps = {}) => {
      dispatch(
        showLoginModal({
          signUpPromoCode,
          onHideModalKey,
          isClosable,
          requireZipCode,
          entryHeader,
          signUpEntryPoint,
          preventReloadOnLogin,
          onSuccessfulLogin,
          signUpCTA,
          continueCTA,
          loginCTA,
          hideBanner,
          hideGuestOption,
        })
      );
    },
    [dispatch]
  );

  // Hide the modal if the user switches pages (via browser buttons, for example)
  const history = useHistory();
  useEffect(() => {
    const unlistenCallback = history.listen(() => {
      if (isLoginModalShowing) {
        // Don't use callback, since we don't want to set the storage key
        dispatch(hideLoginModal());
      }
    });
    return unlistenCallback;
  }, [dispatch, history, isLoginModalShowing]);

  const isShowing = isLoginModalShowing && !currentUserIsLoading && isNil(currentUser);
  return {
    show,
    hide,
    isShowing,
    shouldShowGoogleOTL: !isShowing,
    signUpPromoCode,
    isClosable,
    requireZipCode,
    signUpEntryPoint,
    entryHeader,
    preventReloadOnLogin,
    onSuccessfulLogin,
    signUpCTA,
    continueCTA,
    loginCTA,
    hideBanner,
    hideGuestOption,
  };
};
