import { AddressInput, UtmInput } from 'src/gqlReactTypings.generated.d';

export type PartialAddressInputWithZipCode = Pick<AddressInput, 'zipCode'> & Partial<AddressInput>;

// Client side only user specific data that is persisted to browser storage
export enum UserPreferences {
  ZIPCODE = 'zipcode',
  EXPLORE_DELIVERY_DATES = 'exploreDeliveryDates',
  MOST_RECENTLY_SELECTED_ADDRESS = 'mostRecentlySelectedAddress',
  UTM = 'utm',
  // User will be redirected to subscriptions page when clicking the shef logo on the site:
  USER_REFERRED_FOR_SUBSCRIPTION = 'userReferredForSubscription',
  // User will be redirected to subscriptions page even when manually navigating to shef.com:
  SUBSCRIPTION_VISIBLE_ONLY = 'subscriptionVisibleOnly',
}

export interface IUserPreferences {
  [UserPreferences.ZIPCODE]: string | null;
  [UserPreferences.EXPLORE_DELIVERY_DATES]: string[] | null;
  [UserPreferences.MOST_RECENTLY_SELECTED_ADDRESS]: PartialAddressInputWithZipCode | null;
  [UserPreferences.UTM]: UtmInput | null;
  [UserPreferences.USER_REFERRED_FOR_SUBSCRIPTION]: boolean | null;
  [UserPreferences.SUBSCRIPTION_VISIBLE_ONLY]: boolean | null;
}

export interface IUserPreferencesState {
  preferences: IUserPreferences;
}
