export const abbreviateNumber = (number: number): string => {
  try {
    return Intl.NumberFormat('en-US', {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ignore notion param
      // @ts-ignore -- https://github.com/microsoft/TypeScript/issues/36533
      notation: 'compact',
      maximumFractionDigits: 1,
    }).format(number);
  } catch (e) {
    console.error('Failed to abbreviate number. This is likely related to safari 4.2.1', { number, error: e });
    return number.toString();
  }
};
