type BarHighlightPositionProps = {
  clickedIndex?: number;
  hasValue?: boolean;
  numberOfOptions?: number;
  selectedIndex?: number;
  padding?: number;
};

export const getBarHighlightPosition = ({
  clickedIndex = 0,
  hasValue = false,
  numberOfOptions = 1,
  selectedIndex = 0,
}: BarHighlightPositionProps) =>
  numberOfOptions
    ? `${((hasValue ? selectedIndex : clickedIndex) / (numberOfOptions - 1)) * (100 * (numberOfOptions - 1))}%`
    : '0%';

export const getBarHighlightWidth = ({ numberOfOptions = 1 }: BarHighlightPositionProps) =>
  numberOfOptions ? `${100 / numberOfOptions}%` : '100%';
