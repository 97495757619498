import { ApolloClient } from '@apollo/client';
import { NativeAppMessenger } from 'src/mobile-app/NativeAppMessenger';
import { ITracker } from 'src/tracking/types';
import { AppAdTracker } from './AppAdTracker';
import { WebAdTracker } from './WebAdTracker';

interface AdTrackerArgs<T> {
  apolloClient: ApolloClient<T>;
  browserTrackerId: string;
  nativeAppMessenger: NativeAppMessenger | null;
}

export const createAdTracker = <T>({
  apolloClient,
  browserTrackerId,
  nativeAppMessenger,
}: AdTrackerArgs<T>): ITracker => {
  if (nativeAppMessenger) {
    return new AppAdTracker(browserTrackerId, nativeAppMessenger);
  }

  return new WebAdTracker(browserTrackerId, apolloClient);
};
