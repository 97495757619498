import { UserIdentifierType } from 'common/analytics-event-manager/IAnalyticsEventManager';
import { ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { useContext, useMemo } from 'react';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { ExperimentContext } from './ExperimentContext';

export function useHasValidIdentifier(name: ExperimentNames) {
  const { getExperiment } = useContext(ExperimentContext);
  const [currentUser] = useCurrentUser();

  const canGetVariant = useMemo(() => {
    const userIdentifierType = getExperiment(name)?.userIdentifierType;
    const isUserExperiment = userIdentifierType === UserIdentifierType.USER_ID;
    const isDeviceExperiment = userIdentifierType === UserIdentifierType.DEVICE_ID;
    return Boolean((isUserExperiment && currentUser) || isDeviceExperiment);
  }, [currentUser, getExperiment, name]);

  return canGetVariant;
}
