import React from 'react';
import QuestionMark from 'src/assets/svg/questionmark.svg';
import { ButtonProps, PrimaryButton } from 'src/shared/design-system/Buttons';
import { ifNotProp } from 'src/shared/styled-utils/ifNotProp';
import { ZIndexes } from 'src/shared/ZIndexes';
import styled, { css } from 'styled-components';
import { ScreenWidth } from 'web-common/src/shared/styles';

const BottomRightFixedButton = styled(PrimaryButton)<{ hasText: boolean }>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: unset;
  padding: 15px 22px;
  font-weight: 700;
  z-index: ${ZIndexes.HelpButton};

  @media (max-width: ${ScreenWidth.SMALL}) {
    height: 50px;

    ${ifNotProp(
      'hasText',
      css`
        width: 50px;
      `
    )}
  }
`;

const QuestionMarkIcon = styled.img.attrs({ src: QuestionMark })`
  margin-top: -4px;
  vertical-align: middle;

  :only-child {
    margin: 0 -9px;
  }
`;

const Label = styled.span`
  margin-left: 8px;
`;

interface IHelpButtonComponentProps extends ButtonProps, Omit<React.HTMLAttributes<HTMLButtonElement>, 'color'> {
  label?: string;
}

export const HelpButtonComponent: React.FC<IHelpButtonComponentProps> = ({ label, ...buttonProps }) => (
  <BottomRightFixedButton {...buttonProps} hasText={Boolean(label && label.length)}>
    <QuestionMarkIcon />
    {label && <Label>{label}</Label>}
  </BottomRightFixedButton>
);
