import { useDispatch } from 'src/store';
import { actions } from 'src/store/search';

export const useSearchQueryActions = () => {
  const dispatch = useDispatch();

  return {
    changeSearchQuery: (searchQuery: string, isPartialQuery?: boolean) => {
      dispatch(actions.changeSearchQuery({ searchQuery, isPartialQuery }));
    },
  };
};
