import { useCallback } from 'react';
import { ExitIntentKey, useSignupModalExitIntent } from 'src/pages/consumer/exit-intent-modals/useShefExitIntent';
import { useSelector } from 'src/store';
import { match } from 'ts-pattern';

type UseHideLoginOrSignUpResult = {
  onHideModal: () => void;
};

export const useHideLoginOrSignUpModal = (): UseHideLoginOrSignUpResult => {
  const onHideModalKey = useSelector((state) => state.login.options?.onHideModalKey ?? null);
  const { unsubscribe: signupModalUnsubscribe } = useSignupModalExitIntent();

  const onHideModal = useCallback(() => {
    match(onHideModalKey)
      .with(ExitIntentKey.FIRST_TIME_USER_SIGNUP_MODAL, () => signupModalUnsubscribe())
      .otherwise(() => null);
  }, [onHideModalKey]);

  return {
    onHideModal,
  };
};
