import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './gil-caspi.jpg';

export const GilCaspi: PersonInfo = {
  firstName: 'Gil',
  lastName: 'Caspi',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
