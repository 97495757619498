import { animated } from 'react-spring';
import styled, { css } from 'styled-components';
import { Colors, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';

export const Container = styled.div`
  width: 100%;
  ${Mixins.Flex.centerColumn()}
`;

export const Background = styled.div<{ color?: Colors | string; height?: Spacing | string }>`
  width: 100%;

  ${({ color, height }) => css`
    background: ${color ?? Colors.GRAY_LITE_20};
    height: ${height ?? Spacing.QUARTER};
    border-radius: ${height ?? Spacing.QUARTER};
  `};
`;

export const BarGlow = styled.div<{ show?: boolean }>`
  position: absolute;
  right: 0;
  background: ${Colors.WHITE};
  box-shadow: 0 0 ${Spacing.QUARTER} ${Spacing.QUARTER} ${Colors.AQUA_LITE_30},
    0 0 ${Spacing.SINGLE} ${Spacing.QUARTER} rgba(0, 0, 0, 0.2);
  transform: translateX(50%);
  ${({ show = false }) => css`
    opacity: ${show ? 1 : 0};
    transition: opacity ${show ? Transition.Time.BASE : Transition.Time.DOUBLE} ${show ? '0s' : Transition.Time.DOUBLE};
  `};
`;

export const Bar = styled(animated.div)<{
  color?: Colors | string;
  height?: Spacing | string;
}>`
  position: relative;
  height: 100%;
  min-width: 0%;
  max-width: 100%;

  ${({ color, height }) => css`
    background: ${color ?? Colors.BELL_PEPPER};
    height: ${height ?? Spacing.QUARTER};
    border-radius: ${height ?? Spacing.QUARTER};

    & ${BarGlow} {
      ${Mixins.Shape.circle(height ?? Spacing.QUARTER)}
    }
  `};
`;
