import { shefSpiceText, SpiceLevel } from 'common/SpiceLevels';
import { Pepper, PepperOutline } from 'src/shared/design-system/Icon';
import { Container, Label } from './styles';
import { SpiceTagProps } from './types';

const SpiceTagPepper: React.FC<{ outline: boolean; size: number }> = ({ outline, size }) =>
  outline ? <PepperOutline width={size} height={size} /> : <Pepper width={size} height={size} />;

export const SpiceTag = ({ showLabel, spiceLevel, size = 16, ...restOfProps }: SpiceTagProps): JSX.Element => (
  <Container {...restOfProps}>
    <SpiceTagPepper outline={spiceLevel < SpiceLevel.MILD} size={size} />
    <SpiceTagPepper outline={spiceLevel < SpiceLevel.MEDIUM} size={size} />
    <SpiceTagPepper outline={spiceLevel < SpiceLevel.HOT} size={size} />
    {showLabel && <Label>{shefSpiceText.get(spiceLevel)}</Label>}
  </Container>
);
