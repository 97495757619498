type TReplacer = (key: string, value: any) => any;

// from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
export const getCircularReplacer = (): TReplacer => {
  const seen = new WeakSet();
  return (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return undefined;
      }
      seen.add(value);
    }
    return value;
  };
};

const REDACTED_KEYS = {
  password: 1,
  token: 1,
  secret: 1,
  googleIdToken: 1,
  facebookIdToken: 1,
  appleIdToken: 1,
  accessToken: 1,
  stripeToken: 1,
};

export const redactKeys: TReplacer = (key, val) => {
  return key in REDACTED_KEYS ? '** REDACTED **' : val;
};

export const debugReplacer: TReplacer = (key, val) => {
  console.log('[Debug Replacer]', key, val);
  return val;
};

export const composeReplacers =
  (...replacers: TReplacer[]): TReplacer =>
  (key: string, value: any) =>
    replacers.reduce((newValue, replacer) => replacer(key, newValue), value);
