export enum StorageKeys {
  TOKEN = 'token',
  SESSION_LAST_EVENT = 'sessionLastEvent',
  CURRENT_USER = 'current_user',
  CHECKOUT_ORDER = 'checkout_order',
  REFERRAL_CODE = 'referral_code',
  ZIPCODE = 'consumerZipCode',
  ORGANIZATION = 'consumerOrganization',
  PREFERENCES = 'preferences',
  BROWSER_TRACKER = 'browser_tracker',
  VARIANT_OVERRIDES = 'variant_overrides',
  LAST_RELOAD_TIMESTAMP = 'last_reload_timestamp',
  PROMOTIONAL_STATE = 'promotionalState',
  GLOBAL_STATE_ORDER = 'editableOrderCart',
  GLOBAL_STATE_CART = 'cart',
  CONSUMER_HEADER_PROMO_BANNER_REFERRAL = 'ch_gift_card',
  CONSUMER_HEADER_NEW_USER_PROMO_BANNER = 'new_user_promo_banner',
  CONSUMER_HEADER_PERSISTENT_PROMO_BANNER_PREFIX = 'persistent-banner-prefix',
  CONSUMER_UPSELL_SAME_DELIVERY_DAY_PROMO = 'cusddpd',
  NEW_USER_EDUCATION_MODAL = 'nuem',
  TASTE_TESTER_MODAL = 'taste_tester_modal',
  LOGIN_ON_EXPLORE_MODAL = 'login_on_explore_modal',
  BECOME_A_SHEF_FORM_PHONE = 'become_a_shef_form_phone',
  BECOME_A_SHEF_FORM_TOS = 'become_a_shef_form_tos',
  CUISINE_PREFERENCES = 'cuisine_preferences',
  DIETARY_PREFERENCES = 'dietary_preferences',
  SPICE_LEVEL_PREFERENCES = 'spice_level_preferences',
  DELIVERY_DATE_MENU_TOOLTIP = 'delivery_date_menu_tooltip',
  REGISTRATION_INFO = 'registration_info',
  SHEF_INFORMATION_SESSION_VIEWED_SECONDS = 'sis_viewed_seconds',
  REACHED_APPLICATION_COMPLETED = 'shef_has_reached_application_completed',
  STRIPE_RELOAD_COUNT = 'stripe_reload_count',
  SHEF_FOLLOW_EXPLAINER_SEEN = 'shef_follow_explainer_seen',
  SHEF_FOLLOW_PAINTED_DOOR_EXPLAINER_SEEN = 'shef_follow_painted_door_explainer_seen',
  LAST_REQUESTED_APP_REVIEW_AT = 'last_requested_app_review_at',
  APP_UPSELL_BANNER_DISMISSED_STATE = 'app_upsell_banner_dismissed_state',
  SHEF_MENU_CHECKLIST = 'shef_menu_checklist',
  IS_TASTE_TESTER = 'is_taste_tester',
  SHEF_APPLICATION_ONBOARDING_CALL_SKIPPED = 'shef_application_onboarding_call_skipped',
  DISH_MODAL_DESCRIPTION_EXPANDED = 'dish_modal_description_expanded',
  DISH_MODAL_INGREDIENTS_EXPANDED = 'dish_modal_ingredients_expanded',
  DISH_MODAL_ALLERGY_EXPANDED = 'dish_modal_allergy_expanded',
  BULK_UPSELL_MODAL_EXPANDED = 'bulk_upsell_modal_expanded',
  DATADOG_EXPERIMENT_CONTEXT = 'datadog_experiment_context',
  VISITED_MEAL_PLANS = 'visited_meal_plans',
  VISITED_EXPLORE_PAGE = 'visited_explore_page',
  LAST_VISITED_URL = 'last_visited_url',

  // redux storage keys
  STORE_CART_STATE = 'store:cart',
  STORE_EDITABLE_ORDER_CART_STATE = 'store:editableOrderCart',
  STORE_CHECKOUT_STATE = 'store:checkout',

  RECENT_MINIMUM_ORDER_AMOUNT_FREE_SHIPPING = 'recent_minimum_order_amount_free_shipping',
  RECENT_DISCOUNTED_DELIVERY_FEE = 'recent_discounted_delivery_fee',
  RECENT_DELIVERY_FEE = 'recent_delivery_fee',
  ADMIN_LABELS_SELECTED_HUB = 'admin_labels_selected_hub',
  GOOGLE_OAUTH_PARAMS = 'google_oauth_params',
  HOMEPAGE_CONTEXT = 'homepage_context',
  CELEBRITY_EVENT = 'celebrity_event',
  RATE_RECENT_GROUP_ORDER_KEY = 'rate_recent_group_order',
  SHEF_OPT_IN_TO_FRINGE_MODAL_SEEN = 'opt_fringe_modal', // whether we've shown the modal
  TOP_SHEF_INFO_MODAL_SEEN = 'top-shef-info-modal-seen',
  USER_HAS_SEEN_EXPLORE_MEAL_PLAN_PROPOSITION_MODAL = 'user_has_seen_explore_meal_plan_proposition_modal',
}

export enum ExpirableStorageKeys {}

export const LocalStorageExpirationsInMs: Record<ExpirableStorageKeys, number> = {};

export enum SessionStorageKeys {
  DISH_FIRST_EXPLORE_STORAGE_KEY = 'dish_first_explore_storage_key',
  DISH_FIRST_EXPLORE_FILTERS_STORAGE_KEY = 'dish_first_explore_filters_storage_key',
  DISH_FIRST_DELIVERY_DATE = 'dish_first_delivery_date',
  HISTORY_STACK = 'historyStack',
  SAVED_SEARCH_QUERY = 'saved_search_query',
  RATE_RECENT_ORDER_KEY = 'rate_recent_order',
  MEAL_PLAN_DISH_SELECTION_FORM = 'meal_plan_dish_selection_form',
  MEAL_PLAN_HAS_BEEN_REDIRECTED = 'meal_plan_has_been_redirected',
  MEAL_PLAN_DELIVERY_DATE = 'meal_plan_delivery_date',
  USER_HAS_SEEN_EXPLORE_MEAL_PLAN_PROPOSITION_MODAL = 'user_has_seen_explore_meal_plan_proposition_modal',
  MEAL_PLAN_PREFERENCES = 'meal_plan_preferences',
  TASTE_PROFILE = 'taste_profile',
}
