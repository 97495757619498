import { formatCurrency } from 'common/FormatUtilities';
import CrownDark from 'src/assets/svg/crown-dark.svg';
import { Upsell, UpsellTier } from 'src/gqlReactTypings.generated.d';
import { useFeatureFlag } from 'src/shared/design-system/FeatureFlag';
import Chip, { ChipColor } from 'web-common/src/shared/design-system/components/Chip';

interface UpsellPriceChipProps {
  upsell: Upsell | null | undefined;
  servingsPerMeal: number;
}

export const UpsellPriceChip: React.FC<UpsellPriceChipProps> = ({ upsell, servingsPerMeal }) => {
  const upsellsEnabled = useFeatureFlag('upsellsEnabled');

  if (!upsell || !upsellsEnabled) {
    return null;
  }

  const priceMultiplier = servingsPerMeal || 1;
  const priceCopy = `+${formatCurrency(upsell.price * priceMultiplier, true)}`;
  return <Chip color={ChipColor.ELDERBERRY_50} copy={priceCopy} />;
};

interface UpsellLabelChipProps {
  upsell: Upsell | null | undefined;
  applyFullHeight?: boolean;
}

export const UpsellLabelChip: React.FC<UpsellLabelChipProps> = ({ upsell, applyFullHeight }) => {
  const upsellsEnabled = useFeatureFlag('upsellsEnabled');

  if (!upsell || !upsellsEnabled) {
    return null;
  }

  const crownDarkIcon = <img alt='' className='w-6' src={CrownDark} />;
  const copy = upsell.tier === UpsellTier.PremiumPlus ? 'Premium Plus' : 'Premium';

  return <Chip applyFullHeight={applyFullHeight} color={ChipColor.ELDERBERRY_50} copy={copy} icon={crownDarkIcon} />;
};
