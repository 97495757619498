import { ConsumerGroupOrderFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { FoodItemRatingUpdate, OrderRatingsByOrderId } from './constants';

import { GroupOrderFoodItemRatings } from '../../GroupOrderModal/GroupOrderFoodItemRating';
import {
  createFoodItemRatingFromExistingRating,
  getLineItemsSortedByFoodTypeAndRatingCount,
} from './ConsumerGroupOrderRatingModalUtilities';
import { OrderRatingSectionFoodItem } from './OrderRatingSectionFoodItem';
import { ShefHeader } from './ShefHeader';

interface ShefRatingSectionProps {
  order: ConsumerGroupOrderFieldsFragment['orders'][number];
  ignoreFoodTags: string[];
  negativeFoodTags: string[];
  onFoodItemRatingUpdate: (update: FoodItemRatingUpdate) => void;
  orderRatingsByOrderId: OrderRatingsByOrderId;
  positiveFoodTags: string[];
  className?: string;
}

export const ShefRatingSection = ({
  order,
  ignoreFoodTags,
  negativeFoodTags,
  onFoodItemRatingUpdate,
  orderRatingsByOrderId,
  positiveFoodTags,
  className,
}: ShefRatingSectionProps): JSX.Element => {
  const { id: orderId, publicShef, lineItems } = order;
  const sortedLineItems = getLineItemsSortedByFoodTypeAndRatingCount(lineItems);
  const { foodItemRatings } = orderRatingsByOrderId[orderId];

  return (
    <GroupOrderFoodItemRatings className={className}>
      <ShefHeader publicShef={publicShef} />
      <h4 className='m-4 sm:m-6'>Review your shef</h4>
      <p className='m-4 sm:m-6'>
        Focus your review on the quality of food you received and your experience with this specific Shef.
      </p>

      {sortedLineItems.map(({ foodItem, rating: lineItemFoodItemRating }) => {
        const { id: foodItemId } = foodItem;
        const draftFoodItemRating = foodItemRatings.find(
          ({ foodItemId: ratedFoodItemId }) => ratedFoodItemId === foodItemId
        );
        const existingFoodItemRating = lineItemFoodItemRating
          ? createFoodItemRatingFromExistingRating({
              ...lineItemFoodItemRating,
              foodItemId,
            })
          : null;
        const foodItemRating = existingFoodItemRating ?? draftFoodItemRating;
        const disabled = existingFoodItemRating !== null;

        return (
          <OrderRatingSectionFoodItem
            foodItem={foodItem}
            foodItemRating={foodItemRating}
            ignoreFoodTags={ignoreFoodTags}
            key={`${orderId}-${foodItemId}`}
            negativeFoodTags={negativeFoodTags}
            onFoodItemRatingUpdate={onFoodItemRatingUpdate}
            orderId={orderId}
            positiveFoodTags={positiveFoodTags}
            hasBeenRated={disabled}
          />
        );
      })}
    </GroupOrderFoodItemRatings>
  );
};
