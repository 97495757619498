import { ClientEvents, ClientTrackableEvents, ShareType } from 'common/events/ClientEvents';
import { useCallback } from 'react';
import { useTracker } from 'src/shared/hooks/useTracker';

export const useTrackShare = ({
  code,
  shefId,
  location,
}: Pick<ClientTrackableEvents[ClientEvents.REFERRAL_CODE_SHARED], 'code' | 'shefId' | 'location'>) => {
  const tracker = useTracker();

  const onShare = useCallback(
    (type: ShareType) => {
      tracker.track(ClientEvents.REFERRAL_CODE_SHARED, { code, shefId, type, location });
    },
    [code, shefId, location, tracker]
  );

  return onShare;
};
