import { ISession, ISessionManager } from 'common/sessions';
import React, { useEffect, useState } from 'react';

export const SessionIdContext: React.Context<string | undefined> = React.createContext<string | undefined>(undefined);

interface IProps {
  sessionManager: ISessionManager | undefined;
}

export const SessionIdContextProvider: React.FC<React.PropsWithChildren<IProps>> = ({ children, sessionManager }) => {
  const [sessionId, setSessionId] = useState<string | undefined>(sessionManager?.getOrCreateSession()?.id);

  useEffect(() => {
    const sessionListener = (session: ISession) => {
      setSessionId(session.id);
    };

    sessionManager?.addNewSessionListener(sessionListener);

    return () => {
      sessionManager?.removeNewSessionListener(sessionListener);
    };
  }, [sessionManager, setSessionId]);

  return <SessionIdContext.Provider value={sessionId}>{children}</SessionIdContext.Provider>;
};
