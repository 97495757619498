import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Google = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <title>Google icon</title>
    <Path
      d='M15.68 8.18177C15.68 7.6145 15.6291 7.06905 15.5345 6.54541H8V9.64359H12.3055C12.1164 10.64 11.5491 11.4836 10.6982 12.0509V14.0654H13.2945C14.8073 12.669 15.68 10.6181 15.68 8.18177Z'
      fill='#4285F4'
    />
    <Path
      d='M8 16.0001C10.16 16.0001 11.9709 15.2873 13.2945 14.0655L10.6982 12.051C9.98546 12.531 9.07637 12.8219 8 12.8219C5.92 12.8219 4.15273 11.4183 3.52 9.52734H0.858185V11.5928C2.17455 14.2037 4.87273 16.0001 8 16.0001Z'
      fill='#34A853'
    />
    <Path
      d='M3.52 9.52001C3.36 9.04001 3.26545 8.53091 3.26545 8.00001C3.26545 7.4691 3.36 6.96001 3.52 6.48001V4.41455H0.858182C0.312727 5.49091 0 6.70546 0 8.00001C0 9.29455 0.312727 10.5091 0.858182 11.5855L2.93091 9.97091L3.52 9.52001Z'
      fill='#FBBC05'
    />
    <Path
      d='M8 3.18545C9.17818 3.18545 10.2255 3.59273 11.0618 4.37818L13.3527 2.08727C11.9636 0.792727 10.16 0 8 0C4.87273 0 2.17455 1.79636 0.858185 4.41455L3.52 6.48C4.15273 4.58909 5.92 3.18545 8 3.18545Z'
      fill='#EA4335'
    />
  </SVG>
);
