import { formatGqlErrors } from 'common/GqlUtilities';
import { map, uniq } from 'lodash';
import { clearCartMutation } from 'src/store/cart/utils/cartMutations';
import { getEntityList } from 'src/store/utils';
import { CreateCartEffect } from './listeners';

export const clearCartWithServer: CreateCartEffect =
  ({ gqlClient, cartSlice }) =>
  async (action, listenerApi) => {
    const { clearCartsByShefIds } = cartSlice.actions;

    const state = listenerApi.getState();
    const prevState = listenerApi.getOriginalState();
    if (!state.cart.userLoggedIn) return;

    const shefIds = clearCartsByShefIds.match(action)
      ? action.payload.shefIds || []
      : uniq(map(getEntityList(prevState.cart.lineItems), 'shefId'));
    if (shefIds.length === 0) return;

    try {
      const { errors } = await clearCartMutation({ gqlClient, shefIds });
      // FUTURE TODO: how to handle errors - likely need to reset state...
      if (errors?.length) return console.error(formatGqlErrors(errors));
    } catch (err) {
      console.error(err);
    }
  };
