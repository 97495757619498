import { UtmQueryParams } from 'common/AttributionUtils';
import QueryString from 'query-string';
import { useEffect } from 'react';
import { RouteChildrenProps } from 'react-router';
import { UtmInput } from 'src/gqlReactTypings.generated.d';
import { UserPreferences } from 'src/user-preferences/userPreferencesTypes';
import { useUserPreferences } from 'src/user-preferences/useUserPreferences';
import { useCurrentUser } from './useCurrentUserHook';

interface IProps {
  location: RouteChildrenProps['location'];
}

const getSingleQueryValue = (rawValue: string | string[] | undefined | null): string | undefined | null =>
  Array.isArray(rawValue) ? rawValue[0] : rawValue;

const mapping = {
  source: UtmQueryParams.SOURCE,
  medium: UtmQueryParams.MEDIUM,
  campaign: UtmQueryParams.CAMPAIGN,
  term: UtmQueryParams.TERM,
  content: UtmQueryParams.CONTENT,
  target: UtmQueryParams.TARGET,
  cuisine: UtmQueryParams.CUISINE,
};

export const useUtm = ({ location }: IProps) => {
  const [currentUser, loading] = useCurrentUser();
  const [{ [UserPreferences.UTM]: utmParams }, savePreferences] = useUserPreferences();

  useEffect(() => {
    const query = QueryString.parse(location.search);
    const utm: UtmInput = {};

    Object.entries(mapping).forEach(([key, queryKey]) => {
      const value = getSingleQueryValue(query[queryKey]);

      if (!value) {
        return;
      }

      utm[key] = value;
    });

    if (!Object.values(utm).some((value) => !!value)) {
      return;
    }

    savePreferences(UserPreferences.UTM, utm);

    if (!loading && currentUser && utm.target && utm.target.includes('subscription')) {
      savePreferences(UserPreferences.USER_REFERRED_FOR_SUBSCRIPTION, true);
      savePreferences(UserPreferences.SUBSCRIPTION_VISIBLE_ONLY, true);
    }
  }, [location, savePreferences, currentUser, loading]);

  return utmParams;
};
