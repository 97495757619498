import { TextAreaV2 } from 'src/shared/design-system/Input/TextAreaV2';

interface ShefCommentSectionProps {
  comment: string;
  onCommentUpdate: (comment: string) => void;
  privateComment: string;
  onPrivateCommentUpdate: (privateComment: string) => void;
}

export const ShefCommentSection = ({
  comment,
  onCommentUpdate,
  privateComment,
  onPrivateCommentUpdate,
}: ShefCommentSectionProps): JSX.Element => (
  <div className={'m-4 mb-0 sm:m-6 sm:mb-0'}>
    <div>
      <p>
        <strong>Public review:</strong> Help other customers know what to expect when ordering from this Shef.
      </p>
      <TextAreaV2
        onChange={onCommentUpdate}
        rows={6}
        value={comment}
        placeholder={'Provide helpful details about your Shef and their dishes for other customers to see...'}
      />
    </div>
    <div className='mt-4 sm:mt-6'>
      <p>
        <strong>Private feedback:</strong> Share your thoughts with your Shef directly.
      </p>
      <TextAreaV2
        onChange={onPrivateCommentUpdate}
        rows={6}
        value={privateComment}
        placeholder={'Send a private message only your Shef will see...'}
      />
    </div>
  </div>
);
