import { backoffRetry } from 'common/PromiseUtils';
import { useEffect } from 'react';
import { useLocation } from './useLocation';

/**
 * Rigging in support for anchor links on a page.
 * - Passing in "hash" to the react-router link doesn't work. And doesn't seem
 * like they want to support in their purview: https://github.com/remix-run/react-router/issues/7780
 * - react-router-hash-link was flaky / won't work if we upgrade to 6 currently
 * - This solution from: https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
 */
export const useScrollToAnchor = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash !== '') {
      // Do best effort backoff in case page hasn't finished loaded yet.
      backoffRetry(
        async () => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView();
            return true;
          }

          throw new Error('Could not find anchor element');
        },
        4,
        500
      ).catch((e) => {
        console.warn(e);
      });
    }
  }, [hash]);
};
