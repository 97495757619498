import { BorderRadius } from 'web-common/src/shared/styles';

export type ItemProps = {
  displayedText?: React.ReactNode;
  isSectionHeader?: boolean;
  label?: string | null;
  value: string | number;
};

export enum DropdownSize {
  S = 'S',
  M = 'M',
  L = 'L',
}

export type MenuPosition = {
  top?: number;
  left?: number;
  width?: number;
  transform?: string;
};

export type DropdownProps = {
  /** Always opens the dropdown menu upwards. Use if dropwn usually appears at bottom of screen. */
  alwaysOpenUpwards?: boolean;
  /** Enable autocompletre and filter dropdown items. */
  autocomplete?: boolean;
  /** Set the border radius of the dropdown. Note: Takes precendence over `rounded` which makes the dropdown a pill shape. */
  borderRadius?: BorderRadius;
  /** Additional style class. */
  className?: string;
  /** If the dropdown is disabled. */
  disabled?: boolean;
  /** If current item is selectable. */
  isItemSelectable?: (item: any) => boolean;
  /** Items to show in the dropdown. */
  items?: ItemProps[];
  /** If dropdown appearance is light. */
  light?: boolean;
  /** Max height of menu. */
  maxMenuHeight?: number;
  /** Callback when selected value changes. */
  onChange: (value: string, item: ItemProps) => void;
  /** Placeholder text. */
  placeholder?: string;
  /** Rounded dropdown style. */
  rounded?: boolean;
  /** Show the dropdown arrow. */
  showDropdownArrow?: boolean;
  /** Size of the dropdown. */
  size?: DropdownSize;
  /** On mobile/narrow screens, use the browser dropdown menu. */
  useMobileDropdown?: boolean;
  /** Value of the dropdown. */
  value?: string | number;
  /** Value to show in the dropdown. */
  valueToShow?: string | number;
};

export const DEFAULT_MAX_DROPDOWN_HEIGHT = 250;
export const SELECTED_ITEM_SELECTOR = 'div[data-selected=true]';

export type BlurType = (e?: React.FocusEvent<HTMLInputElement>) => void;
export type KeyPressType = (e: React.KeyboardEvent<HTMLInputElement>) => void;
