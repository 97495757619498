import { ExclamationTriangle } from 'src/shared/design-system/Icon';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';

const StyledSpan = styled.span`
  font-family: ${Font.Family.SANS};
  font-weight: ${Font.Weight.SEMI_BOLD};
  font-size: ${Font.Size.XXS};
  color: ${Colors.RADISH_DARK_10};
`;

const Icon = styled(ExclamationTriangle)`
  color: ${Colors.RADISH_DARK_10};
  height: 14px;
  vertical-align: middle;
  margin-right: ${Spacing.QUARTER};
`;

export const FormErrorMessage = ({ children, ...otherProps }) => (
  <StyledSpan {...otherProps}>
    <Icon />
    {children}
  </StyledSpan>
);
