import { ClientEvents } from 'common/events/ClientEvents';
import { filter, map, values } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  ConsumerGroupOrderFieldsFragment,
  CurrentUserFieldsFragment,
  GroupOrderRatingInput,
  GroupOrderReviewStatus,
  useConsumerSubmitGroupOrderRatingMutation,
} from 'src/gqlReactTypings.generated.d';
import { useRequestAppReview } from 'src/mobile-app/hooks/useRequestAppReview';
import { ConsumerGroupOrderCommentModalReferralStep } from 'src/pages/components/ratings/ConsumerGroupOrderRatingModal/ConsumerGroupOrderRatingModalReferralStep';
import { DishFirstModal } from 'src/pages/consumer/dish-first/DishFirstModal';
import { useOnMount } from 'src/shared/hooks/useOnMount';
import { useTracker } from 'src/shared/hooks/useTracker';
import { Notifications } from 'src/shared/Notifications';
import { match } from 'ts-pattern';
import { FoodItemRating } from '../constants';
import { useInitialFoodItemRating } from '../useInitialFoodItemRating';
import { OrderRating, OrderRatingsByOrderId } from './constants';
import { ConsumerGroupOrderRatingModalShefStep } from './ConsumerGroupOrderRatingModalShefStep';
import { createOrderRatingsMap, isValidGroupOrderRatingInput } from './ConsumerGroupOrderRatingModalUtilities';
import { doAllOrdersHaveAPositiveReview } from './doesAllOrdersHaveAPositiveReview';

interface ConsumerRatingModalProps {
  currentUser: CurrentUserFieldsFragment;
  groupOrder: ConsumerGroupOrderFieldsFragment;
  hide: () => void;
  ignoreFoodTags: string[];
  negativeFoodTags: string[];
  positiveFoodTags: string[];
}

// The current "step" (i.e. page) of the rating modal.
type ConsumerRatingModalStep = 'referral-beg' | 'shef';

/**
 * Modal for rating group orders.
 */
export const ConsumerGroupOrderRatingModal = ({
  currentUser,
  groupOrder,
  hide,
  ignoreFoodTags,
  negativeFoodTags,
  positiveFoodTags,
}: ConsumerRatingModalProps): JSX.Element | null => {
  const { id: groupOrderId } = groupOrder;
  const [step, setStep] = useState<ConsumerRatingModalStep>('shef');
  const tracker = useTracker();

  const [createRatingMutation, { loading }] = useConsumerSubmitGroupOrderRatingMutation();
  const [initialFoodItemRating] = useInitialFoodItemRating();
  const [orderRatingsByOrderId, setOrderRatingsByOrderId] = useState<OrderRatingsByOrderId>(
    createOrderRatingsMap({ groupOrder, initialFoodItemRating })
  );
  const { canRequestAppReview, requestAppReview } = useRequestAppReview({ type: 'post-group-order-rating-submit' });

  const groupOrderRatingInput: GroupOrderRatingInput = {
    groupOrderId,
    ratings: values(orderRatingsByOrderId),
  };

  const hasValidGroupOrderRatingInput = isValidGroupOrderRatingInput(groupOrderRatingInput);

  const updateOrderRating = useCallback(
    (orderId: number, orderRating: OrderRating) => {
      setOrderRatingsByOrderId({
        ...orderRatingsByOrderId,
        [orderId]: orderRating,
      });
    },
    [orderRatingsByOrderId]
  );

  /**
   * Updates the food item rating for the given orderID and foodItemId.
   *
   * If foodItemRating is null, remove the rating entirely.
   * Othewise add foodItemRating to the respective order rating (or overwrite if one with the same foodItemId exists).
   */
  const handleFoodItemRatingUpdate = useCallback(
    ({
      orderId,
      foodItemId,
      foodItemRating,
    }: {
      orderId: number;
      foodItemId: number;
      foodItemRating: FoodItemRating | null;
    }): void => {
      const orderRating = orderRatingsByOrderId[orderId];
      const { foodItemRatings } = orderRating;
      const filteredFoodItemRatings = filter(foodItemRatings, (rating) => rating.foodItemId !== foodItemId);

      const newFoodItemRatings: FoodItemRating[] =
        foodItemRating === null ? filteredFoodItemRatings : [...filteredFoodItemRatings, foodItemRating];

      updateOrderRating(orderId, {
        ...orderRating,
        foodItemRatings: newFoodItemRatings,
      });
    },
    [orderRatingsByOrderId, updateOrderRating]
  );

  /**
   * Given an orderId, update the order rating comment for that order.
   */
  const handleOrderRatingCommentUpdate = useCallback(
    (orderId: number, comment: string) => {
      const orderRating = orderRatingsByOrderId[orderId];

      updateOrderRating(orderId, {
        ...orderRating,
        comment,
      });
    },
    [orderRatingsByOrderId, updateOrderRating]
  );

  const handleOrderRatingPrivateCommentUpdate = useCallback(
    (orderId: number, privateComment: string) => {
      const orderRating = orderRatingsByOrderId[orderId];

      updateOrderRating(orderId, {
        ...orderRating,
        privateComment,
      });
    },
    [orderRatingsByOrderId, updateOrderRating]
  );

  const handlePartialSave = async () => {
    tracker.track(ClientEvents.GROUP_ORDER_RATING_MODAL_PARTIAL_SAVE, { groupOrderId });
    return createRatingMutation({ variables: { input: groupOrderRatingInput } });
  };

  const handleSubmit = async () => {
    tracker.track(ClientEvents.GROUP_ORDER_RATING_MODAL_SUBMIT, { groupOrderId });

    const result = await createRatingMutation({ variables: { input: groupOrderRatingInput } });
    if (result.errors) {
      const errors = map(result.errors, (error) => error.message);
      tracker.track(ClientEvents.GROUP_ORDER_RATING_MODAL_SUBMIT_FAILURE, { groupOrderId, errors });
      Notifications.error(errors[0]);
      return;
    }

    tracker.track(ClientEvents.GROUP_ORDER_RATING_MODAL_SUBMIT_SUCCESS, { groupOrderId });

    const updatedGroupOrder = result.data?.addGroupOrderRating.groupOrder;

    if (!updatedGroupOrder) return;

    const { review, orders } = updatedGroupOrder;
    const hasCompletedReview = review.status === GroupOrderReviewStatus.Completed;
    const isPositiveReview = doAllOrdersHaveAPositiveReview(orders);

    if (hasCompletedReview && isPositiveReview && canRequestAppReview) {
      hide();
      requestAppReview();
    } else if (hasCompletedReview && isPositiveReview) {
      setStep('referral-beg');
    } else {
      hide();
    }
  };

  useOnMount(() => {
    tracker.track(ClientEvents.GROUP_ORDER_RATING_MODAL_SHOW, { groupOrderId });
  });

  useEffect(() => {
    tracker.track(ClientEvents.GROUP_ORDER_RATING_MODAL_STEP, { groupOrderId, step });
  }, [groupOrderId, step, tracker]);

  const content = match(step)
    .with('referral-beg', () => <ConsumerGroupOrderCommentModalReferralStep currentUser={currentUser} close={hide} />)
    .with('shef', () => (
      <ConsumerGroupOrderRatingModalShefStep
        groupOrder={groupOrder}
        ignoreFoodTags={ignoreFoodTags}
        negativeFoodTags={negativeFoodTags}
        onBack={hide}
        onPartialSave={handlePartialSave}
        onSubmit={handleSubmit}
        onFoodItemRatingUpdate={handleFoodItemRatingUpdate}
        orderRatingsByOrderId={orderRatingsByOrderId}
        positiveFoodTags={positiveFoodTags}
        onShefRatingCommentUpdate={handleOrderRatingCommentUpdate}
        onShefRatingPrivateCommentUpdate={handleOrderRatingPrivateCommentUpdate}
        isSubmitDisabled={!hasValidGroupOrderRatingInput || loading}
      />
    ))
    .exhaustive();

  return (
    <DishFirstModal isShowing onClose={hide}>
      {content}
    </DishFirstModal>
  );
};
