import { map, mapValues } from 'lodash';
import { RootState } from 'src/store';
import { IStoredStateOptions } from 'src/store/storage/types';

export const addCurrentVersions = (storedState: {
  [key in keyof RootState]?: Omit<IStoredStateOptions, 'currentVersion'>;
}) =>
  mapValues(storedState, (options) =>
    options
      ? {
          ...options,
          currentVersion: Math.max(...map(options.versions, 'version')),
        }
      : undefined
  );
