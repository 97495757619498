import { DateTime, DateTimeJSOptions } from 'luxon';
import { Opaque } from 'type-fest';
import { DataError } from './DataError';

// TYPE

export const urlDateFormat = 'yyyy-MM-dd';

const urlDateFormatRegex = /^[12]\d{3}-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:0[1-9])|(?:[12][0-9])|(?:3[01]))$/;

export type DateString = Opaque<string, 'DateString'>;

// VALIDATION

export class DateUrlError extends DataError {
  constructor(urlDate: any) {
    super(`Invalid date format. Dates must match the format ${urlDateFormat}, but got ${urlDate} (${typeof urlDate})`);
  }
}

type DateStringValidationValidResponse = { isValid: true; dateString: DateString; dateTime: DateTime };
type DateStringValidationInvalidResponse = { isValid: false; dateString: string; dateTime: undefined };
type DateStringValidationResponse = DateStringValidationValidResponse | DateStringValidationInvalidResponse;

export const validateUrlDate = (
  urlDate: string,
  dateTimeJsOptions?: DateTimeJSOptions
): DateStringValidationResponse => {
  if (new Date(urlDate).toString() === 'Invalid Date') {
    return { isValid: false, dateString: urlDate, dateTime: undefined };
  }
  const deliveryDateFormatted = new Date(urlDate).toISOString().split('T')[0];
  const isValidFormat = urlDateFormatRegex.test(deliveryDateFormatted);
  if (!isValidFormat) return { isValid: false, dateString: deliveryDateFormatted, dateTime: undefined };

  const dateTime = DateTime.fromFormat(deliveryDateFormatted, urlDateFormat, dateTimeJsOptions);
  if (!dateTime.isValid) return { isValid: false, dateString: deliveryDateFormatted, dateTime: undefined };

  return { isValid: true, dateString: deliveryDateFormatted as DateString, dateTime };
};

export const isValidUrlDate = (urlDate: string): urlDate is DateString => validateUrlDate(urlDate).isValid;

export function assertDateString(urlDate: string, dateTimeJsOptions?: DateTimeJSOptions): DateString {
  const validationResponse = validateUrlDate(urlDate, dateTimeJsOptions);
  if (!validationResponse.isValid) throw new DateUrlError(urlDate);
  return validationResponse.dateString;
}

export function assertDateStrings(urlDates: string[]): DateString[] {
  return urlDates.map((date) => assertDateString(date, undefined));
}

// FORMATTING

export const formatUrlDate = (date: Date, timezone: string): DateString =>
  DateTime.fromJSDate(date, { zone: timezone }).toFormat(urlDateFormat) as DateString;

export const formatDateTimeUrlDate = (date: DateTime): DateString => date.toFormat(urlDateFormat) as DateString;

export const urlDateToDateTime = (urlDate: string, timezone: string): DateTime => {
  const validationResponse = validateUrlDate(urlDate, { zone: timezone });
  if (!validationResponse.isValid) throw new DateUrlError(urlDate);
  return validationResponse.dateTime;
};

export const dateStringToDateTime = (dateString: DateString, timezone: string = DateTime.local().zoneName): DateTime =>
  DateTime.fromFormat(dateString, urlDateFormat, { zone: timezone });

export const formatDateString = (urlDate: string, fmt: string): string => urlDateToUtcDateTime(urlDate).toFormat(fmt);

export const urlDateToUtcDateTime = (urlDate: string): DateTime => {
  const validationResponse = validateUrlDate(urlDate, { zone: DateTime.utc().zoneName });
  if (!validationResponse.isValid) throw new DateUrlError(urlDate);
  return validationResponse.dateTime;
};

export const currentUrlDate = (timezone = DateTime.local().zoneName): DateString => formatUrlDate(new Date(), timezone);

export const isoDateToUrlDate = (isoString: string): DateString => assertDateString(isoString.slice(0, 10));

export const addDateToDateString = (days: number, dateString: DateString): DateString =>
  DateTime.fromFormat(dateString, urlDateFormat).plus({ days }).toFormat(urlDateFormat) as DateString;
