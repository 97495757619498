export type ModalAnimation = 'none' | 'fade';

export type FocusableElement = { focus: (options?: FocusOptions) => void };

export const findOrCreateModalRoot = (id = 'shef-modal-root') => {
  const element = document.querySelector(`#${id}`);

  if (element) {
    return element;
  }

  const newElement = document.createElement('div');
  newElement.setAttribute('id', id);
  document.body.appendChild(newElement);

  return newElement;
};
