import { EntityState, PayloadAction } from '@reduxjs/toolkit';
import { Maybe } from 'graphql/jsutils/Maybe';
import {
  AddOnItemType,
  BulkDiscountTier,
  CelebrityEventType,
  DishFirstFoodItemFragment,
  EditableOrderFragment,
  FoodItem,
} from 'src/gqlReactTypings.generated.d';
import { ISegmentMetrics } from 'src/pages/consumer/explore/SegmentAnalytics';
import { initialState } from './cart';

// Entity Types

// line items
export interface CartLineItemV1 {
  id: number;
  shefId: string;
  foodItemId: number;
  quantity: number;
  // used to store overage when we force items to have a lower quantity
  // after changing delivery dates or when an update to food item availabilities
  // comes in with a reduced availability for an item. for now this is just used
  // to decide to show a notification that we reduced the quantity, but in the
  // future it could be used to add items back if user changes delivery dates again
  overageQuantity?: number;
}

export interface CartLineItemV2 extends CartLineItemV1 {
  createdAt: string; // iso date
  updatedAt: string; // iso date
}

export interface CartLineItemV3 extends CartLineItemV2 {
  customizationIds?: Maybe<string[]>;
}

export type CartLineItem = CartLineItemV3;

export type CartLineItemInput = Pick<CartLineItem, 'quantity' | 'foodItemId'>;

// line items + food item data
export type CartFoodItemV1 = Pick<
  FoodItem,
  | 'id'
  | 'userId'
  | 'description'
  | 'foodType'
  | 'imageUrl'
  | 'ingredients'
  | 'name'
  | 'portion'
  | 'price'
  | 'slug'
  | 'spiceLevel'
  | 'vegetarian'
  | 'vegan'
  | 'lowFodmap'
  | 'lowSodium'
  | 'paleo'
  | 'keto'
  | 'kosher'
  | 'whole30'
  | 'isHalal'
  | 'isOrganic'
  | 'containsEggs'
  | 'containsNuts'
  | 'containsSoy'
  | 'containsShellfish'
  | 'isPopular'
  | 'glutenFree'
  | 'dairyFree'
  | 'packaging'
  | 'capacity'
  | 'spiceLevelCustomizations'
  | 'servingSizeCustomizations'
  | 'servingSize'
  | 'portion'
>;

// There's really only one CartFoodItem, we could delete V2, V3 and V4
export type CartFoodItemV2 = CartFoodItemV1 & {
  availability: FoodItemAvailability[];
};

export type CartFoodItemV3 = CartFoodItemV2 & Pick<FoodItem, 'discountForTakeRateShiftExperiment'>;
export type CartFoodItemV4 = CartFoodItemV2; // remove discountForTakeRateShiftExperiment

export type CartFoodItem = CartFoodItemV4;

export type WholeCartLineItem = CartLineItem & { foodItem: CartFoodItem; unitPriceWithCustomizations: number };
export type ServerWholeCartLineItem = Omit<CartLineItem, 'id'> & { foodItem: CartFoodItem };

export interface CartLineItemsById {
  [foodItemId: number]: WholeCartLineItem | undefined;
}

export interface LoadingCartShef {
  id: string;
  loading: true;
  deliveryDate: string; // url date
  availability?: undefined;
  shefProfile?: undefined;
  syncedAt?: undefined;
}

export interface CartShefAvailability {
  availabilityDate: string;
  isSoldOut: boolean;
  numDishesAvailable: number;
}

export type CartBulkDiscountTier = Pick<BulkDiscountTier, 'minSubtotal' | 'maxSubtotal' | 'discountRate'>;

export interface LoadedCartShef {
  id: string; // shefId
  loading: false;
  deliveryDate: string; // url date
  name: string;
  timeZone: string;
  availability: CartShefAvailability[];
  imageUrl: string;
  celebrityEventType?: CelebrityEventType | null;
  shefProfile: {
    cuisineRegion?: string | null;
    businessName?: string | null;
    cutoffMsBeforeDeliveryDate: number;
    dishCapacity: number;
    isTrialShef: boolean;
  };
  syncedAt: string; // iso string
}

export type CartShef = LoadingCartShef | LoadedCartShef;

export type FoodItemAvailability = { availabilityDate: string; numAvailable?: number | null | undefined };

export type FoodItemsAvailabilities = {
  id: number; // food item id
  availabilities: {
    [availabilityDate: string]: number | null | undefined;
  };
  syncedAt: string;
};

// add on items
export interface AddOnItem {
  id: string;
  name: string;
  displayText: string;
  price: number;
  extraText?: Maybe<string>;
  imageUrl: string;
  addonType: AddOnItemType;
}

// TODO: find and fix all ServerAddOnItem references...
export interface ServerAddOnItem extends Omit<AddOnItem, 'id'> {
  id: number;
}

export type CartAddOnItemV1 = AddOnItem & {
  addOnItemId: number;
  quantity: number;
  shefId: string;
};

// TODO: rename
export type CartAddOnItemV2 = ServerAddOnItem & { quantity: number };

export type CartAddOnItem = CartAddOnItemV2;

export interface CartOrder {
  shef: LoadedCartShef;
  lineItems: [WholeCartLineItem, ...WholeCartLineItem[]];
  deliveryDate: string;
  capacityInfo: CapacityInfo;
}

export type CapacityInfo = {
  isOrderingAllRemainingItems: boolean;
  isOrderingMoreThanDeliveryDateCapacity: boolean;
  dishCapacity: number;
  numDishesAvailable: number;
  numDishesRemaining: number;
};

// editable order types
// allow empty {} for the time being, immer doesn't allow complex types to be replace with primitives
// eslint-disable-next-line @typescript-eslint/ban-types
export type EditableOrder = EditableOrderFragment | {};

// Action Types
// delivery date
export type SetDeliveryDateAction = PayloadAction<string>;

// line items
export type CartActionLocation =
  | 'cart'
  | 'consumer-order'
  | 'dish-first-explore-page'
  | 'dish-first-free-delivery-modal'
  | 'dish-first-modal'
  | 'dish-first-modal-more-items'
  | 'other'
  | 'people-also-ordered'
  | 'pre-checkout-upsell-modal';

export type IncLineItemQuantityAction = PayloadAction<{
  foodItem: CartFoodItem;
  deliveryDate: string;
  location: CartActionLocation;
  segmentMetrics?: ISegmentMetrics;
  quantity?: number;
  idx?: number;
  customizationIds?: string[];
}>;
export type SetLineItemQuantityAction = PayloadAction<{
  foodItem: CartFoodItem;
  quantity: number;
  deliveryDate: string;
  location: CartActionLocation;
  customizationIds?: string[];
}>;
export type UpdateFoodItemAvailabilitiesForDateAction = PayloadAction<{
  availabilityDate: string;
  availabilityUpdates: Array<{ id: CartFoodItem['id']; availability: FoodItemAvailability['numAvailable'] }>;
}>;

// add-on items
export type IncAddOnItemQuantityAction = PayloadAction<{ addOnItem: ServerAddOnItem }>;
export type SetAddOnItemQuantityAction = PayloadAction<{ addOnItem: ServerAddOnItem; quantity: number }>;

export type SetShefsAction = PayloadAction<Array<LoadedCartShef>>;
export type UpdateShefAction = PayloadAction<Pick<LoadedCartShef, 'id' | 'deliveryDate'>>;
export type UpdateShefAvailabilitiesForDateAction = PayloadAction<{
  availabilityDate: string;
  availabilityUpdates: Array<{ id: LoadedCartShef['id']; availability: CartShefAvailability['numDishesAvailable'] }>;
}>;
export type SetActiveCartAction = PayloadAction<{ shefId: string; deliveryDate: string }>;

export type ShefIdsAction = PayloadAction<{ shefIds: string[] }>;

export type SetServerCartAction = PayloadAction<{
  recentlyAdded: boolean;
  foodItems: ServerWholeCartLineItem[];
  possibleDeliveryDates?: string[];
  bulkDiscountTiers?: CartBulkDiscountTier[] | null;
}>;

export type SetPossibleDeliveryDatesAction = PayloadAction<{
  possibleDeliveryDates: string[];
}>;

export type SetSkipTomorrowDefaultDate = PayloadAction<{
  skipTomorrowDefaultDate: boolean;
}>;

export type SetAvailableAddOnsAction = PayloadAction<{
  availableAddOnItems: ServerAddOnItem[];
}>;

export type SetEditableOrderAction = PayloadAction<
  Partial<
    Omit<typeof initialState, 'lineItems' | 'foodItems' | 'addOnItems' | 'availableAddOnItems' | 'shefs'> & {
      foodItems: CartFoodItem[];
      lineItems: CartLineItem[];
      addOnItems: CartAddOnItem[];
      availableAddOnItems: ServerAddOnItem[];
      shefs: CartShef[];
    }
  >
>;

export type QueueableAction = IncLineItemQuantityAction | SetLineItemQuantityAction;
export type QueueAction = PayloadAction<QueueableAction>;

// Enums
export enum TippingStyle {
  UNINITIALIZED = 'uninitialized',
  FIXED_AMOUNT = 'fixed_amount',
  PERCENTAGE = 'percentage',
}

// Reducer State
export interface CartStateV1 {
  isMultiCart: boolean;
  isMultiCartTreatment: boolean;
  isStandardDeliveryZip: boolean;
  userLoggedIn: boolean;
  ready: boolean;
  recentlyAdded: boolean;
  activeCartShefId: string;
  shefs: EntityState<CartShef>;
  foodItems: EntityState<CartFoodItemV1>;
  lineItems: EntityState<CartLineItemV1>;
  addOnItems: EntityState<CartAddOnItemV1>;
  availableAddOnItems: EntityState<ServerAddOnItem>;
  foodItemsAvailabilities: EntityState<FoodItemsAvailabilities>;
  tippingStyle: TippingStyle;
  shefTip: number;
  shefTipPercent: number | null;
  // below fields only used by editableOrderCart
  orderId: number | string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  rawOrderData: EditableOrder | {};
}

export interface CartStateV2 extends Omit<CartStateV1, 'addOnItems'> {
  // top-level delivery date for dish-first single delivery date feature
  deliveryDate: string;
  addOnItems: EntityState<CartAddOnItem>;
}

export interface CartStateV3 extends CartStateV2 {
  zipCode: string;
  possibleDeliveryDates: string[];
  requireUserAcknowledgeDeliveryDateUpdate: boolean;
  queuedActions: QueueableAction[];
  rawLoadedDishFirstFoodItems: { [identifier: number]: DishFirstFoodItemFragment };
}

export interface CartStateV4 extends Omit<CartStateV3, 'isStandardDeliveryZip'> {
  isMultiCartFeatureEnabled: boolean;
}

export type CartStateV5 = Omit<CartStateV4, 'rawLoadedDishFirstFoodItems'>;

export interface CartStateV6 extends CartStateV5 {
  minimumFreeShippingAmount: number;
}

export interface CartStateV7 extends CartStateV6 {
  zipCodeTimeZone: string;
}

export type CartStateV8 = Omit<CartStateV7, 'zipCodeTimeZone' | 'minimumFreeShippingAmount'>;

export interface CartStateV9 extends CartStateV8 {
  lastLineItemAction: IncLineItemQuantityAction | SetLineItemQuantityAction | undefined;
}

export interface CartStateV10 extends CartStateV9 {
  skipTomorrowDefaultDate: boolean;
}

export interface CartStateV11 extends Omit<CartStateV10, 'lineItems'> {
  lineItems: EntityState<CartLineItemV2>;
}

export interface CartStateV12 extends CartStateV11 {
  includeSupplyVarietyShefs: boolean;
}

export type CartStateV13 = Omit<CartStateV12, 'includeSupplyVarietyShefs'>;

export interface CartStateV14 extends Omit<CartStateV13, 'lineItems'> {
  lineItems: EntityState<CartLineItemV3>;
}
export interface CartStateV15 extends CartStateV14 {
  bulkDiscountTiers: CartBulkDiscountTier[];
}

export interface CartStateV16 extends CartStateV15 {
  discountApplied: number;
  promoApplied: number;
}

export interface CartStateV17 extends Omit<CartStateV16, 'foodItemsAvailabilities'> {
  foodItems: EntityState<CartFoodItemV2>;
}

export interface CartStateV18 extends Omit<CartStateV17, 'foodItems'> {
  foodItems: EntityState<CartFoodItemV3>;
  promoCode: string | undefined;
}

export interface CartStateV19 extends CartStateV18 {
  regionId: number | undefined;
}

export interface CartStateV20 extends Omit<CartStateV19, 'discountForTakeRateShiftExperiment' | 'foodItems'> {
  foodItems: EntityState<CartFoodItemV4>;
}

export type CartState = CartStateV20;
