import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';
import { useHistory } from '../hooks/useHistory';
import { Routes } from '../Routes';
import { locationInRoutes } from './RouteUtilities';

export const useIsOnSearchPage = () => {
  const history = useHistory();
  const { isSmallScreen } = useScreenContext();
  const isOnSearchPage = locationInRoutes(history.location, [Routes.CONSUMER_ORDER_SEARCH]);
  return { isOnSearchPage, isOnSearchPageMobile: isOnSearchPage && isSmallScreen };
};
