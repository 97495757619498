import { sortBy, takeRightWhile } from 'lodash';
import { IStorage } from 'src/shared/storage/Storage';
import { saveKey } from 'src/store/storage/save';
import { IStoredStateOptions } from 'src/store/storage/types';

export const migrate = (
  storage: IStorage,
  state: any,
  persistedVersionNumber: number,
  options: IStoredStateOptions
): any => {
  const [, ...versionsOverOne] = options.versions;
  const sortedVersions = sortBy(versionsOverOne, 'version');
  const nextVersions = takeRightWhile(sortedVersions, ({ version }) => version > persistedVersionNumber);

  return nextVersions.reduce((currState, versionData) => {
    const nextState = versionData.migration(currState);
    // save result of migration back into local storage so migrations
    // do not continually run each time the store is created
    saveKey(storage, nextState, options.key, versionData.version, options.expiresAt);
    return nextState;
  }, state);
};
