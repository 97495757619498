import styled from 'styled-components';
import { Font } from 'web-common/src/shared/styles';
import { breakpointWidth } from '../constants';

export const Subtitle0 = styled.p`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: normal;

  font-size: 30px;
  line-height: 40px;

  @media only screen and (max-width: ${breakpointWidth}px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const Body = styled.div`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: normal;

  font-size: 18px;
  line-height: 28px;

  strong {
    font-weight: 600;
  }

  @media only screen and (max-width: ${breakpointWidth}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const BodySmall = styled.p`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: normal;

  font-size: 14px;
  line-height: 24px;
`;

export const DetailCallout = styled.p`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: 300;

  font-size: 40px;
  line-height: 50px;

  @media only screen and (max-width: ${breakpointWidth}px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const DetailInfo = styled.p`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: normal;

  font-size: 24px;
  line-height: 34px;

  @media only screen and (max-width: ${breakpointWidth}px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const DetailCapsLarge = styled.p`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;

  font-size: 18px;
  line-height: 18px;
`;

export const DetailCapsSmall = styled.p`
  margin: 0;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;

  font-size: 12px;
  line-height: 14px;
`;

// Breakpoint only present on mobile
export const MobileBr = styled.br<{ breakPoint?: number }>`
  display: none;
  @media only screen and (max-width: ${({ breakPoint }) => breakPoint}px) {
    display: unset;
  }
`;

MobileBr.defaultProps = {
  breakPoint: breakpointWidth,
};
