import { Slugs } from 'common/urls/Slugs';
import { Routes } from 'src/shared/Routes';
import { match, P } from 'ts-pattern';
import { getSluggedPath } from './RouteUtilities';

interface GetReferralUrlParams {
  code: string;
  shefId?: string;
  foodItemSlug?: string;
  orderUuid?: string;
  userId?: string;
}

export const getReferralUrl = (params: GetReferralUrlParams, injectedWindow?: any) => {
  const path = match(params)
    .with({ shefId: P.string, foodItemSlug: P.string }, ({ code, shefId, foodItemSlug }) =>
      getSluggedPath(Routes.CONSUMER_REFER_FOOD_ITEM, [
        { slug: Slugs.ID, value: code },
        { slug: Slugs.SHEF_ID, value: shefId },
        { slug: Slugs.NAME, value: foodItemSlug },
      ])
    )
    .with({ shefId: P.string }, ({ code, shefId }) =>
      getSluggedPath(Routes.CONSUMER_REFER_SHEF, [
        { slug: Slugs.ID, value: code },
        { slug: Slugs.SHEF_ID, value: shefId },
      ])
    )
    .with({ orderUuid: P.string, userId: P.string }, ({ orderUuid, userId }) =>
      getSluggedPath(Routes.CONSUMER_REFER_ORDER, [
        { slug: Slugs.ID, value: userId },
        { slug: Slugs.ORDER, value: orderUuid },
      ])
    )
    .otherwise(({ code }) => getSluggedPath(Routes.CONSUMER_REFER, [{ slug: Slugs.ID, value: code }]));

  return `${(injectedWindow ?? window).location.origin}${path}`;
};
