type KeyType = string | number;

type CleanProps<T> = {
  [K in keyof T as T[K] extends KeyType | undefined ? K : never]: T[K];
};

type PropKey<T> = Exclude<keyof CleanProps<T>, symbol>;

/**
 * @deprecated Please use web-common version
 */
export const switchProp =
  <T extends Record<any, any>, U, K extends PropKey<T>>(
    key: K,
    matcher: Partial<Record<CleanProps<T>[K], U>>,
    defaultValue?: U
  ) =>
  (props: T) => {
    const value = props[key];
    const def = defaultValue === undefined ? '' : defaultValue;

    if (!value) {
      return def;
    }

    return matcher[value] ?? def;
  };
