import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './lisa-huynh.jpg';

export const LisaHuynh: PersonInfo = {
  firstName: 'Lisa',
  lastName: 'Huynh',
  image,
  pronoun: Pronoun.SHE,
  role: ROLE.SOFTWARE_ENGINEER,
};
