/* eslint-disable functional/immutable-data -- Assigning variants to buttons */
import styled, { AnyStyledComponent } from 'styled-components';
import { ButtonColors, ButtonSizes } from './types';

// NOTE: Instead of defining these function using Object.key(ButtonColors),
// defining each key to preserve autofill/types.

export const createButtonColorVariants = (Component: AnyStyledComponent) => ({
  DEFAULT: styled(Component).attrs((props) => ({ ...props, color: ButtonColors.DEFAULT }))``,
  RED: styled(Component).attrs((props) => ({ ...props, color: ButtonColors.RED }))``,
  GRAY: styled(Component).attrs((props) => ({ ...props, color: ButtonColors.GRAY }))``,
  LIGHT_GRAY: styled(Component).attrs((props) => ({ ...props, color: ButtonColors.LIGHT_GRAY }))``,
  DARK_GRAY: styled(Component).attrs((props) => ({ ...props, color: ButtonColors.DARK_GRAY }))``,
  BLUE: styled(Component).attrs((props) => ({ ...props, color: ButtonColors.BLUE }))``,
  YELLOW: styled(Component).attrs((props) => ({ ...props, color: ButtonColors.YELLOW }))``,
  WHITE: styled(Component).attrs((props) => ({ ...props, color: ButtonColors.WHITE }))``,
});

export const createButtonSizeVariants = (Component: AnyStyledComponent) => ({
  XS: styled(Component).attrs((props) => ({ ...props, size: ButtonSizes.XS }))``,
  S: styled(Component).attrs((props) => ({ ...props, size: ButtonSizes.S }))``,
  M: styled(Component).attrs((props) => ({ ...props, size: ButtonSizes.M }))``,
  L: styled(Component).attrs((props) => ({ ...props, size: ButtonSizes.L }))``,
});

/**
 * Creates variants for button. Adds dot notation for button sizes and colors.
 * e.g:
 *  - SIZES -
 *  Button.XS
 *  Button.S
 *  Button.M
 *  Button.L
 *  ...
 *  -  COLOR -
 *  Button.RED
 *  Button.GRAY
 *  Button.BLUE
 *  ...
 *  - SIZES + COLOR -
 *  Button.XS.RED
 *  Button.XS.GRAY
 *  Button.XS.BLUE
 *  ...
 * @param Component Button component to create variants.
 */
export const createButtonVariants = (Component: AnyStyledComponent) => {
  const sizeVariants = createButtonSizeVariants(Component);
  const variants = {
    XS: Object.assign(sizeVariants.XS, createButtonColorVariants(sizeVariants.XS)),
    S: Object.assign(sizeVariants.S, createButtonColorVariants(sizeVariants.S)),
    M: Object.assign(sizeVariants.M, createButtonColorVariants(sizeVariants.M)),
    L: Object.assign(sizeVariants.L, createButtonColorVariants(sizeVariants.L)),
  };
  return Object.assign(Component, variants, createButtonColorVariants(Component));
};
/* eslint-enable functional/immutable-data -- Assigning variants to buttons */
