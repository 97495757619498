export type FulfilledResult<T> = { status: 'fulfilled'; value: T };
export type RejectedResult<E> = { status: 'rejected'; reason: E };
export type AllSettledResult<T, E> = (FulfilledResult<T> | RejectedResult<E>)[];

/**
 * Stand-in for Promise.allSettled, which only exists in node v12+
 * @param promises
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function allSettled<T, E = any>(promises: Promise<T>[]): Promise<AllSettledResult<T, E>> {
  const settledPromises = promises.map((promise) =>
    promise
      .then((value: T): FulfilledResult<T> => ({ status: 'fulfilled', value }))
      .catch((reason: E): RejectedResult<E> => ({ status: 'rejected', reason }))
  );

  return Promise.all(settledPromises);
}

export const sleep = (milliseconds: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

/**
 * maps {@param list} using an async {@param predicate}
 * an async standin for Array.map.
 *
 * This will NOT run your Promises concurrently. Prefer
 * `Promise.all` in those cases.
 * @param list
 * @param predicate
 */
export const asyncMap = async <S, T>(
  list: readonly S[],
  predicate: (item: S, idx: number) => Promise<T>
): Promise<T[]> => {
  const results: T[] = [];
  for (const [idx, item] of list.entries()) {
    const result = await predicate(item, idx);
    results.push(result);
  }
  return results;
};

/**
 * filters {@param list} using an async {@param predicate}
 * an async standin for Array.filter
 * @param list
 * @param predicate
 * @param accumulator
 */
interface IAsyncFilterOptions {}
export const asyncFilter = async <T>(
  list: readonly T[],
  predicate: (item: T, idx: number) => Promise<boolean>,
  _options?: IAsyncFilterOptions
): Promise<T[]> => {
  const results = await Promise.all(list.map(predicate));
  return list.filter((_, index) => results[index]);
};

interface IAsyncReduceOptions {}
/**
 * reduces {@param list} using an async {@param reducer} initialized with an
 * {@param accumulator}
 * an async standin for Array.prototype.reduce
 * @param list
 * @param reducer
 * @param accumulator
 */
export const asyncReduce = async <T, U>(
  list: readonly T[],
  reducer: (item: T, acc: U) => Promise<U>,
  accumulator: U,
  _options?: IAsyncReduceOptions
): Promise<U> => {
  let acc = accumulator;
  for (const item of list) {
    acc = await reducer(item, acc);
  }
  return acc;
};

/**
 * Reduces {@param list} from right to left using an async {@param reducer}
 * initialized with an {@param accumulator}
 * An async standin for Array.prototype.reduceRight
 * @param list
 * @param reducer
 * @param accumulator
 */
export const asyncReduceRight = async <T, U>(
  list: readonly T[],
  reducer: (item: T, acc: U) => Promise<U>,
  accumulator: U,
  _options?: IAsyncReduceOptions
): Promise<U> => {
  let acc = accumulator;
  for (let i = list.length - 1; i >= 0; i--) {
    const item = list[i];
    acc = await reducer(item, acc);
  }
  return acc;
};

export const promiseTimeout = async <T>(promise: Promise<T>, timeoutMs: number): Promise<T> => {
  const timeout = new Promise<T>((_, reject) => {
    setTimeout(() => reject(new Error(`Timed out after ${timeoutMs}ms`)), timeoutMs);
  });

  return Promise.race([promise, timeout]);
};

export const wait = (ms: number): Promise<true> => new Promise((resolve) => setTimeout(() => resolve(true), ms));

export const backoffRetry = async <T>(callback: () => Promise<T>, tries: number, ms: number): Promise<T> => {
  try {
    return await callback();
  } catch (e) {
    if (tries === 1) {
      throw e;
    }
    await wait(ms);
    return await backoffRetry(callback, tries - 1, ms * 2);
  }
};

export const waitUntil = async (
  condition: () => boolean,
  retryIntervalMs: number,
  maxNumberOfRetries: number
): Promise<void> => {
  const poll = (numTriesSoFar: number) => (resolve: () => void, reject: (error: string) => void) => {
    if (condition()) {
      resolve();
    } else if (numTriesSoFar === maxNumberOfRetries - 1) {
      reject(`Condition not met within ${maxNumberOfRetries * retryIntervalMs}ms`);
    } else {
      setTimeout(() => poll(numTriesSoFar + 1)(resolve, reject), retryIntervalMs);
    }
  };

  return new Promise(poll(0));
};
