import { noOp } from 'common/Constants';
import * as React from 'react';
import { useEffect } from 'react';
import { LottieBowl } from 'src/shared/design-system/Animations';
import styled, { css } from 'styled-components';
import { Colors, ZIndex } from 'web-common/src/shared/styles';
import { useViewHeight } from '../../utils/view-height-fix';

interface ILoadingProps {
  delayMs?: number;
  size?: number;
}

export function Loading({ delayMs = 0, size = 100 }) {
  const [delayExpired, setDelayExpired] = React.useState(false);

  useEffect(() => {
    if (delayMs) {
      const timer = setTimeout(() => {
        setDelayExpired(true);
      }, delayMs);

      return () => clearTimeout(timer);
    }
    return noOp;
  }, [delayMs]);

  if (delayExpired || !delayMs) {
    return <LottieBowl style={{ width: `${size}px`, height: `${size}px`, margin: 'auto' }} />;
  }
  return null;
}

interface ILoadingWithHeightProps extends ILoadingProps {
  minHeightVh?: number;
  marginTopVh?: number;
}

export const LoadingWithHeight: React.FC<ILoadingWithHeightProps> = (props) => {
  const { getPx } = useViewHeight();
  const { minHeightVh = 100, marginTopVh = 0, ...restProps } = props;

  return (
    <div style={{ minHeight: getPx(minHeightVh), marginTop: getPx(marginTopVh) }}>
      <Loading {...restProps} />
    </div>
  );
};

export const FixedLoadingContainer = styled.div<{ size: number; showAtBottomOfScreen?: boolean }>`
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;

  ${({ showAtBottomOfScreen, size }) => {
    const loaderSizeValue = size + 20;
    const loaderSize = `${loaderSizeValue}px`;
    const loaderHalfSize = `${loaderSizeValue / 2}px`;
    const topPosition = showAtBottomOfScreen ? '100%' : '50%';

    return css`
      top: calc(${topPosition} - ${showAtBottomOfScreen ? `${loaderSizeValue + 32}px` : loaderHalfSize});
      left: calc(50% - ${loaderHalfSize});

      border-radius: ${loaderSize};

      width: ${loaderSize};
      height: ${loaderSize};

      padding: 20px;
    `;
  }}

  z-index: ${ZIndex.fixedloading};
  background: ${Colors.WHITE};
  border: 2px solid ${Colors.GRAY_LITE_20};

  box-shadow: 0 0 14px rgb(0 0 0 / 10%);
`;

export const FixedLoading = ({ delayMs = 0, size = 100, showAtBottomOfScreen = false }) => (
  <FixedLoadingContainer size={size} showAtBottomOfScreen={showAtBottomOfScreen}>
    <Loading delayMs={delayMs} size={size}></Loading>
  </FixedLoadingContainer>
);

export default Loading;
