import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const ExclamationTriangle = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <title>Exclamation triangle</title>
    <desc>A red triangle with an exclamation mark</desc>
    <Path
      d='M15.8245 12.0312L9.17134 0.65625C8.64033 -0.21875 7.35967 -0.21875 6.8599 0.65625L0.175457 12.0312C-0.324314 12.9062 0.3004 14 1.33118 14H14.6688C15.6996 14 16.3243 12.9062 15.8245 12.0312ZM7.26596 4.25C7.26596 3.84375 7.57832 3.5 8.01562 3.5C8.42168 3.5 8.76528 3.84375 8.76528 4.25V8.25C8.76528 8.6875 8.42168 9 8.01562 9C7.64079 9 7.26596 8.6875 7.26596 8.25V4.25ZM8.01562 12C7.45338 12 7.01608 11.5625 7.01608 11.0312C7.01608 10.5 7.45338 10.0625 8.01562 10.0625C8.54663 10.0625 8.98392 10.5 8.98392 11.0312C8.98392 11.5625 8.54663 12 8.01562 12Z'
      fill='currentColor'
      stroke='none'
    />
  </SVG>
);
