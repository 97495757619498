import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './ash-todd.jpg';

export const AshTodd: PersonInfo = {
  firstName: 'Ash',
  lastName: 'Todd',
  image,
  pronoun: Pronoun.THEY,
  role: ROLE.SOFTWARE_ENGINEER,
};
