import {
  CartsDocument,
  CartShefsDocument,
  CartShefsQuery,
  CartShefsQueryVariables,
  CartsQuery,
  CartsQueryVariables,
} from 'src/gqlReactTypings.generated.d';
import { GqlClient } from 'src/shared/GqlClient';

interface FetchCartQueryParams {
  gqlClient: GqlClient;
  zipCode: string;
}

export function fetchCart({ gqlClient, zipCode }: FetchCartQueryParams) {
  return gqlClient.mutate<CartsQuery, CartsQueryVariables>({
    mutation: CartsDocument,
    variables: { zipCode },
  });
}

interface FetchCartShefsQueryParams {
  gqlClient: GqlClient;
  shefIds: string[];
}

export function fetchCartShefs({ gqlClient, shefIds }: FetchCartShefsQueryParams) {
  return gqlClient.mutate<CartShefsQuery, CartShefsQueryVariables>({
    mutation: CartShefsDocument,
    variables: { shefIds },
  });
}
