import { BulkDiscountTier } from 'common/BulkDiscountUtils';
import { ClientEvents } from 'common/events/ClientEvents';
import { EXP_VAR_TREATMENT, ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { StorageKeys } from 'common/storage/constants';
import React, { createContext, useCallback, useContext, useEffect } from 'react';
import { useVariant } from 'src/experimentation/useVariant';
import { DEFAULT_FREE_DELIVERY_THRESHOLD } from 'src/shared/design-system/BulkDiscount';
import { useBulkDiscount } from 'src/shared/hooks/useBulkDiscount';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { useModal } from 'src/shared/hooks/useModal';
import { useTracker } from 'src/shared/hooks/useTracker';
import { useLocalStorage } from 'src/shared/storage/Storage';

const defaultState = {
  isShowing: false,
  show: () => {},
  hide: () => {},
  isInTreatment: false,
  logExposure: () => {},
  bulkDiscountTiers: [],
  discountedDeliveryFee: 0,
};

type IBulkDiscountModalContext = Omit<typeof defaultState, 'hide' | 'bulkDiscountTiers'> & {
  hide: (dismiss?: boolean) => void;
  bulkDiscountTiers: BulkDiscountTier[];
};

const BulkDiscountModalContext = createContext<IBulkDiscountModalContext>(defaultState);

export const useBulkDiscountModalContext = () => useContext(BulkDiscountModalContext);

export const BulkDiscountModalProvider = ({ children }: { children: React.ReactNode }) => {
  const tracker = useTracker();

  const { variant, logExposure } = useVariant(ExperimentNames.BULK_DISCOUNT, false);
  const [seenModal, setModalShown] = useLocalStorage<boolean>(StorageKeys.BULK_UPSELL_MODAL_EXPANDED, false);

  const [user] = useCurrentUser();
  const isInTreatment = variant?.name === EXP_VAR_TREATMENT;

  const { minimumAmountForDiscountedShipping, bulkDiscountTiers, discountedDeliveryFee } = useBulkDiscount();
  const displayBulkDiscountTiers: BulkDiscountTier[] = [
    { minSubtotal: minimumAmountForDiscountedShipping ?? DEFAULT_FREE_DELIVERY_THRESHOLD, discountRate: 0 },
    ...(bulkDiscountTiers ?? []),
  ];

  const { isShowing, show: modalShow, hide: modalHide } = useModal();

  const showBulkModal = useCallback(() => {
    modalShow();
    setModalShown(true);
    tracker.track(ClientEvents.BULK_DISCOUNT_MODAL_VIEW, {});
  }, [tracker, modalShow, setModalShown]);

  const hideBulkModal = useCallback(() => {
    modalHide();
    tracker.track(ClientEvents.BULK_DISCOUNT_MODAL_CLOSE, {});
  }, [tracker, modalHide]);

  useEffect(() => {
    // Do not show by if user has already seen the modal or not in experiment.
    if (!isInTreatment || seenModal === true) {
      return;
    }

    // Show to existing users by default OR if the user is a returning user (has cookies).
    if (user || seenModal === undefined) {
      showBulkModal();
    }
  }, [user, seenModal, isInTreatment, showBulkModal]);

  return (
    <BulkDiscountModalContext.Provider
      value={{
        isShowing,
        show: showBulkModal,
        hide: hideBulkModal,
        isInTreatment,
        bulkDiscountTiers: displayBulkDiscountTiers,
        discountedDeliveryFee,
        logExposure,
      }}>
      {children}
    </BulkDiscountModalContext.Provider>
  );
};
