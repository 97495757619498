import React from 'react';
import { Link } from 'react-router-dom';
import babishLogos from 'src/assets/img/celebrity-event/babish-logos.svg';
import nyeshaLogos from 'src/assets/img/celebrity-event/nyesha-logos.svg';
import { BabishStyleLoginPromoBanner } from 'src/celebrity-event/login-banners/BabishStyleLoginPromoBanner';
import { NyeshaStyleLoginPromoBanner } from 'src/celebrity-event/login-banners/NyeshaStyleLoginPromoBanner';
import { CelebrityEventType } from 'src/gqlReactTypings.generated.d';
import { MiniBannerProps } from 'src/pages/consumer/dish-first/explore/components/Banners/components/MiniBanner';
import { IConsumerBannerProps } from 'src/shared/design-system/ConsumerBanner';
import { Routes } from 'src/shared/Routes';
import { getSluggedPath } from 'src/shared/utils/RouteUtilities';

export const CONCLUDED_CELEBRITY_EVENTS = [CelebrityEventType.BingingWithBabish, CelebrityEventType.MasterChefNyesha];
export const isConcluded = (eventType: CelebrityEventType | null | undefined): boolean =>
  eventType ? CONCLUDED_CELEBRITY_EVENTS.includes(eventType) : false;

export const CELEBRITY_EVENT_COUPONS: Record<CelebrityEventType, string | null> = {
  [CelebrityEventType.BingingWithBabish]: null,
  [CelebrityEventType.MasterChefNyesha]: 'nyesha35',
};

export const CELEBRITY_EVENT_PROMO_CODE_BANNER: Record<CelebrityEventType, React.ReactNode | null> = {
  [CelebrityEventType.BingingWithBabish]: null,
  [CelebrityEventType.MasterChefNyesha]: (
    <span>
      New Customers! Order from{' '}
      <Link className='cursor-pointer font-semibold underline' to={getSluggedPath(Routes.CONSUMER_LANDING)}>
        another shef
      </Link>{' '}
      to get 20% off and free delivery on orders of $35 or more!
    </span>
  ),
};

export const CELEBRITY_EVENT_LANDING_MENU_ID: Record<CelebrityEventType, string> = {
  [CelebrityEventType.BingingWithBabish]: 'babish-ny',
  [CelebrityEventType.MasterChefNyesha]: 'masterchef-ny',
};

export const CELEBRITY_EVENT_HAS_ORDER_LIMIT: Record<CelebrityEventType, boolean> = {
  [CelebrityEventType.BingingWithBabish]: true,
  [CelebrityEventType.MasterChefNyesha]: false,
};

export const CELEBRITY_EVENT_MAX_ORDER_ALERT: Record<CelebrityEventType, string> = {
  [CelebrityEventType.BingingWithBabish]: 'Cookie limit reached 🍪 Due to high demand only one Babish order per person',
  [CelebrityEventType.MasterChefNyesha]: 'Cookie limit reached 🍪 Due to high demand only 1 order per person',
};

// NOTE: No reason we can't customize colors and other props too
export const CELEBRITY_EVENT_UNSERVED_MARKET_EXPLANATION: Record<CelebrityEventType, IConsumerBannerProps> = {
  [CelebrityEventType.BingingWithBabish]: {
    title: "Babish isn't available right now",
    icon: <i className='fa-regular fa-cookie-bite mr-2' />,
  },
  [CelebrityEventType.MasterChefNyesha]: {
    title: "Nyesha isn't available right now",
    icon: <i className='fa-regular fa-cookie-bite mr-2' />,
  },
};

export const CELEBRITY_EVENT_SOLD_OUT_TEXT: Record<CelebrityEventType, string> = {
  [CelebrityEventType.BingingWithBabish]: 'Babish is sold out for now',
  [CelebrityEventType.MasterChefNyesha]: 'Nyesha is sold out for now',
};

export const CELEBRITY_EVENT_EXPLORE_BANNER: Record<
  CelebrityEventType,
  Pick<MiniBannerProps, 'bgColorClassName' | 'mainText' | 'supportingText' | 'ctaText'> & {
    imageUrl: string;
  }
> = {
  [CelebrityEventType.BingingWithBabish]: {
    bgColorClassName: 'bg-plum-25',
    mainText: 'Binging With Babish',
    ctaText: 'Get your cookies now!',
    imageUrl: 'https://shef-general.s3.us-west-1.amazonaws.com/assets/img/celebrity-event/Cookies+with+Overlay.png',
  },
  [CelebrityEventType.MasterChefNyesha]: {
    bgColorClassName: 'bg-plum-25',
    supportingText: 'Limited Time Offer',
    mainText: 'Celebrity Chef Nyesha Arrington',
    ctaText: 'Order Now',
    // NOTE: Keeping strawberry cookie image commented in case we're asked to swap it back
    imageUrl:
      'https://shef-general.s3.us-west-1.amazonaws.com/uploads/df5f9a9e-816d-418c-bb6b-9b0f5e960936_c6abacef-cb56-4ac4-ae9d-6882632ff471.jpg',
    // 'https://shef-general.s3.us-west-1.amazonaws.com/assets/img/celebrity-event/Nyesha+Strawberry+Cookies.jpg',
  },
};

const promoBannerImage: Record<CelebrityEventType, string> = {
  [CelebrityEventType.BingingWithBabish]:
    'https://shef-general.s3.us-west-1.amazonaws.com/assets/img/celebrity-event/Cookies+with+Overlay.png',
  [CelebrityEventType.MasterChefNyesha]:
    'https://shef-general.s3.us-west-1.amazonaws.com/assets/img/celebrity-event/Nyesha+Strawberry+Cookies.jpg',
};
export const CELEBRITY_EVENT_PROMO_BANNER: Record<CelebrityEventType, React.ReactNode> = {
  [CelebrityEventType.BingingWithBabish]: (
    <BabishStyleLoginPromoBanner
      backgroundImage={promoBannerImage[CelebrityEventType.BingingWithBabish]}
      logos={babishLogos}
      ctaText='Get the Everything Cookie now!'
    />
  ),
  [CelebrityEventType.MasterChefNyesha]: (
    <NyeshaStyleLoginPromoBanner
      backgroundImage={promoBannerImage[CelebrityEventType.MasterChefNyesha]}
      logos={nyeshaLogos}
      ctaText='Get the Stawberry Shortcake Cookie now!'
    />
  ),
};
