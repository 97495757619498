import { currentUrlDate } from 'common/UrlDate';
import { ExploreState } from './types';

export const initialExploreState: ExploreState = {
  deliveryDate: currentUrlDate(),
  foodItemMeta: undefined,
  foodItemSlug: undefined,
  shefId: undefined,
  isAllCuisinesShowing: false,
};

export const useInitialExploreState = (): ExploreState => ({ ...initialExploreState });
