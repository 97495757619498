import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './mike-murray.jpg';

export const MikeMurray: PersonInfo = {
  firstName: 'Mike',
  lastName: 'Murray',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.HEAD_OF_PRODUCT,
  otherRoles: [ROLE.PRODUCT_DESIGNER],
};
