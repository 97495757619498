import React from 'react';
import { Modal } from 'src/shared/design-system/Modal';
import { minScreen } from 'src/shared/utils/StyledComponentUtils';
import styled from 'styled-components';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';
import { ScreenWidth, Spacing } from 'web-common/src/shared/styles';

const StyledDishFirstModal = styled(Modal)`
  padding: ${Spacing.TRIPLE_HALF} 0 0;
  max-height: 720px;

  ${minScreen(ScreenWidth.SMALL)} {
    min-width: 480px;
  }
`;

interface DishFirstModalProps extends React.PropsWithChildren {
  className?: string;
  isFullScreenOnMobile?: boolean;
  isShowing: boolean;
  onClose: () => void;
}

export const DishFirstModal: React.FC<DishFirstModalProps> = ({
  children,
  className,
  isFullScreenOnMobile = true,
  isShowing,
  onClose,
}) => {
  const { isSmallScreen } = useScreenContext();
  const isFullScreen = isFullScreenOnMobile && isSmallScreen;

  return (
    <StyledDishFirstModal
      animation='fade'
      className={className}
      isFullHeight={isFullScreen}
      isFullWidth={isFullScreen}
      isShowing={isShowing}
      onClose={onClose}
      showCloseButton
      size='lg'
      blockScrollOnMount={!isSmallScreen}>
      {children}
    </StyledDishFirstModal>
  );
};
