import { JSONObj } from '../TypeUtilities';

export enum SharedEvents {
  EXPERIMENT_EXPOSURE = 'experiment-exposure',
}

export interface TrackableSharedEvents extends Record<SharedEvents, JSONObj> {
  // Description: When a user or device has been exposed to an experiment.
  [SharedEvents.EXPERIMENT_EXPOSURE]: {
    experiment: string;
    variantName: string;
  };
}
