import { StorageKeys } from 'common/storage/constants';
import { DateTime } from 'luxon';
import { versions as cartVersions } from 'src/store/cart/storage';
import { versions as checkoutVersions } from 'src/store/checkout';
import { loadState } from 'src/store/storage/load';
import { saveState } from 'src/store/storage/save';
import { IStoredState } from 'src/store/storage/types';
import { addCurrentVersions } from 'src/store/storage/version';

const CART_EXPIRATION_DAYS = 30;

export const storedState: IStoredState = addCurrentVersions({
  cart: {
    key: StorageKeys.STORE_CART_STATE,
    expiresAt: DateTime.local().plus({ days: CART_EXPIRATION_DAYS }).toMillis(),
    versions: cartVersions,
  },
  editableOrderCart: {
    key: StorageKeys.STORE_EDITABLE_ORDER_CART_STATE,
    expiresAt: DateTime.local().plus({ days: CART_EXPIRATION_DAYS }).toMillis(),
    versions: cartVersions,
  },
  checkout: {
    key: StorageKeys.STORE_CHECKOUT_STATE,
    versions: checkoutVersions,
  },
});

export const load = loadState(storedState);

export const save = saveState(storedState);
