import { capitalize } from './StringUtils';

export const capitalizeAndPunctuate = (str: string): string => {
  let newVal = capitalize(str);
  if (newVal.charAt(newVal.length - 1) !== '.') {
    newVal += '.';
  }
  return newVal;
};

export const formatCurrency = (value?: number | null, dropEmptyZeros = false): string => {
  const s = (value || 0) / 100.0;
  let text;
  if (dropEmptyZeros && s === Math.round(s)) {
    text = Math.round(s).toString();
  } else {
    text = s.toFixed(2);
  }
  return `$${text.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatDisplayPhone = (value?: string | null): string => {
  if (!value) {
    return '';
  }

  const phone = value.replace(/\D/g, '');
  const match = phone.match(/^(1|)?(\d{3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    const intlCode = match[1] ? '+1' : '';
    return [intlCode, ...match.slice(2, 5)].filter((v) => v).join('-');
  }

  return value;
};

// TODO: replace this with a localization library
export const numberToEnglish = (value: number): string => {
  const namedNumbers = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
  ];
  const result = namedNumbers[value];
  if (result === undefined) {
    throw new Error('The number cannot be formated to english');
  }
  return result;
};

export const minutesSinceMidnightToReadableClockTime = (minutesSinceMidnight: number): string => {
  const minutesPerDay = 24 * 60;
  const normalizedMinutesSinceMidnight = minutesSinceMidnight % minutesPerDay;
  const hoursSinceMidnight = Math.floor(normalizedMinutesSinceMidnight / 60);
  // 0 -> 12, 1 -> 1, ..., 12 -> 12, 13 -> 1, ...
  const hourNumeral = hoursSinceMidnight % 12 === 0 ? 12 : hoursSinceMidnight % 12;
  const minutesPastHour = normalizedMinutesSinceMidnight % 60;
  const minutesNumeral = minutesPastHour < 10 ? `0${minutesPastHour}` : String(minutesPastHour);
  const amPm = normalizedMinutesSinceMidnight < 12 * 60 ? 'AM' : 'PM';

  return `${hourNumeral}:${minutesNumeral} ${amPm}`;
};

/* https://stackoverflow.com/a/13627586/5602521 */
export const integerToEnglishOrdinal = (intToFormat: number): string => {
  const onesDigit = intToFormat % 10;
  // Ordinal is different if the word ends in 11, 12, or 13:
  const teenChecker = intToFormat % 100;
  if (onesDigit === 1 && teenChecker !== 11) {
    return `${intToFormat}st`;
  }
  if (onesDigit === 2 && teenChecker !== 12) {
    return `${intToFormat}nd`;
  }
  if (onesDigit === 3 && teenChecker !== 13) {
    return `${intToFormat}rd`;
  }
  return `${intToFormat}th`;
};

const socialHandleRegex = /([a-zA-Z\d-_.]+)\/?$/;
export const normalizeSocialHandle = (input: string | null | undefined): string | null => {
  const match = input?.match(socialHandleRegex);
  return match ? match[match.length - 1] : null;
};

export const roundUpToNearest49or99 = (value: number) => Math.ceil(value * 2 + 0.001) / 2 - 0.01;
