import { noOp } from 'common/Constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { ItemProps, MenuPosition } from './consts';
import { getElementClientRect, getItemDisplayValue, getMenuPosition, getMenuPositionTransformStyle } from './utils';

type MenuPositionProps = { isOpen: boolean; alwaysOpenUpwards?: boolean; rounded?: boolean };

/**
 * Determines if menu should open upwrads or downwards.
 * @param isOpen: If the dropdown menu is open.
 */
export const useMenuPosition = (props: MenuPositionProps) => {
  const { isOpen, alwaysOpenUpwards, rounded } = props;
  const dropdownRef = useRef<Autocomplete | null>(null);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>({
    transform: getMenuPositionTransformStyle({ flushToDropdown: !rounded, shouldOpenDownwards: !alwaysOpenUpwards }),
  });

  const calcMenuPosition = useCallback(() => {
    if (isOpen && dropdownRef.current?.refs) {
      const { input, menu } = dropdownRef.current.refs;
      if (input && menu) {
        const inputClientRect = getElementClientRect(input);
        const menuClientRect = getElementClientRect(menu);
        setMenuPosition(
          getMenuPosition({ flushToDropdown: !rounded, inputClientRect, menuClientRect, alwaysOpenUpwards })
        );
      }
    }
  }, [alwaysOpenUpwards, isOpen, rounded]);

  useEffect(() => {
    if (isOpen) {
      calcMenuPosition();
    }
  }, [isOpen, calcMenuPosition]);

  useEffect(() => {
    window.addEventListener('resize', calcMenuPosition);
    window.addEventListener('scroll', calcMenuPosition);
    return () => {
      window.removeEventListener('resize', calcMenuPosition);
      window.removeEventListener('scroll', calcMenuPosition);
    };
  }, [calcMenuPosition]);

  return {
    ref: (ref: Autocomplete) => {
      dropdownRef.current = ref;
    },
    menuPosition,
  };
};

type TypeFilterProps = { autocomplete: boolean; isOpen: boolean; items: ItemProps[] };

/**
 * Hooks for keyboaard filtering by first letter when autocomplete is off.
 */
export const useTypingFilter = (props: TypeFilterProps) => {
  const { autocomplete, isOpen, items } = props;
  const [keyPressValue, setKeyPressValue] = useState('');
  const [keyPressTimes, setKeyPressTimes] = useState(0);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newKeyPress = e.key;
    const newKeyPressTimes = keyPressValue === newKeyPress ? keyPressTimes + 1 : 0;

    if (newKeyPress.length === 1) {
      setKeyPressValue(newKeyPress);
      setKeyPressTimes(newKeyPressTimes);
    } else {
      setKeyPressValue('');
      setKeyPressTimes(0);
    }
  };

  const getItemFromKeyPress = () => {
    const filteredItems = items.filter((item) => {
      const valueToCheck = getItemDisplayValue(item);
      return !!keyPressValue && valueToCheck && valueToCheck.toLowerCase().startsWith(keyPressValue);
    });
    return filteredItems[keyPressTimes % filteredItems.length];
  };

  useEffect(() => {
    if (!isOpen && keyPressValue) {
      setKeyPressValue('');
    }
  }, [isOpen, keyPressValue, setKeyPressValue]);

  return { getItemFromKeyPress, keyPressValue, keyPressTimes, onKeyPress: autocomplete ? noOp : onKeyPress };
};
