import { StorageKeys } from 'common/storage/constants';
import { getSessionStorage } from 'src/shared/storage/Storage';
import { CURRENT_VERSION, IPromotionals, IPromotionalState, ISerializablePromotionalState } from './promoCodeTypes';

export function saveStateToStorage(promotionals: IPromotionals): IPromotionals {
  const storage = getSessionStorage();
  const toStore: ISerializablePromotionalState = {
    version: CURRENT_VERSION,
    state: promotionals,
  };

  storage.setItem(StorageKeys.PROMOTIONAL_STATE, JSON.stringify(toStore));
  return promotionals;
}

function loadStateFromStorage(): IPromotionals | null {
  const storage = getSessionStorage();
  const serializedState = storage.getItem(StorageKeys.PROMOTIONAL_STATE);
  if (serializedState) {
    try {
      const fromStore: ISerializablePromotionalState = JSON.parse(serializedState);
      if (fromStore.version !== CURRENT_VERSION) {
        throw new Error(`Version mismatch. Got ${fromStore.version}, expected ${CURRENT_VERSION}`);
      }
      return fromStore.state;
    } catch (err) {
      console.error('Failed to load promotionals from storage:', err);
      return null;
    }
  }
  return null;
}

export const initialPromoCodeState: IPromotionalState = {
  promotionals: loadStateFromStorage() ?? {
    availablePromos: [],
    code: null,
    error: null,
    organization: null,
  },
};
