import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './chijioke-okonkwo.jpg';

export const ChijiokeOkonkwo: PersonInfo = {
  firstName: 'Chijioke',
  lastName: 'Okonkwo',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
