import { PayloadAction } from '@reduxjs/toolkit';

export enum NavLocation {
  ANIMATIONS = 'animations',
  BACK_BUTTON = 'backButton',
  CUISINE_FILTERS = 'cuisineFilters',
  CUISINE_FILTER_BUTTON = 'cuisineFilterButton',
  DATE_PICKER = 'datePicker',
  DATE_PICKER_BUTTON = 'datePickerButton',
  DIETARY_FILTERS = 'dietaryFilters',
  PROTEIN_FILTERS = 'proteinFilters',
  INLINE_FILTER_BUTTONS = 'inlineFilterButtons',
  FILTER_BAR = 'filterBar',
  MENU_BAR = 'menuBar',
  MORE_FILTERS = 'moreFilters',
  NAVIGATION = 'navigation',
  PROMO = 'promo',
  PROMO_EXPANDED = 'promo_expanded',
  SEARCH = 'search',
  SUB_CUISINE_FILTERS = 'subCuisineFilters',
}

export const TOGGLE_LOCATIONS = [
  NavLocation.CUISINE_FILTERS,
  NavLocation.DATE_PICKER,
  NavLocation.DIETARY_FILTERS,
  NavLocation.PROTEIN_FILTERS,
  NavLocation.MORE_FILTERS,
  NavLocation.SEARCH,
  NavLocation.SUB_CUISINE_FILTERS,
];

export enum DockedScreenSize {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export type NavbarState = {
  [NavLocation.ANIMATIONS]: boolean;
  [NavLocation.BACK_BUTTON]: boolean;
  [NavLocation.CUISINE_FILTERS]: boolean;
  [NavLocation.CUISINE_FILTER_BUTTON]: boolean;
  [NavLocation.DATE_PICKER]: boolean;
  [NavLocation.DATE_PICKER_BUTTON]: boolean;
  [NavLocation.DIETARY_FILTERS]: boolean;
  [NavLocation.PROTEIN_FILTERS]: boolean;
  [NavLocation.INLINE_FILTER_BUTTONS]: boolean;
  [NavLocation.FILTER_BAR]: boolean;
  [NavLocation.MENU_BAR]: boolean;
  [NavLocation.MORE_FILTERS]: boolean;
  [NavLocation.NAVIGATION]: boolean;
  [NavLocation.PROMO]: boolean;
  [NavLocation.PROMO_EXPANDED]: boolean;
  [NavLocation.SEARCH]: boolean;
  [NavLocation.SUB_CUISINE_FILTERS]: boolean;
  [DockedScreenSize.DESKTOP]: NavLocation[];
  [DockedScreenSize.TABLET]: NavLocation[];
  [DockedScreenSize.MOBILE]: NavLocation[];
  isContentShowing: boolean;
  isScrollingUp: boolean;
};

export type SetIsScrollingUpState = PayloadAction<{
  isScrollingUp: boolean;
}>;

export type ResetStateAction = PayloadAction<{
  hideBackButton: boolean;
}>;
