import { assertDateString, currentUrlDate, DateString } from 'common/UrlDate';
import { Slugs } from 'common/urls/Slugs';
import { isNil } from 'lodash';
import { useDishModalQuery } from 'src/gqlReactTypings.generated.d';
import { ExploreDishModal } from 'src/shared/design-system/DishFirst/DishModal/ExploreDishModal';
import { getShefMenuUrl } from 'src/shared/design-system/DishFirst/DishModal/utils';
import { useModal } from 'src/shared/hooks/useModal';
import { Routes } from 'src/shared/Routes';
import { getSluggedPath } from 'src/shared/utils/RouteUtilities';
import { CartActionLocation, CartFoodItem } from 'src/store/cart/types';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import { ZipModal } from '../../zip-modal/ZipModal';

interface IDishModalProps {
  cartActionLocation: CartActionLocation;
  deliveryDate?: DateString;
  foodItem: CartFoodItem;
  isEditingOrder: boolean;
  isShowing: boolean;
  quantity: number;
  remainingQuantity: number | null | undefined;
  shefId?: string;
  showModalPrompt?: boolean;
  onAddToCart?: () => void;
  openModal?: () => void;
  onClose?: () => void;
}

export const ExploreDishModalWithZip: React.FC<IDishModalProps> = ({
  cartActionLocation,
  deliveryDate,
  foodItem,
  isEditingOrder,
  isShowing,
  quantity,
  remainingQuantity,
  shefId,
  showModalPrompt,
  onAddToCart,
  onClose,
  openModal,
}) => {
  const { data: foodItemData } = useDishModalQuery({
    variables: { foodItemId: foodItem.id },
    skip: isNil(shefId),
  });

  const deliveryDateString = assertDateString(deliveryDate ?? currentUrlDate());

  // If the customer doesn't have a zip code cached, prompt for one.
  const zipModal = useModal();
  const [cachedZipCode, setCachedZipCode] = useCachedZipCode();
  const handleAddToCart = () => {
    if (isNil(cachedZipCode)) {
      zipModal.show();
    }
    onAddToCart?.();
  };

  return (
    <>
      <ExploreDishModal
        cartActionLocation={cartActionLocation}
        foodItem={foodItem}
        loadedData={foodItemData?.foodItem}
        isShowing={isShowing}
        maxQuantity={remainingQuantity ?? 0}
        quantityInCart={quantity}
        shefMenuUrl={getShefMenuUrl(shefId)}
        onClose={onClose}
        shareUrl={getSluggedPath(Routes.CONSUMER_ACCOUNT_SHARE_FOOD_ITEM, [
          { slug: Slugs.SHEF_ID, value: `${shefId}` },
          { slug: Slugs.NAME, value: foodItem.slug ?? '' },
        ])}
        deliveryDate={deliveryDateString}
        onAddToCart={handleAddToCart}
        isEditingOrder={isEditingOrder}
        showModalPrompt={showModalPrompt}
        openModal={openModal}
      />
      <ZipModal isShowing={zipModal.isShowing} onClose={zipModal.hide} setZip={setCachedZipCode} />
    </>
  );
};
