import React from 'react';
import { Routes } from 'src/shared/Routes';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';

const Banner = styled.div`
  background-color: ${Colors.ADMIN_BRIGHT_WARNING};
`;

const BannerText = styled.div`
  text-align: center;
  display: block;
  color: ${Colors.WHITE};
  cursor: pointer;
  padding: ${Spacing.HALF};
  margin: 0;
  font-size: ${Font.Size.XS};
  font-weight: 500;
  font-family: ${Font.Family.SANS};
`;

export const GlobalImpersonationHeader: React.FC = () => (
  <div className='consumer-header'>
    <Banner>
      <BannerText>
        You are currently impersonating a user. BE CAREFUL. Done? <a href={Routes.LOGOUT}>logout now</a>.
      </BannerText>
    </Banner>
  </div>
);
