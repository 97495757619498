import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './brennan-baraban.jpg';

export const BrennanBaraban: PersonInfo = {
  firstName: 'Brennan',
  lastName: 'Baraban',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
