import { clamp, isNil, partition, reduce, sumBy } from 'lodash';
import { match, P } from 'ts-pattern';

export enum PriceFactorType {
  Nominal = 'NOMINAL',
  Percentage = 'PERCENTAGE',
}

/**
 * Note that defaultPrice should already be the discounted price if in the take rate experiment
 */
export const getPriceWithCustomizations = (
  defaultPrice: number,
  priceFactors: { priceFactor: number; priceFactorType: PriceFactorType }[]
): number => {
  // Apply nominal price factors first, then multiplicative ones. May want to change this logic later
  const [nominalPriceFactors, percentagePriceFactors] = partition(
    priceFactors,
    ({ priceFactorType }) => priceFactorType === PriceFactorType.Nominal
  );

  const priceWithNominalPriceFactorCustomizations = sumBy(nominalPriceFactors, 'priceFactor') + defaultPrice;
  const priceWithAllPriceFactorCustomizations =
    reduce(percentagePriceFactors, (prev, { priceFactor }) => (prev * (priceFactor + 100)) / 100, 1) *
    priceWithNominalPriceFactorCustomizations;

  return Math.floor(priceWithAllPriceFactorCustomizations);
};

export const getCustomizationSavingPercent = ({
  defaultPrice,
  priceFactor,
  priceFactorType,
  defaultNumServings,
  numServings,
}: {
  defaultPrice: number;
  priceFactor: number;
  priceFactorType: PriceFactorType;
  defaultNumServings: number;
  numServings: number;
}): number => {
  const customizationPrice = getPriceWithCustomizations(defaultPrice, [{ priceFactor, priceFactorType }]);

  return (1 - customizationPrice / ((numServings / defaultNumServings) * defaultPrice)) * 100;
};

export const parseNumServings = (portionSizeString: string): number => {
  // parseInt automatically grabs the first number in a string
  const parsedNumServingsBase = parseInt(portionSizeString, 10);

  // Sometimes we have 1-2 servings/2-3 servings, so grab the second number if it exists
  // Regex matches if a string starts with a number, then any number of spaces/dashes, then a second number
  const parsedMaxNumServingsString = portionSizeString.match(/^[\d]+[- ]+([\d]+)/)?.[1];
  const parsedMaxNumServings = !isNil(parsedMaxNumServingsString) ? parseInt(parsedMaxNumServingsString, 10) : null;

  const parsedPortionSize = match({ parsedNumServingsBase, parsedMaxNumServings })
    .with({ parsedNumServingsBase: NaN }, () => 1)
    .with({ parsedMaxNumServings: P.nullish }, ({ parsedNumServingsBase: numServingsBase }) => numServingsBase)
    .with(
      { parsedMaxNumServings: P.number },
      ({ parsedNumServingsBase: numServingsBase, parsedMaxNumServings: maxNumServings }) =>
        (numServingsBase + maxNumServings) / 2
    )
    .exhaustive();
  return clamp(parsedPortionSize, 1, 6);
};
