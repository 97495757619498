import { formatCurrency } from './FormatUtilities';

interface IGetPromoCodeDescriptionForEventParams {
  value: number;
  isPercentage: boolean;
  maxUsesPerUser?: number | null;
}
export const getPromoCodeDescriptionForEvent = ({
  value,
  isPercentage,
  maxUsesPerUser,
}: IGetPromoCodeDescriptionForEventParams): string => {
  const formattedValue = isPercentage ? `${value}%` : formatCurrency(value, true);
  return `${formattedValue}x${maxUsesPerUser ?? 1} order${(maxUsesPerUser ?? 1) === 1 ? '' : 's'}`;
};

export const NEW_USER_CODE_FOR_HUBLESS_REGIONS = 'first15';
export const DEFAULT_NEW_USER_CODE = 'first10';
export const NEWBIE_PROMO_CODE_FIRST_50_FREE_DELIVERY = 'first50';
export const NEWBIE_PROMO_CODE_FIRST_30_FREE_DELIVERY = 'first30';
