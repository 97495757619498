/* eslint max-len: "off" -- ^^^^^^^^^^^ */
import cn from 'classnames';
import styled from 'styled-components';

/**
 * Icons can be found most easily on fontawesome.com
 *
 * Example: the fa-rocket icon can be found at https://fontawesome.com/icons/rocket
 *          OR through the Fontawesome search: https://fontawesome.com/search
 */

export const FaBadgeCheck = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-badge-check', className),
}))``;
export const FaBadgeDollar = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-badge-dollar', className),
}))``;
export const FaBadgeDollarLight = styled.i.attrs(({ className }) => ({
  className: cn('fa-light fa-badge-dollar', className),
}))``;
export const FaBook = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-book-user', className) }))``;
export const FaBuilding = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-building', className) }))``;
export const FaCalendar = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-calendar', className) }))``;
export const FaCalendarCheck = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-calendar-check', className),
}))``;
export const FaCalendarXMark = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-calendar-xmark', className),
}))``;
export const FaCalendarDay = styled.i.attrs(({ className }) => ({
  className: cn('fa-regular fa-calendar-day', className),
}))``;
export const FaCheck = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-check', className) }))``;
export const FaCheckSquare = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-check-square', className),
}))``;
export const FaCircle = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-circle', className) }))``;
export const FaCircleCheck = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-circle-check', className),
}))``;
export const FaCircleInfo = styled.i.attrs(({ className }) => ({
  className: cn('fa-regular fa-circle-info', className),
}))``;
export const FaCircleXMark = styled.i.attrs(({ className }) => ({
  className: cn('fa-regular fa-circle-xmark', className),
}))``;
export const FaComment = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-comment', className) }))``;
export const FaCommentDots = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-comment-dots', className),
}))``;
export const FaCopy = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-copy', className) }))``;
export const FaClock = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-clock', className) }))``;
export const FaEllipsis = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-ellipsis', className) }))``;
export const FaEnvelope = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-envelope', className) }))``;
export const FaGift = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-gift', className) }))``;
export const FaGlobe = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-globe', className) }))``;
export const FaFileSignature = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-file-signature', className),
}))``;
export const FaImage = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-image', className) }))``;
export const FaLock = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-lock', className) }))``;
export const FaMapMarkerAlt = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-map-marker-alt', className),
}))``;
export const FaMessage = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-message-dots', className),
}))``;
export const FaMoneyBill = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-money-bill-wave', className),
}))``;
export const FaPen = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-pen', className) }))``;
export const FaPhone = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-phone', className) }))``;
export const FaPlay = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-play', className) }))``;
export const FaPlayCircle = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-play-circle', className),
}))``;
export const FaPrint = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-print', className) }))``;
export const FaRocket = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-rocket', className) }))``;
export const FaSearch = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-search', className) }))``;
export const FaShareAlt = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-share-alt', className) }))``;
export const FaShieldAlt = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-shield-alt', className),
}))``;
export const FaSpinnerThird = styled.i.attrs(({ className }) => ({
  className: cn('fa-sharp fa-solid fa-spinner-third', className),
}))``;
export const FaTableCellsLarge = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-table-cells-large', className),
}))``;
export const FaTimes = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-times', className) }))``;
export const FaTrash = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-trash', className) }))``;
export const FaUsers = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-users', className) }))``;
export const FaVolumeMute = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-volume-mute', className),
}))``;
export const FaGlobeAmericas = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-globe-americas', className),
}))``;
export const FaHandWave = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-hand-wave', className) }))``;
export const FaLink = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-link', className) }))``;

// Alert
export const FaExclamationCircle = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-exclamation-circle', className),
}))``;
export const FaInfoCircle = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-info-circle', className),
}))``;
export const FaTriangleExclamation = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-triangle-exclamation', className),
}))``;
export const FaExclamationCircleOutline = styled.i.attrs(({ className }) => ({
  className: cn('fa-regular fa-circle-exclamation', className),
}))``;

// Arrows
export const FaAngleRight = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-angle-right', className),
}))``;
export const FaArrowRight = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-arrow-right', className),
}))``;
export const FaArrowLeft = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-arrow-left', className),
}))``;
export const FaArrowUpRightFromSquare = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-arrow-up-right-from-square', className),
}))``;
export const FaArrowRotate = styled.i.attrs(({ className }) => ({
  className: cn('fa-light fa-arrows-rotate', className),
}))``;
export const FaCaretDown = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-caret-down', className),
}))``;
export const FaChevronUp = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-chevron-up', className),
}))``;
export const FaChevronDown = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-chevron-down', className),
}))``;
export const FaChevronRight = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-chevron-right', className),
}))``;
export const FaChevronLeft = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-chevron-left', className),
}))``;
export const FaArrowTrendUp = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-arrow-trend-up', className),
}))``;

// Social/Brands
export const FaStripeS = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-stripe-s', className) }))``;
export const FaGoogle = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-google', className) }))``;
export const FaFacebook = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-facebook', className) }))``;
export const FaFacebookSquare = styled.i.attrs(({ className }) => ({
  className: cn('fa-brands fa-facebook-square', className),
}))``;
export const FaInstagram = styled.i.attrs(({ className }) => ({
  className: cn('fa-brands fa-instagram', className),
}))``;
export const FaLinkedin = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-linkedin', className) }))``;
export const FaTikTok = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-tiktok', className) }))``;
export const FaTwitter = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-twitter', className) }))``;
export const FaTwitterSquare = styled.i.attrs(({ className }) => ({
  className: cn('fa-brands fa-twitter-square', className),
}))``;
export const FaYoutube = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-youtube', className) }))``;
export const FaApple = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-apple', className) }))``;
export const FaAndroid = styled.i.attrs(({ className }) => ({ className: cn('fa-brands fa-android', className) }))``;
export const FaAppGeneric = styled.i.attrs(({ className }) => ({
  className: cn('fa-solid fa-mobile-screen-button', className),
}))``;

export const FaChefHat = styled.i.attrs(({ className }) => ({ className: cn('fa-light fa-hat-chef', className) }))``;

export const FaBowlFood = styled.i.attrs(({ className }) => ({ className: cn('fa-light fa-pot-food', className) }))``;
export const FaQueue = styled.i.attrs(({ className }) => ({ className: cn('fa-solid fa-users-line', className) }))``;

export const FaLocationDot = styled.i.attrs(({ className }) => ({
  className: cn('fa-regular fa-location-dot', className),
}))``;
