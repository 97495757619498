import { createSelector } from '@reduxjs/toolkit';
import { EXP_VAR_TREATMENT, ExperimentNames } from 'common/experiments/ExperimentDefinitions';
import { SessionStorageKeys } from 'common/storage/constants';
import { assertDateString, DateString } from 'common/UrlDate';
import { QueryParams } from 'common/urls/QueryParams';
import { first } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useVariant } from 'src/experimentation/useVariant';
import { Routes } from 'src/shared/Routes';
import { useNonstrictSessionStorage } from 'src/shared/storage/Storage';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';
import { useSelector } from 'src/store';
import { selectCartShefsById, selectMultiCartIsEmpty } from 'src/store/cart/selectors';
import { CartState } from 'src/store/cart/types';
import { findDefaultDeliveryDate } from 'src/store/cart/utils/date';
import { match, P } from 'ts-pattern';
import { StringParam, useQueryParam } from 'use-query-params';
import { useIsDishFirst } from './useIsDishFirst';

const emptyArr = [];

const maybeAssertDateString = (date: string | null | undefined): DateString | undefined =>
  date ? assertDateString(date) : undefined;

// validate the the date is a date we serve at this hub
// default to true in the case the user has not yet loaded in
// any availability data for the zip code they're viewing
const isDateValid = (deliveryDate: DateString | undefined, possibleDeliveryDates: Set<DateString>) => {
  if (possibleDeliveryDates.size === 0) return true;
  return Boolean(deliveryDate && possibleDeliveryDates.has(deliveryDate));
};

const isSessionDateValid = (sessionDeliveryDate: DateString[] | undefined, possibleDeliveryDates: Set<DateString>) => {
  if (sessionDeliveryDate === undefined) return false;
  if (sessionDeliveryDate.length === 0) return true;
  return isDateValid(first(sessionDeliveryDate), possibleDeliveryDates);
};

const cartSelector = createSelector(
  selectCartShefsById,
  (_state: CartState, shefId: string) => shefId,
  selectMultiCartIsEmpty,
  (state: CartState) => state.deliveryDate,
  (state: CartState) => state.possibleDeliveryDates,
  (state: CartState) => state.skipTomorrowDefaultDate,
  (cartShefs, currentShefId, isEmpty, deliveryDate, possibleDeliveryDates, skipTomorrowDefaultDate) => {
    const currentShefDeliveryDate = cartShefs[currentShefId]?.deliveryDate;
    const currentShefAvailability = cartShefs[currentShefId]?.availability || emptyArr;
    const currentShefFirstDeliveryDate =
      currentShefAvailability.find((availability) => !availability.isSoldOut)?.availabilityDate ||
      first(currentShefAvailability)?.availabilityDate;
    const possibleDeliveryDateStrings = possibleDeliveryDates.map((date) => assertDateString(date));
    const possibleDeliveryDatesSet = new Set(possibleDeliveryDateStrings);

    return {
      deliveryDate: assertDateString(deliveryDate),
      defaultDeliveryDate: findDefaultDeliveryDate(
        possibleDeliveryDates,
        '',
        isEmpty,
        undefined,
        skipTomorrowDefaultDate
      ),
      possibleDeliveryDates: possibleDeliveryDateStrings,
      possibleDeliveryDatesSet,
      isEmpty,
      currentShefAvailability,
      currentShefDeliveryDate: maybeAssertDateString(currentShefDeliveryDate),
      currentShefFirstDeliveryDate: maybeAssertDateString(currentShefFirstDeliveryDate),
    };
  }
);

// NOTES:
//
// - delivery date in the url takes presidence over other values
//   when deciding what is the current delivery date to show.
//   we want to clear delivery date query param after reading it
//   so that using the browser's forward and backward buttons
//   do not undo changes to the delivery date filter the user might
//   have made since visiting another page in their history
//
// - url date will always clear out the date url param so subsequent
//   runs to this function will never have url date to choose from
// - cart date can be invalid/stale in the case of users who have
//   visited before or new users arrive and default cart date is not
//   an actual servicable date. either way, we don't know until we
//   have a response from the server. when it's updated, the
//   session date will have already been synced to the cart's invalid
//   value, but this gets detected as value. this will cause us to pick
//   the cart's new delivery date which will be valid, and we will
//   sync the valid value to the session storage
//
// - updated zip codes could cause new a new set of possibleDeliveryDates
//   to be used below. when that update happens the cart date can be
//   assumed to be valid, so the code should correct itself but force
//   the viewing of a new date for the user without warning
//
// - writeUpdatesToSessionStorage value flags wether we should take updates
//   from the url and automatically write them to the session storage
//   in the case of the explore page, auto writing is the desired behavior.
//   in the case of shef's menus, we don't want to record this value to the
//   session and we any updates to it shouldn't be persisted

export const usePersistentDeliveryDates = (): [DateString[], (dates: DateString[]) => void] => {
  // detect if we are on a shef's menu
  const history = useHistory();
  const consumerOrderOneTimeMatch = useRouteMatch<{ id?: string }>(Routes.CONSUMER_ORDER_ONE_TIME);
  const shefId = consumerOrderOneTimeMatch?.params?.id || '';
  const isOnShefMenu = !!shefId;
  const { isDishFirst } = useIsDishFirst();
  const { variant } = useVariant(ExperimentNames.ANY_DAY_DELIVERY, false);
  const isAnyDayDelivery = variant?.name === EXP_VAR_TREATMENT;

  const [rawUrlDeliveryDate, setUrlDeliveryDate] = useQueryParam(QueryParams.DATE, StringParam);
  const urlDeliveryDate = maybeAssertDateString(rawUrlDeliveryDate);
  const [sessionDeliveryDate, setSessionDeliveryDate] = useNonstrictSessionStorage<DateString[]>(
    SessionStorageKeys.DISH_FIRST_DELIVERY_DATE,
    undefined
  );
  const cart = useSelector(({ cart }) => cartSelector(cart, shefId));
  const isOnCartPage = locationInRoutes(history.location, [Routes.CONSUMER_ORDER_FINALIZE, Routes.CONSUMER_ORDER_CART]);

  // swallow delivery date query param
  useEffect(() => {
    if (urlDeliveryDate && !isOnCartPage && isDishFirst) setUrlDeliveryDate(undefined, 'replaceIn');
  }, [urlDeliveryDate, setUrlDeliveryDate, isOnCartPage, isDishFirst]);

  // select the best delivery date from one of a few stored locations (url, session, local / cart storage)
  const persistedDeliveryDate = useMemo(() => {
    const urlDateValid = isDateValid(urlDeliveryDate, cart.possibleDeliveryDatesSet);
    const currentShefDeliveryDateValid = isDateValid(cart.currentShefDeliveryDate, cart.possibleDeliveryDatesSet);
    const sessionDateValid = isSessionDateValid(sessionDeliveryDate, cart.possibleDeliveryDatesSet);
    const firstShefDateValid = isDateValid(cart.currentShefFirstDeliveryDate, cart.possibleDeliveryDatesSet);

    return (
      match({
        urlDateValid,
        sessionDateValid,
        isCartEmpty: cart.isEmpty,
        firstShefDateValid,
        currentShefDeliveryDateValid,
        urlDeliveryDate,
        isDishFirst,
      })
        // url always takes highest priority
        .with({ urlDateValid: true, urlDeliveryDate: P.string }, ({ urlDeliveryDate: u }) => [u])
        .with({ sessionDateValid: true }, () => sessionDeliveryDate)
        // If a user lands on shef and had a non-empty cart in the last session, use and set this date for the
        // current session. We do this to prevent fallback to "Browse all" once the user clears their cart.
        .with({ isCartEmpty: false }, () => {
          setSessionDeliveryDate([cart.deliveryDate]);
          return [cart.deliveryDate];
        })
        // while on shef menu, use the shef's configured delivery date value. this allows the user to change
        // the delivery date from what was on the explore page and have that persist even after reloading the page.
        // since navigation from explore -> shef menu should always have a delivery date in the url, the session's
        // delivery date will be used by default, thus setting the shef's delivery date to the session date, and we
        // drop through to this line on subsequent renders / page reloads.
        // But this only applies if the cart is empty
        .with({ currentShefDeliveryDateValid: true }, () =>
          cart.currentShefDeliveryDate ? [cart.currentShefDeliveryDate] : emptyArr
        )
        .with({ firstShefDateValid: true, isDishFirst: false }, () =>
          cart.currentShefFirstDeliveryDate ? [cart.currentShefFirstDeliveryDate] : emptyArr
        )
        .otherwise(() => {
          const defaultDate = isAnyDayDelivery
            ? emptyArr
            : [maybeAssertDateString(cart.defaultDeliveryDate) ?? cart.deliveryDate];
          setSessionDeliveryDate(defaultDate);
          return defaultDate;
        })
    );
  }, [
    urlDeliveryDate,
    sessionDeliveryDate,
    setSessionDeliveryDate,
    cart.isEmpty,
    cart.deliveryDate,
    cart.possibleDeliveryDatesSet,
    cart.defaultDeliveryDate,
    cart.currentShefFirstDeliveryDate,
    cart.currentShefDeliveryDate,
    isAnyDayDelivery,
    isDishFirst,
  ]);

  const [inMemoryDeliveryDate, setInMemoryDeliveryDate] = useState(persistedDeliveryDate);

  // In shef-first, the url date param swallows date changes from the menu, so override with an in memory date
  if (isOnShefMenu && !isDishFirst) {
    return [inMemoryDeliveryDate, setInMemoryDeliveryDate];
  }

  return [persistedDeliveryDate, setSessionDeliveryDate];
};
