import { DateString } from 'common/UrlDate';
import { DishFirstFoodItemFragment, DishModalFoodItemFragment } from 'src/gqlReactTypings.generated.d';
import { CartActionLocation, CartFoodItem } from 'src/store/cart/types';

export interface MoreFoodItemType extends DishFirstFoodItemFragment {}

export type QuantityType = {
  [key: number]: number | undefined;
};

export type DishModalLocation = 'dish-first-modal' | 'dish-first-modal-more-items';

export interface DishModalType {
  deliveryDate: DateString;
  foodItem: CartFoodItem;
  loadedData?: DishModalFoodItemFragment;
  cartActionLocation: CartActionLocation;
  isShowing?: boolean;
  maxQuantity?: number | null;
  minQuantity?: number | null;
  moreFoodItems?: MoreFoodItemType[];
  onClose?: () => void;
  showAddButton?: boolean;
  moreFoodItemQuantities: QuantityType;
  moreFoodItemsLoading: boolean;
  shefMenuUrl: string;
  shareUrl?: string;
  showShefInfo?: boolean;
  showShefMenuButton?: boolean;
  showModalPrompt?: boolean;
  openModal?: () => void;
  onAddToCart?: () => void;
}

export const DEFAULT_CUSTOMIZATION_ID = 'DEFAULT';
