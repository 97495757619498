import { trimStart } from 'lodash';
import { thumborURL } from 'react-thumbor-img';
import { isDevBox, isDevEnvironment } from 'src/shared/utils/EnvironmentUtilities';
import { roundAndScale } from './roundAndScale';
import { ThumborImgUrlProps } from './types';

const MAX_IMAGE_SCALE = 2;
const DEFAULT_DEVICE_PIXEL_RATIO = 1;

export const generateThumborImgUrl = ({ src, width, height, bucketSize = 50 }: ThumborImgUrlProps): string => {
  if (src.startsWith('data:')) {
    return src;
  }

  const absolutePathExp = /^https?:\/\//i;
  const baseImagePath = process.env.REACT_APP_BASE_IMAGE_PATH;
  const computedSrc = absolutePathExp.test(src) ? src : `${baseImagePath}/${trimStart(src, '/')}`;

  if (isDevEnvironment() && !isDevBox()) {
    return computedSrc;
  }

  const dynamicImageApiEndpoint = process.env.REACT_APP_THUMBOR_ENDPOINT;
  if (!dynamicImageApiEndpoint) {
    throw new Error('REACT_APP_THUMBOR_ENDPOINT is not set!');
  }

  const devicePixelRatio = window.devicePixelRatio ?? DEFAULT_DEVICE_PIXEL_RATIO;
  const scale = Math.min(devicePixelRatio, MAX_IMAGE_SCALE);

  const sourceWidthBeforeRounding = Math.ceil((width || 0) * scale);
  const sourceHeightBeforeRounding = Math.ceil((height || 0) * scale);
  const [sourceWidth, sourceHeight] = roundAndScale(
    [sourceWidthBeforeRounding, sourceHeightBeforeRounding],
    bucketSize
  );

  const thumborImg = {
    server: dynamicImageApiEndpoint,
    src: computedSrc,
    width: sourceWidth,
    height: sourceHeight,
  };

  return thumborURL(thumborImg);
};
