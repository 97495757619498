import { createSlice } from '@reduxjs/toolkit';
import { initialExploreState } from './initialState';
import * as reducers from './reducers';
import { ExploreState } from './types';

const createExploreSlice = (name: string, initialState: ExploreState) =>
  createSlice({
    name,
    initialState,
    reducers,
  });

export const exploreSlice = createExploreSlice('dishFirstExplore', initialExploreState);

export type ExploreSlice = ReturnType<typeof createExploreSlice>;
