import { adjustedStarsToRating } from 'common/RatingUtilities';
import React from 'react';
import { StarRating } from 'src/shared/design-system/StarRating/StarRating';
import { Paragraph } from 'src/shared/design-system/Text';
import styled from 'styled-components';
import { Colors } from 'web-common/src/shared/styles';

const Rating = styled.div`
  height: 22px;
  display: flex;
  align-items: flex-end;
`;

interface IShouldSHowNewLabelArgs {
  numRatings: number;
  isTrialShef: boolean;
}
export const shouldShowNewLabel = ({ numRatings, isTrialShef }: IShouldSHowNewLabelArgs) =>
  !isTrialShef && numRatings < 10;

interface IProps {
  avgRating: number;
  className?: string;
  numRatings: number;
  showNumRatings?: boolean;
  showOnlyNum?: boolean;
  showOneStar?: boolean;
  isTrialShef?: boolean;
  fontSize?: number;
}

export const ConsumerShefRating: React.FC<IProps> = ({
  avgRating,
  className,
  numRatings,
  showOnlyNum,
  showNumRatings = true,
  fontSize = 14,
}) => {
  const rating = adjustedStarsToRating(avgRating);
  const reviewText = numRatings === 1 ? 'rating' : 'ratings';

  return (
    <Rating className={className}>
      <Paragraph margin='.2em .3em 0 0' size={`${fontSize}px`} color={Colors.GRAY_DARK_10} bold>
        {rating.toFixed(1)}
      </Paragraph>
      <StarRating
        rating={rating}
        stars={{
          color: Colors.RADISH,
          size: `${fontSize - 1}px`,
        }}
      />
      {showNumRatings && (
        <Paragraph margin='.2em 0 0 .3em' size={`${fontSize}px`} color={Colors.GRAY_DARK_10} bold>
          ({numRatings}
          {showOnlyNum ? '' : ` ${reviewText}`})
        </Paragraph>
      )}
    </Rating>
  );
};
