import { HTMLAttributes, SVGAttributes } from 'react';
import { Maybe } from 'src/gqlReactTypings.generated.d';
import { Colors } from 'web-common/src/shared/styles';
import { EmptyStar, FilledStar } from './shared';

interface EmptyOrFullStarProps {
  full: number;
  color?: string;
  size?: string;
  index?: number | string;
  percentWidth?: number;
}

const Star = ({ full, color, size, index, percentWidth = 100 }: EmptyOrFullStarProps) =>
  full ? <FilledStar color={color} size={size} index={index} percentWidth={percentWidth} /> : <EmptyStar size={size} />;

interface StarProps extends SVGAttributes<SVGElement> {
  color?: string;
  size?: string;
}
interface StarRatingProps extends HTMLAttributes<HTMLDivElement> {
  displayPartialStars?: boolean;
  rating: number;
  stars?: StarProps;
}

export const StarRating = ({ displayPartialStars = false, rating, stars, ...rest }: StarRatingProps) => {
  const { color, size } = stars ?? {};
  const starColor = color ?? Colors.JACKFRUIT;

  // full stars
  const fullStarsCount = Math.floor(rating);
  const fullStars = Array(fullStarsCount)
    .fill(null)
    .map((_, i) => <Star key={`full-${i}`} index={`full-${i}`} full={1} color={starColor} size={size} />);

  // partial stars - will be displayed as full stars if `displayPartialStars=false`
  const partialStar: Maybe<JSX.Element> =
    rating !== fullStarsCount ? (
      <Star
        full={rating - fullStarsCount}
        key='partial'
        index='partial'
        color={starColor}
        size={size}
        percentWidth={displayPartialStars ? (rating - fullStarsCount) * 100 : 100}
      />
    ) : null;
  const partialStarsCount = partialStar === null ? 0 : 1;

  // empty stars
  const emptyStarsCount = 5 - fullStarsCount - partialStarsCount;
  const emptyStars = Array(emptyStarsCount)
    .fill(null)
    .map((_, i) => <Star full={0} key={`empty-${i}`} index={`empty-${i}`} size={size} />);
  return (
    <div {...rest}>
      {fullStars}
      {partialStar}
      {emptyStars}
    </div>
  );
};
