import { Header2 } from 'src/shared/design-system/Headers';
import { ChevronLeftV2 } from 'src/shared/design-system/Icon';
import { minScreen } from 'src/shared/utils/StyledComponentUtils';
import styled from 'styled-components';
import { FontSize, FontWeight, LineHeight, ScreenWidth, Spacing } from 'web-common/src/shared/styles';

export const HeaderSection = styled.div`
  padding: 0 ${Spacing.SINGLE} ${Spacing.SINGLE};

  ${minScreen(ScreenWidth.SMALL)} {
    padding: 0 ${Spacing.DOUBLE} ${Spacing.SINGLE_HALF};
  }
`;

export const Header = styled(Header2)`
  margin-bottom: 0;
`;

export const SubHeader = styled.p`
  line-height: ${LineHeight.S};
  margin: ${Spacing.HALF} 0;
`;

export const NumItems = styled.p`
  font-size: ${FontSize.XS};
  font-weight: ${FontWeight.BOLD};
  line-height: ${LineHeight.XXS};
  margin: 0;
`;

export const BackChevron = styled(ChevronLeftV2)`
  margin-right: ${Spacing.QUARTER};
  margin-bottom: ${Spacing.EIGHTH};
`;
