import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import * as t from 'io-ts';
import { filter } from 'lodash';
import { RootState } from 'src/store';
import {
  selectAvailableCartOrdersByShefIds,
  selectUnavailableCartOrdersByShefIds,
  selectUnavailableLineItemIds,
} from 'src/store/cart/selectors';
import { CartState } from 'src/store/cart/types';
import { IFirstStateVersion, IStateVersions } from 'src/store/storage';
import { selectCartFoodItemsById } from '../cart/selectors/lineItems';
import { calcLineItemsSubtotal } from '../cart/selectors/subtotals';

interface CheckedOutShefOrder {
  id: string; // shefId
  orderId: number;
}

export const checkedOutOrdersAdapter = createEntityAdapter<CheckedOutShefOrder>();

interface CheckoutState {
  checkoutShefIds: string[];
  checkedOrderIdsByShefId: EntityState<CheckedOutShefOrder>;
}

export const initialState: CheckoutState = {
  checkoutShefIds: [],
  checkedOrderIdsByShefId: checkedOutOrdersAdapter.getInitialState(),
};

export const createCheckoutSlice = (initialState: CheckoutState) =>
  createSlice({
    name: 'checkout',
    initialState,
    reducers: {
      startCheckout: (state, action: PayloadAction<{ shefIds: string[] }>) => {
        state.checkoutShefIds = action.payload.shefIds;
      },
      completedCheckout: (state) => {
        state.checkoutShefIds = [];
      },
    },
  });

export const checkoutSlice = createCheckoutSlice(initialState);

export type CheckoutSlice = typeof checkoutSlice;

export const { startCheckout, completedCheckout } = checkoutSlice.actions;

export const selectCheckoutOrders = createSelector(
  (_state: RootState, cart: CartState) => cart,
  (state: RootState, cart: CartState) => (cart.isMultiCart ? state.checkout.checkoutShefIds : [cart.activeCartShefId]),
  selectAvailableCartOrdersByShefIds
);

export const selectUnavailableCheckoutOrders = createSelector(
  (_state: RootState, cart: CartState) => cart,
  (state: RootState, cart: CartState) => (cart.isMultiCart ? state.checkout.checkoutShefIds : [cart.activeCartShefId]),
  selectUnavailableCartOrdersByShefIds
);

export const selectCheckoutOrdersInputs = createSelector(
  selectCheckoutOrders,
  (_state: RootState, cart: CartState) => selectUnavailableLineItemIds(cart),
  (_state: RootState, cart: CartState) => selectCartFoodItemsById(cart),
  (orders, unvailableLineItemIds, foodItems) =>
    filter(
      orders.map((order) => ({
        shefId: order.shef.id,
        lineItems: order.lineItems
          .filter((item) => !unvailableLineItemIds.has(item.id))
          .map((item) => ({
            foodItemId: item.foodItemId,
            quantity: item.quantity,
            customizationIds: item.customizationIds,
          })),
        lineItemsSubtotal: calcLineItemsSubtotal(order.lineItems, foodItems),
      })),
      (order) => order.lineItems.length > 0
    )
);

export const selectAvailableCheckoutShefs = createSelector(selectCheckoutOrders, (checkoutOrders) =>
  checkoutOrders.map((order) => order.shef)
);

export const selectAvailableCheckoutShefIds = createSelector(selectCheckoutOrders, (checkoutOrders) =>
  checkoutOrders.map((order) => order.shef.id)
);

export const v1Checkout: IFirstStateVersion = {
  version: 1 as const,
  codec: t.unknown,
};

export const versions: IStateVersions = [v1Checkout];
