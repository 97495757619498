import { css } from 'styled-components';

/**
 * Sets left side border radius.
 */
export const setLeftSide = (borderRadius: string | number) => css`
  border-top-left-radius: ${borderRadius};
  border-bottom-left-radius: ${borderRadius};
`;

/**
 * Sets right side border radius.
 */
export const setRightSide = (borderRadius: string | number) => css`
  border-top-right-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`;

/**
 * Sets top side border radius.
 */
export const setTopSide = (borderRadius: string | number) => css`
  border-top-left-radius: ${borderRadius};
  border-top-right-radius: ${borderRadius};
`;

/**
 * Sets bottom side border radius.
 */
export const setBottomSide = (borderRadius: string | number) => css`
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`;

/**
 * Flattens left side border radius.
 */
export const flattenLeftSide = () => css`
  ${setLeftSide(0)}
`;

/**
 * Flattens right side border radius.
 */
export const flattenRightSide = () => css`
  ${setRightSide(0)}
`;

/**
 * Flattens top side border radius.
 */
export const flattenTopSide = () => css`
  ${setTopSide(0)}
`;

/**
 * Flattens bottom side border radius.
 */
export const flattenBottomSide = () => css`
  ${setBottomSide(0)}
`;

export const safariOverflowBorderRadiusFix = () => css`
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`;
