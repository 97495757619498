import { ApolloError } from '@apollo/client';
import { noOp } from 'common/Constants';
import { createContext } from 'react';
import { GetSearchResultsQuery, SearchQuery } from 'src/gqlReactTypings.generated.d';

export interface CombinedSearchDataContextType {
  data?: SearchQuery;
  error?: ApolloError;
  loading?: boolean;
  hasMoreResults: boolean;
  isMoreResultsLoading: boolean;
  handleQueryChange: (query: string) => void;
  handleShowMoreShefs: () => void;
  handleShowMoreDishes: () => void;
  handleClearSearch: () => void;
}

export interface SearchDataContextType {
  activeZipCode: string | null;
  data?: GetSearchResultsQuery;
  dataCombinedSearch?: CombinedSearchDataContextType;
  error?: ApolloError;
  loading?: boolean;
  hasMoreResults: boolean;
  isMoreResultsLoading: boolean;
  isSearchHidden: boolean;
  handleQueryChange: (query: string, isPartialQuery: boolean) => void;
  handleShowMoreShefs: () => void;
  handleShowMoreDishes: () => void;
  handleClearSearch: () => void;
  refreshSearch: () => void;
}

export const defaultSearchDataContextState = {
  activeZipCode: null,
  hasMoreResults: false,
  isMoreResultsLoading: false,
  isSearchHidden: false,
  handleQueryChange: noOp,
  handleShowMoreShefs: noOp,
  handleShowMoreDishes: noOp,
  handleClearSearch: noOp,
  refreshSearch: noOp,
};

export const SearchDataContext = createContext<SearchDataContextType>(defaultSearchDataContextState);
