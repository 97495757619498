import { animated } from 'react-spring';
import { BoxHeart } from 'src/shared/design-system/Icon';
import { ProgressBar as ProgressBarComponent } from 'src/shared/design-system/ProgressBar';
import styled, { css } from 'styled-components';
import { Colors, Font, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';

export const Container = styled.div`
  gap: ${Spacing.HALF};
  ${Mixins.Flex.centerColumn()}
`;

export const ProgressBar = styled(ProgressBarComponent).attrs(
  ({ isOnDarkBackground = false }: { isOnDarkBackground?: boolean }) => ({
    backgroundColor: isOnDarkBackground ? Colors.WHITE : Colors.GRAY_LITE_10,
    barColor: Colors.ELDERBERRY_DARK_10,
    showGlow: true,
    transitionTime: Transition.TimeValue.DOUBLE,
  })
)<{ isOnDarkBackground?: boolean }>``;

export const RemainingAmount = styled(animated.span)`
  font-weight: ${Font.Weight.SEMI_BOLD};
`;

export const Message = styled.div<{ isShowing?: boolean }>`
  color: inherit;
  transition: transform ${Transition.Time.BASE}, opacity ${Transition.Time.BASE};
  gap: ${Spacing.HALF};
  ${Mixins.Flex.center()}
  ${Font.bySize(Font.Size.XS)}
  ${({ isShowing }) => css`
    opacity: ${isShowing ? 1 : 0};
  `}
  ${Mixins.Media.maxWidthSmall(css`
    font-size: ${Font.Size.XXS};
  `)}
`;

export const MessageContainer = styled.div`
  position: relative;
  gap: ${Spacing.HALF};
  overflow: hidden;
  width: 100%:
  ${Mixins.Flex.center()}
`;

export const MessageWrapper = styled.div`
  overflow: hidden;
  transition: height ${Transition.Time.BASE}, width ${Transition.Time.BASE};
`;

export const AllCapsText = styled.span`
  font-weight: ${Font.Weight.SEMI_BOLD};
  text-transform: uppercase;
`;

export const FreeDeliveryIcon = BoxHeart;
