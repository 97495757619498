import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './teotimo-garcia.jpg';

export const TeotimoGarcia: PersonInfo = {
  firstName: 'Teotimo',
  lastName: 'Garcia',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
