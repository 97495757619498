import React, { useCallback } from 'react';
import { PrimaryButton } from 'src/shared/design-system/Buttons';
import { Header2 } from 'src/shared/design-system/Headers/Headers';
import styled from 'styled-components';
import { Font } from 'web-common/src/shared/styles';
import { IContentProps } from './types';

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

const Heading = styled(Header2)`
  color: #da3a4a;
  font-size: 22px;
  line-height: 28px;
`;

const Input = styled.input`
  width: 100%;
  padding: 25px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  text-align: center;

  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
`;

export const OrderAheadContent = React.forwardRef<HTMLInputElement, IContentProps>(
  ({ value, onChange, canSubmit, submit }, ref) => {
    const updateValue = useCallback(
      (evt: React.ChangeEvent<HTMLInputElement>) => {
        onChange(evt.target.value);
      },
      [onChange]
    );

    return (
      <>
        <HeaderRow>
          <Heading>Enter your zip code</Heading>
        </HeaderRow>
        <Input type='number' value={value} onChange={updateValue} ref={ref} data-cy='zip-modal-input' />
        <PrimaryButton onClick={submit} disabled={!canSubmit} data-cy='zip-modal-button'>
          Submit
        </PrimaryButton>
      </>
    );
  }
);
