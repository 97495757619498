import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const ZoomOut = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG height={24} width={24} viewBox={'0 0 24 24'} {...props}>
    <Path
      d='M15.5,11c0,0.83-0.67,1.5-1.5,1.5H8c-0.83,0-1.5-0.67-1.5-1.5S7.17,9.5,8,9.5h6C14.83,9.5,15.5,10.17,15.5,11z M21.56,21.56
          C21.27,21.85,20.88,22,20.5,22s-0.77-0.15-1.06-0.44l-3.23-3.23C14.74,19.38,12.94,20,11,20c-4.97,0-9-4.03-9-9c0-4.97,4.03-9,9-9
          s9,4.03,9,9c0,1.94-0.62,3.74-1.67,5.21l3.23,3.23C22.15,20.03,22.15,20.97,21.56,21.56z M11,17c3.31,0,6-2.69,6-6s-2.69-6-6-6
          s-6,2.69-6,6S7.69,17,11,17z'
    />
  </SVG>
);
