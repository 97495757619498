import { QueryParams } from 'common/urls/QueryParams';
import { useCallback } from 'react';
import { useHistory } from 'src/shared/hooks/useHistory';
import { getPathName } from 'src/shared/utils/DishCardUtils';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

export const useDishModalQueryParams = () => {
  const history = useHistory();
  const [{ [QueryParams.DISH]: foodItemSlug, [QueryParams.SHEF]: shefId }] = useQueryParams({
    [QueryParams.DISH]: withDefault(StringParam, null),
    [QueryParams.SHEF]: withDefault(StringParam, null),
  });

  const clearUrl = useCallback(
    (replaceState?: boolean) => {
      const urlParams = new URLSearchParams(history.location.search);
      urlParams.delete(QueryParams.DISH);
      urlParams.delete(QueryParams.SHEF);
      urlParams.delete(QueryParams.RANK);
      urlParams.delete(QueryParams.EXPLORE_SEGMENT_ID);
      urlParams.delete(QueryParams.EXPLORE_SEGMENT_POS);
      urlParams.delete(QueryParams.EXPLORE_SEGMENT_RENDERED_ITEMS);
      urlParams.delete(QueryParams.EXPLORE_SEGMENT_TOTAL_ITEMS);
      const updateUrl = replaceState ? history.replace : history.push;
      updateUrl(getPathName(history.location.pathname, urlParams), { scrollToTop: false });
    },
    [history]
  );

  return {
    clearUrl,
    foodItemSlug,
    shefId,
    hasUrlParams: !!foodItemSlug || !!shefId,
  };
};
