import { RootState } from 'src/store';
import { DockedScreenSize, NavbarState, NavLocation } from './types';
import { getToggleLocations, isDocked } from './utils';

export const selectNavbar = (state: RootState): NavbarState => state.navbar;

export const selectIsDockedDesktop = (state: RootState, location: NavLocation) =>
  isDocked(selectNavbar(state)[DockedScreenSize.DESKTOP], location);
export const selectIsDockedTablet = (state: RootState, location: NavLocation) =>
  isDocked(selectNavbar(state)[DockedScreenSize.TABLET], location);
export const selectIsDockedMobile = (state: RootState, location: NavLocation) =>
  isDocked(selectNavbar(state)[DockedScreenSize.MOBILE], location);

export const selectAnimations = (state: RootState) => selectNavbar(state)[NavLocation.ANIMATIONS];

export const selectBackButton = (state: RootState) => selectNavbar(state)[NavLocation.BACK_BUTTON];

export const selectCuisineFilters = (state: RootState) => selectNavbar(state)[NavLocation.CUISINE_FILTERS];

export const selectCuisineFilterButton = (state: RootState) => selectNavbar(state)[NavLocation.CUISINE_FILTER_BUTTON];
export const selectCuisineFilterButtonDockedDesktop = (state: RootState) =>
  selectIsDockedDesktop(state, NavLocation.CUISINE_FILTER_BUTTON);
export const selectCuisineFilterButtonDockedTablet = (state: RootState) =>
  selectIsDockedTablet(state, NavLocation.CUISINE_FILTER_BUTTON);
export const selectCuisineFilterButtonDockedMobile = (state: RootState) =>
  selectIsDockedMobile(state, NavLocation.CUISINE_FILTER_BUTTON);

export const selectDatePicker = (state: RootState) => selectNavbar(state)[NavLocation.DATE_PICKER];

export const selectDatePickerButton = (state: RootState) => selectNavbar(state)[NavLocation.DATE_PICKER_BUTTON];
export const selectDatePickerButtonDockedDesktop = (state: RootState) =>
  selectIsDockedDesktop(state, NavLocation.DATE_PICKER_BUTTON);
export const selectDatePickerButtonDockedTablet = (state: RootState) =>
  selectIsDockedTablet(state, NavLocation.DATE_PICKER_BUTTON);
export const selectDatePickerButtonDockedMobile = (state: RootState) =>
  selectIsDockedMobile(state, NavLocation.DATE_PICKER_BUTTON);

export const selectDietaryFilters = (state: RootState) => selectNavbar(state)[NavLocation.DIETARY_FILTERS];

export const selectFilterBar = (state: RootState) => selectNavbar(state)[NavLocation.FILTER_BAR];

export const selectInlineFilterButtons = (state: RootState) => selectNavbar(state)[NavLocation.INLINE_FILTER_BUTTONS];
export const selectInlineFilterButtonsDockedDesktop = (state: RootState) =>
  selectIsDockedDesktop(state, NavLocation.INLINE_FILTER_BUTTONS);
export const selectInlineFilterButtonsDockedTablet = (state: RootState) =>
  selectIsDockedTablet(state, NavLocation.INLINE_FILTER_BUTTONS);
export const selectInlineFilterButtonsDockedMobile = (state: RootState) =>
  selectIsDockedMobile(state, NavLocation.INLINE_FILTER_BUTTONS);

export const selectMenuBar = (state: RootState) => selectNavbar(state)[NavLocation.MENU_BAR];

export const selectMoreFilters = (state: RootState) => selectNavbar(state)[NavLocation.MORE_FILTERS];

export const selectNavigation = (state: RootState) => selectNavbar(state)[NavLocation.NAVIGATION];

export const selectPromo = (state: RootState) => selectNavbar(state)[NavLocation.PROMO];
export const selectPromoExpanded = (state: RootState) => selectNavbar(state)[NavLocation.PROMO_EXPANDED];

export const selectProteinFilters = (state: RootState) => selectNavbar(state)[NavLocation.PROTEIN_FILTERS];

export const selectSearch = (state: RootState) => selectNavbar(state)[NavLocation.SEARCH];

export const selectSubCuisineFilters = (state: RootState) => selectNavbar(state)[NavLocation.SUB_CUISINE_FILTERS];

export const selectIsScrollingUp = (state: RootState) => selectNavbar(state).isScrollingUp;
export const selectIsContentShowing = (state: RootState) =>
  getToggleLocations()
    .map((location) => selectNavbar(state)[location])
    .some((isShowing) => isShowing);
