export const DEFAULT_IMAGE = '';

export const FOOD_IMAGE_PROPS = {
  alt: 'Food Image',
  sourceWidth: 480,
  sourceHeight: 320,
};

export const TEXT = {
  ADD_TO_CART: 'Add to cart',
  CUISINE_DELIMITER: ', ',
  DESCRIPTION: 'Description',
  INGREDIENTS: 'Main ingredients',
  PRICE: 'Total price',
  PORTION: 'Portion size',
  SEE_ALL: 'See all',
  SOLD_OUT: 'Sold out',
};
