import { ExceptFields, JSONObj } from '../TypeUtilities';
import { DishAvailability, ShefAvailability } from './AvailabilityError';

export enum ShefErrorCode {
  // Apollo errors
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  BAD_REQUEST = 'BAD_REQUEST',

  // Auth
  INVALID_EMAIL = 'INVALID_EMAIL',
  SIGN_UP_USER_ALREADY_EXISTS = 'SIGN_UP_USER_ALREADY_EXISTS',
  FACEBOOK_SIGN_UP_NO_EMAIL = 'FACEBOOK_SIGN_UP_NO_EMAIL',
  GOOGLE_BROWSER_NOT_SUPPORTED = 'GOOGLE_BROWSER_NOT_SUPPORTED',

  // Misc
  SHEF_DNE = 'SHEF_DNE',
  SHEF_NOT_AVAILABLE = 'SHEF_NOT_AVAILABLE',

  INVALID_ZIP = 'INVALID_ZIP',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
}

export enum ShefErrorCodeWithPayload {
  EXCEEDED_AVAILABILITY = 'EXCEEDED_AVAILABILITY',
}

export type AnyShefErrorCode = ShefErrorCode | ShefErrorCodeWithPayload;

export interface ShefErrorExtensionData extends Record<ShefErrorCodeWithPayload, ExceptFields<JSONObj, 'source'>> {
  [ShefErrorCodeWithPayload.EXCEEDED_AVAILABILITY]: {
    updatedFoodItemAvailabilities: DishAvailability[];
    updatedShefAvailabilities: ShefAvailability[];
    unavailableFoodItems: DishAvailability[];
    unavailableShefs: ShefAvailability[];
  };
}

// GraphQL error extensions, included in error sent to client
export type ShefErrorExtensions<C extends AnyShefErrorCode> = C extends ShefErrorCodeWithPayload
  ? {
      // Code for associating error between server and client
      code: C;

      // Error information sent to client
      errorData: ShefErrorExtensionData[C];
    }
  : { code: C };
