import { valueToEnumOrNull } from 'common/EnumUtils';
import { shefSpiceText, SpiceLevel } from 'common/SpiceLevels';
import { isNil, sortBy, uniqBy } from 'lodash';
import { ChangeEventHandler } from 'react';
import {
  FoodItemCustomizationFieldsFragment,
  FoodItemCustomizationTypeFieldsFragment,
  PriceFactorType,
} from 'src/gqlReactTypings.generated.d';
import { Fieldset, FieldsetTag } from 'src/shared/design-system/Input/Fieldset/Fieldset';
import { FieldsetInput } from 'src/shared/design-system/Input/Fieldset/FieldsetInput';
import { SpiceTag } from 'src/shared/design-system/SpiceTagV2';
import { DEFAULT_CUSTOMIZATION_ID } from '../../types';
import { CustomizationOption } from './CustomizationOption';
import { SpiceLevelPill } from './SpiceLevelPill';

type SpiceLevelCustomization = Pick<FoodItemCustomizationFieldsFragment, 'priceFactor' | 'priceFactorType' | 'id'> & {
  customizationType: Pick<FoodItemCustomizationTypeFieldsFragment, 'name'>;
};

interface ISpiceLevelCustomizationsProps {
  spiceLevelOptions?: SpiceLevelCustomization[];
  defaultSpiceLevel: SpiceLevel;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
  disabled?: boolean;
}

export const SpiceLevelCustomizations: React.FC<ISpiceLevelCustomizationsProps> = ({
  spiceLevelOptions,
  defaultSpiceLevel,
  onChange,
  value,
  disabled,
}) => {
  if (isNil(spiceLevelOptions)) {
    return <SpiceLevelPill spiceLevel={defaultSpiceLevel} disabled={disabled} />;
  }

  // Include the default
  const allSpiceLevelOptions = sortBy(
    uniqBy(
      [
        {
          id: DEFAULT_CUSTOMIZATION_ID,
          priceFactor: 0,
          priceFactorType: PriceFactorType.Nominal,
          spiceLevel: defaultSpiceLevel,
        },
        ...spiceLevelOptions.map((spiceLevelOption) => {
          const parsedSpiceLevel = parseInt(spiceLevelOption.customizationType.name, 10);
          const spiceLevel = valueToEnumOrNull(SpiceLevel, parsedSpiceLevel) ?? SpiceLevel.NOT_SPICY;
          return {
            spiceLevel,
            ...spiceLevelOption,
          };
        }),
      ],
      'spiceLevel'
    ),
    'spiceLevel'
  );
  return (
    <div className='flex flex-col py-6'>
      <Fieldset label='Spice level' tag={FieldsetTag.OPTIONAL} disabled={disabled}>
        {allSpiceLevelOptions.map((spiceLevelOption) => {
          const spiceLevelText = shefSpiceText.get(spiceLevelOption.spiceLevel) ?? 'Not spicy';
          return (
            <FieldsetInput
              checked={value === spiceLevelOption.id}
              name={spiceLevelText}
              onChange={onChange}
              type='radio'
              key={spiceLevelOption.id}
              value={spiceLevelOption.id}
              disabled={disabled}>
              <CustomizationOption
                icon={<SpiceTag spiceLevel={spiceLevelOption.spiceLevel} />}
                mainText={spiceLevelText}
                highlightText={spiceLevelOption.spiceLevel === defaultSpiceLevel ? 'Shef recommendation' : undefined}
              />
            </FieldsetInput>
          );
        })}
      </Fieldset>
    </div>
  );
};
