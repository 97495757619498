import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './jake-scott.jpg';

export const JakeScott: PersonInfo = {
  firstName: 'Jake',
  lastName: 'Scott',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
