import { ShefProfileState } from './types';

export const initialShefProfileState: ShefProfileState = {
  bio: '',
  cancelOrderNotificationFrequency: null,
  coverPhotoUrl: '',
  email: '',
  id: 0,
  firstName: '',
  lastName: '',
  newOrderNotificationFrequency: null,
  dishCapacity: null,
  phone: '',
  businessName: '',
  websiteUrl: '',
  facebookHandle: '',
  instagramHandle: '',
  twitterHandle: '',
  tikTokHandle: '',
  linkedInHandle: '',
  youtubeHandle: '',
  previewFoodItemId: null,
  inputState: {},
};
