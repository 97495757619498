import { noOp } from 'common/Constants';
import React from 'react';
import { DropdownSize, ItemProps, KeyPressType } from '../consts';
import { getItemDisplayValue } from '../utils';
import { DropdownInput, Icon, IconContainer, InputOverlay, MobileSelect } from './styles';

interface IInputProps {
  /** Is autocomplete enabled. */
  autocomplete: boolean;
  /** If input is disabled. */
  disabled?: boolean;
  /** If the dropdown is open. */
  isOpen?: boolean;
  /** Its to show in HTML select when `showMobileDropdown` is true. */
  items?: ItemProps[];
  /** Callback when selected value changes. */
  onChangeValue: (value: string, item: ItemProps) => void;
  /** Returns keydown event. */
  onKeyDown?: KeyPressType;
  /** For non autocomplete, returns keypress event. */
  onKeyPress?: KeyPressType;
  /** Placeholder text. */
  placeholder?: string;
  /** Sets the value in the input when selected item changes. */
  setInputValue: (value: string) => void;
  /** Show the dropdown arrow. */
  showDropdownArrow?: boolean;
  /** Display HTML select dropdown on mobile. */
  showMobileDropdown?: boolean;
  /** Size of the input. */
  size: DropdownSize;
}

export const Input = React.forwardRef<HTMLInputElement, IInputProps>((props, ref) => {
  const {
    autocomplete,
    disabled,
    isOpen,
    items,
    onChangeValue,
    onKeyDown = noOp,
    onKeyPress = noOp,
    placeholder,
    setInputValue,
    showDropdownArrow = true,
    showMobileDropdown,
    size,
    ...otherProps
  } = props;

  return (
    <>
      {!autocomplete && !showMobileDropdown && <InputOverlay isOpen={isOpen} />}
      {showDropdownArrow && (
        <IconContainer data-role={'dropdown-arrow'} isOpen={isOpen} size={size}>
          <Icon />
        </IconContainer>
      )}
      {showMobileDropdown && items && (
        <MobileSelect
          onChange={(e) => {
            const selectedValue = e.target.value;
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const selectedItem = items.find((item) => item.value === selectedValue) || ({} as ItemProps);

            setInputValue(getItemDisplayValue(selectedItem));
            onChangeValue(selectedValue, selectedItem);
          }}>
          {items.map((item) => (
            <option key={item.value} value={item.value}>
              {getItemDisplayValue(item)}
            </option>
          ))}
        </MobileSelect>
      )}
      <DropdownInput
        {...otherProps}
        disabled={disabled}
        dropdownSize={size}
        onKeyDown={(e) => {
          onKeyDown(e);
          onKeyPress(e);
        }}
        ref={ref}
        readOnly={!autocomplete}
        placeholder={placeholder}
      />
    </>
  );
});
