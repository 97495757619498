import { FollowColors, FollowToggleIcon } from 'src/shared/design-system/FollowShef/FollowToggleIcon';
import { ChevronRight } from 'src/shared/design-system/Icon/ChevronRight';
import { Clickable } from 'src/shared/design-system/Utilities/Clickable';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { Border, Colors, Font, Spacing, Transition } from 'web-common/src/shared/styles';
import { AvatarImage } from '../AvatarImage';

export const Container = styled(Clickable)<{ showShadow?: boolean }>`
  align-items: center;
  background: ${Colors.WHITE};
  display: flex;
  font-family: ${Font.Family.SANS};
  gap: ${Spacing.SINGLE};
  padding: ${Spacing.HALF};
  text-align: left;
  text-decoration: none !important;
  transition: box-shadow ${Transition.Time.BASE};
  width: 100%;

  ${({ href, showShadow }) =>
    match({ hasLink: !!href, showShadow })
      .with(
        { showShadow: true, hasLink: true },
        () => css`
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: ${Border.Radius.M};
          cursor: pointer;

          &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
        `
      )
      .with(
        { showShadow: true, hasLink: false },
        () => css`
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: ${Border.Radius.M};
        `
      )
      .with(
        { showShadow: false, hasLink: true },
        () => css`
          &:hover {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
        `
      )
      .otherwise(() => ``)}
`;

export const ContentContainer = styled.div`
  flex: 1 1 100%;
`;

export const AvatarImageWrapper = styled.div`
  position: relative;
`;

export const Image = styled(AvatarImage).attrs(() => ({
  size: Spacing.TRIPLE_HALF,
}))``;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const Header = styled.div`
  color: ${Colors.GRAY_DARK_30};
  font-weight: 500;
  font-size: ${Font.Size.S};
  line-height: ${Font.LineHeight.S};
`;

export const SubHeader = styled.div`
  color: ${Colors.GRAY_DARK_10};
  font-weight: 400;
  font-size: ${Font.Size.S};
  line-height: ${Font.LineHeight.S};
`;

export const Arrow = styled(ChevronRight)`
  color: ${Colors.GRAY_DARK_30};
  width: ${Spacing.SINGLE_HALF};
`;

export const FollowIcon = styled(FollowToggleIcon).attrs(() => ({
  followingColor: FollowColors.RED,
  isAnimated: false,
  isFollowing: true,
}))``;

export const FollowIconContainer = styled.div`
  align-items: center;
  background: ${Colors.WHITE};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  top: -4px;
  right: -4px;
  height: ${Spacing.SINGLE_HALF};
  width: ${Spacing.SINGLE_HALF};
  position: absolute;

  & svg {
    margin-top: ${Spacing.QUARTER};
    width: ${Spacing.SINGLE_QUARTER};
  }
`;
