import { DEFAULT_DISH_RATING_FILTER, DishRatingFilter } from 'common/DishRatingFilter';
import { valueToStringEnumOrNull } from 'common/EnumUtils';
import { StorageKeys } from 'common/storage/constants';
import { DateString } from 'common/UrlDate';
import { QueryParams } from 'common/urls/QueryParams';
import { Slugs } from 'common/urls/Slugs';
import { useCallback, useMemo } from 'react';
import { RouteChildrenProps } from 'react-router';
import { usePersistentDeliveryDates } from 'src/dish-first/usePersistentDeliveryDates';
import {
  BrowseFilter,
  DishFirstSegmentFilter,
  FoodTypeCoalesced,
  PriceLevel,
  Protein,
  SegmentFilter,
  TagFilter,
} from 'src/gqlReactTypings.generated.d';
import { DEFAULT_IS_ECO_FRIENDLY_PACKAGING } from 'src/pages/consumer/dish-first/explore/Constants';
import { useLocalStorage } from 'src/shared/storage/Storage';
import {
  validateParamAsDateStrings,
  validateParamAsStringArray,
  validateParamInNumberEnum,
  validateParamsInEnum,
} from 'src/shared/utils/QueryParamsUtilities';
import { getSlug } from 'src/shared/utils/RouteUtilities';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { DietaryTags, SpiceLevelOptions } from 'web-common/src/shared/Enums';

export type QueryParamsUpdateType = 'replaceIn' | 'pushIn';

export const DEFAULT_UPDATE_TYPE: QueryParamsUpdateType = 'pushIn';

export type QueryParamsUpdates<TSegmentFilter extends DishFirstSegmentFilter | SegmentFilter> = Partial<{
  [QueryParams.BROWSE_FILTER]: BrowseFilter;
  [QueryParams.TAG_FILTER]: TagFilter[];
  [QueryParams.CONSUMER_SEARCH]: string;
  [QueryParams.CUISINE_FILTER]: string[];
  [QueryParams.DATE]: DateString[];
  [QueryParams.DIETARY_FILTER]: DietaryTags[];
  [QueryParams.DISH_TYPE_FILTER]: FoodTypeCoalesced[];
  [QueryParams.ECO_FILTER]: boolean;
  [QueryParams.MIN_RATING_FILTER]: number;
  [QueryParams.PRICE_LEVEL_FILTER]: PriceLevel[];
  [QueryParams.PROTEIN_FILTER]: Protein[];
  [QueryParams.SEGMENT_FILTER_SCROLL]: TSegmentFilter;
  [QueryParams.SEGMENT_FILTER]: TSegmentFilter[];
  [QueryParams.SPICE_LEVEL_FILTER]: SpiceLevelOptions[];
  [QueryParams.ZIP]: string;
}>;

export interface UseExploreUrl<TSegmentFilter extends DishFirstSegmentFilter | SegmentFilter> {
  activeZipCode: string | null;
  browseFilter: BrowseFilter | null;
  tagFilters: TagFilter[];
  clearQueryParams: () => void;
  cuisineFilters: string[];
  dateFilters: DateString[];
  dietaryFilters: DietaryTags[];
  dishTypeFilters: FoodTypeCoalesced[];
  ecoFilter: boolean;
  minRatingFilter: number;
  priceLevelFilters: PriceLevel[];
  proteinFilters: Protein[];
  scrollToSegmentFilter: TSegmentFilter | null;
  searchQuery: string | null;
  segmentFilters: TSegmentFilter[];
  spiceLevelFilters: SpiceLevelOptions[];
  updateQueryParams: (updates: QueryParamsUpdates<TSegmentFilter>, updateType?: QueryParamsUpdateType) => void;
  zipCode: string | null;
}

/**
 * Hook for parsing and validating the query params for the explore page as well as path params.
 *
 * This hook also:
 *   - exposes an API to update the query params
 * . - updates the query params in the url to only the valid query params
 *   - caches the zipcode from the url
 */
export const useExploreUrl = ({ match }: { match: RouteChildrenProps['match'] }): UseExploreUrl<SegmentFilter> =>
  useExploreUrlGeneric({ match, segmentFilterType: SegmentFilter });

export type UseDishFirstExploreUrl = UseExploreUrl<DishFirstSegmentFilter> & { dateFilters: DateString[] };

export const useDishFirstExploreUrl = ({ match }: { match: RouteChildrenProps['match'] }): UseDishFirstExploreUrl => {
  const result = useExploreUrlGeneric({ match, segmentFilterType: DishFirstSegmentFilter });
  const [deliveryDates] = usePersistentDeliveryDates();
  return useMemo(() => ({ ...result, dateFilters: deliveryDates }), [deliveryDates, result]);
};

const useExploreUrlGeneric = <TSegmentFilter extends DishFirstSegmentFilter | SegmentFilter>({
  match,
  segmentFilterType,
}: {
  match: RouteChildrenProps['match'];
  segmentFilterType: {
    [id: string]: TSegmentFilter;
    [id: number]: TSegmentFilter;
  };
}): UseExploreUrl<TSegmentFilter> => {
  const [, setDietaryPrefs] = useLocalStorage<string[]>(StorageKeys.DIETARY_PREFERENCES, []);
  const [, setSpiceLevelPrefs] = useLocalStorage<string[]>(StorageKeys.SPICE_LEVEL_PREFERENCES, []);
  const [, setCuisinePrefs] = useLocalStorage<string[]>(StorageKeys.CUISINE_PREFERENCES, []);
  const cuisineParam = getSlug(match, Slugs.CUISINE);
  const zipCodeFromPath = useMemo(() => getSlug(match, Slugs.ZIP_CODE), [match]);

  const [
    {
      [QueryParams.BROWSE_FILTER]: rawBrowseFilter,
      [QueryParams.CONSUMER_SEARCH]: searchQuery,
      [QueryParams.CUISINE_FILTER]: rawCuisineFilters,
      [QueryParams.DATE]: rawDateFilters,
      [QueryParams.DIETARY_FILTER]: rawDietaryFilters,
      [QueryParams.DISH_TYPE_FILTER]: rawDishTypeFilters,
      [QueryParams.ECO_FILTER]: ecoFilter,
      [QueryParams.MIN_RATING_FILTER]: rawMinRatingFilter,
      [QueryParams.PROTEIN_FILTER]: rawProteinFilters,
      [QueryParams.PRICE_LEVEL_FILTER]: rawPriceLevelFilters,
      [QueryParams.SEGMENT_FILTER_SCROLL]: rawScrollToSegmentFilter,
      [QueryParams.SEGMENT_FILTER]: rawSegmentFilters,
      [QueryParams.SPICE_LEVEL_FILTER]: rawSpiceLevelFilters,
      [QueryParams.TAG_FILTER]: rawTagFilters,
      [QueryParams.ZIP]: zipCodeFromParams,
    },
    setQueryParams,
  ] = useQueryParams({
    [QueryParams.BROWSE_FILTER]: withDefault(StringParam, null),
    [QueryParams.CONSUMER_SEARCH]: withDefault(StringParam, null),
    [QueryParams.CUISINE_FILTER]: withDefault(ArrayParam, []),
    [QueryParams.DATE]: withDefault(ArrayParam, []),
    [QueryParams.DIETARY_FILTER]: withDefault(ArrayParam, []),
    [QueryParams.DISH_TYPE_FILTER]: withDefault(ArrayParam, []),
    [QueryParams.ECO_FILTER]: withDefault(BooleanParam, DEFAULT_IS_ECO_FRIENDLY_PACKAGING),
    [QueryParams.MIN_RATING_FILTER]: withDefault(NumberParam, DEFAULT_DISH_RATING_FILTER),
    [QueryParams.PRICE_LEVEL_FILTER]: withDefault(ArrayParam, null),
    [QueryParams.PROTEIN_FILTER]: withDefault(ArrayParam, null),
    [QueryParams.SEGMENT_FILTER_SCROLL]: withDefault(StringParam, null),
    [QueryParams.SEGMENT_FILTER]: withDefault(ArrayParam, []),
    [QueryParams.SPICE_LEVEL_FILTER]: withDefault(ArrayParam, []),
    [QueryParams.TAG_FILTER]: withDefault(ArrayParam, []),
    [QueryParams.ZIP]: withDefault(StringParam, null),
  });

  // Sync the cached zipcode from the zipcode from the url
  const zipCodeFromUrl = zipCodeFromPath ?? zipCodeFromParams;
  const [cachedZipCode, setCachedZipCode] = useCachedZipCode();
  setCachedZipCode(zipCodeFromUrl);

  const activeZipCode = zipCodeFromUrl ?? cachedZipCode;

  const segmentFilters: TSegmentFilter[] = useMemo(
    () =>
      validateParamsInEnum(rawSegmentFilters, segmentFilterType) ??
      validateParamsInEnum(cuisineParam, segmentFilterType),
    [cuisineParam, rawSegmentFilters]
  );
  const scrollToSegmentFilter: TSegmentFilter | null = useMemo(
    () => validateParamsInEnum(rawScrollToSegmentFilter, segmentFilterType)[0] ?? null,
    [rawScrollToSegmentFilter]
  );
  const dateFilters = useMemo(() => validateParamAsDateStrings(rawDateFilters), [rawDateFilters]);
  const dietaryFilters = useMemo(() => validateParamsInEnum(rawDietaryFilters, DietaryTags), [rawDietaryFilters]);
  const dishTypeFilters = useMemo(
    () => validateParamsInEnum(rawDishTypeFilters, FoodTypeCoalesced),
    [rawDishTypeFilters]
  );
  const cuisineFilters = useMemo(() => validateParamAsStringArray(rawCuisineFilters), [rawCuisineFilters]);
  const spiceLevelFilters = useMemo(
    () => validateParamsInEnum(rawSpiceLevelFilters, SpiceLevelOptions),
    [rawSpiceLevelFilters]
  );
  const minRatingFilter = useMemo(() => {
    const minRatingFilters = validateParamInNumberEnum(rawMinRatingFilter, DishRatingFilter);
    return minRatingFilters[0] ?? null;
  }, [rawMinRatingFilter]);
  const priceLevelFilters = useMemo(
    () => validateParamsInEnum(rawPriceLevelFilters, PriceLevel),
    [rawPriceLevelFilters]
  );
  const proteinFilters = useMemo(() => validateParamsInEnum(rawProteinFilters, Protein), [rawProteinFilters]);

  const browseFilter = useMemo(
    (): BrowseFilter | null =>
      rawBrowseFilter ? valueToStringEnumOrNull({ theEnum: BrowseFilter, value: rawBrowseFilter }) : null,
    [rawBrowseFilter]
  );

  const tagFilters = useMemo(() => validateParamsInEnum(rawTagFilters, TagFilter), [rawTagFilters]);

  const updatePreferences = useCallback(
    (updates: QueryParamsUpdates<TSegmentFilter>) => {
      if (QueryParams.DIETARY_FILTER in updates) {
        setDietaryPrefs(updates[QueryParams.DIETARY_FILTER] ?? []);
      }

      if (QueryParams.SPICE_LEVEL_FILTER in updates) {
        setSpiceLevelPrefs(updates[QueryParams.SPICE_LEVEL_FILTER] ?? []);
      }

      if (QueryParams.CUISINE_FILTER in updates) {
        setCuisinePrefs(updates[QueryParams.CUISINE_FILTER] ?? []);
      }
    },
    [setDietaryPrefs, setSpiceLevelPrefs, setCuisinePrefs]
  );

  /**
   * Updates the query params with the passed in updates and sets user preferences if applicable.
   */
  const updateQueryParams = useCallback(
    (updates: QueryParamsUpdates<TSegmentFilter>, updateType: QueryParamsUpdateType = DEFAULT_UPDATE_TYPE) => {
      setQueryParams(updates, updateType);
      updatePreferences(updates);
    },
    [setQueryParams, updatePreferences]
  );

  const clearQueryParams = useCallback(() => {
    const updates: Record<keyof QueryParamsUpdates<TSegmentFilter>, undefined> = {
      [QueryParams.BROWSE_FILTER]: undefined,
      [QueryParams.CONSUMER_SEARCH]: undefined,
      [QueryParams.CUISINE_FILTER]: undefined,
      [QueryParams.DATE]: undefined,
      [QueryParams.DIETARY_FILTER]: undefined,
      [QueryParams.DISH_TYPE_FILTER]: undefined,
      [QueryParams.ECO_FILTER]: undefined,
      [QueryParams.MIN_RATING_FILTER]: undefined,
      [QueryParams.PRICE_LEVEL_FILTER]: undefined,
      [QueryParams.PROTEIN_FILTER]: undefined,
      [QueryParams.SEGMENT_FILTER_SCROLL]: undefined,
      [QueryParams.SEGMENT_FILTER]: undefined,
      [QueryParams.SPICE_LEVEL_FILTER]: undefined,
      [QueryParams.TAG_FILTER]: undefined,
      [QueryParams.ZIP]: undefined,
    };

    updateQueryParams(updates);
  }, [updateQueryParams]);

  // TODO: re-enable syncing after fixing issue with differences in TSegmentFilter
  // https://app.asana.com/0/1202647408224810/1203116388782020/f

  /**
   * Returns a query param update for the passed in query param
   * if the parsed value and raw value are different.
   */
  // const getQueryParamUpdate = useCallback(
  //   <
  //     QueryParam extends keyof QueryParamsUpdates<TSegmentFilter>,
  //     ParsedValue extends QueryParamsUpdates<TSegmentFilter>[QueryParam]
  //   >(
  //     queryParam: QueryParam,
  //     parsedValue: ParsedValue,
  //     rawValue: unknown
  //   ) => {
  //     return isEqual(parsedValue, rawValue) ? {} : { [queryParam]: parsedValue ?? undefined };
  //   },
  //   []
  // );

  /**
   * Replaces the query params with valid-only query params.
   *
   * We do this by comparing the parsed query param with the raw query param.
   * If they're different, we replace the query param with the parsed query param.
   */
  // useEffect(() => {
  //   const segmentFiltersUpdate = getQueryParamUpdate(QueryParams.SEGMENT_FILTER, segmentFilters, rawSegmentFilters);
  //   const segmentFilterScrollUpdate = getQueryParamUpdate(
  //     QueryParams.SEGMENT_FILTER_SCROLL,
  //     scrollToSegmentFilter,
  //     rawScrollToSegmentFilter
  //   );
  //   const dateFiltersUpdate = getQueryParamUpdate(QueryParams.DATE, dateFilters, rawDateFilters);
  //   const dietaryFiltersUpdate = getQueryParamUpdate(QueryParams.DIETARY_FILTER, dietaryFilters, rawDietaryFilters);
  //   const dishTypeFiltersUpdate = getQueryParamUpdate(QueryParams.DISH_TYPE_FILTER, dishTypeFilters, rawDishTypeFilters);
  //   const spiceLevelFiltersUpdate = getQueryParamUpdate(
  //     QueryParams.SPICE_LEVEL_FILTER,
  //     spiceLevelFilters,
  //     rawSpiceLevelFilters
  //   );
  //   const minRatingFilterUpdate = getQueryParamUpdate(
  //     QueryParams.MIN_RATING_FILTER,
  //     minRatingFilter,
  //     rawMinRatingFilter
  //   );
  //   const priceLevelFiltersUpdate = getQueryParamUpdate(
  //     QueryParams.PRICE_LEVEL_FILTER,
  //     priceLevelFilters,
  //     rawPriceLevelFilters
  //   );
  //   const proteinFiltersUpdate = getQueryParamUpdate(QueryParams.PROTEIN_FILTER, proteinFilters, rawProteinFilters);

  //   const queryParamsUpdates = {
  //     ...dateFiltersUpdate,
  //     ...dietaryFiltersUpdate,
  //     ...dishTypeFiltersUpdate,
  //     ...minRatingFilterUpdate,
  //     ...priceLevelFiltersUpdate,
  //     ...segmentFilterScrollUpdate,
  //     ...segmentFiltersUpdate,
  //     ...spiceLevelFiltersUpdate,
  //     ...proteinFiltersUpdate,
  //   };

  //   if (Object.keys(queryParamsUpdates).length) {
  //     updateQueryParams(queryParamsUpdates, 'replaceIn');
  //   }
  // }, [
  //   dateFilters,
  //   dietaryFilters,
  //   dishTypeFilters,
  //   dietaryPrefs,
  //   getQueryParamUpdate,
  //   minRatingFilter,
  //   priceLevelFilters,
  //   proteinFilters,
  //   rawDateFilters,
  //   rawDietaryFilters,
  //   rawMinRatingFilter,
  //   rawPriceLevelFilters,
  //   rawProteinFilters,
  //   rawScrollToSegmentFilter,
  //   rawSegmentFilters,
  //   rawSpiceLevelFilters,
  //   scrollToSegmentFilter,
  //   segmentFilters,
  //   setDietaryPrefs,
  //   setSpiceLevelPrefs,
  //   spiceLevelFilters,
  //   spiceLevelPrefs,
  //   updateQueryParams,
  // ]);

  return useMemo(
    () => ({
      activeZipCode,
      browseFilter,
      clearQueryParams,
      cuisineFilters,
      dateFilters,
      dietaryFilters,
      dishTypeFilters,
      ecoFilter,
      minRatingFilter,
      priceLevelFilters,
      proteinFilters,
      scrollToSegmentFilter,
      searchQuery,
      segmentFilters,
      spiceLevelFilters,
      tagFilters,
      updateQueryParams,
      zipCode: zipCodeFromUrl,
    }),
    [
      activeZipCode,
      browseFilter,
      clearQueryParams,
      cuisineFilters,
      dateFilters,
      dietaryFilters,
      dishTypeFilters,
      ecoFilter,
      minRatingFilter,
      priceLevelFilters,
      proteinFilters,
      scrollToSegmentFilter,
      searchQuery,
      segmentFilters,
      spiceLevelFilters,
      tagFilters,
      updateQueryParams,
      zipCodeFromUrl,
    ]
  );
};
