import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Alert = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <Path
      d='M8.01562 11.25C7.57812 11.25 7.26562 11.5938 7.26562 12C7.26562 12.4375 7.60938 12.75 8.01562 12.75C8.42188 12.75 8.73438 12.4375 8.73438 12C8.76562 11.5938 8.42188 11.25 8.01562 11.25ZM8.01562 10C8.26562 10 8.48438 9.78125 8.48438 9.5V5C8.48438 4.75 8.23438 4.5 8.01562 4.5C7.76562 4.5 7.51562 4.75 7.51562 5V9.5C7.51562 9.78125 7.73438 10 8.01562 10ZM15.7656 12.4375L9.51562 1.875C9.20312 1.34375 8.64062 1.03125 8.01562 1C7.35938 1 6.79688 1.34375 6.48438 1.875L0.234375 12.4375C-0.078125 12.9688 -0.078125 13.5938 0.234375 14.125C0.546875 14.6875 1.10938 15 1.76562 15H14.2656C14.8906 15 15.4531 14.6875 15.7656 14.125C16.0781 13.5938 16.0781 12.9688 15.7656 12.4375ZM14.8906 13.625C14.7656 13.875 14.5156 14 14.2344 14H1.76562C1.48438 14 1.23438 13.875 1.10938 13.625C0.953125 13.4062 0.984375 13.1562 1.10938 12.9375L7.35938 2.375C7.48438 2.15625 7.73438 2 8.01562 2C8.01562 2 7.98438 2 8.01562 2C8.26562 2.03125 8.51562 2.15625 8.64062 2.375L14.8906 12.9375C15.0156 13.1562 15.0469 13.4062 14.8906 13.625Z'
      fill='currentColor'
    />
  </SVG>
);
