import React from 'react';
import { SVG } from 'src/shared/design-system/Utilities/SVG';
import { match } from 'ts-pattern';
import { IMAGE_SIZE, WAVE_SIZE } from './consts';
import { Container, Path, WaveContainer } from './styles';
import { WaveSize, WavyEdgeProps } from './types';

export const WavyEdge: React.FC<WavyEdgeProps> = (props) => {
  const {
    className,
    isStroke = false,
    position,
    waveHeight = WAVE_SIZE.height,
    waveWidth = WAVE_SIZE.width,
    waveSize = WaveSize.NORMAL,
    style,
  } = props;
  const height = isStroke ? waveHeight + 2 : waveHeight;
  const stroke = 'M0,0c30.05,0,29.74,16,59.91,16S90,0,120,0';
  const fill = `M120,21L120,21H0V1c30,0,29.8,16,60,16c30.2,0,30-16,60-16V21z`;
  const patternId = `bg${isStroke ? '-stroke' : ''}-${props.patternId}`;
  const waveStyle = match(waveSize)
    .with(WaveSize.LONG, () => ({
      rect: {
        transform: `scaleX(1.5)`,
      },
      width: '100%',
    }))
    .with(WaveSize.SHORT, () => ({
      rect: {
        transform: `scaleX(0.667)`,
      },
      width: '150%',
    }))
    .otherwise(() => ({ rect: {}, width: '100%' }));

  return (
    <Container className={className} waveHeight={height} wavePosition={position}>
      <WaveContainer waveHeight={height} waveWidth={waveWidth} style={style} $isStroke={isStroke}>
        <SVG autoViewBox={false} {...IMAGE_SIZE} height={height}>
          <defs>
            <pattern
              id={patternId}
              patternUnits='userSpaceOnUse'
              patternContentUnits='userSpaceOnUse'
              width={waveWidth}
              height={height}
              shapeRendering='geometricPrecision'
              viewBox={WAVE_SIZE.viewBox}>
              <Path $isStroke={isStroke} d={isStroke ? stroke : fill} />
            </pattern>
          </defs>
          <rect width={waveStyle.width} height='100%' fill={`url(#${patternId})`} style={waveStyle.rect} />
        </SVG>
      </WaveContainer>
    </Container>
  );
};
