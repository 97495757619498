import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ObjectPosition } from 'src/shared/Constants';
import styled from 'styled-components';

export const Image = styled(LazyLoadImage).attrs(({ objectPosition }: { objectPosition?: ObjectPosition }) => ({
  'object-position': objectPosition ?? ObjectPosition.CENTER,
}))`
  object-fit: cover;
  object-position: ${(props) => props['object-position']};
  ${(props) => (props.height ? `height: ${props.height}` : '')};
  ${(props) => (props.width ? `width: ${props.width}` : '')};

  // these are needed to force a box size that can be collided with in chrome
  min-width: 1px;
  min-height: 1px;
`;
