import { formatGqlErrors } from 'common/GqlUtilities';
import { isNil, map } from 'lodash';
import { matchPath } from 'react-router';
import { Routes } from 'src/shared/Routes';
import { fetchCart } from 'src/store/cart/utils/cartQueries';
import type { CreateCartEffect } from './listeners';

export const loadCartFromServer: CreateCartEffect =
  ({ gqlClient, cartSlice }) =>
  async (action, listenerApi) => {
    const { setCartReady, setServerCart, setLoggedOutCart, setPossibleDeliveryDates } = cartSlice.actions;
    const { cart } = listenerApi.getState();
    const { userLoggedIn, ready } = cart;
    const checkoutPageMatch = matchPath(window.location.pathname, {
      path: Routes.CONSUMER_ORDER_FINALIZE,
      exact: true,
    });

    const zipCode = cart.zipCode || action?.payload?.zipCode;
    if (!zipCode) return;
    try {
      const { data, errors } = await fetchCart({ gqlClient, zipCode });
      // FUTURE TODO: how to handle errors - likely needs to retry request
      if (errors?.length) return console.error(formatGqlErrors(errors));

      if (data) {
        // set cart item state if user is logged in
        if (userLoggedIn) {
          listenerApi.dispatch(setServerCart(data.carts));
        } else {
          listenerApi.dispatch(setLoggedOutCart(data.carts));
        }

        // If the customer is on the checkout page, don't change the delivery date
        // Use checkout page to surface errors
        if (isNil(checkoutPageMatch)) {
          // set possible delivery dates for zip code
          const possibleDeliveryDates = map(data.exploreFoodItemsFilterOptions?.availableDeliveryDays || [], 'date');
          listenerApi.dispatch(setPossibleDeliveryDates({ possibleDeliveryDates }));
        }
      }
    } catch (err) {
      console.error(err);
    }

    if (!ready) listenerApi.dispatch(setCartReady());
  };
