import {
  initializeStorageStateForNativeApp,
  overwriteStorageMethodsForNativeApp,
} from 'src/shared/utils/NativeAppStorage';
import { initTokenForApp } from 'src/shared/utils/TokenUtils';
import { NativeAppMessenger } from './NativeAppMessenger';

export const initNativeApp = (): NativeAppMessenger | null => {
  const { App } = window;
  if (!App) {
    return null;
  }

  const nativeAppMessenger = new NativeAppMessenger(App);
  initializeStorageStateForNativeApp();
  overwriteStorageMethodsForNativeApp(nativeAppMessenger);
  initTokenForApp();

  return nativeAppMessenger;
};
