import { VerticallyCenteredText } from 'src/shared/design-system/VerticallyCenteredText';
import styled, { css } from 'styled-components';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Border, Colors, Font, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';
import { getBarHighlightPosition, getBarHighlightWidth } from './utils';

export const BUTTON_HEIGHT = Spacing.DOUBLE_QUARTER;
export const PADDING = Spacing.HALF;
export const DEFAULT_PADDING = Mixins.Spacing.getSpacingValue(Spacing.HALF);

export const BarContainer = styled.div<{ padding?: number }>`
  position: relative;
  width: calc(100% - ${({ padding = DEFAULT_PADDING }) => `${padding * 2}px`});
`;

export const ButtonContainer = styled.div<{ padding?: number }>`
  background: ${Colors.GRAY_LITE_20};
  box-sizing: content-box;
  position: relative;
  cursor: pointer;
  transition: background ${Transition.Time.BASE}, border ${Transition.Time.BASE};
  width: 100%;
  ${Mixins.Flex.set('flex-start', 'center')}
  ${({ padding = DEFAULT_PADDING }) => css`
    border-radius: calc(${Border.Radius.M} + ${padding}px);
    padding: ${padding}px;
  `};
`;

export const ButtonHighLight = styled.div<{
  hasValue: boolean;
  height?: number;
  clickedIndex?: number;
  selectedIndex?: number;
  numberOfOptions: number;
  padding?: number;
}>`
  position: absolute;
  background: ${Colors.GRAY_DARK_30};
  border-radius: ${Border.Radius.M};
  height: ${({ height }) => (height ? `${height}px` : BUTTON_HEIGHT)};
  width: ${getBarHighlightWidth};
  z-index: 1;
  opacity: ${ifProp('hasValue', 1, 0)};
  transform: translateX(${getBarHighlightPosition});
  transition: transform ${ifProp('hasValue', Transition.Time.BASE, 0)}, opacity ${Transition.Time.BASE};
  ${({ padding = DEFAULT_PADDING }) => css`
    top: ${padding}px;
    left: ${padding}px;
  `}px;
`;

export const Button = styled.button<{ isActive?: boolean; height?: number }>`
  background: transparent;
  border: none;
  border-radius: ${Border.Radius.M};
  color: ${ifProp('isActive', Colors.WHITE, Colors.GRAY_DARK_30)};
  cursor: pointer;
  height: ${({ height }) => (height ? `${height}px` : BUTTON_HEIGHT)};
  padding: ${Spacing.HALF};
  flex: 1 1 100%;
  z-index: 2;
  transition: color ${Transition.Time.BASE};
  ${Font.bySize(Font.Size.S, Font.Weight.MEDIUM)}
  ${Mixins.Text.overflow()}
  ${Mixins.Flex.center()}
`;

export const ButtonLabel = styled(VerticallyCenteredText)<{ isSelected: boolean }>`
  color: ${ifProp('isSelected', Colors.WHITE, Colors.GRAY_DARK_30)};
  transition: color ${Transition.Time.BASE};
`;
