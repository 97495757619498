import cn from 'classnames';
import { shefSpiceText, SpiceLevel } from 'common/SpiceLevels';
import { SpiceTag } from 'src/shared/design-system/SpiceTagV2';
import { CustomizationOption } from './CustomizationOption';

interface ISpiceLevelPillProps {
  spiceLevel: SpiceLevel;
  disabled?: boolean;
}

export const SpiceLevelPill: React.FC<ISpiceLevelPillProps> = ({ spiceLevel, disabled }) => (
  <div className={cn('flex flex-col py-6', { 'opacity-50': disabled })}>
    <h2 className='mb-4 mt-0 text-lg font-bold text-neutral-800'>Spice level</h2>
    <div className='h-16 rounded-2xl border border-solid border-neutral-100 pr-4'>
      <CustomizationOption
        icon={<SpiceTag spiceLevel={spiceLevel} />}
        mainText={shefSpiceText.get(spiceLevel) ?? 'Not spicy'}
      />
    </div>
  </div>
);
