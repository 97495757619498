import { createSelector } from '@reduxjs/toolkit';
import { isDefined } from 'common/TypeUtilities';
import { groupBy, sortBy, sumBy } from 'lodash';
import { CartOrder, CartState } from '../types';
import { getShefDeliveryDateCapacityInfo } from '../utils/capacity';
import { selectCartDeliveryDatesByShefIds } from './deliveryDate';
import { selectFullLineItems } from './lineItems';
import { selectCartShefsById } from './shefs';

export const selectCartOrders = createSelector(
  selectFullLineItems,
  selectCartShefsById,
  selectCartDeliveryDatesByShefIds,
  (state: CartState) => state.rawOrderData,
  (lineItems, shefs, deliveryDates, rawOrderData): CartOrder[] => {
    const lineItemsByShef = Object.values(groupBy(lineItems, 'shefId'));
    const cartOrdersWithShef = lineItemsByShef
      .map((lineItems) => {
        const { shefId } = lineItems[0];
        const shef = shefs[shefId];
        const deliveryDate = deliveryDates[shefId];
        if (!shef || shef?.loading || !deliveryDate) return null;

        const capacityInfo = getShefDeliveryDateCapacityInfo(
          shef,
          sumBy(lineItems, 'quantity'),
          deliveryDate,
          rawOrderData
        );
        return { shef, lineItems, deliveryDate, capacityInfo };
      })
      .filter(isDefined);

    return sortBy(cartOrdersWithShef, 'shef.name');
  }
);

export const selectAvailableCartOrders = createSelector(selectCartOrders, (cartOrders) =>
  cartOrders.filter((cart) => cart.capacityInfo.numDishesAvailable > 0)
);

export const selectAvailableCartOrderShefIds = createSelector(selectAvailableCartOrders, (availableOrders) =>
  availableOrders.map((order) => order.shef.id)
);

export const selectUnavailableCartOrders = createSelector(selectCartOrders, (cartOrders) =>
  cartOrders.filter((cart) => cart.capacityInfo.numDishesAvailable === 0)
);

const getShefIds = (_: CartState, shefIds: string[]) => shefIds;

function pickShefs(cartOrders: CartOrder[], shefIds: string[]): CartOrder[] {
  const shefIdSet = new Set(shefIds);
  return cartOrders.filter((cartOrder) => shefIdSet.has(cartOrder.shef.id));
}

export const selectCartOrdersByShefIds = createSelector(selectCartOrders, getShefIds, pickShefs);

export const selectAvailableCartOrdersByShefIds = createSelector(selectAvailableCartOrders, getShefIds, pickShefs);

export const selectUnavailableCartOrdersByShefIds = createSelector(selectUnavailableCartOrders, getShefIds, pickShefs);
