import {
  CartItemsSaveDocument,
  CartItemsSaveMutation,
  CartItemsSaveMutationVariables,
  CartsClearDocument,
  CartsClearMutation,
  CartsClearMutationVariables,
} from 'src/gqlReactTypings.generated.d';
import { GqlClient } from 'src/shared/GqlClient';
import { CartLineItemInput } from '../types';

interface ClearCartMutationParams {
  gqlClient: GqlClient;
  shefIds: string[];
}

export function clearCartMutation({ gqlClient, shefIds }: ClearCartMutationParams) {
  return gqlClient.mutate<CartsClearMutation, CartsClearMutationVariables>({
    mutation: CartsClearDocument,
    variables: { shefIds },
  });
}

interface CartItemsSaveMutationParams {
  gqlClient: GqlClient;
  cartLineItemInputs: CartLineItemInput[];
  deliveryDate: string;
}

export function cartSaveItemsMutation({ gqlClient, cartLineItemInputs, deliveryDate }: CartItemsSaveMutationParams) {
  return gqlClient.mutate<CartItemsSaveMutation, CartItemsSaveMutationVariables>({
    mutation: CartItemsSaveDocument,
    variables: { cartLineItemInputs, deliveryDate },
  });
}
