import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './cynthia-song.jpg';

export const CynthiaSong: PersonInfo = {
  firstName: 'Cynthia',
  lastName: 'Song',
  image,
  pronoun: Pronoun.SHE,
  role: ROLE.PRODUCT_MANAGER,
};
