import stringHash from 'string-hash';
import { IExperimentBucketer, IGetHashArg } from './IExperimentBucketer';

/**
 * IExperimentBucketer which should produce the same result when run in browser
 * or on the server (given the same inputs)
 */
export class IsomorphicExperimentBucketer implements IExperimentBucketer {
  public getHash({ identifier, experimentName }: IGetHashArg): number {
    return stringHash(`${experimentName}_${identifier}`);
  }
}
