import { VerticallyCenteredText } from 'src/shared/design-system/VerticallyCenteredText';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { Border, Colors, Font, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';
import { ToggleButtonSize } from '../../types';
import { DEFAULT_SELECTED_COLOR, DEFAULT_SELECTED_COLOR_HIGHLIGHT } from './consts';

export const ButtonWrapper = styled.div`
  gap: ${Spacing.HALF};
  transition: padding ${Transition.Time.BASE};
`;

export const ButtonText = styled(VerticallyCenteredText)``;

export const ButtonIconContainer = styled.div``;
export const getButtonSizeStyling = (size: ToggleButtonSize) =>
  match(size)
    .with(
      ToggleButtonSize.L,
      () => css`
        & ${ButtonWrapper} {
          padding: ${Spacing.SINGLE} ${Spacing.SINGLE_HALF};
        }
        & ${ButtonText} {
          ${Font.bySize(Font.Size.S, Font.Weight.SEMI_BOLD)}
        }
        & ${ButtonIconContainer} > svg {
          ${Mixins.Shape.square(Spacing.SINGLE_HALF)}
        }
      `
    )
    .otherwise(
      () => css`
        & ${ButtonWrapper} {
          padding: ${Spacing.HALF} ${Spacing.THREE_QUARTERS};
        }
        & ${ButtonText} {
          ${Font.bySize(Font.Size.S, Font.Weight.NORMAL)}
        }
        & ${ButtonIconContainer} > svg {
          ${Mixins.Shape.square(Spacing.SINGLE)}
        }
      `
    );

export const ButtonContainer = styled.button<{
  disabled?: boolean;
  inlineIcon?: boolean;
  isSelected: boolean;
  selectedColor?: Colors;
  selectedColorHighlight?: Colors;
  size?: ToggleButtonSize;
}>`
  border: ${Border.Base};
  border-radius: ${Border.Radius.M};
  transition: border-color ${Transition.Time.BASE}, background-color ${Transition.Time.BASE};

  ${({
    disabled = false,
    inlineIcon = false,
    isSelected,
    selectedColor = DEFAULT_SELECTED_COLOR,
    selectedColorHighlight = DEFAULT_SELECTED_COLOR_HIGHLIGHT,
    size = ToggleButtonSize.L,
  }) => css`
    border-color: ${isSelected ? selectedColor : Colors.GRAY_LITE_10};
    background-color: ${isSelected ? selectedColorHighlight : Colors.WHITE};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    padding: 0;
    overflow: hidden;
    ${getButtonSizeStyling(size)}

    & > ${ButtonWrapper} {
      ${Mixins.Flex.center(inlineIcon ? 'row' : 'column')}
    }
  `}
`;
