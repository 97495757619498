import { formatGqlErrors } from 'common/GqlUtilities';
import { map } from 'lodash';
import { selectLineItems, selectMultiCartDeliveryDateTime } from 'src/store/cart/selectors';
import { cartSaveItemsMutation } from 'src/store/cart/utils/cartMutations';
import { fetchCart } from 'src/store/cart/utils/cartQueries';
import { CreateCartEffect } from './listeners';

export const persistCartToServer: CreateCartEffect =
  ({ cartSlice, gqlClient }) =>
  async (_action, listenerApi) => {
    const { setServerCart } = cartSlice.actions;
    const { cart } = listenerApi.getState();
    const lineItems = selectLineItems(cart);
    const cartLineItemInputs = lineItems.map((item) => ({
      foodItemId: item.foodItemId,
      quantity: item.quantity,
    }));
    const deliveryDate = selectMultiCartDeliveryDateTime(cart).toISO();

    if (cartLineItemInputs.length > 0) {
      try {
        const { errors, data } = await cartSaveItemsMutation({ gqlClient, cartLineItemInputs, deliveryDate });
        // FUTURE TODO: how to handle errors - likely needs to retry request
        if (errors?.length) return console.error(formatGqlErrors(errors));
        if (data) listenerApi.dispatch(setServerCart(data.cartSetLineItems));
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const { data, errors } = await fetchCart({ gqlClient, zipCode: cart.zipCode });
        // FUTURE TODO: how to handle errors - likely needs to retry request
        if (errors?.length) return console.error(formatGqlErrors(errors));
        if (data) {
          listenerApi.dispatch(
            setServerCart({
              ...data.carts,
              possibleDeliveryDates: map(data.exploreFoodItemsFilterOptions?.availableDeliveryDays || [], 'date'),
            })
          );
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
