import { createContext } from 'react';
import { ScreenWidth, ScreenWidthValues } from '../../styles';

export interface IWindowDimension {
  width: number;
  height: number;
}

export const ScreenContext = createContext({
  isSmallScreen: false,
  isMediumScreen: false,
  isLargeScreen: false,
  nearestScreenWidth: ScreenWidth.MEDIUM,
  nearestScreenWidthValue: ScreenWidthValues[ScreenWidth.MEDIUM],
  windowDimensions: {
    width: 0,
    height: 0,
  },
});
