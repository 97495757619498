import styled from 'styled-components';
import { Colors, Font, ScreenWidth } from 'web-common/src/shared/styles';
import { ITextProps } from './ITextProps';

export const Span = styled.span<ITextProps>`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : Font.Family.SANS)};
  font-size: ${(props) => (props.size ? props.size : '24px')};
  color: ${(props) => (props.color ? props.color : props.white ? 'white' : 'black')};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px')};
  line-height: ${(props) => props.lineHeight};

  border-bottom: ${({ weight, bold, underline }) => {
    if (!underline) {
      return 'none';
    }

    const underlineWidth = weight === Font.Weight.SEMI_BOLD || bold ? '2px' : '1px';
    return `${underlineWidth} solid ${Colors.RADISH}`;
  }};

  font-weight: ${({ weight, bold }) => {
    if (weight) {
      return weight;
    }
    if (bold) {
      return Font.Weight.SEMI_BOLD;
    }
    return Font.Weight.LIGHT;
  }};

  @media (max-width: ${ScreenWidth.SMALL}) {
    font-size: ${(props) => (props.mobileSize ? props.mobileSize : props.size)};
    margin: ${(props) => props.marginMobile || props.margin || '0 0 10px'};
  }
`;
