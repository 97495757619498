/* Extracted from https://github.com/mixpanel/mixpanel-js/blob/master/src/utils.js */

export const os = (userAgent: string) => {
  if (/Windows/i.test(userAgent)) {
    if (/Phone/.test(userAgent) || /WPDesktop/.test(userAgent)) {
      return 'Windows Phone';
    }
    return 'Windows';
  }
  if (/(iPhone|iPad|iPod)/.test(userAgent)) {
    return 'iOS';
  }
  if (/Android/.test(userAgent)) {
    return 'Android';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  }
  if (/Mac/i.test(userAgent)) {
    return 'Mac OS X';
  }
  if (/Linux/.test(userAgent)) {
    return 'Linux';
  }
  if (/CrOS/.test(userAgent)) {
    return 'Chrome OS';
  }
  return '';
};

export const device = (userAgent: string) => {
  if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
    return 'Windows Phone';
  }
  if (/iPad/.test(userAgent)) {
    return 'iPad';
  }
  if (/iPod/.test(userAgent)) {
    return 'iPod Touch';
  }
  if (/iPhone/.test(userAgent)) {
    return 'iPhone';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  }
  if (/Android/.test(userAgent)) {
    return 'Android';
  }
  return '';
};

export const searchEngine = (referrer: string) => {
  if (referrer.search('https?://(.*)google.([^/?]*)') === 0) {
    return 'google';
  }
  if (referrer.search('https?://(.*)bing.com') === 0) {
    return 'bing';
  }
  if (referrer.search('https?://(.*)yahoo.com') === 0) {
    return 'yahoo';
  }
  if (referrer.search('https?://(.*)duckduckgo.com') === 0) {
    return 'duckduckgo';
  }
  return null;
};

export const browser = (userAgent: string, vendor = '', opera = '') => {
  if (opera || userAgent.includes(' OPR/')) {
    if (userAgent.includes('Mini')) {
      return 'Opera Mini';
    }
    return 'Opera';
  }
  if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return 'BlackBerry';
  }
  if (userAgent.includes('IEMobile') || userAgent.includes('WPDesktop')) {
    return 'Internet Explorer Mobile';
  }
  if (userAgent.includes('SamsungBrowser/')) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return 'Samsung Internet';
  }
  if (userAgent.includes('Edge') || userAgent.includes('Edg/')) {
    return 'Microsoft Edge';
  }
  if (isFacebookBrowser(userAgent)) {
    return 'Facebook Mobile';
  }
  if (userAgent.includes('Chrome')) {
    return 'Chrome';
  }
  if (userAgent.includes('CriOS')) {
    return 'Chrome iOS';
  }
  if (userAgent.includes('UCWEB') || userAgent.includes('UCBrowser')) {
    return 'UC Browser';
  }
  if (userAgent.includes('FxiOS')) {
    return 'Firefox iOS';
  }
  if (vendor.includes('Apple')) {
    if (userAgent.includes('Mobile')) {
      return 'Mobile Safari';
    }
    return 'Safari';
  }
  if (userAgent.includes('Android')) {
    return 'Android Mobile';
  }
  if (userAgent.includes('Konqueror')) {
    return 'Konqueror';
  }
  if (userAgent.includes('Firefox')) {
    return 'Firefox';
  }
  if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'Internet Explorer';
  }
  if (userAgent.includes('Gecko')) {
    return 'Mozilla';
  }
  return '';
};

/**
 * This function detects which browser version is running this script,
 * parsing major and minor version (e.g., 42.1). User agent strings from:
 * http://www.useragentstring.com/pages/useragentstring.php
 */
export const browserVersion = (userAgent: string, vendor = '', opera = '') => {
  const _browser = browser(userAgent, vendor, opera);
  const versionRegexs: any = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge?\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Samsung Internet': /SamsungBrowser\/(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  };
  const regex = versionRegexs[_browser] ? versionRegexs[_browser] : undefined;
  if (regex === undefined) {
    return null;
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
};

/**
 * Gets the browser's language from the navigator, this value can be passed to
 * `navigatorLanguageToIANALanguageTag` in `shef-api/src/utilities/Language.ts` to get the IANA
 * language tag for the user's browser
 */
export const deviceLanguage = (navigator: Navigator): string => {
  return navigator.languages[0] || navigator.language || ((navigator as any).userLanguage as string | undefined) || '';
};

/**
 * Embedded browser check based on:
 * https://stackoverflow.com/questions/31569518/how-to-detect-facebook-in-app-browser
 * https://user-agents.net/applications/facebook-messenger
 */

export const isFacebookBrowser = (userAgent: string): boolean => {
  return userAgent.includes('FBAN') || userAgent.includes('FBAV');
};

export const isFacebookMessenger = (userAgent: string): boolean => {
  return isFacebookBrowser(userAgent) && userAgent.includes('Messenger');
};

export const isInstagramBrowser = (userAgent: string): boolean => {
  return userAgent.includes('Instagram');
};

export const isAndroidDevice = (userAgent: string): boolean => {
  return os(userAgent) === 'Android';
};
