import { ChevronDown } from 'src/shared/design-system/Icon';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { Colors, Font, ScreenWidth, Transition } from 'web-common/src/shared/styles';
import { DropdownSize } from '../consts';
import { THEME } from '../theme';

export const Wrapper = styled.div`
  flex: 1 1 100%;
  white-space: break-word;
`;

export const InputOverlay = styled.div<{ isOpen?: boolean }>`
  ${ifProp(
    'isOpen',
    css`
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: all;
    `,
    css`
      display: none;
      pointer-eventers: none;
    `
  )}
`;

export const DropdownInput = styled.input<{ disabled?: boolean; dropdownSize: DropdownSize }>`
  box-sizing: border-box;
  border: 1px solid ${Colors.GRAY};
  border-radius: 0;
  color: ${Colors.GRAY_DARK_30};
  flex: 1;
  font-family: ${Font.Family.SANS} !important;
  font-size: ${({ dropdownSize }) => THEME[dropdownSize].FontSize} !important;
  font-weight: 400;
  line-height: ${({ dropdownSize }) => THEME[dropdownSize].FontSize};
  outline: none;
  margin: 0;
  padding: ${({ dropdownSize }) =>
    `calc(${THEME[dropdownSize].Padding} + 1px) ${THEME[dropdownSize].Padding} calc(${THEME[dropdownSize].Padding} - 1px) ${THEME[dropdownSize].Padding}`};
  max-width: auto;
  transition: border ${Transition.Time.BASE}, background ${Transition.Time.BASE};

  ${ifProp(
    'disabled',
    css`
      background-color: ${Colors.GRAY_LITE_30} !important;
      cursor: not-allowed !important;
      color: ${Colors.GRAY_DARK_10};

      &:hover:not([aria-expanded='true']) {
        border-color: ${Colors.GRAY} !important;
        background-color: ${Colors.GRAY_LITE_30};
      }
    `,
    css`
      &[readonly] {
        cursor: pointer;
      }

      &:hover:not([aria-expanded='true']) {
        border-color: ${Colors.GRAY_DARK_10};
        background-color: ${Colors.GRAY_LITE_30};
      }
    `
  )};
`;

export const IconContainer = styled.div<{ isOpen?: boolean; selected?: boolean; size: DropdownSize }>`
  align-items: center;
  color: ${ifProp('selected', Colors.RADISH, Colors.GRAY_DARK_10)};
  display: flex;
  padding: ${({ size }) => THEME[size].Padding};
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: ${ifProp('isOpen', 'rotate(-180deg)', 'rotate(0deg)')};
  transition: transform ${Transition.Time.BASE};

  & svg {
    box-sizing: content-box !important;
    height: ${({ size }) => THEME[size].IconSize};
    width: ${({ size }) => THEME[size].IconSize};
  }
`;

export const Icon = styled(ChevronDown)``;

export const MobileSelect = styled.select`
  display: none;
  position: absolute;
  opacity: 0;
  pointer-events: none;

  @media (max-width: ${ScreenWidth.SMALL}) {
    display: block;
    pointer-events: all;
    height: 100%;
    width: 100%;
  }
`;
