export enum ROLE {
  DATA_SCIENTIST = 'Data',
  ENGINEERING_MANAGER = 'Engineering',
  HEAD_OF_ENGINEERING = 'Engineering',
  HEAD_OF_PRODUCT = 'Product',
  PRODUCT_DESIGNER = 'Designer',
  PRODUCT_MANAGER = 'Product',
  SOFTWARE_ENGINEER = 'Software Engineer',
}

export enum Pronoun {
  HE = 'He/Him',
  SHE = 'She/Her',
  THEY = 'They/Them',
}

export type PersonInfo = {
  firstName: string;
  lastName: string;
  image: string;
  pronoun: Pronoun;
  role: ROLE;
  otherRoles?: ROLE[];
};
