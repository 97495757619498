import React, { useCallback } from 'react';
import { NewUserEducationModal } from 'src/new-user-education-modal/NewUserEducationModal';
// Please keep HelpButtonComponent import explicit. It avoids a potential circular dependency
import { HelpButtonComponent } from 'src/shared/design-system/HelpButton/HelpButtonComponent';
// Please keep useHelpButtonState import explicit. It avoids a potential circular dependency
import { useHelpButtonState } from 'src/shared/design-system/HelpButton/helpButtonState';
import { useLocation } from 'src/shared/hooks/useLocation';
import { useModal } from 'src/shared/hooks/useModal';
import { Routes } from 'src/shared/Routes';
import { locationInRoutes } from 'src/shared/utils/RouteUtilities';

export const NewUserEducationModalHelpButton: React.FC = () => {
  const { isShowing, show, hide } = useModal();
  const { setHasSeenNewUserEducationModal } = useHelpButtonState();
  const location = useLocation();

  const isBlocklistedPage = locationInRoutes(location, [
    Routes.CONSUMER_PERSONAL_SHEF,
    Routes.CONSUMER_PERSONAL_SHEF_FORM,
    Routes.CONSUMER_MEAL_PLANS,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
    Routes.CONSUMER_MEAL_PLANS_PREFERENCES,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
    Routes.CONSUMER_MEAL_PLANS_EDIT,
  ]);

  const onHide = useCallback(() => {
    setHasSeenNewUserEducationModal(true);
    hide();
  }, [setHasSeenNewUserEducationModal, hide]);

  if (isBlocklistedPage) return null;

  return (
    <>
      <HelpButtonComponent onClick={show} label='How it Works' />
      <NewUserEducationModal isShowing={isShowing} onHide={onHide} />
    </>
  );
};
