import { Heart } from 'src/shared/design-system/Icon';
import { ifNotProp } from 'src/shared/styled-utils/ifNotProp';
import styled, { css, keyframes } from 'styled-components';
import { match } from 'ts-pattern';
import { Colors } from 'web-common/src/shared/styles';
import { FollowColors, FollowToggleIconProps } from './types';

const followingIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(3);
  }
`;

export const FollowingIcon = styled(Heart)``;

export const FollowingIconShadow = styled(Heart)`
  position: absolute;
  top: 0;
  left: 0;
  color: ${Colors.RADISH};
  opacity: 0;
`;

export const FollowIcon = styled(Heart).attrs(() => ({
  outline: true,
}))``;

export const Container = styled.div<FollowToggleIconProps>`
  position: relative;

  & ${FollowingIcon} {
    ${({ followingColor }) =>
      match(followingColor)
        .with(
          FollowColors.RED,
          () =>
            css`
              color: ${Colors.RADISH};
            `
        )
        .with(
          FollowColors.WHITE,
          () =>
            css`
              color: ${Colors.WHITE};
            `
        )
        .otherwise(
          () => css`
            color: ${Colors.BLACK};
          `
        )}
  }
  & ${FollowingIconShadow} {
    animation: 0.6s ${followingIn};
    ${ifNotProp(
      'isAnimated',
      css`
        filter: opacity(0);
      `
    )}
  }
`;
