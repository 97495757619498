export enum Slugs {
  ID = 'id',
  SHEF_ID = 'shef_id',
  ORDER = 'order',
  INVOICE_ID = 'invoice',
  REDIRECT = 'redirect_path', // TODO: Move this into params
  QUERY = 'query', // TODO: Move this into params / make more specific
  ZIP_CODE = 'zip_code',
  CODE = 'code',
  DATE = 'date', // TODO: Move this into params
  NAME = 'name',
  TOKEN = 'token',
  CUISINE = 'cuisine',
  REGION_CODE = 'region_code',
  STEP_NUMBER = 'step_number',
  MERCHANDISE_PAGE_SLUG = 'merchandise_page_slug',
  TEMPLATE_ID = 'template_id',
}
