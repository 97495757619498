import styled from 'styled-components';

export const Truncate = styled.div<{ lines: number }>`
  > * {
    display: -webkit-box;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.lines};
    -webkit-box-orient: vertical;
  }
`;
