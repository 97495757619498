import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './omar-abdelkader.jpg';

export const OmarAbdelkader: PersonInfo = {
  firstName: 'Omar',
  lastName: 'Abdelkader',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.SOFTWARE_ENGINEER,
};
