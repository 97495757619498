import { isLeft } from 'fp-ts/Either';
import { mapValues } from 'lodash';
import { IStorage } from 'src/shared/storage/Storage';
import type { RootState } from 'src/store';
import { migrate } from 'src/store/storage/migrate';
import { ILoadPersistedState, IStoredState, IStoredStateOptions, PersistedState } from 'src/store/storage/types';

export const loadState =
  (storedState: IStoredState): ILoadPersistedState =>
  ({ storage }): Partial<RootState> =>
    // have to ignore because of two problems:
    // 1. RootState type being created as a product of the return type of createStore, make this function and createStore dependencies of each
    //    other resulting in circular type reference.
    // 2. combineReducers call in src/store fixes the problem defined above, but that type has a [$CombineReducers] unique symbol that is not
    //    exported from the redux page. this means we can never make sure this return type has that value set. this is okay though, since
    //    we do still have garuntees from the IStoredState type for storedState above and can count on only valid state keys being in the object
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mapValues(storedState, (options) => {
      if (options) return loadKey(storage, options);
    });

function loadKey<S>(storage: IStorage, options: IStoredStateOptions): S | undefined {
  const { key } = options;

  const stored = storage.getItem(key);
  if (!stored) return undefined;

  const decoded = PersistedState.decode(stored);

  if (isLeft(decoded)) {
    // clear out invalid data if there's something wrong with the stored value
    console.error(`Failed to parse persisted storage`, { key, errors: decoded.left });
    storage.removeItem(key);
    return undefined;
  }

  const persisted = decoded.right;

  const expired = persisted.expiresAt ? Date.now() > persisted.expiresAt : false;
  if (expired) {
    storage.removeItem(key);
    return undefined;
  }

  const currentVersion = options.versions.find((versionData) => versionData.version === persisted.version);
  // shouldn't be possible
  if (!currentVersion) {
    console.error(`Invalid persisted state version found`, { key, persistedVersion: persisted.version });
    storage.removeItem(key);
    return undefined;
  }

  const decodedState = currentVersion.codec.decode(persisted.state);
  if (isLeft(decodedState)) {
    // clear out invalid data if there's something wrong with the stored value
    console.error(`Failed to parse persisted state`, { key, errors: decodedState.left });
    storage.removeItem(key);
    return undefined;
  }

  // options.versions requires at least one version in the type, so we must have a value here
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isOldVersion = persisted.version < options.currentVersion;
  if (isOldVersion) {
    return migrate(storage, decodedState.right, persisted.version, options);
  }

  return decodedState.right;
}
