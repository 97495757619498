import cn from 'classnames';
import { formatCurrency } from 'common/FormatUtilities';
import { CustomizationOption } from './CustomizationOption';
import { ServingSizeIcon } from './ServingSizeIcon';

interface IServingSizePillProps {
  portion?: string | null;
  servingSize?: string | null;
  price: number;
  disabled?: boolean;
}

export const ServingSizePill: React.FC<IServingSizePillProps> = ({ portion, servingSize, price, disabled }) => (
  <div className={cn('flex flex-col py-6', { 'opacity-50': disabled })}>
    <h2 className='mb-4 mt-0 text-lg font-bold text-neutral-800'>Portion size</h2>
    <div className='h-16 rounded-2xl border border-solid border-neutral-100 pr-4'>
      <CustomizationOption
        icon={<ServingSizeIcon servingSize={servingSize ?? undefined} />}
        mainText={formatCurrency(price)}
        subText={portion ?? undefined}
      />
    </div>
  </div>
);
