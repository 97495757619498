import { Colors } from 'web-common/src/shared/styles';

export const DEFAULT_COLORS = [
  Colors.RADISH,
  Colors.PUMPKIN,
  Colors.JACKFRUIT,
  Colors.KOHLRABI,
  Colors.AQUA,
  Colors.PLUM,
];
export const DEFAULT_COLORS_1 = ['#343799', '#4584FF', '#FF4369', '#F26849', '#FFBF61', '#07BB87'];

export const DEFAULT_ANGLE = 90;
export const DEFAULT_DECAY = 0.9;
export const DEFAULT_SPREAD = 45;
export const DEFAULT_START_VELOCITY = 50;
export const DEFAULT_NUMBER_OF_PARTICLES = 50;
