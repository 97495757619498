import { createSlice, PayloadAction, Update } from '@reduxjs/toolkit';
import { isDefined } from 'common/TypeUtilities';
import { isoDateToUrlDate } from 'common/UrlDate';
import { compact, isEmpty, isNil, keyBy, map, omit, uniq } from 'lodash';
import {
  addOnItemAdapter,
  availableAddOnItemsAdapter,
  cartShefAdapter,
  foodItemAdapter,
  formatCartFoodItem,
  isLoadedShef,
  lineItemAdapter,
} from './entities';
import {
  clearCartByIds,
  clearUnusedShefs,
  formatCartFoodItems,
  keepDeliveryDateFresh,
  performClearCarts,
  removeExcessiveLineItemQuantity,
  removeLineItems,
  updateAddOnItemQuantity,
  updateDeliveryDate,
  updateLineItemCustomizations,
  updateLineItemQuantity,
} from './reducers';
import { selectLineItem } from './selectors';
import * as CartTypes from './types';
import { getDefaultCartDate } from './utils/date';

export const initialState: CartTypes.CartState = {
  isMultiCartTreatment: false, // FUTURE TODO: remove as it's not used
  isMultiCartFeatureEnabled: false, // FUTURE TODO: remove as it's not used
  skipTomorrowDefaultDate: false,
  isMultiCart: false,
  userLoggedIn: false, // FUTURE TODO: move to redux user state later
  ready: false,
  recentlyAdded: false,
  activeCartShefId: '',
  deliveryDate: getDefaultCartDate(),
  zipCode: '',
  possibleDeliveryDates: [],
  foodItems: foodItemAdapter.getInitialState(),
  lineItems: lineItemAdapter.getInitialState(),
  addOnItems: addOnItemAdapter.getInitialState(),
  availableAddOnItems: availableAddOnItemsAdapter.getInitialState(),
  shefs: cartShefAdapter.getInitialState(),
  tippingStyle: CartTypes.TippingStyle.UNINITIALIZED,
  lastLineItemAction: undefined,
  shefTip: 0,
  shefTipPercent: null,
  requireUserAcknowledgeDeliveryDateUpdate: false,
  queuedActions: [],
  // below fields only used by editableOrderCart
  orderId: 0,
  // FUTURE TODO: remove the need for this value eventually by moving editableOrderCart state to its
  // own reducer, currently this eslint needs to be ignored because immer gets mad upset at
  // complex types like objects get replaced with primitives
  rawOrderData: {},
  bulkDiscountTiers: [],
  discountApplied: 0,
  promoApplied: 0,
  promoCode: undefined,
  regionId: undefined,
};

const createCartSlice = (name: string, initialState: CartTypes.CartState) =>
  createSlice({
    name,
    initialState,
    reducers: {
      init(state: CartTypes.CartState) {
        state.lastLineItemAction = undefined;
      },
      forceRefetch(state: CartTypes.CartState) {
        state.lastLineItemAction = undefined;
      },
      setCartReady(state: CartTypes.CartState) {
        state.ready = true;
      },
      setIsMultiCart(state: CartTypes.CartState, action: PayloadAction<boolean>) {
        state.isMultiCart = action.payload;
      },
      setServerCart(state: CartTypes.CartState, action: CartTypes.SetServerCartAction) {
        const cart = action.payload;

        // replace all the existing data with incoming data if the local cart is empty
        // Don't replace if it's not, or else it's confusing for checkout
        if (isEmpty(state.lineItems.ids) && isEmpty(state.foodItems.ids)) {
          const lineItems = cart.foodItems.map((item) => ({ ...omit(item, 'foodItem'), id: item.foodItemId }));
          lineItemAdapter.setAll(state.lineItems, lineItems);

          const rawFoodItems = map(cart.foodItems, 'foodItem');
          const foodItems = map(rawFoodItems, formatCartFoodItem);
          foodItemAdapter.setAll(state.foodItems, foodItems);
        }

        if (isEmpty(state.bulkDiscountTiers) && cart.bulkDiscountTiers) {
          state.bulkDiscountTiers = cart.bulkDiscountTiers;
        }
      },
      setLoggedOutCart(state: CartTypes.CartState, action: CartTypes.SetServerCartAction) {
        const cart = action.payload;
        if (isEmpty(state.bulkDiscountTiers) && cart.bulkDiscountTiers) {
          state.bulkDiscountTiers = cart.bulkDiscountTiers;
        }
      },
      setDeliveryDate(state: CartTypes.CartState, action: CartTypes.SetDeliveryDateAction) {
        updateDeliveryDate(state, action.payload);
      },
      setPossibleDeliveryDates(state: CartTypes.CartState, action: CartTypes.SetPossibleDeliveryDatesAction) {
        state.possibleDeliveryDates = action.payload.possibleDeliveryDates.sort();
        keepDeliveryDateFresh(state);
      },
      setSkipTomorrowDefaultDate(state: CartTypes.CartState, action: CartTypes.SetSkipTomorrowDefaultDate) {
        state.skipTomorrowDefaultDate = action.payload.skipTomorrowDefaultDate;
      },
      setAvailableAddOns(state: CartTypes.CartState, action: CartTypes.SetAvailableAddOnsAction) {
        const { availableAddOnItems } = action.payload;
        availableAddOnItemsAdapter.setAll(state.availableAddOnItems, availableAddOnItems);
      },
      // line items + add on items modification methods
      //
      // all quantity modifications calculate the diff rather than explicitly
      // setting the value. this allows all operations to be commutative
      // (a + b) = (b + a) giving us the benefit of not worry about the order
      // of operations when undoing failed operations sent to the server
      incLineItemQuantity: (state: CartTypes.CartState, action: CartTypes.IncLineItemQuantityAction) => {
        const { quantity, foodItem, customizationIds } = action.payload;
        const cartQuantity = selectLineItem(state, foodItem.id)?.quantity ?? 0;
        const amount = quantity ? quantity - cartQuantity : 1;
        state.lastLineItemAction = action;
        updateDeliveryDate(state, action.payload.deliveryDate);
        updateLineItemQuantity(state, action, amount);
        updateLineItemCustomizations(state, foodItem, customizationIds);
      },
      decLineItemQuantity: (state: CartTypes.CartState, action: CartTypes.IncLineItemQuantityAction) => {
        const { quantity, foodItem, customizationIds } = action.payload;
        const amount = -1 * (quantity ?? 1);
        state.lastLineItemAction = action;
        updateDeliveryDate(state, action.payload.deliveryDate);
        updateLineItemQuantity(state, action, amount);
        updateLineItemCustomizations(state, foodItem, customizationIds);
      },
      setLineItemQuantity: (state: CartTypes.CartState, action: CartTypes.SetLineItemQuantityAction) => {
        const { foodItem, quantity, customizationIds = [] } = action.payload;
        const currLineItemQuantity = state.lineItems.entities[foodItem.id]?.quantity ?? 0; // 1
        const amount = quantity - currLineItemQuantity;
        state.lastLineItemAction = action;

        updateDeliveryDate(state, action.payload.deliveryDate);
        updateLineItemQuantity(state, action, amount);
        updateLineItemCustomizations(state, foodItem, customizationIds);
      },
      removeLineItem: (state: CartTypes.CartState, action: CartTypes.IncLineItemQuantityAction) => {
        const { foodItem } = action.payload;
        const lineItem = state.lineItems.entities[foodItem.id];
        if (lineItem) {
          removeLineItems(state, [lineItem]);
        } else {
          console.error('No line item record to remove');
        }
      },
      updateFoodItems(state: CartTypes.CartState, action: PayloadAction<{ foodItems: CartTypes.CartFoodItem[] }>) {
        const foodItems = formatCartFoodItems(action.payload.foodItems);
        foodItemAdapter.setMany(state.foodItems, foodItems);
      },
      updateFoodItemAvailabilitiesForDate: (
        state: CartTypes.CartState,
        action: CartTypes.UpdateFoodItemAvailabilitiesForDateAction
      ) => {
        const foodItemIds = map(action.payload.availabilityUpdates, 'id');
        const newAvailabilities = keyBy(action.payload.availabilityUpdates, 'id');
        const { availabilityDate } = action.payload;

        const foodItemUpdates = compact(
          map(foodItemIds, (foodItemId): Update<CartTypes.CartFoodItem> | null => {
            const foodItem = state.foodItems.entities[foodItemId];
            if (isNil(foodItem)) {
              return null;
            }

            const updatedAvailability = newAvailabilities[foodItemId];
            if (isNil(updatedAvailability)) {
              return null;
            }

            return {
              id: foodItemId,
              changes: {
                availability: map(foodItem.availability, (availabilityForDate) =>
                  availabilityForDate.availabilityDate === availabilityDate
                    ? {
                        availabilityDate,
                        numAvailable: updatedAvailability.availability,
                      }
                    : availabilityForDate
                ),
              },
            };
          })
        );

        foodItemAdapter.updateMany(state.foodItems, foodItemUpdates);

        removeExcessiveLineItemQuantity(state);
      },
      incAddOnItemQuantity: (state: CartTypes.CartState, action: CartTypes.IncAddOnItemQuantityAction) => {
        updateAddOnItemQuantity(state, action, 1);
      },
      decAddOnItemQuantity: (state: CartTypes.CartState, action: CartTypes.IncAddOnItemQuantityAction) => {
        updateAddOnItemQuantity(state, action, -1);
      },
      setAddOnItemQuantity: (state: CartTypes.CartState, action: CartTypes.SetAddOnItemQuantityAction) => {
        const { addOnItem, quantity } = action.payload;
        const currAddOnItemQuantity = state.addOnItems.entities[addOnItem.id]?.quantity ?? 0;
        const amount = quantity - currAddOnItemQuantity;
        updateAddOnItemQuantity(state, action, amount);
      },
      setActiveCart: (state: CartTypes.CartState, action: CartTypes.SetActiveCartAction) => {
        const { shefId, deliveryDate } = action.payload;
        state.activeCartShefId = shefId;

        const shef = state.shefs.entities[shefId];
        if (!shef) {
          cartShefAdapter.setOne(state.shefs, { id: shefId, loading: true, deliveryDate });
        } else {
          cartShefAdapter.updateOne(state.shefs, { id: shefId, changes: { deliveryDate } });
        }

        removeExcessiveLineItemQuantity(state);
        clearUnusedShefs(state);
      },
      setShefs: (state: CartTypes.CartState, action: CartTypes.SetShefsAction) => {
        cartShefAdapter.setMany(state.shefs, action.payload);
      },
      updateShef: (state: CartTypes.CartState, action: CartTypes.UpdateShefAction) => {
        const { id, ...changes } = action.payload;
        cartShefAdapter.updateOne(state.shefs, { id, changes });
      },
      updateShefAvailabilitiesForDate: (
        state: CartTypes.CartState,
        action: CartTypes.UpdateShefAvailabilitiesForDateAction
      ) => {
        const { availabilityDate } = action.payload;
        const shefIds = map(action.payload.availabilityUpdates, 'id');
        const keyedAvailabilities = keyBy(action.payload.availabilityUpdates, 'id');

        const updates = compact(
          map(shefIds, (shefId) => {
            const shef = state.shefs.entities[shefId];
            const availabilityUpdate = keyedAvailabilities[shefId];
            if (isNil(shef) || !isLoadedShef(shef) || isNil(availabilityUpdate)) {
              return null;
            }

            const updatedAvailability = map(shef.availability, (availabilityForDate) =>
              availabilityForDate.availabilityDate === availabilityDate
                ? {
                    availabilityDate,
                    numDishesAvailable: availabilityUpdate.availability,
                    isSoldOut: availabilityUpdate.availability === 0,
                  }
                : availabilityForDate
            );
            return {
              id: shefId,
              changes: {
                availability: updatedAvailability,
              },
            };
          })
        );

        cartShefAdapter.updateMany(state.shefs, updates);
      },
      setShefTip: (state: CartTypes.CartState, action: PayloadAction<number>) => {
        state.shefTip = action.payload;
      },
      setShefTipPercent: (state: CartTypes.CartState, action: PayloadAction<number | null>) => {
        state.shefTipPercent = action.payload;
        state.tippingStyle =
          action.payload === null ? CartTypes.TippingStyle.FIXED_AMOUNT : CartTypes.TippingStyle.PERCENTAGE;
      },
      userLogout: (state: CartTypes.CartState) => {
        state.userLoggedIn = false;

        const shefIds = uniq(map(Object.values(state.lineItems.entities).filter(isDefined), 'shefId'));
        clearCartByIds(state, shefIds);
      },
      userLogin: (state: CartTypes.CartState) => {
        state.userLoggedIn = true;
      },
      userAcknowledgeDeliveryDateChange: (state: CartTypes.CartState) => {
        state.requireUserAcknowledgeDeliveryDateUpdate = false;
      },
      clearCarts: (state: CartTypes.CartState) => {
        performClearCarts(state);
      },
      clearCartsByShefIds: (state: CartTypes.CartState, action: CartTypes.ShefIdsAction) => {
        const { shefIds } = action.payload;
        clearCartByIds(state, shefIds);
        const cartShefIdsToRemove = shefIds.filter((shefId) => shefId !== state.activeCartShefId);
        cartShefAdapter.removeMany(state.shefs, cartShefIdsToRemove);
      },
      // cleared after successful payment for an order
      clearActiveCarts: (state: CartTypes.CartState, action: CartTypes.ShefIdsAction) => {
        const { shefIds } = action.payload;
        clearCartByIds(state, shefIds);
        addOnItemAdapter.removeAll(state.addOnItems);
        cartShefAdapter.removeMany(state.shefs, shefIds);

        // reset the tipping values and set to uninitialized.
        // allows experiment code to rerun and set to desired tipping style/amount
        state.tippingStyle = CartTypes.TippingStyle.UNINITIALIZED;
        state.shefTip = 0;
        state.shefTipPercent = null;
      },
      clearQueue: (state: CartTypes.CartState) => {
        state.queuedActions = [];
      },
      // This should be called "Queue of actions that have a different delivery date than our current cart". Used to replay
      // cart actions after delivery date change is acknowledged
      queueAction: (state: CartTypes.CartState, action: CartTypes.QueueAction) => {
        state.queuedActions = [...state.queuedActions, action.payload];
      },
      // only used by editable order data, move to own slice in the future
      setEditableOrder: (state: CartTypes.CartState, action: CartTypes.SetEditableOrderAction) => {
        const { foodItems, lineItems, addOnItems, availableAddOnItems, shefs, ...nonEntityState } = action.payload;

        Object.entries(nonEntityState).forEach(([key, value]) => {
          state[key] = value;
        });

        if (foodItems) {
          foodItemAdapter.setAll(state.foodItems, formatCartFoodItems(foodItems));
        }
        if (lineItems) lineItemAdapter.setAll(state.lineItems, lineItems);
        if (addOnItems) addOnItemAdapter.setAll(state.addOnItems, addOnItems);
        if (availableAddOnItems) availableAddOnItemsAdapter.setAll(state.availableAddOnItems, availableAddOnItems);
        if (shefs) {
          const cartShefs = shefs.map((shef) => ({
            ...shef,
            deliveryDate: isoDateToUrlDate(shef.deliveryDate),
          }));
          cartShefAdapter.setAll(state.shefs, cartShefs);
        }
      },
      resetEditableOrder: () => initialState,
      setDiscountApplied: (state: CartTypes.CartState, action: PayloadAction<{ discountApplied: number }>) => {
        state.discountApplied = action.payload.discountApplied;
      },
      setPromoApplied: (state: CartTypes.CartState, action: PayloadAction<{ promoApplied: number }>) => {
        state.promoApplied = action.payload.promoApplied;
      },
      setPromoCode: (state: CartTypes.CartState, action: PayloadAction<{ promoCode: string | undefined }>) => {
        state.promoCode = action.payload.promoCode;
      },
      setAllDiscountsApplied: (
        state: CartTypes.CartState,
        action: PayloadAction<{ discountApplied: number; promoApplied: number }>
      ) => {
        state.discountApplied = action.payload.discountApplied;
        state.promoApplied = action.payload.promoApplied;
      },
      setZipCode: (state: CartTypes.CartState, action: PayloadAction<{ zipCode: string; regionId: number }>) => {
        state.zipCode = action.payload.zipCode;
        state.regionId = action.payload.regionId;
      },
    },
  });

export const cartSlice = createCartSlice('cart', initialState);
export const editableOrderCartSlice = createCartSlice('editableOrderCart', initialState);

export type CartSlice = ReturnType<typeof createCartSlice>;

export { createCartListeners } from './effects';
