import React, { useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useRefState } from '../../hooks/useRefState';
import { cssPixel } from '../../utils/StyleUnitsUtils';
import { ITextProps } from './ITextProps';
import { Paragraph } from './Paragraph';
import { Span } from './Span';

const Container = styled(Paragraph)`
  position: relative;
  overflow: hidden;
`;

const TruncatedParagraph = styled(Span)<{ lines?: number }>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines};
  -webkit-box-orient: vertical;
  margin: 0;
  padding: 0;
`;

const HiddenParagraph = styled(Span)`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  user-select: none;
  visibility: hidden;
  margin: 0;
  padding: 0;
`;

interface DynamicallyTruncatedParagraphProps extends Omit<ITextProps, 'lineHeight'> {
  children: string;
  lineHeight: number;
  /**
   * the number of lines to render before the correct number of lines to render is calculated
   */
  initialNumberOfLines: number;
  style?: React.CSSProperties;
}

export const DynamicallyTruncatedParagraph = ({
  children,
  lineHeight,
  style,
  initialNumberOfLines,
  ...props
}: DynamicallyTruncatedParagraphProps) => {
  const [ref, setRef] = useRefState<HTMLParagraphElement>();
  const [height, setHeight] = useState<number | undefined>(undefined);

  const calculateLinesToTruncate = useCallback(() => {
    setHeight(ref?.clientHeight);
  }, [ref?.clientHeight]);

  useLayoutEffect(() => {
    calculateLinesToTruncate();
  }, [calculateLinesToTruncate]);

  const maxLines = height ? Math.floor(height / lineHeight) : initialNumberOfLines;

  return (
    <Container style={style}>
      <TruncatedParagraph {...props} lineHeight={cssPixel(lineHeight)} lines={maxLines}>
        {maxLines !== undefined && children}
      </TruncatedParagraph>
      <HiddenParagraph aria-hidden={true} {...props} lineHeight={cssPixel(lineHeight)} ref={setRef}>
        {children}
      </HiddenParagraph>
    </Container>
  );
};
