import { QueryParams } from 'common/urls/QueryParams';
import { Location } from 'history';
import QueryString from 'query-string';

interface GetCheckoutQueryStringParams {
  location: Location;
  urlDate: string;
  shefIds: string[];
  firstName?: string;
  lastName?: string;
  email?: string;
  type?: string;
}

export const getCheckoutQueryString = ({
  location,
  urlDate,
  shefIds,
  firstName,
  lastName,
  email,
  type,
}: GetCheckoutQueryStringParams) => {
  const parsedQs = QueryString.parse(location.search);
  const rank = parsedQs[QueryParams.RANK] as string | undefined;
  const exploreSegmentId = parsedQs[QueryParams.EXPLORE_SEGMENT_ID] as string | undefined;
  const exploreSegmentPos = parsedQs[QueryParams.EXPLORE_SEGMENT_POS] as string | undefined;
  const exploreSegmentRenderedItems = parsedQs[QueryParams.EXPLORE_SEGMENT_RENDERED_ITEMS] as string | undefined;
  const exploreSegmentTotalItems = parsedQs[QueryParams.EXPLORE_SEGMENT_TOTAL_ITEMS] as string | undefined;

  return QueryString.stringify({
    [QueryParams.DATE]: urlDate,
    [QueryParams.SHEF]: shefIds,
    [QueryParams.RANK]: rank,
    [QueryParams.EXPLORE_SEGMENT_ID]: exploreSegmentId,
    [QueryParams.EXPLORE_SEGMENT_POS]: exploreSegmentPos,
    [QueryParams.EXPLORE_SEGMENT_RENDERED_ITEMS]: exploreSegmentRenderedItems,
    [QueryParams.EXPLORE_SEGMENT_TOTAL_ITEMS]: exploreSegmentTotalItems,
    [QueryParams.FIRST_NAME]: firstName,
    [QueryParams.LAST_NAME]: lastName,
    [QueryParams.EMAIL]: email,
    [QueryParams.TYPE]: type,
  });
};
