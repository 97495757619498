import { CloseV2, ShareArrowV2 } from 'src/shared/design-system/Icon';
import { Clickable } from 'src/shared/design-system/Utilities';
import styled from 'styled-components';
import { Border, Colors, Spacing, Transition } from 'web-common/src/shared/styles';

export const TopButton = styled(Clickable)`
  align-items: center;
  background: ${Colors.WHITE};
  border-radius: ${Border.Radius.M};
  color: ${Colors.GRAY_DARK_30};
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: ${Spacing.DOUBLE_HALF};
  width: ${Spacing.DOUBLE_HALF};
  padding: 0;
  box-shadow: 0 0 0 0 ${Colors.BLACK};
  transition: background ${Transition.Time.BASE}, box-shadow ${Transition.Time.BASE}, color ${Transition.Time.BASE};
  z-index: 10;

  & svg {
    height: ${Spacing.SINGLE};
    width: ${Spacing.SINGLE};
  }

  &:hover,
  &:active {
    background: ${Colors.GRAY_LITE_30};
    box-shadow: 0 0 ${Spacing.HALF} 0 black;
    color: ${Colors.GRAY_DARK_30};
  }
`;

export const CloseButton = TopButton;

export const ShareButton = TopButton;

export const CloseIcon = CloseV2;

export const ShareIcon = ShareArrowV2;
