import { currentUrlDate, isoDateToUrlDate } from 'common/UrlDate';
import { useEffect } from 'react';
import { DishFirstFoodItemFragment } from 'src/gqlReactTypings.generated.d';
import { useDispatch, useSelector } from 'src/store';
import { setShefs } from 'src/store/cart/actions';
import { selectLineItem, selectShefAvailableLineItemQuantity } from 'src/store/cart/selectors';
import { LoadedCartShef } from 'src/store/cart/types';
import { UseFoodAvailabilityProps } from '../types';

export const useFoodItemAvailability = ({ deliveryDate, foodItem }: UseFoodAvailabilityProps) => {
  const dispatch = useDispatch();

  // reduce food item's numAvailable value by number in the cart
  const foodItemAvailability = foodItem.availability.find(
    (date) => isoDateToUrlDate(date.availabilityDate) === deliveryDate
  );
  const foodItemCartQuantity = useSelector(({ cart }) => selectLineItem(cart, foodItem.foodItemId)?.quantity ?? 0);
  const maxDishCapacity = foodItemAvailability?.numAvailable ?? Infinity;
  const remainingDishCapacity = Math.max(0, maxDishCapacity - foodItemCartQuantity);

  // reduce total shef capacity by number of items in the cart
  const shefAvailability = foodItem.shef.availability.find((date) => date.availabilityDate === deliveryDate);
  const shefItemCartQuantity = useSelector(({ cart }) => selectShefAvailableLineItemQuantity(cart, foodItem.shef.id));
  const remainingShefCapacity = (shefAvailability?.numDishesAvailable || 0) - shefItemCartQuantity;

  useEffect(() => {
    dispatch(setShefs([dishFirstFoodItemFragmentToCartShef(foodItem)]));
  }, [foodItem]);

  // use the lowest fo the capacity values
  return deliveryDate ? { maxQuantity: Math.min(remainingDishCapacity, remainingShefCapacity) } : { maxQuantity: 0 };
};

export const dishFirstFoodItemFragmentToCartShef = (foodItem: DishFirstFoodItemFragment): LoadedCartShef => ({
  id: foodItem.shef.id,
  loading: false,
  deliveryDate: currentUrlDate(), // doesn't really matter, since this field is only used by shef-first
  name: foodItem.shef.firstName ?? '',
  timeZone: foodItem.shef.shefProfile.timeZone,
  availability: foodItem.shef.availability,
  imageUrl: foodItem.shef.imageUrl ?? '',
  celebrityEventType: foodItem.shef.celebrityEventType,
  shefProfile: {
    cuisineRegion: foodItem.shef.shefProfile.cuisineRegion,
    cutoffMsBeforeDeliveryDate: foodItem.shef.shefProfile.cutoffMsBeforeDeliveryDate,
    dishCapacity: foodItem.shef.shefProfile.dishCapacity,
    isTrialShef: foodItem.shef.shefProfile.isTrialShef,
  },
  syncedAt: new Date().toISOString(),
});
