import { minScreen } from 'src/shared/utils/StyledComponentUtils';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { Colors, ScreenWidth, Transition } from 'web-common/src/shared/styles';
import { Button } from './Button';
import { ButtonColors } from './types';
import { createButtonVariants } from './utils';

type CreatePrimaryColorProps = {
  backgroundActive?: string;
  backgroundDisabled?: string;
  backgroundHover?: string;
  backgroundSelected?: string;
  background?: string;
  disabled?: boolean;
  isSelected?: boolean;
  color?: string;
};

const createPrimaryColorStyle = ({
  backgroundActive,
  backgroundDisabled,
  backgroundHover,
  backgroundSelected,
  background,
  disabled,
  isSelected,
  color = Colors.WHITE,
}: CreatePrimaryColorProps) => css`
  background: ${isSelected ? backgroundSelected : background};
  color: ${color};

  ${match(disabled)
    .with(
      true,
      () => css`
        background: ${backgroundDisabled};
        cursor: not-allowed;
      `
    )
    .otherwise(
      () => css`
        background: ${isSelected ? backgroundSelected : background};
        cursor: pointer;
        &:active {
          background: ${backgroundActive};
        }

        ${minScreen(ScreenWidth.MEDIUM)} {
          &:hover {
            background: ${backgroundHover};
          }
        }
      `
    )}
`;

export const PrimaryButtonComponent = styled(Button)`
  background: ${Colors.RASPBERRY};
  color: white;
  transition: background ${Transition.Time.BASE};
  position: relative;

  ${({ color, disabled, isSelected }) =>
    match(color)
      .with(ButtonColors.GRAY, () =>
        createPrimaryColorStyle({
          backgroundActive: Colors.GRAY_DARK_30,
          backgroundDisabled: Colors.GRAY_LITE_10,
          backgroundHover: Colors.GRAY,
          backgroundSelected: Colors.GRAY_DARK_30,
          background: Colors.GRAY_DARK_10,
          disabled,
          isSelected,
        })
      )
      .with(ButtonColors.LIGHT_GRAY, () =>
        createPrimaryColorStyle({
          backgroundActive: Colors.GRAY_LITE_10,
          backgroundDisabled: Colors.GRAY_LITE_30,
          backgroundHover: Colors.GRAY_LITE_30,
          backgroundSelected: Colors.GRAY_LITE_10,
          background: Colors.GRAY_LITE_20,
          color: Colors.BLACK,
          disabled,
          isSelected,
        })
      )
      .with(ButtonColors.DARK_GRAY, () =>
        createPrimaryColorStyle({
          backgroundActive: Colors.BLACK,
          backgroundDisabled: Colors.GRAY,
          backgroundHover: Colors.GRAY_DARK_10,
          backgroundSelected: Colors.BLACK,
          background: Colors.GRAY_DARK_30,
          disabled,
          isSelected,
        })
      )
      .with(ButtonColors.BLUE, () =>
        createPrimaryColorStyle({
          backgroundActive: Colors.AQUA_DARK_20,
          backgroundDisabled: Colors.AQUA_LITE_20,
          backgroundHover: Colors.AQUA_LITE_10,
          backgroundSelected: Colors.AQUA_DARK_20,
          background: Colors.AQUA,
          disabled,
          isSelected,
        })
      )
      .with(ButtonColors.YELLOW, () =>
        createPrimaryColorStyle({
          backgroundActive: Colors.JACKFRUIT_DARK_10,
          backgroundDisabled: Colors.JACKFRUIT_LITE_10,
          backgroundHover: Colors.JACKFRUIT,
          backgroundSelected: Colors.JACKFRUIT_DARK_10,
          background: Colors.JACKFRUIT,
          color: Colors.GRAY_DARK_30,
          disabled,
          isSelected,
        })
      )
      .with(ButtonColors.WHITE, () =>
        createPrimaryColorStyle({
          backgroundActive: Colors.GRAY_LITE_20,
          backgroundDisabled: Colors.GRAY_LITE_30,
          backgroundHover: Colors.GRAY_LITE_30,
          backgroundSelected: Colors.GRAY_LITE_20,
          background: Colors.WHITE,
          color: Colors.GRAY_DARK_30,
          disabled,
          isSelected,
        })
      )
      .otherwise(() =>
        createPrimaryColorStyle({
          backgroundActive: Colors.BELL_PEPPER_DARK_10,
          backgroundDisabled: Colors.BELL_PEPPER_LITE_20,
          backgroundHover: Colors.BELL_PEPPER_LITE_10,
          backgroundSelected: Colors.BELL_PEPPER_DARK_10,
          background: Colors.RASPBERRY,
          disabled,
          isSelected,
        })
      )}
`;

export const PrimaryButton = createButtonVariants(PrimaryButtonComponent);
