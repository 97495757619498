import { parseNumServings } from 'common/CustomizationUtils';
import { isNil } from 'lodash';
import {
  Serving1Portion,
  Serving2Portions,
  Serving3Portions,
  Serving4Portions,
  Serving5Portions,
  Serving6Portions,
} from 'src/shared/design-system/Icon';

const servingSizeIconRecord: Record<number, React.FC<React.SVGProps<SVGSVGElement>>> = {
  1: Serving1Portion,
  2: Serving2Portions,
  3: Serving3Portions,
  4: Serving4Portions,
  5: Serving5Portions,
  6: Serving6Portions,
};

export const ServingSizeIcon: React.FC<{ servingSize?: string }> = ({ servingSize }) => {
  const parsedNumServings = !isNil(servingSize) ? Math.floor(parseNumServings(servingSize)) : undefined;
  const Icon = !isNil(parsedNumServings) ? servingSizeIconRecord[parsedNumServings] : Serving1Portion;

  return (
    <div className='flex flex-col items-center justify-center'>
      <Icon className='mb-1 size-6 text-neutral-800' />
      <span className='text-xs font-medium text-neutral-700'>{servingSize ?? '1 serving'}</span>
    </div>
  );
};
