export enum BoxShadow {
  MENU = '0 0 8px 1px rgba(0, 0, 0, 0.08)',
  APP_UPSELL_BANNER = '0 2px 4px 0 rgba(0, 0, 0, 0.25)',
  ZIP_CODE_SELECTION = '0 4px 24px rgba(0, 0, 0, 0.1)',
  STICKY_DATE_PICKER = '0 4px 16px rgba(0, 0, 0, 0.25)',
  ACCOUNT_SETTINGS_MODAL = '0 8px 20px rgba(0, 0, 0, 0.15)',
  CHAT_INBOX = '0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.05)',
  CHAT_WIDGET = `0px 16px 80px rgba(0,0,0,0.1), 0px 4px 16px rgba(0,0,0,0.05)`,
  CHIP = '0 2px 5px rgba(0, 0, 0, 0.18)',
  MODAL = '0 0 15px rgba(0, 0, 0, 0.1)',
  HOMEPAGE_HERO_CONTENT = '1px 8px 15px rgba(0, 0, 0, 0.04)',
}
