import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { isProdEnvironment } from '../utils/EnvironmentUtilities';
import { ErrorFallback } from './ErrorFallback';

export const withErrorBoundary =
  <T extends object>(
    WrappedComponent: React.ComponentType<T>
  ): React.FC<React.ComponentProps<typeof WrappedComponent>> =>
  (props: React.ComponentProps<typeof WrappedComponent>) => {
    if (isProdEnvironment()) {
      return (
        <ErrorBoundary fallback={ErrorFallback}>
          <WrappedComponent {...props} />
        </ErrorBoundary>
      );
    }

    return <WrappedComponent {...props} />;
  };
