import { ClientEvents } from 'common/events/ClientEvents';
import React from 'react';
import { LottieBag, LottiePot, LottieWhisk } from 'src/shared/design-system/Animations';
import { useTrackOnMount } from 'src/shared/hooks/UseTrackOnMount';
import styled from 'styled-components';
import { Colors, Font } from 'web-common/src/shared/styles';

const Title = styled.div`
  height: 34px;
  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: ${Colors.GRAY_DARK_30};
  margin-bottom: 20px;
`;

const Description = styled.div`
  font-family: ${Font.Family.SANS};
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;

  text-align: center;

  color: ${Colors.GRAY_DARK_30};

  b {
    font-weight: bold;
    color: ${Colors.RADISH};
  }
`;

const AnimationContainer = styled.div`
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ExploreLocalShefsStep: React.FC = () => {
  useTrackOnMount(ClientEvents.NEW_USER_EDUCATION_STEP, { step: 'explore-local-shefs' });

  return (
    <Container>
      <AnimationContainer>
        <LottieWhisk />
      </AnimationContainer>
      <Title>Explore local shefs</Title>
      <Description>
        <p>Shef connects you with local, independent shefs in your area.</p>
        <p>
          All shefs are <b>certified cooks</b> and have passed <b>food safety training</b>.
        </p>
      </Description>
    </Container>
  );
};

export const ChooseADeliveryDayStep: React.FC = () => {
  useTrackOnMount(ClientEvents.NEW_USER_EDUCATION_STEP, { step: 'choose-a-delivery-day' });

  return (
    <Container>
      <AnimationContainer>
        <LottieBag />
      </AnimationContainer>
      <Title>Choose a delivery day</Title>
      <Description>
        <p>Shefs need time to find fresh ingredients and cook your meal.</p>
        <p>
          To support this, <b>customers typically order a day or two in advance.</b>
        </p>
      </Description>
    </Container>
  );
};

export const ChooseYourDishesStep: React.FC = () => {
  useTrackOnMount(ClientEvents.NEW_USER_EDUCATION_STEP, { step: 'choose-your-dishes' });

  return (
    <Container>
      <AnimationContainer>
        <LottiePot />
      </AnimationContainer>
      <Title>Choose your dishes</Title>
      <Description>
        <p>You select the dishes you’d like to order. Every dish is prepared fresh.</p>
        <p>
          <b>FREE delivery on orders over $40.</b> <br />
          No subscription required.
        </p>
      </Description>
    </Container>
  );
};
