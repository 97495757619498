import { get, isNull } from 'lodash';
import { useEffect } from 'react';
import { scrollWindowToTop } from '../utils/WindowUtils';
import { useHistory } from './useHistory';

/**
 * Hook that listens to history PUSH or REPLACE and scrolls to the top of the page.
 *
 * To bypass this behavior, you can set the `scrollToTop` property in the state to false.
 * @example
 * history.push('/explore', {scrollToTop: false});
 */
export const useScrollToTopOnHistoryChange = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const isNotSearchQueryMutation = isNull(get(location, 'query.query', null));
      if (action === 'PUSH' || (action === 'REPLACE' && isNotSearchQueryMutation)) {
        const scrollToTop = location.state?.scrollToTop ?? true;
        if (scrollToTop) {
          scrollWindowToTop();
        }
      }
    });

    return unlisten;
  }, [history]);
};
