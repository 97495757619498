import React from 'react';
import { UserImg } from './styles';

export interface AvatarImageProps {
  className?: string;
  imageUrl: string;
  size: string;
}

export const AvatarImage: React.FC<AvatarImageProps> = ({ className, imageUrl, size }) => (
  <UserImg className={className} src={imageUrl} size={size} />
);
