export type StandardEnum<T = any> = {
  [id: string]: T | string;
  [id: number]: T;
};

/**
 * For DeliveryType or other enums with deprecated members, please use valueToEnumOrNull and handle the null value,
 * since some of our db values are still using the deprecated enums.
 *
 * @param theEnum
 * @param value
 */
export function valueToEnum<T extends StandardEnum, K extends keyof T>(theEnum: T, value: unknown): T[K] {
  const values = Object.values(theEnum);

  if (values.indexOf(value) > -1) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value as T[K];
  }

  throw new Error(`${value} is not a member of this enum!`);
}

export function valueToEnumOrNull<T extends StandardEnum, K extends keyof T>(theEnum: T, value: unknown): T[K] | null {
  const values = Object.values(theEnum);

  if (values.indexOf(value) > -1) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return value as T[K];
  }

  return null;
}

export function valueToStringEnumOrNull<T extends StandardEnum, K extends keyof T>({
  theEnum,
  value,
  caseSensitive = true,
}: {
  theEnum: T;
  value: string;
  caseSensitive?: boolean;
}): T[K] | null {
  const enumValues = Object.values(theEnum);
  const foundEnumValue = enumValues.find((enumValue) => {
    if (typeof enumValue !== 'string') {
      throw new Error('The enum is not a string enum!');
    }

    const normalizedEnumValue = caseSensitive ? enumValue : enumValue.toLowerCase();
    const normalizedValue = caseSensitive ? value : value.toLowerCase();
    return normalizedEnumValue === normalizedValue;
  });

  if (foundEnumValue) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return foundEnumValue as T[K];
  }

  return null;
}

export function enforceExhaustive(arg: never): Error {
  return new Error(`A case statement which was expected to be exhaustive apparently isn't (missing a case for ${arg})`);
}
