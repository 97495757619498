import styled from 'styled-components';
import { Colors, Font } from 'web-common/src/shared/styles';

export const AvatarInfo = styled.div<{ size: string }>`
  display: flex;
  flex-direction: column;
  height: ${(props) => props.size};
  padding-left: 15px;
`;
export const UserImg = styled.img<{ size: string }>`
  border-radius: 50%;
  height: ${(props) => props.size};
  width: ${(props) => props.size};
`;

export const Username = styled.div`
  font-family: ${Font.Family.SANS};
  font-weight: 700;
  color: ${Colors.GRAY_DARK_30};
`;

export const DateComponent = styled.div`
  font-family: ${Font.Family.SANS};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: ${Colors.GRAY_DARK_20};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
