import { LoginMethod, SignUpMethod } from 'common/events/ServerTypes';
import { OAuthLoginType } from 'src/gqlReactTypings.generated.d';
import { match } from 'ts-pattern';

interface IGetLoginAndSignUpMethodFromOAuthLoginTypeResult {
  signUpMethod: SignUpMethod;
  loginMethod: LoginMethod;
}

// This gets names that we use for metrics/logs.
// Equivalent file in shef-api/src/social-login/getLoginAndSignUpMethodFromOAuthLoginType.ts
// (separate because graphql typings are different for shef-api vs shef-web)
export function getLoginAndSignUpMethodFromOAuthLoginType(
  oAuthLoginType: OAuthLoginType
): IGetLoginAndSignUpMethodFromOAuthLoginTypeResult {
  return match<OAuthLoginType, IGetLoginAndSignUpMethodFromOAuthLoginTypeResult>(oAuthLoginType)
    .with(OAuthLoginType.Apple, () => ({ signUpMethod: 'apple', loginMethod: 'apple-login' }))
    .with(OAuthLoginType.Facebook, () => ({ signUpMethod: 'facebook', loginMethod: 'facebook-login' }))
    .with(OAuthLoginType.GoogleAndroid, OAuthLoginType.GoogleIos, OAuthLoginType.GoogleWeb, () => ({
      signUpMethod: 'google',
      loginMethod: 'google-login',
    }))
    .otherwise(() => {
      // Exhaustive is not matching the type check because of the multiple pattern above, so just throw an error here.
      throw new Error('Unknown SSO type');
    });
}
