import { DateString } from 'common/UrlDate';
import { QueryParams } from 'common/urls/QueryParams';
import { Slugs } from 'common/urls/Slugs';
import { first, isEmpty } from 'lodash';
import QueryString from 'query-string';
import { useHistory } from 'react-router';
import { ToastPosition } from 'react-toastify';
import { usePersistentDeliveryDates } from 'src/dish-first/usePersistentDeliveryDates';
import Notifications from 'src/shared/Notifications';
import { ConsumerRoutes } from 'src/shared/Routes';
import { getSluggedPath } from 'src/shared/utils/RouteUtilities';
import { useSelector } from 'src/store';
import { selectCartFoodItemCount, selectMultiCartDeliveryDate } from 'src/store/cart/selectors';

export const useFilterQueryParams = () => {
  const history = useHistory();
  const parsedQs = QueryString.parse(history.location.search);
  const filtersQueryParams = {
    [QueryParams.CUISINE_FILTER]: parsedQs[QueryParams.CUISINE_FILTER],
    [QueryParams.BROWSE_FILTER]: parsedQs[QueryParams.BROWSE_FILTER],
    [QueryParams.DIETARY_FILTER]: parsedQs[QueryParams.DIETARY_FILTER],
    [QueryParams.DISH_TYPE_FILTER]: parsedQs[QueryParams.DISH_TYPE_FILTER],
    [QueryParams.ECO_FILTER]: parsedQs[QueryParams.ECO_FILTER],
    [QueryParams.MIN_RATING_FILTER]: parsedQs[QueryParams.MIN_RATING_FILTER],
    [QueryParams.PRICE_LEVEL_FILTER]: parsedQs[QueryParams.PRICE_LEVEL_FILTER],
    [QueryParams.PROTEIN_FILTER]: parsedQs[QueryParams.PROTEIN_FILTER],
    [QueryParams.SPICE_LEVEL_FILTER]: parsedQs[QueryParams.SPICE_LEVEL_FILTER],
  };
  const filtersQueryString = QueryString.stringify(filtersQueryParams as { [key: string]: any });

  return {
    appendFiltersQueryStringToUrl: (url: string) => `${url}${filtersQueryString ? `&${filtersQueryString}` : ``}`,
    filtersQueryParams,
    filtersQueryString,
  };
};

export const getShefMenuUrl = (shefId?: string, activeDateFilter?: string, otherParams?: { [key: string]: any }) =>
  getSluggedPath(ConsumerRoutes.CONSUMER_ORDER_ONE_TIME, [{ slug: Slugs.ID, value: shefId ?? '' }], {
    [QueryParams.DATE]: activeDateFilter,
    ...otherParams,
  });

export const getFoodItemUrl = (shefId?: string, foodItemSlug?: string | null) =>
  getSluggedPath(ConsumerRoutes.CONSUMER_ORDER_FOOD_ITEM, [
    { slug: Slugs.ID, value: shefId ?? '' },
    { slug: Slugs.NAME, value: foodItemSlug ?? '' },
  ]);

// There are 2 situations where we need to update the selected date automatically after add to cart
// 1. If the cart is empty, and the dish delivery date is different than the selected date
// 2. If delivery date is flexible, and the food item added is available on the same date as the cart date
// In all other scenarios, we will show the clear cart/change date modal to the user
export const useHandleAddToCartDateUpdates = (shefDeliveryDate?: DateString) => {
  const [deliveryDates, setDeliveryDates] = usePersistentDeliveryDates();
  const selectedDeliveryDate = first(deliveryDates);
  const isFlexible = isEmpty(selectedDeliveryDate);
  const { cartSize, cartDeliveryDate } = useSelector(({ cart }) => {
    const cartSize = selectCartFoodItemCount(cart);
    const cartDeliveryDate = selectMultiCartDeliveryDate(cart);
    return { cartSize, cartDeliveryDate };
  });

  const handleDeliveryDateUpdates = (dishNextDeliveryDate?: DateString) => {
    const deliveryDate = dishNextDeliveryDate ?? shefDeliveryDate;
    if (
      (deliveryDate && cartSize <= 0 && selectedDeliveryDate !== deliveryDate) ||
      (deliveryDate && isFlexible && cartDeliveryDate === deliveryDate)
    ) {
      setDeliveryDates([deliveryDate]);
      Notifications.success('Delivery date updated', {
        position: ToastPosition.BOTTOM_CENTER,
      });
    }
  };

  return { handleDeliveryDateUpdates };
};
