import { ACTUAL_DOMAIN } from './Domains';

export const SHORT_URL_PATH = 's';

/**
 * Given a shef url, return the domain of the url.
 * @param url frontend shef.com url
 */
export const getDomainForShefUrl = (url?: string): string => {
  if (!url) {
    throw new Error(`Missing url!`);
  }

  // e2e test hack
  if (url.includes('web.test.shef')) {
    return 'test.shef';
  }

  // Localhost
  if (url.includes('localhost')) {
    return 'localhost';
  }

  // Note that cookies share the same domain across shef.com, devX.shef.com. This is because
  // our url is dev5.api.shef.com vs api.dev5.shef.com, so the only way to get dev5's cookies shared across
  // API and web is to set it to the shef.com domain. This does mean deviceIds and sessions are shared across
  // hosts, but that's not a big deal.
  // If we cared to fix this, we could encode the environment into the b64 encoding and use that for validity.
  return ACTUAL_DOMAIN;
};
