export class DataError extends Error {
  constructor(message: string, readonly data?: object) {
    super(message);
  }
}

/**
 * Use this error for errors with data we expect to be
 * thrown in course of application behavior.
 */
export class ExpectedDataError extends DataError {}
