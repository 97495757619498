// Keep this in sync with z-indexes in constants.scss
export enum ZIndex {
  ConsumerExploreUnavailableOverlay = 3, // This has to be lower than ConsumerExploreShefImg bc we need the shef top half to not get double opacity applied
  ConsumerExploreShefImg = 4,
  Badge = 5,
  ConsumerExploreAppUpsellBanner = 7,
  SegmentFiltersContainer = 10,
  ShareDialog = 10,
  OrderProgessNumberCircle = 11,
  DropdownFilter = 12, // Higher than SegmentFiltersContainer
  HorizontalSnapBadgeContainerFadeBarOverlay = 13,
  HorizontalSnapBadgeContainerButton = 14,

  OrderHistoryTabbedNavigator = 900, // Lower than the navbar
  ConsumerNavBarBackdrop = 990,
  ConsumerNavBar = 1000, // Has to be higher than SegmentFiltersContainer so search appears on top
  SlideOutCart = 1001, // Has to be higher than nav bar

  ShefInfoContainer = 11, // Has to be higher than date picker component so food safety popover appears on top

  // This payment button should float at the bottom of the screen, but
  // there are some other elements on the page with z-index of 1000:
  StripePaymentRequestGoogleApplePayButton = 1500,

  ShefDashboardApplicationTracker = 4998,
  ShefDashboardNavBarBackdrop = 4999,
  ShefDashboardNavBar = 5000,

  HelpButton = 9916, // Higher than everything but modals
  DeliveryDayPopover = 9916,
  StickyFooter = 9917, // Just higher than the delivery day popover

  WaitlistQueueButtons = 9918,
  Modal = 9998, // Higher than everything but the modal itself
  ModalContent = 9999, // Higher than everything

  fixedloading = 10000,

  LightBoxBackdrop = 30000,
  LightBox = 30002,
  LightBoxMenu = 30004,

  OffCanvasBackdrop = 35000,
  OffCanvas = 35001,

  DropdownMenu = 40000,
  Tooltip = 50000, // Highest Element
}
