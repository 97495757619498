import { abbreviateNumber } from 'common/NumberUtils';
import React from 'react';
import { ThumbsUpV2 } from 'src/shared/design-system/Icon';

interface RatingTagsProps {
  averageRating: number;
  ratingCount: number;
  tags: string[];
}

export const RatingTags: React.FC<RatingTagsProps> = ({ averageRating, ratingCount, tags }) => (
  <>
    <div className='flex h-8 shrink-0 items-center rounded-md bg-rice px-2'>
      <ThumbsUpV2 width={16} height={16} className='mb-1 mr-1' />
      <span className='text-sm font-medium text-neutral-700'>
        {abbreviateNumber(averageRating)}% {ratingCount ? ` (${abbreviateNumber(ratingCount)})` : ``}
      </span>
    </div>
    {tags.map((tag) => (
      <div key={tag} className='flex h-8 shrink-0 items-center rounded-md bg-neutral-50 px-2'>
        <span className='text-sm font-medium text-neutral-700'>{tag}</span>
      </div>
    ))}
  </>
);
