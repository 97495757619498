import React from 'react';
import { Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const MinusV2 = (props: React.SVGProps<SVGSVGElement>) => (
  <SVG height={16} width={16} viewBox={'0 0 16 16'} {...props}>
    <Path
      d={
        'M15.0769,7.03845c-.72378-.00186-8.71736.00134-9.15384,0h-5A.92326.92326,0,0,0,.92317,8.88463c3.59983.00121,10.49953-.00085,14.15378,0A.92352.92352,0,0,0,15.0769,7.03845Z'
      }
    />
  </SVG>
);
