export enum SpiceLevel {
  HOT = 30,
  MEDIUM = 20,
  MILD = 10,
  SPICY = 10,
  NOT_SPICY = 0,
}

export const shefSpiceMap: Array<[SpiceLevel, string]> = [
  [SpiceLevel.NOT_SPICY, 'Not Spicy'],
  [SpiceLevel.MILD, 'Mild'],
  [SpiceLevel.MEDIUM, 'Medium'],
  [SpiceLevel.HOT, 'Hot'],
];

export const shefSpiceText = new Map(shefSpiceMap);
