import { Maybe } from 'graphql/jsutils/Maybe';
import { isNil } from 'lodash';
import { Currencies, Money } from 'ts-money';

export type BulkDiscountTier = {
  minSubtotal: number;
  maxSubtotal?: Maybe<number> | undefined;
  discountRate: number;
};

export const FREE_DELIVERY_MINIMUM = 4000;

export const getNumberOfCompletedBulkDiscountTiers = (
  bulkDiscountTiers: BulkDiscountTier[],
  subtotal: number
): number => bulkDiscountTiers.filter((tier) => subtotal >= tier.minSubtotal).length;

export const getBulkDiscountTier = (
  bulkDiscountTiers: BulkDiscountTier[],
  subtotal: number
): BulkDiscountTier | undefined => {
  return bulkDiscountTiers.find(
    ({ minSubtotal, maxSubtotal }) => subtotal >= minSubtotal && (isNil(maxSubtotal) || subtotal <= maxSubtotal)
  );
};

export const getNextBulkDiscountTier = (
  bulkDiscountTiers: BulkDiscountTier[],
  subtotal: number
): BulkDiscountTier | undefined => {
  return bulkDiscountTiers.find(({ minSubtotal }) => subtotal < minSubtotal);
};

export const getTotalSavingsFromBulkDiscount = (
  bulkDiscountTier: BulkDiscountTier | undefined,
  subtotal: number
): number => {
  return bulkDiscountTier ? new Money(subtotal, Currencies.USD).multiply(bulkDiscountTier.discountRate).amount : 0;
};

export const getTotalFromBulkDiscount = (bulkDiscountTier: BulkDiscountTier | undefined, subtotal: number): number => {
  return bulkDiscountTier ? subtotal - getTotalSavingsFromBulkDiscount(bulkDiscountTier, subtotal) : subtotal;
};

export const getCalculatedTotalFromBulkDiscounts = (
  bulkDiscountTiers: BulkDiscountTier[],
  subtotal: number
): number => {
  const bulkDiscount = getBulkDiscountTier(bulkDiscountTiers, subtotal);
  return getTotalFromBulkDiscount(bulkDiscount, subtotal);
};

export const getAmountNeededToReachBulkDiscountTier = (
  bulkDiscountTier: BulkDiscountTier | undefined,
  subtotal: number
): number => {
  const subtotalNeeded = bulkDiscountTier?.minSubtotal ?? 0;
  return Math.max(subtotalNeeded - subtotal, 0);
};

export const getAmountNeededForNextBulkDiscount = (bulkDiscountTiers: BulkDiscountTier[], subtotal: number): number => {
  const nextBulkDiscountTier = getNextBulkDiscountTier(bulkDiscountTiers, subtotal);
  return getAmountNeededToReachBulkDiscountTier(nextBulkDiscountTier, subtotal);
};
