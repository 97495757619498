import cn from 'classnames';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

export const ModalBackdrop: React.FC<{ isShowing: boolean; onClick?: () => void }> = ({ isShowing, onClick }) => (
  <CSSTransition in={isShowing} unmountOnExit timeout={150}>
    <div
      className={cn('fixed inset-0 z-modal-backdrop bg-black', {
        'h-0 w-0 opacity-0 transition': !isShowing,
        'opacity-40 transition': isShowing,
      })}
      onClick={onClick}
    />
  </CSSTransition>
);
