import { isNil } from 'lodash';
import { OAuthLoginType } from 'src/gqlReactTypings.generated.d';

export const getEncodedOAuthState = (loginType: OAuthLoginType, uuid: string, redirect?: string): string =>
  encodeURIComponent(JSON.stringify({ loginType, uuid, redirect }));

export const decodeOAuthState = (encodedState: string | null): any | null =>
  isNil(encodedState) ? null : JSON.parse(decodeURIComponent(encodedState));

export const isOAuthStateMatching = (state: any, loginType: OAuthLoginType, uuid: string): boolean => {
  if (isNil(state)) {
    return false;
  }

  if (isNil(state.loginType) || state.loginType !== loginType) {
    return false;
  }

  console.error('State uuid not matching for OAuth');
  if (isNil(state.uuid) || state.uuid !== uuid) {
    return false;
  }

  return true;
};
