import { calculateItemsTotal } from 'common/PriceTotalsUtils';
import { filter, first } from 'lodash';
import { useSelector } from 'src/store';
import {
  calcLineItemsSubtotal,
  selectActiveCartItemCount,
  selectActiveCartLineItemCount,
  selectAddOnItems,
  selectAvailableCartItemCount,
  selectAvailableCartOrders,
  selectCart,
  selectCartFoodItemsById,
  selectCartShefDeliveryDate,
  selectMultiShefCartLineItemsSubtotal,
  selectSingleShefCartLineItemsSubtotal,
} from 'src/store/cart/selectors';

export const useCartTotalsState = () =>
  useSelector((state) => {
    const cart = selectCart(state);
    const addOnItems = selectAddOnItems(cart);
    const orders = selectAvailableCartOrders(cart);
    const orderShefIds = orders.map((o) => o.shef.id);
    const foodItems = selectCartFoodItemsById(cart);
    const firstShefId = first(orderShefIds) || '';

    return {
      isMultiCart: cart.isMultiCart,
      addOnItems,
      addOnItemsSubtotal: calculateItemsTotal(addOnItems),
      activeCartItemCount: cart.isMultiCart ? selectAvailableCartItemCount(cart) : selectActiveCartItemCount(cart),
      checkoutShefIds: cart.isMultiCart ? orderShefIds : [cart.activeCartShefId],
      deliveryDate: selectCartShefDeliveryDate(cart, firstShefId) || cart.deliveryDate,
      hasItemsInCart: (cart.isMultiCart ? orders.length : selectActiveCartLineItemCount(cart)) > 0,
      lineItemsSubtotal: cart.isMultiCart
        ? selectMultiShefCartLineItemsSubtotal(cart, orderShefIds)
        : selectSingleShefCartLineItemsSubtotal(cart, cart.activeCartShefId),
      perShefOrders: filter(
        orders.map((order) => ({
          shefId: order.shef.id,
          lineItems: order.lineItems.map((item) => ({
            foodItemId: item.foodItemId,
            quantity: item.quantity,
            customizationIds: item.customizationIds,
          })),
          lineItemsSubtotal: calcLineItemsSubtotal(order.lineItems, foodItems),
        })),
        (order) => order.lineItems.length > 0
      ),
    };
  });
