import { PersonInfo, Pronoun, ROLE } from '../../consts';
import image from './tim-brown.jpg';

export const TimBrown: PersonInfo = {
  firstName: 'Tim',
  lastName: 'Brown',
  image,
  pronoun: Pronoun.HE,
  role: ROLE.HEAD_OF_ENGINEERING,
};
