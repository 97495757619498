import { ApolloClient } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Primitive } from '@sentry/types';
import { browser, browserVersion, device, deviceLanguage, os } from 'common/BrowserUtilities';
import { ClientEvents, ClientTrackableEvents } from 'common/events/ClientEvents';
import { GqlRequestSource } from 'common/GqlRequestSource';
import { formatGqlErrors } from 'common/GqlUtilities';
import { ISessionManager } from 'common/sessions';
import { ISession } from 'common/sessions/ISession';
import { JSONObj } from 'common/TypeUtilities';
import { omitBy } from 'lodash';
import { parse as parseQueryString } from 'query-string';
import { IngestAnalyticsEventDocument } from 'src/gqlReactTypings.generated.d';
import { isNativeMobileApp } from 'src/mobile-app/isNativeMobileApp';
import { getAppVersion } from 'src/shared/utils/AppUtils';
import { getEnvironment } from 'src/shared/utils/EnvironmentUtilities';
import { ITracker, OptOut, UserInfo } from 'src/tracking/types';
import { v4 as uuid } from 'uuid';

export class ThirdPartyTracker<T> implements ITracker {
  optedOut: OptOut;

  userInfo?: UserInfo;

  public constructor(
    public readonly browserTrackerId: string,
    private readonly apolloClient: ApolloClient<T>,
    private readonly adTracker: ITracker,
    private readonly sessionManager?: ISessionManager
  ) {
    this.optedOut = OptOut.NO;
  }

  public init() {
    if (this.sessionManager) {
      this.sessionManager.addNewSessionListener(this.trackSessionCreation);
      this.sessionManager.getOrCreateSession();
    }

    const initializations: [string, () => void][] = [
      [
        'ad tracker',
        () => {
          this.adTracker.init();
        },
      ],
      [
        'sentry',
        () => {
          Sentry.init({
            environment: getEnvironment(),
            dsn: 'https://ba2e556a4ca44978b34b817c5dd9ef9a@o430551.ingest.sentry.io/5379305',
          });

          ThirdPartyTracker.setSentryTag('app.name', isNativeMobileApp() ? 'native' : 'web');
          ThirdPartyTracker.setSentryTag('app.version', getAppVersion());
          ThirdPartyTracker.setSentryTag('browserTrackerId', this.browserTrackerId);

          if (isNativeMobileApp()) {
            ThirdPartyTracker.setSentryTagsForNativeMobileApp();
          }

          const datadogSessionId = window.DD_RUM?.getInternalContext()?.session_id;
          if (datadogSessionId) {
            // Note that we're adding the tag in Sentry, but not every session will have a replay.
            ThirdPartyTracker.setSentryTag(
              'datadog.sessionReplayUrl',
              `https://app.datadoghq.com/rum/replay/sessions/${datadogSessionId}`
            );
          }
        },
      ],
      [
        'datadog',
        () => {
          this.initIdentityForDataDog();
        },
      ],
    ];

    initializations.forEach(([name, init]) => {
      try {
        init();
      } catch (error: unknown) {
        console.error(`Tracker initialization failed ${name}`, error);
      }
    });
  }

  public hasBeenIdentified(): boolean {
    return !!this.userInfo;
  }

  public updateIdentity<I extends keyof UserInfo>(updates: Pick<UserInfo, I>): void {
    if (!this.userInfo) {
      console.error(`updateIdentity called without userinfo initialized`);
      return;
    }
    this.userInfo = {
      ...this.userInfo,
      ...updates,
    };
    this.adTracker.updateIdentity(updates);
    this.updateIdentityForDataDog({ id: this.userInfo.id });

    Sentry.setUser({
      id: this.userInfo.id,
      email: this.userInfo.email,
    });
  }

  public identify(userInfo: UserInfo) {
    this.userInfo = userInfo;

    this.adTracker.identify(this.userInfo);

    Sentry.setUser({
      id: userInfo.id,
      email: userInfo.email,
    });

    this.updateIdentityForDataDog({ id: this.userInfo.id });
  }

  public track<K extends keyof ClientTrackableEvents>(event: K, eventData: ClientTrackableEvents[K]): void {
    // This runs third-party tracker code that we have no control over. It must be wrapped in try/catch,
    // otherwise bugs in other people's code can break our site!
    try {
      if (this.sessionManager) {
        this.sessionManager.touch();
      }
      this.adTracker.track(event, eventData);

      const data = {
        source: isNativeMobileApp() ? GqlRequestSource.CONSUMER_APP : GqlRequestSource.SHEF_WEB,

        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ...(eventData as any),
        zipCode: this.userInfo?.lastSearchedZipCode,
        btId: this.browserTrackerId,
        deviceId: this.userInfo?.deviceId,
        userId: this.userInfo?.id,
        userEmail: this.userInfo?.email,
        pagePath: window.location.pathname,
        pageQueryArgs: window.location.search,
        eId: uuid(),
      };

      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      this.serverTrack(event, data as unknown as JSONObj);
    } catch (err) {
      console.error(err);
    }
  }

  private trackSessionCreation = (args: ISession) => {
    const { id, startedAt } = args;

    function stringOrIgnore(queryParam: string | string[] | null | undefined): string | undefined {
      if (!queryParam) {
        return undefined;
      }
      return Array.isArray(queryParam) ? queryParam[0] : queryParam;
    }

    const parsedQs = parseQueryString(window.location.search);

    this.track(ClientEvents.SESSION_STARTED, {
      startedAt,
      referrer: document.referrer,
      language: deviceLanguage(navigator) ?? '',
      gclid: stringOrIgnore(parsedQs.gclid),
      utmSource: stringOrIgnore(parsedQs.utm_source),
      utmMedium: stringOrIgnore(parsedQs.utm_medium),
      utmCampaign: stringOrIgnore(parsedQs.utm_campaign),
      utmTerm: stringOrIgnore(parsedQs.utm_term),
      utmContent: stringOrIgnore(parsedQs.utm_content),
      utmTarget: stringOrIgnore(parsedQs.utm_target),
      sessionId: id,
    });
  };

  private serverTrack(eventName: string, data?: JSONObj): void {
    if (this.optedOut !== OptOut.YES) {
      const dataStringify = JSON.stringify(ThirdPartyTracker.enhanceServerTrackingData(data));
      this.apolloClient
        .mutate({
          mutation: IngestAnalyticsEventDocument,
          variables: { eventName, data: dataStringify },
        })
        .then(({ errors }) => {
          if (errors?.length) {
            console.error(formatGqlErrors(errors));
          }
        })
        .catch(console.error);
    }
  }

  private static enhanceServerTrackingData(data: JSONObj = {}) {
    return omitBy(
      {
        ...data,
        $os: os(navigator.userAgent),
        $browser: browser(navigator.userAgent, navigator.vendor, window.opera),
        $referrer: document.referrer || '$direct',
        $device: device(navigator.userAgent),
        $current_url: window.location.href,
        $browser_version: browserVersion(navigator.userAgent, navigator.vendor, window.opera),
        $screen_height: window.screen.height,
        $screen_width: window.screen.width,
        occurred_at: new Date(),
      },
      (value) =>
        // We want to filter out empty strings, null, and undefined.
        // Don't filter out falsy values because we still want to include 0.
        value === '' || value == null
    );
  }

  /**
   * Set custom Sentry tags for the native mobile app.
   * This provides us with the ability to query Sentry issues by these tags.
   */
  private static setSentryTagsForNativeMobileApp() {
    if (window.App) {
      ThirdPartyTracker.setSentryTag('app.releaseChannel', window.App.releaseChannel);
      ThirdPartyTracker.setSentryTag('app.updateId', window.App.updateId);
    } else {
      throw new Error("window.App is missing. Can't set Sentry tags for native mobile app.");
    }
  }

  /**
   * Sets a custom Sentry tag on all future events.
   * https://docs.sentry.io/platforms/javascript/enriching-events/tags/
   *
   * A couple things to note:
   *  - All tags are prefixed with 'shef' to ensure that there aren't any
   *    collisions with Sentry-defined tags.
   *  - Nullish values are defaulted to '[missing]'. This makes it easier for
   *    us to see on the Sentry dashboard when expected tags aren't there.
   */
  private static setSentryTag(name: string, value: Primitive) {
    Sentry.setTag(`shef.${name}`, value ?? '[missing]');
  }

  private initIdentityForDataDog(): void {
    this.updateIdentityForDataDog({});
  }

  private updateIdentityForDataDog<I extends keyof UserInfo>(userInfo: Pick<UserInfo, I>): void {
    window.DD_RUM?.setUser({ btId: this.browserTrackerId, ...userInfo });
  }

  private static removeIdentityForDataDog(): void {
    window.DD_RUM?.clearUser();
  }

  public onLogout(): void {
    this.userInfo = undefined;
    if (this.sessionManager) {
      this.sessionManager.clear();
    }
    this.adTracker.onLogout();
    ThirdPartyTracker.removeIdentityForDataDog();
    Sentry.setUser(null);
  }
}
