import { isNil } from 'lodash';
import {
  IAnalyticsEventManager,
  IIdentifiedAnalyticsEventManager,
  ILog,
  UserIdentifierType,
} from '../analytics-event-manager/IAnalyticsEventManager';
import { CompositeError } from '../CompositeError';
import { SharedEvents } from '../events/SharedEvents';
import { IGatedRollout } from '../gated-rollout/GatedRollout';
import { ILogger } from '../logger-types';
import { IExperimentBucketer } from './bucketing/IExperimentBucketer';
import { IsomorphicExperimentBucketer } from './bucketing/IsomorphicExperimentBucketer';
import { IExperiment, IExperimentManager, ILogExposure, IVariant } from './IExperimentManager';

export const CONTROL_VARIANT = 'control';
export class ExperimentManager implements IExperimentManager {
  private readonly defaultBucketer = new IsomorphicExperimentBucketer();

  private readonly eventManager:
    | IAnalyticsEventManager<SharedEvents.EXPERIMENT_EXPOSURE>
    | IIdentifiedAnalyticsEventManager<SharedEvents.EXPERIMENT_EXPOSURE>;

  private readonly gatedRollout: IGatedRollout;

  private readonly experimentConfig: readonly IExperiment[];

  private readonly logger: ILogger;

  public variantOverrides: Record<string, IVariant>;

  // Salt the hash based off of sessionId on non-prod environments
  // so that devs get access to different variants
  private readonly shouldSaltWithSessionId: boolean;

  private readonly sessionId: string | undefined;

  readonly userId?: string;

  private readonly deviceId?: string;

  constructor({
    eventManager,
    gatedRollout,
    experimentConfig,
    logger,
    variantOverrides,
    shouldSaltWithSessionId,
    sessionId,
    userId,
    deviceId,
  }: {
    eventManager:
      | IAnalyticsEventManager<SharedEvents.EXPERIMENT_EXPOSURE>
      | IIdentifiedAnalyticsEventManager<SharedEvents.EXPERIMENT_EXPOSURE>;
    gatedRollout: IGatedRollout;
    experimentConfig: readonly IExperiment[];
    logger: ILogger;
    variantOverrides: Record<string, IVariant>;
    shouldSaltWithSessionId: boolean;
    sessionId?: string;
    userId?: string;
    deviceId?: string;
  }) {
    this.eventManager = eventManager;
    this.gatedRollout = gatedRollout;
    this.experimentConfig = experimentConfig;
    this.logger = logger;
    this.variantOverrides = variantOverrides;
    this.shouldSaltWithSessionId = shouldSaltWithSessionId;
    this.sessionId = sessionId;
    this.userId = userId;
    this.deviceId = deviceId;
  }

  private getControlVariant(experiment: IExperiment): IVariant {
    for (const variant of experiment.variants) {
      if (variant.name === CONTROL_VARIANT) {
        return variant;
      }
    }
    throw new Error(`Experiment ${experiment.name} does not have a ${CONTROL_VARIANT} variant defined!`);
  }

  public getExperiment(name: string): IExperiment | null {
    const exp = this.getAllExperiments().find((e) => e.name === name) || null;
    if (exp) {
      const controlVariant = this.getControlVariant(exp);
      if (!controlVariant) {
        throw new Error(`Experiment ${name} does not have a ${CONTROL_VARIANT} variant defined!`);
      }
    }
    return exp;
  }

  private getAllExperiments(): readonly IExperiment[] {
    return this.experimentConfig;
  }

  public getVariant(experimentName: string, logExposure: boolean, idOverrideHack?: string): IVariant | null {
    const exp = this.getExperiment(experimentName);
    if (!exp) {
      throw new Error(`Experiment ${experimentName} does not exist!`);
    }

    // runtime check for ensuring proper userIdentifierType until we have better typing here
    const identifier =
      (exp.userIdentifierType === UserIdentifierType.USER_ID ? this.userId : this.deviceId) ?? idOverrideHack;
    if (!identifier) {
      throw new Error(
        `Experiment ${experimentName}: getVariant called without the appropriate useridentifier (${exp.userIdentifierType}) available`
      );
    }

    // Used by debugger
    if (Object.keys(this.variantOverrides).includes(experimentName)) {
      return this.variantOverrides[experimentName];
    }

    const key =
      !this.shouldSaltWithSessionId || isNil(this.sessionId)
        ? `${experimentName.toString()}_${identifier}`
        : `${experimentName.toString()}_${identifier}_${this.sessionId}`;

    // First check to see if they are in the rolled out group.
    if (exp.rollout !== undefined) {
      if (!this.gatedRollout.inRollout(key, exp.rollout)) {
        return null;
      }
    }

    // Next get their bucket.
    const bucketer: IExperimentBucketer = exp.bucketer ?? this.defaultBucketer;
    const variant = ExperimentManager.getVariantFromHash(
      bucketer.getHash({ identifier, experimentName: exp.name }),
      exp
    );

    if (logExposure) {
      this.logExposure({ experimentName: exp.name, variant, userId: idOverrideHack ?? this.userId }).catch(
        this.logger.error
      );
    }

    return variant;
  }

  /**
   * Made visible for tests.
   */
  public static getVariantFromHash(hash: number, experiment: Pick<IExperiment, 'variants' | 'name'>): IVariant {
    let sumWeight = 0;
    for (const variant of experiment.variants) {
      sumWeight += variant.weight;
      if (hash % 100 < sumWeight) {
        return variant;
      }
    }
    throw new Error(`Experiment ${experiment.name} weights did not add up to 100!`);
  }

  // Used by debugger
  public setVariantOverride(experimentName: string, experimentVariant: string): void {
    const variant = this.getExperiment(experimentName)?.variants.find((v) => v.name === experimentVariant);
    if (!variant) {
      throw new Error(`Variant ${experimentVariant} does not exist!`);
    }

    this.variantOverrides = {
      ...this.variantOverrides,
      [experimentName]: variant,
    };
  }

  /** Use this method to indicate that a user was enrolled in an experiment * */
  public async logExposure(props: ILogExposure): Promise<void> {
    const eventProperties: ILog<SharedEvents.EXPERIMENT_EXPOSURE> = {
      eventName: SharedEvents.EXPERIMENT_EXPOSURE,
      identifiers: {
        userId: props.userId,
        deviceId: this.deviceId,
      },
      properties: {
        experiment: props.experimentName,
        variantName: props.variant.name,
      },
    };

    await this.eventManager.log(eventProperties);
  }

  /**
   * The same as logExposure, but logs if the underlying promise would throw instead of
   * returning a promise.
   * @param props
   */
  public logExposureOrError(props: ILogExposure): void {
    this.logExposure(props).catch((err) => {
      this.logger.error(`Failed to log exposure`, new CompositeError('Failed to log exposure', err, props));
    });
  }

  public hasIdentifier(): boolean {
    return !isNil(this.userId) || !isNil(this.deviceId);
  }
}
