import { CurrentUserFieldsFragment } from 'src/gqlReactTypings.generated.d';
import { useGlobalReducer } from 'src/shef-global-state/shefGlobalState';

type Hook = () => [CurrentUserFieldsFragment | null, boolean];

export const useCurrentUser: Hook = () => {
  const { state: globalState } = useGlobalReducer();
  return [globalState.currentUser, !globalState.loaded];
};

export type CurrentUser = CurrentUserFieldsFragment;
