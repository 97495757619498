import React from 'react';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';
import { VerticallyCenteredText } from '../VerticallyCenteredText';

interface LoginButtonV2Props {
  iconSrc?: string;
  icon?: React.ReactNode;
  onClick: React.MouseEventHandler;
}

const StyledButton = styled.button`
  font-family: ${Font.Family.SANS};
  font-weight: ${Font.Weight.SEMI_BOLD};
  font-size: ${Font.Size.S};
  text-align: center;

  background: none;
  border: 1px solid ${Colors.GRAY_DARK_30};
  border-radius: ${Spacing.HALF};
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: ${Spacing.NONE} ${Spacing.SINGLE_QUARTER};
  box-sizing: border-box;
  height: ${Spacing.TRIPLE_HALF};
  width: 100%;
`;

const ButtonText = styled(VerticallyCenteredText)`
  display: block;
  width: 100%;
`;

export const LoginButtonV2: React.FC<LoginButtonV2Props & React.ComponentPropsWithoutRef<'button'>> = ({
  icon,
  iconSrc,
  onClick,
  children,
  ...otherProps
}) => {
  const iconElement: React.ReactNode = match({ icon, iconSrc })
    .with({ icon: P.not(P.nullish) }, ({ icon }) => icon)
    .with({ iconSrc: P.not(P.nullish) }, ({ iconSrc }) => <img className='h-4' src={iconSrc} />)
    .otherwise(() => null);
  return (
    <StyledButton onClick={onClick} {...otherProps}>
      <span className='absolute flex items-center justify-center'>{iconElement}</span>
      <ButtonText>{children}</ButtonText>
    </StyledButton>
  );
};
