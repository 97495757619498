import { DynamicImage } from 'src/shared/design-system/DynamicImage';
import styled from 'styled-components';
import { Border, Colors, Font, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';

export const IMAGE_SIZE = 96;

export const Container = styled.div<{ numberOfMoreFoodItems?: number }>`
  box-sizing: border-box;
  width: ${IMAGE_SIZE}px;
  transition: max-width ${Transition.Time.BASE};
  &:first-child {
    margin-left: ${Spacing.DOUBLE};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  margin-bottom: ${Spacing.HALF};
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_SIZE}px;
`;

export const Image = styled(DynamicImage)`
  border-radius: ${Border.Radius.M};
  height: ${IMAGE_SIZE}px;
  width: ${IMAGE_SIZE}px;
  object-fit: cover;
`;

export const PortionContainer = styled.div`
  position: absolute;
  bottom: ${Spacing.QUARTER};
  right: ${Spacing.QUARTER};
  max-width: calc(100% - ${Spacing.HALF});
`;

export const FoodNameContainer = styled.div`
  color: ${Colors.WHITE};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:hover {
    color: ${Colors.WHITE};
    text-decoration: underline;
  }
`;

export const FoodName = styled.div`
  ${Font.bySize(Font.Size.XS, Font.Weight.MEDIUM)}
  font-family: ${Font.Family.SANS};
  ${Mixins.Text.overflow(4)}
  color: ${Colors.GRAY_DARK_30};
  margin-bottom: ${Spacing.QUARTER};
`;

export const Price = styled.div`
  ${Font.bySize(Font.Size.XS, Font.Weight.MEDIUM)}
  font-family: ${Font.Family.SANS};
  color: ${Colors.GRAY_DARK_30};
`;
