import { Checkmark, ChevronDown } from 'src/shared/design-system/Icon';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { BorderRadius, Colors, Font, ScreenWidth, Spacing, Transition } from 'web-common/src/shared/styles';
import { DropdownSize } from './consts';

export const THEME = {
  [DropdownSize.S]: {
    FontSize: Font.Size.XS,
    IconSize: Spacing.SINGLE,
    Padding: Spacing.HALF,
  },
  [DropdownSize.M]: {
    FontSize: Font.Size.S,
    IconSize: Spacing.SINGLE_QUARTER,
    Padding: Spacing.THREE_QUARTERS,
  },
  [DropdownSize.L]: {
    FontSize: Font.Size.M,
    IconSize: Spacing.SINGLE_HALF,
    Padding: Spacing.SINGLE,
  },
};

export const Container = styled.div<{
  light?: boolean;
  borderRadius?: BorderRadius;
  rounded?: boolean;
  size: DropdownSize;
}>`
  & > div {
    display: flex;
    position: relative;
  }

  & input {
    box-sizing: border-box;
    border: 1px solid ${Colors.GRAY};
    color: ${Colors.GRAY_DARK_30};
    flex: 1;
    font-family: ${Font.Family.SANS} !important;
    font-size: ${({ size }) => THEME[size].FontSize} !important;
    font-weight: 400;
    line-height: ${({ size }) => THEME[size].FontSize};
    outline: none;
    margin: 0;
    padding: ${({ size }) =>
      `calc(${THEME[size].Padding} + 1px) ${THEME[size].Padding} calc(${THEME[size].Padding} - 1px) ${THEME[size].Padding}`};
    max-width: auto;
    transition: border ${Transition.Time.BASE}, background ${Transition.Time.BASE};

    ${({ borderRadius, rounded }) => {
      if (borderRadius) {
        return css`
          border-radius: ${borderRadius};
        `;
      }
      return rounded
        ? css`
            border-radius: ${Spacing.DOUBLE};
          `
        : '';
    }}

    ${ifProp(
      'light',
      css`
        background: ${Colors.WHITE};
        border: 1px solid ${Colors.GRAY};

        &:hover:not(:disabled) {
          border-color: ${Colors.GRAY_DARK_10};
        }
      `,
      css`
        background: ${Colors.GRAY_LITE_30};
        border: 1px solid ${Colors.GRAY_LITE_30};

        &:hover:not(:disabled) {
          background: ${Colors.GRAY_LITE_30};
          border-color: ${Colors.GRAY};
        }

        &[aria-expanded='true'] {
          background: ${Colors.WHITE};
          border-color: ${Colors.GRAY};
        }
      `
    )}
    &[readonly] {
      cursor: pointer;
    }
  }

  &:hover input:not([aria-expanded='true']) {
    ${ifProp(
      'light',
      css`
        border-color: ${Colors.GRAY_DARK_10};
        background-color: ${Colors.GRAY_LITE_30};
      `,
      css`
        border-color: ${Colors.GRAY};
        background-color: ${Colors.GRAY_LITE_30};
      `
    )}
  }
`;

export const IconContainer = styled.div<{ isOpen?: boolean; selected?: boolean; size: DropdownSize }>`
  align-items: center;
  color: ${ifProp('selected', Colors.RADISH, Colors.GRAY_DARK_10)};
  display: flex;
  padding: ${({ size }) => THEME[size].Padding};
  pointer-events: none;

  & svg {
    box-sizing: content-box !important;
    height: ${({ size }) => THEME[size].IconSize};
    width: ${({ size }) => THEME[size].IconSize};
  }
`;

export const InputIconContainer = styled(IconContainer)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: ${ifProp('isOpen', 'rotate(-180deg)', 'rotate(0deg)')};
  transition: transform ${Transition.Time.BASE};
`;

export const Icon = styled(ChevronDown)``;

export const SelectedIcon = styled(Checkmark)``;

export const MobileSelect = styled.select`
  display: none;
  position: absolute;
  opacity: 0;
  pointer-events: none;

  @media (max-width: ${ScreenWidth.SMALL}) {
    display: block;
    pointer-events: all;
    height: 100%;
    width: 100%;
  }
`;
