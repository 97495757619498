import { ROLE } from './consts';
import * as Directory from './Directory';

export const ALL = Object.values(Directory);

export const DESIGN = Object.values(Directory).filter((person) => {
  const { role, otherRoles = [] } = person;
  const isDesignRole = role === ROLE.PRODUCT_DESIGNER;
  const hasDesignRole = otherRoles.includes(ROLE.PRODUCT_DESIGNER);
  return isDesignRole || hasDesignRole;
});
