import {
  IReqRes,
  IRequestHandler,
  IRequestRouter,
  nativePaymentResult,
  nativePaymentsAvailable,
  navigate,
  navStackPop,
  RequestHandler,
  RequestRouter,
} from '@shef/native-bridge';
import { Dispatch, useEffect, useMemo, useRef } from 'react';
import { NativeAppAction } from 'src/mobile-app/hooks/types';
import { useHistory } from 'src/shared/hooks/useHistory';

export function useNativeAppRequestRouter(dispatch: Dispatch<NativeAppAction>): [IRequestRouter] {
  const history = useHistory();
  const historyStackIndex = useRef(0);

  useEffect(
    () =>
      history.listen((location, action) => {
        switch (action) {
          case 'POP':
            historyStackIndex.current -= 1;
            break;
          case 'PUSH':
            historyStackIndex.current += 1;
            break;
          default:
            break;
        }
      }),
    [history]
  );

  const router = useMemo(
    () =>
      new RequestRouter([
        createRequestHandler(nativePaymentsAvailable, ({ paymentMethods }) => {
          dispatch({ name: 'SET_PAYMENT_METHODS', paymentMethods });
          return { success: true };
        }),
        createRequestHandler(nativePaymentResult, (paymentResult) => {
          dispatch({ name: 'SET_PAYMENT_RESULT', paymentResult });
          return { success: true };
        }),
        createRequestHandler(navigate, ({ url }) => {
          history.push(url, { intentSource: 'app' });
          return { success: true };
        }),
        createRequestHandler(navStackPop, () => {
          if (historyStackIndex.current > 0) {
            history.goBack();

            return { success: true };
          }

          return { success: false };
        }),
      ]),
    [dispatch, history]
  );

  return [router];
}

function createRequestHandler<I, O>(
  reqRes: IReqRes<I, O>,
  handler: (req: I) => Promise<O> | O
): IRequestHandler<any, any> {
  return new RequestHandler(reqRes, handler);
}
